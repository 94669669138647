import { AddOutlined, Close, DeleteOutlined, VisibilityOutlined, WarningAmber } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import AddPersonalMedicalDetails from '../../Modal/AddPersonalMedicalDetails';
import * as mainService from "../../../services/mainService.js";
import * as schema from "../../../utils/forms/schema.medicalHistory";
import colors from '../../../constant/colors';
import { EditIcon } from '../../../assets/img/edit';
import strings from '../../../constant/strings';
import { useDispatch, useSelector } from 'react-redux';
import * as userActioncs from '../../../redux/user/userActions';
import { HEALTH_CONDITION_STATUS_ENUM } from '../../../constants';
import { toast } from 'react-toastify';
import { LoadingContainer, NoDetailsContainer } from '../../../assets/styles/CommonStyles';
import { getMedicalHistoryAction } from '../../../redux/user/userActions';

const StyledTableCell = styled(TableCell, { shouldForwardProp: (prop) => prop !== 'actionCell' })(({ theme, actionCell }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#2B2B2B',
    [`&.${tableCellClasses.head}`]: {
        boxSizing: 'border-box',
        height: '38px',
        background: '#F8F9FC',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#F5F5F7',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '18px',
        color: '#5E5E5E',
        ...actionCell && { width: '150px' }
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledTableContainer = styled(TableContainer)(() => ({
    borderRadius: '8px',
    boxSizing: 'border-box',
    border: 'solid 1.2px #F5F5F7',
}))

const StyledActionCells = styled(StyledTableCell)(() => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    minHeight: '55px',
    maxWidth: '150px',
    '& svg': {
        width: '18px',
        height: '18px',
        color: '#5E5E5E'
    }
}))

const StryledGridSection = styled(Grid)(() => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    color: '#2B2B2B',
    alignItems: 'center',
    // padding: 8,
}))

function createData(id, name, prof, fat) {
    return { id, name, prof, fat, calories: moment(new Date()).format('DD MMM YYYY') };
}

const StyledAddButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 48px',
    gap: '8px',
    width: '96px',
    height: '40px',
    background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
    borderRadius: '8px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#FFFFFF',
    '& svg': {
        height: '19px',
        width: '19px'
    },
}))

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    padding: 24,
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    background: colors.white,
    boxShadow: 24,
    border: "none",
    outline: "none",
    borderRadius: 8,
    p: 4,
}

const StyleButtonForMoreRecord = styled(Button)(() => ({
    background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
    width: '100%',
    borderRadius: '0px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#FFFFFF',
}))

const StyledNoRecordSpan = styled('span')(() => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '55px',
    border: 'solid 1.2px #F5F5F7',
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '8px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#5E5E5E'
}))


const StyledDialogTitle = styled(DialogTitle)(() => ({
    position: 'relative',
    width: '100%',
    height: '32px',
    '& button': {
        position: 'absolute',
        right: 8,
        top: 6,
        margin: 'auto',
        '& svg': {
            height: 24,
            width: 24,
        }
    }
}))

const StyledDialogContent = styled(DialogContent)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
        fill: '#F36363',
        width: '72px',
        height: '72px'
    }
}))

const StyledDialogContentText = styled(DialogContentText)(() => ({
    height: '18px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#5E5E5E',
}))

const StyledDialogActions = styled(DialogActions)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 20px',
    minWidth: '384px'
}))

const StyledCancelButton = styled(Button)(() => ({
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 80px',
    width: '160px',
    height: '48px',
    border: '1px solid #5E5E5E',
    borderRadius: '8px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#5E5E5E',
    '&:hover': {
        background: "#E5E4E2",
    },
}))

const StyledDeleteButton = styled(Button)(() => ({
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 80px',
    width: '160px',
    height: '48px',
    border: '1px solid #F36363',
    borderRadius: '8px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    background: '#F36363',
    color: '#FFFFFF',
    '&:hover': {
        color: "#F36363"
    },
}))

export default React.memo(function MedicalReports({ navigate, item }) {

    const [state, setState] = useState({ healthCondtions: false })
    const [dialogueState, setDialogueState] = useState({ open: false })
    const [openModal, setOpenModal] = useState({
        state: false,
        title: ''
    });
    let user = localStorage.getItem("userType")
    const dispatch = useDispatch();
    const { userData, healthConditions, medication, surguries, allergies, vaccinatios, patientAppointmentInfoListLoader, medicalHistory } = useSelector(state => ({
        healthConditions: state.user.healthConditions || [],
        medication: state.user.medication || [],
        surguries: state.user.surgury || [],
        allergies: state.user.allergy || [],
        vaccinatios: state.user.vaccination || [],
        userData: state.auth.userData,
        patientAppointmentInfoListLoader: state.user.patientAppointmentInfoListLoader,
        medicalHistory: state.user.medicalHistory
    }));
   

   // const vari= user === 'patient'? " ": patientAppointmentInfoListLoader

    useEffect(() => {
        user === "patient" && dispatch(getMedicalHistoryAction({token : userData.token}))
    }, [])

    const handleDelete = async () => {
        if (dialogueState.id) {
            const response = await dialogueState.apiService({ id: dialogueState.id, token: userData.token });
            if (response.response_type === "success") {
                toast.success(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: { width: 'auto', borderRadius: 8 } });
                dialogueState.getUpdatedValue()
                setDialogueState({ open: false, id: null })
            } else {
                toast.error(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: { width: 'auto', borderRadius: 8 } });
            }
        } else {
            toast.error('Please select Id', { position: "bottom-center", closeButton: false, autoClose: 1000, style: { width: 'auto', borderRadius: 8 } });
        }
    }

    const handleClose = () => {
        setOpenModal({ state: false });
    }

    return (
        <> {patientAppointmentInfoListLoader  ? <LoadingContainer>{strings.loading}</LoadingContainer> : 
        <Grid container>
                <Grid item xs={12}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Health Conditions</StryledGridSection>
                        <Grid xs={12} md={navigate !== "doctor" ? 10 : 12}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell component="td" scope="row" align='center'>Name</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Status</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Treated By</StyledTableCell>
                                            {navigate !== "doctor" && <StyledTableCell component="td" scope="row" actionCell align="center">Action</StyledTableCell>}
                                        </TableRow>
                                    </TableHead>
                                     <TableBody>
                                        {[...healthConditions.slice(0, !state.healthCondtions ? 3 : healthConditions.length)].map((row) => (
                                            <StyledTableRow key={row._id}>
                                                <StyledTableCell component="td" scope="row">{row.diagnosis}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.type && HEALTH_CONDITION_STATUS_ENUM[row.type - 1]}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.treated_by}</StyledTableCell>
                                                {navigate !== "doctor" && <StyledActionCells component="td" scope="row" align="center">
                                                    <span style={{ margin: '2px 0px' }}>
                                                        <IconButton
                                                            onClick={
                                                                setOpenModal.bind(this, {
                                                                    type: 1,
                                                                    state: true,
                                                                    title: strings.healthConditions,
                                                                    initialValues: { diagnosis: row.diagnosis, type: row.type, treated_by: row.treated_by },
                                                                    apiService: mainService.updateHealthCondition.bind(this, row._id),
                                                                    getUpdatedValue: () => dispatch((userActioncs.healthConditions({ token: userData.token }))),
                                                                    schemaValidations: schema.healthSchema
                                                                })
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={
                                                                setDialogueState.bind(this, {
                                                                    open: true,
                                                                    id: row._id,
                                                                    apiService: mainService.deleteHealthCondition.bind(this),
                                                                    getUpdatedValue: () => dispatch((userActioncs.healthConditions({ token: userData.token }))),
                                                                })
                                                            }
                                                        >
                                                            <DeleteOutlined />
                                                        </IconButton>
                                                    </span>
                                                </StyledActionCells>}
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {healthConditions.length === 0 ?
                                    <StyledNoRecordSpan>No Data Entered</StyledNoRecordSpan>
                                    : healthConditions.length > 3 && <StyleButtonForMoreRecord onClick={setState.bind(this, prev => ({ ...prev, healthCondtions: !prev.healthCondtions }))}
                                    >Show {state.healthCondtions ? 'Less' : 'More'} Health Conditions
                                    </StyleButtonForMoreRecord>
                                }
                            </StyledTableContainer>
                        </Grid>
                        {navigate !== "doctor" && <Grid item xs={12} md={2} lg={2} justifyContent={'center'} display={'flex'} alignItems={'start'}>
                            <StyledAddButton
                                onClick={
                                    setOpenModal.bind(this, {
                                        type: 1,
                                        state: true,
                                        title: strings.healthConditions,
                                        initialValues: { diagnosis: '', type: '', treated_by: '' },
                                        apiService: mainService.addHealthCondition.bind(this),
                                        getUpdatedValue: () => dispatch((userActioncs.healthConditions({ token: userData.token }))),
                                        schemaValidations: schema.healthSchema
                                    })
                                }
                                type="button"
                            >
                                <AddOutlined />
                                Add
                            </StyledAddButton>
                        </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Medications</StryledGridSection>
                        <Grid xs={12} md={navigate !== "doctor" ? 10 : 12}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row" align='center'>Name</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Dosage</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Prescribed For</StyledTableCell>
                                            {navigate !== "doctor" && <StyledTableCell component="td" scope="row" actionCell align="center">Action</StyledTableCell>}
                                        </TableRow>
                                    </TableHead>
                                     <TableBody>
                                        {[...medication.slice(0, !state.medications ? 3 : medication.length)].map((row) => (
                                            <StyledTableRow key={row._id}>
                                                <StyledTableCell component="td" scope="row">{row.name}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.quantity}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.medicine_form}</StyledTableCell>
                                                {navigate !== "doctor" && <StyledActionCells component="td" scope="row" align="center">
                                                    <span style={{ margin: '2px 0px' }}>
                                                        <IconButton
                                                            onClick={
                                                                setOpenModal.bind(this, {
                                                                    type: 2,
                                                                    state: true,
                                                                    title: strings.medications,
                                                                    initialValues: { name: row.name, medicine_form: row.medicine_form, medication_strength: row.medication_strength, strength_type: row.strength_type, quantity: row.quantity },
                                                                    apiService: mainService.updateMeications.bind(this, row._id),
                                                                    getUpdatedValue: () => dispatch((userActioncs.medications({ token: userData.token }))),
                                                                    schemaValidations: schema.medicationSchema
                                                                })
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={
                                                                setDialogueState.bind(this, {
                                                                    open: true,
                                                                    id: row._id,
                                                                    apiService: mainService.deleteMedication.bind(this),
                                                                    getUpdatedValue: () => dispatch((userActioncs.medications({ token: userData.token }))),
                                                                })
                                                            }
                                                        >
                                                            <DeleteOutlined />
                                                        </IconButton>
                                                    </span>
                                                </StyledActionCells>}
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {medication.length === 0 ?
                                    <StyledNoRecordSpan>No Data Entered</StyledNoRecordSpan>
                                    : medication.length > 3 && <StyleButtonForMoreRecord onClick={setState.bind(this, prev => ({ ...prev, medications: !prev.medications }))}
                                    >Show {state.medications ? 'Less' : 'More'} Medications
                                    </StyleButtonForMoreRecord>
                                }
                            </StyledTableContainer>
                        </Grid>
                        {navigate !== "doctor" && <Grid item xs={12} md={2} lg={2} justifyContent={'center'} display={'flex'} alignItems={'start'}>
                            <StyledAddButton
                                onClick={
                                    setOpenModal.bind(this, {
                                        type: 2,
                                        state: true,
                                        title: strings.medications,
                                        initialValues: { name: '', medicine_form: '', medication_strength: '', strength_type: '', quantity: '' },
                                        apiService: mainService.addMedication.bind(this),
                                        getUpdatedValue: () => dispatch((userActioncs.medications({ token: userData.token }))),
                                        schemaValidations: schema.medicationSchema
                                    })
                                }
                                type="button"
                            >
                                <AddOutlined />
                                Add
                            </StyledAddButton>
                        </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Surguries</StryledGridSection>
                        <Grid xs={12} md={navigate !== "doctor" ? 10 : 12}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row" align='center'>Name</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Implant (if any)</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Done On</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Done By</StyledTableCell>
                                            {navigate !== "doctor" && <StyledTableCell component="td" scope="row" actionCell align="center">Action</StyledTableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {[...surguries.slice(0, !state.surguries ? 3 : surguries.length)].map((row) => (
                                            <StyledTableRow key={row._id}>
                                                <StyledTableCell component="td" scope="row">{row.name}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.implants}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{moment(row.date).format('YYYY-MMM-DD')}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.surgeon_name}</StyledTableCell>
                                                {navigate !== "doctor" && <StyledActionCells component="td" scope="row" align="center">
                                                    <span style={{ margin: '2px 0px' }}>
                                                        <IconButton
                                                            onClick={
                                                                setOpenModal.bind(this, {
                                                                    type: 3,
                                                                    state: true,
                                                                    title: strings.surguries,
                                                                    initialValues: { name: row.name, implants: row.implants, date: moment(row.date).format('YYYY-MM-DD'), surgeon_name: row.surgeon_name, notes: row.notes },
                                                                    apiService: mainService.updateSurgury.bind(this, row._id),
                                                                    getUpdatedValue: () => dispatch((userActioncs.surguries({ token: userData.token }))),
                                                                    schemaValidations: schema.surgury
                                                                })
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={
                                                                setDialogueState.bind(this, {
                                                                    open: true,
                                                                    id: row._id,
                                                                    apiService: mainService.deleteSurgury.bind(this),
                                                                    getUpdatedValue: () => dispatch((userActioncs.surguries({ token: userData.token }))),
                                                                })
                                                            }
                                                        >
                                                            <DeleteOutlined />
                                                        </IconButton>
                                                    </span>
                                                </StyledActionCells>}
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {surguries.length === 0 ?
                                    <StyledNoRecordSpan>No Data Entered</StyledNoRecordSpan>
                                    : surguries.length > 3 && <StyleButtonForMoreRecord onClick={setState.bind(this, prev => ({ ...prev, surguries: !prev.surguries }))}
                                    >Show {state.surguries ? 'Less' : 'More'} Surguries
                                    </StyleButtonForMoreRecord>
                                }
                            </StyledTableContainer>
                        </Grid>
                        {navigate !== "doctor" &&
                            <Grid item xs={12} md={2} lg={2} justifyContent={'center'} display={'flex'} alignItems={'start'}>
                                <StyledAddButton
                                    onClick={
                                        setOpenModal.bind(this, {
                                            type: 3,
                                            state: true,
                                            title: strings.surguries,
                                            initialValues: { name: '', implants: '', date: '', surgeon_name: '', notes: '' },
                                            apiService: mainService.addSurgury.bind(this),
                                            getUpdatedValue: () => dispatch((userActioncs.surguries({ token: userData.token }))),
                                            schemaValidations: schema.surgury
                                        })
                                    }
                                    type="button"
                                >
                                    <AddOutlined />
                                    Add
                                </StyledAddButton>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Allergies</StryledGridSection>
                        <Grid xs={12} md={navigate !== "doctor" ? 10 : 12}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row" align='center'>Name</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Triggered By</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Medication</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Reactions</StyledTableCell>
                                            {navigate !== "doctor" && <StyledTableCell component="td" scope="row" actionCell align="center">Action</StyledTableCell>}
                                        </TableRow>
                                    </TableHead>
                                 <TableBody>
                                        {[...allergies.slice(0, !state.allergies ? 3 : allergies.length)].map((row) => (
                                            <StyledTableRow key={row._id}>
                                                <StyledTableCell component="td" scope="row">{row.name}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.triggered_by}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.medication}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.reaction}</StyledTableCell>
                                                {navigate !== "doctor" && <StyledActionCells component="td" scope="row" align="center">
                                                    <span style={{ margin: '2px 0px' }}>
                                                        <IconButton
                                                            onClick={
                                                                setOpenModal.bind(this, {
                                                                    type: 4,
                                                                    state: true,
                                                                    title: strings.vaccianations,
                                                                    initialValues: { name: row.name, triggered_by: row.triggered_by, reaction: row.reaction, how_often: row.how_often, notes: row.notes, first_diagnosed: row.first_diagnosed, medication: row.medication },
                                                                    apiService: mainService.updateAllergy.bind(this, row._id),
                                                                    getUpdatedValue: () => dispatch((userActioncs.allergies({ token: userData.token }))),
                                                                    schemaValidations: schema.allergy
                                                                })
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={
                                                                setDialogueState.bind(this, {
                                                                    open: true,
                                                                    id: row._id,
                                                                    apiService: mainService.deleteAllergy.bind(this),
                                                                    getUpdatedValue: () => dispatch((userActioncs.allergies({ token: userData.token }))),
                                                                })
                                                            }
                                                        >
                                                            <DeleteOutlined />
                                                        </IconButton>
                                                    </span>
                                                </StyledActionCells>}
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {allergies.length === 0 ?
                                    <StyledNoRecordSpan>No Data Entered</StyledNoRecordSpan>
                                    : allergies.length > 3 &&
                                    <StyleButtonForMoreRecord onClick={setState.bind(this, prev => ({ ...prev, allergies: !prev.allergies }))}
                                    >Show {state.allergies ? 'Less' : 'More'} Allergies
                                    </StyleButtonForMoreRecord>
                                }
                            </StyledTableContainer>
                        </Grid>
                        {navigate !== "doctor" && <Grid item xs={12} md={2} lg={2} justifyContent={'center'} display={'flex'} alignItems={'start'}>
                            <StyledAddButton
                                onClick={
                                    setOpenModal.bind(this, {
                                        type: 4,
                                        state: true,
                                        title: strings.allergies,
                                        initialValues: { name: '', triggered_by: '', reaction: '', how_often: '', notes: '', first_diagnosed: '', medication: '' },
                                        apiService: mainService.addAllergy.bind(this),
                                        getUpdatedValue: () => dispatch((userActioncs.allergies({ token: userData.token }))),
                                        schemaValidations: schema.allergy
                                    })
                                }
                                type="button"
                            >
                                <AddOutlined />
                                Add
                            </StyledAddButton>
                        </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Vaccinations</StryledGridSection>
                        <Grid xs={12} md={navigate !== "doctor" ? 10 : 12}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row" align='center'>Name</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">For</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Recieved On</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Details</StyledTableCell>
                                            {navigate !== "doctor" && <StyledTableCell component="td" scope="row" actionCell align="center">Action</StyledTableCell>}
                                        </TableRow>
                                    </TableHead>
                                     <TableBody>
                                        {[...vaccinatios.slice(0, !state.vaccinatios ? 3 : vaccinatios.length)].map((row) => (
                                            <StyledTableRow key={row._id}>
                                                <StyledTableCell component="td" scope="row">{row.name}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.for}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{moment(row.date).format('YYYY-MMM-DD')}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.details}</StyledTableCell>
                                                {navigate !== "doctor" && <StyledActionCells component="td" scope="row" align="center">
                                                    {navigate !== "doctor" ? <span style={{ margin: '2px 0px' }}>
                                                        <IconButton
                                                            onClick={
                                                                setOpenModal.bind(this, {
                                                                    type: 5,
                                                                    state: true,
                                                                    title: strings.vaccianations,
                                                                    initialValues: { for: row.for, date: moment(row.date).format('YYYY-MM-DD'), name: row.name, details: row.details, lot_number: row.lot_number, notes: row.notes },
                                                                    apiService: mainService.updateVaccinations.bind(this, row._id),
                                                                    getUpdatedValue: () => dispatch((userActioncs.vaccination({ token: userData.token }))),
                                                                    schemaValidations: schema.vaccination
                                                                })
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={
                                                                setDialogueState.bind(this, {
                                                                    open: true,
                                                                    id: row._id,
                                                                    apiService: mainService.deleteVaccinations.bind(this),
                                                                    getUpdatedValue: () => dispatch((userActioncs.vaccination({ token: userData.token }))),
                                                                })
                                                            }
                                                        >
                                                            <DeleteOutlined />
                                                        </IconButton>
                                                    </span> : ""}
                                                </StyledActionCells>}
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {vaccinatios.length === 0 ?
                                    <StyledNoRecordSpan>No Data Entered</StyledNoRecordSpan>
                                    : vaccinatios.length > 3 &&
                                    <StyleButtonForMoreRecord onClick={setState.bind(this, prev => ({ ...prev, vaccinatios: !prev.vaccinatios }))}
                                    >Show {state.vaccinatios ? 'Less' : 'More'} Vaccinations
                                    </StyleButtonForMoreRecord>
                                }
                            </StyledTableContainer>
                        </Grid>
                        {navigate !== "doctor" && <Grid item xs={12} md={2} lg={2} justifyContent={'center'} display={'flex'} alignItems={'start'}>
                            <StyledAddButton
                                onClick={
                                    setOpenModal.bind(this, {
                                        type: 5,
                                        state: true,
                                        title: strings.vaccianations,
                                        initialValues: { for: '', date: '', name: '', details: '', lot_number: '', notes: '' },
                                        apiService: mainService.addVaccinations.bind(this),
                                        getUpdatedValue: () => dispatch((userActioncs.vaccination({ token: userData.token }))),
                                        schemaValidations: schema.vaccination
                                    })
                                }
                                type="button"
                            >
                                <AddOutlined />
                                Add
                            </StyledAddButton>
                        </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>}
           
            <Modal
                keepMounted
                open={openModal.state}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box style={style}>
                    {openModal.state && <AddPersonalMedicalDetails setOpenModal={setOpenModal} {...openModal} />}
                </Box>
            </Modal>
            <Dialog
                open={dialogueState.open}
                onClose={setDialogueState.bind(null, { id: null, open: false })}
            >
                <StyledDialogTitle>
                    <IconButton onClick={setDialogueState.bind(null, { open: false, id: null })}>
                        <Close />
                    </IconButton>
                </StyledDialogTitle>
                <StyledDialogContent>
                    <WarningAmber />
                    <StyledDialogContentText>
                        Are you sure you want to delete this file?
                    </StyledDialogContentText>
                </StyledDialogContent>
                <StyledDialogActions>
                    <StyledCancelButton onClick={setDialogueState.bind(null, { open: false, id: null })}>Cancel</StyledCancelButton>
                    <StyledDeleteButton onClick={handleDelete}>Delete</StyledDeleteButton>
                </StyledDialogActions>
            </Dialog>
        </>
    )
})