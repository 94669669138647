import React, {useContext, useEffect, useState} from 'react';

import moment from 'moment';
import {
	Link
} from 'react-router-dom';
import { getUser } from "../../../../../utils";

import { StyledContainer } from './styles';

import { toast } from 'react-toastify';
import { Context } from '../../../../../store';
import {
	getMedicalHistoryDataAPI,
	updateAppointmentStatusAPI,
	getAppointmentDetailsAPI
} from '../../../../../api/doctor';
import { 
	addServiceWardPatient,
	// getPatientWardRoomById,
	addDischargePatientWard,
	getPatientInfo,
	getPatientFollowUpDetails,
	addPatientFollowUpForm,
	getIpdHistoryOfPatient,
	getAppointmentHistory,
} from '../../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';

import '../../../components/doctor.css';

import CardComponent from '../../../../../components/Card';
import HealthRecordsChart from '../../../../SubAdmin/Components/HealthRecordsChart';
import AppointmentCard from '../../../../../components/Appointments';

import { getPayLogo } from '../../../../../constants';
import PatientInfo from '../../../../SubAdmin/Components/PatientInfo';
import MedicalHistoryComponent from '../../../../../components/medicalHistory';

import IpdList from '../../../../../components/IpdList';
import './appointment.css';
import ChatBodyDashBoard from '../../../components/ChatBodyDashBoard';
import ECGchart from '../../../../../components/EcgGraph';
import MedicalReports from '../../../../SubAdmin/Panel/ViewPatient/MedicalReports';
const apiUrl = 'https://api.emed4u.com'//'http://localhost:5000' //'https://api.emed4u.com'; 
// const socket = io(apiUrl);

function DoctorViewAppointment(props) {

	const [state, dispatch] = useContext(Context);
	const [id, setId] = React.useState(null);
	const [doctorId, setDoctorId] = React.useState(null);
	const [patientId, setPatientId] = React.useState(null);
    const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);
	const [ipdHistory, setIpdHistory] = React.useState([]);
	const [currentUser, setCurrentUser] = React.useState(null);
	const [permission, setPermission] = React.useState('');
	const [PatientFollowUpDetails,setPatientFollowUpDetails] = React.useState([]);
	const [patientDetails, setPatientDetails] = React.useState({});
	const [patientData, setPatientData] = React.useState({});
	const [appointmenthistory, setAppointmentHistory] = React.useState([]);
	const pathname = window.location.pathname;
	const subAdminView = pathname.includes('subuser');
	const [patientInfo, SetPatientInfo] = React.useState({
		dob: '',
		gender: '',
		phoneNumber: '',
		name: '',
		address: '',
		profileImage: null,
		org_name: '',
		weight: '',
		_id: null
	});
	const blankMedication = { 
		medication_name: '',
		medicine_type: 'Tablet',
		strength_name: '',
		strength_type: 'mg',
		// method_intake: '',
		// dos_quantity_count: '',
		dos_quantity_name: '',
		// dos_quantity_when: ''
	
	};

	const [fields, setFields] = React.useState([
    { ...blankMedication },
	]);
	const [appointment, setAppointment] = React.useState({
		OrganizationId: '',
		SecondOpinionId: {},
		amount: '',
		appointmentType: '',
		conversions: [],
		createdAt: '',
		doctorId: {},
		patientId: {},
		slot: {},
		status: '',
		_id: ''
	});
	const [ecgWidth, setEcgWidth] = useState();
	const [medicalHistory, setMedicalHistory] = React.useState({
		allergieData: [],
		healthConditionData: [],
		medicationData: [],
		surgeriesData: [],
		vaccinationData: []
	});

	const getStatusClass = (item) => {
		if(item === 0) {
			return 'badge badge-pill bg-warning-light'
		} else if(item === 1 || item === 4) {
			return 'badge badge-pill bg-success-blue text-white'
		} else if(item === 2 || item === 3) {
			return 'badge badge-pill bg-danger-light'
		}
	}

	const getStatus = (item) => {
		if(item === 0) {
			return 'Pending'
		} else if(item === 1) {
			return 'Confirm'
		} else if(item === 2) {
			return 'Rejected by doctor'
		}  else if(item === 3) {
			return 'Rejected by patient'
		}  else if(item === 4) {
			return 'Completed'
		}
	}

	// const newAppointmentHistory = appointmenthistory?.filter((item) => item._id === id)
	useEffect(() => {
		(async function () {
			try {
				const data = await JSON.parse(localStorage.getItem('doctor'));
				if (data.permission.includes("IPD") && data.permission.includes("OPD")) {
					setPermission('ADMIN');
				} else if (data.permission.includes("IPD")) {
					setPermission('IPD');
				} else {
					setPermission('OPD');
				}
			} catch {
				loader(false);
			}
		}());

		if(props.match.params && props.match.params.id) { 
			// if(document.body.className === 'chat-page') {}
			// else document.body.className += ' chat-page';
			
			const userId = getUser();
			setId(userId._id);
			setDoctorId(userId._id)
			const user = {
			  userId: userId._id
			};
			  loader(true)
			
			setId(props.match.params.id);
			setPatientId(props.match.params.patientId);
			loader(true);

			getAppointmentDetailsAPI(props.match.params.id).then(res => {
				setAppointment(res.data);
				onSetUserId(res.data)
			}).catch(err => {
				loader(false);
			})
			getAppointment(props.match.params.patientId);
			getMedicalHistoryDataAPI(props.match.params.patientId).then(res => {
				setMedicalHistory(res.data);
				loader(false);
			}).catch(err => {
				loader(false);
			})
			getPatientInfo(props.match.params.patientId).then((res) => {
				loader(false);
				SetPatientInfo({
					dob: res.data.dob,
					email: res.data.email,
					gender: res.data.gender,
					phoneNumber: res.data.phoneNumber,
					name: res.data.name,
					address: res.data.address,
					profileImage: res.data.profileImage,
					org_name: res.data.org_name,
					_id: res.data._id,
					weight: res.data.weight,
					bloodGroup: res.data.bloodGroup
				})
				setPatientData(res.data);
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
			})
			getIpdHistoryOfPatient(props.match.params.patientId).then((res) => {
				const filterIpd = res.data.filter((item)=>item._id !== props.match.params.id);
				setIpdHistory(filterIpd)
			}).catch((err) => {
				loader(false)
			})
			
		}
	}, [props.match.params.id])

	useEffect(()=>{
		const k = document.getElementById('ecg-graph-width-appointmentId').offsetWidth;
		setEcgWidth(k-20)
	},[])

	const onSetUserId = (item) => {
		setCurrentUser(item._id);
		const userId = getUser();
		if(item.unread && item.unread.id === userId._id) {
			const user = {
				id: item._id,
				userId: userId._id
			};
			// socket.emit("unread_doctor", user);
		}
	}


	const getAppointment = (id) => {
		getAppointmentHistory(id).then((res) => {
			const todayDate = moment().add(1, 'days').format('YYYY-MM-DD')
			const upcomingAppointment = res?.data?.filter((item) => moment(item?.slot?.start)?.utc()?.format('YYYY-MM-DD') < todayDate)
			setAppointmentHistory(upcomingAppointment);
		}).catch((err)=>{ })
	}

	const updateStatus = (data) => {
		loader(true);
		updateAppointmentStatusAPI(id,{status: data.status}).then(res => {
			// getAppointment(id);
			getAppointmentDetailsAPI(props.match.params.id).then(res => {
				setAppointment(res.data);
			}).catch(err => {
				loader(false);
			})
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const handleClickAppointment = (data) => {
		if(subAdminView){
			props.history.push(`/subuser/view-appointment/${data?._id}/${data?.patientId?._id}`);
		}else {
			props.history.push(`/doctor/view-appointment/${data?._id}/${data?.patientId?._id}`);
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}
	// const getStatus = (status) => {
	// 	if(status === 0) {
	// 		return 'Pending by you'
	// 	} else if(status === 1) {
	// 		return 'Confirm'
	// 	} else if(status === 2) {
	// 		return 'Rejected by you'
	// 	}  else if(status === 3) {
	// 		return 'Rejected by patient'
	// 	}  else if(status === 4) {
	// 		return 'Completed'
	// 	}
	// }
	const openIpdPatientView = (id) => {
		if(subAdminView){
			props.history.push(`/subuser/view-patient/${id}/${props.match.params.patientId}`)
		}else {
			props.history.push(`/doctor/view-ipd-patient/${id}/${props.match.params.patientId}`)
		}
	}
	const todayExists = () => {
		let exists = false
		PatientFollowUpDetails && PatientFollowUpDetails.map((data) => {
			if(moment(data.followUpDate).format("DD-MM-YYYY") === moment().format("DD-MM-YYYY") || patientDetails?.dischargeDate){
				exists =  true;
			}
		})
		return exists;
	} 
	const handleSymptoms = (text) => text?.split(',').map((item) => <span className='color-blue'>- {item}</span>);
	return (
		<div>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						
						<div className="col-md-12 mobile-screen-container-size">
							<div className="col-md-12 row">
							<Link
								to={subAdminView ? '/subuser/dashboard' : '/doctor/schedule'}
								style={{
									border: '1px solid #5156be',
									borderRadius: '5px',
									padding: '10px',
									marginBottom: '20px',
									color: '#5156be',
									fontSize: '17px',
									fontWeight: '500'
								}}
								onClick={() => {
									setAppointment({
										OrganizationId: '',
										SecondOpinionId: '',
										amount: '',
										appointmentType: '',
										conversions: '',
										createdAt: '',
										doctorId: '',
										patientId: '',
										slot: '',
										status: '',
										_id: ''
									})
								}}
							>
								<i className="fa fa-arrow-left pr-2" />
								Back To Dashboard
							</Link>
							</div>
							<h3 className="mb-4 mt-4">Appointment details</h3>

							{
								(appointment?.createdAt && appointment.status === 0 && (permission === 'ADMIN' || permission === 'OPD')) && appointment.doctorId._id == doctorId && (
									<div className="row d-flex mb-3"
										style={{
											background: '#fff',
											padding: '20px 10px',
											border: '2px solid #ccc',
											borderRadius: '5px'
										}}
									>
										<div className="col-md-6">
											<a className="btn btn-sm bg-success doctor-w-100 mobile-screen-margin"  data-toggle="modal" data-original-title="test" data-target="#acceptAppointment" style={{color: '#fff', fontWeight: '500', fontSize: '15px'}}>
												<i className="fas fa-check pr-2" />
												Accept
											</a>
										</div>
										<div class="modal fade" id="acceptAppointment" tabindex="-1" role="dialog" aria-labelledby="acceptAppointmentLabel" aria-hidden="true">
											<div class="modal-dialog" role="document">
													<div class="modal-content">
															<div class="modal-header">
																	<h5 class="modal-title" id="acceptAppointmentLabel">Are you really want to accept this appointment</h5>
																	<button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""><span aria-hidden="true">×</span></button>
															</div>
															{/* <div class="modal-body">...</div> */}
															<div class="modal-footer">
																	{/* <button class="btn btn-danger" type="button" data-dismiss="modal" data-original-title="" title="">No</button> */}
																	<button
																		class="btn btn-primary"
																		type="button"
																		data-dismiss="modal"
																		data-original-title=""
																		title=""
																		onClick={() => 
																			getAppointmentDetailsAPI(props.match.params.id).then(res => {
																				setAppointment(res.data);
																				if(res.data.status == 3){
																					toast.error('Appointment already cancelled by Patient!')
																				}else {
																					updateStatus({status: 1})
																				}
																			}).catch(err => { })
																		}
																	>
																		Accept
																	</button>
															</div>
													</div>
											</div>
										</div>


										<div className="col-md-6">
											<a className="btn btn-sm bg-danger doctor-w-100" data-toggle="modal" data-original-title="test" data-target="#rejectAppointment" style={{color: '#fff', fontWeight: '500', fontSize: '15px'}}>
												<i className="fas fa-times pr-2" />
												Reject
											</a>
											<div class="modal fade" id="rejectAppointment" tabindex="-1" role="dialog" aria-labelledby="rejectAppointmentLabel" aria-hidden="true">
												<div class="modal-dialog" role="document">
														<div class="modal-content">
																<div class="modal-header">
																		<h5 class="modal-title" id="rejectAppointmentLabel">Are you really want to reject this appointment</h5>
																		<button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""><span aria-hidden="true">×</span></button>
																</div>
																{/* <div class="modal-body">...</div> */}
																<div class="modal-footer">
																		<button
																			class="btn btn-danger"
																			type="button"
																			data-dismiss="modal"
																			data-original-title=""
																			title=""
																			onClick={() => 
																				getAppointmentDetailsAPI(props.match.params.id).then(res => {
																					setAppointment(res.data);
																					if(res.data.status == 3){
																						toast.error('Appointment already cancelled by Patient!')
																					}else {
																						updateStatus({status: 2})
																					}
																				}).catch(err => { })
																			}
																		>
																			Reject
											 							</button>
																</div>
														</div>
												</div>
											</div>
										</div>
									</div>
								)
							}

							<div className="view-appt-doctor row mb-3"
								style={{
									background: '#fff',
									border: '2px solid #ccc',
									borderRadius: '5px',
									padding: '10px'
								}}
							>
								<div className="col-md-12 d-flex" style={{justifyContent: 'space-between'}}>
									<p
										style={{
											display: 'flex',
											fontSize: '17px',
											fontWeight: '500',
											margin: '0px',
											alignItems: 'center'
										}}
									>
										Appointment date & timing - 
										<p
											style={{
												display: 'flex',
												color: '#5156be',
												margin: '0',
												fontWeight: '500',
												fontSize: '17px',
												paddingLeft: '10px'
											}}
										>
										{appointment._id ? moment(appointment.slot.start).utc().format('Do MMM, YYYY') || ''  : ''}
										{appointment._id ? <p style={{margin: 0, paddingLeft: '10px'}}>
												({moment(appointment.slot.start).utc().format('hh:mm a') || ''} to {moment(appointment.slot.end).utc().format('hh:mm a') || ''})
											</p> : ''
										}
										
										</p>
									</p>
									<p
										style={{
											display: 'flex',
											fontSize: '17px',
											fontWeight: '500',
											margin: '0px',
											alignItems: 'center'
										}}
									>
										Booking Status -
										<p
											style={{
												display: 'flex',
												color: '#5156be',
												margin: '0',
												fontWeight: '500',
												fontSize: '17px',
												paddingLeft: '10px'
											}}
										>
											{appointment._id ? <span className={getStatusClass(appointment.status)} style={{fontSize: '14px'}}>
												{getStatus(appointment.status)}
											</span> : ''}
										</p>
									</p>
									
								</div>

							</div>

							{
								appointment.status === 4 ?
									<div className="mb-3 row" style={{
										background: '#fff',
										border: '2px solid #ccc',
										borderRadius: '5px',
										padding: '10px'
									}}>
										<div className="col-md-12">
											<p style={{
												display: 'flex',
												fontSize: '17px',
												fontWeight: '500',
												margin: '0px',
												alignItems: 'center'
											}}>
												View Final report - 
		
												<a
													style={{
														marginLeft: '20px',
														border: 'none',
														padding: '10px 30px',
														background: '#5156be',
														color: '#fff',
														borderRadius: '5px',
														// marginLeft: 'auto'
													}}
													href={appointment.pdf} 
													target="_blank"
												>
													Click here to view report 
												</a>
											</p>
										</div>
									</div> : ''
							}

							<StyledContainer>
								<div className="content">
									<div className="container-fluid">
										<div className="container-fluid-card row d-flex col-md-12">
											<div className='col-md-9 mobile-screen-padding-top'>
												<div className='d-flex'>
												<div className={`${ (permission === 'OPD' || permission === 'ADMIN') ? "col-md-6 p-0" : "col-md-12 p-0"}`}>
													<div className="col-md-12 mb-3 mobile-screen-padding-bottom">

														<CardComponent className="p-4">
															<div className="card-header">
																<h4 className="card-title">Presenting complaint</h4>
															</div>

															<div className="card-body">

																<div className="booking-summary">
																	<div className="booking-item-wrap">
																		{/* <div className='d-flex'> */}
																			<h5>Appointment type</h5>
																			<div style={{wordBreak: 'break-all'}} className="doctor-symptoms mb-3 ml-auto">
																				{appointment._id ? <span  style={{ color: appointment.appointmentType === 0 ? '#5156be' : '#56BE73' }}>
																					- {appointment.appointmentType === 0 ? 'Online' : 'In clinic'}
																				</span> : ''}
																			</div>
																		{/* </div> */}
																		<hr />
																		{/* <div className='d-flex'> */}
																		<h5>Diabetes</h5>
																		<div style={{wordBreak: 'break-all'}} className="doctor-symptoms mb-3 ml-auto">
																			{appointment._id ? <span className='color-blue'>
																				- {appointment.SecondOpinionId.diabetes ? 'Yes' : 'No'}
																			</span> : ''}
																		</div>
																		{/* </div> */}
																		{/* <div className='d-flex'> */}

																		<h5>Hypertension</h5>
																		<div style={{wordBreak: 'break-all'}} className="doctor-symptoms mb-3 ml-auto">
																			{appointment._id ? <span className='color-blue'>
																				- {appointment.SecondOpinionId.hypertension ? 'Yes' : 'No'}
																			</span> : ''}
																		</div>
																		{/* </div> */}
																		<hr />
																		{/* <div className='d-flex'> */}

																		<h5>Symptoms</h5>
																			{appointment._id ? (
																				<div className="doctor-symptoms mb-4 ml-auto">
																					{appointment.SecondOpinionId.symptoms?.length > 1 ? appointment.SecondOpinionId.symptoms.map(item => <span className='color-blue'>- {item}</span>) : handleSymptoms(appointment?.SecondOpinionId?.symptoms[0])}
																				</div>
																			) : ''}
																		<hr />
																		{/* <div className='d-flex'> */}

																		<h5>Why do you need doctor?</h5>
																		<div style={{wordBreak: 'break-all'}} className="doctor-symptoms mb-3 ml-auto">
																			{appointment._id ? <span className='color-blue'>
																				{appointment.SecondOpinionId.why_do_doctor && appointment.SecondOpinionId.why_do_doctor}
																			</span> : '' }
																		{/* </div> */}
																		</div>
																		<hr />
																		{/* <div className='d-flex'> */}

																		<h5>Documents</h5>
																		{appointment._id ? <div className="doctor-symptoms mb-4 ml-auto">
																			{
																				appointment.SecondOpinionId.uploadsDocuments && appointment.SecondOpinionId.uploadsDocuments.map(item => {
																					return <a
																						target="_blank"
																						href={item}
																						download
																						style={{ color: '#5156be' }}
																					>
																						-	Click here to show document
																					</a>

																				})
																			}

																		{/* </div> */}
																		</div> : '' } 
																	</div>
																</div>
															</div>
															</CardComponent>

														</div>
														{
															(appointment.status === 4) &&
															<div className="col-md-12 mb-3">
																<CardComponent className="p-4 mt-4">
																	<div className="card-header">
																		<h4 className="card-title">Final report</h4>
																	</div>

																	<div className="card-body row">
																		<div className="mb-2 col-md-12">
																			<h4 style={{ color: '#272b41' }}>Diagnosis and observation:</h4>
																			<p style={{ paddingLeft: '10px', color: '#5156be' }}>- {appointment.finalReport ? appointment.finalReport?.impression_diagnosis : ''}</p>
																		</div>

																		{
																			appointment._id && appointment.finalReport.medication && appointment.finalReport.medication.map((item, index) => {
																				return <CardComponent className='row mb-2 mt-2 p-2 pl-4 p-relative' >
																					<p style={{ position: 'absolute', background: '#5156be', color: '#fff', padding: '5px', borderRadius: '6px', left: '8px' }}>{index + 1} .</p>
																					<div className="mb-2 col-md-12">
																						<h4 style={{ color: '#272b41' }}>Medication name:</h4>
																						<p className='color-blue' style={{ paddingLeft: '10px', color: '#5156be' }}>- {item.medication_name}</p>
																					</div>
																					<div className='col-md-12'>
																						<div className="mb-2 col-md">
																							<h4 style={{ color: '#272b41' }}>Form of medicine:</h4>
																							<p className='color-blue' style={{ paddingLeft: '10px', color: '#5156be' }}>- {item.medicine_type}</p>
																						</div>

																						<div className="mb-2 col-md">
																							<h4 style={{ color: '#272b41' }}>Strength:</h4>
																							<p className='color-blue' style={{ paddingLeft: '10px', color: '#5156be' }}>- {item.strength_name}</p>
																						</div>

																						<div className="mb-2 col-md">
																							<h4 style={{ color: '#272b41' }}>Strength type:</h4>
																							<p className='color-blue' style={{ paddingLeft: '10px', color: '#5156be' }}>- {item.strength_type}</p>
																						</div>


																						<div className="mb-2 col-md">
																							<h4 style={{ color: '#272b41' }}>Dosage Quantity intake:</h4>
																							<p className='color-blue' style={{ paddingLeft: '10px', color: '#5156be' }}>- {item.dos_quantity_name}</p>
																						</div>
																					</div>
																				</CardComponent>
																			})
																		}

																		<div className="mb-2">
																			<h4 style={{ color: '#272b41' }}>Other Instructions:</h4>
																			<p className='color-blue' style={{ paddingLeft: '10px', color: '#5156be' }}>- {appointment.finalReport.notes}</p>
																		</div>

																	</div>

																</CardComponent>
															</div>
														}
													</div>
													{(appointment?.createdAt && appointment.status === 0 && (permission === 'ADMIN' || permission === 'OPD')) && appointment.doctorId._id == doctorId ? null : (
														(permission === 'OPD' || permission === 'ADMIN') && appointment.doctorId._id == doctorId ? 
														appointment.status === 4 || appointment.status === 3 || appointment.status === 2 ? null : (
																<div className='col-md-6 p-0'>
																	{appointment._id ? <ChatBodyDashBoard
																		id={currentUser}
																		// socket={socket}
																		props={props}
																		mobile={false}
																		patientId={patientId}
																		setAppointment={setAppointment}
																		className=""
																		chatType="patient"
																	/> : ''}
																</div>
															) : null
												    	)
												    }
													
												</div>
												
												<HealthRecordsChart
													showAdd={false}
													patientId={props.match.params.patientId}
													showLipidProfile={true}
												/>
												<div className="p-3 mobile-screen-padding-card">
													<h2 className = "cards-main-heading mb-3">Medical History</h2>
													<MedicalHistoryComponent medicalHistory={medicalHistory} />
												</div>
											</div>
											<div className='col-md' style={{ padding: "0px" }}>
												<PatientInfo
													showBookingInfo = {true}
													patientInfo={patientInfo} 
													{...props}
													showInfoButton = {true}
													appointment={appointment}
													patientDetails={patientDetails}
												/>
												<AppointmentCard
													heading='Previous appointments'
													data={appointmenthistory}
													keyItem="Previous"
													handleClickAppointment={handleClickAppointment}
												/>
												<IpdList data={ipdHistory} openIpdPatientView={openIpdPatientView}/>
												<CardComponent className='p-2 ecg-graph-width-appointment ecg-min-height' id='ecg-graph-width-appointmentId'>
													{ecgWidth && patientInfo._id && <ECGchart
														width={ecgWidth}
														id={patientInfo._id}
													/>}
												</CardComponent>
												<MedicalReports patiendId={patientInfo._id} />
											</div>
										</div>
										</div>
									</div>
							</StyledContainer>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DoctorViewAppointment

