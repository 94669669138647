import React, { useContext } from 'react';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	getManagementDataAPI
} from '../../../api/website';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';

import {
	StyledContainer
} from './styles';
import {Helmet} from "react-helmet";

function Aboutus() {
	const [state, dispatch] = useContext(Context);

	const [managementTeam, setManagementTeam] = React.useState([]);
	const [clinicalTeam, setClinicalTeam] = React.useState([]);
	const [researchAcademics, setResearchAcademics] = React.useState([]);
	const [alliedHealth, setAlliedHealth] = React.useState([]);

	React.useEffect(() => {
		loader(true);
		getManagementDataAPI().then(res => {
			// setManagementTeam(res.data);
			loader(false);
			const managementTeamData = [];
			const clinicalTeamData = [];
			const researchAcademicsData = [];
			const alliedHealthData = [];

			res.data.map(item => {
				if(item.section == 0) {
					managementTeamData.push(item);
				} else if(item.section == 1) {
					clinicalTeamData.push(item);
				}  else if(item.section == 2) {
					researchAcademicsData.push(item);
				} else if(item.section == 3) {
					alliedHealthData.push(item);
				}
				return item;
			})

			setManagementTeam(managementTeamData)
			setClinicalTeam(clinicalTeamData)
			setResearchAcademics(researchAcademicsData)
			setAlliedHealth(alliedHealthData)
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}, []);

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<StyledContainer>
			<Helmet title = {'Emed4u healtcare solution, best hospital management solution.'}
				htmlAttributes={{ lang: "en" }}
				meta={[
					{
						name: `description`,
						content:'Best healthcare solution. Manage your patients, bills and report on one platform.',
					},
					{
						name: `keywords`,
						content:'best healthcare solution, digital healthcare solution, hospital management solution, online patient managemenet solution',
					},
				]}
				
			/>
			<section class="section emed-about-us section-search">
				<div class="container-fluid">
						<div className="row">
							<div className="col-md-4">
									<h1 className="mb-3">
										Our mission
									</h1>
									<span className="text-secondary text-uppercase d-block mb-5">
									Our experienced doctors and healthcare professionals are always available at your fingertips for your health related queries and reliable second opinions. We provide easy access to high quality patient-centred healthcare options in the comfort of your own home.
									</span>

									<h1 className="mb-3 mt-2">
									Our aim 
									</h1>
									<span className="text-secondary text-uppercase d-block mb-2">
									Emed4u aims to assist you with a cost effective, refined and informative medical consultation so that you can focus on a smooth and accelerated recovery. 
									</span>
									
							</div>

							<div className="col-md-8">
							</div>
						</div>
						
				</div>
			</section>

			<section className="section section-specialities">
				<div className="container">
					<div className="section-header text-center">
						<h2>Founding members</h2>
					</div>

					<div className="row p-4">
						{managementTeam.map(item => {
							return (
								<div
									className="col-sm-4 col-lg-3 col-xs-12 mb-2"
									key={item._id}
								>
									<UserAboutComponent doctor={false} userInfo={item} />
								</div>
							);
						})}
					</div>
				</div>
			</section>

			<section className="section section-specialities pt-0">
				<div className="container">
					<div className="section-header text-center">
						<h2>Clinical team</h2>
					</div>

					<div className="row p-4">
						{clinicalTeam.map(item => {
							return (
								<div
									className="col-sm-4 col-lg-3 col-xs-12 mb-2"
									key={item._id}
								>
									<UserAboutComponent doctor={true} userInfo={item} />
								</div>
							);
						})}
					</div>
				</div>
			</section>
			{researchAcademics.length ?
				<section className="section section-specialities pt-0">
					<div className="container">
						<div className="section-header text-center">
							<h2>Research and academics</h2>
						</div>

						<div className="row p-4">
							{researchAcademics.map(item => {
								return (
									<div
										className="col-sm-4 col-lg-3 col-xs-12 mb-2"
										key={item._id}
									>
										<UserAboutComponent doctor={true} userInfo={item} />
									</div>
								);
							})}
						</div>
					</div>
				</section>		
			:	null}
			{alliedHealth.length ?
				<section className="section section-specialities pt-0">
					<div className="container">
						<div className="section-header text-center">
							<h2>Allied health professionals</h2>
						</div>

						<div className="row p-4">
							{alliedHealth.map(item => {
								return (
									<div
										className="col-sm-4 col-lg-3 col-xs-12 mb-2"
										key={item._id}
									>
										<UserAboutComponent doctor={true} userInfo={item} />
									</div>
								);
							})}
						</div>
					</div>
				</section>
			: null}

			
		</StyledContainer>
	)
}

function UserAboutComponent({userInfo, doctor = false}) {
	return (
		<div className="single_special_cource text-center">
			<div className="about-img">
				<img
					src={
						userInfo.profileImage
							? userInfo.profileImage
							: "/assets/img/doctor.png"
					}
					className="special_img"
					style={{ width: "100%", height: "100%" }}
					onError={e => (e.target.src = "/assets/img/doctor.png")}
					alt=""
				/>
			</div>
			<div className="special_cource_text">
				<a>
					<h3 style={{ marginBottom: "0px" }}>{userInfo.name}</h3>{" "}
				</a>
				{userInfo.designation ? (
					<span style={{ color: "#097EB8" }}>{userInfo.designation}</span>
				) : null}

				{userInfo.director ? (
					<span style={{ color: "#097EB8" }}>{userInfo.director}</span>
				) : null}
				{userInfo.place ? (
					<p style={{ color: "#454545", marginBottom: "0px" }}>
						{userInfo.place}
					</p>
				) : null}
				<br />
				{userInfo.department ? (
					<span style={{ color: "#097EB8" }}>{userInfo.department}</span>
				) : null}
				<br />

				{userInfo.address ? (
					<p className="m-0 mb-1">
						{doctor ? (
							<i
								className="fas fa-map-marker-alt mr-1"
								style={{ color: "#3182b1" }}
							></i>
						) : null}
						{userInfo.address}
					</p>
				) : null}
				{userInfo.experience ? (
					<span style={{ color: "rgb(0, 0, 0)", fontWeight: 600 }}>
						<i class="fas fa-star" style={{ color: "#3182b1" }}></i>{" "}
						{userInfo.experience} of experience
					</span>
				) : null}
				{userInfo.bio ? <p>{userInfo.bio}</p> : null}
			</div>
		</div>
	)
}

export default Aboutus
