import React, { useContext } from 'react';

import { toast } from 'react-toastify';
import { Context } from '../../../../../store';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';


import PhoneInput from "react-phone-number-input";

import {
	addPatientAPI,
	getDoctorSlotsListingAPI,
	getDoctorInfoAPI,
	getAllPaymentMethod,
	getAllPatient
} from '../../../../../api/doctor';

import Slider from "react-slick";

import InputField from '../../../../../components/InputField';
import CardComponent from '../../../../../components/Card';
import SelectField from '../../../../../components/SelectField';
import Select from "react-select";
import Files from 'react-files';
import { globalValidator, getUser } from "../../../../../utils";

import moment from 'moment';

import '../../../components/doctor.css';
import './style.css';
import { symptomsArr } from '../../../../../constants';
import InsideMenuBar from '../../../../../components/InsideMenus';

function AddPatientDoctor(props) {
	const [state, dispatch] = useContext(Context);
	let fileRef = React.useRef(null);
	const [id, setId] = React.useState(null);
	const [filesErrorMsg, setFilesErrorMsg] = React.useState('');
	const [patients, setPatients] = React.useState([]);
	const [slots, setSlots] = React.useState([]);
	const [patientIdE, setPatientIdE] = React.useState(false);

	const [selectedSlot, setSelectedSlot] = React.useState({ _id: null, slot: null, date: null });

	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3
	};

	React.useEffect(() => {
		const id = getUser()._id;
		setId(id);

		getDoctor();

		getDoctorSlotsListingAPI(id).then(res => {
			var slotMap = {};
			for (let slot of res.data) {
				const date = moment(slot.start).utc().format('DD/MM/YYYY');
				if (moment(slot.start).utc().format() > moment().format()) {
					if (slotMap[date]) {
						slotMap[date].push(slot);
					}
					else {
						slotMap[date] = [slot];
					}
				}

			}

			var result = Object.keys(slotMap).map(slotDate => {
				return {
					date: slotDate,
					slots: slotMap[slotDate]
				}
			});

			setSlots(result);
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}, []);

	const [paymentMethods, setPaymentMethods] = React.useState([]);

	React.useEffect(() => {
		getAllPaymentMethod().then(res => {
			setPaymentMethods(res.data);
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
		getAllPatient().then(res => {
			setPatients(res.data);
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}, [])

	const onFilesChange = (files) => {
		fileRef.state.files= '';
		const fileSize = files.filter((item) => item.size > 5242880)
		const filesFilter = files.map((item) => item.type)
		const acceptsTypes = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
		const filesCheckType = filesFilter;
		// eslint-disable-next-line no-extend-native
		Array.prototype.gb_inArray = function(filesCheckType, matchAll = false) {
			if (matchAll) {
				return filesCheckType.every(i => this.includes(i));
			} else {
				return filesCheckType.some(i => this.includes(i));
			}
		};
		
		const fileTypeCheck = acceptsTypes.gb_inArray(filesCheckType, true);

		if(files?.length > 5){
			setFilesErrorMsg('Maximum 5 files are allowed!')
			fileRef.state.files= '';
			return
		}
		
		if(!fileTypeCheck){
			setFilesErrorMsg('PDF or Image should be accepted!');
			fileRef.state.files= '';
			return 
		}

		if(fileSize?.length){
			setFilesErrorMsg('Please upload a file smaller than 5 MB')
			fileRef.state.files= '';
			return 
		}
		fileRef.state.files= '';
		setFilesErrorMsg('');
		SetPatientInfo((info) => ({
			...info,
			uploadsDocuments: files
		}))
	}

	const filesRemoveOne = (file) => {
		const files = patientInfo.uploadsDocuments.filter((item) => item.id!==file.id)
		fileRef.state.files=files
		SetPatientInfo((info) => ({
			...info,
			uploadsDocuments: files
		}))
	  }

	const onFilesError = (error, file) => {
		// toast.error(error.message)
		// dispatch({
		// 	type: SET_ERROR,
		// 	payload: error.message
		// });
	}

	const handleChangeSymptoms = (e) => {
		SetPatientInfo(info => ({ ...info, symptoms: e }))
	}

	const [patientInfo, SetPatientInfo] = React.useState({
		email: '',
		OrganizationId: '',
		patientName: '',
		patientAge: '',
		patientWeight: '',
		why_do_doctor: '',
		symptoms: [],
		patientGender: '',
		uploadsDocuments: [],
		hypertension: false,
		diabetes: false,
		appointmentType: 0,
		phoneNumber: '',
		amount: '',
		pendingAmount: 0,
		patientId: ''
	});

	const [paymentType, setPaymentType] = React.useState(null);

	const submitForm = () => {
		const keys = [
			{ name: "symptoms", title: "Symptoms", validateKey: "required" },
			{ name: "why_do_doctor", title: "Why do you need doctor", validateKey: "required" },
			{ name: "amount", title: "Appointment amount", validateKey: "required" },
		];
		const keyEmail = [
			{ name: "email", title: "Enter a valid Email!", validateKey: "email" },
		]

		let valid = globalValidator(patientInfo, keys);
		let validEmail = globalValidator(patientInfo, keyEmail);
		const currentPatient = patients.filter((item) => item.patient._id == patientInfo.patientId )

		if(currentPatient.length){
			toast.error('Patient already exists')
			dispatch({
				type: SET_ERROR,
				payload: 'Patient already exists'
			});
			return false
		}
		if (!patientIdE) {
			if (patientInfo.patientName.length === 0) {
				toast.error('Patient name is required!')
				return dispatch({
					type: SET_ERROR,
					payload: 'Patient name is required!'
				});
			}
			if (patientInfo.email.length === 0) {
				toast.error('Email is required!')
				return dispatch({
					type: SET_ERROR,
					payload: 'Email is required!'
				});
			}
			if(validEmail == 'Please provide valid email'){
				toast.error('Enter a valid Email!')
				return dispatch({
					type: SET_ERROR,
					payload: 'Enter a valid email!'
				});
			}
			if (
				(patientInfo.email.length === 0)
			) {
				if (patientInfo.phoneNumber === undefined || patientInfo.phoneNumber.length === 0) {
					toast.error('Email or phone number is required!')
					return dispatch({
						type: SET_ERROR,
						payload: 'Email or phone number is required!'
					});
				}
			}
			if (
				(patientInfo.phoneNumber === undefined || patientInfo.phoneNumber.length === 0)
			) {
				if (patientInfo.email.length === 0) {
					toast.error('Email or phone number is required!')
					return dispatch({
						type: SET_ERROR,
						payload: 'Email or phone number is required!'
					});
				}
			}
			if (patientInfo.patientAge.length === 0) {
				toast.error('Patient age is required!')
				return dispatch({
					type: SET_ERROR,
					payload: 'Patient age is required!'
				});
			}
			if (patientInfo.patientWeight.length === 0) {
				toast.error('Patient weight is required!')
				return dispatch({
					type: SET_ERROR,
					payload: 'Patient weight is required!'
				});
			}
			if (patientInfo.patientGender.length === 0) {
				toast.error('Patient gender is required!')
				return dispatch({
					type: SET_ERROR,
					payload: 'Patient gender is required!'
				});
			}
		}

		if (patientIdE && patientInfo.patientId.length === 0) {
			toast.error('Patient Id is required!')
			return dispatch({
				type: SET_ERROR,
				payload: 'Patient Id is required!'
			});
		}

		if (!selectedSlot?._id) {
			toast.error('Please select slot!')
			return dispatch({
				type: SET_ERROR,
				payload: 'Please select slot!'
			});
		}

		const orgData = getUser();
		

		if (valid === true) {
			const formData = new FormData();
			Object.keys(patientInfo).forEach(key => {
				if (key === "uploadsDocuments") {
					patientInfo[key].map(doc => {
						formData.append("uploadsDocuments", doc, doc.name);
					});
				} else if (key === "appointmentType") {
					formData.append(key, 1);
				} else if (key === "symptoms") {
					const symptoms = patientInfo.symptoms.map(item => {
						return item.value;
					});
					formData.append(key, symptoms);
				} else if (key === 'amount') {
					formData.append(key, curreny + patientInfo[key]);
				} else {
					formData.append(key, patientInfo[key]);
				}
			});

			if (orgData.org && orgData.orgData) {
				formData.append("org_name", orgData.orgData.org_name);
				formData.append("OrganizationId", orgData.orgData._id);
			}

			if (patientInfo.appointmentType === 0) {
				formData.append("paymentMode", paymentType);
			}
			if (selectedSlot.slot) {
				formData.append("slotId", selectedSlot.slot._id);
			}

			loader(true);
			addPatientAPI(id, formData).then(res => {
				loader(false);
				toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				props.history.push('/doctor/patient-listing');
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error || 'Patient not found!')
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error || 'Patient not found!'
				});
			})


		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}


	const [curreny, setCurreny] = React.useState('');

	const getDoctor = () => {
		getDoctorInfoAPI().then(res => {
			if (res.data && res.data.curreny) {
				setCurreny(res.data.curreny)
			}
		}).catch(err => {
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const setSlot = (value) => {
		setSelectedSlot({ _id: value.slot._id, slot: value.slot, date: value.date });
	}

	const onChangePaymentMethod = (value) => {
		setPaymentType(value.label);
	}

	return (
		<div>
			<InsideMenuBar
				props={props}
				type={'doctor'}
			/>
			<div className="content doct-panel-em">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<h2>Add a patient</h2>
								<hr />

								<div class="form-group cust-do-em row ml-2">
									<input type="checkbox" id="html" value={patientIdE} onChange={(e) => setPatientIdE(e.target.checked)} />
									<label for="html">Do you have patient Id ?</label>
								</div>

								<div className="row">


									{
										patientIdE ?
											<>
												<div className="p-2 col-md-12 form-group ">
													<label>Patient's Id <small style={{ color: 'red' }}>*</small></label>
													<InputField value={patientInfo.patientId} onChange={e => SetPatientInfo((user) => ({ ...user, patientId: e.target.value }))} />
												</div>
											</>

											:
											<>
												<div className="p-2 col-md-4 form-group ">
													<label>Patient's name <small style={{ color: 'red' }}>*</small></label>
													<InputField value={patientInfo.patientName} onChange={e => SetPatientInfo((user) => ({ ...user, patientName: e.target.value }))} />
												</div>
												<div className="p-2 col-md-4 form-group ">
													<label>Patient's email </label>
													<InputField value={patientInfo.email} onChange={e => SetPatientInfo((user) => ({ ...user, email: e.target.value }))} type="email" />
												</div>

												<div className="p-2 col-md-4 form-group doctor-add-patient-form">
													<label>Contact number </label>
													<PhoneInput
														placeholder="Enter phone number"
														international
														defaultCountry="IN"
														value={patientInfo.phoneNumber}
														onChange={e => SetPatientInfo((user) => ({ ...user, phoneNumber: e }))}
													/>
												</div>

												<div className="p-2 col-md-4 form-group ">
													<label>Age <small style={{ color: 'red' }}>*</small></label>
													<InputField value={patientInfo.patientAge} onChange={e => SetPatientInfo((user) => ({ ...user, patientAge: e.target.value }))} />
												</div>
												<div className="p-2 col-md-4 form-group ">
													<label>Weight (in kgs) <small style={{ color: 'red' }}>*</small></label>
													<InputField value={patientInfo.patientWeight} onChange={e => SetPatientInfo((user) => ({ ...user, patientWeight: e.target.value }))} />
												</div>
												<div className="p-2 col-md-4 form-group ">
													<label>Gender <small style={{ color: 'red' }}>*</small></label>
													<SelectField onChange={e => SetPatientInfo((user) => ({ ...user, patientGender: e.target.value }))} value={patientInfo.patientGender} options={[{ label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }, { label: 'Other', value: 'other' }]} />
												</div>
											</>

									}
								</div>

								<div className="row">
									<div className="col-md-12 form-group">
										<div className="row">
											<div className="col-md-6 form-group ">

												<label>Symptoms <small style={{ color: 'red' }}>*</small></label>
												<Select
													type="select"
													name="symptoms"
													isMulti
													closeMenuOnSelect={false}
													onChange={(e) => handleChangeSymptoms(e)}
													options={symptomsArr.map(symptom => {
														return {
															label: symptom.label,
															value: symptom.label,
															isDisabled: symptom.isDisabled
														};
													})}
													isOptionDisabled={(option) => option.isDisabled}
												/>
											</div>
											<div className="col-md-6 form-group">
												<label>Why do you need doctor? <small style={{ color: 'red' }}>*</small></label>
												<InputField value={patientInfo.why_do_doctor} onChange={e => SetPatientInfo((user) => ({ ...user, why_do_doctor: e.target.value }))} />
											</div>
										</div>
									</div>
								</div>
								<div className="row pl-2 pr-2" style={{ alignItems: 'center' }}>
									<div className="col-md-12 form-group">
										<div className="row">
											<div className="col-md-4 form-group">
												<label>Hypertension</label>
												<div className="d-flex">
													{/* <label class="container-doc-em">Yes
															<input type="radio" name="radio" value={patientInfo.hypertension} onChange={e => SetPatientInfo((user) => ({...user, hypertension: true}))} />
															<span class="checkmark"></span>
														</label>
														<label class="container-doc-em">No
															<input type="radio" checked="checked" name="radio" value={patientInfo.hypertension} onChange={e => SetPatientInfo((user) => ({...user, hypertension: false}))} />
															<span class="checkmark"></span>
														</label> */}


													<div class="btn-group me-2" role="group" aria-label="First group">
														<button type="button" className={patientInfo.hypertension ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({ ...user, hypertension: true }))}>Yes</button>
														<button type="button" className={!patientInfo.hypertension ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({ ...user, hypertension: false }))}>No</button>
													</div>

												</div>
											</div>

											<div className="col-md-4 form-group">
												<label>Diabetes</label>
												<div className="d-flex">
													{/* <label class="container-doc-em">Yes
															<input type="radio" name="radio1" value={patientInfo.diabetes} onChange={e => SetPatientInfo((user) => ({...user, diabetes: true}))} />
															<span class="checkmark"></span>
														</label>
														<label class="container-doc-em">No
															<input type="radio" checked="checked" name="radio1" value={patientInfo.diabetes} onChange={e => SetPatientInfo((user) => ({...user, diabetes: false}))} />
															<span class="checkmark"></span>
														</label> */}

													<div class="btn-group me-2" role="group" aria-label="First group">
														<button type="button" className={patientInfo.diabetes ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({ ...user, diabetes: true }))}>Yes</button>
														<button type="button" className={!patientInfo.diabetes ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({ ...user, diabetes: false }))}>No</button>
													</div>

												</div>
											</div>

											<div className="col-md-4 form-group">
												<label>Payment method</label>
												<div className="d-flex">

													<div class="btn-group me-2" role="group" aria-label="First group">
														<button type="button" className={patientInfo.appointmentType === 0 ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({ ...user, appointmentType: 0 }))}>Online</button>
														<button type="button" className={patientInfo.appointmentType === 1 ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({ ...user, appointmentType: 1 }))}>In cash</button>
													</div>

												</div>
											</div>

										</div>

									</div>
									<div className="p-2 col-md-12 form-group ">
										<div className="row">
											<div className="p-2 col-md-6 form-group ">
												<label style={{ color: 'red' }}>Appointment fee<small style={{ color: 'red' }}>*</small></label>
												<InputField value={patientInfo.amount} onChange={e => SetPatientInfo((user) => ({ ...user, amount: e.target.value }))} />
											</div>

											<div className="p-2 col-md-6 form-group ">
												<label style={{ color: 'red' }}>Pending amount <small style={{ color: 'red' }}>(If any amount pending)</small></label>
												<InputField value={patientInfo.pendingAmount} onChange={e => SetPatientInfo((user) => ({ ...user, pendingAmount: e.target.value }))} />
											</div>
										</div>

									</div>

								</div>

								{
									patientInfo.appointmentType === 0 &&
									<div className="row pl-2 pr-2">
										<div className="col-md-6 form-group">
											<label>Payment method <small style={{ color: 'red' }}>*</small></label>
											<Select
												type="select"
												name="symptoms"
												isMulti={false}
												onChange={(e) => onChangePaymentMethod(e)}
												options={paymentMethods.map(paymentMethod => {
													return {
														label: paymentMethod.type,
														value: paymentMethod.code
													};
												})}
											/>
										</div>
									</div>
								}

								<div className="row pl-2 pr-2">
									<div className="col-md-12 form-group">
										<label>Upload documents <small>(You can upload 5 files)</small></label>
										<Files
											ref={(fileItem) => fileRef = fileItem}
											className='files-dropzone'
											onChange={onFilesChange}
											onError={onFilesError}
											// accepts={['image/*', '.pdf']}
											multiple
											// maxFiles={5}
											// maxFileSize={10000000}
											minFileSize={0}
											clickable
										>
											<div>Drop files here or click to upload</div>
										</Files>
										{filesErrorMsg ? <p style={{color: 'red'}}>{filesErrorMsg}</p> : ''}
										{
											patientInfo.uploadsDocuments && patientInfo.uploadsDocuments.length > 0
												?
												<div className="wardRoomList d-flex flex-wrap mt-2">
													{patientInfo.uploadsDocuments.map((file) =>

														<div className="col-md-2 col room-view mt-2">
															<div>
																<img src="/assets/img/file-uploaded.png" />
																<div
																	id={file.id}
																	className='files-list-item-remove'
																	onClick={() => filesRemoveOne(file)} // eslint-disable-line
																/>
															</div>
														</div>


													)}
												</div>
												: null
										}
									</div>
								</div>

								<div className="row">
									{
										slots.length === 0 && (
											<h5>There is no slot available</h5>
										)
									}
								</div>

								<hr />
								<div className="row mb-3">
									<div className="col-md-4 mb-3">Online  -  <b style={{ background: '#2c7fb7', marginLeft: '5px', padding: '5px 40px' }}></b></div>
									<div className="col-md-4 mb-3">In clinic  -  <b style={{ background: '#56be73', marginLeft: '5px', padding: '5px 40px' }}></b></div>
									<div className="col-md-4 mb-3">Selected slot -  <b style={{ background: 'red', marginLeft: '5px', padding: '5px 40px' }}></b></div>
								</div>
								<hr />

								<Slider {...settings}>
									{
										slots && slots.map((item, i) => {
											return <div className="day-slot">
												<ul>
													<li key={i} className="li-doc">
														<span>{item.date}</span>
														<ul className="doc-slot">
															{
																item.slots && item.slots.map((slot, index) => {
																	return <li key={index}><a className={selectedSlot._id === slot._id && selectedSlot.date === item.date ? 'selected-slot' : (
																		slot.type === 1 ? 'slot-online' : 'slot-offline'
																	)} onClick={() => setSlot({ slot: slot, date: item.date })}>{moment(slot.start).utc().format('hh:mm a')} - {moment(slot.end).utc().format('hh:mm a')}</a></li>
																})
															}
														</ul>
													</li>
												</ul>
											</div>
										})
									}
								</Slider>

								<div className="row text-center" style={{ justifyContent: 'center' }}>
									<button className="doctor-active-btn" onClick={() => submitForm()}>Submit</button>
								</div>
							</CardComponent>

						</div>

					</div>
				</div>
			</div>
		</div>
	)
}

export default AddPatientDoctor
