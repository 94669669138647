import React, {createContext, useReducer} from "react";
import Reducer from './Reducer'

const initialState = {
	loading: false,
	error: null,
	doctors: [],
	orgInfo: {},
	appointments: {
		slot: null,
		date: null,
		doctor: null,
		amount: 0
	},
	patient: {},
	invoice:{},
	callingData: {},
	userType: '',
};

const Store = ({children}) => {
	const [state, dispatch] = useReducer(Reducer, initialState);
	return (
		<Context.Provider value={[state, dispatch]}>
			{children}
		</Context.Provider>
	)
};

export const Context = createContext([initialState, function(){}]);
export default Store;