import styled from 'styled-components';

export const StyledContainer = styled.div`
    .call-box .call-wrapper {
        height: auto !important;
        text-align: center;
        padding: 20% 0px;
    }

    .log-emed {
        border: 1px solid #ccc;
        height: 40px;
        line-height: 10px;
        padding: 15px !important;
        border-radius: 10px;
        &:before {
            border: none !important;
        }
    }

    .logo {
        height: 80px;

        img {
            height: 100%;
        }
    }

    @media only screen and (max-width: 991.98px) {
        .main-nav > li > a {
            color: #fff !important;
        }
        .main-nav > li .submenu li a {
            white-space: normal;
        }
    }
        
`;
