import { BigImageItem, CenteredContent, ColoredText, Credit, Date, Description, EyeIcon, EyeViewWrapper, HighLightedContentWrapper, IconWrapper, IFrame, Input, InputWrapper, LeftWisker, ListingWrapper, RightWisker, SearchIcon, Section, SingleCardWrapper, ViewText, WiskerWrapper, BigImageItemMain } from "./styles"
import { youtube } from "./youtubeList"
import './style.css';
import moment from "moment";

const openInNewTab = (id) => {
	window.open(`https://emed4u.com/blogs/${id}`, "_blank");
  }

export const InputSearch = () => {
	return (
		<InputWrapper>
			<IconWrapper><SearchIcon /></IconWrapper>
			<Input placeholder='Search by blog topic' />
		</InputWrapper>
	)
}

export const WiskerText = () => {
	return (
		<WiskerWrapper>
			<LeftWisker />
				<ColoredText>NEW BLOG</ColoredText>
			<RightWisker />
		</WiskerWrapper>
	)
}

export const HighLightedContent = ({singleBlog}) => {
	return (
		<HighLightedContentWrapper>
			<Section onClick={(e) => openInNewTab(singleBlog.slugurl)}>
				<BigImageItemMain>
					<img alt="blog" src={singleBlog.blogImage} />
				</BigImageItemMain>
				{/* <BigPreviewItem /> */}
				{/* <IFrame src={`https://www.youtube.com/embed/${videoID}?controls=1`} /> */}
			</Section>
			<Section className="pad">
				<WiskerText />
				<Date>{moment(singleBlog.createdAt).format('MMMM Do YYYY')}</Date>
				<Description>{singleBlog.title}</Description>
				<Credit>Category: {singleBlog.category} , <Credit className="colored">(Views : {singleBlog.views})</Credit> </Credit>
				<Credit>Written by: Emed4u Team , <Credit className="colored">8 mins read</Credit> </Credit>
			</Section>
		</HighLightedContentWrapper>
	)
}

	const SingleCard = ({ blog }) => {
	return (
		<SingleCardWrapper onClick={(e) => openInNewTab(blog.slugurl)}>
			<BigImageItem>
				<img alt="blog" src={blog.blogImage} />
			</BigImageItem>
			{/* onClick={(e) => openInNewTab(link, e)} */}
			{/* <BigPreviewItem className="small" /> */}
			<EyeView blog={blog} />
			<Description className="small">{blog.title}</Description>
			<Credit className="small">Written by: Emed4u Team · <Credit className="colored">{blog._id == "60aff75d7b9357355b32e966" ? 10 : 8} mins read</Credit> · </Credit>
		</SingleCardWrapper>
	)
}

export const ListContent  = ({blogs}) => {
	return (
		<ListingWrapper>
			{blogs.map((item) => <SingleCard blog={item}/> )}
		</ListingWrapper>
	)
}

export const EyeView = ({blog}) =>{
	return (
		<EyeViewWrapper>
			<Date className="small">{moment(blog.createdAt).format('MMMM Do YYYY')}</Date>
			<EyeViewWrapper className="no-margin"><EyeIcon /><ViewText>{blog.views} views</ViewText></EyeViewWrapper>
		</EyeViewWrapper>
	)
}