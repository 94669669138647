import React, { useEffect, useContext, useRef } from 'react';
import './index.css'
import moment from 'moment';
import { toast } from 'react-toastify';
import {
    AddDiagnosis,
    addPatientFollowUpLab,
    addPatientFollowUpRadiology,
    addPatientFollowUpNurseMorning,
    addPatientFollowUpNurseAfterNoon,
    addPatientFollowUpNurseEvening,
    addPatientFollowUpNurseNight,
    addPatientFollowUpFormEdication,
    addPatientFollowUpDoctorMorning,
    addPatientFollowUpDoctorAfterNoon,
    addPatientFollowUpDoctorEvening,
    addPatientFollowUpDoctorNight,
    deletePatientFollowUpFormEdication,
} from '../../api/organization';
import InputField from '../InputField'
import SelectField from '../SelectField';
import { medicineType, strengthType } from '../../constants';
import Files from 'react-files';
import Slider from "react-slick";
import { Context } from '../../store';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../store/types';

function FollowUpCarousel(props) {
    const { PatientFollowUpDetails,permission, desigantion, setPatientFollowUpDetails, patientDetails, doctorObservation, fetchPatientFollowUpDetails, detailsId, todayExists } = props;
	const [state, dispatch] = useContext(Context);
    const [index, setIndex] = React.useState(0)
    const [ediatble, setEdiatable] = React.useState();
    const [followUpData, setFollowUpData] = React.useState({})
    const [documents, setDocuments] = React.useState([]);
    const [labtext, setLabtext] = React.useState('');
    const [data, setData] = React.useState({});
    const [currentId, setCurrentId] = React.useState(0);
    const [radiologyText, setRadiologyText] = React.useState('');
    const [filesErrorMsg1, setFilesErrorMsg1] = React.useState('');
    const [filesErrorMsg2, setFilesErrorMsg2] = React.useState('');

    const [nurseMorning, setNurseMorning] = React.useState('');
    const [nurseAfterNoon, setNurseAfterNoon] = React.useState('');
    const [nurseEvening, setNurseEvening] = React.useState('');
    const [nurseNight, setNurseNight] = React.useState('');

    const [doctorMorning, setDoctorMorning] = React.useState('');
    const [doctorAfterNoon, setDoctorAfterNoon] = React.useState('');
    const [doctorEvening, setDoctorEvening] = React.useState('');
    const [doctorNight, setDoctorNight] = React.useState('');
    const [labButton, setLabButton] = React.useState(true);
    const [radiologyButton, setRadiologyButton] = React.useState(true);
    //ScrollRef
    const nurseMorningRef = useRef();
    const nurseAfternoonRef = useRef();
    const nurseEveningRef = useRef();
    const nurseNightRef = useRef();

    const doctorMorningRef = useRef();
    const doctorAfternoonRef = useRef();
    const doctorEveningRef = useRef();
    const doctorNightRef = useRef();
    const path = window.location.pathname;

    
    const [medicationModalOpen, setMedicationModalOpen] = React.useState(false);
    const [medicationFormData, setmedicationFormData] = React.useState({
        name: '',
        form: '',
        strength: '',
        strengthType: '',
        morning: '',
        afternoon: '',
        evening: '',
        night: ''
    })
	
    const blankMedication = {
        medication_name: '',
        medicine_type: 'Tablet',
        strength_name: '',
        strength_type: 'mg',
        // method_intake: '',
        // dos_quantity_count: '',
        dos_quantity_name: '',
        // dos_quantity_when: ''

    };
    useEffect(() => {
        setFollowUpData([]);
        if (PatientFollowUpDetails.length) {
            PatientFollowUpDetails.map((item,id) => {
                if(moment(item.followUpDate).format("DD-MM-YYYY") === moment().format("DD-MM-YYYY")){
                    setCurrentId(id);
                    setData(PatientFollowUpDetails[id])
                    setFollowUpData(PatientFollowUpDetails[id])
                    checkEdiatble(PatientFollowUpDetails[id]);
                }
                else {
                    setCurrentId(0);
                    setFollowUpData(PatientFollowUpDetails[0])
                    setData(PatientFollowUpDetails[0])
                    checkEdiatble(PatientFollowUpDetails[0]);
                }
            })
        }
    }, [PatientFollowUpDetails,patientDetails, path])
    const [fields, setFields] = React.useState([
        { ...blankMedication },
    ]);
    const checkEdiatble = (item) => {
        if (permission && permission === 'OPD') {
            setEdiatable(false);
        }
        else {
            if (patientDetails?.dischargeByDoctor || patientDetails?.dischargeByFinancial) {
                setEdiatable(false);
            }
            else if (item && moment(moment(item?.followUpDate).format("YYYY-MM-DD")).isSame(moment().format("YYYY-MM-DD")) && (desigantion === 'Compounder' || desigantion === 'Nurse') && !(patientDetails?.dischargeByDoctor || patientDetails?.dischargeByFinancial)) {
                setEdiatable(true);
            }
            else if (!(patientDetails?.dischargeByDoctor || patientDetails?.dischargeByFinancial) && doctorObservation && moment(moment(item?.followUpDate).format("YYYY-MM-DD")).isSame(moment().format("YYYY-MM-DD"))) {
                setEdiatable(true);
            }
            else {
                setEdiatable(false)
            }
        }
    }

    const updateFollowUpData = (resData) => {
        let data = PatientFollowUpDetails;
        data[currentId] = resData;
        setPatientFollowUpDetails(data)
    }
    const onLabFilesChange = (files) => {
        var type = files[0].type;
        if(files[0]?.type == "image/png" || files[0]?.type == "image/jpg" || files[0]?.type == "image/jpeg" || files[0]?.type == "application/pdf"){
			if(files[0]?.size > 5242880){
                setFilesErrorMsg1('Please upload a file smaller than 5 MB')
                return
            } else {
                if (files[0]) {
                    const formData = new FormData();
                    formData.append("documents", files[0]);
                    addPatientFollowUpLab(data._id, formData).then((res) => {
                        setFollowUpData(res.data);
                        updateFollowUpData(res.data)
                        setFilesErrorMsg1('')
                    }).catch(err => {
                        setFilesErrorMsg1('')
                    })
                }
            }
			setFilesErrorMsg1('')
		} else {
			setFilesErrorMsg1('PDF or Image should be accepted!');
		}        
    }
    const onRadioLogyFilesChange = (files) => {
        var type = files[0].type;
        if(files[0]?.type == "image/png" || files[0]?.type == "image/jpg" || files[0]?.type == "image/jpeg" || files[0]?.type == "application/pdf"){
            if(files[0]?.size > 5242880){
                setFilesErrorMsg2('Please upload a file smaller than 5 MB')
                return
            }else {
                const fileSize = files.filter((item) => item.size > 5242880)
                if (files[0]) {
                    const formData = new FormData();
                    formData.append("documents", files[0]);
                    addPatientFollowUpRadiology(data._id, formData).then((res) => {
                        setFollowUpData(res.data);
                        updateFollowUpData(res.data)
                        setFilesErrorMsg2('')
                    }).catch(err => {
                        setFilesErrorMsg2('')
                    })
                }
            }
		} else {
			setFilesErrorMsg2('PDF or Image should be accepted!');
		}
       
       
    }
    const onFilesError = (error, file) => {
        console.log(error, file)
    }

    function handleAdd() {
        setMedicationModalOpen(true);
        document.body.style.overflowY = 'hidden';

    }

    const ShowErr = (err) => {
        let message = (err.response &&  err.response?.data?.error) ? err.response.data.error : 'Something went wrong. Please try again'
        toast.error(message);
        dispatch({
            type: SET_ERROR,
            payload: message
        });
    }

    const handleAddDiagnosis = () => {
        if(!followUpData._id) {
            toast.error('Please add FollowUp');
            dispatch({
                type: SET_ERROR,
                payload: 'Please add FollowUp'
            });
            return ;
        }

        if(followUpData?.diagnosis?.replace(/ /g, "")){
            AddDiagnosis(followUpData._id, { diagnosis: followUpData?.diagnosis }).then((res) => {
                setFollowUpData(res.data);
                updateFollowUpData(res.data);
                toast.success("Successfully Submit");
            }).catch(err => {
                ShowErr(err);
            });
        } else {
            toast.error("Please fill diagnosis and observation field");
        }
    }

    const handleDeleteMedication = (val) => {
        loader(true);
        const payload = {
            medicationId: val._id
        }
        deletePatientFollowUpFormEdication(followUpData._id, payload).then((res) => {
            toast.success("Medication successfully deleted!");
            fetchPatientFollowUpDetails(detailsId);
            loader(false);
        }).catch(err => {
            loader(false);
            toast.error('Something went wrong');
            dispatch({
                type: SET_ERROR,
                payload: 'Something went wrong'
            });
        })
    }

    const hanldelabSubmit = () => {
         setLabButton(false);
        if(!followUpData._id) {
            toast.error('Please add FollowUp');
            dispatch({
                type: SET_ERROR,
                payload: 'Please add FollowUp'
            });
            setLabButton(true);
            return ;
        }
        const labData = {
            text: labtext,
            documents: ''
        }
        if(labtext?.replace(/ /g, "")){
            addPatientFollowUpLab(followUpData._id, labData).then((res) => {
                setLabtext('');
                setLabButton(true);
                setFollowUpData(res.data);
                updateFollowUpData(res.data)
            }).catch(err => {
                ShowErr(err);
                setLabButton(true);
            })
        } else {
            toast.error('Please fill lab investigation');
            setLabButton(true);
        }
    }

    const hanldeRadiologySubmit = () => {
        setRadiologyButton(false);
        if(!followUpData._id) {
            toast.error('Please add FollowUp');
            dispatch({
                type: SET_ERROR,
                payload: 'Please add FollowUp'
            });
            setRadiologyButton(true);
            return ;
        }
        const radioData = {
            text: radiologyText,
            documents: ''
        }
        if(radiologyText?.replace(/ /g, "")){
            addPatientFollowUpRadiology(followUpData._id, radioData).then((res) => {
                setRadiologyText('');
                setRadiologyButton(true);
                setFollowUpData(res.data);
                updateFollowUpData(res.data)
            }).catch(err => {
                ShowErr(err);
                setRadiologyButton(true);
            })
        } else {
            toast.error('Please fill radiology or Other investigation');
            setRadiologyButton(true);
        }
        
    }

    const scrollToBottom = (ref) => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
    };
    const hanldeNurseMorningSubmit = () => {
        if(!followUpData._id) {
            toast.error('Please add FollowUp');
            dispatch({
                type: SET_ERROR,
                payload: 'Please add FollowUp'
            });
            return ;
        }
        const morningData = {
            text: nurseMorning,
        }
        addPatientFollowUpNurseMorning(followUpData._id, morningData).then((res) => {
            setNurseMorning('');
            setFollowUpData(res.data);
            updateFollowUpData(res.data)
            scrollToBottom(nurseMorningRef);
        }).catch(err => {
            ShowErr(err)
        })
    }
    const hanldeNurseAfterNoonSubmit = () => {
        if(!followUpData._id) {
            toast.error('Please add FollowUp');
            dispatch({
                type: SET_ERROR,
                payload: 'Please add FollowUp'
            });
            return ;
        }
        const afterNoonData = {
            text: nurseAfterNoon,
        }
        addPatientFollowUpNurseAfterNoon(followUpData._id, afterNoonData).then((res) => {
            setNurseAfterNoon('');
            setFollowUpData(res.data);
            updateFollowUpData(res.data)
            scrollToBottom(nurseAfternoonRef);
        }).catch(err => {
            ShowErr(err)
        })
    }
    const hanldeNurseEveningSubmit = () => {
        if(!followUpData._id) {
            toast.error('Please add FollowUp');
            dispatch({
                type: SET_ERROR,
                payload: 'Please add FollowUp'
            });
            return ;
        }
        const eveningData = {
            text: nurseEvening,
        }
        addPatientFollowUpNurseEvening(followUpData._id, eveningData).then((res) => {
            setNurseEvening('');
            setFollowUpData(res.data);
            updateFollowUpData(res.data)
            scrollToBottom(nurseEveningRef);
        }).catch(err => {
            ShowErr(err)
        })
    }
    const handleNurseNightSubmit = () => {
        if(!followUpData._id) {
            toast.error('Please add FollowUp');
            dispatch({
                type: SET_ERROR,
                payload: 'Please add FollowUp'
            });
            return ;
        }
        const nightData = {
            text: nurseNight,
        }
        addPatientFollowUpNurseNight(followUpData._id, nightData).then((res) => {
            setNurseNight('');
            setFollowUpData(res.data);
            updateFollowUpData(res.data)
            scrollToBottom(nurseNightRef);
        }).catch(err => {
            ShowErr(err)
        })
    }
    const handleDoctorSubmit = (type) => {
        if(!followUpData._id) {
            toast.error('Please add FollowUp');
            dispatch({
                type: SET_ERROR,
                payload: 'Please add FollowUp'
            });
            return ;
        }
        switch (type) {
            case 'morning':
                addPatientFollowUpDoctorMorning(followUpData._id, { text: doctorMorning }).then((res) => {
                    setDoctorMorning('');
                    setFollowUpData(res.data);
                    updateFollowUpData(res.data)
                    scrollToBottom(doctorMorningRef);
                }).catch(err => {
                        ShowErr(err)
                })
                break
            case 'afternoon':
                addPatientFollowUpDoctorAfterNoon(followUpData._id, { text: doctorAfterNoon}).then((res) => {
                    setDoctorAfterNoon('');
                    setFollowUpData(res.data);
                    updateFollowUpData(res.data)
                    scrollToBottom(doctorAfternoonRef);
                }).catch(err => {
                    ShowErr(err)
                })
                break
            case 'evening':
                addPatientFollowUpDoctorEvening(followUpData._id, { text: doctorEvening }).then((res) => {
                    setDoctorEvening('');
                    setFollowUpData(res.data);
                    updateFollowUpData(res.data)
                    scrollToBottom(doctorEveningRef);
                }).catch(err => {
                    ShowErr(err)
                })
                break
            case 'night':
                addPatientFollowUpDoctorNight(followUpData._id, { text: doctorNight }).then((res) => {
                    setDoctorNight('');
                    setFollowUpData(res.data);
                    updateFollowUpData(res.data)
                    scrollToBottom(doctorNightRef);
                }).catch(err => {
                    ShowErr(err)
                })
                break
            default:
                return
        }
        
    }

    const handleClearValue = () => {
        setmedicationFormData({
            name: '',
            // form: '',
            // strength: '',
            // strengthType: '',
            morning: '',
            afternoon: '',
            evening: '',
            night: ''
        });
    }

    const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

    const handleFormMedicationSubmit = () => {
        loader(true);
        setMedicationModalOpen(false);
        if(medicationFormData.name){
            addPatientFollowUpFormEdication(followUpData._id, medicationFormData).then((res) => {
                loader(false);
                setFollowUpData(res.data);
                setMedicationModalOpen(false);
                document.body.style.overflowY = 'auto';
                updateFollowUpData(res.data);
                handleClearValue();
            }).catch(err => {
                ShowErr(err)
                loader(false);
            })
        }else {
            toast.error('Please fill medication name');
            dispatch({
                type: SET_ERROR,
                payload: 'Please fill medication name'
            });
            loader(false);
        }
       
    }

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "30px",
        slidesToShow: PatientFollowUpDetails.length < 3 ? PatientFollowUpDetails.length : 3,
        speed: 300,
        focusOnSelect: true,
        afterChange: (state) =>  {
            setIndex(state);
            setData(PatientFollowUpDetails[state])
            setFollowUpData(PatientFollowUpDetails[state])
            checkEdiatble(PatientFollowUpDetails[state])
        },
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              }
            }
          ],
      };

    return (
        <div className={props.className}>
            <div className="col-md-14 no-padding row">
                <div className="col-md-6 no-padding form-group row hb-mobile-screen-diagnosis">
                    <div className="col-md-8 no-padding form-group hb-mobile-screen-diagnosis">
                        <label>Diagnosis and observation:</label>
                        <InputField placeholder="Enter diagnosis and observation" readOnly={ediatble ? false : true} value={followUpData?.diagnosis} onChange={e => setFollowUpData((followUpData) => ({ ...followUpData, diagnosis: e.target.value }))} />
                    </div>
                    {ediatble ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <button style={{ marginRight: "0px", display: 'flex', justifyContent: 'center', marginTop: "0.7rem" }} className="doctor-active-btn mrn-auto" onClick={() => handleAddDiagnosis()}> Submit</button>
                        </div> : null}
                </div>
                <div class="col-md-6 hb-mobile-screen-slider" style={{paddingTop:'1.4rem'}}>
                    <Slider {...settings}>
                        {
                            PatientFollowUpDetails && PatientFollowUpDetails.map((item, id) => (
                                <div>
                                    <span>{id === index ? moment(item.followUpDate).format("dddd") : moment(item.followUpDate).format("ddd")}</span>
                                    <span class="d-block" >{id === index ? moment(item.followUpDate).format("MMM Do YYYY") : moment(item.followUpDate).format("Do")}</span>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>

            <div className='col-md'>

                <div>
                    <div className="card-body" style={{ padding: "0px" }}>
                        <div className="col-md-14">
                            <div className="col-md-14" >

                                <div className="col-md-14 no-padding form-group d-flex">
                                    <h3>Form of medication</h3>
                                    {(ediatble && followUpData?._id )?
                                        <button style={{ marginRight: "0px" }} className="doctor-active-btn mrn-auto ml-auto" onClick={() => handleAdd()}> <i class="fa fa-plus"> </i></button> : null}
                                </div>
                                <div className="col-md-14" style={{ pointerEvents: `${ediatble ? '' : 'none'}` }}>
                                    {
                                        followUpData && followUpData?.medication?.length && followUpData.medication.map((val, idx) => {
                                            const medication_nameId = `smedication_name-${idx}`;
                                            const medicine_typeId = `medicine_type-${idx}`;
                                            const strength_nameId = `strength_name-${idx}`;
                                            const strength_typeId = `strength_type-${idx}`;

                                            return (
                                                <>
                                                    <div key={`medication_name-${idx}`} className="row" style={{ justifyContent: "space-between" }}>
                                                        <div className="col-md-2 no-padding form-group medication-form ">
                                                            <label>Medication name:</label>
                                                            <span style={{wordBreak: 'break-word'}}>{val.name}</span>
                                                        </div>


                                                        <div className="col-md-2 no-padding form-group medication-form ">
                                                            <label>Form of medicine:</label>
                                                            <span>{val.form}</span>
                                                        </div>

                                                        <div className="col-md-1 no-padding form-group medication-form ">
                                                            <label>Strength:</label>
                                                            <span style={{wordBreak: 'break-word'}}>{val.strength}</span>
                                                        </div>

                                                        <div className="col-md-1.8 no-padding form-group medication-form ">
                                                            <label>Strength type:</label>
                                                            <span>{val.strengthType}</span>
                                                        </div>
                                                        <div className="col-md-5 mb-5 d-flex row" style={{ textAlign: "center" }} >
                                                            <div className="col-md-3">
                                                                <p>Mornign Time</p>
                                                                <div className="drug-administration-check-circle">
                                                                    <label class="container">
                                                                        {/* <input className="drug-administration-checkbox checked" type="checkbox" value={val.morning} checked={val.morning} />
                                                                        <span class="drug-admin-checkmark"></span> */}
                                                                        {
                                                                            val.morning ? <i class="fas fa-check-circle active-check-icon"></i> : <i class="fas fa-circle inactive-check-icon"></i>
                                                                        }
                                                                    </label>
                                                                </div>

                                                            </div>
                                                            <div className="col-md-3">
                                                                <p>Afternoon Time</p>
                                                                <div className="drug-administration-check-circle">
                                                                    <label class="container">
                                                                        {/* <input className="drug-administration-checkbox checked" type="checkbox" value={val.afternoon} checked={val.afternoon} />
                                                                        <span class="drug-admin-checkmark"></span> */}
                                                                        {
                                                                            val.afternoon ? <i class="fas fa-check-circle active-check-icon"></i> : <i class="fas fa-circle inactive-check-icon"></i>
                                                                        }
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <p>Evening Time</p>
                                                                <div className="drug-administration-check-circle">
                                                                    <label class="container">
                                                                        {/* <input className="drug-administration-checkbox checked" type="checkbox" value={val.evening} checked={val.evening} />
                                                                        <span class="drug-admin-checkmark"></span> */}
                                                                        {
                                                                            val.evening ? <i class="fas fa-check-circle active-check-icon"></i> : <i class="fas fa-circle inactive-check-icon"></i>
                                                                        }
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <p>Night Time</p>
                                                                <div className="drug-administration-check-circle">
                                                                    <label class="container">
                                                                        {/* <input className="drug-administration-checkbox checked" type="checkbox" value={val.night} checked={val.night} />
                                                                        <span class="drug-admin-checkmark"></span> */}
                                                                        {
                                                                            val.night ? <i class="fas fa-check-circle active-check-icon"></i> : <i class="fas fa-circle inactive-check-icon"></i>
                                                                        }
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {ediatble ?
                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                <i
                                                                    className="btn fas fa-trash medication-edit-icon"
                                                                    data-toggle="modal"
                                                                    data-original-title="test"
                                                                    data-target="#acceptAppointment"
                                                                    
                                                                />
                                                            </div> : null}
                                                                <div class="modal fade" id="acceptAppointment" tabindex="-1" role="dialog" aria-labelledby="acceptAppointmentLabel" aria-hidden="true">
                                                                    <div class="modal-dialog" role="document">
                                                                            <div class="modal-content">
                                                                                    <div class="modal-header">
                                                                                            <h5 class="modal-title" id="acceptAppointmentLabel">Are you really want to delete this medication</h5>
                                                                                            <button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""><span aria-hidden="true">×</span></button>
                                                                                    </div>
                                                                                    {/* <div class="modal-body">...</div> */}
                                                                                    <div class="modal-footer">
                                                                                            {/* <button class="btn btn-danger" type="button" data-dismiss="modal" data-original-title="" title="">No</button> */}
                                                                                            <button
                                                                                                class="btn btn-danger"
                                                                                                type="button"
                                                                                                data-dismiss="modal"
                                                                                                data-original-title=""
                                                                                                title=""
                                                                                                onClick={() => {
                                                                                                    handleDeleteMedication(val)
                                                                                                }}
                                                                                            >
                                                                                                Delete
                                                                                            </button>
                                                                                    </div>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                        )}
                                </div>
                            </div>

                        </div>
                        <div className="col-md-14 mb-3 row" style={{justifyContent : "space-between"}}>
                            <div className="col-md-6 mb-4 d-flex row">
                                <div className="col-md-14">
                                    <p style={{ fontWeight: 700, fontSize: "18px" }}>Lab Investigation needed</p>
                                </div>
                                {(!ediatble && !followUpData?.lab?.length) ? 
                                <div className="col-md-12 no-data-div">
                                    <h4>Sorry no Data Available</h4>
                                    </div> : 
                                <div className="col-md-12 lab-investigation-card">
                                    <ul className="list-unstyled" style={{ height: `${ediatble ? '75%' : '100%'}` }}>
                                        {
                                            followUpData && followUpData?.lab && followUpData?.lab.map((item, id) => (
                                                <li className="media received" key={id}>
                                                    <div className="media-body">
                                                        <div className="msg-box">
                                                            <div>
                                                                {
                                                                    item.documents && <a
                                                                        target="_blank"
                                                                        href={item.documents}
                                                                        rel="noreferrer"
                                                                        download
                                                                        style={{ color: '#5156be' }}
                                                                    >
                                                                        Click here to show document {id + 1}
                                                                		</a>
                                                                }
                                                                {
                                                                    item.text && <p style={{wordBreak: 'break-word'}}>{item.text}</p>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </li>
                                            ))
                                        }

                                    </ul>
                                    {ediatble ?
                                        <div className="list-footer-div">
                                            <div className="chat-footer">
                                                <div className="input-group">
                                                    {/* <input type="text" className="input-msg-send form-control" placeholder="Type something" value={message} onChange={e => setMessage(e.target.value)} onKeyDown={onEnter} /> */}
                                                    <input style={{ borderRadius: "5px", margin: "0px 10px", maxWidth: "60%" }} value={labtext} type="text" className="input-msg-send form-control" placeholder="Type" onChange={(e) => setLabtext(e.target.value)} />
                                                    <div className="input-group-append">
                                                        {/* <button type="button" className="btn msg-send-btn" onClick={() => sendMessage()}><i className="fab fa-telegram-plane"></i></button> */}
                                                        <button
                                                            style={{ marginRight: "10px", borderRadius: "5px" }}
                                                            type="button"
                                                            className="btn msg-send-btn"
                                                            onClick={labButton ? hanldelabSubmit : ''}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                    <div className="input-group-prepend" style={{ marginRight: "10px" }}>
                                                        <Files
                                                            className='files-dropzone file-drop-list'
                                                            onChange={onLabFilesChange}
                                                            style={{cursor: 'pointer'}}
                                                            onError={onFilesError}
                                                            // accepts={['image/*', '.pdf']}
                                                            multiple={false}
                                                            // maxFiles={5}
                                                            // maxFileSize={10000000}
                                                            minFileSize={0}
                                                            clickable
                                                        >
                                                            {/*documents && documents.length > 0 ? (
                                                                <div className="files-gallery">
                                                                    {documents.map(file => (
                                                                        <img src="/assets/img/file-uploaded.png" />
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                
                                                            )*/}
                                                            <div>upload current reports</div>
                                                        </Files>
                                                    </div>
                                                    {filesErrorMsg1 ? <p style={{color: 'red',marginTop: '10px'}}>{filesErrorMsg1}</p> : ''}

                                                </div>
                                            </div>
                                        </div> : null}
                                </div>
                                }
                            </div>
                            <div className="col-md-6 mb-4 d-flex row ml-auto">
                                <div className="col-md-14">
                                    <p style={{ fontWeight: 700, fontSize: "18px" }}>Radiology or Other investigation</p>
                                </div>
                                {(!ediatble && !followUpData?.radiology?.length) ?  <div className="col-md-12 no-data-div"><h4>Sorry no Data Available</h4></div> : 
                                <div className="col-md-12 lab-investigation-card">
                                    <ul className="list-unstyled" style={{ height: `${ediatble ? '75%' : '100%'}` }}>
                                        {
                                            followUpData && followUpData?.radiology?.length && followUpData.radiology?.map((item, id) => (
                                                <li className="media received" key={id}>
                                                    <div className="media-body">
                                                        <div className="msg-box">
                                                            <div>
                                                                {
                                                                    item.documents && <a
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        href={item.documents}
                                                                        download
                                                                        style={{ color: '#5156be' }}
                                                                        >
                                                                        Click here to show document {id + 1}
                                                                    </a>
                                                                }
                                                                {
                                                                    item.text && <p style={{wordBreak: 'break-word'}}>{item.text}</p>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </li>
                                            ))
                                        }

                                    </ul>
                                    {ediatble ?
                                        <div className="list-footer-div">
                                            <div className="chat-footer">
                                                <div className="input-group">
                                                    {/* <input type="text" className="input-msg-send form-control" placeholder="Type something" value={message} onChange={e => setMessage(e.target.value)} onKeyDown={onEnter} /> */}
                                                    <input style={{ borderRadius: "5px", margin: "0px 10px", maxWidth: "60%" }} type="text" value={radiologyText} className="input-msg-send form-control" placeholder="Type" onChange={(e) => setRadiologyText(e.target.value)} />
                                                    <div className="input-group-append">
                                                        {/* <button type="button" className="btn msg-send-btn" onClick={() => sendMessage()}><i className="fab fa-telegram-plane"></i></button> */}
                                                        <button
                                                            style={{ marginRight: "10px", borderRadius: "5px" }}
                                                            type="button"
                                                            className="btn msg-send-btn"
                                                            onClick={radiologyButton ? hanldeRadiologySubmit : ''}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                    <div className="input-group-prepend" style={{ marginRight: "10px" }}>
                                                        <Files
                                                            className='files-dropzone file-drop-list'
                                                            onChange={onRadioLogyFilesChange}
                                                            style={{cursor: 'pointer'}}
                                                            onError={onFilesError}
                                                            // accepts={['image/*', '.pdf']}
                                                            multiple={false}
                                                            // maxFiles={5}
                                                            // maxFileSize={10000000}
                                                            minFileSize={0}
                                                            clickable
                                                        >
                                                            {/*documents && documents.length > 0 ? (
                                                                <div className="files-gallery">
                                                                    {documents.map(file => (
                                                                        <img src="/assets/img/file-uploaded.png" />
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                               
                                                            )*/}
                                                            <div>upload current reports</div>
                                                        </Files>
                                                    </div>
                                                        {filesErrorMsg2 ? <p style={{color: 'red', marginTop: '10px'}}>{filesErrorMsg2}</p> : ''}
                                                </div>
                                            </div>
                                        </div> : null}
                                </div>}
                            </div>
                        </div>
                        <div className="col-md-14 mb-3">
                            <div className="col-md-14">
                                <p style={{ fontWeight: 700, fontSize: "18px" }}>Nursing Observation/ Questions for Docs</p>
                            </div>
                            <div className="col-md-14 mb-4 d-flex row">
                                <div className="col-md-3 mb-4">
                                    <p style={{ fontWeight: 500, fontSize: "18px" }}>Morning</p>
                                    {(!ediatble && !followUpData?.nursingMorning?.length) ? 
                                <div className="col-md-12 no-data-div">
                                    <h5>Sorry no Data Available</h5>
                                    </div> :
                                    <div className="col-md-12 lab-investigation-card">
                                        <ul className="list-unstyled" style={{ height: `${ediatble ? '75%' : '100%'}` }} ref={nurseMorningRef}>
                                            {
                                                followUpData && followUpData.nursingMorning && followUpData.nursingMorning.map((item, id) => (
                                                    <li className="media received" key={item.id}>
                                                        <div className="media-body">
                                                            <div className="msg-box">
                                                                <div className={`${item.done ? 'todoStrikeThrough' : ''} todoDayTime`}>
                                                                    {
                                                                        item.text && <p>{item.text}</p>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </li>
                                                ))
                                            }

                                        </ul>
                                        {ediatble && !doctorObservation ?
                                            <div className="list-footer-div">
                                                <div className="chat-footer">
                                                    <div className="input-group">
                                                        {/* <input type="text" className="input-msg-send form-control" placeholder="Type something" value={message} onChange={e => setMessage(e.target.value)} onKeyDown={onEnter} /> */}
                                                        <input style={{ borderRadius: "5px", margin: "0px 10px", maxWidth: "60%" }} value={nurseMorning} type="text" className="input-msg-send form-control" placeholder="Type" onChange={e => setNurseMorning(e.target.value)} />
                                                        <div className="input-group-append">
                                                            {/* <button type="button" className="btn msg-send-btn" onClick={() => sendMessage()}><i className="fab fa-telegram-plane"></i></button> */}
                                                            <button style={{ marginRight: "10px", borderRadius: "5px" }} type="button" className="btn msg-send-btn" onClick={hanldeNurseMorningSubmit}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null}
                                    </div>}
                                </div>
                                <div className="col-md-3 mb-4">
                                    <p style={{ fontWeight: 500, fontSize: "18px" }}>Afternoon Time</p>
                                    {(!ediatble && !followUpData?.nursingMorning?.length) ?
                                        <div className="col-md-12 no-data-div">
                                            <h5>Sorry no Data Available</h5>
                                        </div> :
                                        <div className="col-md-12 lab-investigation-card">
                                            <ul className="list-unstyled" style={{ height: `${ediatble ? '75%' : '100%'}` }} ref={nurseAfternoonRef}>
                                                {
                                                    followUpData && followUpData.nursingAfternoon && followUpData.nursingAfternoon.map((item, id) => (
                                                        <li className="media received" key={item.id}>
                                                            <div className="media-body">
                                                                <div className="msg-box">
                                                                    <div className={`${item.done ? 'todoStrikeThrough' : ''} todoDayTime`}>
                                                                        {
                                                                            item.text && <p>{item.text}</p>
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    ))
                                                }

                                            </ul>
                                            {ediatble && !doctorObservation ?
                                                <div className="list-footer-div">
                                                    <div className="chat-footer">
                                                        <div className="input-group">
                                                            {/* <input type="text" className="input-msg-send form-control" placeholder="Type something" value={message} onChange={e => setMessage(e.target.value)} onKeyDown={onEnter} /> */}
                                                            <input style={{ borderRadius: "5px", margin: "0px 10px", maxWidth: "60%" }} type="text" className="input-msg-send form-control" value={nurseAfterNoon} placeholder="Type" onChange={e => setNurseAfterNoon(e.target.value)} />
                                                            <div className="input-group-append">
                                                                {/* <button type="button" className="btn msg-send-btn" onClick={() => sendMessage()}><i className="fab fa-telegram-plane"></i></button> */}
                                                                <button style={{ marginRight: "10px", borderRadius: "5px" }} type="button" className="btn msg-send-btn" onClick={hanldeNurseAfterNoonSubmit}>Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : null}
                                        </div>}
                                </div>
                                <div className="col-md-3 mb-4">
                                    <p style={{ fontWeight: 500, fontSize: "18px" }}>Evening Time</p>
                                    {(!ediatble && !followUpData?.nursingEvening?.length) ? 
                                <div className="col-md-12 no-data-div">
                                    <h5>Sorry no Data Available</h5>
                                    </div> :
                                    <div className="col-md-12 lab-investigation-card">
                                        <ul className="list-unstyled" style={{ height: `${ediatble ? '75%' : '100%'}` }} ref={nurseEveningRef}>
                                            {
                                                followUpData && followUpData.nursingEvening && followUpData.nursingEvening.map((item, id) => (
                                                    <li className="media received" key={item.id}>
                                                        <div className="media-body">
                                                            <div className="msg-box">
                                                                <div className={`${item.done ? 'todoStrikeThrough' : ''} todoDayTime`}>
                                                                    {
                                                                        item.text && <p>{item.text}</p>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </li>
                                                ))
                                            }

                                        </ul>
                                        {ediatble && !doctorObservation ?
                                            <div className="list-footer-div">
                                                <div className="chat-footer">
                                                    <div className="input-group">
                                                        {/* <input type="text" className="input-msg-send form-control" placeholder="Type something" value={message} onChange={e => setMessage(e.target.value)} onKeyDown={onEnter} /> */}
                                                        <input style={{ borderRadius: "5px", margin: "0px 10px", maxWidth: "60%" }} type="text" className="input-msg-send form-control" placeholder="Type" value={nurseEvening} onChange={e => setNurseEvening(e.target.value)} />
                                                        <div className="input-group-append">
                                                            {/* <button type="button" className="btn msg-send-btn" onClick={() => sendMessage()}><i className="fab fa-telegram-plane"></i></button> */}
                                                            <button style={{ marginRight: "10px", borderRadius: "5px" }} type="button" className="btn msg-send-btn" onClick={hanldeNurseEveningSubmit}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null}
                                    </div>}
                                </div>
                                <div className="col-md-3 mb-4">
                                    <p style={{ fontWeight: 500, fontSize: "18px" }}>Night Time</p>
                                    {(!ediatble && !followUpData?.nursingNight?.length) ? 
                                <div className="col-md-12 no-data-div">
                                    <h5>Sorry no Data Available</h5>
                                    </div> :
                                    <div className="col-md-12 lab-investigation-card">
                                        <ul className="list-unstyled" style={{ height: `${ediatble ? '75%' : '100%'}` }} ref={nurseNightRef}>
                                            {
                                                followUpData && followUpData.nursingNight && followUpData.nursingNight.map((item, id) => (
                                                    <li className="media received" key={item.id}>
                                                        <div className="media-body">
                                                            <div className="msg-box">
                                                                <div className={`${item.done ? 'todoStrikeThrough' : ''} todoDayTime`}>
                                                                    {
                                                                        item.text && <p>{item.text}</p>
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </li>
                                                ))
                                            }

                                        </ul>
                                        {ediatble && !doctorObservation ?
                                            <div className="list-footer-div">
                                                <div className="chat-footer">
                                                    <div className="input-group">
                                                        {/* <input type="text" className="input-msg-send form-control" placeholder="Type something" value={message} onChange={e => setMessage(e.target.value)} onKeyDown={onEnter} /> */}
                                                        <input style={{ borderRadius: "5px", margin: "0px 10px", maxWidth: "60%" }} type="text" className="input-msg-send form-control" placeholder="Type" value={nurseNight} onChange={(e) => setNurseNight(e.target.value)} />
                                                        <div className="input-group-append">
                                                            {/* <button type="button" className="btn msg-send-btn" onClick={() => sendMessage()}><i className="fab fa-telegram-plane"></i></button> */}
                                                            <button style={{ marginRight: "10px", borderRadius: "5px" }} type="button" className="btn msg-send-btn" onClick={handleNurseNightSubmit}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : null
                                        }
                                    </div>}
                                </div>
                            </div>
                        </div>
                        {doctorObservation ?
                            <div className="col-md-14 mb-3">
                                <div className="col-md-14">
                                    <p style={{ fontWeight: 700, fontSize: "18px" }}>Doctor Observation/ Questions for Docs</p>
                                </div>
                                <div className="col-md-14 mb-4 d-flex row">
                                    <div className="col-md-3 mb-4">
                                        <p style={{ fontWeight: 500, fontSize: "18px" }}>Morning</p>
                                        {(!ediatble && !followUpData?.doctorMorning?.length) ? 
                                <div className="col-md-12 no-data-div">
                                    <h5>Sorry no Data Available</h5>
                                    </div> :
                                        <div className="col-md-12 lab-investigation-card">
                                            <ul className="list-unstyled" ref={doctorMorningRef}>
                                                {
                                                    followUpData && followUpData.doctorMorning?.map((item, id) => (
                                                        <li className="media received" key={item.id}>
                                                            <div className="media-body">
                                                                <div className="msg-box">
                                                                    <div className={`${item.done ? 'todoStrikeThrough' : ''} todoDayTime`}>
                                                                        {
                                                                            item.text && <p>{item.text}</p>
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                            {ediatble ?
                                                <div className="list-footer-div">
                                                    <div className="chat-footer">
                                                        <div className="input-group">
                                                            {/* <input type="text" className="input-msg-send form-control" placeholder="Type something" value={message} onChange={e => setMessage(e.target.value)} onKeyDown={onEnter} /> */}
                                                            <input style={{ borderRadius: "5px", margin: "0px 10px", maxWidth: "60%" }} type="text" className="input-msg-send form-control" placeholder="Type" value={doctorMorning} onChange={e => setDoctorMorning(e.target.value)} />
                                                            <div className="input-group-append">
                                                                {/* <button type="button" className="btn msg-send-btn" onClick={() => sendMessage()}><i className="fab fa-telegram-plane"></i></button> */}
                                                                <button style={{ marginRight: "10px", borderRadius: "5px" }} type="button" className="btn msg-send-btn" onClick={() => handleDoctorSubmit('morning')}>Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : null}
                                        </div>}
                                    </div>
                                    <div className="col-md-3 mb-4">
                                        <p style={{ fontWeight: 500, fontSize: "18px" }}>Afternoon Time</p>
                                        {(!ediatble && !followUpData?.doctorAfternoon?.length) ? 
                                <div className="col-md-12 no-data-div">
                                    <h5>Sorry no Data Available</h5>
                                    </div> :
                                        <div className="col-md-12 lab-investigation-card">
                                            <ul className="list-unstyled" ref={doctorAfternoonRef}>
                                                {
                                                    followUpData && followUpData.doctorAfternoon?.map((item, id) => (
                                                        <li className="media received" key={item.id}>
                                                            <div className="media-body">
                                                                <div className="msg-box">
                                                                    <div className={`${item.done ? 'todoStrikeThrough' : ''} todoDayTime`}>
                                                                        {
                                                                            item.text && <p>{item.text}</p>
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    ))
                                                }

                                            </ul>
                                            {
                                                ediatble ?
                                                    <div className="list-footer-div">
                                                        <div className="chat-footer">
                                                            <div className="input-group">
                                                                {/* <input type="text" className="input-msg-send form-control" placeholder="Type something" value={message} onChange={e => setMessage(e.target.value)} onKeyDown={onEnter} /> */}
                                                                <input style={{ borderRadius: "5px", margin: "0px 10px", maxWidth: "60%" }} type="text" className="input-msg-send form-control" placeholder="Type" value={doctorAfterNoon} onChange={e => setDoctorAfterNoon(e.target.value)} />
                                                                <div className="input-group-append">
                                                                    {/* <button type="button" className="btn msg-send-btn" onClick={() => sendMessage()}><i className="fab fa-telegram-plane"></i></button> */}
                                                                    <button style={{ marginRight: "10px", borderRadius: "5px" }} type="button" className="btn msg-send-btn" onClick={() => handleDoctorSubmit('afternoon')}>Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : null}
                                        </div>}
                                    </div>
                                    <div className="col-md-3 mb-4">
                                        <p style={{ fontWeight: 500, fontSize: "18px" }}>Evening Time</p>
                                        {(!ediatble && !followUpData?.doctorEvening?.length) ?
                                            <div className="col-md-12 no-data-div">
                                                <h5>Sorry no Data Available</h5>
                                            </div> :
                                            <div className="col-md-12 lab-investigation-card">
                                                <ul className="list-unstyled" ref={doctorEveningRef}>
                                                    {
                                                        followUpData && followUpData.doctorEvening?.map((item, id) => (
                                                            <li className="media received" key={item.id}>
                                                                <div className="media-body">
                                                                    <div className="msg-box">
                                                                        <div className={`${item.done ? 'todoStrikeThrough' : ''} todoDayTime`}>
                                                                            {
                                                                                item.text && <p>{item.text}</p>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                                {ediatble ?
                                                    <div className="list-footer-div">
                                                        <div className="chat-footer">
                                                            <div className="input-group">
                                                                {/* <input type="text" className="input-msg-send form-control" placeholder="Type something" value={message} onChange={e => setMessage(e.target.value)} onKeyDown={onEnter} /> */}
                                                                <input style={{ borderRadius: "5px", margin: "0px 10px", maxWidth: "60%" }} type="text" className="input-msg-send form-control" placeholder="Type" value={doctorEvening} onChange={e => setDoctorEvening(e.target.value)} />
                                                                <div className="input-group-append">
                                                                    {/* <button type="button" className="btn msg-send-btn" onClick={() => sendMessage()}><i className="fab fa-telegram-plane"></i></button> */}
                                                                    <button style={{ marginRight: "10px", borderRadius: "5px" }} type="button" className="btn msg-send-btn" onClick={() => handleDoctorSubmit('evening')}>Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-3 mb-4">
                                        <p style={{ fontWeight: 500, fontSize: "18px" }}>Night Time</p>
                                        {(!ediatble && !followUpData?.doctorNight?.length) ?
                                            <div className="col-md-12 no-data-div">
                                                <h5>Sorry no Data Available</h5>
                                            </div> :
                                            <div className="col-md-12 lab-investigation-card">
                                                <ul className="list-unstyled" ref={doctorNightRef}>
                                                    {
                                                        followUpData && followUpData.doctorNight?.map((item, id) => (
                                                            <li className="media received" key={item.id}>
                                                                <div className="media-body">
                                                                    <div className="msg-box">
                                                                        <div className={`${item.done ? 'todoStrikeThrough' : ''} todoDayTime`}>
                                                                            {
                                                                                item.text && <p>{item.text}</p>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                                {ediatble ?
                                                    <div className="list-footer-div">
                                                        <div className="chat-footer">
                                                            <div className="input-group">
                                                                {/* <input type="text" className="input-msg-send form-control" placeholder="Type something" value={message} onChange={e => setMessage(e.target.value)} onKeyDown={onEnter} /> */}
                                                                <input style={{ borderRadius: "5px", margin: "0px 10px", maxWidth: "60%" }} type="text" className="input-msg-send form-control" placeholder="Type" value={doctorNight} onChange={e => setDoctorNight(e.target.value)} />
                                                                <div className="input-group-append">
                                                                    {/* <button type="button" className="btn msg-send-btn" onClick={() => sendMessage()}><i className="fab fa-telegram-plane"></i></button> */}
                                                                    <button style={{ marginRight: "10px", borderRadius: "5px" }} type="button" className="btn msg-send-btn" onClick={() => handleDoctorSubmit('night')}>Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> : null}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div> : null
                        }
                    </div>
                </div>
                {
                    medicationModalOpen ? <div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
                        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '70%' }} role="document">
                            <div className="modal-content" style={{ maxHeight: "700px", overflowY: "scroll" }}>
                                <div className="col-md-11" style={{ padding: '30px' }}>
                                    <div className="col-md-12 no-padding form-group d-flex">
                                        <h3>Form of medication</h3>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-3 no-padding form-group ">
                                                <label>Medication name:</label>
                                                <InputField
                                                    placeholder="name"
                                                    value={medicationFormData.name}
                                                    onChange={(e) => setmedicationFormData({ ...medicationFormData, name: e.target.value })}
                                                />
                                            </div>


                                            <div className="col-md-3 no-padding form-group strength-type-select">
                                                <label>Form of medicine:</label>
                                                <SelectField
                                                    value={medicationFormData.form}
                                                    onChange={(e) => setmedicationFormData({ ...medicationFormData, form: e.target.value })}
                                                    // name={medicine_typeId}
                                                    options={medicineType}
                                                />
                                            </div>

                                            <div className="col-md-3 no-padding form-group ">
                                                <label>Strength:</label>
                                                <InputField
                                                    placeholder="eg. 100"
                                                    // name={strength_nameId}
                                                    value={medicationFormData.strength}
                                                    onChange={(e) => setmedicationFormData({ ...medicationFormData, strength: e.target.value })}
                                                />
                                            </div>

                                            <div className="col-md-3 no-padding form-group strength-type-select">
                                                <label>Strength type:</label>
                                                <SelectField
                                                    // name={strength_typeId}
                                                    value={medicationFormData.strengthType}
                                                    onChange={(e) => setmedicationFormData({ ...medicationFormData, strengthType: e.target.value })}
                                                    options={strengthType}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-10 mt-5 mb-5 d-flex row" style={{ textAlign: "center" }} >
                                            <div className="col-md-3">
                                                <p style={{whiteSpace: 'nowrap'}}>Mornign Time</p>
                                                <div className="drug-administration-check-circle">
                                                    <label class="container">
                                                        <input className="drug-administration-checkbox checked" type="checkbox" value={medicationFormData.morning} checked={medicationFormData.morning} onChange={e => setmedicationFormData({ ...medicationFormData, morning: e.target.checked })} />
                                                        <span class="drug-admin-checkmark"></span>
                                                    </label>
                                                </div>

                                            </div>
                                            <div className="col-md-3">
                                                <p style={{whiteSpace: 'nowrap'}}>Afternoon Time</p>
                                                <div className="drug-administration-check-circle">
                                                    <label class="container">
                                                        <input className="drug-administration-checkbox checked" type="checkbox" value={medicationFormData.afternoon} checked={medicationFormData.afternoon} onChange={e => setmedicationFormData({ ...medicationFormData, afternoon: e.target.checked })} />
                                                        <span class="drug-admin-checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <p style={{whiteSpace: 'nowrap'}}>Evening Time</p>
                                                <div className="drug-administration-check-circle">
                                                    <label class="container">
                                                        <input className="drug-administration-checkbox checked" type="checkbox" value={medicationFormData.evening} checked={medicationFormData.evening} onChange={e => setmedicationFormData({ ...medicationFormData, evening: e.target.checked })} />
                                                        <span class="drug-admin-checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <p style={{whiteSpace: 'nowrap'}}>Night Time</p>
                                                <div className="drug-administration-check-circle">
                                                    <label class="container">
                                                        <input className="drug-administration-checkbox checked" type="checkbox" value={medicationFormData.night} checked={medicationFormData.night} onChange={e => setmedicationFormData({ ...medicationFormData, night: e.target.checked })} />
                                                        <span class="drug-admin-checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ml-auto mb-5 mr-5">
                                    <button style={{ marginLeft: 'auto', marginTop: '1.75rem' }} className="doctor-active-btn doctor-w-20 ml-auto" onClick={handleFormMedicationSubmit}>Submit </button>
                                    <button
                                        style={{ marginLeft: 'auto', marginTop: '1.75rem' }}
                                        className="doctor-active-btn doctor-w-20 ml-auto"
                                        onClick={() => {
                                            setMedicationModalOpen(false);
                                            handleClearValue();
                                            document.body.style.overflowY = 'auto';
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div> : null
                }
            </div>
        </div>
    )
}

export default FollowUpCarousel
