import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ColoredText, Content, ContentDescription, ContentImage, Event, EventLink, Host, IconWrapper, IFrame, Input, InputWrapper, LeftWisker, Location, MapIcon, Question, RegistrationLinkButton, RightArrowIcon, RightWisker, SearchIcon, SingleQuesWrapper, Time, VideoIcon, WiskerWrapper } from "./styles";
// const Toggle = ({ condition, index, setCondition }) => !condition ? <Expand style={expandStyle} onClick={() => setCondition(index)} /> : <Collapse style={expandStyle} onClick={() => setCondition(index)} />

export function DesktopInputSearch() {
	return (
		<InputWrapper>
			<IconWrapper><SearchIcon /></IconWrapper>
			{/* <Input placeholder='Search event topic, Host ' /> */}
		</InputWrapper>
	)
}

const openInNewTab = (url, e) => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
	if (newWindow) newWindow.opener = null
}

const WiskerText = () => {
	return (
		<WiskerWrapper>
			<LeftWisker />
			<ColoredText>Up Coming</ColoredText>
			<RightWisker />
		</WiskerWrapper>
	)
}

export const SingleQuestion = ({ link, time, event, location, host, online, index }) => {
	// const isMobile = useMediaQuery({
	// 	query: '(min-width: 700px)'
	// })
	// const isDesktop = useMediaQuery({
	// 	query: '(max-width: 1122px)'
	// })
	// const inBetween = isMobile && isDesktop;

	//onClick={(e) => openInNewTab(link, e)}

	const [showModal, setShowModal] = useState(false)

	const videoID = link.split('=')[1];
	return (
		<SingleQuesWrapper>
			<Content>
				<IFrame src={`https://www.youtube.com/embed/${videoID}`} onClick={(e) => openInNewTab(link, e)} />
				<ContentDescription>
					<Time>{time} {(online && !index) ? <WiskerText /> : null}</Time>
					<Event>{event}</Event>
					{online ? <EventLink onClick={(e) => openInNewTab(link, e)}> <VideoIcon />Event Link </EventLink> : <Location>{<MapIcon />}{location}</Location>}
					<Host>{host}</Host>
				</ContentDescription>
			</Content>
			{/* setShowModal(true) */}
			{!online ? <RegistrationLinkButton onClick={(e) => openInNewTab(link, e)}>Event Link  <RightArrowIcon /> </RegistrationLinkButton> : null}

			<div className="modal ContactUsModal custom-modal fade none-border show" style={{
				display: showModal ? 'block' : 'none', backdropFilter: 'blur(1px)',
				background: 'rgb(104 71 99 / 24%)'
			}}>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header pb-0" style={{ flexDirection: 'column', border: 'none', padding: '2.25rem 65px' }}>
							<img
								className="call-avatar mb-2"
								src={"/assets/img/x.png"}
								alt="User"
								style={{ position: 'absolute', right: '18px', top: '18px', cursor: 'pointer' }}
								data-dismiss="modal"
								onClick={(e) => {
									document.body.style.overflowY = 'auto'
									setShowModal(false);
								}}
							/>
							<h4 className="modal-title" style={{ fontWeight: 600, fontSize: '32px', paddingBottom: 25 }}>Coming Soon.</h4>
						</div>
					</div>
				</div>
			</div>

		</SingleQuesWrapper>
	)
}















{/* <div className={inBetween ? "d-flex-important" : "d-flex"}>
				<Question>{question}</Question>
			</div> */}