import React, {useContext, useEffect} from 'react';

import CardComponent from '../../../../../components/Card';
import './patientadd.css'
import AdmissionDetails from './AdmissionDetails';
import PatientDetails from './PatientDetails';
import PatientHistory from './PatientHistory';
import { getWardRoomAPI, getDoctors } from '../../../../../api/organization';
import { Context } from '../../../../../store';
import { toast } from 'react-toastify';
import {
	SET_ERROR,
	SET_COMMON_LOADING,
} from '../../../../../store/types';

import InsideMenuBar from '../../../../../components/InsideMenus';


function IpdWardpatientFlow(props) {
	const [currentTab, SetCurrentTab] = React.useState(1);
	const [state, dispatch] = useContext(Context);
	const [visitedTab, setVisitedTab] = React.useState(1);
	const [doctorsList,setDoctors] = React.useState([]);
	const [wardRoomList,setWardRoomList] = React.useState([]);

	const [patientDetails, setPatientDetails] = React.useState({
		// date: '',
		// timeofAdmission: '',
		admissionDateTime: '',
		roomWard: '',
		mlcNo: '',
		firNo: '',
		referernceName: '',
		referernceContactNo: '',
		doctor: '',
		reason: '',
		diagnosis: '',
		chiefComplaints: [{
			complaints: '',
            duration: ''
		}],
		illessSumary: '',
		// bp: '',
		BPSystolic : '',
		BPDiastolic: '',
		temp : '',
		spo2 : '',
		pulse : '',
		rr  : '',
		generalAppearce : [{
			general: '',
			comments: ''
		}],
		signification: [''],
        familyHistory: [''],
        historyOfAlcohalism: [''],
		doctorName : '',
		roomName:''
	});

	useEffect(() => {
		getWardRoomAPI().then(res=>{
			setWardRoomList(res.data)
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
		})
		getDoctors().then(res => {
			setDoctors(res.data);
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
		})
	}, [])

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}
	const getStatusClass = (item) => {
		if(item === 0) {
			return 'badge badge-pill bg-warning-light'
		} else if(item === 1 || item === 4) {
			return 'badge badge-pill bg-success-blue text-white'
		} else if(item === 2) {
			return 'badge badge-pill bg-danger-light'
		}  else if(item === 3) {
			return 'badge badge-pill bg-danger-light'
		}
	}

	const getStatus = (item) => {
		if(item === 0) {
			return 'Pending'
		} else if(item === 1) {
			return 'Confirm'
		} else if(item === 2) {
			return 'Rejected by you'
		}  else if(item === 3) {
			return 'Rejected by patient'
		}  else if(item === 4) {
			return 'Completed'
		}
	}

	const handletabChange = (tabId) => {
		if(tabId > visitedTab){
			return
		}
		SetCurrentTab(tabId);
	}
	return (
		<div>
			<InsideMenuBar 
				props={props} 
				type={'subadmin'}
				/>
				
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent  className="p-4 mb-4">
								<div className="d-flex mb-4">
									<button onClick={()=>handletabChange(1)} className={currentTab === 1 ? 'doctor-active-btn' : visitedTab>1 ? "doctor-btn-visited" :"doctor-btn-outline"}>
										Admission Details 
									</button>

									<button onClick={() => handletabChange(2)} className={currentTab === 2 ? 'doctor-active-btn' :  visitedTab>2 ? "doctor-btn-visited" :"doctor-btn-outline"}>
										Patient Details 
									</button>

									<button onClick={() => handletabChange(3)} className={currentTab === 3 ?  'doctor-active-btn' :  visitedTab>3 ? "doctor-btn-visited" :"doctor-btn-outline"}>
										 Patient History
									</button>
								</div>

								{
									currentTab === 1 && <AdmissionDetails patientDetails={patientDetails} setPatientDetails={setPatientDetails} SetCurrentTab={SetCurrentTab} setVisitedTab={setVisitedTab} doctorsList={doctorsList} wardRoomList={wardRoomList}/>
								}
								{
									currentTab === 2 && <PatientDetails patientDetails={patientDetails} setPatientDetails={setPatientDetails} SetCurrentTab={SetCurrentTab} setVisitedTab={setVisitedTab}/>
								}
								{
									currentTab === 3 && <PatientHistory {...props} patientDetails={patientDetails} setPatientDetails={setPatientDetails} SetCurrentTab={SetCurrentTab} setVisitedTab={setVisitedTab}/>
								}
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default IpdWardpatientFlow