import React from 'react';

import { Link } from 'react-router-dom';

import CardComponent from '../../../../components/Card'
import InsideMenuBar from '../../../../components/InsideMenus';

function OrgSuccessBooking(props) {
	return (
		<div>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent>
								<div className="success-cont p-5">
									<i className="fas fa-check"></i>
									<h3>Appointment booked Successfully!</h3>
									<p>Appointment booked with <strong>Dr. Sandeeo</strong><br />18/01/2021 on <strong>11.00 AM</strong></p>
									<Link to={"/organization/view-invoice/IN-00001"} className="btn btn-primary view-inv-btn">View Invoice</Link>
								</div>
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OrgSuccessBooking
