import React from 'react';

const PatientSelectModal = (props) => {
    const { setShowModal,handleExistingPatientClick, handleCreateNewPatient } = props;
    return (
        <div class="modal fade call-modal show" id="exampleModalCenterlogout" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <i
                        style={{
                            top: "10",
                            right: "10"
                        }}
                        class="fas fa-times patient-info-modal-close-icon-preview ml-auto" onClick={() => {
                            setShowModal(false);
                            document.body.style.overflowY = 'auto';
                        }}></i>
                    <div class="modal-body col p-5" style={{ fontSize: "20px" }}>
                        <button type="button" class="col-md-12 btn btn-primary" data-dismiss="modal" onClick={handleExistingPatientClick} >Existing patient</button>
                        <button type="button" class="col-md-12 mt-3 btn btn-primary" data-dismiss="modal" onClick={handleCreateNewPatient} >Create a new patient</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PatientSelectModal;