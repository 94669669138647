import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Context } from '../../../../../store';
import { getInvoiceDetailsAPI } from '../../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';
import { getUser } from "../../../../../utils";
import { getPayLogo } from '../../../../../constants';
import { StyledContainer } from './styles'
import InsideMenuBar from '../../../../../components/InsideMenus';

function ViewInvoice(props) {

	const [state, dispatch] = React.useContext(Context);
	const [invoiceData, setInvoiceData] = React.useState(null);
	const [patient, setPatient] = React.useState({});
	const [logo, setLogo] = React.useState('/assets/img/logo.png');
	React.useEffect(() => {
		if(props.match.params && props.match.params.id) { 
			document.body.className = "";
			loader(true);
			const patientInfo = JSON.parse(localStorage.getItem('customer'));
			setPatient(patientInfo);

			getInvoiceDetailsAPI(props.match.params.id).then(res => {
				loader(false);
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				setInvoiceData(res?.data);
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}

		const data = getUser();
		if(data && data.logo) {
			setLogo(data.logo);
		}
	}, [])

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}
	
	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-12 col-lg-12 col-xl-12">
						{
							invoiceData && (
								<div className="invoice-content">
									<div className="invoice-item">
										<div className="row">
											<div className="col-md-6">
												<div className="invoice-logo doc-inv">
													<img src={logo} alt="logo" />
												</div>
											</div>
											<div className="col-md-6">
												<p className="invoice-details">
													<strong>Invoice id:</strong> #{invoiceData.invoiceNumber} <br />
													<strong>Issued:</strong> {moment(invoiceData.createdAt).utc().format('DD/MM/YYYY')}
												</p>
											</div>
										</div>
									</div>
									
									<div className="invoice-item">
										<div className="row">
											<div className="col-md-6">
												<div className="invoice-info">
													{/* <strong className="customer-text">Invoice From</strong>
													<p className="invoice-details invoice-details-two">
														Emed4u <br />
														Shiv nagar colony, opposite Jwala Estate,<br /> Amritsar, Punjab, India <br />
													</p> */}
												</div>
											</div>
											<div className="col-md-6">
												<div className="invoice-info invoice-info2">
													<strong className="customer-text">Invoice To</strong>
													<p className="invoice-details">
														{patient?.name} <br />
													</p>
												</div>
											</div>
										</div>
									</div>
									
									<div className="invoice-item invoice-table-wrap">
										<div className="row">
											<div className="col-md-12">
												<div className="table-responsive">
													<table className="invoice-table table table-bordered">
														<thead>
															<tr>
																<th>Description</th>
																<th className="text-center">Quantity</th>
																<th className="text-right">Total</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>
																	<div>
																		<p>Organization: <span style={{color: 'black', fontWeight: 'bold'}}>{invoiceData.OrganizationId.org_name}</span></p>
																		<p>Doctor: <span style={{color: 'black', fontWeight: 'bold'}}>{invoiceData.doctorId.name} ({invoiceData.doctorId.department})</span></p>
																		<p>Appointment Id: <span  style={{color: 'black', fontWeight: 'bold'}}>{invoiceData.appointmentId._id}</span></p>
																		<p>Appointment type: <span  style={{color: 'black', fontWeight: 'bold'}}>{invoiceData.appointmentId.appointmentType === 0 ? 'Online' : 'Offline' }</span></p>
																		<p>Appointment date: <span  style={{color: 'black', fontWeight: 'bold'}}>{moment(invoiceData.slot.start).utc().format('Do MMMM, YYYY')}</span></p>
																		<p>Appointment timing: <span  style={{color: 'black', fontWeight: 'bold'}}>{moment(invoiceData.slot.start).utc().format('hh:mm a')} - {moment(invoiceData.slot.end).utc().format('hh:mm a')}</span></p>
																		{
																			invoiceData.paymentMode && 
																			<><p>Payment method:<img src={getPayLogo(invoiceData.paymentMode)} height="30px" alt="" style={{paddingRight: '10px', paddingLeft: '10px'}} />{invoiceData.paymentMode}</p></>
																		}
																	</div>
																</td>
																<td className="text-center">1</td>
																<td className="text-right">{invoiceData.amount}</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
											<div className="col-md-6 col-xl-4 ml-auto">
												<div className="table-responsive">
													<table className="invoice-table-two table">
														<tbody>
														<tr>
															<th>Subtotal:</th>
															<td><span>{invoiceData.amount}</span></td>
														</tr>
														<tr>
															<th>Discount:</th>
															<td><span>0%</span></td>
														</tr>
														<tr>
															<th>GST:</th>
															<td><span>0%</span></td>
														</tr>
														<tr>
															<th>Total Amount:</th>
															<td><span>{invoiceData.amount}</span></td>
														</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
									
									<div className="other-info">
										<h4>Other information</h4>
										<p className="text-muted mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sed dictum ligula, cursus blandit risus. Maecenas eget metus non tellus dignissim aliquam ut a ex. Maecenas sed vehicula dui, ac suscipit lacus. Sed finibus leo vitae lorem interdum, eu scelerisque tellus fermentum. Curabitur sit amet lacinia lorem. Nullam finibus pellentesque libero.</p>
									</div>
								</div>	
							)
						}
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default ViewInvoice;
