import React, {useEffect, useContext, useState} from 'react';
import Files from "react-files";

import InputField from '../../../../components/InputField';

import { toast } from 'react-toastify';

import { Context } from '../../../../store';
import {
	postRegisterAPI
} from '../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import { globalValidator, saveUser } from "../../../../utils";

import {
	StyledLeftPanel,
	StyledLeftHeading,
	StyledLeftSubHeading,
	StyledLeftImage,
	StyledRightPanel,
	StyledLoginButton,
	StyledRightBottom,
	StyledBottomContent,
	StyledBottomLink,
	StyledForgotPassword,
	StyledForgotPasswordLink
} from './styles';

function RegisterDoctor(props) {


	const [state, dispatch] = useContext(Context);

	const [orgUser, SetOrgUser] = useState({email: '', password: '', name: ''});
	const [uploadsDocuments , setUploadsDocuments] = useState([]);
    const [filesErrorMsg, setFilesErrorMsg] = React.useState('');
	let fileRef = React.useRef(null);

	const login = (e) => {
		e.preventDefault();
		const { email, password, name } = orgUser;
    const keys = [
			{ name: "name", title: "Name", validateKey: "required" },
      { name: "email", title: "Email", validateKey: "email" },
      { name: "password", title: "Password", validateKey: "password" }
    ];
		let valid = globalValidator({ email, password, name }, keys);

		if(valid === true) {
			if(uploadsDocuments.length > 0) {
				const formData = new FormData();
        Object.keys(orgUser).forEach(key => {
          formData.append(key, orgUser[key]);
        });
        uploadsDocuments.map(doc => {
          formData.append("licence", doc);
				});
				
				loader(true);
				postRegisterAPI(formData).then(res => {
					loader(false);
					// toast.success(res.message)
					dispatch({
						type: SET_ERROR,
						payload: res.message
					});
					props.history.push('/');
				}).catch(err => {
					loader(false);
					toast.error(err.response.data.error)
					dispatch({
						type: SET_ERROR,
						payload: err.response.data.error
					});
				})

			} else {
				toast.error('Please upload CV, Degree, License')
				dispatch({
					type: SET_ERROR,
					payload: 'Please upload CV, Degree, License'
				});
			}
			
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const signinRe = () => {
		props.history.push('/doctor/login');
	}

	const onFilesChange = files => {
		fileRef.state.files= '';
		const fileSize = files.filter((item) => item.size > 5242880)
		if(files?.length > 5){
			setFilesErrorMsg('Maximum 5 files are allowed!')
			fileRef.state.files= '';
			return
		}
		if(fileSize?.length){
			fileRef.state.files= '';
            setFilesErrorMsg('Please upload a file smaller than 5 MB')
            return
        }
    	setUploadsDocuments(files);
		setFilesErrorMsg('')
		fileRef.state.files= '';
  };

	const onFilesError = (error, file) => {};
	

	return (
		<section className="section home-tile-section">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-9 m-auto">
						<div className="row">
              <StyledLeftPanel className="col-md-5">
								<div className="row no-gutters h-100">
									<div className="col-10 col-lg-9 d-flex flex-column mx-auto">
										<StyledLeftHeading className="mt-5 mb-4">Login</StyledLeftHeading>
										<StyledLeftSubHeading>
											To keep connected with us please login with your personal info.
										</StyledLeftSubHeading>
										<div className="mt-auto mb-4">
											<StyledLeftImage src="http://demo.harnishdesign.net/html/oxyy/images/login-vector.png" />
										</div>
									</div>
								</div>
							</StyledLeftPanel>

							<StyledRightPanel className="col-md-7">
								<div className="container my-auto py-5">
									<div className="row">
										<div className="col-11 col-lg-10 mx-auto">

											<form className="form-border info-widget">
												<div className="form-group card-label">
													<label>Name</label>
													<InputField value={orgUser.name} onChange={e => SetOrgUser((user) => ({...user, name: e.target.value}))} />
												</div>

												<div className="form-group card-label">
													<label>Email</label>
													<InputField value={orgUser.email} onChange={e => SetOrgUser((user) => ({...user, email: e.target.value}))} type="email" />
												</div>

												<div className="form-group card-label">
													<label>Password</label>
													<InputField value={orgUser.password} password={true}  type="password" onChange={e => SetOrgUser((user) => ({...user, password: e.target.value}))} />
												</div>

												<div className="form-group">
													<Files
														ref={(fileItem) => fileRef = fileItem}
														className='files-dropzone'
														onChange={onFilesChange}
														onError={onFilesError}
														// accepts={[
														// 	"application/msword",
														// 	"application/vnd.ms-excel",
														// 	"application/vnd.ms-powerpoint",
														// 	".docx",
														// 	"text/plain",
														// 	"application/pdf",
														// 	"image/*"
														// ]}
														multiple
														// maxFiles={5}
														// maxFileSize={10000000}
														minFileSize={0}
														clickable
													>
														{uploadsDocuments.length > 0 ? (
															<div className="files-gallery">
																{uploadsDocuments.map(file => (
																	<img src="/assets/img/file-uploaded.png" style={{width: '50px', height: '50px'}} />
																))}
															</div>
														) : (
															<div>Please select CV, Degree, License </div>
														)}

														
													</Files>
													{filesErrorMsg ? <p style={{color: 'red'}}>{filesErrorMsg}</p> : ''}
												</div>

												<StyledLoginButton onClick={(e) => login(e)}>
													Sign Up Now
												</StyledLoginButton>
											</form>

											<StyledRightBottom>
												<StyledBottomContent onClick={() => signinRe()}>
													I have an account with Emed4u? - 
													<StyledBottomLink>
														Sign In Now
													</StyledBottomLink> 
												</StyledBottomContent>
											</StyledRightBottom>											
										</div>
									
									</div>

								</div>
							
								
							</StyledRightPanel>

            </div>
          </div>
        </div>
		  </div>
	  </section>  
	)
}

export default RegisterDoctor
