import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';

import { confirmAppointmentApi, getDoctorAppointmentApi, getPatientDetailsForDoctorApi, getPatientPreviousAppointmentForDoctorApi } from '../../../services/mainService';
import { confirmAppointmentAction, getDoctorAppointmentAction,  getPatientDetailsForDoctorAction, getPatientPrescriptionAction, getPatientPreviousAppointmentForDoctorAction } from './doctorAppointmentActions';
import { confirmAppointmentFailure, confirmAppointmentRequest, confirmAppointmentSuccess, 
  getPatientDetailsFailure, getPatientDetailsRequest, getPatientDetailsSuccess, 
  getPatientPreviousAppointmentFailure, getPatientPreviousAppointmentRequest, getPatientPreviousAppointmentSuccess, getdoctorAppointmentListFailure, 
  getdoctorAppointmentListRequest, getdoctorAppointmentListSuccess } from './doctorAppointmentSlice';

  //apointment list
function* getDoctorAppointmentList(action) {
  try {
    yield put(getdoctorAppointmentListRequest())  
    const apiResponse = yield call(getDoctorAppointmentApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const data = apiResponse.response.data;
        yield put(getdoctorAppointmentListSuccess(data));

      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(getdoctorAppointmentListFailure(apiResponse.response.message))
      }
    }
  } catch (error) {  }
}

//patient details
function* getPatientDetailsForDoctor(action) {
  try {
    yield put(getPatientDetailsRequest())
    const apiResponse = yield call(getPatientDetailsForDoctorApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const data = apiResponse.response.data;
        console.log('data')
        yield put(getPatientDetailsSuccess(data));

      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(getPatientDetailsFailure(apiResponse.response.message))
      }
    }
  } catch (error) {  }
}

// patient previous appointments
function* getPreviousAppointmentForDoctor(action) {
  try {
    yield put(getPatientPreviousAppointmentRequest())
    const apiResponse = yield call(getPatientPreviousAppointmentForDoctorApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const data = apiResponse.response.data;
        yield put(getPatientPreviousAppointmentSuccess(data));

      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(getPatientPreviousAppointmentFailure(apiResponse.response.message))
      }
      
    }
  } catch (error) {  }
}
//confirm appointment
function* confirmAppointment(action) {
  try {
     yield put(confirmAppointmentRequest()); 
    const apiResponse = yield call(confirmAppointmentApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const listData = apiResponse.response.data;
        yield put(confirmAppointmentSuccess(listData));

      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(confirmAppointmentFailure(apiResponse.response.message))
      }
    }
  } catch (error) { }
}


export function* doctorAppointmentSaga() {
  yield takeLatest(getDoctorAppointmentAction.type, getDoctorAppointmentList);
  yield takeLatest(getPatientDetailsForDoctorAction.type, getPatientDetailsForDoctor);
  yield takeLatest(getPatientPreviousAppointmentForDoctorAction.type, getPreviousAppointmentForDoctor);
  yield takeLatest(confirmAppointmentAction.type, confirmAppointment);

}