import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';
import '../../components/doctor.css';
import {
	addPaymentMethod,
	getAllPaymentMethod,
	removePaymentMethod
} from '../../../../api/doctor';
import { getPayLogo } from '../../../../constants';
import CardComponent from '../../../../components/Card';
import TableComponent from '../../../../components/Table';
import InputField from '../../../../components/InputField';
import SelectField from '../../../../components/SelectField';
import { globalValidator } from "../../../../utils";
import InsideMenuBar from '../../../../components/InsideMenus';

function PaymentMethod(props) {
	const [state, dispatch] = useContext(Context);
	const [payments, setPayments] = useState([]);


	const getPayment = () => {
		loader(true);
		getAllPaymentMethod().then(res => {
			setPayments(res.data);
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}

	useEffect(() => {
		getPayment()
	}, [])

	const tableHeading = [
		{id: 1, title: 'Payment type'},
		{id: 2, title: 'Payment code'},
		{id: 7, title: 'Actions'}
	];

	const [paymentMethod, SetPaymentMethod] = React.useState({
    type: 'Apple pay',
    code: ''
	});
	
	const checkedMethod = payments.map((value) =>  value.type)

	const options = [
		{id: 1, label: 'Apple pay', value: 'Apple pay', disable: checkedMethod.includes("Apple pay")},
		{id: 3, label: 'Amazon pay', value: 'Amazon pay', disable: checkedMethod.includes("Amazon pay")},
		{id: 2, label: 'Google pay', value: 'Google pay', disable: checkedMethod.includes("Google pay")},
		{id: 5, label: 'Paypal', value: 'Paypal', disable: checkedMethod.includes("Paypal")},
		{id: 6, label: 'Phonepe', value: 'Phonepe', disable: checkedMethod.includes("Phonepe")},
		{id: 4, label: 'Samsung pay', value: 'Samsung pay', disable: checkedMethod.includes("Samsung pay")}
	]

	const onDelete = (id) => {
		loader(true);
		removePaymentMethod(id).then(res => {
			loader(false);
			toast.success(res.message);
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
			getPayment()
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}


	const onSubmit = () => {
		const keys = [
      { name: "type", title: "Payment type", validateKey: "required" },
      { name: "code", title: "Payment code", validateKey: "required" },
    ];
		let valid = globalValidator(paymentMethod, keys);
		if(valid === true) {
			loader(true);
      		addPaymentMethod(paymentMethod).then(res => {
				loader(false);
				toast.success(res.message);
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				SetPaymentMethod({
					type: '',
					code: ''
				})
				getPayment()
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<div>
			<InsideMenuBar
				props={props}
				type={'doctor'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4 mb-4">
								<div className='col-md-3 ml-auto'>
									<button data-toggle="modal" data-original-title="test" data-target="#exampleModal" className='doctor-active-btn doctor-w-100 mb-4' style={{textTransform:"unset"}}>
										Add payment method
									</button>
								</div>
								<TableComponent tableHeading={tableHeading}>
									{
										payments.map(item => {
											return <tr>
															<td>
																<img src={getPayLogo(item.type)} height="30px" style={{paddingRight: '20px'}} alt='' />
 																{item.type}
															</td>
															<td>
																{item.code}
															</td>
															<td>
																<div className="table-action">
																	<a href className="btn btn-sm bg-info-light ml-2" onClick={() => onDelete(item._id)}><i className="far fa-trash-alt "></i> </a>
																</div>
															</td>
														</tr>
										})
									}
								</TableComponent>

								<div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
									<div className="modal-dialog" role="document">
										<div className="modal-content">
											<div className="modal-header">
												<h5 className="modal-title" id="exampleModalLabel">Add payment method</h5>
												<button className="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""><span aria-hidden="true">×</span></button>
											</div>

											<div className="modal-body">
												<div className="row pt-3">

													<div className="col-md-12 no-padding form-group ">
														<label>Payment type	</label>
														<SelectField
															onChange={e => SetPaymentMethod((user) => ({...user, type: e.target.value}))}
															value={paymentMethod.type} 
															options={options}
														/>
													</div>

													<div className="col-md-12 no-padding form-group ">
														<label>Payment code</label>
														<InputField value={paymentMethod.code} onChange={e => SetPaymentMethod((user) => ({...user, code: e.target.value}))} />
													</div>

													
												</div>
											</div>

											<div className="modal-footer">
												<button
													className="btn btn-primary"
													type="button"
													data-dismiss="modal"
													data-original-title=""
													title=""
													onClick={() => SetPaymentMethod({
														type: '',
														code: ''
													})}
												>
													Close
												</button>
												<button
													className="btn btn-secondary"
													type="button"
													data-dismiss="modal"
													data-original-title=""
													title=""
													onClick={() => onSubmit()}
												>
													Save
												</button>
											</div>
										</div>
									</div>
								</div>

							</CardComponent>
						</div>
					</div>
				</div>
			</div>	
		</div>
	)
}

export default PaymentMethod
