import React from 'react'
import Slider from "react-slick";

import {
	StyledTestimonialWrapper,
	StyledTestimonialContent
} from './styles';

function Testimonial(props) {
	const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className : 'home-main-slider-full'
	};
	
	return (
		<StyledTestimonialWrapper className="doctor-slider slider m-auto">
			<Slider {...settings}>
				
				<StyledTestimonialContent className="profile-widget col-12 p-4">
					{/* <div className="feature-item m-auto">
						<img className="avatar-img rounded-circle" alt="User Image" src="/assets/img/patients/patient8.jpg" />
					</div> */}
					<div className="pro-content">
						<h3 className="title">
							<a>{props.data[0].name}</a> 
						</h3>
						<p className="speciality">{props.data[0].msg}</p>
						<div className="rating">
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
						</div>
					</div>
				</StyledTestimonialContent>

				<StyledTestimonialContent className="profile-widget col-12 p-4">
					{/* <div className="feature-item m-auto">
						<img className="avatar-img rounded-circle" alt="User Image" src="/assets/img/patients/patient8.jpg" />
					</div> */}
					<div className="pro-content">
						<h3 className="title">
							<a>{props.data[1].name}</a> 
						</h3>
						<p className="speciality">{props.data[1].msg}</p>
						<div className="rating">
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
						</div>
					</div>
				</StyledTestimonialContent>


				<StyledTestimonialContent className="profile-widget col-12 p-4">
					{/* <div className="feature-item m-auto">
						<img className="avatar-img rounded-circle" alt="User Image" src="/assets/img/patients/patient8.jpg" />
					</div> */}
					<div className="pro-content">
						<h3 className="title">
							<a>{props.data[2].name}</a> 
						</h3>
						<p className="speciality">{props.data[2].msg}</p>
						<div className="rating">
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
						</div>
					</div>
				</StyledTestimonialContent>
		
				

			</Slider>
		</StyledTestimonialWrapper>
	)
}

export default Testimonial
