import React, { useEffect, useMemo, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";

import styled from "styled-components";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import moment from "moment";
import colors from "../../../constant/colors";

const StyledSlotsPaper = styled(Paper)(() => ({
  boxShadow: `-4px -4px 15px ${colors.shadowGradient}, 4px 4px 15px ${colors.shadowGradient}`,
  "& .MuiGrid-container": {
    width: "100%",
    marginLeft: 0,
    marginTop: 0,
    "& .MuiGrid-item": {
      paddingLeft: "8px",
      paddingTop: "8px",
      paddingBottom: "8px",
      "& .MuiBox-root": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px dashed #5E5E5E",
        borderRadius: "8px",
        width: "140px",
        height: "40px",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "18px",
        textTransform: "capitalize",
        color: "#5E5E5E",
      },
    },
    "& .selected": {
      cursor: "pointer",
      "& .MuiBox-root": {
        background:
          "linear-gradient(90deg, rgba(9, 126, 183, 0.2) 0%, rgba(42, 171, 108, 0.2) 100%)",
        "& span": {
          background: "linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)",
          "-webkit-background-clip": "text",
          "-webkit-text-fill-color": "transparent",
          backgroundClip: "text",
          textFillColor: "transparent",
        },
      },
    },
  },
}));

const AvailableSlotsBlock = ({ slotId, slot }) => {
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);

  const handleSlotClick = (item, index) => {
    setSelectedSlotIndex(index);
    slotId(item);
  };

  const { doctorSlotList, loading } = useSelector((state) => ({
    doctorSlotList: state.appointment.doctorSlotList,
    loading: state.appointment.loading,
  }));

  const doctorslotlist = useMemo(() => {
    setSelectedSlotIndex(null);
    return doctorSlotList;
  }, [doctorSlotList]);

  return (
    <StyledSlotsPaper
      elevation={1}
      sx={{ borderRadius: "8px", width: "100%" }}
    >
      <div style={{minHeight: '320px', maxWidth: '320px', overflowX: 'auto'}}>
      <Grid container spacing={0} sx= {{maxHeight: '320px'}} >
        {loading ? <Grid item>Loading...</Grid> :
          doctorslotlist.length > 0 ?
            doctorslotlist.map((item, index) => {
              const availableTimeSlot1 = moment(item?.start_time).format('h:mm A')
              const availableTimeSlot2 = moment(item?.end_time).add(1, 'seconds').format('h:mm A')
              const isSelected = slot?._id == item._id;
              return (
                <Grid item 
                  className={`${selectedSlotIndex === index || isSelected ? "selected" : ""}`}
                  onClick={() => handleSlotClick(item, index)}>
                  <Box style={{ cursor: 'pointer' }}>
                    <span>{`${availableTimeSlot1} -${availableTimeSlot2}`}</span>
                  </Box>
                </Grid>
              );
            }) : <Grid container style={{ padding: 8 }}> No slots available</Grid>}
      </Grid>
      </div>
    </StyledSlotsPaper>
  );
};

export default React.memo(AvailableSlotsBlock);
