import moment from 'moment'; 

export const tabName = (name) =>  {
    switch (name) {
        case 'Blood pressure':
            return 'all_Bp';
        case 'Blood sugar':
            return 'all_Bsugar';
        case 'Oxygen Saturation':
            return 'all_OxygenLevel';
        case 'Pulse':
            return 'all_Pluse';
        case 'Respiratory rate':
            return 'all_RespiratoryRates';
        case 'Temperature':
            return 'all_Temperature';
        case 'Exercise':
            return 'all_Exercise';
        case 'Nutrition':
            return 'all_Food';
        case 'BMI':
            return 'bmiall';
        case 'Waist circumference':
            return 'all_Waist';
        case 'Weight':
            return 'all_Weight';
        case 'Lipid Profile':
            return 'all_Cholesterol';
        case 'Joint Movements':
            return 'all_Joint';
        case 'Pain level':
            return 'all_Pain';
        default:
            return null;
    }
}


export const menus1 = [
	{id: 1, name: 'Blood pressure'},
	{id: 9, name: 'Pulse'},
	{id: 7, name: 'Oxygen Saturation'},
	{id: 10, name: 'Respiratory rate'},
	{id: 2, name: 'Blood sugar'},
	{id: 3, name: 'Temperature'}
];

export const menus2 = [
	{id: 1, name: 'Lipid Profile'}
];

export const menus3 = [
	{id: 1, name: 'Joint Movements'},
	{id: 2, name: 'Pain level'}
];

export const menus4 = [
	{id: 1, name: 'Weight'},
	{id: 6, name: 'BMI'},
	{id: 2, name: 'Waist circumference'},
	{id: 3, name: 'Exercise'},
	{id: 4, name: 'Nutrition'},
	{id: 5, name: 'Sleep'},
];

export const menusLineChart = [
	{id: 1, value: '1D', key: 'todaysData'},
	{id: 2, value: '3D', key: 'threeDaysData'},
	{id: 3, value: '1W', key: 'oneWeeksData'},
	{id: 4, value: '1M', key: 'oneMonthData'},
	{id: 5, value: '3M', key: 'threeMonthData'},
	{id: 6, value: '6M', key: 'sixMonthData'},
];


export const determineLabel = (name) => {
    switch(name){
        case 'all_Bsugar':
            return {label: 'Blood sugar' , color: '#FEF862', graphData: 'how_much'}
        case 'all_OxygenLevel':
            return {label: 'Oxygen Saturation %' , color: '#FEF862', graphData: 'level'}
        case 'all_Pluse':
            return {label: 'Pulse per min' , color: '#EBC2FC', graphData: 'pluse'}
        case 'all_RespiratoryRates':
            return {label: 'Respiratory Rate (breaths/min)' , color: '#D99138', graphData: 'respiratory_rates'}
        case 'all_Temperature':
            return {label: 'Temperature' , color: '#6DFAFB', graphData: 'temperature'}
        case 'all_Food':
            return {label: 'Nutrition (duration)' , color: 'rgba(1, 59, 97, 1)', graphData: 'duration'}
        case 'bmiall':
            return {label: 'BMI' , color: 'rgba(1, 59, 97, 1)'}
        case 'all_Waist':
            return {label: 'Waist circumference (cm)' , color: 'rgba(1, 59, 97, 1)', graphData: 'waist'}
        case 'all_Weight':
            return {label: 'Weight' , color: 'rgba(1, 59, 97, 1)', graphData: 'weight'}
        default:
            return null;
    }
}

export const getDoubleLineChart = (value, setLineData) => {
    var lineData = {};
    var dates = [];
    var times = [];
    var sys_BP = [];
    var dia_BP =[];
    let h_Details = value;
        
            let h_len = Object.keys(h_Details).length

            for (var i =0; i<h_len; i++){
                         
                var time_Stamp = new Date(h_Details[i].createdAt);
             
                     
                dates[i] = moment(h_Details[i].createdAt).format('hh:mm A, DD/MM/YY');//(time_Stamp.toISOString()).substr(0,10);
                times[i] = (time_Stamp.toISOString()).substr(11,5);
                sys_BP.push(parseInt(h_Details[i].sys_blood_pressure));
                dia_BP.push(parseInt(h_Details[i].dia_blood_pressure));
        
            }

            lineData = {
                labels: dates,
                datasets: [
                    {
                        label: "Systolic",
                        data: sys_BP,
                        fill: false,
                        borderColor: "brown"
                    },
                    {
                        label: "Diastolic",
                        data: dia_BP,
                        fill: false,
                        borderColor: "rgba(1, 59, 97, 1)"
                    }
                ]
            };
            setLineData(lineData);
}

const getBMIValue = (value) => {
    var height = parseInt(value.height);
    var weight = parseInt(value.weight);
    
    let bmi =  (weight / height) / height;
    let bmiValue = parseInt(parseFloat(bmi*10000).toFixed(2));
    
    if(bmiValue < 18.5) {
        return bmiValue;
    } else if(bmiValue >= 18.5 && bmiValue < 24.9) {
        return bmiValue;
    } else if(bmiValue >= 25 && bmiValue < 29.9) {
        return bmiValue;
    }else if(bmiValue >= 30) {
        return bmiValue;
    }
}


export const getSingleLineChart = (value, obj, type) => {
    var dates = [];
    var sys_BP = [];
    if(type === 'bmiall'){
        value.map(item => {
            dates.push(moment(item.createdAt).format('DD/MM/YY'))
            sys_BP.push(getBMIValue(item));
            return item;
        })
    } else {
        var times = [];
        let h_Details = value;
    
        let h_len = Object.keys(h_Details).length

        for (var i =0; i<h_len; i++){
            var time_Stamp = new Date(h_Details[i].createdAt);
            dates[i] = moment(h_Details[i].createdAt).format('hh:mm A, DD/MM/YY');
            times[i] = (time_Stamp.toISOString()).substr(11,5);
            sys_BP.push((h_Details[i][obj.graphData]));
        }
    }

    
    var ctx = document.getElementById(type).getContext('2d')
    var chartOptions = {
        scales: {
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: obj.label,
                    fontSize:10,
                    fontColor:'black'
                },
                gridLines:{},
                stacked:true,
                ticks: {
                    fontColor:'black',
                    fontSize: 10,
                    beginAtZero: false,
                    stepSize :20,
                }
            }],
            xAxes: [{
                stacked:true,
                scaleLabel: {
                    display: true,
                    labelString: 'Time/Date',
                    fontSize:10,
                    fontColor:'black'
                },
                gridLines:{
                    display:false
                },
                ticks: {
                    fontColor:'black',
                    fontSize: 10,
                    beginAtZero: true,
                }
            }]
        },
        legend: {
        display: true,
        position: 'top',
        labels: {
            boxWidth: 80,
            fontColor: 'black'
        }
    }          
};

    var dataFirst = {
        label: obj.label,
        data: sys_BP,
        lineTension: 0,
        fill: false,
        borderColor: obj.color
    };

    var bpData = {
        labels: dates,
        datasets: [dataFirst]
    };

    setTimeout(() => {
        new window.Chart(ctx, {
            type: 'line',
            data: bpData,
            options: chartOptions
        });
        }, 1000)
}

export const addData = (name, data, setData, addDetails, clearSearch) => {
    clearSearch();
    switch(name){
        case 'Blood pressure':
            return addDetails(data, 'bp_detail', setData);
        case 'Blood sugar':
            return addDetails(data,'add_Bsugar', setData);
        case 'Oxygen Saturation':
            return addDetails(data,'add_OxygenLevel', setData);
        case 'Pulse':
            return addDetails(data,'add_Pluse', setData);
        case 'Respiratory rate':
            return addDetails(data,'add_RespiratoryRates', setData);
        case 'Temperature':
            return addDetails(data,'add_Temperature', setData);
        case 'Exercise':
            return addDetails(data,'add_Exercise', setData);
        case 'Nutrition':
            return addDetails(data,'add_Food', setData);
        case 'BMI':
            return addDetails(data,'bmi-calculate', setData);
        case 'Waist circumference':
            return addDetails(data,'add_Waist', setData);
        case 'Weight':
            return addDetails(data,'add_Weight', setData);
        case 'Lipid Profile':
            return addDetails(data,'add_Cholesterol', setData);
        case 'Joint Movements':
            return addDetails(data,'add_Joint', setData);
        case 'Pain level':
            return addDetails(data,'add_Pain', setData);
        default:
            return null;
    }
}

export const onChangeDuration = (value, returnData) => {
    var today = moment().format();
    var threeDays = moment().subtract(3, "days").format('YYYY-MM-DD');
    var sevenDays = moment().subtract(7, "days").format('YYYY-MM-DD');
    var oneMonth = moment().subtract(1, "months").format();
    var threeMonth = moment().subtract(3, "months").format();
    var sixMonth = moment().subtract(6, "months").format();
    
    switch(value){
        case 'todaysData':
            return returnData(today)
        case 'threeDaysData':
            return returnData(threeDays)
        case 'oneWeeksData':
            return returnData(sevenDays)
        case 'oneMonthData':
            return returnData(oneMonth)
        case 'threeMonthData':
            return returnData(threeMonth)
        case 'sixMonthData':
            return returnData(sixMonth)
        default: 
            return null;				
    }
}


export const getBmiTitle = (value) => {
		
    var height = parseInt(value?.height);
    var weight = parseInt(value?.weight);

    let bmi =  (weight / height) / height;
    let bmiValue = parseInt(parseFloat(bmi*10000).toFixed(2));
    
    if(bmiValue < 18.5) {
        return `Underweight (${bmiValue})`;
    } else if(bmiValue >= 18.5 && bmiValue < 24.9) {
        return `Normal weight (${bmiValue})`;
    } else if(bmiValue >= 25 && bmiValue < 29.9) {
        return `Overweight (${bmiValue})`;
    }else if(bmiValue >= 30) {
        return `Obesity (${bmiValue})`;
    }
}

export const getBmiClass = (value) => {
    var height = parseInt(value.height);
    var weight = parseInt(value.weight);
    
    let bmi =  (weight / height) / height;
    let bmiValue = parseInt(parseFloat(bmi*10000).toFixed(2));
    
    if(bmiValue < 18.5) {
        return "under-bmi";
    } else if(bmiValue >= 18.5 && bmiValue < 24.9) {
        return "success-bmi";
    } else if(bmiValue >= 25 && bmiValue < 29.9) {
        return "over-bmi";
    }else if(bmiValue >= 30) {
        return "obesity-bmi";
    }
}

export const deleteData = (id, name, deleteItem) => {
    switch (name) {
        case 'Blood pressure':
            return deleteItem(id, 'bp');
        case 'Blood sugar':
            return deleteItem(id, 'bsugar');
        case 'Oxygen Saturation':
            return deleteItem(id, 'oxygen_level');
        case 'Pulse':
            return deleteItem(id, 'pluse');
        case 'Respiratory rate':
            return deleteItem(id, 'respiratory_rate');
        case 'Temperature':
            return deleteItem(id, 'temperature');
        case 'Exercise':
            return deleteItem(id, 'exercise');
        case 'Nutrition':
            return deleteItem(id, 'food');
        case 'BMI':
            return deleteItem(id, 'height');
        case 'Waist circumference':
            return deleteItem(id, 'waist');
        case 'Weight':
            return deleteItem(id, 'weight');
        case 'Lipid Profile':
            return deleteItem(id, 'cholesterol');
        case 'Joint Movements':
            return deleteItem(id, 'joint');
        case 'Pain level':
            return deleteItem(id, 'pain');
        default:
            return null;
    }
}

export const bpData = {
    showChart: true,
    isDoubleLineChart: true,
    inputFields: [  
        {
            id: 1, label:'Systolic (mmHg)', name:'sys_blood_pressure', validation:'systolic',   
        },
        {
            id: 2, label:'Diastolic (mmHg)', name:'dia_blood_pressure', validation:'diastolic',   
        },
        {
            id: 3, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Systolic (mmHg):',valueTobeDisplayed:'sys_blood_pressure' 
        },
        {
            id: 2, label:'Diastolic (mmHg):', valueTobeDisplayed:'dia_blood_pressure'
        },
        {
            id: 3, label:'Notes:', valueTobeDisplayed:'notes'
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
}  

const options = [
    { id: 1, label: 'Fasting', value: 'Fasting' },
    { id: 2, label: '2 hours Post Breakfast', value: '2 hours Post Breakfast' },
    { id: 1, label: 'Pre Lunch', value: 'Pre Lunch' },
    { id: 1, label: '2 hours Post Lunch', value: '2 hours Post Lunch' },
    { id: 1, label: 'Pre Dinner', value: 'Pre Dinner' },
    { id: 1, label: '2 hours Post Dinner', value: '2 hours Post Dinner' },
    { id: 1, label: 'Bed Time', value: 'Bed Time' },
    { id: 1, label: '3 AM', value: '3 AM' },
    { id: 1, label: 'Random', value: 'Random' }
];

export const bloodSugarData = {
    showChart: true,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Reading Type', name:'blood_sugar', selectType: true, options: options
        }, 
        {
            id: 2, label:'Blood sugar (mg/dl)', name:'how_much',
        },
        {
            id: 3, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Blood sugar (mg/dl):', valueTobeDisplayed:'how_much',
        },
        {
            id: 2, label:'Reading Type:', valueTobeDisplayed:'blood_sugar',
        }, 
        {
            id: 3, label:'Notes:', valueTobeDisplayed:'notes',   
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
} 


const oxygenOptions = [
    { id: 1, label: 'Pulse Oximeter Method', value: 'Pulse Oximeter Method' },
    { id: 2, label: 'Arterial Blood Gas', value: 'Arterial Blood Gas' }
]

export const oxygenData = {
    showChart: true,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Reading method', name:'reading_method', selectType: true, options: oxygenOptions
        }, 
        {
            id: 2, label:'Level (%)', name:'level', validation: 'oxygen',
        },
        {
            id: 3, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Level (%):', valueTobeDisplayed:'level',
        }, 
        {
            id: 2, label:'Reading method:', valueTobeDisplayed:'reading_method',
        },
        {
            id: 3, label:'Notes:', valueTobeDisplayed:'notes',   
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
}  

export const PulseData = {
    showChart: true,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Pulse per min', name:'pluse', validation: 'pulse',
        }, 
        {
            id: 2, label:'Location', name:'location',
        },
        {
            id: 3, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Pulse:', valueTobeDisplayed:'pluse',
        }, 
        {
            id: 2, label:'Location:', valueTobeDisplayed:'location',
        },
        {
            id: 3, label:'Notes:', valueTobeDisplayed:'notes',   
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
} 

export const respiratoryData = {
    showChart: true,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Respiratory rate', name:'respiratory_rates', validation: 'respiratory'
        }, 
        {
            id: 2, label:'Notes', name:'notes',   
        }
       ],
    showData: [
        {
            id: 1, label:'Respiratory Rate (breaths/min):', valueTobeDisplayed:'respiratory_rates',
        }, 
        {
            id: 2, label:'Notes:', valueTobeDisplayed:'notes',
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
} 

const tempOptions = [
    { id: 1, label: 'Celsius', value: 'Celsius' },
// {id: 2, label : 'Fahrenheit', value: 'Fahrenheit'}
]

export const tempData = {
    showChart: true,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Temperature', name:'temperature', validation: 'temperature'
        }, 
        {
            id: 2, label:'Temperature Unit', name:'temperature_unit', selectType: true, options: tempOptions
        },
        {
            id: 3, label:'Notes', name:'notes',   
        }
       ],
    showData: [
        {
            id: 1, label:'Temperature:', valueTobeDisplayed:'temperature',
        }, 
        {
            id: 2, label:'Temperature Unit:', valueTobeDisplayed:'temperature_unit',
        },
        {
            id: 3, label:'Notes:', valueTobeDisplayed:'notes',
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
} 

export const lipidData = {
    showImage: true,
    url: '/assets/img/chole.jpeg',
    showChart: false,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Total Cholesterol (mg/dl)', name:'total_cholesterol'
        }, 
        {
            id: 2, label:'HDL-C (mg/dl)', name:'HDL_C',
        },
        {
            id: 3, label:'LDL_C', name:'LDL_C'
        }, 
        {
            id: 4, label:'Triglycerides (mg/dl)', name:'triglycerides',
        },
        {
            id: 5, label:'VLDL (mg/dl)', name:'VLDL',
        },
        {
            id: 5, label:'Notes', name:'notes',   
        }
       ],
    showData: [
        {
            id: 1, label:'Total Cholesterol (mg/dl):', valueTobeDisplayed:'total_cholesterol',
        }, 
        {
            id: 2, label:'HDL-C (mg/dl):', valueTobeDisplayed:'HDL_C',
        },
        {
            id: 3, label:'Triglycerides (mg/dl):', valueTobeDisplayed:'triglycerides',
        }, 
        {
            id: 5, label:'VLDL (mg/dl)', valueTobeDisplayed:'VLDL',
        },
        {
            id: 5, label:'Notes:', valueTobeDisplayed:'notes',
        },
        {
            id: 6, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
}

const jointOptions = [
    { id: 1, label: 'No joint stiffness', value: 'No joint stiffness' },
    { id: 2, label: 'Slight joint stiffness', value: 'Slight joint stiffness' },
    { id: 3, label: 'Moderate joint stiffness - pain affecting regular movement', value: 'Moderate joint stiffness - pain affecting regular movement' },
    { id: 4, label: 'Significant joint stiffness - able to move but with lot of pain', value: 'Significant joint stiffness - able to move but with lot of pain' },
    { id: 5, label: 'Severe joint stiffness - almost unable to move. Severe pain', value: 'Severe joint stiffness - almost unable to move. Severe pain' },
]

export const jointData = {
    showChart: false,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Joint stiffness level', name:'level', selectType: true, options: jointOptions,
        }, 
        {
            id: 2, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Joint stiffness level:', valueTobeDisplayed:'level',
        }, 
        {
            id: 2, label:'Notes:', valueTobeDisplayed:'notes',   
        },
        {
            id: 3, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
}

const painOptions = [
    { id: 1, label: 'No Pain', value: 'No Pain' },
    { id: 2, label: 'Slight joint stiffness', value: 'Slight joint stiffness' },
    { id: 3, label: 'Minor - Very Mild', value: 'Minor - Very Mild' },
    { id: 4, label: 'Minor - Discomforting', value: 'Minor - Discomforting' },
    { id: 5, label: 'Minor - Tolerable', value: 'Minor - Tolerable' },
    { id: 6, label: 'Moderate - Distressing', value: 'Moderate - Distressing' },
    { id: 7, label: 'Moderate - Very Distressing', value: 'Moderate - Very Distressing' },
    { id: 8, label: 'Moderate - Intense', value: 'Moderate - Intense' },
    { id: 9, label: 'Severe - Very Intense', value: 'Severe - Very Intense' },
    { id: 10, label: 'Severe - Utterly Horrible', value: 'Severe - Utterly Horrible' },
    { id: 11, label: 'Severe - Unbearable', value: 'Severe - Unbearable' },
    { id: 12, label: 'Severe - Unimaginable', value: 'Severe - Unimaginable' }
]


export const painData = {
    showChart: false,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Pain level', name:'intensity', selectType: true , options: painOptions,
        }, 
        {
            id: 2, label:'Duration (min)', name:'duration',
        },
        {
            id: 3, label:'Location', name:'location',
        },
        {
            id: 4, label:'Trigger', name:'trigger',
        },
        {
            id: 5, label:'Relief', name:'relief',
        },
        {
            id: 6, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Pain level:', valueTobeDisplayed:'pluse',
        }, 
        {
            id: 2, label:'Duration (min):', valueTobeDisplayed:'duration',
        },
        {
            id: 3, label:'Location', valueTobeDisplayed:'location',
        },
        {
            id: 4, label:'Trigger', valueTobeDisplayed:'trigger',
        },
        {
            id: 5, label:'Relief', valueTobeDisplayed:'relief',
        },
        {
            id: 6, label:'Notes:', valueTobeDisplayed:'notes',   
        },
        {
            id: 7, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
} 

const exerciseOptions = [
    { id: 1, label: 'Brisk Walk', value: 'Brisk Walk' },
    { id: 2, label: 'Regular Walk', value: 'Regular Walk' },
    { id: 3, label: 'Jogging', value: 'Jogging' },
    { id: 4, label: 'Yoga', value: 'Yoga' },
    { id: 5, label: 'Aerobics', value: 'Aerobics' },
    { id: 6, label: 'Swimming', value: 'Swimming' },
    { id: 7, label: 'Cycling', value: 'Cycling' },
    { id: 8, label: 'Other', value: 'Other' }
]


export const exerciseData = {
    showChart: false,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Exercise Type', name:'type', selectType: 'pulse',options: exerciseOptions,
        }, 
        {
            id: 2, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Duration (min):', valueTobeDisplayed:'duration',
        }, 
        {
            id: 2, label:'Exercise Type:', valueTobeDisplayed:'type',
        },
        {
            id: 3, label:'Notes:', valueTobeDisplayed:'notes',   
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
} 

export const nutritionData = {
    showChart: true,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Food Item', name:'type',
        },
        {
            id: 2, label:'Timing', name:'duration',
        },
        {
            id: 3, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Food Item:', valueTobeDisplayed:'type',
        }, 
        {
            id: 2, label:'Timing:', valueTobeDisplayed:'duration',
        },
        {
            id: 3, label:'Notes:', valueTobeDisplayed:'notes',   
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
}

export const bmiData = {
    showChart: true,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Height (cm)', name:'height',
        },
        {
            id: 2, label:'Weight (kg)', name:'weight',
        },
       ],
    showData: [
        {
            id: 1, label:'BMI:', valueTobeDisplayed:'height',
        },
        {
            id: 2, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
}


export const waistData = {
    showChart: true,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Waist measurement (cm)', name:'waist',
        },
        {
            id: 2, label:'Notes', name:'notes',
        },
       ],
    showData: [
        {
            id: 1, label:'Waist measurement (cm):', valueTobeDisplayed:'waist',
        },
        {
            id: 2, label:'Notes:', valueTobeDisplayed:'notes',
        },
        {
            id: 2, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
}

export const weightData = {
    showChart: true,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Weight (kg)', name:'weight',
        },
        {
            id: 2, label:'Notes', name:'notes',
        },
       ],
    showData: [
        {
            id: 1, label:'Weight (kg):', valueTobeDisplayed:'weight',
        },
        {
            id: 2, label:'Notes:', valueTobeDisplayed:'notes',
        },
        {
            id: 2, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
}


export const renderData = (name) => {
    switch(name) {
        case 'Blood pressure':
            return bpData;
        case 'Blood sugar':
            return bloodSugarData;
        case 'Oxygen Saturation':
            return oxygenData;
        case 'Pulse':
            return PulseData;
        case 'Respiratory rate':
            return respiratoryData;
        case 'Temperature':
            return tempData
        case 'Lipid Profile':
            return lipidData;
        case 'Pain level':
            return painData;
        case 'Joint Movements':
            return jointData;
        case 'Weight':
            return weightData;
        case 'BMI':
            return bmiData;
        case 'Waist circumference':
            return waistData;
        case 'Exercise':
            return exerciseData;
        case 'Nutrition':
            return nutritionData;
        case 'Sleep':
            return [];
        default:
            return [];
    }
}

