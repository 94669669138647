import { CircleContainer, RadioItem, RadioItemCirlce, RadioItemCirlceFilled, RadioItemText, WhiteCirlce } from "./styles"

const Filled = () => {
    return (
        <RadioItemCirlceFilled>
            <WhiteCirlce />
        </RadioItemCirlceFilled>
    )
}

export const SingleRadio = ({index = 0, selectedOption, setSelectedOption }) => {
    return (
        <RadioItem onClick={() => setSelectedOption(index)}>
            <RadioItemText>{index}</RadioItemText>
            <CircleContainer>
                {selectedOption !== index ? <RadioItemCirlce /> : <Filled />}
            </CircleContainer>
        </RadioItem>
    )
}