import React, { useState } from 'react'
import { Content, ContentContainer, ContentWrapper, EmptyCircle, FilledCircle, HoverCard, ImageContainer, ImageWrapper, ItemWrapper, LightText, Line, ListWrapper, Pointer, PointerWrapper, Title, Image, MainDiv } from './styled'
import { isMobile } from "react-device-detect";

const dataText = (year) => {
	switch (year) {
		case 2018:
			return 'It started with four directors coming together with a shared vision of bringing healthcare reform worldwide.'
		case 2019:
			return 'The team expanded and created a digital platform where patients could receive second opinions from qualified doctors worldwide.'
		case 2020:
			return 'We began introducing innovative healthcare products and raising awareness on various social media channels. We organized open health forums, webinars, and events that served as reliable sources of health information.'
		case 2021:
			return 'To reach our goal of creating a digital healthcare ecosystem, we diligently began developing our healthcare management software and integrating it with healthcare wearables.'
		case 2022:
			return 'Launched our HMS (healthcare management software)'
		default:
			break;
	}
}

const data = [
	{ idx: 0, date: 2018, title: 'The Start', content: dataText(2018) },
	{ idx: 1, date: 2019, title: 'Member', content: dataText(2019) },
	{ idx: 2, date: 2020, title: 'Adding', content: dataText(2020) },
	{ idx: 3, date: 2021, title: 'Team Building', content: dataText(2021) },
	{ idx: 4, date: 2022, title: 'Development', content: dataText(2022) },
]



export const TimeLine = ({ index }) => {

	const [selectedYear, setSelectedYear] = useState(2020)

	const HoverLeft = ({ content, title }) => {
		return (
			<HoverCard>
				<PointerWrapper><Pointer /></PointerWrapper>
				<ContentContainer>
					<ImageContainer src="/assets/img/Frame57.png" alt='logo' />
					<ContentWrapper>
						<Title>{title}</Title>
						<Content>{content}</Content>
					</ContentWrapper>
				</ContentContainer>

			</HoverCard>
		)
	}


	const Items = ({ date, title, content, check, turn }) => {
		const condition = turn === 0;
		return (
			<ItemWrapper onClick={() => setSelectedYear(date)} check={check}>
				<LightText className={check && 'increase-size'} turn={condition}>{date}</LightText>
				<LightText turn={condition}>{check ? <FilledCircle /> : <EmptyCircle />}</LightText>
				{!check ? <LightText>{title}</LightText> : <HoverLeft title={title} content={content} />}
			</ItemWrapper>
		)
	}
	return (
		<>
			<MainDiv>
				<Line />
				<ListWrapper>
					{data.map(({ date, title, content, idx }) => {
						return <Items
							key={idx}
							date={date}
							title={title}
							content={content}
							check={selectedYear === date}
							turn={index}
						/>
					})}
				</ListWrapper>
			</MainDiv>
			<ImageWrapper>
				<Image src="/assets/img/vector-back.png" alt='logo' active={selectedYear !== 2018} onClick={() => selectedYear !== 2018 && setSelectedYear(selectedYear - 1)} />
				<Image src="/assets/img/vector-next.png" alt='logo' active={selectedYear !== 2022} onClick={() => selectedYear !== 2022 && setSelectedYear(selectedYear + 1)} />
			</ImageWrapper>
		</>

	)
}
