import { makeStyles } from "@mui/styles";
import colors from "../../../constant/colors";
const useLipidProfileStyles = makeStyles((theme) => ({
  paper: {
    height: 266,
    width: 240,
    // padding: 20,
    margin: 8
  },
  secondaryCont: {
    background: colors.white1,
    padding: "8px 0px 8px 0px"
  }
}));
export { useLipidProfileStyles };
