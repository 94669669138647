import { object, string } from "yup";

export const healthSchema = object({
    type: string().required('Type is required'),
    treated_by: string().required('Treated By is requried'),
    diagnosis: string().required('Diagnosis is required')
})

export const medicationSchema = object({
    name: string().required('Name is required'),
    medicine_form: string().required('Medicine form is requried'),
    medication_strength: string().required('Medication strength is required'),
    strength_type: string().required('Strength type is required'),
    quantity: string().required('Quantity is required'),
})

export const surgury = object({
    name: string().required('Name is required'),
    implants: string(),
    date: string().required('Date is required'),
    surgeon_name: string().required('Surgen name is required'),
    notes: string()
})

export const allergy = object({
    name: string().required('Name is required'),
    triggered_by: string().required('Triggered by is requried'),
    reaction: string().required('Reaction is required'),
    how_often: string().required('How often is required'),
    first_diagnosed: string().required('First diagnosed is required'),
    medication: string().required('Medication is required'),
    notes: string()
})

export const vaccination = object({
    for: string().required('For is required'),
    date: string().required('Date is required'),
    name: string().required('Name is required'),
    details: string().required('Detail is required'),
    lot_number: string().required('Lot number is required'),
    notes: string(),
})