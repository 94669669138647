import styled from 'styled-components';

export const StyledContainer = styled.div`
  
`;

export const StyledButton = styled.button`
    background-color: #5156be;
    color: #fff;
    border: 1px solid #5156be;
    margin-top: 20px;
    padding: 12px 25px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
`;

export const StyledButtonModfiy = styled.button`
    background-color: transparent;
    color: #5156be;
    border: 1px solid #5156be;
    margin-top: 20px;
    padding: 12px 25px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    width: 100%
`;

export const StyledButtonCancel = styled.button`
    background-color: #ff5050;
    color: #fff;
    border: 1px solid #ff5050;
    5ab: ;
    margin-top: 20px;
    padding: 12px 25px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
`;
