import styled from 'styled-components';

export const StyledContainer = styled.div`
  
    .emed-about-us.section-search {
        background: #f9f9f9 url(/assets/img/aboutus.jpg) no-repeat bottom center; 
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 570px;

        h1 {
            color: #fff !important;
        } 
        span {
            color: #fff !important;
            font-size: 18px;
        }
    }
`;
