import styled from 'styled-components';

export const StyledContainer = styled.div`
	padding: 0px;
	background-color: #fff;

	.PhoneInputInput {
		height: 45px;
		border: 1px solid #dcdcdc;
		border-radius: 6px;
		padding-left: 6px;
	}

	.PhoneInput:focus,
	.PhoneInputInput:focus  {
		outline: none;
	}

	.content-left {
		background-color: #1dbe72;
		padding: 0;
	}

	.container-checkbox {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		font-weight: 500;
		font-size: 14px;
		font-size: 0.875rem;
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}

		a {
			color: #1dbe72;
		}

		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 20px;
			width: 20px;
			background-color: #eee;
			border-radius: 5px;
		}
	}
	.container-checkbox:hover input ~ .checkmark {
		background-color: #ccc;
	}
	.container-checkbox input:checked ~ .checkmark {
		background-color: #1dbe72;
	}
	.container-checkbox .checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}
	.container-checkbox input:checked ~ .checkmark:after {
		display: block;
	}
	.container-checkbox .checkmark:after {
		left: 7px;
		top: 4px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;

export const StyledLeftPanel = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	min-height: 100%;
	padding: 60px 60px 35px 60px;
	color: #fff;
	text-align: center;
	position: relative;

	a#logo {
    position: absolute;
    left: 20px;
    top: 15px;
    display: block;
		height: 35px;
		outline: none;
		text-decoration: none;
	}
	h2 {
    color: #fff;
    font-size: 32px;
    font-size: 2rem;
    margin: 20px 0 15px 0;
    font-weight: 600;
	}
	p {
		font-size: 15px;
    font-size: 0.9375rem;
		font-weight: 500;
		margin-bottom: 25px;
	}
	.copy {
		font-size: 14px;
		font-size: 0.875rem;
		position: absolute;
		bottom: 25px;
		left: 0;
		width: 100%;
		opacity: 0.5;
	}
`;

export const StyledRightPanel = styled.div`
	padding: 60px;
	height: 100%;
	min-height: 100%;
	overflow-y: scroll;
	display: flex;
	justify-content: center;
	align-items: center;

	#wizard_container {
    width: 450px;
	}

	#top-wizard {
    padding-bottom: 25px;
	}

	#location {
    font-size: 12px;
    font-size: 0.75rem;
	}

	#progressbar {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    background-color: #f0f0f0;
    overflow: hidden;
	}
	.ui-progressbar {
    height: 8px;
    width: 100%;
	}

	.ui-progressbar .ui-progressbar-value {
    height: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
	}

	.ui-widget-header {
    background: #6C3;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
	}
`;

export const StyledRightTitle = styled.h2`
	display: block;
	font-size: 26px;
	font-size: 1.625rem;
	margin-bottom: 5px;
	font-weight: 600;
`;

export const StyledSubTitle = styled.h3`
	margin: 0 0 30px 0;
	padding: 0;
	font-weight: 500;
	font-size: 19px;
	font-size: 1.1875rem;
`;

export const StyledUploadFile = styled.div`
	margin-top: 20px;
	margin-bottom: 30px;
	label {
    font-weight: 500;
    margin-bottom: 5px;
    color: #222;
	}
	.fileupload {
    position: relative;
    width: 100%;
    margin-top: 5px;
    font-size: 14px;
    font-size: 0.875rem;
		margin-bottom: 35px;
		
		input[type=file] {
			border: 1px solid #ddd;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			-ms-border-radius: 3px;
			border-radius: 3px;
			background-color: #fff;
			padding: 5px;
			height: auto;
			width: 100%;
			color: #999;
			outline: none;
		}
	}
`;

export const StyledRightBottom = styled.div`
	border-top: 2px solid #ededed;
	padding-top: 20px;
	text-align: right;
	margin-top: 30px;
`;

export const StyledSuccessButton = styled.button`
	cursor: pointer;
	border: none;
	color: #fff;
	text-decoration: none;
	transition: background .5s ease;
	-moz-transition: background .5s ease;
	-webkit-transition: background .5s ease;
	-o-transition: background .5s ease;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-align: center;
	background: #1dbe72;
	position: relative;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: 600;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	line-height: 1;
	padding: 12px 30px;

	a {
		color: #fff;
	}
`;

export const StyledFinalTitle = styled.h3`
	margin-bottom: 25px;
	margin-top: 25px;
	-webkit-font-smoothing: antialiased;
	font-family: "Work Sans",Arial,sans-serif;
	text-align: center;
`;

export const StyledFinalSubTitle = styled.p`
	margin-bottom: 25px;
	-webkit-font-smoothing: antialiased;
	text-align: center;
`;

export const StyledFinalStep = styled.div`
	margin-top: 5em;
	background-color: #f9f9f9;
	padding: 12px 0 0 0;
	text-align: left;
	padding: 8px 5px 5px 8px;
	.container_check {
		margin-bottom: 3px;
		font-weight: 500;
		display: block;
		position: relative;
		font-size: 14px;
		font-size: 0.875rem;
		padding-left: 30px;
		line-height: 1.3;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		color: #222;

		a{
			color: #1dbe72;
			text-decoration: none;
			-moz-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			-webkit-transition: all 0.3s ease-in-out;
			-ms-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
			outline: none;
		}

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}

		input[type=checkbox], input[type=radio] {
			box-sizing: border-box;
			padding: 0;
		}
	}
`;

export const StyledSelect = styled.div`
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	label {
		font-weight: 500;
		margin-bottom: 5px;
		color: #222;
	}
	select {
		border-color: #dbdbdb
	}
`;