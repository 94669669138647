import styled from 'styled-components';

export const StyledContainer = styled.div`
	text-align: center;
	width: 100%;

	.pagination {
		display: inline-block;
		padding-left: 0;
		margin: 20px 0;
		border-radius: 4px;
	}
	.pagination>li {
    display: inline;
	}
	.pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
	}
	.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
	}
	.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 7px 14px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #5156be;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
    cursor : pointer;
	}
	.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 2;
    color: #fff;
    cursor: default;
    background-color: #5156be;
    border-color: #5156be;
	}
`;
