import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getDoctorSlotsListingAPI,
	reBookAppointmentAPI
} from '../../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING,
	SET_DATES_APPOINTMENTS
} from '../../../../store/types';
import moment from 'moment';
import CardComponent from '../../../../components/Card';
import {
	StyledHeader,
	StyledBackTo,
	StyledTitle,
	StyledButtonPay
} from './styles';
import InsideMenuBar from '../../../../components/InsideMenus';

function BookingSlots(props) {

	const [state, dispatch] = React.useContext(Context);
	const [id, setId] = useState(null);
	const [slots, setSlots] = useState([]);
	const [doctor, setDoctor] = useState(null);

	const [apptPrice, setApptPrice] = useState({
		onlineAmount: 0,
		offlineAmount: 0,
		onlinePriceArr : [],
		offlinePriceArr : []
	});

	const [curreny, setCurrency] = React.useState('');

	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3
	};
	
	const [reBookAppointment, setReBookAppointment] = useState(false);
	const [appId, setAppId] = useState(null);
	React.useEffect(() => {
		if(props.match.path === '/patient/reschedule-book-appointment/:id/:appId') {
			setReBookAppointment(true);
			setAppId(props.match.params.appId)
		}
	}, [])

	useEffect(() => {
		if(props.match.params && props.match.params.id) {
			setId(props.match.params.id);
      const allCookieData = Cookies.get();
      if(allCookieData && allCookieData.doctorInfo) {
        setDoctor(JSON.parse(allCookieData.doctorInfo))

        const doctorData = JSON.parse(allCookieData.doctorInfo);
		const { fifteenOnline, appointmentPrice, thirtyfiveOnline, sixtyOnline, fifteenOffline, clinicPrice, thirtyfiveOffline, sixtyOffline } = doctorData.data;
		const onlineRate = [fifteenOnline, appointmentPrice, thirtyfiveOnline, sixtyOnline];
		const offlineRate = [fifteenOffline, clinicPrice, thirtyfiveOffline, sixtyOffline];
		  setApptPrice({
			  onlineAmount: doctorData.onlinePrice,
			  offlineAmount: doctorData.offlinePrice,
			  onlinePriceArr: onlineRate,
			  offlinePriceArr: offlineRate,
		  })
				setCurrency(doctorData.curreny)
      }

			loader(true);
			getDoctorSlotsListingAPI(props.match.params.id).then(res => {
				loader(false);
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				

				var slotMap = {};
				for(let slot of res.data){
						const date = moment(slot.start).utc().format('DD/MM/YYYY');
						if(moment(slot.start).utc().format() > moment().format()) {
							if(slotMap[date]) {
									slotMap[date].push(slot);
							}
							else {
									slotMap[date] = [slot];
							}
						}
						
				}

				var result = Object.keys(slotMap).map(slotDate => {
						return {
								date: slotDate,
								slots: slotMap[slotDate]
						}
				});
				
				setSlots(result);
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
	}, [])

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	// const slots = [
	// 	{id: 1, date: '02/02/2021', slots: ['10:00 am', '10:30 am', '11:00 am', '11:30 am', '12:00 pm', '12:30 pm', '02:00 pm', '02:30 pm']},
	// 	{id: 2, date: '18/01/2021', slots: ['10:00 am', '10:30 am', '11:00 am', '11:30 am', '12:00 pm', '12:30 pm', '02:00 pm', '02:30 pm']},
	// 	{id: 3, date: '19/01/2021', slots: ['10:00 am', '10:30 am', '11:00 am', '11:30 am', '12:00 pm', '12:30 pm', '02:00 pm', '02:30 pm']},
	// 	{id: 4, date: '20/01/2021', slots: ['10:00 am', '10:30 am', '11:00 am', '11:30 am', '12:00 pm', '12:30 pm', '02:00 pm', '02:30 pm']},
	// 	{id: 5, date: '21/01/2021', slots: ['10:00 am', '10:30 am', '11:00 am', '11:30 am', '12:00 pm', '12:30 pm', '02:00 pm', '02:30 pm']},
	// 	{id: 6, date: '22/01/2021', slots: ['10:00 am', '10:30 am', '11:00 am', '11:30 am', '12:00 pm', '12:30 pm', '02:00 pm', '02:30 pm']}
	// ];

	const [selectedSlot, setSelectedSlot] = useState({_id: null,slot: null, date: null, type: null});

	const setSlot = (value) => {
		setSelectedSlot({_id: value.slot._id, slot: value.slot, date: value.date, type: value.slot.type});
		localStorage.setItem('slotType', value.slot.type);
	}

	const gotoCheckout = () => {
		if(selectedSlot._id && selectedSlot.date) {
      if(!reBookAppointment) {
        if(selectedSlot.type === 1) {
          if(apptPrice.onlineAmount <= 0 ) {
            toast.error("You can't book this appointment")
            dispatch({
              type: SET_ERROR,
              payload: "You can't book this appointment"
            });
            return
          }
        } else {
          if(apptPrice.offlineAmount <= 0) {
            toast.error("You can't book this appointment")
            dispatch({
              type: SET_ERROR,
              payload: "You can't book this appointment"
            });
            return
          }
        }
      }
			
			if(reBookAppointment) {
				loader(true);
				reBookAppointmentAPI(appId, {slotId: selectedSlot._id}).then(res => {
					loader(false);
					// toast.success(res?.message)
					dispatch({
						type: SET_ERROR,
						payload: res?.message
					});
					props.history.push(`/patient/view-appointment/${props.match.params.appId}`);
				}).catch(err => {
					loader(false);
					toast.error(err?.response?.data?.error)
					dispatch({
						type: SET_ERROR,
						payload: err?.response?.data?.error
					});
				})

			} else {

				Cookies.set('slot', JSON.stringify(selectedSlot.slot), { path: '' });

				dispatch({
					type: SET_DATES_APPOINTMENTS,
					payload: {
						slot: selectedSlot.slot,
						date: selectedSlot.date,
						doctor: id
					}
				});
				props.history.push('/patient/book-appointment-form');
			}

		}
	}
	const renderMin = (index) => {
		switch (index) {
			case 0: return '15 min';
			case 1: return '30 min';
			case 2: return '45 min';
			case 3: return '60 min';
			default: return;
		}
	}
	// const renderPriceText = (curr, rate, index) => `15 min - AFN 12, 30 min - AFN 12, 45 min - AFN 12,  60 min - AFN 12`
	const renderPriceText = (curr, rate, index) => `${renderMin(index)}  - ${curr} ${rate}`
	
	// const renderPrice = (curr, price, index) => price.filter((rate) => rate).map((item)=>item).join(', ');
	const renderPrice = (curr, price) => price.map((rate, index) => renderPriceText(curr, rate, index)).join(', ');
	const appointmentStyle = {marginLeft: '0px'};
	return (
		<div>
			{/* <Breadcrumb
				backRoute={[{id: 1, route: 'Dashboard', path: '/patient'}]}
				currentRoute='Book appointment'
				title='Book slot' /> */}
			<InsideMenuBar
				props={props}
				type={'patient'}
			/>

			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<StyledHeader>
									
										{
											reBookAppointment ? <h5>Reschedule appointment</h5> : <StyledBackTo>
												<Link to={'/patient/listing-practitioners'}>
											<i className="fa fa-arrow-left" />
											Back
										</Link>
										</StyledBackTo>
										}
										
									
									<StyledTitle>
										{doctor ? doctor.name + '  (Available Slots)' : ''}
									</StyledTitle>
									{/* <StyledAddEditPatient></StyledAddEditPatient> */}
								</StyledHeader>
							</CardComponent>

							<div className="row mt-3">
								<div className="col-12 col-sm-4 col-md-6">
									<h4 className="mb-1">Today - {moment().format('Do MMMM YYYY')}</h4>
									<p className="text-muted">{moment().format('dddd')}</p>
								</div>
							</div>
							<hr />
							<div className="row mb-3">
								<div className="col-md-4 mb-2"><b>Online appointment -</b>  <b style={{background: '#2c7fb7', paddingLeft: '10px', color: '#fff', padding: '3px 20px'}}></b></div>
								{/* Blue color */}
								<div className="col-md-4 mb-2"><b>In clinic appointment - </b> <b style={{background: '#56be73', paddingLeft: '10px', color: '#fff', padding: '3px 20px'}}></b></div>
								{/* Green colors */}
								<div className="col-md-4 mb-2"><b>Selected slot -  </b><b style={{background: 'red', paddingLeft: '10px', color: '#fff', padding: '3px 20px'}}></b></div> 
								{/* Red colors */}
							</div>

							<hr />
							<div className="row mb-3" style={appointmentStyle}>
								<div><b>Online appointment price -</b> <b style={{color: '#2c7fb7', paddingLeft: '10px'}}>{renderPrice(curreny, apptPrice?.onlinePriceArr)}</b></div>
								{/* <div className="col-md-4"><b>In clinic appointment price -</b> <b style={{color: '#56be73', paddingLeft: '10px'}}>{curreny} {renderPrice(apptPrice?.offlinePriceArr)}</b></div> */}
								
							</div>
							<hr />
							<div className="row mb-3" style={appointmentStyle}>
								{/* <div className="col-md-4"><b>Online appointment price -</b> <b style={{color: '#2c7fb7', paddingLeft: '10px'}}>{curreny} {renderPrice(apptPrice?.onlinePriceArr)}</b></div> */}
								<div><b>In clinic appointment price -</b> <b style={{color: '#56be73', paddingLeft: '10px'}}>{renderPrice(curreny, apptPrice?.offlinePriceArr)}</b></div>
								
							</div>


							<CardComponent className="p-4">
								<div class="schedule-header">
									<div class="row">
										<div class="col-md-12">

											<Slider {...settings}>
												{
													slots && slots.map((item, i) => {
														return <div className="day-slot">
																		<ul>
																			<li key={i} className="li-doc">
																				<span>{item.date}</span>
																				<ul className="doc-slot">
																					{
																						item.slots && item.slots.map((slot, index) => {
																							return 	<li key={index}><a className={selectedSlot._id === slot._id && selectedSlot.date === item.date ? 'selected-slot' : (
																								slot.type === 1 ? 'slot-online' : 'slot-offline'
																							)} onClick={() => setSlot({slot: slot, date: item.date})}>{moment(slot.start).utc().format('hh:mm a')} - {moment(slot.end).utc().format('hh:mm a')}</a></li>
																						})
																					}
																				</ul>
																			</li>
																		</ul>
																	</div>
													})
												}
											</Slider>

											{
													slots.length === 0 && (
														<h5>There is no slot available</h5>
													)
												}

											{/* <div class="day-slot">
												<ul>
													{
														slots && slots.map((item, i) => {
															return <li key={i}>
																				<span>{item.date}</span>
																				<StyledSlots>
																					{
																						item.slots && item.slots.map((slot, index) => {
																							return 	<li key={index}><a className={selectedSlot._id === slot._id && selectedSlot.date === item.date ? 'selected-slot' : (
																								slot.type === 1 ? 'slot-online' : 'slot-offline'
																							)} onClick={() => setSlot({slot: slot, date: item.date, type: slot.type})}>{moment(slot.start).utc().format('hh:mm a')} - {moment(slot.end).utc().format('hh:mm a')}</a></li>
																						})
																					}
																				</StyledSlots>
																			</li>
																	
														})
													}
												</ul>
												
												{
													slots.length === 0 && (
														<h5>There is no slot available</h5>
													)
												}

												
											</div> */}
										</div>
									</div>
								</div>
							</CardComponent>

							<div class="submit-section proceed-btn text-right">
								{
									slots.length > 0 && (
									<StyledButtonPay class="btn btn-primary submit-btn" onClick={gotoCheckout}>
										{reBookAppointment ? 'Reschedule appointment' : 'Proceed'}
									</StyledButtonPay>
									)
								}
								
							</div>

						</div>
					</div>
				</div>
			</div>	
		</div>
	)
}

export default BookingSlots
