import styled from 'styled-components';

export const StyledSlider = styled.div`
  iframe {
		border: none;
	}

	.bd-home-slider {
    max-height: 500px;
    height: 500px;
    @media (max-width: 767px){
			max-height: 100%;
			height: 100%;
			padding: 0px;
    }
	}

	.slide-image {
    @media (max-width: 767px){
        padding: 0px 15px;
    }
	}

	.bg-img-001 {
    background: url(/assets/img/linguistic.png);
    background-repeat: no-repeat;
    background-size: contain;
        background-size: 100% 100%;
    width: 100%;
    @media (max-width: 991px){
        background: none !important;
    }
	}

	.bg-img-002 {
    background: url(/assets/img/yoga-benefits.png);
    background-repeat: no-repeat;
    background-size: contain;
        background-size: 100% 100%;
    width: 100%;
    @media (max-width: 991px){
        background: none !important;
    }
	}

	.bg-img-003 {
    background: url(/assets/img/yoga-23-2.png);
    background-repeat: no-repeat;
    background-size: contain;
        background-size: 100% 100%;
    width: 100%;
    @media (max-width: 991px){
        background: none !important;
    }
	}

	.bg-home-mobile-001 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/linguistic.png) !important;
			background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}
`;
