import { makeStyles } from '@mui/styles';

import colors from '../../constant/colors';
import fontWeights from '../../constant/fontWeights';

const medicationStyles = makeStyles((theme) => ({
  mainCont: {
    maxHeight: 500,
    overflow: 'auto',
    textOverflow: "ellipsis",
    overflow: "auto",
    whiteSpace: 'nowrap'
  },
  textStyle: {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight700,
    fontSize: "14px",
    lineHeight: "18px",
    color: colors.grey1,
    marginBottom: 16
  },
  margin: {
    marginTop: 8
  },
  customAutocomplete: {
    "& .MuiAutocomplete-inputRoot": {
      background: colors.white3,
      borderRadius: "8px",
    },
    "& .MuiAutocomplete-input": { padding: 0, color: "black" },
  },
  textStyle1: {
    width: '100%',
    display: "flex",
    fontSize: 14,
    backgroundColor: 'yellow',
    justifyContent: "center",
    background: colors.primary,
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  headerCont: {
    display: "flex",
    width: "100%",
    justifyContent:'space-between',
  },
  deleteTextStyle: {
    background: colors.primary,
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  }
}));

export { medicationStyles };
