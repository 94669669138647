import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledContainer = styled.div`
  
`;

export const StyledHeading = styled.h3`
	margin-bottom: 20px;
`;

export const StyledAdd = styled(Link)`
    margin-left: auto;
	cursor: pointer;
	border: none;
	color: #fff;
	text-decoration: none;
	transition: background .5s ease;
	-moz-transition: background .5s ease;
	-webkit-transition: background .5s ease;
	-o-transition: background .5s ease;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-align: center;
	background: #5156be;
	position: relative;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: 600;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	line-height: 1;
	margin-top: 0px !important;
	padding: 16px 22px;

	a:hover {
		color: #FFF;
	}
`;