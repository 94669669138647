import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getDoctorAvailableTimeRequest, getDoctorAvailableTimeSuccess, getDoctorAvailableTimeFailure, addSlotDoctorRequest, addSlotDoctorSuccess, addSlotDoctorFailure, getSlotsByDoctorRequest, getSlotsByDoctorSuccess, getSlotsByDoctorFailure } from './doctorScheduleSlice'
import { addSlotsDoctorAction, getDoctorAvailableTimeAction, getSlotsByDoctorAction} from './doctorScheduleAction';
import { addSlotDoctorApi, getDoctorAvailableTimeApi, getSlotsByDoctorApi} from '../../../services/mainService';

  function* getDoctorAvailableTimeList(action) {
    try {
       yield put(getDoctorAvailableTimeRequest()); 
      const apiResponse = yield call(getDoctorAvailableTimeApi, action.payload);
      if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
          yield put(getDoctorAvailableTimeSuccess(listData));
  
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put(getDoctorAvailableTimeFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }

  function* addSlotsDoctor(action) {
    try {
       yield put(addSlotDoctorRequest()); 
      const apiResponse = yield call(addSlotDoctorApi, action.payload);
      if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
          yield put(addSlotDoctorSuccess(listData));
  
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put(addSlotDoctorFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }
 
  function* getSlotsByDoctor(action) {
    try {
       yield put(getSlotsByDoctorRequest()); 
      const apiResponse = yield call(getSlotsByDoctorApi, action.payload);
      if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
          yield put(getSlotsByDoctorSuccess(listData));
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put(getSlotsByDoctorFailure(apiResponse.response.message))
        }
      }
    } 
    catch (error) {}
  }

  

  export function* scheduleSaga() {
    yield takeLatest(getDoctorAvailableTimeAction.type, getDoctorAvailableTimeList);
    yield takeLatest(addSlotsDoctorAction.type, addSlotsDoctor);
    yield takeLatest(getSlotsByDoctorAction.type, getSlotsByDoctor);
    
  }