import { apiPost, apiGet, apiPut, apiDelete } from "../utils";

export function subAdminLoginAPI(data) {
	return apiPost(`/api/subadmin/login`, data);
}

export function postLoginAPI(data) {
	return apiPost(`/api/subadmin/login`, data);
}

export function getDashboardAppointmentAPI(query) {
	return apiGet(`/api/organization/org-dashboard-appointments`, query);
}

export function getAppointmentsOrg(query) {
	return apiGet(`/api/organization/appointments`, query);
}

// export function getAllPatient(query) {
// 	return apiGet(`/api/organization/search-doctor-patient-by-subadmin`, query);
// }

export function resetPasswordAPI(id, query) {
	return apiPut(`/api/subadmin/reset-password/${id}`, query);
}

export function getDoctorsListing(id) {
	return apiGet(`/api/organization/patient-doctor-listing/${id}`);
}

export function BookedAppointmentAPI(id, data) {
	return apiPost(`/api/organization/patient-booked-appointment-subuser/${id}`, data);
}


export function getAppointmentDetailsAPI(id) {
	return apiGet(`/api/organization/patient-booked-appointment-detail/${id}`);
}

export function updateCashPaymentAPI(id, data) {
	return apiPut(`/api/organization/update-appointment-cashpayment-status/${id}`, data);
}

export function getAllPatient(query) {
	return apiGet(`/api/organization/patients-listing`, query);
}