import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';
import React from 'react'
import styled from 'styled-components'
import colors from '../../constant/colors';



export const StyledTableCell = styled(TableCell, { shouldForwardProp: (prop) => prop !== 'actionCell' })(({ theme, actionCell }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#2B2B2B',
    [`&.${tableCellClasses.head}`]: {
        boxSizing: 'border-box',
        height: '38px',
        background: '#F8F9FC',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#F5F5F7',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '18px',
        color: '#5E5E5E',
        ...actionCell && { width: '150px' }
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

 export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const StyledTableContainer = styled(TableContainer)(() => ({
    borderRadius: '8px',
    boxSizing: 'border-box',
    border: 'solid 1.2px #F5F5F7',
}))

export const StyledDiv = styled.div`
   font-family: Poppins;
   font-size: 24px;
   font-weight: 500;
   line-height: 32px;
   letter-spacing: 0em;
   text-align: left;
   color: #5E5E5E;
   text-align: center;
   min-Height: 300px;
   `;

 export const StryledGridSection = styled(Grid)(() => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    color: '#2B2B2B',
    alignItems: 'center',
    // padding: 8,
}))


export const StyledNoRecordSpan = styled('span')(() => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '55px',
    border: 'solid 1.2px #F5F5F7',
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '8px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#5E5E5E'
}))

export const StyleButtonForMoreRecord = styled(Button)(() => ({
    background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
    width: '100%',
    borderRadius: '0px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18px',   
    '&.MuiButton-root': {
        color: 'white',
      },
}))

export default function MedicalHistory({data}) {
    return (
        <>
        {Object.keys(data).length === 0 ? <StyledDiv>No Medical History requested</StyledDiv> :
            <Grid container>
                { data?.health_condition === false ? '' :
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Health Conditions</StryledGridSection>
                        <Grid item xs={10}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row" align='center'>Name</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Treated By</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { data?.health_condition.map((row, index)=> <StyledTableRow key={index}>                                               
                                                <StyledTableCell component="td" scope="row" align="center">{row.diagnosis}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.treated_by}</StyledTableCell>    
                                            </StyledTableRow> )}
                                            
                                        
                                    </TableBody>
                                </Table>
                                { data?.health_condition.length === 0 ? <StyledNoRecordSpan>No data</StyledNoRecordSpan> : ''}
                            </StyledTableContainer>
                        </Grid>
                        
                    </Grid>
                </Grid> }

               { data?.medications === false ? '' :
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Medications</StryledGridSection>
                        <Grid item xs={10}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row" align='center'>Name</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Medicine form</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Dosage</StyledTableCell>                                
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { data?.medications.map((row, index)=> <StyledTableRow key={index}>
                                                <StyledTableCell component="td" scope="row" align="center">{row.name}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.medicine_form}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.quantity}</StyledTableCell>
                                            </StyledTableRow>) }                                                                                    
                                    </TableBody>
                                </Table>
                                { data?.medications.length === 0 ? <StyledNoRecordSpan>No data</StyledNoRecordSpan> : ''}
                            </StyledTableContainer>
                        </Grid>                        
                    </Grid>
                </Grid> }
                { data?.surguries === false ? '' :
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Surguries</StryledGridSection>
                        <Grid item xs={10}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row" align='center'>Name</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Implant (if any)</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Done on</StyledTableCell>                                            
                                            <StyledTableCell component="td" scope="row" align="center">Done By</StyledTableCell>                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {data?.surguries.map((row, index)=> <StyledTableRow key={index}>
                                                <StyledTableCell component="td" scope="row">{row.name}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.implants}</StyledTableCell>  
                                                <StyledTableCell component="td" scope="row" align="center">{moment(row.date).format('YYYY-MMM-DD')}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.sorgeon_name}</StyledTableCell>                                                   
                                            </StyledTableRow>)}                                                                                                     
                                    </TableBody>
                                </Table>
                                { data?.surguries.length === 0 ? <StyledNoRecordSpan>No data</StyledNoRecordSpan> : ''}
                            </StyledTableContainer>
                        </Grid>                        
                    </Grid>
                </Grid> }
                { data?.allergies === false ? '' : 
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Allergies</StryledGridSection>
                        <Grid item xs={10}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row" align='center'>Name</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Triggered By</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Medication</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Reactions</StyledTableCell>                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.allergies.map((row, index)=> <StyledTableRow key={index}>
                                                <StyledTableCell component="td" scope="row" align='center'>{row.name}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.triggered_by}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.medication}</StyledTableCell>                                                
                                                <StyledTableCell component="td" scope="row" align="center">{row.reaction}</StyledTableCell>                                                
                                            </StyledTableRow>)}                                                                                    
                                    </TableBody>
                                </Table>
                                { data?.allergies.length === 0 ? <StyledNoRecordSpan>No data</StyledNoRecordSpan> : ''}  
                            </StyledTableContainer>
                        </Grid>                        
                    </Grid>
                </Grid> }
                { data?.vaccinations === false ? '' :
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Vaccinations</StryledGridSection>
                        <Grid item xs={10}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row" align='center'>Name</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">For</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Recieved On</StyledTableCell>
                                            <StyledTableCell component="td" scope="row" align="center">Details</StyledTableCell>                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                       {data?.vaccinations.map((row, index) => <StyledTableRow key={index}>
                                                <StyledTableCell component="td" scope="row" align="center">{row.name}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.for}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{moment(row.createdAt).format('YYYY-MMM-DD')}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row" align="center">{row.details}</StyledTableCell>
                                            </StyledTableRow>)}   
                                    </TableBody>
                                </Table>
                                { data?.vaccinations.length === 0 ? <StyledNoRecordSpan>No data</StyledNoRecordSpan> : ''}
                            </StyledTableContainer>
                        </Grid>
                        
                    </Grid>
                </Grid> }
            </Grid> 
}
            
        </>
    )
}