import React, {useContext, useState} from 'react';

import PhoneInput from "react-phone-number-input";
import Select from "react-select";

import { Context } from '../../../store';
import {
	fileUploading,
	postSignupAPI
} from '../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';
import { toast } from 'react-toastify';
import { globalValidator } from "../../../utils";

import InputField from '../../../components/InputField';

import {
	StyledContainer,
	StyledLeftPanel,
	StyledRightPanel,
	StyledRightTitle,
	StyledSubTitle,
	StyledUploadFile,
	StyledRightBottom,
	StyledSuccessButton,
	StyledFinalTitle,
	StyledFinalSubTitle,
	StyledFinalStep,
	StyledSelect
} from './styles';

const permission = [
	{ label: 'IPD', isDisabled: false },
	{ label: 'OPD', isDisabled: false },
]
function OrganizationRegistration(props) {
	const [steps, setSteps] = React.useState(1);

	const [personalInfo, setPersonalInfo] = useState({org_name: '', main_name: '', email: '', contact_number: '', address: '', country: { value: "IN", label: "India" }});
	const [logo, setLogo] = useState(null);
	const [document1, setDocument1] = useState(null);
	const [document2, setDocument2] = useState(null);
	const [document3, setDocument3] = useState(null);
	const [document4, setDocument4] = useState(null);
	const [document5, setDocument5] = useState(null);
	const [doctor_panel, setDoctor_panel] = useState('');
	const [terms, setTerms] = useState(false);

	const [state, dispatch] = useContext(Context);


	const getWidth = () => {
		if (steps === 1) return '0%';
		else if (steps === 2) return '50%';
		else if (steps === 3) return '100%';
	}

	const prevHandle = (e) => {
		e.preventDefault();
		if(steps === 2) setSteps(1);	
		else if(steps === 3)	setSteps(2);
	}

	const nextHandle = (e) => {
		e.preventDefault();
		
		if(steps === 1) {
			const keys = [
				{ name: "org_name", title: "Organization Name", validateKey: "required" },
				{ name: "main_name", title: "Main person name", validateKey: "required" },
				{ name: "email", title: "Email", validateKey: "email" },
				{ name: "contact_number", title: "Contact number", validateKey: "required" },
				{ name: "address", title: "Address", validateKey: "required" },
				{name: 'country', title: "Country", validateKey: "required"}
			];
			let valid = globalValidator(personalInfo, keys);

			if(valid === true) {
				setSteps(2);
			} else {
				toast.error(valid)
				dispatch({
					type: SET_ERROR,
					payload: valid
				});
			}
		}
		if(steps === 2) {
			const keys = [
				{ name: "doctor_panel", title: "Doctor panel", validateKey: "required" }
			];
			let valid = globalValidator({doctor_panel}, keys);
			if(valid === true) {
				setSteps(3)
			} else {
				toast.error(valid)
				dispatch({
					type: SET_ERROR,
					payload: valid
				});
			}
		};

		if(steps === 3) {
			const keys = [
				{ name: "terms", title: "Terms and conditions", validateKey: "required" }
			];
			let valid = globalValidator({terms}, keys);
			if(valid === true) {
				if (terms) {
					const orgData = {
						org_name: personalInfo.org_name,
						main_name: personalInfo.main_name,
						email: personalInfo.email,
						contact_number: personalInfo.contact_number,
						address: personalInfo.address,
						logo: logo,
						permission: personalInfo?.permission?.map((item) => item?.value),
						doctor_panel: doctor_panel,
						country: personalInfo.country.label,
						documents: [document1,document2, document3, document4, document5],
						password: 'Emed4u@12345#!'
					}
					loader(true);
					postSignupAPI(orgData).then(res => {
						loader(false);
						// toast.success(res.message)
						dispatch({
							type: SET_ERROR,
							payload: res.message
						});
						props.history.push('/organization/registration-success');
					}).catch(err => {
						loader(false);
						toast.error(err.response.data.error)
						dispatch({
							type: SET_ERROR,
							payload: err.response.data.error
						});
					})
				} else {
					toast.error("Terms and conditions is required")
					dispatch({
						type: SET_ERROR,
						payload: "Terms and conditions is required"
					});
				}
			} else {
				toast.error(valid)
				dispatch({
					type: SET_ERROR,
					payload: valid
				});
			}
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const uplaodFile = (event, type) => {
		var fileName = event.target.files[0];
		if(fileName) {
			const formData = new FormData();
			formData.append("file", fileName);
			loader(true);
			fileUploading(formData).then(res => {
				loader(false);
				if(type === 'logo') setLogo(res.image);
				else if(type === 'document1')  setDocument1(res.image);
				else if(type === 'document2') setDocument2(res.image);
				else if(type === 'document3') setDocument3(res.image);
				else if(type === 'document4') setDocument4(res.image);
				else if(type === 'document5') setDocument5(res.image);
			}, err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		}
	}

	const handleChange = (e) => {
		setPersonalInfo(info => ({...info, permission: e}))
	}


	return (
		<StyledContainer className="container-fluid">
			<div className="row">
				<div className="col-xl-4 col-lg-4 content-left">
					<StyledLeftPanel>
						<a href="" id="logo">
							<img src="/assets/img/logo.png" alt="" height="45" />
						</a>
						<div>
							<figure>
								<img src="/assets/img/info_graphic_1.svg" alt="" className="img-fluid" width="270" height="270" />
							</figure>
							<h2>Join us</h2>
							<h3 style={{color: '#fff'}}>If telemedicine is evolution, we believe this is revolution!</h3>
						</div>
						<div className="copy">© 2020 Emed4u</div>
					</StyledLeftPanel>
				</div>

				<StyledRightPanel className="col-xl-8 col-lg-8">
					<div id="wizard_container">
						<div id="top-wizard">
							<span id="location">{steps} of 3 completed</span>
							<div id="progressbar" className="ui-progressbar ui-widget-content">
								<div className="ui-progressbar-value ui-widget-header" style={{ width: getWidth() }}></div>
								{/* style="width: 0%; display: none;" */}
							</div>
						</div>

						<form>
							
							{
								steps === 1 && (
								<>
									<StyledRightTitle>
										Organization / Clinic / Practitioner 
									</StyledRightTitle>
									<StyledSubTitle>Details</StyledSubTitle>
								</>
								)
							}

							{
								steps === 2 && (
								<>
									<StyledRightTitle>
										About your Organization / Clinic
									</StyledRightTitle>
									<StyledSubTitle>Please upload documents</StyledSubTitle>
								</>
								)
							}

							{
								steps === 3 && (
								<>
									<StyledFinalTitle>
										Thank your for your time {personalInfo.main_name} !
									</StyledFinalTitle>
									<StyledFinalSubTitle>
										We will contact you on the following email address with further details <strong>{personalInfo.email}</strong>
									</StyledFinalSubTitle>
								</>
								)
							}
						
							{
								steps === 1 && (
								<>
									<div className="form-group card-label">
										<label>Organization / Clinic / Practitioner name <small style={{color: 'red'}}> *</small></label>
										<InputField value={personalInfo.org_name} onChange={e => setPersonalInfo((user) => ({...user, org_name: e.target.value}))} />
									</div>
									<div className="form-group card-label">
										<label>Main person name <small style={{color: 'red'}}> *</small></label>
										<InputField value={personalInfo.main_name} onChange={e => setPersonalInfo((user) => ({...user, main_name: e.target.value}))} />
									</div>
									<div className="form-group card-label">
										<label>Email <small style={{color: 'red'}}> *</small></label>
										<InputField value={personalInfo.email} onChange={e => setPersonalInfo((user) => ({...user, email: e.target.value}))} />
									</div>
									
									<div className="form-group card-label">
									<label>Contact number <small style={{color: 'red'}}> *</small></label>
										<PhoneInput
                        placeholder="Enter phone number"
                        international
                        defaultCountry="IN"
                        value={personalInfo.contact_number}
                        onChange={e => setPersonalInfo((user) => ({...user, contact_number: e}))}
                      />
									</div>

									{/* <div className="form-group card-label">
										<label>Contact number</label>
										<InputField value={personalInfo.contact_number} onChange={e => setPersonalInfo((user) => ({...user, contact_number: e.target.value}))} />
									</div> */}
									<div className="form-group card-label">
										<label>Address <small style={{color: 'red'}}> *</small></label>
										<InputField value={personalInfo.address} onChange={e => setPersonalInfo((user) => ({...user, address: e.target.value}))} />
									</div>

									<div className="form-group card-label">
										<label>Country <small style={{color: 'red'}}> *</small></label>
										<Select
											options={[
												// { value: "world", label: "World" },
												{ value: "AF", label: "Afghanistan" },
												{ value: "AL", label: "Albania" },
												{ value: "DZ", label: "Algeria" },
												{ value: "AO", label: "Angola" },
												{ value: "AR", label: "Argentina" },
												{ value: "AM", label: "Armenia" },
												{ value: "AU", label: "Australia" },
												{ value: "AT", label: "Austria" },
												{ value: "AZ", label: "Azerbaijan" },
												{ value: "BS", label: "Bahamas" },
												{ value: "BD", label: "Bangladesh" },
												{ value: "BY", label: "Belarus" },
												{ value: "BE", label: "Belgium" },
												{ value: "BZ", label: "Belize" },
												{ value: "BJ", label: "Benin" },
												{ value: "BT", label: "Bhutan" },
												{ value: "BO", label: "Bolivia" },
												{ value: "BA", label: "Bosnia and Herzegovina" },
												{ value: "BW", label: "Botswana" },
												{ value: "BR", label: "Brazil" },
												{ value: "BN", label: "Brunei Darussalam" },
												{ value: "BG", label: "Bulgaria" },
												{ value: "BF", label: "Burkina Faso" },
												{ value: "BI", label: "Burundi" },
												{ value: "KH", label: "Cambodia" },
												{ value: "CM", label: "Cameroon" },
												{ value: "CA", label: "Canada" },
												{ value: "CI", label: "Ivory Coast" },
												{ value: "CF", label: "Central African Republic" },
												{ value: "TD", label: "Chad" },
												{ value: "CL", label: "Chile" },
												{ value: "CN", label: "China" },
												{ value: "CO", label: "Colombia" },
												{ value: "CG", label: "Congo" },
												{ value: "CD", label: "Democratic Republic of Congo" },
												{ value: "CR", label: "Costa Rica" },
												{ value: "HR", label: "Croatia" },
												{ value: "CU", label: "Cuba" },
												{ value: "CY", label: "Cyprus" },
												{ value: "CZ", label: "Czech Republic" },
												{ value: "DK", label: "Denmark" },
												{ value: "DP", label: "Diamond Princess" },
												{ value: "DJ", label: "Djibouti" },
												{ value: "DO", label: "Dominican Republic" },
												{ value: "CD", label: "DR Congo" },
												{ value: "EC", label: "Ecuador" },
												{ value: "EG", label: "Egypt" },
												{ value: "SV", label: "El Salvador" },
												{ value: "GQ", label: "Equatorial Guinea" },
												{ value: "ER", label: "Eritrea" },
												{ value: "EE", label: "Estonia" },
												{ value: "ET", label: "Ethiopia" },
												{ value: "FK", label: "Falkland Islands" },
												{ value: "FJ", label: "Fiji" },
												{ value: "FI", label: "Finland" },
												{ value: "FR", label: "France" },
												{ value: "GF", label: "French Guiana" },
												{ value: "TF", label: "French Southern Territories" },
												{ value: "GA", label: "Gabon" },
												{ value: "GM", label: "Gambia" },
												{ value: "GE", label: "Georgia" },
												{ value: "DE", label: "Germany" },
												{ value: "GH", label: "Ghana" },
												{ value: "GR", label: "Greece" },
												{ value: "GL", label: "Greenland" },
												{ value: "GT", label: "Guatemala" },
												{ value: "GN", label: "Guinea" },
												{ value: "GW", label: "Guinea-Bissau" },
												{ value: "GY", label: "Guyana" },
												{ value: "HT", label: "Haiti" },
												{ value: "HN", label: "Honduras" },
												{ value: "HK", label: "Hong Kong" },
												{ value: "HU", label: "Hungary" },
												{ value: "IS", label: "Iceland" },
												{ value: "IN", label: "India" },
												{ value: "ID", label: "Indonesia" },
												{ value: "IR", label: "Iran" },
												{ value: "IQ", label: "Iraq" },
												{ value: "IE", label: "Ireland" },
												{ value: "IL", label: "Israel" },
												{ value: "IT", label: "Italy" },
												{ value: "JM", label: "Jamaica" },
												{ value: "JP", label: "Japan" },
												{ value: "JO", label: "Jordan" },
												{ value: "KZ", label: "Kazakhstan" },
												{ value: "KE", label: "Kenya" },
												{ value: "KP", label: "Korea" },
												{ value: "XK", label: "Kosovo" },
												{ value: "KW", label: "Kuwait" },
												{ value: "KG", label: "Kyrgyzstan" },
												{ value: "LA", label: "Lao" },
												{ value: "LV", label: "Latvia" },
												{ value: "LB", label: "Lebanon" },
												{ value: "LS", label: "Lesotho" },
												{ value: "LR", label: "Liberia" },
												{ value: "LY", label: "Libya" },
												{ value: "LT", label: "Lithuania" },
												{ value: "LU", label: "Luxembourg" },
												{ value: "MK", label: "Macedonia" },
												{ value: "MG", label: "Madagascar" },
												{ value: "MW", label: "Malawi" },
												{ value: "MY", label: "Malaysia" },
												{ value: "ML", label: "Mali" },
												{ value: "MR", label: "Mauritania" },
												{ value: "MX", label: "Mexico" },
												{ value: "MD", label: "Moldova" },
												{ value: "MN", label: "Mongolia" },
												{ value: "ME", label: "Montenegro" },
												{ value: "MA", label: "Morocco" },
												{ value: "MZ", label: "Mozambique" },
												{ value: "MM", label: "Myanmar" },
												{ value: "NA", label: "Namibia" },
												{ value: "NP", label: "Nepal" },
												{ value: "NL", label: "Netherlands" },
												{ value: "NC", label: "New Caledonia" },
												{ value: "NZ", label: "New Zealand" },
												{ value: "NI", label: "Nicaragua" },
												{ value: "NE", label: "Niger" },
												{ value: "NG", label: "Nigeria" },
												{ value: "KP", label: "North Korea" },
												{ value: "NO", label: "Norway" },
												{ value: "OM", label: "Oman" },
												{ value: "PK", label: "Pakistan" },
												{ value: "PS", label: "Palestine" },
												{ value: "PA", label: "Panama" },
												{ value: "PG", label: "Papua New Guinea" },
												{ value: "PY", label: "Paraguay" },
												{ value: "PE", label: "Peru" },
												{ value: "PH", label: "Philippines" },
												{ value: "PL", label: "Poland" },
												{ value: "PT", label: "Portugal" },
												{ value: "PR", label: "Puerto Rico" },
												{ value: "QA", label: "Qatar" },
												{ value: "XK", label: "Republic of Kosovo" },
												{ value: "RO", label: "Romania" },
												{ value: "RU", label: "Russia" },
												{ value: "RW", label: "Rwanda" },
												{ value: "SA", label: "Saudi Arabia" },
												{ value: "SN", label: "Senegal" },
												{ value: "RS", label: "Serbia" },
												{ value: "SL", label: "Sierra Leone" },
												{ value: "SG", label: "Singapore" },
												{ value: "SK", label: "Slovakia" },
												{ value: "SI", label: "Slovenia" },
												{ value: "SB", label: "Solomon Islands" },
												{ value: "SO", label: "Somalia" },
												{ value: "ZA", label: "South Africa" },
												{ value: "KR", label: "South Korea" },
												{ value: "SS", label: "South Sudan" },
												{ value: "ES", label: "Spain" },
												{ value: "LK", label: "Sri Lanka" },
												{ value: "SD", label: "Sudan" },
												{ value: "SR", label: "Suriname" },
												{ value: "SJ", label: "Svalbard and Jan Mayen" },
												{ value: "SZ", label: "Swaziland" },
												{ value: "SE", label: "Sweden" },
												{ value: "CH", label: "Switzerland" },
												{ value: "SY", label: "Syrian Arab Republic" },
												{ value: "TW", label: "Taiwan" },
												{ value: "TJ", label: "Tajikistan" },
												{ value: "TZ", label: "Tanzania" },
												{ value: "TH", label: "Thailand" },
												{ value: "TL", label: "Timor-Leste" },
												{ value: "TG", label: "Togo" },
												{ value: "TT", label: "Trinidad and Tobago" },
												{ value: "TN", label: "Tunisia" },
												{ value: "TR", label: "Turkey" },
												{ value: "TM", label: "Turkmenistan" },
												{ value: "AE", label: "UAE" },
												{ value: "UG", label: "Uganda" },
												{ value: "GB", label: "United Kingdom" },
												{ value: "UA", label: "Ukraine" },
												{ value: "US", label: "USA" },
												{ value: "UY", label: "Uruguay" },
												{ value: "UZ", label: "Uzbekistan" },
												{ value: "VU", label: "Vanuatu" },
												{ value: "VE", label: "Venezuela" },
												{ value: "VN", label: "Vietnam" },
												{ value: "EH", label: "Western Sahara" },
												{ value: "YE", label: "Yemen" },
												{ value: "ZM", label: "Zambia" },
												{ value: "ZW", label: "Zimbabwe" }
											]}
											onChange={(e) => setPersonalInfo((user) => ({...user, country: e}))}
											value={personalInfo.country}
											style={{ width: "100%" }}
										/>
									</div>

									<StyledUploadFile className="form-group">
										<label>Upload logo <small> (optional)</small></label>
										<div className="fileupload">
											<input type="file" name="fileupload" accept="image/*" className="required" onChange={e => uplaodFile(e, 'logo')} />
											{/* accept=".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"  */}
										</div>
									</StyledUploadFile>
								</>
								)
							}

							{
								steps === 2 && (
									<>
										<StyledUploadFile className="form-group">
											<label>Medical license</label>
											<div className="fileupload">
												<input type="file" name="fileupload" className="required" onChange={e => uplaodFile(e, 'document1')} />
												{/* accept=".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"  */}
											</div>
										</StyledUploadFile>
										<StyledUploadFile className="form-group">
											<label>Registration license</label>
											<div className="fileupload">
												<input type="file" name="fileupload" className="required" onChange={e => uplaodFile(e, 'document2')} />
												{/* accept=".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"  */}
											</div>
										</StyledUploadFile>
										<StyledUploadFile className="form-group">
											<label>Address proof</label>
											<div className="fileupload">
												<input type="file" name="fileupload" className="required" onChange={e => uplaodFile(e, 'document4')} />
												{/* accept=".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"  */}
											</div>
										</StyledUploadFile>

										<StyledUploadFile className="form-group">
											<label>Other document <small>(Optional)</small></label>
											<div className="fileupload">
												<input type="file" name="fileupload" className="required" onChange={e => uplaodFile(e, 'document5')} />
												{/* accept=".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"  */}
											</div>
										</StyledUploadFile>

											<StyledUploadFile className="form-group">
											<label>Identiy proof</label>
											<div className="fileupload">
												<input type="file" name="fileupload" className="required" onChange={e => uplaodFile(e, 'document3')} />
												{/* accept=".pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"  */}
											</div>
										</StyledUploadFile>
										<div className="col-md-12 p-0 no-padding form-group ">
										<label>Department</label>
										<Select
											type="select"
											name="symptoms"
											isMulti
											value={personalInfo.permission}
											// value={[{label: 'Admin', value: 'Admin', isDisabled: false}]}
											onChange={(e) => handleChange(e)}
											options={permission.map(symptom => {
												return {
													label: symptom.label,
													value: symptom.label,
													isDisabled: symptom.isDisabled
												};
											})}
											isOptionDisabled={(option) => option.isDisabled}
										/>
									</div>
								
										<StyledSelect>
											<label>
												Practitioner panel <small style={{color: 'red'}}> *</small>
											</label>
											<select className="form-control" onChange={(e) => setDoctor_panel(e.target.value)}>
												<option value="">Choose a range</option>
												<option value="1">1 practitioner</option>
												<option value="3">1-3 practitioner</option>
												<option value="5">1-5 practitioner</option>
												<option value="10">1-10 practitioner</option>
												<option value="25">1-25 practitioner</option>
												<option value="50">1-50 practitioner</option>
												<option value="500">&gt;500 practitioner</option>
											</select>
										</StyledSelect>
									</>
								)
							}

							{
								steps === 3 && (
									<>
										<StyledFinalStep className="form-group terms">

											<label class="container-checkbox">
												By clicking on "Register" you agree to our 	<a target="_blank" href="https://emed4u.com/terms-and-conditions">terms of use</a>. You can find out how we collect, use and share your data in our <a target="_blank" href="https://emed4u.com/privacy-policy">privacy policy</a>. Our cookie policy explains how we use cookies and similar technologies. 
												{/* Please accept our &nbsp;
												<a target="_blank" href="https://emed4u.com/terms-and-conditions">Terms & conditions</a>&nbsp;, &nbsp;
												<a target="_blank" href="https://emed4u.com/privacy-policy">Privacy policy</a>&nbsp;
												before Submit */}
												<input type="checkbox" value={terms} checked={terms} onChange={e => setTerms(e.target.value)} />
												<span class="checkmark"></span>
											</label>
										</StyledFinalStep>
									</>
								)
							}

							<StyledRightBottom>
								{steps != 1 && <StyledSuccessButton onClick={(e) => prevHandle(e)} className="mr-4">
									Prev
								</StyledSuccessButton>}
								<StyledSuccessButton onClick={(e) => nextHandle(e)}>
									{
										steps === 3 ? (
											// <Link to={'/organization/dashboard'}>{/* </Link> */}
											'Submit'
										) : 'Next'
									}
								</StyledSuccessButton>
							</StyledRightBottom>
						</form>
					</div>
				</StyledRightPanel>
			</div>
		</StyledContainer>
	)
}

export default OrganizationRegistration
