import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getDashboardAppointmentAPI,
	getDoctors,
	getPatients,
	getSubAdminAPI,
	getWardRoomPatients,
	getWardRoomAPI,
	payNowPayment
} from '../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';
import { globalDateFormat } from "../../../../utils";
import {
	StyledContainer,
} from './styles';

import CardComponent from '../../../../components/Card';
import InsideMenuBar from '../../../../components/InsideMenus';
import DashboardUserListing from './components/DashboardUserListing';
import DoctorListing from './components/DoctorListing'
import AppointmentsBox from '../../../../components/Appointments';
import UpgradeModal from './components/UpgradeModal';


function OrganizationDashboard(props) {

	const [state, dispatch] = React.useContext(Context);
	const [orgInfo, setOrgInfo] = React.useState({});
	const [trial, setTrial] = React.useState({
		trial: false,
		text: '',
		trialDate: null
	});
	const [wardRoomPatients, setWardRoompatients] = React.useState([])
	const [doctors, setDoctors] = React.useState([]);
	const [subAdmin, setsubAdmin] = React.useState([]);
	const [patients, setPatients] = React.useState([])
	const [appointmentsToday, setAppointmentsToday] = React.useState([]);
	const [appointmentsUpcoming, setAppointmentsUpcoming] = React.useState([]);
	const [wardRoomList, setWardRoomList] = React.useState([]);
	const [upgradeModalOpen, setUpgradeModalOpen] = React.useState(false)
	const [selectValue, setSelectValue] = React.useState('name');
	const [selectDocValue, setSelectDocValue] = React.useState('name');
	const [selectSubAdminValue, setSelectSubAdminValue] = React.useState('name');



	const fetchPatient = (value) => {
		const payload = {
			[selectValue]: value
		}
		getPatients(value.length > 0 ? payload : '').then(res => {
			loader(false);
			setPatients(res.data);
		}).catch(err => {
			setPatients([]);
			loader(false);
		})
	}

	const fetchDoctor = (value) => {
		const payload = {
			[selectDocValue]: value
		}
		getDoctors(value.length > 0 ? payload : '').then(res => {
			setDoctors(res.data);
			loader(false);
		}).catch(err => {
			setDoctors([]);
			loader(false);
		})
	}

	const fetchSubAdmin = (value) => {
		const payload = {
			[selectSubAdminValue] : value
		}
		getSubAdminAPI(value.length > 0 ? payload : '').then(res => {
			loader(false);
			setsubAdmin(res.data);
		}).catch(err => {
			setsubAdmin([]);
			loader(false);
		})
	}

	React.useEffect(() => {
		const data = JSON.parse(localStorage.getItem('organization'))
		setOrgInfo(data);

		if(data && data.trial) {
			var date1 = new Date(data.trialDate);
    	var date2 = new Date();
		
			var Difference_In_Time = date2.getTime() - date1.getTime();
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			
			var leftDays = 30 - Difference_In_Days
			setTrial({
				trial: data.trial,
				text: 'Trial',
				trialDate: Math.round(leftDays)
			})
		} else if(data && data.subscription) {
			var date1 = new Date(data.subscriptionDate);
    	var date2 = new Date();
		
			var Difference_In_Time = date2.getTime() - date1.getTime();
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			
			var leftDays = 30 - Difference_In_Days
			setTrial({
				trial: data.subscription,
				text: 'Subscribed',
				trialDate: Math.round(leftDays)
			})
		}

		loader(true);
		getDashboardAppointmentAPI().then(res => {
			loader(false);
			setAppointmentsToday(res.data.todaysAppointments);
			setAppointmentsUpcoming(res.data.upcomingAppointments);

		}).catch(err => {
			loader(false);
		})
		fetchDoctor('');
		fetchPatient('');
		fetchSubAdmin('');
		getWardRoomAPI().then(res => {
			setWardRoomList(res.data)
		}).catch(err => {
			loader(false);
		})
		getWardRoomPatients().then((res) => {
			// const IpdPatientInfo = res.data.map((item) => item.patient)
			setWardRoompatients(res.data)
		}).catch((err) => {
			loader(false)
		})
	}, []);


	const handleViewClick = (type, id) => {
		switch(type) {
			case 'Admitted patients':
				props.history.push(`/organization/patient-edit/${id}`)
				break
			case 'Patients':
				props.history.push(`/organization/patient-edit/${id}`)
				break
			case 'Doctors':
				props.history.push(`/organization/doctor-edit/${id}`)
				break
			case 'Sub-adminstrators':
				props.history.push(`/organization/update-sub-admin/${id}`)
				break
			default:
		}
	}

	const handleViewAllClick = (type) => {
		switch(type) {
			case 'Admitted patients':
				props.history.push(`/organization/rooms`)
				break
			case 'Patients':
				props.history.push(`/organization/patient-listing`)
				break
			case 'Doctors':
				props.history.push(`/organization/doctor-listing`)
				break
			case 'Sub-adminstrators':
				props.history.push(`/organization/sub-admin`)
				break
			default:
		}
	}
	
	const fetchUsers = (type, search) => {
        switch(type) {
            case 'Patients':
				loader(true);
                fetchPatient(search)
                break
            case 'Doctors':
				loader(true);
                fetchDoctor(search)
                break
            case 'Sub-adminstrators':
				loader(true);
                fetchSubAdmin(search)
                break
            default:
        }
    }

	const handleUpgradeModal = () => {
		setUpgradeModalOpen(true);
		document.body.style.overflowY = 'hidden';
	}

	const handleSelectSearchChange = (e,search) => {
		setSelectValue(e.target.value)
		if(search){
			loader(true);
			fetchPatient('')
		}
	}
	const handleSelectDocChange =  (e,search) => {
		setSelectDocValue(e.target.value);
		if(search){
			loader(true);
			fetchDoctor('');
		}
	}

	const handleSubSelectChange = (e,search) => {
		setSelectSubAdminValue(e.target.value);
		if(search){
			loader(true);
			fetchSubAdmin('');
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const payNow = () => {
		var txnid = Math.floor((Math.random() * 10000000) + 5483729);
		var data = {
			"firstname" : orgInfo?.org_name,
			"lastname" : "",
			"email" : orgInfo?.email,
			"phone" : orgInfo?.contact_number,
			"amount" : 1,
			"productinfo" : "Tele Medicine",
			"txnid" : txnid,
			"surl" : `https://api.emed4u.com/api/organization/payment/success/${orgInfo?._id}`,
			"furl" : "https://api.emed4u.com/api/organization/payment/fail"
		}
		payNowPayment(data).then(res => 
				window.open(res, '_blank', '')
			).catch(err => {
		})
	}

	return (
		<StyledContainer>
				<InsideMenuBar
					props={props}
					type={'organization'}
				/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-8 col-lg-12 col-md-8 col-sm-12">
							<div className="row">
								<div class="col-xl-4 col-lg-4 col-md-12 col-xs-12">
									<CardComponent className="mb-4 appointments-chart-card">
										<div className="widget-profile pro-widget-content">
											<div className="profile-info-widget">
											<div className="profile-info-widget-setting mt-2" onClick={() => props.history.push('/organization/settings')}>
													<i className="fas fa-user-cog menubaricon"></i>
												</div>
												<a className="booking-doc-img mt-3">
													<img src={orgInfo && orgInfo.logo ? orgInfo.logo : '/assets/img/loading-img.jpg'} alt="User Profile" />
												</a>
												<div className="profile-det-info mb-3">
													<h3>{orgInfo ? orgInfo.org_name : '-'}</h3>
													<div className="patient-details">
														<h5>Member since - {moment(orgInfo.createdAt).format(globalDateFormat)}</h5>
														<h5 className="mb-0"><i class="fas fa-map-marker-alt"></i>{orgInfo && orgInfo.address ? orgInfo.address : '-'}</h5>
														<h5><i class="fa fa-phone" aria-hidden="true" style={{transform: 'rotate(110deg)'}}></i>
															{orgInfo.contact_number ? orgInfo.contact_number : '-'}</h5>

														{trial.trial && (trial.text == 'Trial') && <div className="trial-mode">
															<span className="tr-mode">Mode : Trial</span>
															{
																<span>Expaired In : {trial.trialDate} days left</span>
															}
														</div>}

														{trial.trial && (trial.text == 'Subscribed') && <div className="trial-mode-pr">
															<span className="tr-mode">Mode : {trial.text}</span>
															{
																<span>Expaired In : {trial.trialDate} days left</span>
															}
														</div>}
														
														
													</div>
												</div>
											</div>

											{trial.trial && (trial.text == 'Trial') && <div style={{ width: "100%", color: '#fff', backgroundColor: "rgb(81, 86, 190)", borderColor: "rgb(81, 86, 190)" }} className="btn-patient-primary submit-btn p-2 pr-4 mt-1 mb-1 ml-auto " onClick={payNow}>Pay now</div>}

											<div style={{ width: "100%", backgroundColor: "#86efd3", borderColor: "#86efd3" }} className="btn-patient-primary submit-btn p-2 pr-4 mt-1 mb-1 ml-auto button-text-white btn-patient-light" onClick={handleUpgradeModal}>Upgrade</div>

										</div>
									</CardComponent>
								</div>
								<div className="col-xl-4 pl-0 col-lg-12 col-md-4 col-xs-12">
									<AppointmentsBox
										singleCard={true}
										height={"233px"}
										showTotal={true}
										org = {true}
										heading = {'Outpatient appointments'}
										todayAppointment={appointmentsToday}
										upcomingAppointment={appointmentsUpcoming}
									/>
								</div>
								<div className='col-xl-4 col-lg-6 col-md-4 p-0 col-xs-12'>
									<DashboardUserListing
										data={wardRoomPatients}
										SearchBox={false}
										wardRoom={true}
										title="Admitted patients"
										onViewClick={handleViewClick}
										onViewAllClick={handleViewAllClick}
										height={"253px"}
										redirect={false}
										totalText = {"Total inpatients"}
									/>
								</div>
								<div className='col-md-12 d-flex p-0'>
									<div className="col-xl-6 col-lg-6 col-md-6 col-xs-12">
										<DoctorListing
											data={doctors}
											title="Doctors"
											SearchBox={true}
											searchFilter={true}
											selectValue={selectDocValue}
											onViewClick={handleViewClick}
											handleSearchSelectChange={handleSelectDocChange}
											onViewAllClick={handleViewAllClick}
											fetchUsers={fetchUsers}
											height={"430px"}
											redirect={true}
											routeTitle="Add a doctor"
											route='/organization/doctor-add'
											totalText={"Total doctors"}
											{...props}
										/>
									</div>
									<div className="col-xl-6 col-lg-6 p-0 col-md-6 col-xs-12">
										<DashboardUserListing
											data={subAdmin}
											SearchBox={true}
											handleSearchSelectChange={handleSubSelectChange}
											searchFilter ={true}
											selectValue={selectSubAdminValue}
											title="Sub-adminstrators"
											onViewClick={handleViewClick}
											fetchUsers={fetchUsers}
											onViewAllClick={handleViewAllClick}
											height={"430px"}
											route='/organization/add-sub-admin'
											redirect={true}
											routeTitle="Add a sub-admin"
											totalText={"Total sub-admins"}
											{...props}
										/>
									</div>
								</div>
							</div>
							
							
						</div>
						<div className="col-xl-4  col-lg-12 col-md-4 col-sm-12">
								<div className="col-xl-12 col-lg-12 col-md-12 p-0 col-xs-12">
									<DashboardUserListing
										data={patients}
										SearchBox={true}
										handleSearchSelectChange={handleSelectSearchChange}
										searchFilter ={true}
										title="Patients"
										selectValue={selectValue}
										onViewClick={handleViewClick}
										fetchUsers={fetchUsers}
										onViewAllClick={handleViewAllClick}
										height={"420px"}
										route='/organization/patient-add'
										redirect={true}
										routeTitle="Add a patient"
										totalText={"Total patients"}
										{...props}
									/>
							</div>
							<div className="col-md-12 col-xl-12 p-0 col-xs-12 col-sm-12">
									<CardComponent className="mb-4 appointments-chart-card">
										<div className="d-flex row ml-1 current-vitals-chart-card-heading">
											<h5 className="pt-3 pl-3">Inpatient departments</h5>
										</div>
										<hr />
										<div className="row ipd-room-view scroll-bar" style={{height:"325px",overflow:"scroll",margin:"0px"}}>
											<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
												<div class="d-flex row p-3" style={{ "text-align": "center" }}><div class="col-md-4"><i class="fas fa-square" style={{ "color": "rgba(95, 239, 84, 0.59)" }}></i>  Available</div><div class="col-md-4"><i class="fas fa-square" style={{ "color": "rgba(255, 151, 184, 0.57)" }}></i>  Occupied</div><div class="col-md-4"><i class="fas fa-square" style={{ "color": "rgba(92, 76, 76, 0.7)" }}></i>  Not in service</div></div>
											</div>
											<div className="wardRoomList d-flex flex-wrap mt-2">
												{
													wardRoomList && wardRoomList.map((data, id) =>
														<div className="col-md-3 col room-view mt-2">
															<i className={`fas fa-clinic-medical room-view-icon room-view-status-${data.status}`}></i>
															<p>{data.roomNo} {data.roomType ? `(${data.roomType})` : ''}</p>
														</div>
													)
												}
											</div>
										</div>
									</CardComponent>
								</div>
						</div>
						{
											upgradeModalOpen &&
											<UpgradeModal
												setUpgradeModalOpen={setUpgradeModalOpen}
											/>
										}
								
								
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default OrganizationDashboard
