import styled from 'styled-components';
import { ReactComponent as Search } from '../../../../assets/img/search.svg';
import { ReactComponent as RightArrow } from '../../../../assets/img/right-arrow.svg';
import { ReactComponent as Video } from '../../../../assets/img/video.svg';
import { ReactComponent as Wisker } from '../../../../assets/img/left-wisker.svg';
import { ReactComponent as Map } from '../../../../assets/img/map.svg';

export const StyledContainer = styled.div`
    justify-content: center;
    display: flex;
    height: 300px;
    padding-top: 150px;
`;

export const EventWrapper = styled.div`
    padding: 32px 80px 79px;
    background: #FFFFFF;
    @media only screen and (min-width: 700px) and (max-width: 1200px) {
        padding: 32px 4% 79px;
    }

`;

export const EventTitle = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 104px;
    line-height: 136px;
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SearchField = styled.div`
    // margin: 32px 80px 79px;
`;


export const InputWrapper = styled.div`
    position: relative;
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 11px;
    left: 16px;
`;

export const Input = styled.input`
    border: 2px solid #CCCCCC;
    border-radius: 8px;
    padding-left: 48px;
    background-size: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5E5E5E;
    width: 300px;
    height: 48px;
    border: 2px solid #CCCCCC;
    border-radius: 8px;

    &.input {
        border: 2px solid #CCCCCC;
        border-radius: 8px;
        background-size: 20px;
        font-weight: 400;
        color: #5E5E5E;
        width: 213px;
        height: 32px;
        border: 1px solid #CCCCCC;
        border-radius: 6px;
        font-size: 12px;
        line-height: 16px;
        padding-left: 28px;
    }
`;

export const SearchIcon = styled(props => <Search {...props}/>)``;

export const SectionEvents = styled.div`
    margin: 56px 0 0;
`;

export const EventsHeading = styled.div`
    display: flex;
`;

export const TextWrapper = styled.div`
    width: -webkit-fill-available;
    width: -moz-available;
    border-bottom: ${(props) => props.border ? '4px solid transparent' : '1px solid #CCCCCC'};
	background-image: linear-gradient(white, white), linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	background-origin: border-box;
	background-clip: content-box, border-box;
`;

export const EventModeTitle = styled.div`
    width: -webkit-fill-available;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2B2B2B;
    cursor: pointer;
    &.colored {
        background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
    }
`;

export const SingleQuesWrapper = styled.div`
    border-bottom: 1px solid #CCCCCC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 48px 0 49px;

    @media only screen and (min-width: 700px) and (max-width: 970px) {
        display: flex;
        flex-direction: column;
    }
`;

export const Question = styled.div`
    margin: 40px 0 32px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #2B2B2B;
    width: -webkit-fill-available;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    width: 85%;
`;

export const ContentImage = styled.div`
    width: 320px;
    height: 165.28px;
    background: yellow;
    filter: drop-shadow(-4px -4px 15px rgba(192, 192, 192, 0.25)) drop-shadow(4px 4px 15px rgba(192, 192, 192, 0.25));
    border-radius: 8px;
`;

const CommonTextStyle = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
`;

export const Time = styled(CommonTextStyle)`
    font-size: 16px;
    color: #5E5E5E;
    display: flex;
    align-self: center;
    @media only screen and (min-width: 700px) and (max-width: 725px) {
        font-size: 100%;
    }
`;

export const Event = styled(CommonTextStyle)`
    font-size: 32px;
    color: #2B2B2B;
    margin: 16px 0 0;

    @media only screen and (min-width: 1060px) and (max-width: 1120px) {
        font-size: 180%;
    }

    @media only screen and (min-width: 970px) and (max-width: 1060px) {
        font-size: 150%;
    }

    @media only screen and (min-width: 700px) and (max-width: 970px) {
        font-size: 148%;
    }
    


`;

export const Location = styled(CommonTextStyle)`
    font-size: 16px;
    color: #5E5E5E;
    margin: 16px 0 0;
    
    display: flex;
    align-items: center;
`;

export const Host = styled(CommonTextStyle)`
    font-size: 16px;
    color: #5E5E5E;
    margin: 16px 0 0;
`;

export const ContentDescription = styled.div`
    margin-left: 24px;
`;

export const RegistrationLinkButton = styled.div`
    min-width: 216px;
    height: 48px;
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @media only screen and (min-width: 700px) and (max-width: 970px) {
        margin-top: 20px;
        width: 100%;
    }
    
`;

export const RightArrowIcon = styled(props => <RightArrow {...props}/>)`
    margin-left: 11px;
`;

export const IFrame = styled.iframe`
    frameborder: 0;
    border: none;
    filter: drop-shadow(-4px -4px 15px rgba(192, 192, 192, 0.25)) drop-shadow(4px 4px 15px rgba(192, 192, 192, 0.25));
    border-radius: 8px;
    max-width: 320px;
    height: 165.28px;
`;

export const EventLink = styled.div`
    margin: 16px 0 0;
    width: fit-content;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    display: flex;
    justify-content: center;
    align-self: center;
    cursor: pointer;
`;

export const VideoIcon = styled(props => <Video {...props}/>)`
    margin-right: 10px;
`;

export const WiskerWrapper = styled.div`
  display: flex;
  margin-left: 11px;
`;

export const LeftWisker = styled(props => <Wisker {...props}/>)`
`;

export const RightWisker = styled(props => <Wisker {...props}/>)`
  transform: rotate(180deg);
`;

export const ColoredText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0 6px;
`;

export const MapIcon = styled(props => <Map {...props}/>)`
    margin-right: 10px
`;