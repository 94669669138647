import React, { useContext, useEffect } from 'react';

import { Link } from 'react-router-dom';
import moment from 'moment';

import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getAllCompletedApp
} from '../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import CardComponent from '../../../../components/Card';
import TableComponent from '../../../../components/Table';
import InsideMenuBar from '../../../../components/InsideMenus';


function DoctorCompletedAppointments(props) {
	const [state, dispatch] = useContext(Context);

	const [appointment, setAppointment] = React.useState([]);

	useEffect(() => {
		loader(true);
		getAllCompletedApp().then(res => {
			setAppointment(res.data);
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}, [])

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const tableHeading = [
		{id: 1, title: 'Patient'},
		{id: 2, title: 'Appt Date'},
		{id: 3, title: 'Amount'},
		{id: 4, title: 'Feedback'},
		{id: 5, title: 'Actions'}
    ];
    
    return (
        <div>
			<InsideMenuBar 
				props={props} 
				type={'doctor'}
			/>
            <div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<div className="doctor-panel-header">
									<h3 className="mb-3">Completed appointments</h3>
								</div>

								<TableComponent tableHeading={tableHeading}>
								{
										appointment.map(item => {
											return <tr>
															<td>
																<h2 className="table-avatar">
																	<a className="avatar avatar-sm mr-2">
																		<img className="avatar-img rounded-circle" src="/assets/img/doctor-empty.png" alt="User Image" />
																	</a>
																	<a>{item.patientId.name} </a>
																</h2>
															</td>
															<td>{moment(item.slot.start).utc().format('DD/MM/YYYY')}<span class="d-block text-info">{item.slot && moment(item.slot.start).utc().format('hh:mm a')}-{moment(item.slot.end).utc().format('hh:mm a')}</span></td>
															<td>₹{item.amount} </td>
															<td><span className={item.feedback ? 'badge badge-pill bg-success-light' : 'badge badge-pill bg-warning-light'}>{item.feedback  ? 'Given' : 'Pending'}</span></td>
															<td>
																<div className="table-action">
																	<Link to={`/doctor/view-appointment/${item._id}/${item.patientId._id}`} className="btn btn-sm bg-info-light">
																		<i className="far fa-eye"></i> View
																	</Link>
					
																</div>
															</td>
														</tr>
										})
									}
								</TableComponent>
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
        </div>
    )
}

export default DoctorCompletedAppointments
