import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import CardComponent from '../../../../../components/Card';
import InputField from '../../../../../components/InputField';
import SelectField from '../../../../../components/SelectField';
import DatePicker from 'react-date-picker';
import "react-datepicker/dist/react-datepicker.css";
import './index.css'
import { toast } from 'react-toastify';
import { globalValidator } from "../../../../../utils";
import TimePicker from 'react-time-picker';

import { Context } from '../../../../../store';

import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';

import {
	StyledContainer,
	StyledHeader,
	StyledBackTo,
	StyledTitle,
	StyledAddEditPatient
} from './styles';

function AdmissionDetails(props) {
	const [state, dispatch] = useContext(Context);
	const [selectedCurrentDate, setselectedCurrentDate] = useState(false);
    const {patientDetails, setPatientDetails, SetCurrentTab, setVisitedTab, doctorsList, wardRoomList} = props;
	const [id, setId] = useState(null);

	const gotoNext = (e) => {
		e.preventDefault();
		const keys = [
			{ name: "admissionDateTime", title: "Date and Time", validateKey: "required" },
			{ name: "roomWard", title: "Bed No.", validateKey: "required" },
			{ name : "doctor", title: "Doctor", validateKey: "required"}
		];
		let valid = globalValidator(patientDetails, keys);

		if (valid === true) {
			setVisitedTab(2)
			SetCurrentTab(2);
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const handleSelectCurrent = (e) => {
		setselectedCurrentDate(e.target.checked)
		if(e.target.checked) {
			setPatientDetails({...patientDetails,admissionDateTime : new Date()});
		}
	}


	const handleRoomChange = (e) => {
		let roomName;
		wardRoomList.map((data) => {
			if(data._id === e.target.value) {
				roomName=data.roomNo;
			}
		});
		setPatientDetails({...patientDetails,roomWard:e.target.value,roomName:roomName})
	}

	const handleDoctorChange = (e) => {
		let doctorName;
		doctorsList.map((data) => {
			if(data._id === e.target.value) doctorName=data.name;
		});
		setPatientDetails({...patientDetails, doctor : e.target.value, doctorName : doctorName})
	}
	return (
		<StyledContainer>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<div class="form-group cust-do-em row ml-2">
									<input type="checkbox" id="html" value={selectedCurrentDate} onChange={handleSelectCurrent} />
									<label for="html">Select current Date & Time</label>
							</div>
                            <div className="row pt-3">
                                <div className="col-md-3 no-padding form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label>Date</label>
                                    <DatePicker minDate={new Date()} showTimeSelect value={patientDetails.admissionDateTime} className="ipdwardDatePicker" selected={patientDetails.admissionDateTime} onChange={date => setPatientDetails((patientDetails) => ({ ...patientDetails, admissionDateTime: date }))} />
                                </div>
                                <div className="col-md-3 no-padding form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label>Time</label>
                                    <TimePicker
										openClockOnFocus
										onChange={time => {
											if(time == null){
												return false
											 }
											patientDetails.admissionDateTime.setHours(time.split(':')[0]);
											patientDetails.admissionDateTime.setMinutes(time.split(':')[1]);
											setPatientDetails((patientDetails) => ({ ...patientDetails, admissionDateTime: patientDetails.admissionDateTime }))
										}}
                                        value={patientDetails.admissionDateTime}
                                        format="hh:mm a"
										className="admission-details-timepicker"
                                    />
                                </div>
                                <div className="col-md-5 no-padding ml-auto form-group">
                                    <label>MLC No.</label>
										<InputField value={patientDetails.mlcNo} onChange={e => setPatientDetails((patientDetails) => ({ ...patientDetails, mlcNo: e.target.value }))} />
                                </div>
							</div>

							<div className="row pt-3">
								<div className="col-md-5 no-padding form-group ">
									<label>Select Bed</label>
									<SelectField value={patientDetails.roomWard} onChange={handleRoomChange} options={
										wardRoomList && wardRoomList.filter((item) => item.status === 0).map((data) => {
											return {
												'id': data._id,
												'label': `${data.roomNo ? data.roomNo : ''} ${data.roomType ? `(${data.roomType})` : ''}`,
												'value': data._id,
											}
										})
									} />
								</div>
                                <div className="col-md-5 no-padding ml-auto form-group">
                                    <label>FIR No.</label>
										<InputField value={patientDetails.firNo} onChange={e => setPatientDetails((patientDetails) => ({ ...patientDetails, firNo: e.target.value }))} />
                                </div>
							</div>

                            <div className="row pt-3">
                                <div className="col-md-6 no-padding">
                                    <div className="no-padding form-group ">
										<label>Admission Under consultant</label>
										<SelectField value={patientDetails.doctor} onChange={handleDoctorChange} options={doctorsList && doctorsList?.map((data)=>{
											return {
											'id': data._id,
											'label' : data.name,
											'value' : data._id,
											}
										})} />
								    </div>
                                    <div className="no-padding form-group">
                                        <label>Reason For Admission</label>
										<InputField value={patientDetails.reason} onChange={e => setPatientDetails((patientDetails) => ({ ...patientDetails, reason: e.target.value }))} />
                                    </div>
                                </div>
                                <div className="col-md-5 no-padding ml-auto form-group" style={{boxShadow:"0px 0px 5px 2px #e3dddd"}}>
                                    <p style={{fontSize:"18px"}}>Reference Details</p>
                                    <div style={{padding:"0px 15px"}}>
                                        <div className="no-padding form-group">
                                            <label>Name</label>
                                            <InputField value={patientDetails.referernceName} onChange={e => setPatientDetails((patientDetails) => ({ ...patientDetails, referernceName: e.target.value }))} />
                                        </div>
                                        <div className="no-padding form-group">
                                            <label>Mobile No</label>
                                            <InputField value={patientDetails.referernceContactNo} onChange={e => setPatientDetails((patientDetails) => ({ ...patientDetails, referernceContactNo: e.target.value }))} />
                                        </div>
                                    </div>
                                </div>
							</div>

								<StyledHeader>
									<StyledAddEditPatient onClick={(e) => gotoNext(e)}>
										Next
									</StyledAddEditPatient>
								</StyledHeader>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default AdmissionDetails
