import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  patientInfoListLoading: false,
  error: null,
  doctorAppointmentList: [],
  patientInfo: {},
  previousAppointment: [],
  medicalDetailsByDoctor:[],
  confirmAppointment:false
};

const doctorAppointmentSlice = createSlice({
  name: 'doctorAppointment',
  initialState,
  reducers: {
    //apointment list
    getdoctorAppointmentListRequest(state) {
      state.doctorAppointmentList = [];
      state.loading = true;
      state.error = null;
    },
    getdoctorAppointmentListSuccess(state, { payload }) {
      state.doctorAppointmentList = payload;
      state.loading = false;
      state.error = null;
    },
    getdoctorAppointmentListFailure(state, { payload }) {
      state.doctorAppointmentList = [];
      state.loading = false;
      state.error = payload;
    },

    //patient details 
    getPatientDetailsRequest(state) {
      state.patientInfo = {};
      state.patientInfoListLoading = true;
      state.error = null;
    },
    getPatientDetailsSuccess(state, { payload }) {
      state.patientInfo = payload;
      state.patientInfoListLoading = false;
      state.error = null;
    },
    getPatientDetailsFailure(state, { payload }) {
      state.patientInfo = {};
      state.patientInfoListLoading = false;
      state.error = payload;
    },
    // previous appointment
    getPatientPreviousAppointmentRequest(state, { payload }) {
      state.previousAppointment = [];
      state.patientInfoListLoading = true;
      state.error = null;
    },
    getPatientPreviousAppointmentSuccess(state, { payload }) {
      state.previousAppointment = payload;
      state.patientInfoListLoading = false;
      state.error = null;
    },
    getPatientPreviousAppointmentFailure(state, { payload }) {
      state.previousAppointment = [];
      state.patientInfoListLoading = false;
      state.error = payload;
    },

    //medical history/ medical details
    getMedicalDetailsByDoctorRequest(state) {
      state.medicalDetailsByDoctor = [];
      state.patientInfoListLoading = true;
      state.error = null;
    },
    getMedicalDetailsByDoctorSuccess(state, { payload }) {
      state.medicalDetailsByDoctor = payload;
      state.patientInfoListLoading = false;
      state.error = null;
    },
    getMedicalDetailsByDoctorFailure(state, { payload }) {
      state.medicalDetailsByDoctor = [];
      state.patientInfoListLoading = false;
      state.error = payload;
    },

    //confirm appointment
    confirmAppointmentRequest(state) {
      state.confirmAppointment = false;
      state.loading= true;
      state.error = null;
    },
    confirmAppointmentSuccess(state, { payload }) {
      state.confirmAppointment = payload;
      state.loading = false;
      state.error = null;
    },
    confirmAppointmentFailure(state, { payload }) {
      state.confirmAppointment = {};
      state.loading = false;
      state.error = payload;
    },

  },
});

export const { getdoctorAppointmentListRequest, getdoctorAppointmentListSuccess, getdoctorAppointmentListFailure, 
  getPatientDetailsRequest, getPatientDetailsSuccess, getPatientDetailsFailure, 
  getPatientPreviousAppointmentRequest, getPatientPreviousAppointmentSuccess, getPatientPreviousAppointmentFailure, 
  getMedicalDetailsByDoctorRequest,getMedicalDetailsByDoctorSuccess,getMedicalDetailsByDoctorFailure, 
confirmAppointmentRequest,confirmAppointmentSuccess,confirmAppointmentFailure} = doctorAppointmentSlice.actions;

export default doctorAppointmentSlice.reducer;
