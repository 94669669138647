import React from 'react';
import moment from 'moment';

function PatientDetailModal(props) {
    const {patientData, modalTitle = "Patient Info" } = props;
    return (
        <div className="col-md-12 p-4 mt-4">
            {/* <StyledContainer> */}
                <div>
                    <h4 className="card-title">{modalTitle}</h4>
                </div>

                <div className="card-body">
                    <div className="booking-summary" style={{ display: 'flex' }}>
                        <div className="booking-item-wrap col-md-12">
								<ul className="booking-date">
									<li>Name<span>{patientData.name ? patientData.name : '-'}</span></li>
									<li>Email<span>{patientData.email ? patientData.email : '-'}</span></li>
									<li>Contact number<span>{patientData.phoneNumber ? patientData.phoneNumber : '-'}</span></li>
									<li>Gender <span>{patientData.gender ? patientData.gender : '-'}</span></li>
									<li>DOB<span>{patientData.dob ? moment(patientData.dob).format('Do MMMM YYYY') : '-'}</span></li>
									<li>Address<span>{patientData.address ? patientData.address : '-'}</span></li>
									<li>Weight<span>{patientData.weight ? patientData.weight : '-'}</span></li>
								</ul>
								
							</div>
                    </div>
                </div>
        </div>
    )
}

export default PatientDetailModal;