import styled from "styled-components";
import colors from "../../constant/colors";
import fontWeights from "../../constant/fontWeights";

export const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 10vh 20vh;
  font-family: Poppins !important;

  & .MuiTypography-root ul li {
    padding-bottom: 10px;
  }

  & .MuiTypography-root {
      width: 100%;
      text-align: left;
      align-self: flex-start;
    }

  & .centered {
    text-align: center;
    align-self: center;
  }

  & .MuiTypography-h3 {
    font-weight: ${fontWeights.fontWeight600};
    background: ${colors.primary};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0px 60px 60px;
  }

  & .MuiTypography-p {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 15px;
  }

  & .MuiTypography-h6 {
    font-family: Poppins !important;
    font-weight: ${fontWeights.fontWeight600}
    padding-bottom: 20px;
  }
`;