import styled from 'styled-components';

export const StyledContainer = styled.div`
.bg-success-light {
	background-color: #c8c9ee !important;
    border-color: #c8c9ee !important;
    color: #5156be !important;
}
.bg-info-light {
	background-color: #c8c9ee !important;
    border-color: #c8c9ee !important;
    color: #5156be !important;
}
`;


export const StyledButtonGroup = styled.div`
	margin-bottom: 20px;

	.active-btn {
		background: #5156be;
		color: #fff;
	}
`;

export const StyledButton = styled.button`
	border: 2px solid #5156be;
	border-radius: 4px;
	padding: 10px 15px !important;
	text-align: center;
	font-size: 15px;
	color: #5156be;
	text-transform: uppercase;
	font-weight: 500;
	background: transparent;
	margin-right: 20px;
`;