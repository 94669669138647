import React, { useState, useContext, useEffect }  from 'react';
import { Link } from 'react-router-dom';

import CardComponent from '../../../../../components/Card'
import InputField from '../../../../../components/InputField';
import SelectField from '../../../../../components/SelectField';

import { toast } from 'react-toastify';
import { globalValidator, getUser } from "../../../../../utils";

import { Context } from '../../../../../store';
import {
	getWardRoomByIDAPI,
	addWardRoomAPI,
	getWardRoomAPI,
} from '../../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';

import {
	StyledContainer,
	StyledHeader,
	StyledBackTo,
	StyledTitle,
	StyledAddEditPatient
} from './styles';
import InsideMenuBar from '../../../../../components/InsideMenus';

function AddRoom(props) {
	const [state, dispatch] = useContext(Context);

	const [wardRoom, setWardRoom] = useState({roomNo: '', roomType: '', status: ''});
	const [wardRoomList,setWardRoomList] = React.useState([]);
	const [id, setId] = useState('');

	useEffect(() => {
		if(props.match.params && props.match.params.id) {
			setId(props.match.params.id);
			loader(true);
			getWardRoomByIDAPI(props.match.params.id).then(res => {
				loader(false);
				// toast.success(res.message)
			// 	dispatch({
			// 		type: SET_ERROR,
			// 		payload: res.message
			// 	});
				
				setWardRoom({
					roomNo: res.data.roomNo,
					status: res.data.status,
					roomType: res.data.roomType,
				});
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
	}, [])

	React.useEffect(() => {
		loader(true);
		getWardRoomAPI().then(res=>{
			setWardRoomList(res?.data)
			loader(false);
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
		})
	}, [])

	const submitPatient = (e) => {
		e.preventDefault();
		const keys = [
			{ name: "roomNo", title: "Room No", validateKey: "required" },
			{ name: "roomType", title: "Room Type", validateKey: "required" },
			{ name: "status", title: "Status", validateKey: "required" }
		  ];
		let valid = globalValidator(wardRoom, keys);
		const wardRoomLists = wardRoomList.filter((room) => room.roomNo == wardRoom.roomNo);
		if(wardRoomLists.length){
			toast.error('Room Already Exists!')
			dispatch({
				type: SET_ERROR,
				payload: 'Room Already Exists!'
			});
			return
		}
		if (valid === true) {
			loader(true);
			addWardRoomAPI(wardRoom).then((res) => {
				loader(false);
				props.history.push('/organization/rooms');
			}).catch(err => {
				loader(false)
			})
		}
		else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<StyledHeader>
									<StyledBackTo>
										<Link to={'/organization/rooms'}>
											<i className="fa fa-arrow-left" />
											Back to listing page
										</Link>
									</StyledBackTo>

									<StyledTitle>
										{id ? 'Update' : 'Add'} Room
									</StyledTitle>

									<StyledAddEditPatient  onClick={(e) => submitPatient(e)}>
										{id ? 'Update' : 'Save'} Room
									</StyledAddEditPatient>
								</StyledHeader>

								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Room no</label>
										<InputField value={wardRoom.roomNo} type="email" onChange={e => setWardRoom((patient) => ({...patient, roomNo: e.target.value}))} />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Room Type</label>
										<InputField value={wardRoom.roomType} type="email" onChange={e => setWardRoom((patient) => ({...patient, roomType: e.target.value}))} />
									</div>

									<div className="col-md-12 no-padding form-group ">
										<label>Status</label>
										<SelectField value={wardRoom.status} onChange={e => setWardRoom((patient) => ({...patient, status: e.target.value}))} options={[{id: 1, label: 'Available', value: 0},{id: 2, label: 'Occupied', value: 1},{id: 3, label: 'Not in Service', value: 2}]} />
									</div>

								</div>

								
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default AddRoom
