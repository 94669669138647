import React, { useEffect, useState } from 'react';
import { Formik } from "formik";
import { Add } from '@mui/icons-material';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import styled from 'styled-components';
import { emergencyContacts } from '../../../utils/forms/schema.profileSettings';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../../services/authService';
import { patientProfile } from '../../../redux/user/userActions';
import PhoneField from '../../../components/PhoneField';
import { toast } from 'react-toastify';

const StyledGridContainer = styled(Grid)(({ theme }) => ({
    height: '100%',
    '& .headingText': {
        height: 18,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '18px',
        textTransform: 'capitalize',
        color: '#2B2B2B'
    },
    '& .addContactBox': {
        padding: 0,
        display: 'inline-block',
        border: '2px dashed #fff',
        background: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%) border-box',
        borderRadius: '8px',
        boxShadow: 'border-box',
        '& > .MuiBox-root': {
            display: 'flex',
            background: 'linear-gradient(90deg, rgba(9, 126, 183, 0.05) 0%, rgba(42, 171, 108, 0.05) 100%)',
            '& .MuiSvgIcon-root': {
                fill: 'url(#header-shape-gradient)',
                width: 32,
                height: 32
            },
            '& .MuiTypography-root': {
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '18px',
                textTransform: 'capitalize',
                background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
                backgroundClip: 'text',
                textFillColor: 'transparent',
            }
        },
    },
    '& .MuiInputBase-root': {
        paddingRight: 0,
    },
}))

function EmergencyContact({ screenWidth }) {
    const { patientProfileData, userData } = useSelector(state => ({
        patientProfileData: state.user.patientProfile || {},
        userData: state.auth.userData
    }))
    const dispatch = useDispatch()
    const initialValues = React.useMemo(() => {
        return {
            primary: {
                name: patientProfileData.emergencyContacts?.[0]?.name || "",
                country_code: patientProfileData.emergencyContacts?.[0]?.country_code?.toString() || "",
                phone_number: patientProfileData.emergencyContacts?.[0]?.phone_number?.toString() || "",
                relation: patientProfileData.emergencyContacts?.[0]?.relation || ""
            },
            second: {
                name: patientProfileData.emergencyContacts?.[1]?.name || "",
                country_code: patientProfileData.emergencyContacts?.[1]?.country_code?.toString() || "",
                phone_number: patientProfileData.emergencyContacts?.[1]?.phone_number?.toString() || "",
                relation: patientProfileData.emergencyContacts?.[1]?.relation || ""
            },
            third: {
                name: patientProfileData.emergencyContacts?.[2]?.name || "",
                country_code: patientProfileData.emergencyContacts?.[2]?.country_code?.toString() || "",
                phone_number: patientProfileData.emergencyContacts?.[2]?.phone_number?.toString() || "",
                relation: patientProfileData.emergencyContacts?.[2]?.relation || ""
            }
        }
    }, [patientProfileData])

    const [addContact, setAddContacts] = useState({
        second: true,
        third: true
    })

    useEffect(() => {
        setAddContacts((pre) => {
            return { ...pre, second: !initialValues?.second?.name, third: !initialValues?.third?.name }
        })
    }, [initialValues])


    const handleClick = (key) => setAddContacts(state => ({ ...state, [key]: !state[key] }))

    const handleSubmitForm = async (data) => {
        const _data = Object.values(data).filter(x => Object.values(x).some(y => y))
        const response = await updateProfile({
            emergencyContacts: _data.map((x, i) => {
                x.index = i + 1
                return x
            })
        }, userData.token)
        if (response.response_type === 'success') {
            toast.success(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
            dispatch(patientProfile({ token: userData.token }))
        } else {
            toast.error(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        }
    }

    return (
        <StyledGridContainer container spacing={0} justifyContent={'space-between'} alignItems={"flex-start"} display={'flex'} position={'relative'} minHeight={350}>
            <Formik
                initialValues={initialValues}
                validationSchema={emergencyContacts}
                onSubmit={handleSubmitForm}
                enableReinitialize
            >
                {({ handleChange, handleBlur, errors, handleSubmit, values, touched }) => (<>
                    <Grid item xs={12}>
                        <Grid container spacing={4} className='form-container'>
                            <Grid item xs={12} md={4} style={{paddingTop: '16px', marginTop: '16px'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography className='headingText'>
                                            Contact 1 (Primary)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label='Name' name="primary.name" value={values.primary.name} onChange={handleChange} onBlur={handleBlur} fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PhoneField
                                            value={`${values.primary.country_code}${values.primary.phone_number}`}
                                            name="primary.phone_number"
                                            onChange={(value, { dialCode }) => {
                                                handleChange("primary.country_code")(dialCode)
                                                handleChange("primary.phone_number")(value.slice(dialCode.length))
                                            }}
                                            onBlur={handleBlur('primary.phone_number')}
                                            touched={touched}
                                            errors={errors}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label='Relation' name="primary.relation" value={values.primary.relation} onChange={handleChange} onBlur={handleBlur} fullWidth />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid container spacing={2} paddingLeft={0}>
                                    <Grid item xs={12}>
                                        <Typography className='headingText'>
                                            {!addContact.second && <>Contact 2</>}
                                        </Typography>
                                    </Grid>
                                    {addContact.second ? <Grid item xs={12} height={192} className='addContactBox'>
                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} height={'100%'} onClick={handleClick.bind(this, 'second')}>
                                            <Box><Add /></Box>
                                            <Box><Typography>Add Contact</Typography></Box>
                                        </Box>
                                    </Grid> :
                                        <>
                                            <Grid item xs={12}>
                                                <TextField label='Name' value={values.second.name} name="second.name" onChange={handleChange} onBlur={handleBlur} fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PhoneField
                                                    value={`${values.second.country_code}${values.second.phone_number}`}
                                                    name="second.phone_number"
                                                    onChange={(value, { dialCode }) => {
                                                        handleChange("second.country_code")(dialCode)
                                                        handleChange("second.phone_number")(value.slice(dialCode.length))
                                                    }}
                                                    onBlur={handleBlur('second.phone_number')}
                                                    touched={touched}
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField label='Relation' value={values.second.relation} name="second.relation" onChange={handleChange} onBlur={handleBlur} fullWidth />
                                            </Grid>
                                        </>}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid container spacing={2} paddingLeft={0}>
                                    <Grid item xs={12}>
                                        <Typography className='headingText'>
                                            {!addContact.third && <>Contact 3</>}
                                        </Typography>
                                    </Grid>
                                    {addContact.third ? <Grid item xs={12} height={192} className='addContactBox'>
                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} height={'100%'} onClick={handleClick.bind(this, 'third')}>
                                            <Box><Add /></Box>
                                            <Box><Typography>Add Contact</Typography></Box>
                                        </Box>
                                    </Grid> :
                                        <>
                                            <Grid item xs={12}>
                                                <TextField label='Name' name="third.name" value={values.third.name} onChange={handleChange} onBlur={handleBlur} fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <PhoneField
                                                    value={`${values.third.country_code}${values.third.phone_number}`}
                                                    name="third.phone_number"
                                                    onChange={(value, { dialCode }) => {
                                                        handleChange("third.country_code")(dialCode)
                                                        handleChange("third.phone_number")(value.slice(dialCode.length))
                                                    }}
                                                    onBlur={handleBlur('third.phone_number')}
                                                    touched={touched}
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField label='Relation' name="third.relation" value={values.third.relation} onChange={handleChange} onBlur={handleBlur} fullWidth />
                                            </Grid>
                                        </>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{
                        ...screenWidth <= 900 && { marginTop: '32px', paddingBottom: '8px' }
                    }}>
                        <Button type="submit" onClick={handleSubmit} sx={{
                            color: '#FFF',
                            padding: '8px 32px',
                            background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
                            borderRadius: '8px',
                            height: 40,
                            minWidth: '201px'
                        }}>
                            <Typography sx={{
                                height: 24,
                                fontFamily: 'Poppins',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: 14,
                                lineHeight: '24px'
                            }}>
                                Update Contact
                            </Typography>
                        </Button>
                    </Grid></>)}
            </Formik>
        </StyledGridContainer>
    )
}

export default React.memo(EmergencyContact) 
