
import React from 'react';
import moment from 'moment';

import { isMobile } from "react-device-detect";
import { Context } from '../../../../store';

import { getUser } from '../../../../utils';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';
import ChatBody from './ChatBody';

import {
	StyledContainer,
	StyledBackSection,
	StyledBackTo 
} from './styles';

const apiUrl = 'https://api.emed4u.com'//'http://localhost:5000' //'https://api.emed4u.com'; 
// const socket = io(apiUrl);

const renderChat = (item, currentUser, onSetUserId, id) => (
	<a href="javascript:void(0);" className={currentUser === item._id ? 'media read-chat active' : "media"} key={item._id} onClick={() => onSetUserId(item)}>
		<div className="media-img-wrap">
			<div className="avatar">
				<img src={item.patientId.profileImage ? item.patientId.profileImage : '/assets/img/doctor-empty.png'} alt="User Image" className="avatar-img rounded-circle" />
			</div>
		</div>
		<div className="media-body">
			<div>
				<div className="user-name">{item.patientId.name}</div>
				<div className="user-last-chat">{item.conversions.length > 0 ? item.conversions[item.conversions.length - 1].text : ''} </div>
			</div>
			<div>
				<div className="last-chat-time block">{item.conversions.length > 0 ? moment(item.conversions[item.conversions.length - 1].createdAt).fromNow() : ''}</div>
				{item.unread && item.unread.id === id && <div className="badge badge-danger badge-pill">!</div>}
			</div>
		</div>
	</a>
)

function DoctorChat(props) {
	const [state, dispatch] = React.useContext(Context);

  const [id, setId] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [patientList, setPatientList] = React.useState([]);

  React.useEffect(() => {
    if(document.body.className === 'chat-page') {}
    else document.body.className += ' chat-page';
    
    const userId = getUser();
    setId(userId._id);

    const user = {
      userId: userId._id
    };
	  loader(true)
	//   socket.emit("join_doctor", user);
	// 	socket.emit("join_doctor_id", user);

	//   socket.on("patients_list_chat", data => {
	// 	  data && setPatientList(data);
	// 	  loader(false)
	//   });
  }, []);


	const removeClasses = () => {
		document.body.className = "";
	}

	const onSetUserId = (item) => {
		props.history.push(`/doctor/doctor-chat/${item._id}`)
		setCurrentUser(item._id);
		const userId = getUser();
		if(item.unread && item.unread.id === userId._id) {
			const user = {
				id: item._id,
				userId: userId._id
			};
			// socket.emit("unread_doctor_chat", user);
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}
	
	React.useEffect(() => {
		const { id } = props?.match?.params;
		if (id) setCurrentUser(id);
		else setCurrentUser(null);
	}, [props?.match?.params, props?.match?.params?.id])
	
	return (
		<StyledContainer>

			<div className="content">
				<div className="container-fluid">	
					<div className="row">
						<div className="col-xl-12">

							{
								!isMobile &&  <div className="chat-window">

																<div className="chat-cont-left">
																	<div className="chat-header">
																		<span>Communication</span>

																		<StyledBackSection>
																			<StyledBackTo to={'/doctor/schedule'} onClick={() => removeClasses()}>
																				<i className="fa fa-arrow-left" />
																				Back To Dashboard
																			</StyledBackTo>
																		</StyledBackSection>
																	</div>
																	<div className="chat-users-list">
																		<div className={`chat-scroll chat-content-left-side ${patientList?.length ? '' : 'chat-user-no-data'}`}>
																			{
																				patientList?.length ? (
																					<>
																					{patientList.filter((item) => item?.conversions?.length)
																					.sort((a, b) => moment().diff(a?.conversions?.at(-1)?.createdAt) - moment().diff(b?.conversions?.at(-1)?.createdAt))?.map(item => renderChat(item, currentUser, onSetUserId, id))}
																					{patientList.filter((item) => !item?.conversions?.length)
																					.sort((a, b) => moment().diff(a?.conversions?.at(-1)?.createdAt) - moment().diff(b?.conversions?.at(-1)?.createdAt))?.map(item => renderChat(item, currentUser, onSetUserId, id))}
																					</>
																				) : (
																					<div>No chat found</div>
																				)
																			}
																		</div>
																	</div>
																</div>

																{/* <!-- /Chat Left --> */}

																{/* <!-- Chat Right --> */}
																{
																	currentUser ?
																		<ChatBody 
																			id={currentUser}
																			// socket={socket}
																			props={props}
																			mobile={false}
																			className=""
																		/> 
																	: null
																}
																
																{
																	!currentUser && 
																	<div>
																		<div className="col-md-4 offset-4 text-center" >
																			<img 
																			src="/assets/img/chatBoard.svg" 
																				style={{
																					width: '100%',
																					marginTop: '50%'
																				}}
																			/>
																			<p style={{marginTop: '10px'}}>Select a chat to read messages</p>
																		</div>
																	</div>
																}
																

															</div>
							}


							{
								isMobile && 
									<div>
										{!currentUser && <div className="chat-cont-left">
											<div className="chat-header">
												<span>Messages</span>

												<StyledBackSection>
													<StyledBackTo to={'/doctor/schedule'} onClick={() => removeClasses()}>
														<i className="fa fa-arrow-left" />
														Back To Dashboard
													</StyledBackTo>
												</StyledBackSection>
											</div>
											<div className="chat-users-list">
												<div className="chat-scroll">
													{
														patientList && patientList.map(item => {
														return <a href="javascript:void(0);" className={currentUser === item._id ? 'media read-chat active' : "media"} key={item._id} onClick={() => onSetUserId(item)}>
																<div className="media-img-wrap">
																	<div className="avatar">
																		<img src={item.patientId.profileImage ? item.patientId.profileImage : '/assets/img/doctor-empty.png'} alt="User Image" className="avatar-img rounded-circle" />
																	</div>
																</div>
																<div className="media-body">
																	<div>
																		<div className="user-name">{item.patientId.name}</div>
																		<div className="user-last-chat">{item.conversions.length > 0 ? item.conversions[item.conversions.length - 1].text : ''} </div>
																	</div>
																	<div>
																		<div className="last-chat-time block">{item.conversions.length > 0 ?   moment(item.conversions[item.conversions.length - 1].createdAt).fromNow(): ''}</div>
																		{/* {item.msgCount && <div className="badge badge-success badge-pill">{item.msgCount}</div>} */}
																	</div>
																</div>
															</a>
														})
													}
												</div>
											</div>
										</div>}

										{
											currentUser ?
												<ChatBody 
													id={currentUser}
													// socket={socket}
													props={props}
													mobile={true}
													unSelect={() => setCurrentUser(null)}
													className='mob-chat-right-emed'
												/> 
											: null
										}
									</div>
							}

							
						</div>
					</div>		

				</div>
			</div>
		</StyledContainer>
	)
}

export default DoctorChat;