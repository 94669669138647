import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { getMedicalHistoryDataAPI } from '../../../api/doctor';
import { getPatientInfo } from '../../../api/organization';
import Card from '../../../components/Card';
import MedicalHistory from '../../../components/medicalHistory';
import HealthParameters from './components/HealthRecordChart';
import { Context } from '../../../store';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';

const PatientInfo = (props) => {
	const [state, dispatch] = React.useContext(Context);

	const [pathName, setPathName] = React.useState(props.location.search);    
    const [patientInfo, setPatientInfo] = React.useState({});
	const [data,setData] = React.useState({})
    const [showHealth, setShowHealth] = React.useState(false);
    const [medicalHistory, setMedicalHistory] = React.useState({
		allergieData: [],
		healthConditionData: [],
		medicationData: [],
		surgeriesData: [],
		vaccinationData: []
	});
    React.useEffect(() => {
        loader(true);
        const parameters = {};
        const para = pathName.split('?')
        for (const arr in para ) {
            const arrayItem = para[arr].split('&');
            parameters[arrayItem[0]] = {};
            for (const i in arrayItem) {
                if (arrayItem[i].includes('=')) {
                    let d = arrayItem[i].split('=');
                    parameters[arrayItem[0]][d[0]] = d[1]
                }
            }
        }
        if(parameters && parameters.userData && Object.keys(parameters.userData).length){
            const user = parameters.userData;
            for (let u in user){
                if(user[u] === 'true' && u !== 'lipid'){
                    setShowHealth(true);
                }
            }
        }
        if (parameters && parameters.patientInfo && Object.keys(parameters.patientInfo).length) {
            getPatientInfo(props.match.params.id).then((res) => {
                setPatientInfo(res.data);
            }).catch((err) => { })
        }
        if (parameters && parameters.userData2 && Object.keys(parameters.userData2).length) {
            getMedicalHistoryDataAPI(props.match.params.id).then(res => {
                setMedicalHistory(res.data);
                loader(false);
            }).catch(err => {
                loader(false);
                toast.error(err.response.data.error)
                dispatch({
                    type: SET_ERROR,
                    payload: err.response.data.error
                });
            })
        }
        loader(false);
        setData(parameters)

    }, [pathName])

    const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}
    return (

        <div className="content">
            <div className="container-fluid">
                {data?.timeStamp && data.timeStamp.endTime && moment().isAfter(parseInt(data.timeStamp.endTime)) ?
                    <div style={{margin:'auto',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                        <i style={{fontSize:"50px",color:"#be515a"}} class="mb-2 fas fa-exclamation-triangle"></i>
                        <h1>Sorry! Page is expired</h1>
                    </div>
                 :
                <div className="row">
                    {data && data.patientInfo && Object.keys(data.patientInfo).length ?
                        <div className='col-md-3'>
                            <Card className="mb-4">
                                <div className="widget-profile" style={{height:"383px",padding:"10px",borderRadius:"20px"}}>
                                    <h5 className='mr-auto'>Patient details</h5>
                                    <div className="profile-info-widget">
                                        {
                                            data.patientInfo && data.patientInfo.profile ? <a className="booking-doc-img">
                                                <img src={patientInfo.profileImage ? patientInfo.profileImage : "/assets/img/loading-img.jpg"} alt="User" />
                                            </a> : null
                                        }
                                        <div className="profile-det-info">
                                            {data.patientInfo && data.patientInfo.name ? <h3>{patientInfo.name}</h3> : null}
                                            <div className="patient-details">
                                                {data.patientInfo && data.patientInfo.email ? <h5>{patientInfo.email}</h5> : null}
                                                {data.patientInfo && data.patientInfo.dob ? <h5><i className="fas fa-birthday-cake"></i> {patientInfo.dob ? moment(patientInfo.dob).format('Do MMMM, YYYY') : '-'}</h5> : null}
                                                {data.patientInfo && data.patientInfo.bloodGroup ? <h5><i className="fas fa-burn"></i> Blood Group : {patientInfo.bloodGroup ? patientInfo.bloodGroup : '-'}</h5> : null}
                                                {data.patientInfo && data.patientInfo.contact ? <h5><i class="fa fa-phone" aria-hidden="true" style={{transform: 'rotate(110deg)'}}></i>
															{patientInfo.phoneNumber ? patientInfo.phoneNumber : '-'}</h5> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div> : null
                    }
                    {data && data.userData && Object.keys(data.userData).length ?
                            <HealthParameters
                                showAdd={false}
                                patientId={props.match.params.id}
                                showLipidProfile={data.userData.lipid}
                                hideBp = {!data.userData.bp}
                                hidePulse = {!data.userData.pulse}
                                hidetemp = {!data.userData.temp}
                                hideBs = {!data.userData.bloodSugar}
                                hideRr = {!data.userData.respiratory}
                                hideOxygen = {!data.userData.oxygn}
                            />
                        : null
                    }
                    { data && data.userData2 && Object.keys(data.userData2).length ? 
                    <div className="col-md-12 p-3">
                        <h2 className="cards-main-heading mb-3">Medical History</h2>
                            <MedicalHistory
                                hideMedication={!data.userData2.medications}
                                hideHealthCondition={!data.userData2.healthConditions}
                                hideSurgery={!data.userData2.surgeries}
                                hideAllergies={!data.userData2.allergies}
                                hideVaccine={!data.userData2.vaccnations}
                                medicalHistory={medicalHistory}
                                collapseShow={Object.keys(data.userData2).length <= 2}
                            />
                        </div>
                    :null}
                    
                </div>
}
                
            </div>
        </div>
    )
}

export default PatientInfo;