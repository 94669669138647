import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
import DoctorProfileSetting from "../../components/ui/Doctor/Profile";

const DoctorSettings = () => {
  return (
    <Grid container spacing={0}>
      <AppLayout>
        <DoctorProfileSetting />
      </AppLayout>
    </Grid>
  )
}

export default DoctorSettings;