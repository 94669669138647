import React from 'react'
import Slider from "react-slick";

import {
    StyledSlider
} from './styles';

function SliderComponent(props) {

  const { title = '',
    subTitle = '',
    description = '',
    heading= '' } = props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className : 'home-main-slider-full'
  };


  return (
    <StyledSlider>
       <Slider {...settings}>
          <div className={`col-12 d-flex ${props.className}`}>
            <div className="slide-image col-12 col-lg-6">
              <span className="text-secondary text-uppercase d-block mb-3">{title}</span>
              <h2 className="mb-3">{subTitle}</h2>
              <p>
                {description}
              </p>
              {heading && <h5>"{heading}"</h5>}
            </div>
            {/* <div className="col-12 col-lg-6 d-flex justify-content-center">
                <iframe src="https://www.youtube.com/embed/nuVqJ_OriR8?rel=0&controls=0&showinfo=0" width="940" allowfullscreen></iframe>
            </div> */}
          </div>
          {props.yoga ? <div className={`col-12 d-flex ${props.className2}`}>
            <div className="slide-image col-12 col-lg-6">
              <span className="text-secondary text-uppercase d-block mb-3">
              
              </span>
              <h2 className="mb-3">A balance between mind, body and soul</h2>
              <p>
                
              </p>

              
            </div>
            {/* <div className="col-12 col-lg-6 d-flex justify-content-center">
              <iframe src="https://www.youtube.com/embed/nuVqJ_OriR8?rel=0&controls=0&showinfo=0" width="940" allowfullscreen></iframe>
            </div> */}
          </div> : null}
         
        </Slider>
    </StyledSlider>
  )
}

export default SliderComponent
