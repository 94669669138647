import { makeStyles } from "@mui/styles";

import colors from "../../../constant/colors";

const usePatientHealthStyles = makeStyles((theme) => ({
  ecgWaveform: {
    width: "90%",
    height: "16vw",
    margin: "10px 10px 01px 20px",
    background: colors.black2,
    border: `1px solid ${colors.black2}`,
    borderRadius: "8px",
  },
}));
export { usePatientHealthStyles };
