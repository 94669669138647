import moment from 'moment';
import React from 'react';
import {getEcgAPI} from '../../api/patient';
import SelectField from '../SelectField';
import './style.css';

  
var updateInterval = 8;
var i = 0;

class ECGchart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentEcgId : 0,
            allEcgData : [],
        }
        this.drawGraph = this.drawGraph.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.redrawGraph = this.redrawGraph.bind(this);
      }
    componentDidMount = () =>{
        getEcgAPI(this.props.id).then((res) => {
            if(res.data.length){
                this.setState({
                    ...this.state,allEcgData:res.data[0].Details
                })
                this.drawGraph(res.data[0].Details[0].ecgData);
            } else {
                this.drawGraph([]);
            }
        }).catch((err) => {
            this.drawGraph([]);
        })
    }

    drawGraph(ecg){
            var chart1 = new window.Rickshaw.Graph({
                element: document.querySelector("#demo_chart"),
                width: this.props.width || "400",
                height: this.state.allEcgData.length ? "280" : "250",
                renderer: "line",
                min: "-65000",
                max: "65000",
                strokeWidth: 2,
                stroke: true,
                series: new window.Rickshaw.Series.FixedDuration(
                    [
                        {
                            name: "x",
                            color: "#F3232",
                        },
                        {
                            name: "y",
                            // color: "#5C8BB0",
                            color: "#F32"
                        }
                    ],
                    undefined,
                    {
                        timeInterval: updateInterval,
                        maxDataPoints: 1100
                    }
                ),

            });

            new window.Rickshaw.Graph.Axis.Y({
                graph: chart1,
                orientation: "left",
                tickFormat: function (y, index) {
                    const rem = index % 4
                    return ''
                },
                ticks: 25,
                element: document.getElementById("y_axis")
            });

            let timeIndex = 0;
            let uniqueId = -1;
            let rep = -1
            new window.Rickshaw.Graph.Axis.X({
                graph: chart1,
                // timeUnit: seconds,
                ticks: 40,
                orientation: "top",
                tickFormat: function (x, index) {
                    if (timeIndex === 0) {
                        timeIndex = new Date(x).getMilliseconds();
                    }
                    if (uniqueId !== new Date(x).getMilliseconds() % timeIndex) {
                        uniqueId = new Date(x).getMilliseconds() % timeIndex;
                        if (index > 0) {
                            if (new Date(x).getMilliseconds() % timeIndex < 9) {
                                return ''
                            }
                            if ((new Date(x).getMilliseconds() % timeIndex) - 9 === 30) {
                                uniqueId = -1;
                                i = 0;
                                timeIndex = 0;
                            }
                            return `${(new Date(x).getMilliseconds() % timeIndex) - 9}s`
                        }
                    }
                    else {
                        return ''
                    }
                },
            })
            if (ecg.length) {
                this.timerID = setInterval(function () {
                    i = i + 4;
                    insertRandomDatapoints(i);
                }, updateInterval);
            } else {
                chart1.vis.selectAll(".dot")
                    .data({})
                    .enter().append("circle")
                    .attr("class", "dot")
                    .attr("cx", function (d, i) {
                        return chart1.x(i)
                    })
                    .attr("cy", function (d, i) {
                        return chart1.y(i)
                    })
                    .attr("r", 5);
                chart1.render();
            }

            // /* Function that generates and inserts random datapoint into the chart */
            function insertRandomDatapoints() {
                let tmpData = {
                    x: i,
                    y: ecg[i]
                };
                let tmpData1 = {
                    x: i + 1,
                    y: ecg[i + 1]
                };
                let tmpData2 = {
                    x: i + 2,
                    y: ecg[i + 2]
                };
                let tmpData3 = {
                    x: i + 3,
                    y: ecg[i + 3]
                };
                //   let tmpData4 = {
                //     x: i + 4,
                //     y: ecg[i + 4]
                //   };
                //   let tmpData5 = {
                //     x: i + 5,
                //     y: ecg[i + 5]
                //   };
                //   let tmpData6 = {
                //     x: i + 6,
                //     y: ecg[i + 6]
                //   };
                //   let tmpData7 = {
                //     x: i + 7,
                //     y: ecg[i + 7]
                //   };

                chart1.vis.selectAll(".dot")
                    .data(tmpData)
                    .enter().append("circle")
                    .attr("class", "dot")
                    .attr("cx", function (d, i) {
                        return chart1.x(i)
                    })
                    .attr("cy", function (d, i) {
                        return chart1.y(i)
                    })
                    .attr("r", 5);
                chart1.series.addData(tmpData);
                chart1.series.addData(tmpData1);
                chart1.series.addData(tmpData2);
                chart1.series.addData(tmpData3);
                // chart1.series.addData(tmpData4);
                // chart1.series.addData(tmpData5);
                // chart1.series.addData(tmpData6);
                // chart1.series.addData(tmpData7);

                chart1.render();
            }
    }

    componentWillUnmount(){
        clearInterval(this.timerID);
    }
    redrawGraph(ecg) {
        const myNode = document.querySelector('#demo_chart');
        const myNodeY = document.getElementById('y_axis');
        const myNodeX = document.getElementById('x_axis');
        while (myNode && myNode.firstChild) {
            myNode.removeChild(myNode.lastChild);
        }
        while (myNodeY && myNodeY.firstChild) {
            myNodeY.removeChild(myNodeY.lastChild);
        }
        while (myNodeX && myNodeX.firstChild) {
            myNodeX.removeChild(myNodeX.lastChild);
        }
        i = 0;
        this.drawGraph(ecg)
    }

    handleChange(e){
        this.setState({ ...this.state, currentEcgId: e.target.value });
        const el = this.state.allEcgData.find((item,id) => id == e.target.value);
        clearInterval(this.timerID);
        this.redrawGraph(el.ecgData || [])
    }
    render() {
        const arr = this.state.allEcgData?.map((item, id) => {
            return {
                id: id,
                label: moment(item.createdAt).format('DD MMM YYYY h:mm a'),
                value: id
            }
        });
        return (
            <>
                <div className='select-field-ecg d-flex '>
                    <h5 className='col-md-5 ecg-heading'>Current ECG</h5>
                    <SelectField
                        value={this.state.currentEcgId}
                        onChange={this.handleChange}
                        options={arr}
                    />
                </div>
                <div id="chart_container">
                    <div id="y_axis"></div>
                    <div id="x_axis"></div>
                    <div id="demo_chart" width={this.props.width || "400"} height="325"></div>
                </div>
                <h6 className='text-danger text-center mt-1'>{this.state.allEcgData?.length ? '' : 'No ECG data found'}</h6>
            </>
        );
    }
}

export default ECGchart

