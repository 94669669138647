import React, { useEffect } from 'react';

import './style.css';

const Thankyou = () => {
	useEffect(() => {
		localStorage.removeItem('organization');
	}, [])

	return (
		<div className="pt-5 pb-5">
			<div class="container medium pt-5 pb-5">
				<div class="head-line">
					<div class="subscription-block">
						<h1>You have successfully subscribed!</h1>
						<p>This is your official confirmation. Thanks for joining Emed4u subscription. We have cleared your session for a while please login again. &nbsp;
							<br />
							 <a href="https://emed4u.com/faqs" >If you have any query go to FAQ's page</a>&nbsp;&nbsp;
						</p>
						<img src="https://www.codica.com/static/girl-newsletter.ce5960c4.svg" alt="Girl newsletter" title="Girl newsletter" />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Thankyou