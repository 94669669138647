import React from "react";
import { StyledContainer } from "../../../assets/styles/WebsiteLinks";
import { Typography } from "@mui/material";

const RefundPolicy = () => {
  return (
    <StyledContainer>
      <Typography variant="h3" className="centered">
        Refund & Cancellation Policy
      </Typography>
      <Typography variant="p">
        For internet-based subscription Services, you are free to cancel the
        Service at any time. Emed shall refund any unexpended advance fees paid
        by you at the time of cancellation of the Service. In case you wish to
        seek a refund of the advance paid by you because you do not agree with a
        modification to the Agreement, please write to . We will process refund
        of the unexpended amount.
        <br />
        <br />
        Subjected to terms under clause ‘Use of Services’ of this Agreement for
        all home-based Services, Emed shall provide refund of any unexpended
        advance fees paid by you if:
        <br />
        <br />
        <ul>
          <li>
            Emed is not able to deliver the Services and/or Products for any
            reason. In case you wish to seek a refund of the advance paid by you
            because you believe that Emed Service is not as per the
            specification provided on the Application, please write to . Emed
            will process refund of the unexpended amount provided internal
            investigation confirms the claim made by you.
          </li>
          <li>
            If the patient cancels a booking more than two hours before
            scheduled appointment time an automated refund will be issued after
            deducting transaction fee, if any.
          </li>
          <li>
            If patient cancels a booking within two hours of scheduled
            appointment time 50% automated refund will be done.
          </li>
          <li>
            In case of no show by the patient during appointed time no refund
            will be generated.
          </li>
          <li>
            If the doctors do not show up during appointed time full
            automated refund will be issued.
          </li>
          <li>
            If Appointment is taken within two hours of scheduled time and
            doctor does not show up, full automated refund will be done.
          </li>
          <li>
            If the patient does not show up during appointed time no refund
            will be issued.
          </li>
          <li>
            If the consultation does not happen due to any special
            circumstance including technical issues, an option to request refund
            will be available to the patient for a period of 7 days but the
            issuance of full or part refund will be on the sole discretion of
            Emed.
          </li>
          <li>
             If appointment / consultation is cancelled due to technical
            issues by doctor than full refund will be made.
          </li>
          <li>
            All refunds will be subject to a transaction charge that will be
            deducted while making a refund.
          </li>
        </ul>
        <br />
        In case you wish to seek a refund of the advance paid by you, please
        write to . We will process refund of the unexpended amount at the
        discretion of Emed on case-to-case basis.
      </Typography>
    </StyledContainer>
  );
};

export default RefundPolicy;
