import styled from 'styled-components';
import { ReactComponent as Search } from '../../../../assets/img/search.svg';
import { ReactComponent as RightArrow } from '../../../../assets/img/right-arrow.svg';
import { ReactComponent as Video } from '../../../../assets/img/video.svg';
import { ReactComponent as VideoWhite } from '../../../../assets/img/video-white.svg';
import { ReactComponent as Wisker } from '../../../../assets/img/left-wisker.svg';
import { ReactComponent as Map } from '../../../../assets/img/map.svg';

export const StyledContainer = styled.div`
    justify-content: center;
    display: flex;
    height: 300px;
    padding-top: 150px;
`;

export const EventWrapper = styled.div`
    // padding: 32px 80px 79px;
    padding: 16px 16px 16px;
    background: #FFFFFF;
`;

export const EventTitle = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.01em;
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    width: fit-content;
    
    @media only screen and (min-width: 320px) and (max-width: 570px) {
        margin-right: 10px;
    }
`;

export const HeaderContainer = styled.div`
    @media only screen and (min-width: 400px) and (max-width: 700px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const SearchField = styled.div`
    margin: 8px 0 0;
    @media only screen and (min-width: 570px) and (max-width: 700px) {
        margin: 0;
    }
`;


export const InputWrapper = styled.div`
    position: relative;
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 6px;
    left: 13px;
`;

export const Input = styled.input`
    border: 2px solid #CCCCCC;
    border-radius: 8px;
    // padding-left: 48px;
    padding-left: 35px;
    background-size: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5E5E5E;
    // width: 300px;
    // height: 48px;
    border: 2px solid #CCCCCC;
    border-radius: 8px;

    width: 288px;
    height: 32px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;

    &.input {
        border: 2px solid #CCCCCC;
        border-radius: 8px;
        background-size: 20px;
        font-weight: 400;
        color: #5E5E5E;
        width: 213px;
        height: 32px;
        border: 1px solid #CCCCCC;
        border-radius: 6px;
        font-size: 12px;
        line-height: 16px;
        padding-left: 28px;
    }
    @media only screen and (max-width: 440px) {
        // width: 100%;
        // margin-left: 12px;
    }
    @media only screen and (min-width: 320px) and (max-width: 400px) {
        width: 100%;
    }
`;

export const SearchIcon = styled(props => <Search {...props}/>)`
    width: 16px;
    height: 16px;
    
`;

export const SectionEvents = styled.div`
    margin: 32px 0 0;
`;

export const EventsHeading = styled.div`
    display: flex;
`;

export const TextWrapper = styled.div`
    width: -webkit-fill-available;
    width: -moz-available;
    border-bottom: ${(props) => props.border ? '2px solid transparent' : '1px solid #CCCCCC'};
	background-image: linear-gradient(white, white), linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	background-origin: border-box;
	background-clip: content-box, border-box;
`;

export const EventModeTitle = styled.div`
    width: -webkit-fill-available;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    // font-size: 24px;
    font-size: 12px;
    line-height: 32px;
    text-align: center;
    color: #2B2B2B;
    cursor: pointer;
    &.colored {
        background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
    }
`;

export const SingleQuesWrapper = styled.div`
    border-bottom: 1px solid #CCCCCC;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    padding: 16px 0 16px;
`;

export const Question = styled.div`
    margin: 40px 0 32px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #2B2B2B;
    width: -webkit-fill-available;
`;

export const Content = styled.div`
    @media only screen and (min-width: 570px) and (max-width: 700px) {
        display: flex;
        align-items: center;
    }
    @media only screen and (min-width: 320px) and (max-width: 570px) {
        width: 100%
    }
`;

export const ContentImage = styled.div`
    // width: 320px;
    // height: 165.28px;
    width: 288px;
    @media only screen and (min-width: 320px) and (max-width: 570px) {
        width: 100%;
    }
    height: 148px;
    background: yellow;
    filter: drop-shadow(-4px -4px 15px rgba(192, 192, 192, 0.25)) drop-shadow(4px 4px 15px rgba(192, 192, 192, 0.25));
    border-radius: 8px;
`;

const CommonTextStyle = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`;

export const Time = styled(CommonTextStyle)`
    // font-size: 16px;
    font-size: 12px;
    color: #5E5E5E;
    // display: flex;
    // align-self: center;
    // margin: 8px 0 0;
`;

export const Event = styled(CommonTextStyle)`
    // font-size: 32px;
    color: #2B2B2B;
    // margin: 16px 0 0;
    margin: 4px 0 0;

    font-size: 16px;
    line-height: 24px;
`;

export const Location = styled(CommonTextStyle)`
    // font-size: 16px;
    color: #5E5E5E;
    // margin: 16px 0 0;

    font-size: 12px;
    line-height: 16px;
    margin: 8px 0 0;
`;

export const Host = styled(CommonTextStyle)`
    // font-size: 16px;
    color: #5E5E5E;
    // margin: 16px 0 0;
    margin: 4px 0 0;
    font-size: 12px;
    line-height: 16px;
`;

export const ContentDescription = styled.div`
    // margin-left: 24px;
    
    @media only screen and (min-width: 570px) and (max-width: 700px) {
        margin-left: 12px;
    }

`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const RegistrationLinkButton = styled.div`

    width: 288px;
    height: 32px;
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    border-radius: 6px;

    // width: 216px;
    // height: 48px;
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    // border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
    
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    margin: 16px 0 0;
    font-size: 12px;

    @media only screen and (min-width: 320px) and (max-width: 700px) {
        width: 100%;
    }

    
`;

export const RightArrowIcon = styled(props => <RightArrow {...props}/>)`
    margin-left: 11px;
    width: 5px;
    // height: 5px;
`;

export const IFrame = styled.iframe`
    frameborder: 0;
    border: none;
    filter: drop-shadow(-4px -4px 15px rgba(192, 192, 192, 0.25)) drop-shadow(4px 4px 15px rgba(192, 192, 192, 0.25));
    border-radius: 8px;
    // width: 320px;
    // height: 165.28px;
    width: 288px;
    height: 148px;
    @media only screen and (min-width: 320px) and (max-width: 570px) {
        width: 100%;
    }

`;

export const EventLink = styled.div`
    margin: 16px 0 0;
    width: fit-content;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    display: flex;
    justify-content: center;
    align-self: center;
    cursor: pointer;
`;

export const VideoIcon = styled(props => <Video {...props}/>)`
    margin-right: 10px;
`;

export const VideoWhiteIcon = styled(props => <VideoWhite {...props}/>)`
    margin-left: 10px;
`;

export const WiskerWrapper = styled.div`
  display: flex;
//   margin-left: 11px;
    margin: 0 0 4px 4px;

    
    @media only screen and (min-width: 570px) and (max-width: 700px) {
        display: flex;
        justify-content: center;
    }
    @media only screen and (min-width: 320px) and (max-width: 570px) {
        display: flex;
        justify-content: flex-start;
    }
`;

export const LeftWisker = styled(props => <Wisker {...props}/>)`
`;

export const RightWisker = styled(props => <Wisker {...props}/>)`
  transform: rotate(180deg);
`;

export const ColoredText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0 6px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const MapIcon = styled(props => <Map {...props}/>)`
    margin-right: 10px
    width: 16px;
    height: 16px;
`;

