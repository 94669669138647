import styled from 'styled-components';

export const StyledContainer = styled.div`
	.bg-form-lan {
		background: white;
		padding: 30px 10px;
		border-radius: 20px;

		h5 {
			width: 100%;
			text-align: center;
			font-size: 18px;
		}
	}

	.main-lan-con {
		width: 80%;
		margin: 0 10%;
	}

	.lan-main-start {
    padding: 30px;
    background: white;
    border-radius: 20px;
		margin: 0px 10px;

		h5 {
			font-size: 40px !important;
			color: #28328c !important;
			width: 100%;
			text-align: center;
			margin-bottom: 20px;
		}

		.lan-contrainer {
			display: flex;
			width: 100%;
			align-items: center;
			margin: 20px 0px;

			h6 {
				font-size: 20px;
				text-align: center;
				padding-bottom: 15px;
			}

			.lan-left {
				width: 70%;
			}

			.lan-right {
				width: 30%;

				img {
					width: 150px;
				}
			}
		}
	}

	@media (max-width: 767px){ 
		.main-lan-con {
			width: 100%;
			margin: 0px 0%;
			padding: 10px !important;
		}
		.lan-main-start {
			padding: 20px;
			background: white;
			border-radius: 20px;
			margin: 0px 10px;

			.lan-contrainer {
				display: block;
	
				.lan-left {
					text-align: center;
					width: 100%;
				}
	
				.lan-right {
					text-align: center;
					width: 100%;
				}
			}
		}
		
		
	}
`;

export const StyledHeaderContainer = styled.div`

`;


export const StyledFooterContainer = styled.div`
	background-color: #f3f3f3;
	height: 220px;
	padding: 30px;

	.logo-fo {
		text-align: center;

		img {width: 300px;}
	}

	.fo-content {
		text-align: center;
    padding: 20px 0px;

		ul {
			list-style: none;
			display: flex;
			justify-content: center;

			li {
				padding: 0 10px;
			}
		}
	}

`;

export const StyledCardWrapper = styled.div`
  text-align: center;
  min-height: 240px;
  padding: 25px 20px;
  height: 310px;

  &:hover {
    background-color: #19ce67;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 8px;
    
    h3, span {
        color: #fff;
    }

    button {
      background: #fff;
      color: #19ce67;  
    }
  }
`;

export const StyledCardIconWrapper = styled.div`
  margin-bottom: 20px;
`;

export const StyledCardIcon = styled.img`
  background: white;
  border-radius: 20%;
  padding: 10px;
  box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
`;

export const StyledCardTitle = styled.h3`
  padding-bottom: 5px;
  font-size: 22px;
  font-weight: 700;
`;

export const StyledCardSubTitle = styled.span`
  color: #212529;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
`;

export const StyledCardButton = styled.button`
  margin-top: 20px;
  background: #19ce67;
  color: #ffffff;
  border: none;
  line-height: initial;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 17px 50px 15.5px 25px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  display: inline-flex;
  font-weight: 600;
  position: relative;

  i {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    display: inline-block;
    height: 33px;
    width: 33px;
    line-height: 33px;
    color: #ffffff;
    border-radius: 50%;
    background-color: #8ee9b2;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
`;

export const StyledHomeSlider = styled.div`
	.bd-home-slider {
		max-height: 500px;
		height: 500px;
		@media (max-width: 767px){
			max-height: 100%;
			height: 100%;
			padding: 0px;
		}

		span {
			color: #fff !important;
			@media (max-width: 767px){
				color: #6c757d!important;
			}
		}
		h3 {
			color: #fff !important;
			@media (max-width: 767px){
				color: #272b41!important;
			}
		}
		li {
			color: #fff !important;
			@media (max-width: 767px){
				color: #757575!important;
			}
		}
	}

	.slide-image {
		@media (max-width: 767px){
			padding: 0px 15px;
		}
	}

	.bg-img-001 {
		background: url(/assets/img/emed4urevised.png);
		background-repeat: no-repeat;
		background-size: contain;
		    background-size: 100% 100%;
		width: 100%;
		@media (max-width: 767px){
			background: none !important;
		}
	}

	.bg-img-002 {
		background: url(/assets/img/emed4u2nd.png);
		background-repeat: no-repeat;
		background-size: contain;
		    background-size: 100% 100%;
		width: 100%;
		@media (max-width: 767px){
			background: none !important;
		}
	}

	.bg-img-003 {
		background: url(/assets/img/emed4u3rd1.svg);
		//background-repeat: no-repeat;
		background-size: contain;
		    background-size: 100% 100%;
		width: 100%;
		@media (max-width: 767px){
			background: none !important;
		}
	}

	.bg-img-004 {
		background: url(/assets/img/4thbg.png);
		background-repeat: no-repeat;
		background-size: contain;
		    background-size: 100% 100%;
		width: 100%;
		@media (max-width: 767px){
			background: none !important;
		}
	}

	.bg-home-mobile-001 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/emed4urevised.png) !important;
			background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.bg-home-mobile-002 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/emed4u2nd.png) !important;
			background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.bg-home-mobile-003 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/emed4u3rd1.png) !important;
			//background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.bg-home-mobile-004 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/4thbg.png) !important;
			//background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.img-dsk-sh-book {
		display: block !important;
		@media (max-width: 767px){
			display: none !important;
		}
	}

	.img-dsk-sh-book-01 {
		@media (max-width: 767px){
			z-index: 999;
			height: 115px;
			a {
				z-index: 999;
				width: 120px;
				margin-top: 39px;
				font-size: 14px;
			}
		}
	}

	.img-dsk-sh {
		display: none !important;

		img {
			width: 150%;
			height: 100%;
			margin-left: -238px;
		}
		@media (max-width: 767px){
			display: flex !important;
		}
	}
`;