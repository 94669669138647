import { apiPost, apiGet, apiPut } from "../utils";

export function orgLoginAPI(org) {
	return apiPost(`/api/organization/login`, org);
}

export function postLoginAPI(org) {
	return apiPost(`/api/organization/login`, org);
}

export function postSignupAPI(org) {
	return apiPost(`/api/organization/register`, org);
}

export function postForgotAPI(org) {
	return apiPost(`/api/organization/forgotpassword`, org);
}

export function updatedDocuments(id, documents) {
	return apiPut(`/api/organization/submit-documents/${id}`, documents);
}


export function updateOrganization(id, org) {
	return apiPut(`/api/organization/update-orginfo/${id}`, org);
}

export function getOrgLogo(id) {
	return apiGet(`/api/organization/org-logo/${id}`);
}

export function getOrgPatientReport(id) {
	return apiGet(`/api/organization/patient-reports/${id}`);
}

export function updateOrganizationLogo(id, org) {
	return apiPut(`/api/organization/update-logo/${id}`, org);
}

export function resetPasswordOrg(org) {
	return apiPut(`/api/organization/resetpassword`, org);
}

export function fileUploading(file) {
	return apiPost(`/api/organization/fileUploading`, file);
}

export function getDoctors(query) {
	return apiGet(`/api/organization/doctors-listing`, query);
}

export function addDoctor(data) {
	return apiPost(`/api/organization/add-doctor`, data);
}

export function getDoctorInfo(id) {
	return apiGet(`/api/organization/doctors-info/${id}`);
}

export function updateDoctorInfo(id, data) {
	return apiPut(`/api/organization/update-doctor/${id}`, data);
}

export function getPatients(query) {
	return apiGet(`/api/organization/patients-listing`, query);
}

export function addPatient(data) {
	return apiPost(`/api/organization/add-patient`, data);
}

export function addPatientById(data) {
	return apiPost(`/api/organization/add-patient-by-id`, data);
}

export function getPatientInfo(id) {
	return apiGet(`/api/organization/patient-info/${id}`);
}

export function updatePatientInfo(id, data) {
	return apiPut(`/api/organization/update-patient/${id}`, data);
}

export function getInvoicesOrg(query) {
	return apiGet(`/api/organization/invoices`, query);
}


export function getAppointmentsOrg(query) {
	return apiGet(`/api/organization/appointments`, query);
}

export function getDashboardAppointmentAPI(query) {
	return apiGet(`/api/organization/org-dashboard-appointments`, query);
}

export function getInvoiceDetailsAPI(id) {
	return apiGet(`/api/organization/patient-invoice-detail/${id}`);
}

export function getCouponsAPI(query) {
	return apiGet(`/api/organization/get/coupons`, query);
}

export function getCouponByIdAPI(id) {
	return apiGet(`/api/organization/get/coupon/${id}`);
}

export function addCouponAPI(body) {
	return apiPost(`/api/organization/add/coupon`, body);
}

export function updateCouponAPI(id, body) {
	return apiPut(`/api/organization/update/coupon/${id}`, body);
}

export function getOrgAPI() {
	return apiGet(`/api/organization/org-info`);
}

// Sub admin 
export function getSubAdminAPI(query) {
	return apiGet(`/api/organization/get/allsubadminusers`, query);
}

export function getSubAdminByIdAPI(id) {
	return apiGet(`/api/organization/get/subadminuser/${id}`);
}

export function addSubAdminAPI(body) {
	return apiPost(`/api/organization/add/subadminuser`, body);
}

export function updateSubAdminAPI(id, body) {
	return apiPut(`/api/organization/update/subadminuser/${id}`, body);
}


export function getTermsAPI() {
	return apiGet(`/api/organization/get-terms-by-org`);
}

export function addTermsAPI(body) {
	return apiPost(`/api/organization/add/terms-org`, body);
}

export function updateTermsAPI(id, body) {
	return apiPut(`/api/organization/update/terms-org-detail/${id}`, body);
}

export function updateCashPaymentAPI(id, data) {
	return apiPut(`/api/organization/update-appointment-cashpayment-status/${id}`, data);
}


// IPD

export function getWardRoomAPI() {
	return apiGet(`/api/organization/get/ward-rooms`);
}

export function getWardRoomByIDAPI(id) {
	return apiGet(`/api/organization/get/ward-room/${id}`);
}

export function addWardRoomAPI(data) {
	return apiPost(`/api/organization/add/ward-rooms`, data);
}
export function addPatientToWard(data){
	return apiPost(`/api/organization/sub-user/patient-admit-ward`, data);
}
export function getWardRoomPatients() {
	return apiGet(`/api/organization/get/admit-patient-ward-rooms`)
}
export function addServiceWardPatient(id,data) {
	return apiPut(`/api/organization/sub-user/add-service-ward-patient/${id}`,data)
}
export function getPatientWardRoomById(id) {
	return apiGet(`/api/organization/get/admit-patient-ward-rooms-by-Id/${id}`)
}
export function addDischargePatientWard(id,data) {
	return apiPut(`/api/organization/sub-user/discharge-patient-ward/${id}`,data)
}
export function addDischargePatientByDoctor(id,data) {
	return apiPut(`/api/organization/doctor/discharge-patient-ward/${id}`,data)
}
export function getPatientFollowUpDetails(id) {
	return apiGet(`/api/organization/get/patient-followup-details/${id}`)
}
export function addPatientFollowUpForm(id,data) {
	return apiPost(`/api/organization/add/patient-followup-form/${id}`,data)
}
export function AddDiagnosis(id,data) {
	return apiPut(`/api/organization/update/patient-followup-form-diagnosis/${id}`,data)
}
export function addPatientFollowUpLab(id,data) {
	return apiPut(`/api/organization/update/patient-followup-form-lab/${id}`,data)
}
export function addPatientFollowUpRadiology(id,data) {
	return apiPut(`/api/organization/update/patient-followup-form-radiology/${id}`,data)
}
export function addPatientFollowUpNurseMorning(id,data) {
	return apiPut(`/api/organization/update/patient-followup-form-nurse-morning/${id}`,data)
}
export function addPatientFollowUpNurseAfterNoon(id,data) {
	return apiPut(`/api/organization/update/patient-followup-form-nurse-afternoon/${id}`,data)
}
export function addPatientFollowUpNurseEvening(id,data) {
	return apiPut(`/api/organization/update/patient-followup-form-nurse-evening/${id}`,data)
}
export function addPatientFollowUpNurseNight(id,data) {
	return apiPut(`/api/organization/update/patient-followup-form-nurse-night/${id}`,data)
}
export function addPatientFollowUpDoctorMorning(id,data) {
	return apiPut(`/api/doctor/update/patient-followup-form-doctor-morning/${id}`,data)
}
export function addPatientFollowUpDoctorAfterNoon(id,data) {
	return apiPut(`/api/doctor/update/patient-followup-form-doctor-afternoon/${id}`,data)
}
export function addPatientFollowUpDoctorEvening(id,data) {
	return apiPut(`/api/doctor/update/patient-followup-form-doctor-evening/${id}`,data)
}
export function addPatientFollowUpDoctorNight(id,data) {
	return apiPut(`/api/doctor/update/patient-followup-form-doctor-night/${id}`,data)
}
export function addPatientFollowUpFormEdication(id, data) {
	return apiPut(`/api/organization/update/patient-followup-form-medication/${id}`,data)
}
export function deletePatientFollowUpFormEdication(id, data) {
	return apiPut(`/api/organization/update/patient-followup-form-delete-medication/${id}`,data)
}
export function getIpdHistoryOfPatient(id){
	return apiGet(`/api/organization/get/ipd-history-patient/${id}`)
}
export function getAppointmentHistory(id){
	return apiGet(`/api/organization/doctor-all-patient-app/${id}`)
}


// Inventory end-points
export function getInventory() {
	return apiGet(`/api/organization/get/inventory`);
}

export function getInventoryByDoctor(id) {
	return apiGet(`/api/organization/get/inventory-doctor/${id}`);
}

export function getInventoryById(id) {
	return apiGet(`/api/organization/get/inventory/${id}`);
}

export function addInventory(data){
	return apiPost(`/api/organization/add/inventory`, data);
}

export function addInventoryDoctor(data){
	return apiPost(`/api/organization/add/inventory-doctor`, data);
}

export function updateInventory(id,data){
	return apiPut(`/api/organization/update/inventory/${id}`, data);
}

export function payNowPayment(data){
	return apiPost(`/api/organization/buy/subscription`, data);
}