import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Formik } from "formik";
import { toast } from "react-toastify";
import UploadFile from "../../../controls/UploadFile";
import TabComponent from "../../../Tab";
import PhoneField from "../../../PhoneField";
import FieldWrapper from "../../../InputField/TextFieldWrapper";
import SelectionDropdown from "../../../control/SelectionDropdown";
import { genderList, settingTabs } from "../../../../constant/staticLists";
import { capitalizeFirstLetter } from "../../../../constant/staticHelper";
import defaultUserPng from "../../../../assets/img/user.png";
import { doctorProfileSchema, resetDoctoPassword } from "../../../../utils/forms/schema.doctorProfileSettingSchema";
import { doctorProfileAction, updateDoctorProfileAction } from "../../../../redux/user/doctor/doctorAction";
import { doctorlogoutAction } from "../../../../redux/auth/authActions";
import * as mainService from "../../../../services/mainService";
import { DOCTOR_UPLOAD } from "../../../../services/apiUrl";
import { StyledCrossIcon, StyledSecondaryButton, UserImage } from "../../../../assets/styles/CommonStyles";
import HeadingComponent from "../../HeadingComponent";
import { useStyles } from "../../../../assets/styles/PatientDashboardStyles";
import { profileStyles } from "../../../../assets/styles/DoctorProfileSettingStyles";
import { commonMuiStyles } from "../../../../assets/styles/CommonMUIStyles";
import strings from "../../../../constant/strings";

const DoctorProfileSetting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const profileSettingClasses = profileStyles();
  const styleClasses = commonMuiStyles();
  const inputRefs = useRef();
  const [onLoading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [fileType, setFileType] = useState();
  const [tabValue, setTabValue] = useState(0);

  const { doctorProfileData, userData } = useSelector((state) => ({
    userData: state.auth.userData,
    doctorProfileData: state.doctor.doctorProfileData,
  }));

  const genderLists = Object.keys(genderList).map((key) => ({
    label: capitalizeFirstLetter(key.replace(/_/g, " ").toLowerCase()),
    level: genderList[key],
  }));

  useEffect(() => {
    dispatch(doctorProfileAction(userData.token));
  }, [dispatch, userData.token]);

  useEffect(() => {
    if (image) {
      handleSubmitImage();
    }
  }, [fileType]);

  const handleSubmitImage = async () => {
    const formData = new FormData();
    formData.append('file', image);

    try {
      const response = await mainService.fileUpload(DOCTOR_UPLOAD, formData, userData.token);
      const uploadedImage = response?.response?.data;
      if (uploadedImage) {
        await handleImageUpdate(uploadedImage);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleImageUpdate = async (profile_image) => {
    setLoading(true);
    try {
      const res = await mainService.updateProfileByDoctorAPI({ token: userData.token, data: { profile_image } });
      if (res.response_type === "fail") {
        toast.error(res?.response?.message, { position: "bottom-center", autoClose: 2000 });
      } else {
        toast.success("Profile image updated successfully.", { position: "bottom-center", autoClose: 2000 });
        dispatch(doctorProfileAction(userData.token));
      }
    } catch (error) {
      console.error('Error updating profile image:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProfileUpdate = async (values, { resetForm }) => {
    if (tabValue === 1) {
      delete values.confirmPassword;
    }
    try {
      const res = await mainService.updateProfileByDoctorAPI({ token: userData.token, data: values });
      if (res.response_type === "fail") {
        toast.error(res?.response?.message, { position: "bottom-center", autoClose: 2000 });
      } else {
        resetForm();
        dispatch(doctorProfileAction(userData.token));
        toast.success(tabValue === 0 ? "Profile updated successfully." : "Password updated successfully.", { position: "bottom-center", autoClose: 2000 });

        if (tabValue === 1) {
          dispatch(doctorlogoutAction(userData.token));
          localStorage.clear()
          history.push('/');
        }
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={`${classes.mainCont} ${profileSettingClasses.mainSecCont}`}>
        <HeadingComponent navigate="doctor_profile" text={{ primary: strings.doctorsProfile }} />
        <StyledCrossIcon src={"/assets/img/x.png"} alt="x" onClick={() => history.goBack()} />
      </Grid>

      <Grid item xs={12} className={profileSettingClasses.imageCont}>
        <div>
          <IconButton
            sx={{
              position: "absolute",
              right: "calc(50% - 55px)",
            }}
          >
            <BorderColorIcon onClick={() => inputRefs.current.click()} />
          </IconButton>

          <UploadFile
            inputRefs={inputRefs}
            image={image}
            setImage={setImage}
            fileType={fileType}
            setFileType={setFileType}
            limit={5}
            navigate="single"
          />

          <UserImage
            src={image || doctorProfileData?.profile_image || defaultUserPng}
            className={profileSettingClasses.imageStyle}
            alt="Doctor Profile"
          />
        </div>
      </Grid>

      <Grid item xs={12}>
        <TabComponent
          tabs={settingTabs}
          tabValue={tabValue}
          setTabValue={setTabValue}
          handleTab={(e, newValue) => setTabValue(newValue)}
          customTab={`${styleClasses.customTab} ${classes.tabFontSize}`}
          customTabIndicator={styleClasses.customTabIndicator}
        />
      </Grid>

      <Grid container spacing={2} className={profileSettingClasses.formikCont}>
        <Formik
          initialValues={tabValue === 0 ? doctorProfileData : { password: '', newPassword: '', confirmPassword: '' }}
          validationSchema={tabValue === 0 ? doctorProfileSchema : resetDoctoPassword}
          onSubmit={handleProfileUpdate}
          enableReinitialize
        >
          {({ values, handleSubmit, setFieldValue, errors, touched, setTouched }) => (
            <>
              {tabValue === 0 ? (
                <>
                  <Grid item lg={4} md={6} xs={12}>
                    <FieldWrapper type="text" name="name" label={strings.doctorName} />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <PhoneField
                      name="phone_number"
                      value={`${values.country_code} ${values.phone_number}`}
                      onChange={(value, { dialCode }) => {
                        setFieldValue("phone_number", value.slice(dialCode.length));
                        setFieldValue("country_code", dialCode);
                      }}
                      onBlur={() => setTouched({ ...touched, phone_number: true })}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <SelectionDropdown
                      name="gender"
                      value={values.gender}
                      label={strings.selectGender}
                      list={genderLists}
                      handleDropdownValue={(data) => setFieldValue("gender", data.level)}
                      className={styleClasses.customAutocomplete}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper type="text" name="address" label={strings.selectAddress} />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <FieldWrapper name="password" label={strings.enterCurrentPassword} />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper name="newPassword" label={strings.enterNewPassword} />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldWrapper name="confirmPassword" label={strings.enterConfirmPassword} />
                  </Grid>
                </>
              )}

              <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <StyledSecondaryButton disabled={Object.keys(errors).length > 0} onClick={handleSubmit}>
                  Update Profile
                </StyledSecondaryButton>
              </Grid>
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default DoctorProfileSetting;
