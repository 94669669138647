import React, {useContext} from 'react';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	getFAQSAPI
} from '../../../api/website';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';


import {
    StyledContainer
} from './styles';

import { useMediaQuery } from 'react-responsive'
import MobileAboutPage from '../../LandingPage/MobileAboutPage';
import AboutPage from '../../LandingPage/AboutPage';
function FAQ() {
	const [state, dispatch] = useContext(Context);

	const [faqs, setFaqs] = React.useState([]);

	React.useEffect(() => {
		loader(true);
		getFAQSAPI().then(res => {
			setFaqs(res.data);
			loader(false);
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}, []);

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const isMobile = useMediaQuery({
		query: '(min-width: 700px)'
	  });
	  return !isMobile ? <MobileAboutPage isMobile={!isMobile} /> : <AboutPage />

	return (
		<StyledContainer>
				<section class="faq-section">
				<div class="container">
					<div class="row">
					<div class="col-md-6 offset-md-3">

							<div class="faq-title text-center pb-3">
									<h2>FAQ</h2>
							</div>
					</div>
					<div class="col-md-12 mb-5">
							<div class="faq" id="accordion">
								{
									faqs.map((item, index) => {
										return 	<div class="card" key={item._id}>
															<div class="card-header" id={item.question}>
																	<div class="mb-0">
																		
																			<h5 class="faq-title" data-toggle="collapse" data-target={`#faqCollapse-${index+1}`}data-aria-expanded="true" data-aria-controls={`faqCollapse-${index+1}`}>
																					<span class="badge">{index+1}</span>{item.question}
																			</h5>
																	</div>
															</div>
															<div id={`faqCollapse-${index+1}`} class="collapse" aria-labelledby={item.question} data-parent="#accordion">
																	<div class="card-body">
																			<p>{item.answer} </p>
																	</div>
															</div>
													</div>
									})
								}

							
									
									
							</div>
					</div>
				</div>
			</div>
			</section>
		</StyledContainer>
	)
}

export default FAQ
