import React from 'react';

import ReactStars from "react-rating-stars-component";

import moment from 'moment';

import { toast } from 'react-toastify';

import { Context } from '../../../../store';
import {
	getAppointmentDetailsAPI,
	cancelAppointmentAPI,
	submitFeedbackAPI
} from '../../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import CardComponent from '../../../../components/Card'
import InputField from '../../../../components/InputField';

import {
	StyledContainer,
	StyledButton,
	StyledButtonModfiy,
	StyledButtonCancel
} from './styles';

import { getPayLogo } from '../../../../constants';
import InsideMenuBar from '../../../../components/InsideMenus';

function ViewAppointment(props) {

	const [state, dispatch] = React.useContext(Context);

	const [id, setId] = React.useState(null);
	const [appointment, setAppointment] = React.useState({});
	const [feedback, setFeedback] = React.useState({
		feedback: '',
		rating: '',
		emed4u_feedback: '',
		emed4u_rating: '',
	});

	const fetchAppointmentDetails = () => {
		if(props.match.params && props.match.params.id) { 
			setId(props.match.params.id);
			loader(true);
			getAppointmentDetailsAPI(props.match.params.id).then(res => {
				loader(false);
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				
				setAppointment(res.data);

				setFeedback({
					feedback: res.data.feedback,
					rating: res.data.rating,
					emed4u_feedback: res.data.emed4u_feedback,
					emed4u_rating: res.data.emed4u_rating
				})

			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
	}

	React.useEffect(() => {
		fetchAppointmentDetails()
	}, [])

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const getStatusClass = (item) => {
		if(item === 0) {
			return 'badge badge-pill bg-warning-light'
		} else if(item === 1 || item === 4) {
			return 'badge badge-pill bg-success-blue text-white'
		} else if(item === 2) {
			return 'badge badge-pill bg-danger-light'
		}  else if(item === 3) {
			return 'badge badge-pill bg-danger-light'
		}
	}

	const getStatus = (item) => {
		if(item === 0) {
			return 'Pending'
		} else if(item === 1) {
			return 'Confirm'
		} else if(item === 2) {
			return 'Reject'
		}  else if(item === 3) {
			return 'Cancelled'
		} else if(item === 4) {
			return 'Completed'
		}
	}

	const rescheduleAppointment = (e) => {
		e.preventDefault();
		props.history.push(`/patient/reschedule-book-appointment/${appointment.doctorId._id}/${appointment._id}`);
	}

	const cancelAppointment = (e) => {
		e.preventDefault();

		loader(true);
		cancelAppointmentAPI(id, {status: 3, slotId: appointment.slot._id}).then(res => {
				loader(false);
				toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				
				props.history.push(`/patient/appointment-listing`);

			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
	}

	const submitFeedBack = () => {
		loader(true);
		submitFeedbackAPI(id, feedback).then(res => {
			loader(false);
			toast.success(res?.message || 'Feedback succussfully added!')
			dispatch({
				type: SET_ERROR,
				payload: res?.message || 'Feedback succussfully added!'
			});
			fetchAppointmentDetails();
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'patient'}
			/>
				<div className="content">
					<div className="container-fluid">
						<div className="row">

							<div className="col-md-12 col-lg-12 col-xl-12">

								{
												appointment.status === 4 && (
													<CardComponent className="p-4 mt-2 mb-4">
														{
															appointment.finalReport && (
																<div className="card-body">
																	<div className="mb-2">
																		<h4 style={{color: '#60c27b'}}>
																			<span style={{color: 'black',padding: '2px 10px', margin: '0px 5px'}}>Final report link : <a style={{color: '#5156be'}} href={appointment.pdf} target="_blank">View PDF</a></span>
																		</h4>
																		<hr />
																		<h4 style={{color: '#000'}}>Diagnosis and observation:</h4>
																		<p style={{paddingLeft:'10px',color:"#5156be"}}>- {appointment.finalReport.impression_diagnosis}</p>
																	</div>

																		{
																			appointment.finalReport.medication && appointment.finalReport.medication.map((item, index) => {
																				return <CardComponent className='row mb-2 mt-2 p-2 pl-4 p-relative' >
																								<p style={{position: 'absolute', background: '#5156be', color: '#fff', padding: '5px', borderRadius: '6px', left: '8px'}}>{index+1} .</p>
																								<div className="mb-2 col-md-12">
																										<h4 style={{color: '#000'}}>Medication name:</h4>
																										<p style={{paddingLeft:'10px',color:"#5156be"}}>- {item.medication_name}</p>
																								</div>
																								<div className="mb-2 col-md-3">
																										<h4 style={{color: '#000'}}>Form of medicine:</h4>
																										<p style={{paddingLeft:'10px',color:"#5156be"}}>- {item.medicine_type}</p>
																								</div>

																								<div className="mb-2 col-md-3">
																										<h4 style={{color: '#000'}}>Strength:</h4>
																										<p style={{paddingLeft:'10px',color:"#5156be"}}>- {item.strength_name}</p>
																								</div>

																								<div className="mb-2 col-md-3">
																										<h4 style={{color: '#000'}}>Strength type:</h4>
																										<p style={{paddingLeft:'10px',color:"#5156be"}}>- {item.strength_type}</p>
																								</div>


																								<div className="mb-2 col-md-3">
																										<h4 style={{color: '#000'}}>Dose regimen:</h4>
																										<p style={{paddingLeft:'10px',color:"#5156be"}}>- {item.dos_quantity_name}</p>
																								</div>
																		</CardComponent>
																		}) 
																}

																	{/* <div className="mb-2">
																		<h4 style={{color: '#60c27b'}}>Strength:</h4>
																		<p style={{paddingLeft:'10px'}}>- {appointment.finalReport.strength_name}</p>
																	</div>

																	<div className="mb-2">
																		<h4 style={{color: '#60c27b'}}>Strength type:</h4>
																		<p style={{paddingLeft:'10px'}}>- {appointment.finalReport.strength_type}</p>
																	</div>

																	<div className="mb-2">
																		<h4 style={{color: '#60c27b'}}>Method of intake:</h4>
																		<p style={{paddingLeft:'10px'}}>- {appointment.finalReport.method_intake}</p>
																	</div>

																	<div className="mb-2">
																		<h4 style={{color: '#60c27b'}}>Quantity:</h4>
																		<p style={{paddingLeft:'10px'}}>- {appointment.finalReport.dos_quantity_count}</p>
																	</div>


																	<div className="mb-2">
																		<h4 style={{color: '#60c27b'}}>Quantity intake:</h4>
																		<p style={{paddingLeft:'10px'}}>- {appointment.finalReport.dos_quantity_name}</p>
																	</div>

																	<div className="mb-2">
																		<h4 style={{color: '#60c27b'}}>When:</h4>
																		<p style={{paddingLeft:'10px'}}>- {appointment.finalReport.dos_quantity_when}</p>
																	</div> */}

																	<div className="mb-2">
																		<h4 style={{color: '#000'}}>Other Instructions:</h4>
																		<p style={{paddingLeft:'10px',color:"#5156be"}}>- {appointment.finalReport.notes}</p>
																	</div>

																	{/* <div className="mb-2">
																		<h4 style={{color: '#60c27b'}}>Duration start on:</h4>
																		<p style={{paddingLeft:'10px'}}>- {moment(appointment.finalReport.duration_start).format('DD/MM/YYYY')}</p>
																	</div>

																	<div className="mb-2">
																		<h4 style={{color: '#60c27b'}}>Duration till:</h4>
																		<p style={{paddingLeft:'10px'}}>- {moment(appointment.finalReport.duration_end).format('DD/MM/YYYY')}</p>
																	</div> */}

																</div>
															)
														}
													</CardComponent>
												)
											}

								{
									appointment && 
									<div className="row">
										<div className="col-md-4">
											<CardComponent>
												<div className="card-header">
													<h4 className="card-title">Booking Summary</h4>
												</div>
												
												<div className="card-body">
													<div className="booking-doc-info">
														<a className="booking-doc-img">
															<img src={appointment.doctorId && appointment.doctorId.profileImage ? appointment.doctorId.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" />
														</a>
														<div className="booking-info">
															<h4><a >{appointment.doctorId && appointment.doctorId.name}</a></h4>
															<div className="rating">
																<span className="d-inline-block average-rating">{appointment.doctorId && appointment.doctorId.department}</span>
															</div>
															<div className="clinic-details">
																<p className="doc-location"><i className="fas fa-map-marker-alt"></i> {appointment.doctorId && appointment.doctorId.address}</p>
															</div>
														</div>
													</div>

													<div className="booking-summary">
														<div className="booking-item-wrap">
															<ul className="booking-date">
																<li>Appt. Date <span>{appointment.slot && moment(appointment.slot.start).utc().format('Do MMMM, YYYY')}</span></li>
																<li>Appt. Time <span>{appointment.slot && moment(appointment.slot.start).utc().format('hh:mm a')} - {appointment.slot && moment(appointment.slot.end).utc().format('hh:mm a')}</span></li>
																<li>Last updated <span>{appointment.last_updated ? moment(appointment.last_updated).format('Do MMMM, YYYY') : '-'}</span></li>
															</ul>
															<ul className="booking-fee">
																<li>Consulting Fee <span>{appointment.amount}</span></li>
																{
																	appointment.appointmentType === 0 && <>	<li>Payment method <span><img src={getPayLogo(appointment.paymentMode)} height="30px" style={{paddingRight: '10px'}} />{appointment.paymentMode}</span></li>
																	<li>Payment code <span>{appointment.paymentModeCode}</span></li>
																	</>
																}

																{
																	appointment.pendingAmount ? <li>Pending amount <span>{appointment.pendingAmount}</span></li> : null
																}
															</ul>
															<div className="booking-total">
																<ul className="booking-total-list">
																	<li>
																		<span>Booking Status</span>
																		<span className={getStatusClass(appointment.status)} style={{float: 'right'}}>{getStatus(appointment.status)}</span>
																	</li>
																	{/* <li>
																		<span>Appt. Status	</span>
																		<span className="total-cost">In-progress</span>
																	</li> */}
																</ul>
															</div>
														</div>
													</div>
												</div>
											</CardComponent>
											
											
										</div>

										<div className="col-md-8">

											
												{/* <div className="form-group">
													<label>Send message to doctor</label>
													<InputField  />
													<StyledButton class="btn btn-primary submit-btn" >Send</StyledButton>
												</div>

												<hr /> */}

												{
													appointment && appointment.status === 3 && 
													<CardComponent className="p-4 mb-3">
														<h5>You have cancelled the appointment!</h5>
													</CardComponent>
 												}

												{
												appointment.slot && moment(appointment.slot.start).utc().format() > moment().format() &&	(appointment.status === 0 || appointment.status === 1) && (
														<CardComponent className="p-4 mb-3">
															<StyledButtonModfiy class="btn btn-primary submit-btn" data-toggle="modal" data-original-title="test" data-target="#exampleModal">
																Click to reschedule your appointment
															</StyledButtonModfiy>

															<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
																<div class="modal-dialog" role="document">
																		<div class="modal-content">
																				<div class="modal-header">
																						<h5 class="modal-title" id="exampleModalLabel">Are you sure?</h5>
																						<button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""><span aria-hidden="true">×</span></button>
																				</div>
																				{/* <div class="modal-body">...</div> */}
																				<div class="modal-footer">
																						
																						<button
																							class="btn btn-primary"
																							type="button"
																							data-dismiss="modal"
																							data-original-title=""
																							title=""
																							onClick={(e) =>
																								getAppointmentDetailsAPI(props.match.params.id).then(res => {
																									setAppointment(res.data);
																									setFeedback({
																										feedback: res.data.feedback,
																										rating: res.data.rating,
																										emed4u_feedback: res.data.emed4u_feedback,
																										emed4u_rating: res.data.emed4u_rating
																									})
																									if(res.data.status == 2){
																										return toast.error("Appointment already rejected by doctor!")
																									}else {
																										rescheduleAppointment(e)
																									}
																								}).catch(err => {
																									toast.error(err?.response?.data?.error)
																									dispatch({
																										type: SET_ERROR,
																										payload: err?.response?.data?.error
																									});
																								})
																							}
																						>
																							Yes
																						</button>

																						<button class="btn btn-danger" type="button" data-dismiss="modal" data-original-title="" title="">No</button>
																				</div>
																		</div>
																</div>
															</div>

															<StyledButtonCancel
																class="btn btn-primary submit-btn"
																data-toggle="modal"
																data-original-title="test"
																data-target="#cancelAppointment"
																// onClick={() => fetchAppointmentDetails()}
															>
																Cancel your appointment
															</StyledButtonCancel>

															<div class="modal fade" id="cancelAppointment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
																<div class="modal-dialog" role="document">
																		<div class="modal-content">
																				<div class="modal-header">
																						<h5 class="modal-title" id="exampleModalLabel">Are you sure?</h5>
																						<button class="close" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""><span aria-hidden="true">×</span></button>
																				</div>
																				{/* <div class="modal-body">...</div> */}
																				<div class="modal-footer">
																						
																						<button
																							class="btn btn-primary"
																							type="button"
																							data-dismiss="modal"
																							data-original-title=""
																							title=""
																							onClick={ (e) => 
																								getAppointmentDetailsAPI(props.match.params.id).then(res => {
																									setAppointment(res.data);
																									setFeedback({
																										feedback: res.data.feedback,
																										rating: res.data.rating,
																										emed4u_feedback: res.data.emed4u_feedback,
																										emed4u_rating: res.data.emed4u_rating
																									})
																									if(res.data.status == 2){
																										return toast.error("Appointment already rejected by doctor!")
																									}else {
																										cancelAppointment(e)
																									}
																								}).catch(err => {
																									toast.error(err?.response?.data?.error)
																									dispatch({
																										type: SET_ERROR,
																										payload: err?.response?.data?.error
																									});
																								})
																							}
																						>
																							Yes
																						</button>

																						<button class="btn btn-danger" type="button" data-dismiss="modal" data-original-title="" title="">No</button>
																				</div>
																		</div>
																</div>
															</div>
														</CardComponent>
													) 
												}

												{
												appointment.status === 4 && !appointment.feedback && (
													<CardComponent className="p-4">
														<h4>Please provide your valuable feedback to doctor </h4>
														<div className="form-group">
															<InputField placeholder='Your feedback is valuable' value={feedback.feedback} onChange={e => setFeedback((user) => ({...user, feedback: e.target.value}))}  />
															<label className='mt-2'>Rate your consultation</label>
															
															<ReactStars
																count={5}
																value={feedback.rating}
																isHalf={true}
																edit={true}
																size={40}
																activeColor="#ffd700"
																onChange={(e) => setFeedback((user) => ({...user, rating: e}))}
															/>
															{/* <StyledButton class="btn btn-primary submit-btn" >Submit your feedback</StyledButton> */}
														</div>

														{/* <hr /> */}
														
														{/* <h4>Please provide your valuable feedback to Emed4u (How's interface of this website) </h4> */}
														<div className="form-group">
															{/* <label>Feedback (write something...)</label>
															<InputField  value={feedback.emed4u_feedback} onChange={e => setFeedback((user) => ({...user, emed4u_feedback: e.target.value}))} />
															<ReactStars
																count={5}
																value={feedback.emed4u_rating}
																isHalf={true}
																edit={true}
																size={40}
																activeColor="#ffd700"
																onChange={(e) => setFeedback((user) => ({...user, emed4u_rating: e}))}
															/> */}
															<StyledButton class="btn btn-primary submit-btn" onClick={() => submitFeedBack()}>Submit your feedback</StyledButton>
														</div>
													</CardComponent>
												)
											}
											
											
											
										</div>
									</div>
								}

								
										
								
								
								

							</div>
						</div>
					</div>
				</div>

		</StyledContainer>
	)
}

export default ViewAppointment
