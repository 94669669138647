
import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    fontWeight: 'light',
    paddingTop:"20px",
    paddingBottom:"40px"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  drView: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 0px',
    borderBottom: '2px solid #D3D0CF'
  },
  patientView: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0px 20px 0px',
    borderBottom: '2px solid #D3D0CF',
    fontSize: '12px',
  },
  drDetailsSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  boldtext: {
    fontWeight: '700',
  },
  subHeading: {
    fontWeight:'bold',
    fontSize:"14px"
  }
});
const PdfPreview = (props) => {

  return (
    <PDFViewer  style={{height:"100%" ,width: "100%"}}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.drView}>
              <View>
                <Text style={{ letterSpacing: "3px", fontWeight: 'heavy', fontSize: "40px", color: 'green' }}>EMED4U</Text>
                <Text style={{}}>Health & care t your doorstep</Text>
              </View>
              <View style={{ marginLeft: "auto" }}>
                <Text style={{ color: 'black', fontWeight: 'hairline' }}>Emed4u</Text>
                <View style={{ flexDirection: 'row' }}><Text style={{ fontWeight: 'demibold' }}>Address : </Text><Text style={{}}>Chandigarh, India</Text></View>
                {/* <View style={{ flexDirection: 'row' }}><Text>Contact number : </Text><Text style={{}}>7658064535</Text></View> */}
              </View>
            </View>
            <View style={styles.patientView}>
              <View style={{border: '2px solid black',flexDirection:'row', padding:"10px",fontSize:"12px"}}>
                <View style={{flex:1}}>
                  <View style={{flexDirection:'row'}}>
                    <Text>Patient name:</Text>
                    <Text style={{marginLeft:'auto'}}>{props.patientFormData.name}</Text>
                  </View>
                  {
                    props.patientFormData.gender &&
                    <View style={{ flexDirection: 'row' }}>
                      <Text>Gender:</Text>
                      <Text style={{ marginLeft: 'auto' }}>{props.patientFormData.gender}</Text>
                    </View>
                  }
                  {
                    props.patientDetails.referernceName !== '' &&
                    <View style={{ flexDirection: 'row' }}>
                      <Text>Reference Name:</Text>
                      <Text style={{ marginLeft: 'auto' }}>{props.patientDetails.referernceName}</Text>
                    </View>
                  }
                  {
                    props.patientDetails.referernceContactNo !== '' &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>Reference Number:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientDetails.referernceContactNo}</Text>
                      </View>
                  }
                </View>
                <View style={{marginLeft:'auto',flex:1,paddingLeft:"40px"}}>
                  {
                    props.patientFormData?.bloodGroup !== '' &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>Blood Group:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientFormData?.bloodGroup}</Text>
                      </View>
                  }
                
                  {
                    props.patientFormData?.dob !== "" &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>Patient age:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{new Date(props.patientFormData?.dob).toLocaleString()}</Text>
                      </View>
                  }
                  {
                    props.patientFormData?.city !== "" &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>City:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientFormData?.city}</Text>
                      </View>
                  }
                  {
                    props.patientFormData.phoneNumber !== "" &&
                    <View style={{ flexDirection: 'row' }}>
                      <Text>Phone Number:</Text>
                      <Text style={{ marginLeft: 'auto' }}>{props.patientFormData.phoneNumber}</Text>
                    </View>
                  }
                </View>
              </View>
              <View style={{marginTop:"20px",marginBottom:"15px"}}>
                <Text style={{marginBottom:"10px"}}>Admission Details</Text>
                <View style={{ border: '2px solid black', flexDirection: 'row', padding: "10px", fontSize: "12px" }}>
                  <View style={{ flex: 1 }}>
                    {
                      props.patientDetails?.roomName !== "" &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>Bed No:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientDetails?.roomName}</Text>
                      </View>
                    }
                    
                    {
                      props.patientDetails?.mlcNo !== "" &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>Mlc No:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientDetails?.mlcNo}</Text>
                      </View>
                    }
                    {
                      props.patientDetails?.firNo !== "" &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>Fir No:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientDetails.firNo}</Text>
                      </View>
                    }
                    {
                      props.patientDetails?.BPSystolic !== "" &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>BPSystolic:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientDetails.BPSystolic}</Text>
                      </View>
                    }
                    {
                      props.patientDetails?.BPDiastolic !== "" &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>BPDiastolic:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientDetails.BPDiastolic}</Text>
                      </View>
                    }
                    {
                      props.patientDetails?.pulse !== "" &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>Pulse:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientDetails.pulse}</Text>
                      </View>
                    }
                    
                  </View>
                  <View style={{ marginLeft: 'auto', flex: 1, paddingLeft: "40px" }}>
                    {
                      props.patientDetails?.admissionDateTime !== '' &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>Admission Date:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientDetails.admissionDateTime.toLocaleDateString()}</Text>
                      </View>
                    }
                    {
                      props.patientDetails?.admissionDateTime !== '' &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>Admission Time:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientDetails.admissionDateTime.toLocaleTimeString()}</Text>
                      </View>
                    }

                    {
                      props.patientDetails?.spo2 !== "" &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>Spo2:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientDetails?.spo2}</Text>
                      </View>
                    }
                    {
                      props.patientDetails?.temp !== "" &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>Temp:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientDetails?.temp}</Text>
                      </View>
                    }
                    {
                      props.patientDetails.reason !== '' &&
                      <View style={{ flexDirection: 'row' }}>
                        <Text>Reason of Admission:</Text>
                        <Text style={{ marginLeft: 'auto' }}>{props.patientDetails.reason}</Text>
                      </View>
                    }
                  </View>
                </View>
              </View>

            </View>

            <View style={{padding: '10px 0px' }}>
              <Text>Patient Details</Text>
            </View>
            <View style={styles.patientView}>
              <View style={{ display: 'flex', flexDirection: 'row', paddingBottom: "20px" }}>
                <View style={styles.drDetailsSection}>
                  {
                    props.patientDetails.diagnosis !== '' &&
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <Text style={styles.subHeading}>Provisional Diagnosis: </Text>
                      <Text style={{ marginLeft: "40px" }}>{props.patientDetails.diagnosis}</Text>
                    </View>
                  }
                  {
                    props.patientDetails.illessSumary !== '' ?
                      <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <Text style={styles.subHeading}>Patient Illness Summary</Text>
                        <Text style={{ marginLeft: "40px" }}>{props.patientDetails.illessSumary}</Text>
                      </View> : null
                  }
                  {
                    props.patientDetails.chiefComplaints[0].complaints !== '' &&
                    <View style={{ marginTop: "10px" }}>
                      <Text style={styles.subHeading}>Chief Complaints And Duration : </Text>
                      <View style={{ display: "flex", flexDirection: "row", paddingTop: "10px", paddingBottom: "10px",paddingLeft: "20px", paddingRight: "20px"}}>
                        <View style={{ flex: 4 }}>
                          <Text style={{ paddingBottom: "5px" }}>Complaints</Text>
                          {
                            props.patientDetails.chiefComplaints.map((data) =>
                              <Text> {data.complaints} </Text>
                            )
                          }
                        </View>
                        <View style={{ flex: 2 }}>
                          <Text style={{ paddingBottom: "5px" }}>Duration</Text>
                          {
                            props.patientDetails.chiefComplaints.map((data) =>
                              <Text> {data.duration}</Text>
                            )
                          }
                        </View>
                      </View>
                    </View>
                  }
                  {
                    props.patientDetails.generalAppearce[0].comments !== '' &&
                    <View style={{ marginTop: "5px" }}>
                      <Text style={styles.subHeading}>General Appearance: </Text>
                      <View style={{ display: "flex", flexDirection: "row", paddingTop: "10px", paddingBottom: "10px",paddingLeft: "20px", paddingRight: "20px"}}>
                        <View style={{ flex: 4 }}>
                          <Text style={{ paddingBottom: "5px" }}>General</Text>
                          {
                            props.patientDetails.generalAppearce.map((data) =>
                              <Text> {data.general} </Text>
                            )
                          }
                        </View>
                        <View style={{ flex: 2 }}>
                          <Text style={{ paddingBottom: "5px" }}>Comments</Text>
                          {
                            props.patientDetails.generalAppearce.map((data) =>
                              <Text> {data.comments}</Text>
                            )
                          }
                        </View>
                      </View>
                    </View>
                  }
                </View>
              </View>
            </View>
            <View style={{padding: '10px 0px' }}>
              <Text style={{fontWeight:"heavy"}}>Patient History</Text>
            </View>
            <View style={styles.patientView}>
              <View style={{ display: 'flex', flexDirection: 'row', paddingBottom: "20px" }}>
                <View style={styles.drDetailsSection}>
                  {
                    props.patientDetails.signification[0] !== '' &&
                    <View style={{ marginTop: "5px" }}>
                      <Text style={styles.subHeading}>Signification Past Medical and Surgical History : </Text>
                      <View style={{ display: "flex", flexDirection: "row", padding: "10px" }}>
                        <View style={{ flex: 4 }}>
                          {
                            props.patientDetails.signification.map((data,id) =>
                              <Text>{id+1}{"."} {data} </Text>
                            )
                          }
                        </View>
                      </View>
                    </View>
                  }
                  {
                    props.patientDetails.familyHistory[0] !== '' &&
                    <View style={{ marginTop: "10px" }}>
                      <Text style={styles.subHeading}>Family History significant/relevent to diagnosis or treatment : </Text>
                      <View style={{ display: "flex", flexDirection: "row", padding: "10px" }}>
                        <View style={{ flex: 4 }}>
                          {
                            props.patientDetails.familyHistory.map((data,id) =>
                              <Text>{id+1}{"."} {data} </Text>
                            )
                          }
                        </View>
                      </View>
                    </View>
                  }
                  {
                    props.patientDetails.historyOfAlcohalism[0] !== '' &&
                    <View style={{ marginTop: "10px" }}>
                      <Text style={styles.subHeading}>History of alcohalism tabacco or substance abuse : </Text>
                      <View style={{ display: "flex", flexDirection: "row", padding: "10px" }}>
                        <View style={{ flex: 4 }}>
                          {
                            props.patientDetails.historyOfAlcohalism.map((data,id) =>
                              <Text>{id+1}{"."} {data} </Text>
                            )
                          }
                        </View>
                      </View>
                    </View>
                  }
                </View>
              </View>
            </View>
            <View style={{display: 'flex', flexDirection: 'row',marginTop:"40px"}}>
              <View>
              <Text>{props.patientDetails.doctor.name}</Text>
              </View>
              <View style={{ marginLeft: "auto" }}>
                  <Text>Health Staff</Text>
                  <Text>{props.patientDetails.doctor.department}</Text>
              </View>
            </View>
          </View>
         
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default PdfPreview;