import React from 'react';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';

import {
	StyledContainer,
	StyledHeading,
	StyledDoctor,
	StyledDoctorSearch,
	StyledAddDoctor
} from './styles';

import { Context } from '../../../../store';
import {
	getDoctors,
	getOrgAPI
} from '../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import CardComponent from '../../../../components/Card'
import TableComponent from '../../../../components/Table';
import InputField from '../../../../components/InputField';
import Pagination from '../../../../components/Pagination';

import moment from 'moment';
import Search from '../../../../components/Search';
import InsideMenuBar from '../../../../components/InsideMenus';

const tableHeading = [
	{id: 1, title: 'Doctor'},
	{id: 2, title: 'Department'},
	{id: 3, title: 'Status'},
	{id: 4, title: 'Last login'},
	{id: 5, title: 'Actions'}
];

const options = [
	{ id: 0, label: 'Id', value: '_id' },
	{ id: 1, label: 'Name', value: 'name' },
	{ id: 2, label: 'Email', value: 'email' },
	// { id: 3, label: 'Status', value: 'status' },
	{ id: 4, label: 'Contact Number', value: 'contact' }
]

function DoctorListing(props) {

	const [state, dispatch] = React.useContext(Context);
	const [doctors, setDoctors] = React.useState([]);
	const [panel, setPanel] = React.useState(null);
	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('_id');

	const fetchDoctors = () => {
		loader(true);
		getDoctors().then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setDoctors(res?.data);
		}).catch(err => {
			loader(false);
			setDoctors([]);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	React.useEffect(() => {
		loader(true);
		fetchDoctors();
		getOrgAPI().then(res => {	
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setPanel(res?.data.doctor_panel);
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}, [])

	const searchDoctor = () => {
		loader(true);
		const payload = {
            [selectValue]: search
        }
		getDoctors(payload).then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setDoctors(res?.data);
		
		}).catch(err => {
			loader(false);
			setDoctors([]);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}


	const [doctorPaginate, setDoctorPaginate] = React.useState([]);
	const pageChangeDoctor  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setDoctorPaginate(data);
	}

	React.useEffect(() => {
        if(search === '') {
            fetchDoctors();
        }
    }, [search]);

	React.useEffect(() => {
        fetchDoctors();
    }, [selectValue])

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-2">
								<StyledHeading></StyledHeading>
								<StyledDoctor>
								<div className="doctor-panel-filters row col-md-12 p-4">
									<Search
										selectValue={selectValue} 
										setSelectValue={setSelectValue} 
										search={search} 
										setSearch={setSearch} 
										searchPatient={searchDoctor} 
										name={"Doctor"}
										options={options}
									/>
									<div className="col-md-2 mt-5 mb-0 no-padding form-group doctor-active-btn-div">
										{
											doctors?.length > panel ? <StyledAddDoctor>Upgrade</StyledAddDoctor> : <Link style={{width: '100%'}} to={'/organization/doctor-add'}><StyledAddDoctor>Add a doctor</StyledAddDoctor></Link>
										
										}
									</div>
									</div>
									
								</StyledDoctor>
								<TableComponent tableHeading={tableHeading}>
									{
										doctors?.length ? doctorPaginate.map(item => {
											return <tr key={item._id}>
																<td>
																	<h2 className="table-avatar">
																		<a className="avatar avatar-sm mr-2">
																			<img className="avatar-img rounded-circle" src={item.profileImage ? item.profileImage : "/assets/img/doctors/doctor-thumb-01.jpg"} alt="User Image" />
																		</a>
																		<a>{item.name}</a>
																	</h2>
																</td>
																<td>{item.department ? item.department : '-'}</td>
																<td><span className={item.status === 0 ? 'badge badge-pill bg-success-blue text-white' : 'badge badge-pill bg-danger-light'}>{item.status === 0 ? 'Active' : 'Deactive'}</span></td>
																<td>{item.lastLogin ? moment(item.lastLogin).format('DD/MM/YYYY, hh:mm a') : '-'} </td>
																<td>
																	<div className="table-action">
																		<Link to={`/organization/doctor-edit/${item._id}`} className="btn btn-sm bg-info-light-blue">
																			<i className="far fa-eye"></i> View
																		</Link>
																	</div>
																</td>
															</tr>
										}): null
									}
									
								</TableComponent>
								{doctors?.length == 0 ? (<h5 style={{width: '100%', textAlign: 'center', margin: '2% 0 1%'}}>Sorry! There are no Doctors to display!</h5>) : null}
								{doctors?.length > 0 && <Pagination items={doctors} onChangePage={pageChangeDoctor} />}

							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default DoctorListing
