import React from "react";
import { Grid } from '@mui/material';
import PatientHealth from "../../../components/ui/Patient/PatientHealth";
import AppLayout from "../../SideBar";

const Patient = () => {
    return (
        <Grid container spacing={0}>
            <AppLayout>
                <PatientHealth />
            </AppLayout>
        </Grid>
    )
}

export default Patient;