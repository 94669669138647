import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { StyledTableContainer, StyledTableCell, StyledDiv } from './MedicalHistory';


const PatientDetails = ({data}) => {

  const readBloodGroup = (num) => {
    switch(num){
      case 1 : return "A+"
      case 2 : return "A-"
      case 3 : return "B+"
      case 4 : return "B-"
      case 5 : return "O+"
      case 6 : return "O-"
      case 7 : return "AB+"
      case 8 : return "AB-"      
  }

  }
  return (
    <div>

{ (data === undefined ||  Object.keys(data).length === 0 )? <StyledDiv>No Personal Details requested </StyledDiv> :
      <StyledTableContainer  >
      <Table>
        <TableHead></TableHead>

        <TableBody>
          { data?.name === undefined ? '' : <TableRow>
            <StyledTableCell component="td"  align="center" >Name</StyledTableCell>
            <TableCell component="td"  align="center">{data?.name}</TableCell>
          </TableRow>  }
          
          { data?.phoneNumber === undefined ? '' : 
          <TableRow>
          <TableCell component="td"  align="center">Contact Number</TableCell>
          <TableCell component="td"  align="center">{data?.phoneNumber}</TableCell>
        </TableRow> }
          
          { data?.dob === undefined ?  '' :  <TableRow>
            <TableCell component="td"  align="center">Date of birth</TableCell>
            <TableCell component="td"  align="center">{data?.dob}</TableCell>
          </TableRow> }
          
          { data?.email === undefined ?  '' :  <TableRow>
            <TableCell component="td"  align="center"> Email address</TableCell>
            <TableCell component="td"  align="center">{data?.email}</TableCell>
          </TableRow>}
          
          { data?.bloodGroup === undefined ?  '' :   <TableRow>
            <TableCell component="td"  align="center">Blood Group</TableCell>
            <TableCell component="td"  align="center">{readBloodGroup(data?.bloodGroup)}</TableCell>
          </TableRow> }
          
        </TableBody>
      </Table>
    </StyledTableContainer> }

    </div>
  )
}

export default PatientDetails;