import styled from 'styled-components';

export const StyledHomeSlider = styled.div`

	.slider-main-mobile-screen {
		.slick-dots {
			bottom: 12px !important;
			display: block !important;
			li {
				margin: 0;
			}
			button {
				width: 10px;
				height: 10px;
				border-radius: 50px;
				border: 2px solid #fff;
				background: transparent;
			}
			.slick-active {
				button {
					background-color: #fff;
				}
			}
		}
	}

	.bd-home-slider {
		max-height: 97vh;
		height: 97vh;
		@media (max-width: 767px){
			max-height: 100%;
			height: 100%;
			padding: 0px;
		}
		@media (max-width: 450px){
			height: 200px !important;
			display: flex !important;
			align-content: center !important;
		  }

		span {
			color: #fff !important;
			@media (max-width: 767px){
				color: #6c757d!important;
			}
		}
		h1 {
			color: #fff !important;
			font-size: 72px;
			font-weight: 700;
			line-height: 94px;
			@media (max-width: 767px){
				color: #272b41!important;
			}
			@media (max-width: 450px){
			    color: #fff !important;
				font-size: 16px;
				line-height: 24px;
				width: 200px;
			}
		}
		li {
			color: #fff !important;
			@media (max-width: 767px){
				color: #757575!important;
			}
		}
	}

	.slide-image {
		@media (max-width: 767px){
			padding: 0px 15px;
		}
	}

	.bg-img-001 {
		background: url(/assets/img/emed4u3rd1.svg);
		background-repeat: no-repeat;
		background-size: contain;
		background-size: 100%;
		width: 100%;
		// @media (max-width: 767px){
		// 	background: none !important;
		// }
	}

	.bg-img-002 {
		background: url(/assets/img/emed4u3rd2.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-size: 100%;
		width: 100%;
		// @media (max-width: 767px){
		// 	background: none !important;
		// }
	}

	.bg-img-003 {
		background: url(/assets/img/emed4u3rd3.png);
		//background-repeat: no-repeat;
		background-size: contain;
		background-size: 100%;
		width: 100%;
		// @media (max-width: 767px){
		// 	background: none !important;
		// }
	}

	.bg-img-004 {
		background: url(/assets/img/4thbg.png);
		background-repeat: no-repeat;
		background-size: contain;
		    background-size: 100% 100%;
		width: 100%;
		// @media (max-width: 767px){
		// 	background: none !important;
		// }
	}

	.bg-img-002 {
		background: url(/assets/img/emed4u3rd2.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-size: 100%;
		width: 100%;
		// @media (max-width: 767px){
		// 	background: none !important;
		// }
	}

	.bg-home-mobile-001 {
		background: none !important;
		@media (max-width: 767px){
			// background: url(/assets/img/emed4u3rd1.svg) !important;
			// background-repeat: no-repeat !important;
			// background-size: contain !important;
			// width: 100% !important;
		}
	}

	.bg-home-mobile-002 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/emed4u3rd2.svg) !important;
			background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.bg-home-mobile-003 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/emed4u3rd3.svg) !important;
			//background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.bg-home-mobile-004 {
		background: none !important;
		@media (max-width: 767px){
			background: url(/assets/img/4thbg.png) !important;
			//background-repeat: no-repeat !important;
			background-size: contain !important;
			width: 100% !important;
		}
	}

	.img-dsk-sh-book {
		display: block !important;
		@media (max-width: 767px){
			display: none !important;
		}
	}

	.img-dsk-sh-book-01 {
		@media (max-width: 767px){
			z-index: 999;
			height: 115px;
			a {
				z-index: 999;
				width: 120px;
				margin-top: 39px;
				font-size: 14px;
			}
		}
		@media (max-width: 450px){
			height: auto !important;
			a {
				width: unset;
				margin-top: 5px;
				font-size: 12px;
			}
		}
	}

	.img-dsk-sh {
		display: none !important;

		img {
			width: 150%;
			height: 100%;
			margin-left: -238px;
		}
		@media (max-width: 767px){
			display: flex !important;
		}
	}
`;
