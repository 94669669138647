import React from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../assets/styles/phoneNumberStyles.css";
import { StyledErrorMessage } from "../../assets/styles/CommonStyles";
import colors from "../../constant/colors";

const PhoneField = (props) => {
  const { touched, errors, onChange = {}, value, onBlur = {} } = props;
  let val = errors.phoneNumber === undefined ? touched.phone_number && errors.phone_number : touched.phoneNumber && errors.phoneNumber
  let val1 = errors.phoneNumber === undefined? "phoneNumber": "phone_number"
  return (
    <>
      <PhoneInput
        inputProps={{
          name: val1,
          required: true,
          enableSearch: true,
          disableSearchIcon: false,
        }}
        containerStyle={{
          border: val ? `1px solid ${colors.red}` : `1px solid ${colors.grey}`,
          borderRadius: 5
        }}
        value={value}
        onChange={onChange}
        country={'in'}
        inputStyle={{ width: '100%', height: '4.0em', border: "none", background: colors.white3 }}
        placeholder="Enter Phone Number"
        onBlur={onBlur}
        dropdownStyle={{ color: '#000' }}
      />
      {val && (
        <StyledErrorMessage>{val || ""}</StyledErrorMessage>
      )}
    </>
  );
};

export default PhoneField;
