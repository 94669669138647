import { makeStyles } from "@mui/styles";

import colors from "../../constant/colors";
import fontWeights from "../../constant/fontWeights";

const appointmentStyles = makeStyles(() => ({
  secondaryCont: {
    background: colors.white,
    // border: `1px solid ${colors.white1}`,
    borderRadius: 8
  },
  disabledTextStyle: {
    color: colors.grey1
  },
  secCont: { 
    display: 'flex', 
    alignItems: 'center' 
  },
  statusStyle: {
    padding: 8,
     borderRadius: 8,
    color: "#fff",
  }
}));

export { appointmentStyles };
