import styled from 'styled-components';

export const StyledContainer = styled.div`
	.bg-success, .badge-success {
    color: #fff;
    background: #19ce67;
    padding: 5px 15px;
	}
`;


export const StyledHeading = styled.h3`
	margin-bottom: 20px;
`;