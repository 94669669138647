import React, {useContext, useState, useEffect}  from 'react';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	getBlogSlugDetailAPI
} from '../../../api/website';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';

import moment from 'moment';

import Breadcrumb from '../../../components/Breadcrumb';
import StyledBlogCard from '../components/BlogCard';
import LatestBlog from '../components/LatestBlog';

import {
	StyledBlogDetail
} from './styles';

function BlogDetail(props) {
	const [state, dispatch] = useContext(Context);

	const [blog, setBlog] = useState({});

	useEffect(() => {
		if(props.match.params && props.match.params.id) {
			loader(true);
			getBlogSlugDetailAPI(props.match.params.id).then(res => {
				loader(false);
				setBlog(res.data);
			}).catch(err => {
				loader(false);
			})
		}
	}, []);

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<StyledBlogDetail>
			{/* <Breadcrumb 
				backRoute={[{id: 1, route: 'Home'}, {id: 2, route: 'Blogs listing'}]}
				currentRoute='Blogs'
				title='Blog detail' /> */}

			<div className="content">
				<div className="container-fluid">
					<div className="row">
						{ blog &&
							<div className="col-lg-12 col-md-12">
								<StyledBlogCard 
									title={blog.title}
									doctorName={'Emed4u team'}
									date={moment(blog.createdAt).format('Do MMM, YYYY')}
									totalViews={`${blog.views} Views`}
									blogImage={blog.blogImage}
									image={'/assets/img/logo_website.png'}
									item={blog}
								>
									<p
										className="excert"
										dangerouslySetInnerHTML={{
											__html: blog.description
										}}
									></p>
								</StyledBlogCard>
							</div>

						}

						{/* <div className="col-lg-4 col-md-12">
							<LatestBlog />
						</div> */}
					</div>
				</div>
			</div>
		</StyledBlogDetail>
	)
}

export default BlogDetail
