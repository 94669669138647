import React from 'react';
import {
	EyeIcon,
	StyledInputContainer
} from './styles';


function InputField({onChange, type = "text", password=false, value="", placeholder="", name = "", onKeyDown, readOnly, max, className, style, touched={}, helperText={} }) {
	
	const [passwordType, setPasswordType] = React.useState(type);

	const onSetType = () => {
		passwordType === 'text' ? setPasswordType('password') : setPasswordType('text')
	}

	const renderIcons = () => passwordType === 'password' ? <EyeIcon className="fa fa-eye" style={{color: 'black'}} onClick={() => onSetType()} /> : <EyeIcon style={{color: 'black'}} className="fa fa-eye-slash" onClick={() => onSetType()} />
	return (
		<StyledInputContainer>
			<input name={name} style={style} className={`form-control ${className}`} type={passwordType} onChange={onChange} value={value} placeholder={placeholder} max={max} readOnly={readOnly} onKeyDown={onKeyDown} helperText={helperText} touched={touched}/>
			{password && renderIcons()}
		</StyledInputContainer>
	)
}

export default InputField

