import styled from 'styled-components';

export const StyledContainer = styled.div`
  .files-dropzone {
    padding: 10px;
    border: 1px dashed #d3d3d3;
    min-height: 120px;
    width: 100%;
    align-items: center !important;
    display: flex;
    justify-content: center !important
  }

  .active-btn {
    background: #60d16d;
    color: #fff;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  .container input:checked ~ .checkmark {
    background-color: #60d16d;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .container .checkmark:after {
     top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`;

export const StyledButtonPay = styled.button`
	background-color: #5156be;
	color: #fff;
	border: 1px solid #5156be;
	margin-top: 20px;
	padding: 12px 25px;
	border-radius: 5px;
	font-weight: 700;
`;

export const StyledButtonType = styled.button`
  background: transparent;
  border: 2px solid #5156be;
  color: #5156be;
  font-weight: 500;
  padding: 7px 20px;
  font-size: 16px;
  &.active-blue-btn {
    background: #5156be !important;
    color: #fff !important;
  }
`;