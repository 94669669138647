import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import InputField from '../../../../../components/InputField';
import "react-datepicker/dist/react-datepicker.css";
import './index.css'
import { toast } from 'react-toastify';
import { globalValidator } from "../../../../../utils";

import { Context } from '../../../../../store';

import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';

import {
	StyledContainer,
	StyledHeader,
	StyledAddEditPatient
} from './styles';

function PatientDetails (props) {
	const [state, dispatch] = useContext(Context);
    const {patientDetails, setPatientDetails, SetCurrentTab, setVisitedTab} = props;

	const gotoNext = (e) => {
		e.preventDefault();
		const keys = [
			{ name: "diagnosis", title: "Provisional Diagnosis", validateKey: "required" },
		];
		let valid = globalValidator(patientDetails, keys);

		if (valid === true) {
            setVisitedTab(3);
			SetCurrentTab(3);
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

    const handlechiefComplaints = (e,id,type) => {
        let data;
        switch(type){
            case 'Complaints':
                data = patientDetails.chiefComplaints;
                data[id] = {
                    ...data[id],
                    complaints: e.target.value,
                }
                setPatientDetails({...patientDetails,chiefComplaints:data});
                return;
            case 'Duration':
                data = patientDetails.chiefComplaints;
                data[id] = {
                    ...data[id],
                    duration: e.target.value,
                }
                setPatientDetails({...patientDetails,chiefComplaints:data});
                return;
            default:
                return
        }
    }

    const handleGeneralAppearce = (e,id,type) => {
        let data;
        switch(type){
            case 'General':
                data = patientDetails.generalAppearce;
                data[id] = {
                    ...data[id],
                    general: e.target.value,
                }
                setPatientDetails({...patientDetails,generalAppearce:data});
                return;
            case 'Comments':
                data = patientDetails.generalAppearce;
                data[id] = {
                    ...data[id],
                    comments: e.target.value,
                }
                setPatientDetails({...patientDetails,generalAppearce:data});
                return;
            default:
                return
        }
    }

    const handleAddchiefComplaints = () => {
        const data = patientDetails.chiefComplaints[patientDetails.chiefComplaints.length-1];
        if(patientDetails.chiefComplaints.length && data?.complaints ==='' && data?.duration === '')
        {
            return;
        }
        setPatientDetails({...patientDetails,chiefComplaints:[...patientDetails.chiefComplaints,{
            complaints: '',
            duration: ''
        }]})
    }

    const handleAddGeneralAppearance = () => {
        const data = patientDetails.generalAppearce[patientDetails.generalAppearce.length-1];
        if(patientDetails.generalAppearce.length && data?.general ==='' && data?.comments === '')
        {
            return;
        }
        setPatientDetails({...patientDetails,generalAppearce:[...patientDetails.generalAppearce,{
            general: '',
            comments: ''
        }]})
    }

    const handleDeleteComplaints = (id) => {
        const data = patientDetails.chiefComplaints;
        data.splice(id,1);
        setPatientDetails({ ...patientDetails, chiefComplaints: data });
    }

    const handleDeleteGeneralAppearce = (id) => {
        const data = patientDetails.generalAppearce;
        data.splice(id,1);
        setPatientDetails({ ...patientDetails, generalAppearce: data });
    }

	return (
		<StyledContainer>
			<div className="content">
				<div className="container-fluid">
                    {state.patient.patientIdE &&
                        <>
                            <i style={{ color: "#b7b74b", fontWeight: 'bold', fontSize: "18px" }} class="fas fa-plus"></i> <a style={{ color: "#6400ff", fontSize: "18px" }} target="_blank" href="https://emed4u.com/privacy-policy">Copy From Last OPD Visit</a>&nbsp;
                        </>
                    }
                    <div>
                        <div className="row pt-3">
                            <div className="col no-padding form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                                <label>Provisional Diagnosis</label>
                                <InputField value={patientDetails.diagnosis} onChange={e => setPatientDetails((patientDetails) => ({ ...patientDetails, diagnosis: e.target.value }))} />
                            </div>
                        </div>
                        <div className="pt-3 no-padding form-group" >
                            <div style={{display: "flex"}}>
                                <p style={{fontSize:"18px",fontWeight:"500"}}>Chief Complaints And Duration</p>
                                <i onClick={handleAddchiefComplaints} class="fa fa-plus todo-add-icon" aria-hidden="true"></i>
                            </div>
                            <div style={{boxShadow: "0px 0px 5px 2px #e3dddd",borderRadius:"5px" }}>

                                <div style={{maxHeight:"200px",overflowY:"auto"}}>
                                    <table className="table table-bordered">
                                        <thead className="thead-light to-do-thead" style={{position:"sticky",top:-1}}>
                                            <tr>
                                                <th>Complaints </th>
                                                <th>Duration </th>
                                                <th style={{maxWidth: "30px"}}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{overflow:"scroll"}}>
                                            {patientDetails && patientDetails.chiefComplaints.map((o, i) => {
                                                return (
                                                    <tr key={"item-" + i} className="to-do-table-row">
                                                        <td>
                                                            <InputField value={o.complaints} onChange={(e) => handlechiefComplaints(e,i,'Complaints')} />
                                                        </td>
                                                        <td>
                                                            <InputField value={o.duration} onChange={(e) => handlechiefComplaints(e,i,'Duration')}/>
                                                        </td>
                                                        <td style={{textAlign:'center'}}>
                                                            <i style={{margin:"auto",padding:"inherit",cursor:"pointer"}} class="fa fa-times" aria-hidden="true" onClick={()=>handleDeleteComplaints(i)}></i>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="pt-3 no-padding form-group" >
                            <p style={{fontSize:"18px",fontWeight:"500"}}>Presenting Illness Summary</p>
                            <textarea value={patientDetails.illessSumary} onChange={(e)=>setPatientDetails((patientDetails) => ({...patientDetails,illessSumary:e.target.value}))} rows={3} cols={50} style={{lineHeight:"24px",padding:"10px",width:"100%",borderRadius:"5px",border:"2px solid #979797"}}/>
                        </div>
                        <div className="pt-3 no-padding form-group" >
                            <p style={{ fontSize: "18px", fontWeight: "500" }}>Key findings, on physical examination at the time of admission </p>
                            <div style={{ boxShadow: "0px 0px 5px 2px #e3dddd", padding: "0px 15px 15px 15px", borderRadius:"5px" }}>
                                <div className="row pt-3">
                                    <div className="col-md-4 no-padding form-group">
                                        <label>BP (mmHG)</label>
                                        <div className="d-flex row ">
                                            <div className="col-md-6 pr-0">
                                                <InputField placeholder="Systolic" value={patientDetails.BPSystolic} onChange={e => setPatientDetails((patientDetails) => ({ ...patientDetails, BPSystolic: e.target.value }))} />
                                            </div>
                                            <div className="col-md-6">
                                                <InputField placeholder="Diastolic" value={patientDetails.BPDiastolic} onChange={e => setPatientDetails((patientDetails) => ({...patientDetails,BPDiastolic: e.target.value}))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 no-padding form-group">
                                        <label>Temp (f)</label>
                                        <InputField value={patientDetails.temp} onChange={e => setPatientDetails((patientDetails) => ({ ...patientDetails, temp: e.target.value }))} />
                                    </div>
                                    <div className="col-md-4 no-padding form-group">
                                        <label>SPO2 (%)</label>
                                        <InputField value={patientDetails.spo2} onChange={e => setPatientDetails((patientDetails) => ({ ...patientDetails, spo2: e.target.value }))} />
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="col-md-4 no-padding form-group">
                                        <label>Pulse (/min)</label>
                                        <InputField value={patientDetails.pulse} onChange={e => setPatientDetails((patientDetails) => ({ ...patientDetails, pulse: e.target.value }))} />
                                    </div>
                                    <div className="col-md-4 no-padding form-group">
                                        <label>RR (/min)</label>
                                        <InputField value={patientDetails.rr} onChange={e => setPatientDetails((patientDetails) => ({ ...patientDetails, rr: e.target.value }))} />
                                    </div>
                                </div>
                                <div className="pt-3 no-padding form-group">
                                    <div class="pl-3 pr-3" style={{ display: "flex" }}>
                                        <p style={{ fontSize: "18px", fontWeight: "500" }}>General Appearance</p>
                                        <i onClick={handleAddGeneralAppearance} class="fa fa-plus todo-add-icon" aria-hidden="true"></i>
                                    </div>
                                    <div className="col-md-12" style={{ maxHeight: "200px", overflowY: "auto" }}>
                                        <table className="table table-bordered ">
                                            <thead className="thead-light to-do-thead" style={{ position: "sticky", top: -1 }}>
                                                <tr>
                                                    <th>General Appearance </th>
                                                    <th>Comments </th>
                                                    <th style={{ maxWidth: "30px" }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ overflow: "scroll" }}>
                                            {patientDetails && patientDetails.generalAppearce.map((o, i) => {
                                                    return (
                                                        <tr key={"item-" + i} className="to-do-table-row">
                                                            <td>
                                                                <InputField value={o.general} onChange={(e) => handleGeneralAppearce(e, i, 'General')} />
                                                            </td>
                                                            <td>
                                                                <InputField value={o.comments} onChange={(e) => handleGeneralAppearce(e, i, 'Comments')} />
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <i style={{ margin: "auto", padding: "inherit", cursor: "pointer" }} class="fa fa-times" aria-hidden="true" onClick={() => handleDeleteGeneralAppearce(i)}></i>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <StyledHeader>
                            <StyledAddEditPatient onClick={(e) => gotoNext(e)}>
                                Next
                            </StyledAddEditPatient>
                        </StyledHeader>
                    </div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default PatientDetails 

