import React from 'react';

import { toast } from 'react-toastify';

import { Context } from '../../../../store';
import {
	sendReportAPI,
	getAppointmentDetailsAPI
} from '../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';
import { globalValidator } from "../../../../utils";

import InputField from '../../../../components/InputField';
import SelectField from '../../../../components/SelectField';
import { medicineType, strengthType } from '../../../../constants';

function DoctorFinalReport(props) {
	const [state, dispatch] = React.useContext(Context);
	const { setIsModalVisible, setAppointment, fields,setReport,report, setFields, blankMedication ,apptId} = props;
 
	const handleCatChange = (e, index, type, key) => {
		
	
		const updatedCats = [...fields];
		updatedCats[index][key] = e.target.value;
		setFields(updatedCats);

		// updatedCats[e.target.dataset.idx][e.target.className] = e.target.value;
		// setFields(updatedCats);
	};

	function handleChange(i, event) {
		const values = [...fields];
		values[i].value = event.target.value;
		setFields(values);
	}

  function handleAdd() {
		setFields([...fields, { ...blankMedication }]);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }

	// const [report, setReport] = React.useState({
	// 	impression_diagnosis: '',
	// 	complications: '',
	// 	treatment_plan: '',
	// 	follow_up: ''
	// });

	const sendReport = (e) => {
		const keys = [
			{ name: "impression_diagnosis", title: "Diagnosis and observation", validateKey: "required" },
			// { name: "medication_name", title: "Medication name", validateKey: "required" },
			// { name: "complications", title: "The complications that may happen are", validateKey: "required" },
			// { name: "treatment_plan", title: "Treatment plan", validateKey: "required" },
			// { name: "follow_up", title: "Follow up", validateKey: "required" }
		];
		let valid = globalValidator(report, keys);

		if (valid === true) {
			const data = {
				impression_diagnosis: report.impression_diagnosis,
				// medication_name: report.medication_name,
				notes: report.notes,
				medication: fields,
				pendingAmount: report.pendingAmount
			}
			// return
			loader(true);
			sendReportAPI(apptId, data).then(res => {

				
				// toast.success(res.message);
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				setIsModalVisible(false);
				document.body.style.overflowY = 'auto';
				if (setAppointment) {
					getAppointmentDetailsAPI(apptId).then(res => {
						setAppointment(res.data);
						setFields([
							{ ...blankMedication },
						]);
						setReport({
							impression_diagnosis: '',
							notes: '',
							pendingAmount: 0
						});
						loader(false);
					}).catch(err => {
						loader(false);
					})
				} else {
					setFields([
						{ ...blankMedication },
					]);
					setReport({
						impression_diagnosis: '',
						notes: '',
						pendingAmount: 0
					});
					loader(false);
				}

				// props.history.push('/doctor/schedule');

			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const handleClear = () => {
		setFields([
			{ ...blankMedication },
		]);
		setReport({
			impression_diagnosis: '',
			notes: '',
			pendingAmount: 0
		});
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}



	const Quantity = [
		{id: 1, value: "once daily", label: "once daily", },
		{id: 2, value: "twice daily", label: "twice daily", },
		{id: 3, value: "when needed", label: "when needed", },
		{id: 4, value: "3 times daily", label: "3 times daily", },
		{id: 5, value: "4 times daily", label: "4 times daily", },
		{id: 6, value: "5 times daily", label: "5 times daily", },
		{id: 7, value: "every other day", label: "every other day", },
		{id: 8, value: "every 2 hours", label: "every 2 hours", },
		{id: 9, value: "every 3 hours", label: "every 3 hours", },
		{id: 10, value: "every 4 hours", label: "every 4 hours", },
		{id: 11, value: "every 6 hours", label: "every 6 hours", },
		{id: 12, value: "every 8 hours", label: "every 8 hours", },
		{id: 13, value: "every 12 hours", label: "every 12 hours", },
		{id: 14, value: "every hour", label: "every hour", },
		{id: 15, value: "every other hour", label: "every other hour", },
		{id: 16, value: "once a week", label: "once a week", },
		{id: 17, value: "twice a week", label: "twice a week", },
		{id: 18, value: "thrice a week", label: "thrice a week", },
		{id: 19, value: "4 days in a week", label: "4 days in a week", },
		{id: 20, value: "5 days in a week", label: "5 days in a week", },
		{id: 21, value: "once every 2 weeks", label: "once every 2 weeks", },
		{id: 22, value: "once a month", label: "once a month", },
		{id: 23, value: "twice a month", label: "twice a month", },
		{id: 24, value: "thrice a month", label: "thrice a month", },
		{id: 25, value: "every other month", label: "every other month", }
	];

	return (
		<div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '70%' }} role="document">
                <div className="modal-content">
				<i class="fas fa-times patient-info-modal-close-icon p-2" onClick={() => {
                                setIsModalVisible(false);
                                document.body.style.overflowY = 'auto';
                            }}></i>
					<div className="col-md-12 mt-2 p-3 col-lg-12 col-xl-12" style={{ maxHeight: "80vh", overflowY: "scroll" }}>
						<h2>Send medication</h2>

						<div className="col-md-12 p-0 no-padding form-group">
							<label>Diagnosis and observation:</label>
							<InputField placeholder="Enter diagnosis and observation" value={report.impression_diagnosis} onChange={e => setReport((user) => ({ ...user, impression_diagnosis: e.target.value }))} />
						</div>

						{/* <div className="col-md-12 no-padding form-group ">
								<label>Medication name:</label>
								<InputField placeholder="Enter name of medication" value={report.medication_name} onChange={e => setReport((user) => ({...user, medication_name: e.target.value}))} />
							</div> */}

						<div className="col-md-12 p-0 no-padding form-group d-flex">
							<h3>Form of medication</h3>
							<button className="doctor-active-btn mrn-auto" onClick={() => handleAdd()}> <i class="fa fa-plus p-2"> </i></button>
						</div>

						{
							fields.map((val, idx) => {
								const medication_nameId = `smedication_name-${idx}`;
								const medicine_typeId = `medicine_type-${idx}`;
								const strength_nameId = `strength_name-${idx}`;
								const strength_typeId = `strength_type-${idx}`;
								const method_intakeId = `method_intake-${idx}`;
								const dos_quantity_countId = `dos_quantity_count-${idx}`;
								const dos_quantity_nameId = `dos_quantity_name-${idx}`;
								const dos_quantity_whenId = `dos_quantity_when-${idx}`;

								return (
									<>
										<div key={`medication_name-${idx}`} className="row">
											<div className="col-md-4 no-padding form-group ">
												<label>Medication name:</label>
												<InputField
													placeholder="eg. 100"
													name={medication_nameId}
													value={fields[idx].medication_name}
													onChange={(e) => handleCatChange(e, idx, 'text', 'medication_name')}
												/>
											</div>


											<div className="col-md-3 no-padding form-group ">
												<label>Form of medicine:</label>
												<SelectField
													value={fields[idx].medicine_type}
													onChange={(e) => handleCatChange(e, idx, 'select', 'medicine_type')}
													name={medicine_typeId}
													options={medicineType} />
											</div>

											{/* <div className="row"> */}
											<div className="col-md-2 no-padding form-group ">
												<label>Strength:</label>
												<InputField
													placeholder="eg. 100"
													name={strength_nameId}
													value={fields[idx].strength_name}
													onChange={(e) => handleCatChange(e, idx, 'text', 'strength_name')}
												/>
											</div>

											<div className="col-md-3 no-padding form-group ">
												<label>Strength type:</label>
												<SelectField
													name={strength_typeId}
													value={fields[idx].strength_type}
													onChange={(e) => handleCatChange(e, idx, 'select', 'strength_type')}
													options={strengthType} />
											</div>

											<div className="col-md-3 no-padding form-group ">
												<label>Dosage Quantity intake:</label>
												<SelectField
													name={dos_quantity_nameId}
													value={fields[idx].dos_quantity_name}
													onChange={(e) => handleCatChange(e, idx, 'select', 'dos_quantity_name')}
													options={Quantity} />

											</div>

											{/* <div className="col-md-3 no-padding form-group ">
													<label>Method of intake:</label>
													<SelectField 
													name={method_intakeId}
													value={fields[idx].method_intake}
													onChange={handleCatChange}
													options={methodOfIntake} />
												
												</div> */}
											{/* <InputField placeholder="eg. Orally"  /> */}
											{/* </div> */}


											{/* <div className="col-md-12 no-padding form-group ">
												<h6>Dosage</h6>
											</div> */}

											{/* <div className="row"> */}
											{/* <div className="col-md-4 no-padding form-group ">
													<label>Dosage Quantity:</label>
													<InputField placeholder="#" 
														name={dos_quantity_countId}
														value={fields[idx].dos_quantity_count}
														onChange={handleCatChange}
													 />
												</div> */}

											{/* <div className="col-md-4 no-padding form-group ">
													<label>Dosage Quantity intake:</label>
													<SelectField 
													name={dos_quantity_nameId}
													value={fields[idx].dos_quantity_name}
													onChange={handleCatChange}
													options={Quantity} />
													
												</div> */}

											{/* <InputField placeholder="daily" value={report.dos_quantity_name} onChange={e => setReport((user) => ({...user, dos_quantity_name: e.target.value}))} /> */}

											{/* <div className="col-md-4 no-padding form-group ">
													<label>Dosage When:</label>
													<SelectField 
													name={dos_quantity_whenId}
													value={fields[idx].dos_quantity_when}
													onChange={handleCatChange}
													options={when} />

													
												</div> */}
											{/* <InputField placeholder="eg. Before food" value={report.dos_quantity_when} onChange={e => setReport((user) => ({...user, dos_quantity_when: e.target.value}))} /> */}
											{/* </div> */}

										</div>
										<hr />
									</>
								);
							})
						}

						<br />

						{/* <div className="col-md-12 no-padding form-group ">
								<label>Form of medicine:</label>
								<SelectField  value={report.medicine_type} onChange={e => setReport((user) => ({...user, medicine_type: e.target.value}))} options={medicineType} />
							</div>

							<div className="row">
								<div className="col-md-4 no-padding form-group ">
									<label>Strength:</label>
									<InputField placeholder="eg. 100" value={report.strength_name} onChange={e => setReport((user) => ({...user, strength_name: e.target.value}))} />
								</div>

								<div className="col-md-4 no-padding form-group ">
									<label>Strength type:</label>
									<SelectField  value={report.strength_type} onChange={e => setReport((user) => ({...user, strength_type: e.target.value}))} options={strengthType} />
								</div>

								<div className="col-md-4 no-padding form-group ">
									<label>Method of intake:</label>
									<SelectField value={report.method_intake} onChange={e => setReport((user) => ({...user, method_intake: e.target.value}))} options={methodOfIntake} />
								</div>
							</div>
							

							<div className="col-md-12 no-padding form-group ">
								<h3>Dosage</h3>
							</div>

							<div className="row">
								<div className="col-md-4 no-padding form-group ">
									<label>Quantity:</label>
									<InputField placeholder="#" value={report.dos_quantity_count} onChange={e => setReport((user) => ({...user, dos_quantity_count: e.target.value}))} />
								</div>

								<div className="col-md-4 no-padding form-group ">
									<label>Quantity intake:</label>
									<SelectField value={report.dos_quantity_name} onChange={e => setReport((user) => ({...user, dos_quantity_name: e.target.value}))} options={Quantity} />
								</div>

								<div className="col-md-4 no-padding form-group ">
									<label>When:</label>
									<SelectField value={report.dos_quantity_when} onChange={e => setReport((user) => ({...user, dos_quantity_when: e.target.value}))} options={when} />

								</div>
							</div> */}

						<div className="row">
							<div className="col-md-12 no-padding form-group ">
								<label>Other Instructions:</label>
								<InputField placeholder="Note to patient eg. Medicine to be taken 30 min before meal." value={report.notes} onChange={e => setReport((user) => ({ ...user, notes: e.target.value }))} />
							</div>
						</div>

						<div className="row">
							<div className="col-md-12 no-padding form-group ">
								<label>Settle payment: <small>(If any pending amount)</small></label>
								<InputField placeholder="Note to patient eg. Medicine to be taken 30 min before meal." value={report.pendingAmount} onChange={e => setReport((user) => ({ ...user, pendingAmount: e.target.value }))} />
							</div>
						</div>
						<div className="col-md-12 row ml-auto " style={{ justifyContent: "flex-end" }}>
							<div className="btn btn-primary submit-btn p-3 mr-4" onClick={handleClear}>Clear</div>
							<div className="btn btn-primary submit-btn p-3" onClick={(e) => sendReport(e)}>Send final report to patient</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default DoctorFinalReport
