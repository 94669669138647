import styled from 'styled-components';

export const StyledContactus = styled.div`
  
`;

export const StyledTitle = styled.h2`
	color:var(--terthemecolor);
	font-weight: 700;
	line-height: 1.5;
	font-style: normal;
	font-family: 'Poppins',Arial,Helvetica,sans-serif;

	&::after {
    content: " Serve You";
		font-weight: normal;
		color: #19ce67;
	}
`;

export const StyledSubTitle = styled.p`
  color: #19ce67 !important;
  font-weight: 500;
  font-size: 17px !important;

  .text-center {
    text-align: center;
  }
`;

export const StyledDescription = styled.p`
	margin-bottom: 13px;
	font-size: 16px;
	line-height: 1.65;
`;

export const StyledColounContent = styled.div`
	padding: 30px;
	border: 1px solid #eee;
	border-radius: 4px;
	text-align: center;
	margin-right: 20px;
	min-height : 280px
`;

export const StyledImgContrainer = styled.div`
	margin: 0 0 23px;
	width: 100%;
`;

export const StyledImg = styled.img`
	width: 100%;
	max-width: 65px;
	margin: 0 auto;
	display: block;
	height: auto;
	border: none;
	-webkit-border-radius: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
`;

export const StyledContactTitleContent = styled.div`
	word-break: break-word;
	margin-bottom: 15px;
	width: 100%;
	margin: 0;
	font-size: 20px;
	line-height: 30px;
	font-weight: 600;
`;

export const StyledContactSubTitleContent = styled.span`
	font-size: 16px;
	font-weight: 400;
	display: block;
	line-height: 20px;
`;

export const StyledContactColounDescription = styled.p`
	width: 100%;
	margin: 0;
	font: 400 14px/24px'Open Sans',sans-serif;
	padding-top:20px;
`;

export const StyledButton = styled.div`
	text-align: center;
	width: 100%;
	padding: 0px 10px;
`;

export const StyledCardButton = styled.button`
  margin-top: 20px;
  background: #19ce67;
  color: #ffffff;
	border: none;
	width: 100%;
  line-height: initial;
  text-transform: uppercase;
  border-radius: 8px;
  padding: 17px 50px 15.5px 25px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-weight: 600;
  position: relative;
`;