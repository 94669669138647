import styled from 'styled-components';

export const StyledContainer = styled.div`
.content {
  background: #fff;
  .container-fluid {
	padding-left: 15px;
	padding-right: 15px;
  }
}

.btn-primary {
  background-color: #5156be;
  border: 1px solid #5156be;
}


.postion-relative {
  position: relative;
}
.remove-heath {
  position: absolute;
  right: 6px;
  top: -10px;
  background: #5156be;
  color: #fff;
  font-size: 20px;
  border: #5156be;
  border-radius: 50%;
  padding: 4px 12px;
}

a {
  cursor: pointer;
}

.health-btn-outline {
  border: 2px solid #5156be;
  border-radius: 4px;
  padding: 10px 15px !important;
  text-align: center;
  font-size: 15px;
  color: #5156be;
  text-transform: uppercase;
  font-weight: 500;
  background: transparent;
  margin-right: 20px;
  cursor: pointer;
}

.health-active-btn {
  border: 2px solid #5156be;
  border-radius: 4px;
  padding: 10px 15px !important;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 20px;
  background: #1dbe72;
  color: #fff;
  cursor: pointer;
}

.success-bmi {
  background: #b6e297;
  color: #000;
}
.under-bmi {
  background: #f8ccce;
  color: #000;
}
.over-bmi {
  background: #fcfbc2;
  color: #000;
}
.obesity-bmi {
  background: #dd8990;
  color: #000;
}

.color-white {
  color: #000 !important;
}
.color-white h5 {
  color: #000 !important;
}
.line-chart-buttons {
  color: #000;
  background: transparent;
  border: none;
}
.line-chart-buttons:hover,  .line-chart-buttons::after{
  background:#09dca4
}
.selected-group {
  background-color: #19ce67;
}

.hb-select-field {
  width: 70px;
	top: 5px;
	position: absolute;
	left: 20px;
	min-height: 20px;
	height: 20px;
	align-items: center;
	border: none;
	padding: 0px;
}

.hb-cancel-icon-input {
    position: absolute;
    right: 35px;
    height: 100%;
    top:5px;
    width: 35px;
    color:#797676;
    font-size:20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    cursor:pointer
}
.hb-search-icon-doctor {
    position:absolute;
    right:10px;
    height:100%;
    width:35px;
    border-top-right-radius:20px;
    border-bottom-right-radius:20px
}
`;