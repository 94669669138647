import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProfileSetting from "../../ProfileSetting";
import strings from "../../../../constant/strings";
import SubadminDetails from "./SubadminDetails";
import { subadminProfileAction } from "../../../../redux/user/subadmin/subadminAction";
import { useDispatch, useSelector } from "react-redux";
import ResetPasswordsubadmin from "../ResetPasswordsubadmin";

const SubadminProfileSetting = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [onLoading, setLoading] = useState(false); // Handle loading state here
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData,
  }));

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true); // Start loading
      try {
        await dispatch(subadminProfileAction(userData.token)); // Dispatch action
      } catch (error) {
        console.error("Error fetching subadmin profile:", error);
      } finally {
        setLoading(false); // Stop loading after action completes (either success or failure)
      }
    };

    fetchProfile();
  }, [dispatch, userData.token]);

  const tabs = [
    { id: 0, name: "Sub-admin details" },
    { id: 1, name: "Reset password" },
  ];

  return (
    <>
      <ProfileSetting
        tabValue={tabValue}
        setTabValue={setTabValue}
        tabs={tabs}
        profileHead={strings.subadminProfile}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} mt={2}>
          {tabValue === 0 ? (
            <SubadminDetails onLoading={onLoading} setLoading={setLoading} />
          ) : (
            <ResetPasswordsubadmin setLoading={setLoading} onLoading={onLoading} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SubadminProfileSetting;
