import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';

import { Context } from '../../../../../store';
import {
	getCouponByIdAPI,
	addCouponAPI,
	updateCouponAPI
} from '../../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';

import { globalValidator } from "../../../../../utils";

import CardComponent from '../../../../../components/Card'
import InputField from '../../../../../components/InputField';
import SelectField from '../../../../../components/SelectField';

import {
	StyledContainer,
	StyledHeader,
	StyledBackTo,
	StyledTitle,
	StyledAddEditDoctor
} from './styles';
import InsideMenuBar from '../../../../../components/InsideMenus';
import moment from 'moment';

function ViewCoupon(props) {
	const [state, dispatch] = useContext(Context);
	
	const [id, setId] = useState(null);

	const [couponInfo, setCouponInfo] = useState({
		start_time: new Date(),
		finish_time: new Date(),
		coupon: '',
		type: '',
		status: '',
		amount: '',
	})

	useEffect(() => {
		if(props.match.params && props.match.params.id) {
			setId(props.match.params.id);
			loader(true);
			getCouponByIdAPI(props.match.params.id).then(res => {
				loader(false);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				
				setCouponInfo({
					start_time: moment(res.data.start_time).format("YYYY-MM-DD"),
					finish_time: moment(res.data.finish_time).format("YYYY-MM-DD"),
					coupon: res.data.coupon,
					type: res.data.type,
					status: res.data.status,
					amount: res.data.amount
				});
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		}
	}, [])

	const submit = (e) => {
		e.preventDefault();
    const keys = [
      { name: "start_time", title: "Start date", validateKey: "required" },
			{ name: "finish_time", title: "End date", validateKey: "required" },
			{ name: "coupon", title: "Coupon code", validateKey: "required" },
			{ name: "amount", title: "Amount", validateKey: "required" },
    ];
		let valid = globalValidator(couponInfo, keys);

		if(valid === true) {
			loader(true);
			if(id) {
				updateCouponAPI(id, couponInfo).then(res => {
					loader(false);
					// toast.success(res.message)
					dispatch({
						type: SET_ERROR,
						payload: res.message
					});
				}).catch(err => {
					loader(false);
					toast.error(err.response.data.error)
					dispatch({
						type: SET_ERROR,
						payload: err.response.data.error
					});
				})
			} else {
				
				addCouponAPI(couponInfo).then(res => {
					loader(false);
					// toast.success(res.message)
					dispatch({
						type: SET_ERROR,
						payload: res.message
					});
					setCouponInfo({start_time: new Date(),
						finish_time: new Date(),
						coupon: '',
						type: '',
						status: '',
						amount: ''});
				}).catch(err => {
					loader(false);
					toast.error(err.response.data.error)
					dispatch({
						type: SET_ERROR,
						payload: err.response.data.error
					});
				})
			}
			
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}


	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<StyledHeader>
									<StyledBackTo>
										<Link to={'/organization/coupons'}>
											<i className="fa fa-arrow-left" />
											Back to listing page
										</Link>
									</StyledBackTo>

									<StyledTitle>
										{id ? 'Update' : 'Add'} coupon
									</StyledTitle>

									<StyledAddEditDoctor onClick={(e) => submit(e)}>
									{id ? 'Update' : 'Add'} coupon
									</StyledAddEditDoctor>
								</StyledHeader>

								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Coupon code</label>
										<InputField value={couponInfo.coupon} onChange={e => setCouponInfo((user) => ({...user, coupon: e.target.value}))} />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Coupon type</label>
										<SelectField  value={couponInfo.type} onChange={e => setCouponInfo((user) => ({...user, type: e.target.value}))} options={[{id: 1, label: 'Select type'}, {id: 1, label: '₹', value: '0'}, {id: 1, label: '%', value: '1'}]}  />
									</div>
								</div>

								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Amount</label>
										<InputField value={couponInfo.amount} onChange={e => setCouponInfo((user) => ({...user, amount: e.target.value}))}  />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Status</label>
										<SelectField value={couponInfo.status} onChange={e => setCouponInfo((user) => ({...user, status: e.target.value}))} options={[{id: 1, label: 'Select status'}, {id: 1, label: 'Active', value: '0'}, {id: 1, label: 'Closed', value: '1'}]}  />
									</div>
								</div>

								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Start date</label>
										<InputField
											type="date"
											value={couponInfo.start_time}
											onChange={e => {
												setCouponInfo((user) => ({ ...user, start_time: e.target.value }))
											}}
										/>
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>End date</label>
										<InputField 
											type="date" 
											value={couponInfo.finish_time} 
											onChange={e => setCouponInfo((user) => ({...user, finish_time: e.target.value}))} 
										/>
									</div>
								</div>

							
						
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default ViewCoupon
