import React, { useContext } from 'react';
import {
    ModalCardInfo,
    ModalCardItem,
    TextArea
} from '../Header/styles';
import './modal.css';
import { Context } from '../../store';
import { globalValidator } from '../../utils';
import { postContactUsAPI } from '../../api/website';
import { SET_ERROR } from '../../store/types';
import { toast } from 'react-toastify';
import Loader from '../../assets/img/loader22.gif';

const ContactUsModal = (props) => {

    const [state, dispatch] = useContext(Context);
    const [loading, setLoading] = React.useState(false)

    const [contactUs, setContactUs] = React.useState({
        name: "",
        email: "",
        subject: "",
        message: ""
    });

    const keys = [
        { name: "name", title: "Name", validateKey: "required" },
        { name: "email", title: "Email", validateKey: "email" },
        { name: "subject", title: "Subject", validateKey: "required" },
        {
            name: "message",
            title: "Message",
            validateKey: "required"
        },
    ];

    const valid = globalValidator(contactUs, keys);

    const submitContactRequest = () => {
        if (valid === true) {
            setLoading(true);
            postContactUsAPI(contactUs).then(res => {
                setLoading(false);
                // toast.success(res.message)
                dispatch({
                    type: SET_ERROR,
                    payload: res.message
                });
                setContactUs({
                    name: "",
                    email: "",
                    subject: "",
                    message: ""
                })
                props.handleOpen()
            }).catch(err => {
                setLoading(false);
                toast.error(err.response.data.error)
                dispatch({
                    type: SET_ERROR,
                    payload: err.response.data.error
                });
            })
        } else {
            // toast.error(valid)
            // dispatch({
            //     type: SET_ERROR,
            //     payload: valid
            // });
        }

    }

    return <div className="modal ContactUsModal custom-modal fade none-border show" style={{
        display: props.tableToggle1 ? 'block' : 'none', backdropFilter: 'blur(1px)',
        background: 'rgb(104 71 99 / 24%)'
    }}>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header pb-0" style={{ flexDirection: 'column', border: 'none', padding: '2.25rem 65px' }}>
                    <img
                        className="call-avatar mb-2"
                        src={"/assets/img/x.png"}
                        alt="User"
                        style={{ position: 'absolute', right: '18px', top: '18px', cursor: 'pointer' }}
                        data-dismiss="modal"
                        onClick={(e) => {
                            document.body.style.overflowY = 'auto'
                            props.setTableToggle1(tableToggle1 => !tableToggle1);
                        }}
                    />
                    <h4 className="modal-title" style={{ fontWeight: 600, fontSize: '32px' }}>Contact Us</h4>
                    <p className="m-0 mt-2">Fill up the form and our team will get back to you within 24hrs</p>
                </div>
                <div className="modal-body d-flex row " style={{ padding: '0 40px' }}>
                    <div className="col-md-12 no-padding form-group mobilePadding">

                        <ModalCardInfo>
                            <ModalCardItem>
                                <img
                                    className="call-avatar"
                                    style={{ width: '180px' }}
                                    src={"/assets/img/Frame.png"}
                                    alt="User"
                                />
                            </ModalCardItem>

                            <div style={{ marginTop: '25px' }}>
                                <div className='modal-main-content-item'>
                                    <img
                                        className="call-avatar mb-2"
                                        src={"/assets/img/phone-call.png"}
                                        alt="User"
                                    />
                                    <p>+91-7658064535</p>
                                </div>
                                <div className='modal-main-content-item'>
                                    <img
                                        className="call-avatar mb-2"
                                        src={"/assets/img/mail.png"}
                                        alt="User"
                                    />
                                    <p>info@emed4u.com</p>
                                </div>
                            </div>
                        </ModalCardInfo>

                        <div className="d-flex justify-content-between">
                            <input
                                style={{ background: '#F8F7F7' }}
                                className="mb-4 customInput form-control"
                                placeholder="Full Name"
                                value={contactUs.name}
                                onChange={e => setContactUs((user) => ({ ...user, name: e.target.value }))}
                            />
                            <input
                                style={{ background: '#F8F7F7' }}
                                className="mb-4 customInput form-control"
                                placeholder="Email"
                                value={contactUs.email}
                                onChange={e => setContactUs((user) => ({ ...user, email: e.target.value }))}
                            />
                        </div>
                        <input
                            style={{ background: '#F8F7F7' }}
                            className="mb-4 form-control"
                            placeholder="Subject"
                            value={contactUs.subject}
                            onChange={e => setContactUs((user) => ({ ...user, subject: e.target.value }))}
                        />
                        <TextArea
                            className=""
                            style={{ height: "129px" }}
                            placeholder="Message"
                            value={contactUs.message}
                            onChange={e => setContactUs((user) => ({ ...user, message: e.target.value }))}
                        />
                    </div>
                    <div
                        className="modal-footer justify-content-flexend pt-0 button-position"
                        style={{cursor: valid === true ? 'pointer' : 'not-allowed' }}
                    >
                        <div>
                            {!loading ? <img
                                data-dismiss="modal"
                                style={{ opacity: valid === true ? 1 : 0.5 }}
                                onClick={() => valid === true && submitContactRequest()}
                                className="call-avatar"
                                src={"/assets/img/Frame33.png"}
                                alt="User"
                            /> :
                                <div
                                    id='submit'
                                    className={`button-login-style ${loading ? 'button-opacity-05' : 'button-opacity-0'}`}
                                    style={{ width: 200, marginBottom: 5 }}
                                >
                                    <img
                                        // className="call-avatar mb-2"
                                        style={{ width: '30px', height: '30px' }}
                                        src={Loader}
                                        alt="User"
                                    />
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default ContactUsModal