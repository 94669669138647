import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';
import PhoneInput from "react-phone-number-input";
import Select from "react-select";

import CardComponent from '../../../../../components/Card'
import InputField from '../../../../../components/InputField';
import SelectField from '../../../../../components/SelectField';

import { globalValidator, getUser } from "../../../../../utils";

import { Context } from '../../../../../store';
import {
	addDoctor,
	getDoctorInfo,
	getDoctors,
	updateDoctorInfo
} from '../../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';

import {
	StyledContainer,
	StyledHeader,
	StyledBackTo,
	StyledTitle,
	StyledAddEditDoctor
} from './styles';
import InsideMenuBar from '../../../../../components/InsideMenus';

const permission = [
	{ label: 'IPD', isDisabled: false },
	{ label: 'OPD', isDisabled: false },
]

function DoctorAddEdit(props) {
	const [state, dispatch] = useContext(Context);
// bloodGroup: '', address: '',
	const [doctor, setDoctor] = useState({name: 'Dr. ', email: '', phoneNumber: '', status: '', department: ''});
	const [doctors, setDoctors] = React.useState([]);
	const [id, setId] = useState(null);
	const pathname = window.location.pathname
	const departments = [
		{id: 1, label: 'Allergy and immunology', value: 'Allergy and immunology'},
		{id: 2, label: 'Anesthesiology', value: 'Anesthesiology'},
		{id: 3, label: 'Dermatology', value: 'Dermatology'},
		{id: 4, label: 'Diagnostic radiology', value: 'Diagnostic radiology'},
		{id: 5, label: 'Emergency medicine', value: 'Emergency medicine'},
		{id: 6, label: 'Family medicine', value: 'Family medicine'},
		{id: 7, label: 'Internal medicine', value: 'Internal medicine'},
		{id: 8, label: 'Medical genetics', value: 'Medical genetics'},
		{id: 9, label: 'Neurology', value: 'Neurology'},
		{id: 10, label: 'Nuclear medicine', value: 'Nuclear medicine'},
		{id: 11, label: 'Obstetrics and gynaecology', value: 'Obstetrics and gynaecology'},
		{id: 12, label: 'Ophthalmology', value: 'Ophthalmology'},
		{id: 13, label: 'Pathology', value: 'Pathology'},
		{id: 14, label: 'Pediatrics', value: 'Pediatrics'},
		{id: 15, label: 'Physical medicine and rehabilitation', value: 'Physical medicine and rehabilitation'},
		{id: 16, label: 'Preventive medicine', value: 'Preventive medicine'},
		{id: 17, label: 'Psychiatry', value: 'Psychiatry'},
		{id: 18, label: 'Radiation oncology', value: 'Radiation oncology'},
		{id: 19, label: 'Surgery', value: 'Surgery'},
		{id: 20, label: 'Urology', value: 'Urology'}
	]

	const fetchDoctor = () => {
		getDoctors().then(res => {
			if(pathname.includes('doctor-edit')){
				const filterCurrent = res?.data && res?.data.filter((item) => item._id != props.match.params.id)
				setDoctors(filterCurrent);
			}else {
				setDoctors(res.data);
			}
			loader(false);
		}).catch(err => {
			loader(false);
		})
	}

	useEffect(() => {
		fetchDoctor()
	}, [])
	
	
	useEffect(() => {
		if(props.match.params && props.match.params.id) {
			setId(props.match.params.id);
			loader(true);
			getDoctorInfo(props.match.params.id).then(res => {
				loader(false);
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				setDoctor({
					name: res.data.name,
					email: res.data.email,
					phoneNumber: res.data.phoneNumber,
					status: res.data.status,
					permission:res.data.permission && res.data.permission.map((item) => {
						return {label: item, value: item, isDisabled: false}
					}) ,
					// bloodGroup: res.data.bloodGroup,
					// address: res.data.address,
					department: res.data.department
				});
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
	}, [props.match.params && props.match.params.id])

	const submitDoctor = (e) => {
		e.preventDefault();
    const keys = [
		{ name: "email", title: "Email", validateKey: "email" },
		{ name: "name", title: "Name", validateKey: "required" },
		{ name: "permission", title: "Permission", validateKey: "required" }
    ];
		let valid = globalValidator(doctor, keys);
		const org = getUser();
		
		const emailFilter = doctors.filter((item) => item.email == doctor.email)
		const phoneNumberFilter = doctors.filter((item) => item.phoneNumber == doctor.phoneNumber)
		if(valid === true) {
			loader(true);
			if(id) {
				const data = {
					...doctor,
					permission:doctor.permission.map((item) => item.value)
				}
				if(emailFilter.length){
					loader(false);
					toast.error("Email account already exists!")
					dispatch({
						type: SET_ERROR,
						payload: "Email account already exists!"
					}); 
					return 
				} 
				if(!doctor.phoneNumber || !(doctor?.phoneNumber?.length > 10)){
					loader(false);
					toast.error("Please enter valid contact number")
					dispatch({
						type: SET_ERROR,
						payload: "Please enter valid contact number"
					});
					return 
				}

				if(phoneNumberFilter.length){
					loader(false);
					toast.error("Contact number already exists!")
					dispatch({
						type: SET_ERROR,
						payload: "Contact number already exists!"
					});
					return 
				}
				updateDoctorInfo(id, data).then(res => {
					loader(false);
					toast.success(res?.message)
					dispatch({
						type: SET_ERROR,
						payload: res?.message
					});
				}).catch(err => {
					loader(false);
					toast.error(err?.response?.data?.error)
					dispatch({
						type: SET_ERROR,
						payload: err?.response?.data?.error
					});
				})
			} else {
				let data = {
					name: doctor.name, 
					email: doctor.email, 
					phoneNumber: doctor.phoneNumber, 
					status: doctor.status, 
					org_name: org.org_name,
					permission:doctor.permission.map((item) => item.value),
					// bloodGroup: doctor.bloodGroup, 
					// address: doctor.address,
					password: 'Emed4u@12345#!',
					department: doctor.department,
					verifyed: true,
					adminVerifyed: true
				}
				if(emailFilter.length){
					loader(false);
					toast.error("Email account already exists!")
					dispatch({
						type: SET_ERROR,
						payload: "Email account already exists!"
					}); 
					return 
				} 

				if(!doctor.phoneNumber || !(doctor?.phoneNumber?.length > 10)){
					loader(false);
					toast.error("Please enter valid contact number")
					dispatch({
						type: SET_ERROR,
						payload: "Please enter valid contact number"
					});
					return 
				}

				if(phoneNumberFilter.length){
					loader(false);
					toast.error("Contact number already exists!")
					dispatch({
						type: SET_ERROR,
						payload: "Contact number already exists!"
					});
					return 
				}
				
				if(!doctor.status){
					loader(false);
					toast.error("Please enter status")
					dispatch({
						type: SET_ERROR,
						payload: "Please enter status"
					});
					return 
				}

				if(!doctor.department){
					loader(false);
					toast.error("Please enter department")
					dispatch({
						type: SET_ERROR,
						payload: "Please enter department"
					});
					return 
				}
				
				addDoctor(data).then(res => {
					loader(false);
					toast.success(res?.message || 'Credentials are sent to doctor mail!')
					dispatch({
						type: SET_ERROR,
						payload: res?.message
					});
					// , bloodGroup: '', address: ''
					setDoctor({
						permission: '',
						password: '',
						verifyed: true,
						adminVerifyed: true,
						name: 'Dr. ',
						email: '',
						phoneNumber: '',
						status: '',
						department: ''
					});
				}).catch(err => {
					loader(false);
					toast.error(err?.response?.data?.error)
					dispatch({
						type: SET_ERROR,
						payload: err?.response?.data?.error
					});
				})
			}
			
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const handleChange = (e) => {
		setDoctor(info => ({...info, permission: e}))
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<StyledHeader>
									<StyledBackTo>
										<Link to={'/organization/dashboard'}>
											<i className="fa fa-arrow-left" />
											Back To Dashboard
										</Link>
									</StyledBackTo>

									<StyledTitle>
										{id ? 'Update' : 'Add a'} doctor
									</StyledTitle>

									<StyledAddEditDoctor onClick={(e) => submitDoctor(e)}>
									{id ? 'Update' : 'Save'} Doctor
									</StyledAddEditDoctor>
								</StyledHeader>

								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Name</label>
										<InputField value={doctor.name} onChange={e => setDoctor((doctor) => ({...doctor, name: e.target.value}))} />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Email</label>
										<InputField value={doctor.email} onChange={e => setDoctor((doctor) => ({...doctor, email: e.target.value}))} type="email" />
									</div>
								</div>

								<div className="row pt-3">

									<div className="col-md-6 no-padding form-group ">
										<label>Status</label>
										<SelectField  value={doctor.status} options={[{id: 1, label: 'Active', value: '0'}, {id: 1, label: 'Deactive', value: '1'}]} onChange={e => setDoctor((doctor) => ({...doctor, status: e.target.value}))} />
									</div>

									<div className="col-md-6 no-padding form-group">
									<label>Contact number</label>
										<PhoneInput
											placeholder="Enter phone number"
											international
											defaultCountry="IN"
											value={doctor.phoneNumber}
											onChange={e => setDoctor((user) => ({...user, phoneNumber: e}))}
										/>
									</div>
									{/* <div className="col-md-6 no-padding form-group ">
										<label>Blood Group</label>
										<SelectField value={doctor.bloodGroup} onChange={e => setDoctor((doctor) => ({...doctor, bloodGroup: e.target.value}))}  options={[{id: 1, label: 'A-', value: 'A-'},{id: 1, label: 'A+', value: 'A+'},
											{id: 1, label: 'B-', value: 'B-'},{id: 1, label: 'B+', value: 'B+'},
											{id: 1, label: 'AB-', value: 'AB-'},{id: 1, label: 'AB+', value: 'AB+'},
											{id: 1, label: 'O-', value: 'O-'},{id: 1, label: 'O+', value: 'O+'}]} />
									</div> */}

								</div>

								{/* <div className="row pt-3">

									<div className="col-md-6 no-padding form-group ">
										<label>Contact number</label>
										<InputField value={doctor.phoneNumber} onChange={e => setDoctor((doctor) => ({...doctor, phoneNumber: e.target.value}))}  />
									</div>
									<div className="col-md-6 no-padding form-group ">
										<label>Address</label>
										<InputField value={doctor.address} onChange={e => setDoctor((doctor) => ({...doctor, address: e.target.value}))}  />
									</div>

								</div> */}

								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Department</label>
										<SelectField  value={doctor.department} options={departments} onChange={e => setDoctor((doctor) => ({...doctor, department: e.target.value}))} />
										{/* <InputField value={doctor.department} onChange={e => setDoctor((doctor) => ({...doctor, department: e.target.value}))}  /> */}
									</div>
									<div className="col-md-6 no-padding form-group ">
										<label>Permission</label>
										<Select
											type="select"
											name="symptoms"
											isMulti
											value={doctor.permission}
											// value={[{label: 'Admin', value: 'Admin', isDisabled: false}]}
											onChange={(e) => handleChange(e)}
											options={permission.map(symptom => {
												return {
													label: symptom.label,
													value: symptom.label,
													isDisabled: symptom.isDisabled
												};
											})}
											isOptionDisabled={(option) => option.isDisabled}
										/>
									</div>
								</div>
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default DoctorAddEdit
