import styled from 'styled-components';

export const StyledContainer = styled.div`
.content {
  background: #fff;
  .container-fluid {
	padding-left: 15px;
	padding-right: 15px;
  }
}

.btn-primary {
  background-color: #5156be;
  border: 1px solid #5156be;
}


.postion-relative {
  position: relative;
}
.remove-heath {
  position: absolute;
  right: 6px;
  top: -10px;
  background: #5156be;
  color: #fff;
  font-size: 20px;
  border: #5156be;
  border-radius: 50%;
  padding: 4px 12px;
}

a {
  cursor: pointer;
}

.health-btn-outline {
  border: 2px solid #5156be;
  border-radius: 4px;
  padding: 10px 15px !important;
  text-align: center;
  font-size: 15px;
  color: #5156be;
  text-transform: uppercase;
  font-weight: 500;
  background: transparent;
  margin-right: 20px;
  cursor: pointer;
}

.health-active-btn {
  border: 2px solid #5156be;
  border-radius: 4px;
  padding: 10px 15px !important;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 20px;
  background: #1dbe72;
  color: #fff;
  cursor: pointer;
}

.success-bmi {
  background: #b6e297;
  color: #000;
}
.under-bmi {
  background: #f8ccce;
  color: #000;
}
.over-bmi {
  background: #fcfbc2;
  color: #000;
}
.obesity-bmi {
  background: #dd8990;
  color: #000;
}

.color-white {
  color: #000 !important;
}
.color-white h5 {
  color: #000 !important;
}
.line-chart-buttons {
  color: #000;
  background: transparent;
  border: none;
}
.line-chart-buttons:hover,  .line-chart-buttons::after{
  background:#09dca4
}
.selected-group {
  background-color: #19ce67;
}
`;

export const StyledButtonPay = styled.button`
	background-color: #5156be;
	color: #fff;
	border: 1px solid #5156be;
	margin-top: 20px;
	padding: 12px 25px;
	border-radius: 5px;
	font-weight: 700;
`;

export const StyledSlots = styled.ul`
	display: grid;
	margin-top: 10px;

	li {
		width: 100%;

		a {
			background-color: #e9e9e9;
			border: 1px solid #e9e9e9;
			border-radius: 3px;
			color: #757575;
			display: block;
			font-size: 14px;
			margin-bottom: 10px;
			padding: 5px 5px;
			text-align: center;
			position: relative;
			cursor: pointer;
		}

		.selected {
			background-color: #42c0fb;
			border: 1px solid #42c0fb;
			color: #fff;
		}
	}
	
`;

export const Link = styled.div`
  border: 1px solid #5156be;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  color: #5156be;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
`;