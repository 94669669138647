import { CircularProgress, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useStyles } from "../../assets/styles/BloodSugarStyles";
import { StyledButton, StyledCrossIcon, StyledFormik } from "../../assets/styles/CommonStyles";
import { capitalizeFirstLetter } from "../../constant/staticHelper";
import { temperatureScale } from "../../constant/staticLists";
import strings from "../../constant/strings";
import * as mainService from "../../services/mainService.js";
import { bodyTempSchema } from "../../utils/forms/schema.bodyTempSchema";
import BloodSugarLevelDropdown from "../control/BloodSugarLevelDropdown";
import FieldWrapper from "../InputField/TextFieldWrapper";
import SelectionDropdown from "../control/SelectionDropdown";

const BodyTemperature = ({ setOpenModal, getUpdatedValue }) => {
  let initialValues= {
    temperature: "",
    temperature_unit: 1,
    notes: "",
  }
  const classes = useStyles();
  const bodyTempLists = Object.keys(temperatureScale).map(key => ({
    label: capitalizeFirstLetter(key.replace(/_/g, ' ').toLowerCase()),
    level: temperatureScale[key]
  }));
  const [buttonLoading, setButtonLoading] = useState(false);
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData
  }));

  const handleSubmit = async (values, {resetForm}) => {
    const data = {
      temperature: values.temperature,
      temperature_unit: values.temperature_unit,
      notes: values.note,
      token: userData.token
    }
    const res = await mainService.addBodyTemperatureApi(data);
    if (res.response_type === "fail") {
      setButtonLoading(false);
      toast.error(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
    } else {
      resetForm();
      setButtonLoading(false);
      setOpenModal(false);
      getUpdatedValue(true)
      toast.success(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
    }
  }

  return (
    <div>
      <Grid className={classes.secondayCont}>
        <h2 className={classes.mainCont}>{strings.bodyTemp}</h2>
        <StyledCrossIcon
          src={"/assets/img/x.png"}
          alt="User"
          onClick={() => setOpenModal(false)}
        />

      </Grid>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={bodyTempSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleChange,
            errors,
            handleSubmit, setFieldValue, validateField
          }) =>{
            const handleDropdownValue = (data) => {
              if (data) {
                const ids = data.level;
                setFieldValue("temperature_unit", ids);
              } else {
                setFieldValue("temperature_unit", '');
              }
              validateField("temperature_unit");
            };
       return (
            <StyledFormik>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FieldWrapper
                    name="temperature"
                    label={`${strings.enterTemp}`}
                    type="number"
                    onChange={(e) => {
                      handleChange("temperature")(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                <SelectionDropdown
                    name="temperature_unit"
                    label={`${strings.selectScale}`}
                    list={bodyTempLists}
                    handleDropdownValue={handleDropdownValue}
                  />
                  {/* <BloodSugarLevelDropdown
                    name="temperature_unit"
                    label={`${strings.selectScale}`}
                    onChange={(e) => {
                      handleChange("temperature_unit")(e);
                    }}
                    list={bodyTempLists}
                  /> */}
                </Grid>
                <Grid item xs={12}>
                  <FieldWrapper
                    name="note"
                    type="text"
                    label={`${strings.note}`}
                    onChange={(e) => {
                      handleChange("note")(e);
                    }}
                  />
                </Grid>
              </Grid>
              <Box m={1} pt={1}>
                <StyledButton
                  name="submit"
                  disabled={Object.keys(errors).length > 0 || buttonLoading}
                  onClick={handleSubmit}
                  variant="contained">
                  {buttonLoading ? (
                    <CircularProgress
                      style={{ color: "white" }}
                      size={20}
                      thickness={5}
                    />
                  ) : `${strings.save}`}

                </StyledButton>
              </Box>
            </StyledFormik>
          )}}
        </Formik>
      </div>
    </div>
  )
}

export default BodyTemperature;