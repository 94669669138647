import React, { useState } from 'react';
import { useEffect } from 'react';
import './modal.css';

const HMSPackages = () => {

    const [isChecked, setChecked] = useState(false)

    useEffect(() => {
        executeScroll()
    },[])

    const executeScroll = () => {
		document.querySelector('body').scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

    const data = [
        {
            id: 1,
            title: 'In-patient Department (IPD)',
            amount: '48.99',
            buttontitle: 'Get Started ',
        },
        {
            id: 2,
            title: 'Both IPD & OPD',
            amount: '48.99',
            buttontitle: 'Get Started ',
            buttonborder: true
        }, {
            id: 3,
            title: 'Out-patient Department (OPD)',
            amount: '48.99',
            buttontitle: 'Get Started '

        }
    ]

    const otherdata = [
        {
            id: 1,
            text: 'Full control over your In patients'
        },
        {
            id: 2,
            text: 'Full control over your In patients'
        },
        {
            id: 3,
            text: 'Full control over your In patients'
        },
        {
            id: 4,
            text: 'Full control over your In patients'
        },
        {
            id: 5,
            text: 'Full control over your In patients'
        },
        {
            id: 6,
            text: 'Full control over your In patients'
        }

    ]

    return <div className="Packages-Main-Container">
        <p className="hms-package-title">15-days free trial</p>
        <p className="hms-package-title-other" >Find a simple plan which suits your need</p>
        <div className="hms-package-outer">
            <div className="hms-package-inner">
                <p className={!isChecked ? "hms-package-inner-text-one" : "hms-package-inner-text-two"}>Monthly</p>
                <label className="switch">
                    <input type="checkbox" onClick={() => setChecked((prevProps) => !prevProps)} checked={isChecked} />
                    <span className="slider round"></span>
                </label>
                <p className={isChecked ? "hms-package-inner-text-one" : "hms-package-inner-text-two"}>Yearly</p>
            </div>

        </div>
        <div className="hms-package-inner-main">
            {data.map((item) => {
                return (
                    <div className="hms-package-inner-one">
                        <p className="hms-package-inner-title-one">{item.title}</p>
                        <div className="border-bottom-main"><div className='bottom-border' /> </div>
                        {/* <div > */}
                        <div className="hms-package-amount">
                            <p className="hms-package-amount-title">{item.amount}<span className="yearly-title">/Yearly</span></p>
                        </div>
                        <div className={`button-div ${!item.buttonborder && 'extra-style'}`}>
                            <div className="hms-button-main">
                                <p className='hms-button-text'>{item.buttontitle}</p>
                            </div>
                        </div>
                        <div className="full-control-div">
                            {otherdata.map((item) => {
                                return (
                                    <div className="hms-check-div">
                                        <img src="/assets/img/check.png" style={{
                                            width: '24px',
                                            height: '24px'
                                        }} />
                                        <p className="hms-full-control-text">{item.text}</p>
                                    </div>
                                )

                            })}
                        </div>
                    </div>
                    // </div>
                )
            })}
        </div>
    </div>
}

export default HMSPackages;