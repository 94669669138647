import { object, string, number } from "yup";
import * as Yup from 'yup';

export const signUpSchema = object({
  email: string()
    .email("Please enter a valid email address")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i,
      "Invalid email"
    )
    .required("Email is required"),
  password: string()
    .required('Password is required')
    .min(8, "Minimum 8 characters required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    confirmPassword: string()
    .min(7, "Minimum 7 characters required")
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords do not match.") .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    phoneNumber: number()
    .positive("The number must be positive")
    .integer("The number must be an integer")
    .typeError("Please enter a valid phone number")
    .min(10, "Minimum 10 characters required")
    .required("Mobile Number is required"),
  fullName: string()
    .matches(/^(?!\\s)[a-zA-Z ]*$/, "Invalid input")
    .min(3, "Minimum 3 characters required")
    .required("Name is required")
});

    // .min(8, "Minimum 7 characters required")
    // .required("Password is required"),