import React from 'react';
import moment from 'moment';
import CardComponent from '../Card'
import './index.css'
import { useHistory } from 'react-router-dom';

function AppointmentsBox(props) {
	const { data, heading,height,showTotal,org, singleCard, upcomingAppointment, todayAppointment, keyItem, handleClickAppointment, subUserProp } = props;
	const history = useHistory()
	const [id, setId] = React.useState(0);
	const [AppointmentData, setAppointmentData] = React.useState([]);
	const path = window.location.pathname
	const renderName = (item) => {
		if(path == '/doctor/schedule'){
			return item?.patientId?.name
		}
		if(path == '/patient/dashboard'){
			return item?.doctorId?.name
		}
		if(path.includes('subuser')){
			return item?.patientId?.name
		}
		return item?.doctorId?.name
	}

	const getStatusClass = (item) => {
		if(item === 0) {
			return 'badge badge-pill bg-warning-light'
		} else if(item === 1 || item === 4) {
			return 'badge badge-pill bg-success-blue text-white'
		} else if(item === 2 || item === 3) {
			return 'badge badge-pill bg-danger-light'
		}
	}

	const getStatus = (item) => {
		if(item === 0) {
			return 'Pending'
		} else if(item === 1) {
			return 'Confirm'
		} else if(item === 2) {
			return 'Rejected'
		}  else if(item === 3) {
			return 'Cancelled'
		}  else if(item === 4) {
			return 'Completed'
		}
	}

	const renderProfileImage = (item) => {
		if(path == '/doctor/schedule'){
			return item?.patientId?.profileImage || "/assets/img/loading-img.jpg"
		}
		if(path == '/patient/dashboard'){
			return item.doctorId.profileImage || "/assets/img/loading-img.jpg"
		}
		if(path.includes('subuser')){
			return item?.patientId?.profileImage || "/assets/img/loading-img.jpg"
		}
		return item?.doctorId?.profileImage || "/assets/img/loading-img.jpg"
	}
// profileImage
	const renderDepartment = (item) => {
		if(path.includes('subuser')){
			return ''
		}
		if(path != '/doctor/schedule'){
			return <h6 style={{ color: "#999999",fontSize:'14px' }}>{item.doctorId?.department}</h6>
		}
	}
	React.useEffect(() => {
		setAppointmentData(todayAppointment)
	},[todayAppointment])


// You can also add years in the beginning of the array or seconds in its end
const UNITS = ["hours", "minutes"]
function getValidFor (createdAt, expiresAt, unit = 'months') {
    const validForUnit = expiresAt.diff(createdAt, unit);
    // you could adjust the if to your needs 
    if (validForUnit > 1 || unit === "minutes") {
    return [validForUnit, unit];
  }
  return getValidFor(createdAt, expiresAt, UNITS[UNITS.indexOf(unit) + 1]);
}
	const timeToStart = (startTime) => {
		const dateStr = startTime;
		const date = new Date(dateStr);
		const result = new Date(date.toISOString().slice(0, -1));
		return moment(result).toNow(true)
	}
	const handleSymptoms = (text) => text && text?.split(',')?.map((item) => <div className="btn btn-primary submit-btn ml-1 mt-1 filter-emed4u-sml-btn activebutton" style={{ backgroundColor: "#5156be", border: "1px solid #5156be" }}>{item}</div>);
	return (
		<>
			{
				singleCard ? (
					<CardComponent style={{minWidth:"340px"}} className="mb-4 current-vitals-chart-card">
						<div className={`d-flex row ml-1 current-vitals-chart-card-heading ${org ? "mr-0" : ''}`} style={{ alignItems: 'center' }}>
							<h6 className="pt-3 pl-2">{org ? heading : 'Appointments'}</h6>
							<div className={org ? "col-md-12 ml-auto d-flex mt-1" :  "ml-auto mr-4 mt-3"}>
								<span className={`float-end mr-1 ml-0 badge ${org ? "col-md-6" : '' } ${id == 0 ? "badge-success-transparent" : "badge-success-transparent-outline"}`} onClick={() => {
									setId(0);
									setAppointmentData(todayAppointment);
								}}>
									Today
								</span>
								<span className={`float-end mr-1 ml-0 badge ${org ? "col-md-6" : '' } ${id == 1 ? "badge-success-transparent" : "badge-success-transparent-outline"}`} onClick={() => {
									setId(1);
									setAppointmentData(upcomingAppointment)
								}}>
									Upcoming
								</span>
							</div>
						</div>
						<hr />
						<ul className={`list-unstyled pt-0 ${showTotal ? 'mb-0' : ''}`} style={{ height: height ? height : "350px",padding: '16px', overflow: "scroll" }} >
							{
								AppointmentData.length ? AppointmentData.map(item => {
									return <>
										<li
											className="media received d-flex m-0 mb-2 row current-vitals-chart-card-li"
											onClick={() => props.type === 'patient' ? props.history.push(`/patient/view-appointment/${item?._id}`) : history.push(`/doctor/view-appointment/${item?._id}/${item?.patientId?._id}`)}
											style={{ alignItems: "center", cursor: 'pointer' }}
										>
											<div class="media mt-0 col-md-12 p-0" style={{ alignItems: "center" }}>
												<img
													style={{ width: "45px", height: "45px", marginRight: "0px" }}
													class="rounded-circle"
													src={renderProfileImage(item)}
													alt="User"
												/>
												<div className="col-md pr-0" style={{ alignItems: "center" }}>
													
													<div className=''>
														<div className="col-md-7 p-0 dateTimeDiv-details">
															<h4
																style={{ fontSize: '15px',whiteSpace: 'nowrap' }}
															>
																{renderName(item)}
															</h4>
															{renderDepartment(item)}
														</div>
														<div
															className="col-md p-0 dateTimeDiv-details"
															style={{
																display: 'flex',
    															justifyContent: 'space-between',
															}}
															>
															<span style={{ fontSize: "12px" }}>
																{moment(item.slot.start).utc().format("DD/MM/YYYY")}
															</span> 
															<br />
															<span style={{ color: "#5156be", fontSize: '12px' }}>
																{moment(item.slot.start).utc().format("hh:mm a")} - {moment(item.slot.end).utc().format("hh:mm a")}
															</span>
														</div>
														<div
															className='d-flex'
															style={{
																display: 'flex',
																justifyContent: 'space-between',
																alignItems: 'flex-start',
																gap: '5px'
															}}
														>
															<span className={getStatusClass(item.status)} style={{fontSize: '10px'}}>
																{getStatus(item.status)}
															</span>
															<span className="ml-auto 1" style={{ color: "#5156be", fontSize: "12px" }}><i class="far fa-clock"></i> starts in {timeToStart(item.slot.start)}</span>
														</div>
													</div>
												</div>
											</div>
										</li>
										<hr />
									</>
								}) :
									<div className="d-flex justify-content-center" style={{ marginTop: "3 rem", textAlign: "center", padding: "20px" }}>Sorry! There are no scheduled appointments</div>
							}

						</ul>
						{
							showTotal ?
							<div className="d-flex ml-1 mr-3 mt-2 mb-2">
							<h5>
								Total appointments: {AppointmentData ? AppointmentData.length : 0}
							</h5>
						</div> : null
						}
						{/* <div className="col-md row">
									<div style={{ margin: "0px 10px", backgroundColor: "#c8c9ee", borderColor: "#c8c9ee", color: "#5156be" }} className="btn-patient-primary button-text-white submit-btn p-2 pr-4 mt-3 mb-3 ml-auto"><i class="fas fa-pen"></i>   Edit Consult</div>
								</div> */}
					</CardComponent>
				)
					:
					heading === 'Previous appointments' ?
						(
							<CardComponent style={{minWidth:"340px"}} key = {data} className="mb-4 appointments-chart-card">
								<div className="d-flex row ml-1 current-vitals-chart-card-heading">
									<h5 className="pt-3 pl-3">{heading}</h5>
								</div>
								<hr />
								<ul className="list-unstyled pl-3 pr-3" style={{ maxHeight: "250px", overflow: "scroll" }} >
									{
										data.length ? data.map(item => {
											return <li
												className="received m-0 mb-2 current-vitals-chart-card-li"
												style={{ alignItems: "center", cursor:"pointer"}}
												onClick={()=>handleClickAppointment(item)}
											>
												<div class="media mt-0 col-md p-0">
													<div class="media-body">
														<div class="align-items-center">
															<div class="mt-0">
																<h5 style={{wordBreak: 'break-all'}} class="mb-1 tx-13 font-weight-sembold text-dark">
																	{item.SecondOpinionId?.why_do_doctor ? item.SecondOpinionId?.why_do_doctor : ''}
																</h5>
																<div style={{ paddingLeft: "20px" }}>
																	{item.SecondOpinionId?.symptoms.length > 1 ?
																		item.SecondOpinionId?.symptoms.map((item) => (
																		item ? <div className="btn btn-primary submit-btn ml-1 mt-1 filter-emed4u-sml-btn activebutton" style={{ backgroundColor: "#5156be", border: "1px solid #5156be" }}>{item}</div> : null
																		)) : handleSymptoms(item?.SecondOpinionId?.symptoms[0])
																	}
																</div>
															</div>
														</div>
													</div>
												</div>
												<hr />
											</li>
										}) : <div className="d-flex justify-content-center" style={{ marginTop: "21%", textAlign: "center", padding: "20px" }}>Sorry! There are no Appointments to display</div>
									}
								</ul>
							</CardComponent>
						) : (
							<CardComponent style={{minWidth:"340px"}} key = {data} className="mb-4 appointments-chart-card">

								<h5 className="mb-3">{heading}</h5>
								{data && data.length ?
									<>
										<div id={`carouselExampleControls${keyItem}`} class="carousel slide" data-ride="carousel">
											<div class="carousel-inner">
												<div class="carousel-item active" onClick={() => props.type === 'patient' ? props.history.push(`/patient/view-appointment/${data[0]._id}`) : {}}>
													<div className="row d-flex pr-5 pl-5 dateTimeDiv" style={{ justifyContent: "space-between" }}>
														<span className="ml-auto 2" style={{ color: "#5156be" }}><i class="far fa-clock"></i> starts in {timeToStart(data[0].slot.start)}</span>
													</div>
													<div className="d-flex mt-2" style={{ alignItems: "center", cursor: 'pointer' }} onClick={() => this.redirectToAppointment()}>
														<div className="col-md-2" style={{ margin: "5px", height:"55px",width:"55px",padding: "0px" }}>
															<img style={{width: '100%', height: '100%', borderRadius: '50%'}} src={data[0].doctorId && data[0].doctorId.profileImage ? data[0].doctorId.profileImage : "/assets/img/loading-img.jpg"} alt="User" />
														</div>
														<div className="col-md-5 dateTimeDiv-details">
															<h4 style={{fontSize: '15px'}}>{data[0].doctorId?.name}</h4>
															<h6 style={{ color: "#999999" }}>{data[0].doctorId?.department}</h6>
														</div>
														<div className="col-md-5 dateTimeDiv-details">
															<span>{moment(data[0].slot.start).format("DD/MM/YYYY")}</span> <br />
															<span style={{ color: "#5156be", fontSize: '12px' }}>{moment(data[0].slot.start).format("hh:mm a")} - {moment(data[0].slot.end).format("hh:mm a")} </span>
														</div>
													</div>
												</div>
												{data.map((item, index) => {
													if (index >= 1) {
														return (
															<div class="carousel-item" onClick={() => props.type === 'patient' ? props.history.push(`/patient/view-appointment/${item._id}`) : {}}>
																<div className="row d-flex pr-5 pl-5 dateTimeDiv" style={{ justifyContent: "space-between" }}>
																	<span className="ml-auto 3" style={{ color: "#5156be" }}><i class="far fa-clock"></i> starts in {timeToStart(item.slot.start)}</span>
																</div>
																<div className="d-flex mt-2" style={{ alignItems: "center", cursor: 'pointer' }} onClick={() => this.redirectToAppointment()}>
																	<div className="col-md-2" style={{ margin: "5px", height:"55px",width:"55px",padding: "0px" }}>
																		<img style={{width: '100%', height: '100%', borderRadius: '50%'}} src={item.doctorId && item.doctorId.profileImage ? item.doctorId.profileImage : "/assets/img/loading-img.jpg"} alt="User" />
																	</div>
																	<div className="col-md-5 dateTimeDiv-details">
																		<h4 style={{fontSize: '15px'}}>{item.doctorId?.name}</h4>
																		<h6 style={{ color: "#999999" }}>{item.doctorId?.department}</h6>
																	</div>
																	<div className="col-md-5 dateTimeDiv-details">
																		<span>{moment(item.slot.start).format("DD/MM/YYYY")}</span> <br />
																		<span style={{ color: "#5156be", fontSize: '12px' }}>{moment(item.slot.start).format("hh:mm a")} - {moment(item.slot.end).format("hh:mm a")} </span>
																	</div>
																</div>
															</div>
														)
													}
												}
												)}
											</div>
											<a style={{ alignItems: "flex-start", width: "9%" }} class="carousel-control-prev" href={`#carouselExampleControls${keyItem}`} role="button" data-slide="prev">
												<span class="carousel-control-prev-icon" aria-hidden="true"></span>
												<span class="sr-only">Previous</span>
											</a>
											<a style={{ alignItems: "flex-start", width: "9%", }} class="carousel-control-next" href={`#carouselExampleControls${keyItem}`} role="button" data-slide="next">
												<span class="carousel-control-next-icon" aria-hidden="true"></span>
												<span class="sr-only">Next</span>
											</a>
										</div>

										<hr />

									</> : <div className="d-flex justify-content-center" style={{ marginTop: "21%", textAlign: "center", padding: "20px" }}>Sorry! There are no Appointments to display</div>
								}
							</CardComponent>
						)}
		</>
	);
}

export default AppointmentsBox;
