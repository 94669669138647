import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Context } from '../../../store';
import {
	postForgotAPI
} from '../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING,
	SET_INFO_ORGANIZATION
} from '../../../store/types';
import { globalValidator } from "../../../utils";
import InputField from '../../../components/InputField';
import {
	StyledLeftPanel,
	StyledLeftHeading,
	StyledLeftSubHeading,
	StyledLeftImage,
	StyledRightPanel,
	StyledLoginButton,
	StyledRightBottom,
	StyledBottomContent,
	StyledBottomLink,
} from './styles';

function ForgotPasswordOrgination(props) {

	const [state, dispatch] = useContext(Context);
	const [orgUser, SetOrgUser] = useState({ email: '' });

	const forgotPwd = (e) => {
		e.preventDefault();
		const { email } = orgUser;
		const keys = [
			{ name: "email", title: "Email", validateKey: "email" }
		];
		let valid = globalValidator({ email }, keys);

		if (valid === true) {
			loader(true);
			postForgotAPI(orgUser).then(res => {
				loader(false);
				toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				dispatch({
					type: SET_INFO_ORGANIZATION,
					payload: res?.data
				});
				props.history.push('/organization/login');
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error || 'Please provide valid email')
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error || 'Please provide valid email'
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<section className="section home-tile-section">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-9 m-auto">
						<div className="row">
							<StyledLeftPanel className="col-md-5">
								<div className="row no-gutters h-100">
									<div className="col-10 col-lg-9 d-flex flex-column mx-auto">
										<StyledLeftHeading className="mt-5 mb-4">Forgot password for organization / clinic</StyledLeftHeading>
										<StyledLeftSubHeading>
											If you have forgotten your password please type in your Email id.
										</StyledLeftSubHeading>
										<div className="mt-auto mb-4">
											<StyledLeftImage src="http://demo.harnishdesign.net/html/oxyy/images/login-vector.png" />
										</div>
									</div>
								</div>
							</StyledLeftPanel>
							<StyledRightPanel className="col-md-7">
								<div className="container my-auto py-5">
									<div className="row">
										<div className="col-11 col-lg-10 mx-auto">
											{/* <StyledRightHeading>Login with Social Profile</StyledRightHeading>
											<div className="d-flex  flex-column align-items-center mb-3">
												<StyledRightSocialLogin>
													<StyledSocialFB>
														<i className="fab fa-facebook-f mr-1" />
													</StyledSocialFB>
													<StyledSocialGoogle>
														<i className="fab fa-google mr-1" />
													</StyledSocialGoogle>
												</StyledRightSocialLogin>
											</div> */}

											{/* <StyledRightDivider className="d-flex align-items-center my-3">
												<StyledDividerLine />
												<StyledDividerHeading className="mx-2">
													Or use your email account
												</StyledDividerHeading>
												<StyledDividerLine />
											</StyledRightDivider> */}

											<form className="form-border info-widget">
												<div className="form-group card-label">
													<label>Email</label>
													<InputField value={orgUser.email} onChange={e => SetOrgUser((user) => ({ ...user, email: e.target.value }))} type="email" />
												</div>

												{/* <div className="form-group card-label">
													<label>Password</label>
													<InputField value={orgUser.password} password={true}  type="password" onChange={e => SetOrgUser((user) => ({...user, password: e.target.value}))} />
												</div> */}

												{/* <StyledForgotPassword onClick={() => forgotPassword()}>
													<StyledForgotPasswordLink>
														Forgot Password ?
													</StyledForgotPasswordLink>
												</StyledForgotPassword> */}

												<StyledLoginButton onClick={(e) => forgotPwd(e)}>
													Forgot password
												</StyledLoginButton>
											</form>

											<StyledRightBottom>
												<StyledBottomContent>
													I have an account? -
													<StyledBottomLink to={'/organization/login'}>
														Sign in Now
													</StyledBottomLink>
												</StyledBottomContent>
											</StyledRightBottom>
										</div>
									</div>
								</div>
							</StyledRightPanel>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ForgotPasswordOrgination
