import { CardButton,ButtonTitle, CardDescription, CardTitle, CardSub,HoverCard, HoverCardOne, HoverCardTwo, RightArrowIcon, CardSubPoints, ImageMain, } from "./styles"
import { Link } from 'react-router-dom';
const title1 = 'Health & care at your door step.'
const title2 = 'Enhance Continuity of care'
const description = 'Our HMS is designed in line with international standards. It strives to establish a healthcare ecosystem that includes all key stakeholders in the delivery of healthcare, such as doctors, nurses, medical staff, radiologists, etc. The goal is to establish an efficient workflow between all departments so they can offer patient-centered solutions. It is a easy to learn and adaptable software that will offer complete solutions for all important hospital activities.'
const title3 = 'Streamline workflow'
const title4 = 'increase Revennue'
const title5 = 'Faster Vital Tracking'
const title6 = 'Optimise Resource'
const title7 = 'Quality patient Experience'
const title8 = 'Analyze Efficiency'
const sub1 = 'Comprehensive and innovative features'
const sub2 = 'Continuous workflow'
const sub3 = 'Holistic solution to patient’s problems'
const sub4 = 'Precise & timely transactional reports'
const sub5 = 'Comprehensive view of financial health'
const sub6 = 'Decrease in operational costs'
const sub7 = 'Record health vitals'
const sub8 = 'Automatically track vitals using wearables'
const sub9 = 'Detect early signs & symptoms'
const sub10 = 'Real-time departments collaborarions'
const sub11 = 'Enhances productivity and decision-making'
const sub12 = 'Automated processes reduces workload'
const sub13 = 'Easy online and in-clinic appointments.'
const sub14 = 'Reduce waiting time'
const sub15 = 'No need of carrying excessive documents'
const sub16 = 'Track the efficiency of all key holders'
const sub17 = 'Comprehensive data on patient footfall'
const sub18 = 'Aids in further improvement & planning'

export const HMSCard = () => {
    return (
        <HoverCard>
            <CardTitle>{title1}</CardTitle>
            <CardDescription>{description}</CardDescription>
            <Link style={{ whiteSpace: 'pre' }} to={'/hmspackages'}>
                <CardButton>
                    <ButtonTitle>START FREE TRIAL NOW</ButtonTitle>
                    <RightArrowIcon />
                </CardButton>
            </Link>
        </HoverCard>
    )
}
export const HMSCardOne = () => {
    return (
        <HoverCardOne>
            <ImageMain alt="img" src="/assets/img/HeartImage.png"/>
            <CardTitle>{title2}</CardTitle>
            <CardSub>
                <CardSubPoints>Top-notch technology</CardSubPoints>
                <CardSubPoints>Patient-centered treatment</CardSubPoints>
                <CardSubPoints>Increase the number of satisfied clients</CardSubPoints>
            </CardSub>
        </HoverCardOne>
    )
}
export const HMSCardTwo = () => {
    return (
        <HoverCardTwo>
             <ImageMain alt="img" src="/assets/img/img2.png"/>
            <CardTitle>{title3}</CardTitle>
            <CardSub>
                <CardSubPoints>{sub1}</CardSubPoints>
                <CardSubPoints>{sub2}</CardSubPoints>
                <CardSubPoints>{sub3}</CardSubPoints>
            </CardSub>

        </HoverCardTwo>
    )
}
export const HMSCardThree = () => {
    return (
        <HoverCardOne>
             <ImageMain alt="img" src="/assets/img/img3.png"/>
            <CardTitle>{title4}</CardTitle>
            <CardSub>
                <CardSubPoints>{sub4}</CardSubPoints>
                <CardSubPoints>{sub5}</CardSubPoints>
                <CardSubPoints>{sub6}</CardSubPoints>
            </CardSub>

        </HoverCardOne>
    )
}
export const HMSCardFour = () => {
    return (
        <HoverCardTwo>
             <ImageMain alt="img" src="/assets/img/img4.png"/>
            <CardTitle>{title5}</CardTitle>
            <CardSub>
                <CardSubPoints>{sub7}</CardSubPoints>
                <CardSubPoints>{sub8}</CardSubPoints>
                <CardSubPoints>{sub9}</CardSubPoints>
            </CardSub>

        </HoverCardTwo>
    )
}
export const HMSCardFive = () => {
    return (
        <HoverCardOne>
             <ImageMain alt="img" src="/assets/img/img5.png"/>
            <CardTitle>{title6}</CardTitle>
            <CardSub>

                <CardSubPoints>{sub10}</CardSubPoints>
                <CardSubPoints>{sub11}</CardSubPoints>
                <CardSubPoints>{sub12}</CardSubPoints>
            </CardSub>

        </HoverCardOne>
    )
}
export const HMSCardSix = () => {
    return (
        <HoverCardTwo>
             <ImageMain alt="img" src="/assets/img/img6.png"/>
            <CardTitle>{title7}</CardTitle>
            <CardSub>
                <CardSubPoints>{sub13}</CardSubPoints>
                <CardSubPoints>{sub14}</CardSubPoints>
                <CardSubPoints>{sub15}</CardSubPoints>
            </CardSub>

        </HoverCardTwo>
    )
}
export const HMSCardSeven = () => {
    return (
        <HoverCardOne>
             <ImageMain alt="img" src="/assets/img/img7.png"/>
            <CardTitle>{title8}</CardTitle>
            <CardSub>
                <CardSubPoints>{sub16}</CardSubPoints>
                <CardSubPoints>{sub17}</CardSubPoints>
                <CardSubPoints>{sub18}</CardSubPoints>
            </CardSub>

        </HoverCardOne>
    )
}