import { CircularProgress, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { useStyles } from "../../assets/styles/BloodSugarStyles";
import { StyledButton, StyledCrossIcon, StyledFormik } from "../../assets/styles/CommonStyles";
import strings from "../../constant/strings";
import { bloodPressureSchema } from "../../utils/forms/schema.bloodPressureSchema";
import FieldWrapper from "../InputField/TextFieldWrapper";
import * as mainService from "../../services/mainService.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const BloodPressure = ({ setOpenModal, getUpdatedValue }) => {
  const classes = useStyles();
  const formikRef = useRef();
  const [buttonLoading, setButtonLoading] = useState(false);
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData
  }));

  let initialValue= {
    systolic: "",
    diastolic: "",
    note: ""
  }

  const handleValues = async (values, { resetForm }) => {
    const data = {
      sys_blood_pressure: values.systolic,
      dia_blood_pressure: values.diastolic,
      notes: values.note,
      token: userData.token
    }
    const res = await mainService.addBloodPressureApi(data);
    if (res.response_type === "fail") {
      setButtonLoading(false);
      toast.error(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
    } else {
      setButtonLoading(false);
      getUpdatedValue(true);
      setOpenModal(false);
      resetForm();
      toast.success(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
    }
  }

  return (
    <div>
       <Grid className={classes.secondayCont}>
                <h2 className={classes.mainCont}>{strings.bloodPressure}</h2>
                <StyledCrossIcon
                  src={"/assets/img/x.png"}
                  alt="User"
                  ref={formikRef}
                  onClick={() =>  setOpenModal(false)}
                />

              </Grid>
      <div>
        <Formik
          initialValues={initialValue}
          validationSchema={bloodPressureSchema}
          onSubmit={handleValues}

        >
          {({
            handleChange,
            errors,
            values,
            handleSubmit,
            
          }) => (
            <StyledFormik>
             
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FieldWrapper
                    name="systolic"
                    label={`${strings.systolic}`}
                    type="number"
                    onChange={(e) => {
                      handleChange("systolic")(e);
                    }}
                  />

                </Grid>
                <Grid item xs={12}>
                  <FieldWrapper
                    name="diastolic"
                    type="number"
                    label={`${strings.diastolic}`}
                    onChange={(e) => {
                      handleChange("diastolic")(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldWrapper
                    name="note"
                    type="text"
                    label={`${strings.note}`}
                    onChange={(e) => {
                      handleChange("note")(e);
                    }}
                  />
                </Grid>
              </Grid>
              <Box m={1} pt={1}>
                <StyledButton
                  name="submit"
                  disabled={Object.keys(errors).length > 0 || buttonLoading}
                  onClick={handleSubmit}
                  variant="contained">
                  {buttonLoading ? (
                    <CircularProgress
                      style={{ color: "white" }}
                      size={20}
                      thickness={5}
                    />
                  ) : `${strings.save}`}

                </StyledButton>
              </Box>
            </StyledFormik>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default BloodPressure;