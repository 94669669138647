import React from 'react';

import {
	StyledContainer
} from './styles';
import { Helmet } from "react-helmet";

function HowItWorks() {

	const organisation = [
		{
			id: 1,
			name: 'Setting up as an organisation',
			value: 'signup-org'
		},
		{
			id: 2,
			name: 'Dashboard',
			value: 'dashboard-org'
		},
		{
			id: 3,
			name: 'Appointments',
			value: 'appointments-org'
		},
		{
			id: 4,
			name: 'Doctor (add/list)',
			value: 'doctor-org'
		},
		{
			id: 5,
			name: 'Patient (Add/list)',
			value: 'patient-org'
		},
		{
			id: 6,
			name: 'Sub-admin (add/list)',
			value: 'sub-org'
		},
		{
			id: 7,
			name: 'Coupons',
			value: 'coupons-org'
		},
		{
			id: 8,
			name: 'Invoices',
			value: 'invoices-org'
		},
		{
			id: 9,
			name: 'Edit you profile',
			value: 'profile-org'
		},
		{
			id: 10,
			name: 'Logout',
			value: 'logout-org'
		}
	];

	const doctors = [
		{
			id: 1,
			name: 'Doctors/clinicians/health practitioners',
			value: 'dashboard-doctor'
		},
		{
			id: 2,
			name: 'My appointment overview',
			value: 'appointment-doctor'
		},
		{
			id: 3,
			name: 'List and Calendar View',
			value: 'calendar-doctor'
		},
		{
			id: 4,
			name: 'Schedule Time',
			value: 'schedule-doctor'
		},
		{
			id: 5,
			name: 'Communication',
			value: 'communication-doctor'
		},
		{
			id: 6,
			name: 'Patient (Add/list)',
			value: 'patient-doctor'
		},
		{
			id: 7,
			name: 'Payment method',
			value: 'payment-doctor'
		},
		{
			id: 8,
			name: 'Invoices',
			value: 'invoices-doctor'
		},
		{
			id: 9,
			name: 'Profile settings',
			value: 'profile-doctor'
		},
		{
			id: 10,
			name: 'Logout',
			value: 'logout-doctor'
		}
	];

	const patients = [
		{
			id: 1,
			name: 'Patient and Clients',
			value: 'dashboard-patient'
		},
		{
			id: 2,
			name: 'My appointment overview',
			value: 'appointment-patient'
		},
		{
			id: 3,
			name: 'Book your appointment',
			value: 'book-patient'
		},
		{
			id: 4,
			name: 'Message your doctor',
			value: 'message-patient'
		},
		{
			id: 5,
			name: 'My health data',
			value: 'health-patient'
		},
		{
			id: 6,
			name: 'My medical history',
			value: 'medical-patient'
		},
		{
			id: 9,
			name: 'Profile settings',
			value: 'profile-patient'
		},
		{
			id: 11,
			name: 'Billing and invoices',
			value: 'billing-patient'
		},
		{
			id: 10,
			name: 'Logout',
			value: 'logout-patient'
		}
	];

	const [orgValue, setOrgValue] = React.useState('signup-org');
	const [doctorValue, setDoctorValue] = React.useState('dashboard-doctor');
	const [patientValue, setPatientValue] = React.useState('dashboard-patient');

	return (
		<StyledContainer>
			<Helmet title={'Online Hospital Management solution, Patient managemnet solution.'}
				htmlAttributes={{ lang: "en" }}
				meta={[
					{
						name: `description`,
						content: 'online hospital management solution for doctors. Manage Patient bills and appointment at one place',
					},
					{
						name: `keywords`,
						content: 'hospital management system, patient management system, paitient bill and appoitmnet system',
					},
				]}
			/>
			<section className="section section-specialities">
				<div class="container">
					<div className="section-header text-center">
						<h2>Emed4u feels proud to introduce Arrow</h2>
					</div>
					<div className="section-header">
						<p>
							<img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" />Arrow1 is a dynamic, versatile and intelligent web-based solutions for any professional
							working in a healthcare and wellbeing sector. It could be used by a doctor, a hospital
							manager, a therapist, a neuro-linguistic programmer, a physiotherapist or even a yoga
							practitioner. The list of professionals that can benefit from this is a long one.
						</p>
						<p>
							<img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" />It is a smart solution bridging the gap between face to face and online reviews. It is a tool to manage your appointments, trackthe health of your patients or clients, keep a detailed overview of your financial productivity and most importantly provide and excellent and informed continuity of care.
						</p>
						<p>
							<img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" />Arrow1 is an innovative healthcare product that amalgamates essence of German engineering, British management and Indian technology. We are so proud of our product that if telemedicine is considered an evolution of modern age, we believe Arrow is revolution.
						</p>
					</div>
				</div>
			</section>

			<section className="section pt-4 bg-blue mt-4 mb-5">
				<div class="container">
					<div className="section-header text-center">
						<h2>How it works?</h2>
					</div>
					<div className="section-header ">
						<p>We totally understand the stress and the frustration of trying a new system, especially the ones that are based around healthcare. Hence the medical and technology team from Emed4u have worked hard to make Arrow1 as simple as ABC. Think of it as a 3 step process:</p>
					</div>
					<div className="row">
						<div className="col-md-4 ">
							<div className="card m-1 p-4 card-hows">
								<div className="u-valign-middle">
									<h1>01</h1>
								</div>
								<div className="">Step 1: Set up your organisation and choose the subscription package.</div>
							</div>
						</div>
						<div className="col-md-4 ">
							<div className="card m-1 p-4 card-hows">
								<div className="u-valign-middle">
									<h1>02</h1>
								</div>
								<div className="">Step 2: Add your team of professionals in your organisation account.</div>
							</div>
						</div>
						<div className="col-md-4 ">
							<div className="card m-1 p-4 card-hows">
								<div className="u-valign-middle">
									<h1>03</h1>
								</div>
								<div className="">Step 3: The team members confirm their individual accounts and all is done.</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* For organisation */}
			<section className="section section-specialities">
				<div className="content">
					<div className="container-fluid">
						<div className="section-header text-center">
							<h2>How it works for an Organisation / Clinic?</h2>
						</div>
						<div className="row">
							<div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
								<div className="profile-sidebar">
									<div className="dashboard-widget">
										<nav className="dashboard-menu">
											<ul>
												{
													organisation.map(item => {
														return <li className={item.value === orgValue ? 'active-org' : 'org'} key={item.id} onClick={() => setOrgValue(item.value)}>
															<a>{item.name}</a>
														</li>
													})
												}

											</ul>
										</nav>
									</div>
								</div>
							</div>

							<div className="col-md-7 col-lg-8 col-xl-9 pl-4">
								<div className="section-title-org">
									<p>{organisation.filter(item => item.value === orgValue)[0].name}</p>
									<hr />
								</div>
								{
									orgValue === 'signup-org' &&
									<>
										<p className="font-18">
											The first step to use Arrow1 is by setting yourself up as an organisation. This is applicable in all situations where you may be a lone practitioner or a multi-speciality hospital or even if you are a professional involved in holistic or alternate medicine.
										</p>

										<div class="info-box pl-4 pr-4">
											<ul class="list green-bullets ">
												<li>Go to www.emed4u.com</li>
												<li>Click on the section of organisation/clinic.</li>
												<li>Sign up or sign in as applicable.</li>
												<li>Fill the registration form and submit the required documents.</li>
												<li>Once submitted, your registration details will be reviewed and verified by our compliance team. This may take 1-2 working days although our aim is to complete this process in less than 1 day.</li>
												<li>Once your details are verified, your account will become active and your will receive an email and a text message to notify you.</li>
												<li>This means that your organisation account is set up.</li>
												<li>At this stage we recommend that you reset your password from "edit your profile" section on left hand side menu bar.</li>
												<li>You can also add or change your profile logo and other details.</li>
											</ul>
										</div>
									</>
								}

								{
									orgValue === 'dashboard-org' &&
									<>
										<p className="font-18">This button provides you a complete overview of the appointments which are scheduled for today or the ones that are upcoming. Action button gives you a snapshot of a specific appointment including the details of the patient and practitioner.</p>
									</>
								}

								{
									orgValue === 'appointments-org' &&
									<>
										<p className="font-18">This button gives you a complete overview of the historical appointments and their respective statuses.The status may appear as confirmed, pending or cancelled.</p>
									</>
								}


								{
									orgValue === 'doctor-org' &&
									<>
										<p className="font-18">
											Use this button to add your team members or your own self in your organisation which you have created earlier. If you are lone worker go to add a doctor and fill out the form by supplying some basic information.
										</p>

										<p className="font-18">
											If there are more than 1 professional in your team, you can add them by proving some basic information. This option is not only for adding doctors in your team, it can also be used to add other professionals e.g. a physiotherapist in an orthopaedic hospital, a yoga practitioner in a health and wellbeing clinic or a therapist in a psychiatric clinic. All the team members will receive a text message and an email to notify them of this addition. They can login with a default password and create their profile.
										</p>

										<p className="font-18">
											You also have the possibility to activate or deactivate a team member’s account depending on their employment status in your organisation.
										</p>

									</>
								}


								{
									orgValue === 'patient-org' &&
									<>
										<p className="font-18">
											This button gives you the possibility to add a patient with some basic information to your organisation (name, gender, email and phone number). Patient will receive an email and sms with a default password that can be changed once they log in their account.  Once the patient login their account, they will have an overview of which professionals are available in your team. The appointments then can be booked as per availability and clinical needs.
										</p>

										<p className="font-18">
											An organisation can see the list of patients that have received services from them.
										</p>
									</>
								}

								{
									orgValue === 'sub-org' &&
									<>
										<p className="font-18">
											This button can be used to add a sub-admin to your organisation. The sub-admin could be a nurse, receptionist, compounder or a secretary employed by the organisation. Organisation is expected to set up an account and a password for the subadmin which provides them with a possibility to add patients and allocate them to available clinicians.
										</p>

										<p className="font-18">
											Organisation has the possibility of activating or deactivating the sub-admin’s account depending on their employment status.
										</p>
									</>
								}

								{
									orgValue === 'coupons-org' &&
									<>
										<p className="font-18">
											This button provides you the possibility to generate a reduction voucher which could be in the form of actual monetary amount or a percentage off from the consultation. You can use this option in promotions, special occasion, festivals, birthdays, future follow ups etc. You have the option of setting the validity or expiry date of the voucher as well.
										</p>
									</>
								}

								{
									orgValue === 'invoices-org' &&
									<>
										<p className="font-18">
											This button lists all the invoices issued by your organisation.
										</p>
									</>
								}

								{
									orgValue === 'profile-org' &&
									<>
										<p className="font-18">
											Use this button to edit your profile page. You have range of possibilities here from editing your organisation name, logo, image, location, tagline etc.
										</p>
									</>
								}

								{
									orgValue === 'logout-org' &&
									<>
										<p className="font-18">
										</p>
									</>
								}

							</div>
						</div>
					</div>

				</div>
			</section>

			{/* For Doctor  */}
			<section className="section section-specialities" style={{ background: '#f8f9fa' }}>
				<div className="content">
					<div className="container-fluid">
						<div className="section-header text-center">
							<h2>How it works for a Doctors / clinicians / health practitioners?</h2>
						</div>
						<div className="row">
							<div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
								<div className="profile-sidebar">
									<div className="dashboard-widget">
										<nav className="dashboard-menu">
											<ul>
												{
													doctors.map(item => {
														return <li className={item.value === doctorValue ? 'active-main' : 'main'} key={item.id} onClick={() => setDoctorValue(item.value)}>
															<a>{item.name}</a>
														</li>
													})
												}

											</ul>
										</nav>
									</div>
								</div>
							</div>

							<div className="col-md-7 col-lg-8 col-xl-9 pl-4">
								<div className="section-title-org">
									<p>{doctors.filter(item => item.value === doctorValue)[0].name}</p>
									<hr />
								</div>
								{
									doctorValue === 'dashboard-doctor' &&
									<>
										<p className="font-18">
											There are 2 ways in which you can set up an account as a doctor or a health professional.
										</p>

										<div class="info-box pl-4 pr-4">
											<ul class="list green-bullets ">
												<li>
													By making your own organisation in the first instance and then adding yourself as a lone worker.
												</li>
												<li>
													Another organisation which already has an account set up on Arrow1 by Emd4u adds you as a professional in their team.
												</li>
											</ul>
										</div>
									</>
								}

								{
									doctorValue === 'appointment-doctor' &&
									<>
										<p className="font-18">This button gives you an overview or your past, current and upcoming clinical appointments. Action button gives you a snapshot of the appointment including the details of the patient and the practitioner. It also gives you the option to viewas to how the payment was made and if the patient or client has added their medical history and their other health parameters.</p>
									</>
								}

								{
									doctorValue === 'calendar-doctor' &&
									<>
										<p className="font-18">This button provides you a summary of all the patients that you have treated. You can also get a snapshot of the current and upcoming appointments in a list or a calendar view. The action button helps to review if a payment has been made and gives you the choice if you want to accept or reject a particular appointment.</p>
									</>
								}


								{
									doctorValue === 'schedule-doctor' &&
									<>
										<p className="font-18">
											This button assists you in scheduling your availability and time slots and is visible to your patients or clients when they choose to be reviewed by you. You have the possibility of choosing 2 different types of appointments which are either by online mode or the in clinic face to face reviews. You may choose your own consultation fees and set the currency that you would like to be paid in.
										</p>

									</>
								}


								{
									doctorValue === 'communication-doctor' &&
									<>
										<p className="font-18">
											This button will let you chat with your patients either through text or with the audio or video call. Please note that this function is available only to the clinicians and is not available to the patients. Once the review is over you can then generate a final report for the patient.
										</p>

									</>
								}

								{
									doctorValue === 'patient-doctor' &&
									<>
										<p className="font-18">
											This button gives you the possibility to add a patient with some basic information such as name, gender, email and phone number. You may choose to add their current symptoms or the patients have a possibility of filling these details from their own accounts.
										</p>

										<p className="font-18">
											You have the possibility of choosing a specific patient who you may wish to monitor more closely and review their file as and when needed. You can see their medical history and most importantly their vital health parameters in a live graphical format.
										</p>

										<p className="font-18">
											You also have the option of rebooking an alternate appointment for a patient that you have seen in the past.
										</p>
									</>
								}

								{
									doctorValue === 'payment-doctor' &&
									<>
										<p className="font-18">
											This button allows you to decide the payment that you would like to use for your consultation. You just have to add the payment ID of respective portal.
										</p>
									</>
								}

								{
									doctorValue === 'invoices-doctor' &&
									<>
										<p className="font-18">
											This button lists all the invoices that have been generated for your consultation.
										</p>
									</>
								}

								{
									doctorValue === 'profile-doctor' &&
									<>
										<p className="font-18">
											Use this button to edit your profile page and upload a recent picture or a logo. You can update your password in this domain.
										</p>
									</>
								}

								{
									doctorValue === 'logout-doctor' &&
									<>
										<p className="font-18">
										</p>
									</>
								}

							</div>
						</div>
					</div>

				</div>
			</section>

			{/* For patient */}
			<section className="section section-specialities">
				<div className="content">
					<div className="container-fluid">
						<div className="section-header text-center">
							<h2>How it works for a Patient and Clients?</h2>
						</div>
						<div className="row">
							<div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
								<div className="profile-sidebar">
									<div className="dashboard-widget">
										<nav className="dashboard-menu">
											<ul>
												{
													patients.map(item => {
														return <li className={item.value === patientValue ? 'active-patients' : 'patients'} key={item.id} onClick={() => setPatientValue(item.value)}>
															<a>{item.name}</a>
														</li>
													})
												}

											</ul>
										</nav>
									</div>
								</div>
							</div>

							<div className="col-md-7 col-lg-8 col-xl-9 pl-4">
								<div className="section-title-org">
									<p>{patients.filter(item => item.value === patientValue)[0].name}</p>
									<hr />
								</div>
								{
									patientValue === 'dashboard-patient' &&
									<>

										<div class="info-box pl-4 pr-4">
											<ul class="list green-bullets ">
												<li>
													A patient profile can be created either by an organisation, a doctor or a sub-admin. Scenarios in this case could vary from a patient calling an organisation to request an appointment or a walk-in to a clinic or service. A user is expected to inform the organisation, doctor or sub-admin that they would like to have an outpatient or a tele consultation.
												</li>
												<li>
													Once a patient/client details has been added, they will receive an email and sms with a default password that can be changed once they log in their account.  Once they are logged in they will have an overview of which professionals are available andthe appointments then can be booked as per availability and clinical needs. They can use their web browser on their computer or mobile devices or simply login to the app on their device.
												</li>
											</ul>
										</div>
									</>
								}

								{
									patientValue === 'appointment-patient' &&
									<>
										<p className="font-18">This button gives you an overview or your past, current and upcoming clinical appointments. You can also see the health reports that have been generated in the previous consultation. Action button gives you a snapshot of the appointment including the details of the patient and practitioner.</p>
									</>
								}

								{
									patientValue === 'book-patient' &&
									<>
										<p className="font-18">Use this button to view the available physician/ clinicians, professionals available in the organisation. You can create an appointment by clicking ‘book now’ in the clinician’s profile and choosing the available slots as per your need and availability. You may be able to see 2 different types of slots which are broadly for face to face or tele consultation modes. After choosing the slot and clicking the proceed button, you will land on an electronic form which will ask you some basic information and the symptoms of your illness. You can provide more details by typing in why you need a doctor. Please note that this is a mandatory field.</p>

										<p className="font-18">
											In the middle of the page is an option for payment and there are 2 possibilities which is either for cash or online payment. For online appointment, the mode of payment is always electronic.
										</p>

										<p className="font-18">
											Use the area for upload your documents to scan all your previous health records. This enables doctors and professionals to be well informed about your health history and provide you with more refined and informed medical recommendations. If you are using mobile devices, the process is as simple as taking a picture with your camera.
										</p>

										<p className="font-18">
											At the end of page is proceed button which will direct you to payment terminal. Choose your desired payment option and once your payment has gone through, your profile will be forwarded to the clinician to accept. Once your appointment has been accepted, you will receive a SMS and an email of confirmation.
										</p>

									</>
								}


								{
									patientValue === 'message-patient' &&
									<>
										<p className="font-18">
											You can use this option to chat to your doctor and upload any extra reports or records that have been requested.
										</p>

									</>
								}


								{
									patientValue === 'health-patient' &&
									<>
										<p className="font-18">
											You can record your vital health parameters such as blood pressure, pulse, oxygen saturation, respiratory rate, blood sugar and temperature. Some other blood results can also be added such as lipid profile. In the section with symptoms, you can elaborate on your joint movement and pain scale.
										</p>

										<p className="font-18">
											Use the wellbeing section at the end to record your weight, BMI, waist circumference, exercise, nutrition and sleep parameters.
										</p>

										<p className="font-18">
											All these parameters will appear in a graphical format which will help you to identify certain key areas of your health that need some medical attention.
										</p>

									</>
								}

								{
									patientValue === 'medical-patient' &&
									<>
										<p className="font-18">
											This can be used to add your previous and ongoing diagnoses and the treatments that you are using. You can add both medical and surgical history in this section. You can also list the medications that you are currently prescribed and list your allergies.
										</p>

									</>
								}

								{
									patientValue === 'profile-patient' &&
									<>
										<p className="font-18">
											Use this button to edit your profile page and upload a recent picture. You can update your password and add any emergency contact details if needed.
										</p>
									</>
								}

								{
									patientValue === 'billing-patient' &&
									<>
										<p className="font-18">
											This button lists all the invoices you have received by the organisation involved in your care.
										</p>
									</>
								}

								{
									patientValue === 'logout-patient' &&
									<>
										<p className="font-18">
										</p>
									</>
								}


							</div>
						</div>
					</div>

				</div>
			</section>
		</StyledContainer>
	)
}

export default HowItWorks
