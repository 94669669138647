import { object, string, number } from "yup";

export const bodyTempSchema = object({
    temperature: number()
        .typeError("Body Temperature level must be a number")
        .required("Temperature is required")
        .when('temperature_unit', ([temperatureUnit]) => {
            const c = temperatureUnit === '1'
            const minMessage = `Temperature level in ${c ? 'celsius' : 'fahrenheit'} must be greater than or equal to ${c ? 35 : 96}`
            const maxMessage = `Temperature level in ${c ? 'celsius' : 'fahrenheit'} must be less than or equal to ${c ? 42 : 107.6}`
            const logic = (message, {value}) => {
                const isEnterF = c && value >= 96 && value <= 107.6
                const isEnterC = !c && value >= 36 && value <= 42
                const chooseScale = isEnterF ? 'Fahrenheit' : 'Celsius'
                const appendMessage = isEnterC || isEnterF ? `, if your entered value is in ${chooseScale} then please select scale as ${chooseScale}` : ''
                return message + appendMessage
            }
            return number()
                .min(c ? 35 : 96, logic.bind(this, minMessage))
                .max(c ? 42 : 107.6, logic.bind(this, maxMessage))
        }),
    temperature_unit: string().required("Temperature unit is required")
});