import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Line } from "react-chartjs-2";
import Pagination from '../../../components/Pagination';
import { Context } from '../../../store';
import {
	addBPAPI,
	getBPAPI,
	addHeightAPI,
	getHeightAPI,
	deleteBp,
} from '../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';
import Breadcrumb from '../../../components/Breadcrumb';
import CardComponent from '../../../components/Card';
import InputField from '../../../components/InputField';
import SelectField from '../../../components/SelectField';

import {
	StyledContainer,
	StyledBackSection,
	StyledBackTo
} from './styles';
import FilterByDate from '../../../components/DateFilter/FilterByDate';
import { menus1, menus2, menus3, menus4, menusLineChart, determineLabel, getDoubleLineChart, getSingleLineChart, onChangeDuration, getBmiTitle, getBmiClass, addData, renderData, tabName, deleteData } from './utils';
import InsideMenuBar from '../../../components/InsideMenus';

function HealthParameters(props) {
	const healthParameter = localStorage.getItem('Health_Parameter');
	const [state, dispatch] = React.useContext(Context);
	const [userOrg, setUserOrg] = React.useState(true);
	const [allData, setData] = React.useState([]);
	const [copyData, setCopyData] = React.useState([]);
	const [groupName, setGroupName] = React.useState(undefined);
	const [searchDate, setSearchDate] = React.useState('');
	const [lineData, setLineData] = React.useState({});
	const [formData, setFormData] = React.useState({});
	const [tabVariable, setTabVariable] = useState('')
	const [currentTitle, setCurrentTitle] = React.useState('Blood pressure');

	const [getBPData, setGetBPData] = React.useState(false);

	React.useEffect(() => {
		const user = JSON.parse(localStorage.getItem('customer'));
		if (user && user.org) { setUserOrg(true) }
		else { setUserOrg(false) }

		const script = document.createElement("script");

		script.src = "https://cdn.jsdelivr.net/npm/chart.js@2.9.4/dist/Chart.min.js";
		script.async = true;

		document.body.appendChild(script);

		document.body.className = "";

		getBP();

	}, [])

	const getBP = (healthType, value) => {
		!healthType && localStorage.setItem('Health_Parameter', 'all_Bp')
		const type = healthType ? healthType : 'all_Bp';
		let responseData = []
		loader(true);
		setGetBPData(false);

		if (type === 'bmiall') {
			getHeightAPI().then(res => {
				// toast.success(res.message);
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});

				if (res.data && res.data.length > 0) {
					let filteredData = [];
					if (value) {
						filteredData = res?.data.filter(item => moment(item.createdAt).diff(value, 'day') >= 0);
						setData(filteredData);
						setSearchDate(value);
						setCopyData(filteredData)
						setAllSearchData(filteredData.slice(0, 10))
					} else {
						setCopyData(res.data)
						setData(res.data);
						setPaginateData(res.data.slice(0, 10))
					}
					getSingleLineChart(filteredData.length ? filteredData : res.data, determineLabel(type), type);

					setGetBPData(true);
				}
				loader(false);
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
		else {
			getBPAPI(type).then(res => {

				// toast.success(res.message);
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});

				switch (type) {
					case 'all_Bp':
						responseData = res.data[0].bpDetails;
						break;
					default:
						responseData = res.data[0].Details;
						break;
				}

				if (responseData?.length) {
					let filteredData = [];
					if (value) {
						filteredData = responseData.filter(item => moment(item.createdAt).diff(value, 'day') >= 0);
						setData(filteredData);
						setSearchDate(value);
						setCopyData(filteredData)
						setAllSearchData(filteredData.slice(0, 10))
					} else {
						setCopyData(responseData)
						setData(responseData);
						setPaginateData(responseData.slice(0, 10))
					}
					type === 'all_Bp' ? getDoubleLineChart(filteredData.length ? filteredData : responseData, setLineData) : getSingleLineChart(filteredData.length ? filteredData : responseData, determineLabel(type), type);

					setGetBPData(true);
				}
				loader(false);
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
	}



	const clearSearch = () => {
		setSearchDate('');
		setGroupName('');
	}

	const getData = (name) => {
		localStorage.setItem('Health_Parameter', tabName(name))
		setTabVariable(tabName(name));
		clearSearch();
		setCurrentTitle(name);
	
		return getBP(tabName(name));
	}

	const addDetails = (data, type, resetData) => {
		loader(true);
		if (type === 'bmi-calculate') {
			addHeightAPI(data).then(res => {

				loader(false);
				// toast.success(res.message);
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});

				getBP(healthParameter);
				return resetData({});
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
			return;
		} else {
			addBPAPI(data, type).then(res => {

				loader(false);
				// toast.success(res.message);
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				getBP(healthParameter);
				return resetData({});
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		}
	}

	const deleteItem = (id, name) => {
		loader(true);
		deleteBp(id, name).then(res => {
			loader(false);
			// toast.success(res.message);
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
			getBP(healthParameter);
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}


	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const validationInputFeild = (e, type) => {
		if (type === 'oxygen') {
			if (e.target.value <= 100) {
				setFormData((user) => ({ ...user, level: e.target.value }))
			} else {
				toast.error("% has to be under 100")
				dispatch({
					type: SET_ERROR,
					payload: "% has to be under 100"
				});
			}
		} else if (type === 'pulse') {
			if (e.target.value <= 300) {
				setFormData((user) => ({ ...user, pluse: e.target.value }))
			} else {
				toast.error("Pluse has to be maximum 300")
				dispatch({
					type: SET_ERROR,
					payload: "Pluse has to be maximum 300"
				});
			}
		} else if (type === 'systolic') {
			if (e.target.value <= 250) {
				if (formData.dia_blood_pressure < formData.sys_blood_pressure) {
					setFormData((user) => ({ ...user, sys_blood_pressure: e.target.value }))
				} else {
					setFormData((user) => ({ ...user, sys_blood_pressure: e.target.value }))
					toast.error("Systolic can not be lower than diastolic!")
					dispatch({
						type: SET_ERROR,
						payload: "Systolic can not be lower than diastolic!"
					});
				}
			} else {
				toast.error("Systolic has to be maximum 250")
				dispatch({
					type: SET_ERROR,
					payload: "Systolic has to be maximum 250"
				});
			}
		} else if (type === 'diastolic') {
			if (e.target.value <= 130) {
				setFormData((user) => ({ ...user, dia_blood_pressure: e.target.value }))
			} else {
				toast.error("Diastolic has to be maximum 130")
				dispatch({
					type: SET_ERROR,
					payload: "Diastolic has to be maximum 130"
				});
			}
		} else if (type ==='respiratory') {
			if (e.target.value <= 25) {
				setFormData((user) => ({ ...user, respiratory_rates: e.target.value }))
			} else {
				toast.error("Respiratory Rate (breaths/min) has to be maximum 25")
				dispatch({
					type: SET_ERROR,
					payload: "Respiratory Rate (breaths/min) has to be maximum 25"
				});
			}
		} else if (type === 'temperature') {
			if (e.target.value <= 41) {
				setFormData((user) => ({ ...user, temperature: e.target.value }))
			} else {
				toast.error("Temperature has to be maximum 41 degree")
				dispatch({
					type: SET_ERROR,
					payload: "Temperature has to be maximum 41 degree"
				});
			}
		}
	}

	const [paginateData, setPaginateData] = React.useState([]);
	const [allSearchData, setAllSearchData] = React.useState([]);

	const pageChangeBP = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setPaginateData(data)
	}

	const onChangeDate = async (value) => {
		setSearchDate(value)
		const sortData = copyData.filter((item) => moment(item.createdAt).format('YYYY-MM-DD') === value);
		setAllSearchData(sortData?.length > 10 ? sortData.slice(0, 10) : sortData);
		setData(sortData);
		if (healthParameter === 'all_Bp') {
			getDoubleLineChart(sortData, setLineData)
		} else getSingleLineChart(sortData, determineLabel(healthParameter), healthParameter)
	}

	const returnData = (value) => {
		let filteredData = [];
		filteredData = copyData.filter(item => moment(item.createdAt).diff(value, 'day') >= 0);
		setData(filteredData);
		setSearchDate(value);
		setAllSearchData(filteredData?.length > 10 ? filteredData.slice(0, 10) : filteredData)
		if (healthParameter === 'all_Bp') {
			getDoubleLineChart(filteredData, setLineData)
		} else getSingleLineChart(filteredData, determineLabel(healthParameter), healthParameter)
	}

	const onReset = () => {
		setSearchDate('');
		getBP(healthParameter);
		setGroupName('');
	}

	const renderStatus = (title) => {
		switch (title) {
			case 'Pulse':
				return ' per min '
			case 'Blood sugar':
				return ' mg/dl'
			case 'Temperature':
				return ' °C'
			case 'Respiratory rate':
				return ' per min '
			case 'Oxygen Saturation':
				return '%'
			default:
				break;
		}
	}
	const renderInfo = renderData(currentTitle); 
	return (
		<StyledContainer>
			{
				userOrg &&
				<>
					<InsideMenuBar
						props={props}
						type={'patient'}
					/>
				</>
			}

			{
				!userOrg &&
				<>
					<Breadcrumb
						backRoute={[{ id: 1, route: 'Dashboard', path: '/patient/medical-record' }]}
						currentRoute='Health parameters'
						title='All trackers' />

					<StyledBackSection>
						<StyledBackTo to={'/patient/medical-record'}>
							<i className="fa fa-arrow-left" />
							Back To Dashboard
						</StyledBackTo>
					</StyledBackSection>
				</>
			}

			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
							<div className="profile-sidebar">

								<div className="dashboard-widget">
									<nav className="dashboard-menu">
										<h4 className="ml-3 mt-3">Vital signs</h4>
										<ul>
											{
												menus1.map(item => (
													<li key={item.id} className={item.name === currentTitle ? 'active' : ''}>
														<a onClick={() => { getData(item.name) }}>
															<span>{item.name}</span>
														</a>
													</li>
												))
											}
										</ul>

										<h4 className="ml-3 mt-3">Lipids</h4>
										<ul>
											{
												menus2.map(item => (
													<li key={item.id} className={item.name === currentTitle ? 'active' : ''}>
														<a onClick={() => getData(item.name)}>
															<span>{item.name}</span>
														</a>
													</li>
												))
											}
										</ul>

										<h4 className="ml-3 mt-3">Symptoms</h4>
										<ul>
											{
												menus3.map(item => (
													<li key={item.id} className={item.name === currentTitle ? 'active' : ''}>
														<a onClick={() => getData(item.name)}>
															<span>{item.name}</span>
														</a>
													</li>
												))
											}
										</ul>

										<h4 className="ml-3 mt-3">Fitness and well-being</h4>
										<ul>
											{
												menus4.map(item => (
													<li key={item.id} className={item.name === currentTitle ? 'active' : ''}>
														<a onClick={() => { getData(item.name) }}>
															<span>{item.name}</span>
														</a>
													</li>
												))
											}
										</ul>

									</nav>
								</div>
							</div>
						</div>

						<div className="col-md-7 col-lg-8 col-xl-9">
							<h2>{currentTitle}</h2>
									<CardComponent className="p-4 mb-4">
										<div className="row mb-4">
										{renderInfo.inputFields.map((item)=> 
											<div className="col-md-6 no-padding form-group ">
												<label>{item.label}</label>
												{item.selectType ?
													<SelectField name={item.name} value={formData[item.name]} onChange={e => setFormData((user) => ({ ...user, [item.name]: e.target.value }))}  options={item.options}/>
													:<InputField name={item.name} value={formData[item.name]} onChange={(e) => item.validation ? validationInputFeild(e, item.validation) : setFormData((user) => ({ ...user, [item.name]: e.target.value }))} />
												}
											</div>
										)}

											<div className="col-md-12 no-padding form-group text-center">
												<div className="btn btn-primary submit-btn p-3" onClick={(e) => addData(currentTitle, formData, setFormData, addDetails, clearSearch)}>Add</div>
											</div>

											<hr />
											<div className='col-md-12'>
												{renderInfo.showChart ?
													renderInfo.isDoubleLineChart ?
														getBPData ?
															<Line data={lineData} />
														: null
														:
														<div id='myDiv' class="chart-container" style={{ position: 'relative', width: '100%' }}>
															<canvas id={tabVariable}></canvas>
														</div>
													: renderInfo.showImage ?
														<div className="col-md-12">
															<img src={renderInfo.url} />
														</div>
													: null
												}
											{renderInfo.showChart ?
												<div className='col-md-7 col-lg-8 col-xl-8 text-center d-flex mt-4'>
													{menusLineChart.map((item) =>
														<div
															className={`line-chart-buttons btn btn-primary submit-btn ml-3 pt-2 text-center ${item.id === groupName && 'selected-group'}`}
															onClick={() => {
																setGroupName(item.id)
																onChangeDuration(item.key, returnData)
															}}
														>
															{item.value}
														</div>
													)}
													<FilterByDate
														onChangeDate={onChangeDate}
														searchDate={searchDate}
														setSearchDate={setSearchDate}
														onReset={onReset}
													/>
												</div>
												: null}
											</div>
										</div>

										<div className="row mt-4">
											<div className="col-md-12 mt-4 mb-4">{allData.length > 0 && <h2>{currentTitle} readings</h2>}</div>
											<>
												{searchDate ?
													allSearchData?.length ?
														allSearchData.map(item => {
															return <div className={currentTitle !== 'BMI'? "col-md-6 col-xs-12 postion-relative":`p-4 mb-4 color-white bmi-cal ${getBmiClass(item)}` }>
																<CardComponent className="p-4 mb-4">
																{renderInfo.showData.map((data)=> 
																	<h5>{currentTitle !== 'BMI'? data.label : `${data.label} ${getBmiTitle(item)}`} {data.label === 'Date:'? moment(item[data.valueTobeDisplayed]).format('DD/MM/YYYY, hh:mm a') :item[data.valueTobeDisplayed]}</h5>
																)}
																</CardComponent>
																<button className="remove-heath" onClick={() => deleteData(item._id, currentTitle, deleteItem)}><i className="fas fa-times"></i></button>
															</div>
														})
														: <div className='col-md-12'><h2 className='text-center'>No Records Found</h2></div>
													:
													paginateData.map(item => {
														return <div className="col-md-6 col-xs-12 postion-relative">
															<CardComponent className="p-4 mb-4">
															{renderInfo.showData.map((data)=> 
																	<h5>{data.label} {data.label === 'Date:'? moment(item[data.valueTobeDisplayed]).format('DD/MM/YYYY, hh:mm a') :item[data.valueTobeDisplayed]}{ data.label == 'Heart rate:' ||  data.label == 'Level:' || data.label == 'Blood sugar:' || data.label == 'Temperature:' || data.label == 'Respiratory Rate:' ? renderStatus(renderInfo.title) : ''}</h5>
																)}
															</CardComponent>
															<button className="remove-heath" onClick={() => deleteData(item._id, currentTitle)}><i className="fas fa-times"></i></button>
														</div>
													})

												}
											</>

										</div>

										{allData?.length > 10 ? <Pagination items={allData} onChangePage={pageChangeBP} /> : null}
									</CardComponent>
						</div>
					</div>
				</div>
			</div>

		</StyledContainer>
	)
}

export default HealthParameters
