import React, { useContext, useEffect } from 'react';

import { toast } from 'react-toastify';
import { Context } from '../../../../../store';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';


import PhoneInput from "react-phone-number-input";

import { 
	bookAppointmentForPatientAPI,
	getDoctorSlotsListingAPI,
	getAllAppointmentAPI,
	getDoctorInfoAPI,
	getAllPaymentMethod
 } from '../../../../../api/doctor';

import Slider from "react-slick";

import InputField from '../../../../../components/InputField';
import DoctorSidebar from '../../../components/Sidebar';
import CardComponent from '../../../../../components/Card';
import SelectField from '../../../../../components/SelectField';
import Select from "react-select";
import Files from 'react-files';
import { globalValidator, getUser } from "../../../../../utils";

import moment from 'moment';

import '../../../components/doctor.css';
import { symptomsArr } from '../../../../../constants';
import InsideMenuBar from '../../../../../components/InsideMenus';

function BookAppointmentForPatient(props) {
	const [state, dispatch] = useContext(Context);
	let fileRef = React.useRef(null);
	const [id, setId] = React.useState(null);
	const [slots, setSlots] = React.useState([]);
	const [selectedSlot, setSelectedSlot] = React.useState({_id: null,slot: null, date: null});
	const [filesErrorMsg, setFilesErrorMsg] = React.useState('');

	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3
	};

	useEffect(() => {
		if(props.match.params && props.match.params.id) { 
			setId(props.match.params.id);
			loader(true);
			getAllAppointmentAPI(props.match.params.id).then(res => {
				if(res.data && res.data.length > 0) {
					if(props.match.path == "/doctor/book-new-appointment-form/:id") {
						SetPatientInfo({
							patientName: res.data[0].SecondOpinionId.patientName,
							patientAge: res.data[0].SecondOpinionId.patientAge,
							patientWeight: res.data[0].SecondOpinionId.patientWeight,
							why_do_doctor: '',
							symptoms: [],
							patientGender: res.data[0].SecondOpinionId.patientGender,
							uploadsDocuments: [],
							hypertension: false,
							diabetes: false,
							appointmentType: 1,
							patientId: res.data[0].SecondOpinionId.patientId
						})
					}

					if(props.match.path == "/doctor/re-book-appointment-form/:id/:apptId") {
						const updatedData = res.data.filter(item => props.match.params.apptId == item._id);
						SetPatientInfo({
							patientName: updatedData[0].SecondOpinionId.patientName,
							patientAge: updatedData[0].SecondOpinionId.patientAge,
							patientWeight: updatedData[0].SecondOpinionId.patientWeight,
							why_do_doctor: updatedData[0].SecondOpinionId.why_do_doctor,
							symptoms: updatedData[0].SecondOpinionId.symptoms.map(symptom => {
								return {
									label: symptom,
									value: symptom
								};
							}),
							patientGender: updatedData[0].SecondOpinionId.patientGender,
							uploadsDocuments: [],
							hypertension: updatedData[0].SecondOpinionId.hypertension,
							diabetes: updatedData[0].SecondOpinionId.diabetes,
							appointmentType: 1,
							patientId: updatedData[0].SecondOpinionId.patientId
						})
					}
					
				}
				loader(false);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		}
	}, [])

	React.useEffect(() => {
		const id = getUser()._id;
		setId(id);
		getDoctor();
		getDoctorSlotsListingAPI(id).then(res => {
			var slotMap = {};
				for(let slot of res.data){
						const date = moment(slot.start).utc().format('DD/MM/YYYY');
						if(moment(slot.start).utc().format() > moment().format()) {
							if(slotMap[date]) {
									slotMap[date].push(slot);
							}
							else {
									slotMap[date] = [slot];
							}
						}
						
				}

				var result = Object.keys(slotMap).map(slotDate => {
						return {
								date: slotDate,
								slots: slotMap[slotDate]
						}
				});
				
			setSlots(result);
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}, []);

	const onFilesChange = (files) => {
		fileRef.state.files= '';
		const fileSize = files.filter((item) => item.size > 5242880)
		const filesFilter = files.map((item) => item.type)
		const acceptsTypes = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
		const filesCheckType = filesFilter;
		// eslint-disable-next-line no-extend-native
		Array.prototype.gb_inArray = function(filesCheckType) {return filesCheckType.every(i => this.includes(i))};
		const fileTypeCheck = acceptsTypes.gb_inArray(filesCheckType);

		if(files?.length > 5){
			setFilesErrorMsg('Maximum 5 files are allowed!')
			fileRef.state.files= '';
			return
		}
		
		if(!fileTypeCheck){
			setFilesErrorMsg('PDF or Image should be accepted!');
			fileRef.state.files= '';
			return 
		}

		if(fileSize?.length){
			setFilesErrorMsg('Please upload a file smaller than 5 MB')
			fileRef.state.files= '';
			return 
		}

		setFilesErrorMsg('')
		fileRef.state.files= '';
		SetPatientInfo((info) => ({
			...info,
			uploadsDocuments: files
		}))
	}
	const filesRemoveOne = (file) => {
		const files = patientInfo.uploadsDocuments.filter((item) => item.id!==file.id)
		fileRef.state.files=files
		SetPatientInfo((info) => ({
			...info,
			uploadsDocuments: files
		}))
	  }
 
	const onFilesError = (error, file) => {
		// toast.error(error.message)
		// dispatch({
		// 	type: SET_ERROR,
		// 	payload: error.message
		// });
	}

	const handleChangeSymptoms = (e) => {
		SetPatientInfo(info => ({...info, symptoms: e}))
	}
	
	const [patientInfo, SetPatientInfo] = React.useState({
		patientId: '',
		patientName: '',
		patientAge: '',
		patientWeight: '',
		why_do_doctor: '',
		symptoms: [],
		patientGender: '',
		uploadsDocuments: [],
		hypertension: false,
		diabetes: false,
		appointmentType: 1,
		amount: '',
		pendingAmount: 0
	});

	const submitForm = () => {
		const keys = [
			{ name: "patientName", title: "Name", validateKey: "required" },
			{ name: "patientAge", title: "Age", validateKey: "required" },
			{ name: "patientWeight", title: "Weight", validateKey: "required" },
			{ name: "symptoms", title: "Symptoms", validateKey: "required" },
			{ name: "why_do_doctor", title: "Why do you need doctor", validateKey: "required" },
			{ name: "amount", title: "Appointment amount", validateKey: "required" }
    ];
		let valid = globalValidator(patientInfo, keys);

		if(valid === true) {
			const formData = new FormData();
			Object.keys(patientInfo).forEach(key => {
				if (key === "uploadsDocuments") {
					patientInfo[key].map(doc => {
						formData.append("uploadsDocuments", doc, doc.name);
					});
				} else if(key === "symptoms") {
					const symptoms = patientInfo.symptoms.map(item => {
						return item.value;
					});
					formData.append(key, symptoms);
				} else if(key === "appointmentType") {
					formData.append(key, 1);
				} else if (key === 'amount') {
					formData.append(key, curreny+patientInfo[key]);
				}  else {
					formData.append(key, patientInfo[key]);
				}
			});

			
			if (selectedSlot.slot) {
				formData.append("slotId", selectedSlot.slot._id);
			}

			if(patientInfo.appointmentType === 0) {
				formData.append("paymentMode", paymentType);
			}

			if (slots.length === 0) {
				toast.error('Please create slot first!')
				return dispatch({
					type: SET_ERROR,
					payload: 'Please create slot first!'
				});
			}
	
			if (!selectedSlot?._id) {
				toast.error('Please select slot!')
				return dispatch({
					type: SET_ERROR,
					payload: 'Please select slot!'
				});
			}

			// formData.append("patientId", id);

			const doctorId = getUser()._id;
			loader(true);
			bookAppointmentForPatientAPI(doctorId, formData).then(res => {
				loader(false);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				props.history.push('/doctor/patient-listing');
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})


		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const [curreny, setCurreny] = React.useState('');

	const getDoctor = () => {
		getDoctorInfoAPI().then(res => {
			if(res.data && res.data.curreny) {
				setCurreny(res.data.curreny)
			}
		}).catch(err => {
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const setSlot = (value) => {
		setSelectedSlot({_id: value.slot._id, slot: value.slot, date: value.date});
	}

	const [paymentMethods, setPaymentMethods] = React.useState([]);

	React.useEffect(() => {
		getAllPaymentMethod().then(res => {
			setPaymentMethods(res.data);
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}, [])

	const [paymentType, setPaymentType] = React.useState(null);

	const onChangePaymentMethod = (value) => {
		setPaymentType(value.label);
	}

	return (
		<div>
			<InsideMenuBar
				props={props}
				type={'doctor'}
			/>
			<div className="content doct-panel-em">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<h2>Book appointment for patient</h2>
								<hr />
									<div className="row pt-2">
										<div className="p-4 col-md-3 form-group ">
											<label>Patient name <small style={{color: 'red'}}>*</small></label>
											<InputField value={patientInfo.patientName} onChange={e => SetPatientInfo((user) => ({...user, patientName: e.target.value}))}  />
										</div>
										<div className="p-4 col-md-3 form-group ">
											<label>Patient age <small style={{color: 'red'}}>*</small></label>
											<InputField value={patientInfo.patientAge} onChange={e => SetPatientInfo((user) => ({...user, patientAge: e.target.value}))}  />
										</div>
										<div className="p-4 col-md-3 form-group ">
											<label>Weight (in kgs) <small style={{color: 'red'}}>*</small></label>
											<InputField value={patientInfo.patientWeight} onChange={e => SetPatientInfo((user) => ({...user, patientWeight: e.target.value}))}  />
										</div>
										<div className="p-4 col-md-3 form-group ">
											<label>Gender <small style={{color: 'red'}}>*</small></label>
											<SelectField onChange={e => SetPatientInfo((user) => ({...user, patientGender: e.target.value}))} value={patientInfo.patientGender}  options={[{label: 'Select gender'}, {label: 'Male', value: 'male'}, {label: 'Female', value: 'female'}, {label: 'Other', value: 'other'}]}  />
										</div>
									</div>

									<div className="row pl-2 pr-2">
										<div className="col-md-12 form-group">
											<label>Symptoms <small style={{color: 'red'}}>*</small></label>
											<Select
												type="select"
												name="symptoms"
												isMulti
												closeMenuOnSelect={false}
												value={patientInfo.symptoms}
												onChange={(e) => handleChangeSymptoms(e)}
												options={symptomsArr.map(symptom => {
													return {
														label: symptom.label,
														value: symptom.label,
														isDisabled: symptom.isDisabled
													};
												})}
												isOptionDisabled={(option) => option.isDisabled}
											/>
										</div>
									</div>

									<div className="row pl-2 pr-2">
										<div className="col-md-12 form-group">
											<label>Why do you need doctor? <small style={{color: 'red'}}>*</small></label>
											<InputField value={patientInfo.why_do_doctor} onChange={e => SetPatientInfo((user) => ({...user, why_do_doctor: e.target.value}))}  />
										</div>
									</div>

									<div className="row pl-2 pr-2">
										<div className="col-md-12 form-group">
											<div className="row">	
												<div className="col-md-4 form-group">
													<label>Hypertension</label>
													<div className="d-flex">
														{/* <label class="container-doc-em">Yes
															<input type="radio" name="radio" value={patientInfo.hypertension} onChange={e => SetPatientInfo((user) => ({...user, hypertension: true}))} />
															<span class="checkmark"></span>
														</label>
														<label class="container-doc-em">No
															<input type="radio" checked="checked" name="radio" value={patientInfo.hypertension} onChange={e => SetPatientInfo((user) => ({...user, hypertension: false}))} />
															<span class="checkmark"></span>
														</label> */}

														<div class="btn-group me-2" role="group" aria-label="First group">
															<button type="button" className={patientInfo.hypertension ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({...user, hypertension: true}))}>Yes</button>
															<button type="button" className={!patientInfo.hypertension ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({...user, hypertension: false}))}>No</button>
														</div>
													
													</div>
												</div>

												<div className="col-md-4 form-group">
													<label>Diabetes</label>
													<div className="d-flex">
														{/* <label class="container-doc-em">Yes
															<input type="radio" name="radio1" value={patientInfo.diabetes} onChange={e => SetPatientInfo((user) => ({...user, diabetes: true}))} />
															<span class="checkmark"></span>
														</label>
														<label class="container-doc-em">No
															<input type="radio" checked="checked" name="radio1" value={patientInfo.diabetes} onChange={e => SetPatientInfo((user) => ({...user, diabetes: false}))} />
															<span class="checkmark"></span>
														</label> */}

														<div class="btn-group me-2" role="group" aria-label="First group">
															<button type="button" className={patientInfo.diabetes ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({...user, diabetes: true}))}>Yes</button>
															<button type="button" className={!patientInfo.diabetes ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({...user, diabetes: false}))}>No</button>
														</div>
													
													</div>
												</div>


												<div className="col-md-4 form-group">
													<label>Payment method</label>
													<div className="d-flex">

														<div class="btn-group me-2" role="group" aria-label="First group">
															<button type="button" className={patientInfo.appointmentType === 0 ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({...user, appointmentType: 0}))}>Online</button>
															<button type="button" className={patientInfo.appointmentType === 1 ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({...user, appointmentType: 1}))}>In cash</button>
														</div>
														
													</div>
												</div>

											</div>
										</div>

										<div className="p-4 col-md-12 form-group ">
											<div className="row">
												<div className="col-md-6 form-group">
												<label style={{color: 'red'}}>Appointment fee<small style={{color: 'red'}}>*</small></label>
												<InputField value={patientInfo.amount} onChange={e => SetPatientInfo((user) => ({...user, amount: e.target.value}))}  />
												</div>

												<div className="col-md-6 form-group">
													<label style={{color: 'red'}}>Pending amount <small style={{color: 'red'}}>(If any amount pending)</small></label>
												<InputField value={patientInfo.pendingAmount} onChange={e => SetPatientInfo((user) => ({...user, pendingAmount: e.target.value}))}  />
												</div>
											</div>
											
										</div>

									</div>

									{
										patientInfo.appointmentType === 0 && 
											<div className="row pl-2 pr-2">
												<div className="col-md-12 form-group">
													<label>Payment method <small style={{color: 'red'}}>*</small></label>
													<Select
														type="select"
														name="symptoms"
														isMulti={false}
														onChange={(e) => onChangePaymentMethod(e)}
														options={paymentMethods.map(paymentMethod => {
															return {
																label: paymentMethod.type,
																value: paymentMethod.code
															};
														})}
													/>
												</div>
											</div>
									}

									<div className="row pl-2 pr-2">
										<div className="col-md-12 form-group">
											<label>Upload documents <small>(You can upload 5 files)</small></label>
											<Files
												ref={(fileItem) => fileRef = fileItem}
												className='files-dropzone'
												onChange={onFilesChange}
												onError={onFilesError}
												// accepts={['image/*', '.pdf', 'audio/*']}
												multiple
												// maxFiles={5}
												// maxFileSize={10000000}
												minFileSize={0}
												clickable
											>
													<div>Drop files here or click to upload</div>

											</Files>
											{filesErrorMsg ? <p style={{color: 'red'}}>{filesErrorMsg}</p> : ''}
										{
											patientInfo.uploadsDocuments && patientInfo.uploadsDocuments.length > 0
												?
												<div className="wardRoomList d-flex flex-wrap mt-2">
													{patientInfo.uploadsDocuments.map((file) =>

														<div className="col-md-2 col room-view mt-2">
															<div>
																<img src="/assets/img/file-uploaded.png" />
																<div
																	id={file.id}
																	className='files-list-item-remove'
																	onClick={() => filesRemoveOne(file)} // eslint-disable-line
																/>
															</div>
														</div>


													)}
												</div>
												: null
										}
										</div>
									</div>

								<div className="row">
									{
										slots.length === 0 && (
											<h5>There is no slot available</h5>
										)
									}
								</div>

								<hr />
									<div className="row mb-3">
										<div className="col-md-4">Online appointment -  <b style={{color: '#2c7fb7', paddingLeft: '10px'}}>Blue color</b></div>
										<div className="col-md-4">In clinic appointment -  <b style={{color: '#56be73', paddingLeft: '10px'}}>Green colors</b></div>
										<div className="col-md-4">Selected slot -  <b style={{color: 'red', paddingLeft: '10px'}}>Red colors</b></div>
									</div>
								<hr />

								<Slider {...settings}>
									{
										slots && slots.map((item, i) => {
											return <div className="day-slot">
															<ul>
																<li key={i} className="li-doc">
																	<span>{item.date}</span>
																	<ul className="doc-slot">
																		{
																			item.slots && item.slots.map((slot, index) => {
																				return 	<li key={index}><a className={selectedSlot._id === slot._id && selectedSlot.date === item.date ? 'selected-slot' : (
																					slot.type === 1 ? 'slot-online' : 'slot-offline'
																				)} onClick={() => setSlot({slot: slot, date: item.date})}>{moment(slot.start).utc().format('hh:mm a')} - {moment(slot.end).utc().format('hh:mm a')}</a></li>
																			})
																		}
																	</ul>
																</li>
															</ul>
														 </div>
										})
									}
								</Slider>

									<div className="row text-center" style={{justifyContent: 'center'}}>
										<button className="doctor-active-btn" onClick={() => submitForm()}>Submit form</button>
									</div>
								</CardComponent>
								
						</div>

					</div>
				</div>
			</div>
		</div>
	)
}

export default BookAppointmentForPatient
