import React from 'react';
import CardComponent from '../../../../../../components/Card';
import moment from 'moment';

function PatientInfoModal(props) {
    const {appointment,patientDetails } = props;
    return (
        <div className="col-md-12 p-4 mt-1" style={{maxHeight:"650px", overflow:"scroll"}}>
            {/* <StyledContainer> */}
                <div>
                    <h4 className="card-title">Patient info</h4>
                </div>

                <div className="card-body">
                    <div className="booking-summary" style={{ display: 'flex' }}>
                        {/* <div className="booking-item-wrap col-md-6">
								<ul className="booking-date">
									<li>Name<span>{patientData.name ? patientData.name : '-'}</span></li>
									<li>Email<span>{patientData.email ? patientData.email : '-'}</span></li>
									<li>Contact number<span>{patientData.phoneNumber ? patientData.phoneNumber : '-'}</span></li>
									<li>Gender <span>{patientData.gender ? patientData.gender : '-'}</span></li>
									<li>DOB<span>{patientData.dob ? moment(patientData.dob).format('Do MMMM YYYY') : '-'}</span></li>
									<li>Address<span>{patientData.address ? patientData.address : '-'}</span></li>
									<li>BloodGroup<span>{patientData.bloodGroup ? patientData.bloodGroup : '-'}</span></li>
								</ul>
								
							</div> */}
                        <div className="booking-item-wrap col-md-6">
                            <ul className="booking-fee">
                                <li>Admission Date<span>{patientDetails.admissionDateTime ? moment(patientDetails.admissionDateTime).format('Do MMMM YYYY') : '-'}</span></li>
                                <li>Room No<span>{patientDetails.roomWard ? patientDetails.roomWard.roomNo : '-'}</span></li>
                                <li>Room Type<span>{patientDetails.roomWard ? patientDetails.roomWard.roomType : '-'}</span></li>
                                <li>Admission Under Consultant<span>{patientDetails.doctor ? patientDetails.doctor.name : '-'}</span></li>
                                <li>Reason For Admission<span>{patientDetails.reason ? patientDetails.reason : '-'}</span></li>
                                <li>Referernce Name<span>{patientDetails.referernceName ? patientDetails.referernceName : '-'}</span></li>
                                <li>Referernce Contact Number<span>{patientDetails.referernceContactNo ? patientDetails.referernceContactNo : '-'}</span></li>
                                <li>MLC No.<span>{patientDetails.mlcNo ? patientDetails.mlcNo : '-'}</span></li>
                                <li>FIR No.<span>{patientDetails.firNo ? patientDetails.firNo : '-'}</span></li>
                            </ul>
                        </div>
                        <hr />
                    </div>
                </div>
                <hr />
                <div>
                    <h4 className="card-title">Patient Details</h4>
                </div>
                <div className="card-body">
                    <div className="booking-summary" style={{ display: 'flex' }}>
                        <div className="booking-item-wrap col-md-6">
                            <ul className="booking-fee">
                                <li>Provisional Diagnosis<span>{patientDetails.diagnosis ? patientDetails.diagnosis : '-'}</span></li>
                                <li>BPDiastolic<span>{patientDetails.BPDiastolic ? patientDetails.BPDiastolic : '-'}</span></li>
                                <li>BPSystolic<span>{patientDetails.BPSystolic ? patientDetails.BPSystolic : '-'}</span></li>
                                <li>Temp (f)<span>{patientDetails.temp ? patientDetails.temp : '-'}</span></li>
                            </ul>
                        </div>
                        <div className="booking-item-wrap col-md-6">
                            <ul className="booking-fee">
                                <li>SPO2 (%)<span>{patientDetails.spo2 ? patientDetails.spo2 : '-'}</span></li>
                                <li>Pulse (/min)<span>{patientDetails.pulse ? patientDetails.pulse : '-'}</span></li>
                                <li>RR (/min)<span>{patientDetails.rr ? patientDetails.rr : '-'}</span></li>
                                <li>Presenting Illness Summary<span>{patientDetails.illessSumary ? patientDetails.illessSumary : '-'}</span></li>

                            </ul>
                        </div>

                    </div>
                    <div className="booking-item-wrap col-md-12">
                        <ul className="booking-fee">
                            <li>Chief Complaints And Duration
                                <table class="table table-bordered mt-2 ml-4">
                                    <thead>
                                        <tr>
                                            <th scope="col">Complaints</th>
                                            <th scope="col">Duration</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            patientDetails.chiefComplaints && patientDetails.chiefComplaints.map((data, id) =>
                                                <tr key={id}>
                                                    <td>{data.complaints ? data.complaints : '-'}</td>
                                                    <td>{data.duration ? data.duration : '-'}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </li>
                            <li>General Appearance
                                <table class="table table-bordered mt-2 ml-4">
                                    <thead>
                                        <tr>
                                            <th scope="col">General Appearance</th>
                                            <th scope="col">Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            patientDetails.generalAppearce && patientDetails.generalAppearce.map((data, id) =>
                                                <tr key={id}>
                                                    <td>{data.general ? data.general : '-'}</td>
                                                    <td>{data.comments ? data.comments : '-'}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr />
                <div>
                    <h4 className="card-title">Patient History</h4>
                </div>
                <div className="card-body">
                    <div className="booking-summary" style={{ display: 'flex' }}>
                        <div className="booking-item-wrap col-md-12">
                            <ul className="booking-fee">

                                <li>
                                    <table class="table table-bordered mt-2">
                                        <thead>
                                            <tr>
                                                <th scope="col">Signification Past Medical and Surgical History</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                patientDetails.signification && patientDetails.signification.map((data, id) =>
                                                    <tr key={id}>
                                                        <td>{data ? data : '-'}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </li>
                                <li>
                                    <table class="table table-bordered mt-2">
                                        <thead>
                                            <tr>
                                                <th scope="col">Family History</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                patientDetails.familyHistory && patientDetails.familyHistory.map((data, id) =>
                                                    <tr key={id}>
                                                        <td>{data ? data : '-'}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </li>
                                <li>
                                    <table class="table table-bordered mt-2">
                                        <thead>
                                            <tr>
                                                <th scope="col">History of alcohalism tabacco or substance abuse</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                patientDetails.historyOfAlcohalism && patientDetails.historyOfAlcohalism.map((data, id) =>
                                                    <tr key={id}>
                                                        <td>{data ? data : '-'}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            {
                appointment === 4 &&
                <div className="col-md-12">
                    <CardComponent className="p-4 mt-4">
                        <div className="">
                            <h4 className="card-title">Final report</h4>
                        </div>

                        <div className="card-body row">
                            <div className="mb-2 col-md-12">
                                <h4 style={{ color: '#60c27b' }}>Diagnosis and observation:</h4>
                                <p style={{ paddingLeft: '10px' }}>- {props.appointment.finalReport.impression_diagnosis}</p>
                            </div>

                            {/* <div className="mb-2 col-md-12">
									<h4 style={{color: '#60c27b'}}>Medication name:</h4>
									<p style={{paddingLeft:'10px'}}>- {props.appointment.finalReport.medication_name}</p>
								</div> */}

                            {
                                props.appointment.finalReport.medication && props.appointment.finalReport.medication.map((item, index) => {
                                    return <CardComponent className='row mb-2 mt-2 p-2 pl-4 p-relative' key={index}>
                                        <p style={{ position: 'absolute', background: '#56be75', color: '#fff', padding: '5px', borderRadius: '6px', left: '8px' }}>{index + 1} .</p>
                                        <div className="mb-2 col-md-12">
                                            <h4 style={{ color: '#60c27b' }}>Medication name:</h4>
                                            <p style={{ paddingLeft: '10px' }}>- {item.medication_name}</p>
                                        </div>
                                        <div className="mb-2 col-md-3">
                                            <h4 style={{ color: '#60c27b' }}>Form of medicine:</h4>
                                            <p style={{ paddingLeft: '10px' }}>- {item.medicine_type}</p>
                                        </div>

                                        <div className="mb-2 col-md-3">
                                            <h4 style={{ color: '#60c27b' }}>Strength:</h4>
                                            <p style={{ paddingLeft: '10px' }}>- {item.strength_name}</p>
                                        </div>

                                        <div className="mb-2 col-md-3">
                                            <h4 style={{ color: '#60c27b' }}>Strength type:</h4>
                                            <p style={{ paddingLeft: '10px' }}>- {item.strength_type}</p>
                                        </div>


                                        <div className="mb-2 col-md-3">
                                            <h4 style={{ color: '#60c27b' }}>Dosage Quantity intake:</h4>
                                            <p style={{ paddingLeft: '10px' }}>- {item.dos_quantity_name}</p>
                                        </div>
                                    </CardComponent>
                                })
                            }



                            {/* <div className="mb-2">
									<h4 style={{color: '#60c27b'}}>When:</h4>
									<p style={{paddingLeft:'10px'}}>- {props.appointment.finalReport.dos_quantity_when}</p>
								</div> */}

                            <div className="mb-2">
                                <h4 style={{ color: '#60c27b' }}>Other Instructions:</h4>
                                <p style={{ paddingLeft: '10px' }}>- {props.appointment.finalReport.notes}</p>
                            </div>

                            {/* <div className="mb-2">
									<h4 style={{color: '#60c27b'}}>Duration start on:</h4>
									<p style={{paddingLeft:'10px'}}>- {moment(props.appointment.finalReport.duration_start).format('DD/MM/YYYY')}</p>
								</div>

								<div className="mb-2">
									<h4 style={{color: '#60c27b'}}>Duration till:</h4>
									<p style={{paddingLeft:'10px'}}>- {moment(props.appointment.finalReport.duration_end).format('DD/MM/YYYY')}</p>
								</div> */}

                        </div>

                    </CardComponent>
                </div>
            }
        </div>
    )
}

export default PatientInfoModal;