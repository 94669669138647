import React, {useContext, useEffect,useState,useReducer} from 'react';
import moment from 'moment';
import { StyledContainer } from './styles';
import {
	Link
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { Context } from '../../../../../store';
import {
	getMedicalHistoryDataAPI,
	updateAppointmentStatusAPI,
} from '../../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';
import '../../../Components/subusers.css';
import { 
	getPatientWardRoomById,
	getPatientInfo,
	getPatientFollowUpDetails,
	addPatientFollowUpForm,
	getIpdHistoryOfPatient,
	getAppointmentHistory
} from '../../../../../api/organization';
import './appointment.css';
import FollowUpCarousel from '../../../../../components/Carousel';
import HealthRecordsChart from '../../../Components/HealthRecordsChart';
import PatientInfo from '../../../Components/PatientInfo';
import ServiceList from '../../../../../components/Service';
import AppointmentCard from '../../../../../components/Appointments';
import MedicalHistoryComponent from '../../../../../components/medicalHistory';
import IpdList from '../../../../../components/IpdList';
import DischargeModal from '../../../../../components/Discharge';
import DischargePreview from '../../../../../components/PdfPreview/DischargePreview';
import ECGchart from '../../../../../components/EcgGraph';
import CardComponent from '../../../../../components/Card';
import MedicalReports from '../../../../SubAdmin/Panel/ViewPatient/MedicalReports';

function SubAdminViewPatient(props) {
	const [state, dispatch] = useContext(Context);
	const [id, setId] = useState(null);
	const [patientId, setPatientId] = useState(null);
	// const [desigantion, setDesigantion] = useState('');
	const [patientData, setPatientData] = useState({});
	const [patientDetails, setPatientDetails] = useState({});
	const [ipdHistory, setIpdHistory] = useState([]);
	const [showSubModal, setShowSubModal] = useState(false);
	const [ecgWidth, setEcgWidth] = useState();
    const [pdfModal,setPdfModal] = useState(false)
	const [patientInfo, SetPatientInfo] = useState({
		dob: '',
		gender: '',
		phoneNumber: '',
		name: '',
		address: '',
		profileImage: null,
		org_name: '',
		weight: '',
		_id: null
	});
	const blankMedication = { 
		medication_name: '',
		medicine_type: 'Tablet',
		strength_name: '',
		strength_type: 'mg',
		// method_intake: '',
		// dos_quantity_count: '',
		dos_quantity_name: '',
		// dos_quantity_when: ''
	
	};

	const [fields, setFields] = useState([
    { ...blankMedication },
	]);
	const [appointment, setAppointment] = useState({
		OrganizationId: null,
		SecondOpinionId: {},
		amount: null,
		appointmentType: null,
		conversions: [],
		createdAt: null,
		doctorId: {},
		patientId: {},
		slot: {},
		status: 0,
		_id: "601c0dfdfcfe01d6ad88b3ed"
	});

	const [medicalHistory, setMedicalHistory] = useState({
		allergieData: [],
		healthConditionData: [],
		medicationData: [],
		surgeriesData: [],
		vaccinationData: []
	});
	const [modalVisible, setModalVisible] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
	const [historyAppointments,setHistoryAppointments] = useState([])
	const [PatientFollowUpDetails,setPatientFollowUpDetails] = useState([]);
	// const [permissions,setPermissions] = useState([]);
	const permissions = JSON.parse(localStorage.getItem('subUserPermission'))
	const desigantion = JSON.parse(localStorage.getItem('subUserDesignation'))
	
	const handleFetchData = () => {
		const script = document.createElement("script");

    script.src = "https://cdn.jsdelivr.net/npm/chart.js@2.9.4/dist/Chart.min.js";
    script.async = true;

		document.body.appendChild(script);
		
		if(props.match.params && props.match.params.id) { 
			setPatientId(props.match.params.patientId);
			setId(props.match.params.id);
			loader(true);
			try {
				(async function () {
					const promise = await Promise.allSettled([getPatientInfo(props.match.params.patientId), getPatientWardRoomById(props.match.params.id), getPatientFollowUpDetails(props.match.params.id), getIpdHistoryOfPatient(props.match.params.patientId)]);
					if (promise[0].status === 'fulfilled') {
						SetPatientInfo({
							dob: promise[0].value.data.dob,
							email: promise[0].value.data.email,
							gender: promise[0].value.data.gender,
							phoneNumber: promise[0].value.data.phoneNumber,
							name: promise[0].value.data.name,
							address: promise[0].value.data.address,
							profileImage: promise[0].value.data.profileImage,
							org_name: promise[0].value.data.org_name,
							_id: promise[0].value.data._id,
							weight: promise[0].value.data.weight
						})
						setPatientData(promise[0].value.data);
					}
					if (promise[1].status === 'fulfilled') {
						setPatientDetails(promise[1].value.data);
					}
					if (promise[2].status === 'fulfilled') {
						setPatientFollowUpDetails(promise[2].value.data.reverse())
					}
					if (promise[3].status === 'fulfilled') {
						const filterIpd = promise[3].value.data.filter((item) => item._id !== props.match.params.id);
						const filterIpdHistory = filterIpd.filter((item)=> item.dischargeByDoctor !== false);

						setIpdHistory(filterIpdHistory)
					}
					loader(false);
				}());
			} catch (err) {
				loader(false);
			}
		}
		let des =  JSON.parse(localStorage.getItem('subuser'))
		// setDesigantion(des.designation);
		// setPermissions(des.permission)
		getAppointments();
	}

	useEffect(() => {
		handleFetchData();
	}, [props.match.params.id]);

	useEffect(()=>{
		handleFetchData();
		const k = document.getElementById('ecg-graph-width-appointmentId').offsetWidth;
		setEcgWidth(k-20)
	},[]);

	const getAppointments = () => {
		getAppointmentHistory(props.match.params.patientId).then(res => {
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setHistoryAppointments(res?.data);
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const addPatientFollowUp = () => {
		const data = {
			diagnosis: '',
			medication: [],
			lab: [],
			radiology: [],
			nursingMorning: [],
			nursingAfternoon: [],
			nursingEvening: [],
			nursingNight: [],
			doctorMorning: [],
			doctorAfternoon: [],
			doctorEvening: [],
			doctorNight: [],
			followUpDate: new Date(),
			patient: patientId
		}
		addPatientFollowUpForm(id,data).then((res) => {
			getPatientFollowUpDetails(props.match.params.id).then((res) => {
				setPatientFollowUpDetails(res?.data.reverse())
				forceUpdate();
			}).catch((err) => {
				toast.error(err?.response?.data?.error)
			})
		}).catch((err) => { })
	}
	const openIpdPatientView = (id) => {
		props.history.push(`/subuser/view-patient/${id}/${props.match.params.patientId}`)
	}

	const handleClickAppointment = (data) => {
		props.history.push(`/subuser/view-appointment/${data?._id}/${data?.patientId?._id}`);
	}

	const handlePreviewPdf = () => {
		setShowSubModal(false)
        setPdfModal(true);
    }

	const todayExists = () => {
		let exists = false
		PatientFollowUpDetails && PatientFollowUpDetails.map((data) => {
			if(moment(data.followUpDate).format("DD-MM-YYYY") === moment().format("DD-MM-YYYY") || patientDetails?.dischargeByDoctor || patientDetails?.dischargeByFinancial){
				exists =  true;
			}
		})
		return exists;
	}

	return (
		<div>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
                            <div className="col-md-12 row">
								{patientDetails.dischargeByDoctor && !patientDetails?.dischargeByFinancial  && <div className="col-md-12 p-2 warning-card text-center mb-2">
									Patient is discharged by doctor, Please complete your discharge process.
								</div>}
							<Link
								to={'/subuser/dashboard'}
								style={{
									border: '1px solid #5156be',
									borderRadius: '5px',
									padding: '10px',
									marginBottom: '20px',
									color: '#5156be',
									fontSize: '17px',
									fontWeight: '500'
								}}
							>
								<i className="fa fa-arrow-left pr-2" />
								Back To Dashboard
							</Link>
								<div className='ml-auto d-flex'>
									{!todayExists() && (desigantion === 'Compounder' || desigantion === 'Nurse') && !patientDetails?.dischargeByDoctor ?
									permissions.includes("Admin") || permissions.includes("Admit a patient into IPD") ? <div className="col-md-1">
											<button style={{ marginLeft: 'auto' }} className="doctor-active-btn doctor-w-20 mb-4 ml-auto" onClick={addPatientFollowUp}>Add </button>
										</div> : null : null
									}
									{patientDetails?.dischargeByFinancial ? null :
										(permissions.includes("Admin") || permissions.includes("Discharge") ?
											<button style={{ marginLeft: 'auto' }} className="doctor-active-btn doctor-w-20 mb-4 ml-auto" onClick={() => {
												setModalVisible(true);
												document.body.style.overflowY = 'hidden';
											}}>Discharge </button>
											: null)
									}
								</div>
                            </div>

							<h3 className="mb-4 mt-4">Patient IPD details </h3>
							<StyledContainer>
								<div className="content">
									<div className="container-fluid pr-0">
										<div className="container-fluid-card row d-flex col-md-12">
											<div className='col-md-9'>
												<div className="col-md-12">
													

												</div>
												<FollowUpCarousel
													PatientFollowUpDetails={PatientFollowUpDetails}
													desigantion={desigantion}
													setPatientFollowUpDetails={setPatientFollowUpDetails}
													patientDetails={patientDetails}
													todayExists={todayExists}
												/>
												<HealthRecordsChart showAdd={true} patientId={props.match.params.patientId} />
												<div className="p-3">
													<h2 className = "cards-main-heading mb-3">Medical History</h2>
													<MedicalHistoryComponent medicalHistory={medicalHistory} />
												</div>
											</div>
											<div className='col-md' style={{ padding: "0px" }}>
												<PatientInfo patientInfo={patientInfo} {...props} showInfoButton = {true} appointment={appointment} patientDetails={patientDetails}/>
												<ServiceList
													data={patientDetails.services}
													id={id}
													setPatientDetails={setPatientDetails}
													patientDetails={patientDetails}
													showAddButton={!patientDetails.dischargeByFinancial && !patientDetails.dischargeByDoctor}
												/>
												<AppointmentCard
													heading='Previous appointments'
													data={historyAppointments}
													keyItem="Previous"
													handleClickAppointment = {handleClickAppointment}
												/>
												<IpdList data={ipdHistory} openIpdPatientView={openIpdPatientView}/>
												<CardComponent className='p-2 ecg-graph-width-appointment ecg-min-height' id='ecg-graph-width-appointmentId'>
													{ecgWidth && patientInfo._id && <ECGchart
														width={ecgWidth}
														id={patientInfo._id}
													/>}
												</CardComponent>
												<MedicalReports patiendId={props.match.params.patientId} />
											</div>
										</div>
										</div>
									</div>
									{/* <PatientInfoModal/> */}
							</StyledContainer>
						</div>

					</div>
				</div>
			</div>
            {
                modalVisible &&
				<DischargeModal
					patientDetails={patientDetails}
					id={id}
					setPatientDetails={setPatientDetails}
					setModalVisible={setModalVisible}
					loader={loader}
					showPreviewModal = {true}
					setPdfModal={setPdfModal}
					setShowSubModal={setShowSubModal}
				/>
            }
			 {showSubModal && <div class="modal fade call-modal show" id="exampleModalCenterlogout" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <i
                            style={{
                                top: "10",
                                right: "10"
                            }}
                            class="fas fa-times patient-info-modal-close-icon-preview ml-auto" onClick={() => {
                                setShowSubModal(false);
								props.history.push(`/subuser/dashboard`);
                                document.body.style.overflowY = 'auto';
                            }}></i>
                        <div class="modal-body p-5" style={{ fontSize: "20px" }}>
                            Do you want to preview details?
                        </div>
                        <div class="modal-footer">
                            {/* <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={handleSubmit}>Discharge</button> */}
                            <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={handlePreviewPdf} >Preview</button>
                        </div>
                    </div>
                </div>
            </div>}
		{pdfModal && <div className="modal fade call-modal show pdf-preview-modal" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
		  <i style={{ fontSize: "28px", right: "139px", margin: "18px", position: "absolute", color: "white" }} class="fa fa-times" aria-hidden="true" onClick={(e) => {
				setPdfModal(false);
				props.history.push(`/subuser/dashboard`);
				document.body.style.overflowY = 'auto';
			}}>
			</i>
			<DischargePreview patientDetails={patientDetails} />
		</div>}
		</div>
	)
}

export default SubAdminViewPatient
