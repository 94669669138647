import React, {useContext, useState} from 'react';
import { toast } from 'react-toastify';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import {
	StyledContainer,
	StyledButton
} from './styles';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';
import { Context } from '../../../store';
import CardComponent from '../../../components/Card';
import InsideMenuBar from '../../../components/InsideMenus';

function CreateQr(props) {
	const [state, dispatch] = useContext(Context);

    const [medical,setMedical] = useState(false);
    const [patient,setPatient] = useState(false);
	const [timeStamp, setTimeStamp] = useState();
    const [medicalHistory,setMedicalHistory] = useState({
        healthConditions: false,
        medications: false,
        surgeries: false,
        allergies: false,
        vaccnations: false
    })
	const [patientData,setPatientData] = useState({
        name: false,
        email: false,
        contact: false,
        profile: false,
		dob: false,
		bloodGroup: false
    })
    const [health,setHealth] = useState(false);
    const [parameters,setParameters] = useState({
        bp: false,
        pulse: false,
        oxygn: false,
        respiratory: false,
        temp: false,
        bloodSugar: false,
        lipid: false,
        // weight: false,
        // bmi: false,
        // waist: false,
        // exercise: false,
        // nutration: false,
        // jointMovement: false,
        // pain: false,
    })

    const handleMedicalHistory = (e) => {
        setMedical(e.target.checked)
        setMedicalHistory({
            healthConditions: e.target.checked,
            medications: e.target.checked,
            surgeries: e.target.checked,
            allergies: e.target.checked,
            vaccnations: e.target.checked
        })
    }

	
	const handlePatientInfo = (e) => {
		setPatient(e.target.checked);
		setPatientData({
			name: e.target.checked,
			email: e.target.checked,
			contact: e.target.checked,
			profile: e.target.checked,
			dob : e.target.checked,
			bloodGroup: e.target.checked,
		})
	}

    const handleHealthParams = (e) => {
        setHealth(e.target.checked);
        setParameters({
            bp: e.target.checked,
            pulse: e.target.checked,
            oxygn: e.target.checked,
            respiratory: e.target.checked,
            temp: e.target.checked,
            lipid: e.target.checked,
            bloodSugar: e.target.checked,
            // weight: e.target.checked,
            // bmi: e.target.checked,
            // waist: e.target.checked,
            // exercise: e.target.checked,
            // nutration: e.target.checked,
            // jointMovement: e.target.checked,
            // pain: e.target.checked,
        })
    }
	const handleChangeParams = (e) => {
		if(e.target.checked === false){
			setHealth(e.target.checked);
		}
		setParameters({
			...parameters,
			[e.target.name] : e.target.checked
		})
	}

	const handleChangePatient = (e) => {
		if(e.target.checked === false){
			setPatient(false)
		}
		setPatientData({
			...patientData,
			[e.target.name] : e.target.checked
		})
	}

	const handleChangeMedical = (e) => {
		if(e.target.checked === false){
			setMedical(e.target.checked);
		}
		setMedicalHistory({
			...medicalHistory,
			[e.target.name] : e.target.checked
		})
	}

	const handleClick = () => {
		const user = JSON.parse(localStorage.getItem('customer'));
		let url = '?patientInfo';
		let url1 = '?userData';
		let url2 = '?userData2';
		let url3  = '';
		let hasSelected = false
		for (const property in parameters) {
			if (parameters[property]) {
				hasSelected=true;
				url1 = url1 + `&${property}=${parameters[property]}`
			}
		}
		for (const property in medicalHistory) {
			if (medicalHistory[property]) {
				hasSelected=true;
				url2 = url2 + `&${property}=${medicalHistory[property]}`
			}
		}
		for (const property in patientData) {
			if (patientData[property]) {
				hasSelected=true;
				url = url + `&${property}=${patientData[property]}`
			}
		}
		if (timeStamp) {
			url3 = '?timeStamp' + `&endTime=${timeStamp.getTime()}`
		}
		if(hasSelected){
			props.history.push(`/patient/share-qr/${user._id}/${url}${url1}${url2}${url3}`);
		} else {
			toast.error('Please select atleast one field')
			dispatch({
				type: SET_ERROR,
				payload: 'Please select atleast one field'
			});
		}
	}
  	
	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'patient'}
			/>

			<div className="content mobile-content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<CardComponent className="p-4 mb-4 row">
								<div className=" col-md-6 d-flex align-center">
									<h3>Please Select features to convert into QR code</h3>
								</div>
								<div className="col-md-12 no-padding form-group mt-4 d-flex row">
									<label style={{ fontWeight: "600", textAlign: "center", display: "flex", alignItems: 'center' }} className="col-md-2 mt-1">Valid till: </label>
									<div className="col-md-5 mt-1">
										<DatePicker format={'dd-MM-yyyy'} minDate={new Date()} showTimeSelect value={timeStamp} onChange={(d) => setTimeStamp(d)} className="ipdwardDatePicker" />
									</div>
									<div className="col-md-5 mt-1">
										<TimePicker
											openClockOnFocus
											// onChange={time => setServicesDetails((serviceDetails) => ({ ...serviceDetails, time: time }))}
											value={timeStamp}
											onChange={time => {
												if (timeStamp) {
													timeStamp.setHours(time.split(':')[0]);
													timeStamp.setMinutes(time.split(':')[1]);
												}
												else if (time) {
													new Date().setHours(time.split(':')[0]);
													new Date().setMinutes(time.split(':')[1]);
												}
												setTimeStamp(timeStamp)
											}}
											format="hh:mm:ss a"
											className="admission-details-timepicker"
										/>
									</div>
								</div>
								<div className="d-flex  col-md-6" style={{ flexDirection: 'column', marginTop: "30px" }}>
									<div className="d-flex">
										<div class="form-group cust-do-em row ml-2">
											<input type="checkbox" id="health" value={health} checked={health} onChange={handleHealthParams} />
											<label for="health">Health Parameters</label>
										</div>
									</div>
									<div class="form-group cust-do-em row ml-5 mb-1">
										<input type="checkbox" id="bp" name="bp" value={parameters.bp} checked={parameters.bp} onChange={handleChangeParams} />
										<label for="bp">Blood Pressure</label>
									</div>
									<div class="form-group cust-do-em row ml-5 mb-1">
										<input type="checkbox" id="pulse" name="pulse" value={parameters.pulse} checked={parameters.pulse} onChange={handleChangeParams} />
										<label for="pulse">Pulse</label>
									</div>
									<div class="form-group cust-do-em row ml-5 mb-1">
										<input type="checkbox" id="oxygen" name="oxygn" value={parameters.oxygn} checked={parameters.oxygn} onChange={handleChangeParams} />
										<label for="oxygen">Oxygen Saturation</label>
									</div>
									<div class="form-group cust-do-em row ml-5 mb-1">
										<input type="checkbox" id="rate" name="respiratory" value={parameters.respiratory} checked={parameters.respiratory} onChange={handleChangeParams} />
										<label for="rate">Respiratory rate</label>
									</div>
									<div class="form-group cust-do-em row ml-5 mb-1">
										<input type="checkbox" id="sugar" name="bloodSugar" value={parameters.bloodSugar} checked={parameters.bloodSugar} onChange={handleChangeParams} />
										<label for="sugar">Blood sugar</label>
									</div>
									<div class="form-group cust-do-em row ml-5 mb-1">
										<input type="checkbox" id="temp" name="temp" value={parameters.temp} checked={parameters.temp} onChange={handleChangeParams} />
										<label for="temp">Temperature</label>
									</div>
									<div class="form-group cust-do-em row ml-5 mb-1">
										<input type="checkbox" id="lipid" name="lipid" value={parameters.lipid} checked={parameters.lipid} onChange={handleChangeParams} />
										<label for="lipid">Lipid Profile</label>
									</div>
									{/* <div class="form-group cust-do-em row ml-5 mb-1">
														<input type="checkbox" id="weight" value={parameters.weight} name="weight" checked={parameters.weight} onChange={e => setParameters({...parameters,weight:e.target.checked})}  />
														<label for="weight">Weight</label>
												</div>
												<div class="form-group cust-do-em row ml-5 mb-1">
														<input type="checkbox" id="bmi" name="bmi" value={parameters.bmi} checked={parameters.bmi} onChange={e => setParameters({...parameters,bmi:e.target.checked})}  />
														<label for="bmi">BMI</label>
												</div>
												<div class="form-group cust-do-em row ml-5 mb-1">
														<input type="checkbox" id="waist" name="waist" value={parameters.waist} checked={parameters.waist} onChange={e => setParameters({...parameters,waist:e.target.checked})}  />
														<label for="waist">Waist circumference</label>
												</div> */}
									{/* <div class="form-group cust-do-em row ml-5 mb-1">
														<input type="checkbox" id="exercise" name="exercise" value={parameters.exercise} checked={parameters.exercise} onChange={e => setParameters({...parameters,exercise:e.target.checked})}  />
														<label for="exercise">Exercise</label>
												</div>
												<div class="form-group cust-do-em row ml-5 mb-1">
														<input type="checkbox" id="nutrition" name="nutration" value={parameters.nutration} checked={parameters.nutration} onChange={e => setParameters({...parameters,nutration:e.target.checked})}  />
														<label for="nutrition">Nutrition</label>
												</div>
												<div class="form-group cust-do-em row ml-5 mb-1">
														<input type="checkbox" id="joint" name="jointMovement" value={parameters.jointMovement} checked={parameters.jointMovement} onChange={e => setParameters({...parameters,jointMovement:e.target.checked})}  />
														<label for="joint">Joint Movements</label>
												</div>
												<div class="form-group cust-do-em row ml-5 mb-1">
														<input type="checkbox" id="pain" name="pain" value={parameters.pain} checked={parameters.pain} onChange={e => setParameters({...parameters,pain:e.target.checked})}  />
														<label for="pain">Pain level</label>
												</div> */}
								</div>

								<div className="d-flex col-md-6" style={{ flexDirection: 'column', marginTop: "30px" }}>
									<div className='col-md mb-2'>
										<div className="d-flex">
											<div class="form-group cust-do-em row ml-2">
												<input type="checkbox" id="patientDetails" value={patient} checked={patient} onChange={handlePatientInfo} />
												<label for="patientDetails">Patient Details</label>
											</div>
										</div>
										<div class="form-group cust-do-em row ml-5 mb-1">
											<input type="checkbox" id="patientName" name="name" value={patientData.name} checked={patientData.name} onChange={handleChangePatient} />
											<label for="patientName">Name</label>
										</div>
										<div class="form-group cust-do-em row ml-5 mb-1">
											<input type="checkbox" id="patientProfile" name='profile' value={patientData.profile} checked={patientData.profile} onChange={handleChangePatient} />
											<label for="patientProfile">Profile</label>
										</div>
										<div class="form-group cust-do-em row ml-5 mb-1">
											<input type="checkbox" id="patientEmail" name='email' value={patientData.email} checked={patientData.email} onChange={handleChangePatient} />
											<label for="patientEmail">Email</label>
										</div>
										<div class="form-group cust-do-em row ml-5 mb-1">
											<input type="checkbox" id="patientContact" name='contact' value={patientData.contact} checked={patientData.contact} onChange={handleChangePatient} />
											<label for="patientContact">Contact No</label>
										</div>
										<div class="form-group cust-do-em row ml-5 mb-1">
											<input type="checkbox" id="dob" name='dob' value={patientData.dob} checked={patientData.dob} onChange={handleChangePatient} />
											<label for="dob">Dob</label>
										</div>
										<div class="form-group cust-do-em row ml-5 mb-1">
											<input type="checkbox" id="bloodGroup" name='bloodGroup' value={patientData.bloodGroup} checked={patientData.bloodGroup} onChange={handleChangePatient} />
											<label for="bloodGroup">Blood Group</label>
										</div>
									</div>
									<div className='col-md'>
										<div className="d-flex">
											<div class="form-group cust-do-em row ml-2">
												<input type="checkbox" id="medicalHistory" value={medical} checked={medical} onChange={handleMedicalHistory} />
												<label for="medicalHistory">Medical History</label>
											</div>
										</div>
										<div class="form-group cust-do-em row ml-5 mb-1">
											<input type="checkbox" id="healthConditions" name='healthConditions' value={medicalHistory.healthConditions} checked={medicalHistory.healthConditions} onChange={handleChangeMedical} />
											<label for="healthConditions">Health Conditions</label>
										</div>
										<div class="form-group cust-do-em row ml-5 mb-1">
											<input type="checkbox" id="medications" name='medications' value={medicalHistory.medications} checked={medicalHistory.medications} onChange={handleChangeMedical} />
											<label for="medications">Medications</label>
										</div>
										<div class="form-group cust-do-em row ml-5 mb-1">
											<input type="checkbox" id="surgeries" name='surgeries' value={medicalHistory.surgeries} checked={medicalHistory.surgeries} onChange={handleChangeMedical} />
											<label for="surgeries">Surgeries</label>
										</div>
										<div class="form-group cust-do-em row ml-5 mb-1">
											<input type="checkbox" id="allergies" name='allergies' value={medicalHistory.allergies} checked={medicalHistory.allergies} onChange={handleChangeMedical} />
											<label for="allergies">Allergies</label>
										</div>
										<div class="form-group cust-do-em row ml-5 mb-1">
											<input type="checkbox" id="vaccinations" name='vaccnations' value={medicalHistory.vaccnations} checked={medicalHistory.vaccnations} onChange={handleChangeMedical} />
											<label for="vaccinations">Vaccinations</label>
										</div>
									</div>
								</div>

								<div className="d-flex align-center col-md-12">
									<StyledButton onClick={handleClick}>Create a QR code</StyledButton>
								</div>

							</CardComponent>
						</div>

					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default CreateQr
