import React from 'react';
import { Link } from 'react-router-dom';

import {
  StyledCard
} from './styles';

function CardWithImage(props) {
  return (
    <StyledCard className="text-center doctor-book-card">
      <div className="emed4uLanding-login">
        <img src={props.img} alt="" className="img-fluid" />
      </div>
      {/* <div className="doctor-book-card-content tile-card-content-1"> */}
        <div className="text-center" style={{padding: '25px'}}>
          <h3 className=" mb-0" style={{color: '#000', 'text-shadow': 'none'}}>{props.title}</h3>
          <Link to={props.route} className="btn book-btn1 px-3 py-2 mt-3" tabindex="0">{props.button}</Link>
        </div>
      {/* </div> */}
    </StyledCard>
  )
}

export default CardWithImage
