import React, { useState, useContext, useEffect }  from 'react';
import { Link } from 'react-router-dom';

import PhoneInput from "react-phone-number-input";

import Select from "react-select";

import CardComponent from '../../../../../components/Card'
import InputField from '../../../../../components/InputField';
import SelectField from '../../../../../components/SelectField';

import { toast } from 'react-toastify';
import { globalValidator, getUser } from "../../../../../utils";

import { Context } from '../../../../../store';
import {
	getSubAdminByIdAPI,
	addSubAdminAPI,
	updateSubAdminAPI
} from '../../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';

import {
	StyledContainer,
	StyledHeader,
	StyledBackTo,
	StyledTitle,
	StyledAddEditPatient
} from './styles';
import InsideMenuBar from '../../../../../components/InsideMenus';

const permission = [
	{ label: 'Admin', isDisabled: false },
	{ label: 'Admit a patient into IPD', isDisabled: false },
	{ label: 'Discharge', isDisabled: false },
	// { label: 'Accounting', isDisabled: false },
	{ label: 'Reports', isDisabled: false },
	{ label: 'OPD', isDisabled: false },
	// { label: 'Laboratory', isDisabled: false },

]

const permisionOBJ = (idx, label, value, isDisabled) => ({
	id: idx,
	label: label,
	value: value,
	isDisabled: isDisabled
})

function ViewSubAdmin(props) {
	const [state, dispatch] = useContext(Context);
	const location = window.location.pathname
	const [patient, setPatient] = useState({
		name: '',
		username: '',
		status: '',
		password: '',
		phoneNumber: '',
		gender: '',
		permission: '',
		designation: ''
	});
	const [id, setId] = useState(null);
	const optionsPermission = permission.map((sym, idx) => permisionOBJ(idx+1, sym.label, sym.label ,sym.isDisabled))
	useEffect(() => {
		if(props.match.params && props.match.params.id) {
			setId(props.match.params.id);
			loader(true);
			getSubAdminByIdAPI(props.match.params.id).then(res => {
				loader(false);
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				
				setPatient({
					username: res.data.email,
					status: res.data.status === 0 ? '0' : '1',
					name: res.data.name,
					password: res.data.password,
					phoneNumber: res.data.phoneNumber,
					gender: res.data.gender,
					permission:res.data.permission.map((item) => {
						return {label: item, value: item, isDisabled: false}
					}),
					address: res.data.address,
					designation: res.data.designation
				});
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
	}, [])

	const submitPatient = (e) => {
		e.preventDefault();

		if(!patient.username){
			toast.error("Please fill email!")
			dispatch({
				type: SET_ERROR,
				payload: 'Please fill email!'
			});
			return false
		}
		if(!patient.name){
			toast.error("Please fill name!")
			dispatch({
				type: SET_ERROR,
				payload: 'Please fill name!'
			});
			return false
		}
		if(!patient.status){
			toast.error("Please select status!")
			dispatch({
				type: SET_ERROR,
				payload: 'Please select status!'
			});
			return false
		}
		if(!patient.phoneNumber){
			toast.error("Please fill contact number!")
			dispatch({
				type: SET_ERROR,
				payload: 'Please fill contact number!'
			});
			return false
		}
		if(!patient.gender){
			toast.error("Please select gender!")
			dispatch({
				type: SET_ERROR,
				payload: 'Please select gender!'
			});
			return false
		}
		if(!patient.designation){
			toast.error("Please select designation!")
			dispatch({
				type: SET_ERROR,
				payload: 'Please select designation!'
			});
			return false
		}
		if(!patient.address){
			toast.error("Please fill address!")
			dispatch({
				type: SET_ERROR,
				payload: 'Please fill address!'
			});
			return false
		}
		if(!patient.permission){
			toast.error("Please select permission!")
			dispatch({
				type: SET_ERROR,
				payload: 'Please select permission!'
			});
			return false
		}
		if(location == '/organization/add-sub-admin' && !patient.password){
			toast.error("Please fill password!")
			dispatch({
				type: SET_ERROR,
				payload: 'Please fill password!'
			});
			return false
		}
		

		const keys = [
			{ name: "username", title: "Username", validateKey: "email" },
		];
		let valid = globalValidator(patient, keys);

		if(valid === true) {
			loader(true);
			if(id) {
				let data = {
					email: patient.username,
					status: patient.status,
					name: patient.name,
					phoneNumber: patient.phoneNumber,
					gender: patient.gender,
					permission:patient.permission.map((item) => item.value),
					designation:patient.designation,
					address:patient.address
				}
				updateSubAdminAPI(id, data).then(res => {
					loader(false);
					toast.success(res?.message || 'Sub Admin is updated!')
					dispatch({
						type: SET_ERROR,
						payload: res?.message || 'Sub Admin is updated!'
					});
				}).catch(err => {
					loader(false);
					toast.error(err?.response?.data?.error)
					dispatch({
						type: SET_ERROR,
						payload: err?.response?.data?.error
					});
				})
			} else {
				const org = getUser();

				let data = {
					email: patient.username, 
					status: patient.status, 
					password: patient.password,
					name: patient.name,
					org_name: org.org_name,
					phoneNumber: patient.phoneNumber,
					gender: patient.gender,
					designation: patient.designation,
					address: patient.address,
					permission:patient.permission.map((item) => item.value)
				}
				addSubAdminAPI(data).then(res => {
					loader(false);
					toast.success(res?.message || 'Sub Admin added successfully!')
					dispatch({
						type: SET_ERROR,
						payload: res?.message || 'Sub Admin added successfully!'
					});
					setPatient({
						name: '',
						username: '',
						status: '',
						password: '',
						phoneNumber: '',
						gender: '',
						permission: [],
						designation: '',
						address: ''
					});
				}).catch(err => {
					loader(false);
					toast.error(err?.response?.data?.error)
					dispatch({
						type: SET_ERROR,
						payload: err?.response?.data?.error
					});
				})
			}
			
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const handleChange = (e) => {
		setPatient(info => ({...info, permission: e}))
	}
	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<StyledHeader>
									<StyledBackTo>
										<Link to={'/organization/dashboard'}>
											<i className="fa fa-arrow-left" />
											Back To Dashboard
										</Link>
									</StyledBackTo>

									<StyledTitle>
										{id ? 'Update' : 'Add'} Sub-administrator
									</StyledTitle>

									<StyledAddEditPatient  onClick={(e) => submitPatient(e)}>
										{id ? 'Update' : 'Save'} Sub-administrator
									</StyledAddEditPatient>
								</StyledHeader>

								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Email</label>
										<InputField value={patient.username} type="email" onChange={e => setPatient((patient) => ({...patient, username: e.target.value}))} />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Name</label>
										<InputField value={patient.name} onChange={e => setPatient((patient) => ({...patient, name: e.target.value}))} />
									</div>

								</div>

								<div className="row pt-3">

									{/* <div className="col-md-6 no-padding form-group ">
										<label>Assign doctor</label>
										<SelectField options={[]} />
									</div> */}
									<div className="col-md-6 no-padding form-group ">
										<label>Status</label>
										<SelectField value={patient.status} onChange={e => setPatient((patient) => ({...patient, status: e.target.value}))} options={[{id: 1, label: 'Active', value: 0},{id: 1, label: 'Deactive', value: 1}]} />
									</div>

									<div className="col-md-6 no-padding form-group">
										<label>Contact number</label>
										<PhoneInput
											placeholder="Enter phone number"
											international
											defaultCountry="IN"
											value={patient.phoneNumber}
											onChange={e => setPatient((user) => ({...user, phoneNumber: e}))}
										/>
									</div>

								</div>

								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Gender</label>
										<SelectField value={patient.gender} onChange={e => setPatient((patient) => ({...patient, gender: e.target.value}))} options={[{id: 1, label: 'Male', value: 'male'},{id: 2, label: 'Female', value: 'female'}, {id: 3, label: 'Other', value: 'other'}]} />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Designation</label>
										<SelectField value={patient.designation} onChange={e => setPatient((patient) => ({...patient, designation: e.target.value}))} options={[{id: 1, label: 'Nurse', value: 'Nurse'},{id: 2, label: 'Compounder', value: 'Compounder'}, {id: 3, label: 'Receptionist', value: 'Receptionist'}, {id: 3, label: 'Lab Assistent', value: 'Lab Assistent'}]} />
									</div>
								</div>

								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Address</label>
										<InputField value={patient.address} onChange={e => setPatient((patient) => ({...patient, address: e.target.value}))} />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Permission</label>
										<Select
											type="select"
											name="symptoms"
											isMulti
											value={patient.permission}
											onChange={(e) => handleChange(e)}
											options={optionsPermission}
											isOptionDisabled={(option) => option.isDisabled}
										/>
									</div>
								</div>

								{
									id === null && (
										<div className="row pt-3">

											<div className="col-md-12 no-padding form-group ">
												<label>Password</label>
												<InputField password={true} type="password" value={patient.password} onChange={e => setPatient((patient) => ({...patient, password: e.target.value}))} />
											</div>
										

											</div>
									)
								}
								
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default ViewSubAdmin
