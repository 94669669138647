import moment from 'moment';
import React from 'react';
import CardComponent from '../Card';
import AddService from './AddService';
import './index.css';

const ServiceList = (prop) => {
    const { data, showAddButton } = prop;
    const [modalVisible, setModalVisible] = React.useState(false);
    const totalAmount = () => {
        let total = []
        let sum = 0;
        data?.map((item) => {
            item?.serviceAmount && total.push(parseInt(item?.serviceAmount))
            })

        for (let i = 0; i < total.length; i++) {
            sum += total[i];
        }
        return sum;
    }
    return (
        <CardComponent className="mb-4 appointments-chart-card">
            <div className="d-flex row ml-1 current-vitals-chart-card-heading">
                <h5 className="pt-3 pl-3">Service listing</h5>
                {
                    showAddButton ?  <i class="fas fa-plus add-service-details-icon" onClick={() => {
                        setModalVisible(true);
                        document.body.style.overflowY = 'hidden';
                    }}></i> : null 
                }
            </div>
            <hr />
            <ul className="list-unstyled pl-3 pr-3" style={{ height: "250px", overflow: "scroll" }} >
                {
                    data && data?.length ? data?.map(item => {
                        return <li className="received m-0 mb-2 current-vitals-chart-card-li" style={{ alignItems: "center" }}>
                            <div class="media mt-0">
                                <div class="media-body">
                                    <div class="d-flex align-items-center">
                                        <div class="mt-0">
                                            <h5 style={{wordBreak: 'break-all'}} class="mb-1 tx-13 font-weight-sembold text-dark">
                                                {item.serviceName}
                                            </h5>
                                            <p class="mb-0 tx-12 text-muted">{item.serviceDate ? moment(item.serviceDate).format("DD-MM-YYYY") : ''}</p>
                                        </div>
                                        <span class="ml-auto">
                                            <span class="float-end badge-success-transparent">
                                                {item.serviceAmount}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr />

                        </li>
                    }) : <div className="d-flex justify-content-center" style={{ marginTop: "21%", textAlign: "center", padding: "20px" }}>Sorry! There are no Services to display</div>
                }
            </ul>
            <div className="d-flex justify-content-between ml-2 mb-3 mr-3 mt-2">
                <h6>
                    Total Services: {data ? data?.length : 0}
                </h6>
                <h6>
                    Total Amount: {data?.length ? totalAmount() : 0}
                </h6>
            </div>

            {
                modalVisible &&
                <div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
                    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '90%' }} role="document">
                        <div className="modal-content p-4" style={{ maxHeight: "700px", overflowY: "scroll" }}>
                            <AddService {...prop} setModalVisible = {setModalVisible} />
                            <div className="row ml-auto mb-5 mr-5">
                                {/* <button style={{ marginLeft: 'auto', marginTop: '1.75rem' }} className="doctor-active-btn doctor-w-20 ml-auto" onClick={handleSubmit}>Submit </button> */}
                                {/* <button disabled={patientDetails?.services.length === 0 ? true : false} style={{ marginLeft: 'auto', marginTop: '1.75rem' }} className="doctor-active-btn doctor-w-20 ml-auto" onClick={handlePreview}>Preview </button> */}
                                {/* <button style={{ marginLeft: 'auto', marginTop: '1.75rem' }} className="doctor-active-btn doctor-w-20 ml-auto" onClick={() => setModalVisible(false)}>Cancel </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </CardComponent>
    )
}
export default ServiceList;