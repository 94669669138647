import React, { useEffect } from 'react';
import PhoneInput from "react-phone-number-input";
import { toast } from 'react-toastify';
import { Context } from '../../../store';
import {
  getPatientInfoAPI,
	updatePatientInfoAPI,
	updatePatientImage,
	resetPasswordAPI,
} from '../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';
import { globalValidator } from "../../../utils";
import {
	StyledContainer,
	StyledButton
} from './styles';
import CardComponent from '../../../components/Card';
import InputField from '../../../components/InputField';
import SelectField from '../../../components/SelectField';
import moment from 'moment';
import InsideMenuBar from '../../../components/InsideMenus';

function PatientSettings(props) {
	const [state, dispatch] = React.useContext(Context);
	const [logoPayload, setLogoPayload] = React.useState(null);
	const [logo, setLogo] = React.useState(null);
	const [id, setId] = React.useState(null);
	const currentDate = moment(new Date()).format("YYYY-MM-DD")
	const [patientInfo, SetPatientInfo] = React.useState({
		dob: new Date(),
		gender: '',
		phoneNumber: '',
		name: '',
		address: '',
		_id: null,
		weight: ''
	});
  
	const [emergencyData, SetEmergencyData] = React.useState({
		emergency_name: '',
		emergency_number: '',
		emergency_relation: ''
	});
  
	const [patientPassword, SetPatientPassword] = React.useState({
		password: '',
		confirmPassword: ''
	});

	const getPatientInfo = () => {
		loader(true);
		getPatientInfoAPI().then(res => {
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
			SetPatientInfo({
				dob: res.data.dob ? moment(res.data.dob).format('YYYY-MM-DD') : new Date(),
				gender: res.data.gender,
				phoneNumber: res.data.phoneNumber,
				name: res.data.name,
				address: res.data.address,
				_id: res.data._id,
				weight: res.data.weight,
				patientHeight: res.data.patientHeight,
				bloodGroup: res.data.bloodGroup
			})
			SetEmergencyData({
				emergency_name: res.data.emergency_name,
				emergency_number: res.data.emergency_number,
				emergency_relation: res.data.emergency_relation
			})
			setLogo(res.data.profileImage);
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	useEffect(() => {
    	document.body.className = "";
		getPatientInfo();
	}, []);

  const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
  }

  const updateInfo = () => {
    const keys = [
      { name: "name", title: "Name", validateKey: "required" },
      { name: "phoneNumber", title: "Contact number", validateKey: "required" },
      // { name: "dob", title: "DOB", validateKey: "required" },
			// { name: "address", title: "Address", validateKey: "required" }
    ];
		let valid = globalValidator(patientInfo, keys);

		if(valid === true) {
      updatePatientInfoAPI(patientInfo._id,patientInfo).then(res => {
    
				loader(false);
				toast.success(res.message);
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
    } else {
      toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
    }
	if (logoPayload.type) {
		const formData = new FormData();
			formData.append('profileImage', logoPayload);
			loader(true);
			updatePatientImage(patientInfo._id,formData).then(res => {
				loader(false);
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
	}
  }

  const updateEmergencyDetail = () => {
    const keys = [
      { name: "emergency_name", title: "Name", validateKey: "required" },
      { name: "emergency_number", title: "Contact number", validateKey: "required" },
      { name: "emergency_relation", title: "Relation", validateKey: "required" }
    ];
		let valid = globalValidator(emergencyData, keys);

		if(valid === true) {
			loader(true);
      updatePatientInfoAPI(patientInfo._id,emergencyData).then(res => {
				
				loader(false);
				toast.success(res.message);
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
    } else {
      toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
    }
	}

	const resetPassword = () => {
		const keys = [
			{ name: "password", title: "Password", validateKey: "password" },
			{ name: "confirmPassword", title: "Confirm password", validateKey: "required" },
		];
		let valid = globalValidator(patientPassword, keys);
		if (patientPassword?.password === patientPassword?.confirmPassword && true) {
			if (valid === true) {
				loader(true);
				resetPasswordAPI({ password: patientPassword.password }).then(res => {
					loader(false);
					toast.success(res.message || "Password successfully updated!");
					dispatch({
						type: SET_ERROR,
						payload: res.message || "Password successfully updated!"
					});
					SetPatientPassword({
						password: '',
						confirmPassword: ''
					})
				}).catch(err => {
					loader(false);
					toast.error(err.response.data.error)
					dispatch({
						type: SET_ERROR,
						payload: err.response.data.error
					});
				})
			} else {
				toast.error(valid)
				dispatch({
					type: SET_ERROR,
					payload: valid
				});
			}
		} else {
			toast.error("Your password and confirmation password do not match.");
			dispatch({
				type: SET_ERROR,
				payload: "Your password and confirmation password do not match."
			});
		}
	}
	
	const setImage = event => {
		event.preventDefault();
		const imageFile = event.target.files[0];
		if(imageFile?.type == "image/png" || imageFile?.type ==  "image/jpg" || imageFile?.type == "image/jpeg"){
			if (imageFile.size > 2097152) {
				toast.error("Please upload a image smaller than 2 MB");
				return false;
			}

			setLogo(URL.createObjectURL(imageFile));
			setLogoPayload(imageFile);
			// const formData = new FormData();
			// formData.append('profileImage', imageFile);
			// loader(true);
			// updatePatientImage(patientInfo._id,formData).then(res => {
			// 	loader(false);
			// 	toast.success(res.message || "Patient updated!");
			// 	dispatch({
			// 		type: SET_ERROR,
			// 		payload: res.message || "Patient updated!"
			// 	});
			// }).catch(err => {
			// 	loader(false);
			// 	toast.error(err.response.data.error)
			// 	dispatch({
			// 		type: SET_ERROR,
			// 		payload: err.response.data.error
			// 	});
			// })
		} else {
			toast.error("Only JPG & PNG images are accepted");
		}
  	};

  const validationInputFeild = (e, type) => {
	switch(type){
		case 'weight':
			if(e.target.value >0 || e.target.value === ''){
				SetPatientInfo((user) => ({ ...user, weight: e.target.value }))
			}
			else {
				toast.error("Weight has to be Number")
				dispatch({
					type: SET_ERROR,
					payload: "Weight has to be Number"
				});
			}
			break
		case 'height':
			if (e.target.value > 0 || e.target.value === '') {
				SetPatientInfo((user) => ({ ...user, patientHeight: e.target.value }))			}
			else {
				toast.error("Height has to be Number")
				dispatch({
					type: SET_ERROR,
					payload: "Height has to be Number"
				});
			}
			break
		default:
	}
}
  
	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'patient'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<CardComponent className="p-4 mb-4">
								<div className="col-12 col-md-12">
									<div className="form-group">
										<div className="change-avatar">
											<div className="profile-img">
												<img
													style={{borderRadius: '50%'}}
													src={logo || "/assets/img/loading-img.jpg"}
													alt="User"
												/>
											</div>
											<div className="upload-img">
												<div className="change-photo-btn">
													<span><i className="fa fa-upload"></i> Change your profile image</span>
													<input
														type="file"
														className="upload"
														accept="image/png, image/jpg"
														onChange={(e) => setImage(e)}
													/>
												</div>
												<small className="form-text text-muted">Allowed JPG or PNG. Max size of 2MB</small>
											</div>
										</div>
									</div>
								</div>

								<div className="d-flex align-center">
									<h3>Update your personal info</h3>
									<StyledButton  onClick={updateInfo}>Update profile</StyledButton>
								</div>
								
								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Name</label>
										<InputField value={patientInfo.name} onChange={e => SetPatientInfo((user) => ({...user, name: e.target.value}))} />
									</div>

									{/* <div className="col-md-6 no-padding form-group ">
										<label>Contact number</label>
										<InputField value={patientInfo.phoneNumber} onChange={e => SetPatientInfo((user) => ({...user, phoneNumber: e.target.value}))} />
									</div> */}

									<div className="col-md-6 no-padding form-group doctor-add-patient-form">
										<label>Contact number</label>
										{/* <InputField value={doctorInfo.phoneNumber} onChange={e => SetDoctorInfo((user) => ({...user, phoneNumber: e.target.value}))} /> */}

										<PhoneInput
											placeholder="Enter phone number"
											international
											defaultCountry="IN"
											value={patientInfo.phoneNumber}
											onChange={e => SetPatientInfo((user) => ({...user, phoneNumber: e}))}
										/>
									</div>


									<div className="col-md-6 no-padding form-group ">
										<label>Gender</label>
										<SelectField onChange={e => SetPatientInfo((user) => ({...user, gender: e.target.value}))} value={patientInfo.gender}  options={[{label: 'Male', value: 'male'}, {label: 'Female', value: 'female'}, {label: 'Other', value: 'other'}]}  />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Date of birth</label>
										{/* <InputField value={patientInfo.dob} onChange={e => SetPatientInfo((user) => ({...user, dob: e.target.value}))} /> */}
										<InputField 
											type="date" 
											max={currentDate}
											onChange={e => SetPatientInfo((user) => ({...user, dob: e.target.value}))} 
											value={patientInfo.dob}
										/>
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Address</label>
										<InputField value={patientInfo.address} onChange={e => SetPatientInfo((user) => ({...user, address: e.target.value}))} />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Weight (in kgs)</label>
										<InputField value={patientInfo.weight} onChange={(e) => validationInputFeild(e, 'weight')} />
									</div>
									<div className="col-md-6 no-padding form-group ">
										<label>Blood group</label>
										<SelectField value={patientInfo.bloodGroup} onChange={e => SetPatientInfo((user) => ({ ...user, bloodGroup: e.target.value }))} options={[{ id: 1, label: 'A-', value: 'A-' }, { id: 1, label: 'A+', value: 'A+' },
										{ id: 1, label: 'B-', value: 'B-' }, { id: 1, label: 'B+', value: 'B+' },
										{ id: 1, label: 'AB-', value: 'AB-' }, { id: 1, label: 'AB+', value: 'AB+' },
										{ id: 1, label: 'O-', value: 'O-' }, { id: 1, label: 'O+', value: 'O+' }]} />
									</div>
									<div className="col-md-6 no-padding form-group ">
										<label>Height(Cm)</label>
										<InputField name='patientHeight' value={patientInfo.patientHeight} onChange={(e) => validationInputFeild(e, 'height')} />
									</div>
								</div>

								<hr />

								<div className="d-flex align-center">
									<h3>Emergency contact</h3>
									<StyledButton onClick={updateEmergencyDetail}>Update contact</StyledButton>
								</div>
								<h3></h3>
								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group doctor-add-patient-form">
										<label>Contact number</label>
										<PhoneInput
											international
											defaultCountry="IN"
											value={emergencyData.emergency_number}
											onChange={e => SetEmergencyData((user) => ({...user, emergency_number: e}))}
										/>
									</div>

									<div className="col-md-3 no-padding form-group ">
										<label>Name</label>
										<InputField  value={emergencyData.emergency_name} onChange={e => SetEmergencyData((user) => ({...user, emergency_name: e.target.value}))} />
									</div>

									<div className="col-md-3 no-padding form-group ">
										<label>Relation (If any)</label>
										<InputField  value={emergencyData.emergency_relation} onChange={e => SetEmergencyData((user) => ({...user, emergency_relation: e.target.value}))} />
									</div>

								</div>

								<hr />

								<div className="d-flex align-center">
									<h3>Reset your password</h3>
									<StyledButton onClick={() => resetPassword()}>Update password</StyledButton>
								</div>

								<div className="row pt-3">
									<div className="col-md-6 no-padding form-group ">
										<label>Password</label>
										<InputField  value={patientPassword.password} onChange={e => SetPatientPassword((user) => ({...user, password: e.target.value}))} type="password" password={true} />
									</div>

									<div className="col-md-6 no-padding form-group ">
										<label>Confirm password</label>
										<InputField  value={patientPassword.confirmPassword} onChange={e => SetPatientPassword((user) => ({...user, confirmPassword: e.target.value}))} type="password" password={true} />
									</div>
								</div>
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default PatientSettings;
