import React, {useEffect, useContext, useState} from 'react';

import InputField from '../../../../components/InputField';

import { toast } from 'react-toastify';

import { Context } from '../../../../store';
import {
	forgotPasswordAPI
} from '../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import { globalValidator, saveUser } from "../../../../utils";

import {
	StyledLeftPanel,
	StyledLeftHeading,
	StyledLeftSubHeading,
	StyledLeftImage,
	StyledRightPanel,
	StyledRightHeading,
	StyledRightSocialLogin,
	StyledSocialFB,
	StyledSocialGoogle,
	StyledRightDivider,
	StyledDividerLine,
	StyledDividerHeading,
	StyledLoginButton,
	StyledRightBottom,
	StyledBottomContent,
	StyledBottomLink,
	StyledForgotPassword,
	StyledForgotPasswordLink
} from './styles';

function ForgotPasswordDoctor(props) {


	const [state, dispatch] = useContext(Context);

	const [orgUser, SetOrgUser] = useState({email: ''});

	const login = (e) => {
		e.preventDefault();
		const { email } = orgUser;
    const keys = [
      { name: "email", title: "Email", validateKey: "email" },
    ];
		let valid = globalValidator({ email }, keys);

		if(valid === true) {
			loader(true);
			forgotPasswordAPI(orgUser).then(res => {
				loader(false);
				toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				SetOrgUser({email: ''});
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error || 'Please provide valid email')
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error || 'Please provide valid email'
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const LoginRe = () => {
		props.history.push('/doctor/login');
	}

	return (
		<section className="section home-tile-section">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-9 m-auto">
						<div className="row">
              <StyledLeftPanel className="col-md-5">
								<div className="row no-gutters h-100">
									<div className="col-10 col-lg-9 d-flex flex-column mx-auto">
										<StyledLeftHeading className="mt-5 mb-4">Forgot password for doctor</StyledLeftHeading>
										<StyledLeftSubHeading>
										If you have forgotten your password please type in your Email id.
										</StyledLeftSubHeading>
										<div className="mt-auto mb-4">
											<StyledLeftImage src="http://demo.harnishdesign.net/html/oxyy/images/login-vector.png" />
										</div>
									</div>
								</div>
							</StyledLeftPanel>

							<StyledRightPanel className="col-md-7">
								<div className="container my-auto py-5">
									<div className="row">
										<div className="col-11 col-lg-10 mx-auto">

											<form className="form-border info-widget">
												<div className="form-group card-label">
													<label>Email</label>
													<InputField value={orgUser.email} onChange={e => SetOrgUser((user) => ({...user, email: e.target.value}))} type="email" />
												</div>


												<StyledLoginButton onClick={(e) => login(e)}>
													Forgot password
												</StyledLoginButton>
											</form>

											<StyledRightBottom>
												<StyledBottomContent>
													I have an account with Emed4u? - 
													<StyledBottomLink onClick={() => LoginRe()}>
														Sign In Now
													</StyledBottomLink> 
												</StyledBottomContent>
											</StyledRightBottom>											
										</div>
									
									</div>

								</div>
							
								
							</StyledRightPanel>

            </div>
          </div>
        </div>
		  </div>
	  </section>  
	)
}

export default ForgotPasswordDoctor
