import { makeStyles } from "@mui/styles";
import colors from "../../constant/colors";

export const useStyles = makeStyles(()=>({
    infoContainer: {
        backgroundColor: "#EAAD111A",
    },

    secondContainer: {
        width: "135px",
    },

    textStyle1: {
        color: "#EAAD11",
    },

    textStyle2: {
        color: colors.primary
    },
    disableButton : {
        background: "linear-gradient(90deg, rgba(9, 126, 183, 0.5) 0%, rgba(42, 171, 108, 0.5) 100%)"
    }

   

   
}))