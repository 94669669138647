import React, { useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import moment from 'moment';

import { toast } from 'react-toastify';

import { Context } from '../../../../store';
import {
	BookedAppointmentAPI,
	getFileLinkAPI,
	getAllPaymentMethod,
} from '../../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';


import CardComponent from '../../../../components/Card';
import InputField from '../../../../components/InputField';

import { getPayLogo } from '../../../../constants';

import {
	Card,
	StyledButton,
	StyledContrainer
} from './styles'
import InsideMenuBar from '../../../../components/InsideMenus';

function PatientCheckout(props) {
	const [state, dispatch] = React.useContext(Context);
	const [doctorInfo, setDoctorInfo] = React.useState({
		name: '',
		address: '',
		department: '',
		amount: 0,
		profileImage: ''
	});
	const [appointmentType, setAppointmentType] = React.useState('');
	const [paymentMethodType, setPaymentMethodType] = React.useState(null);
	const [selectedPayment, setSelectedPayment] = React.useState(null);
	const [selectedPaymentCode, setSelectedPaymentCode] = React.useState(null);
	const [slotData, setSlotData] = React.useState({
		start: '',
		end: ''
	});


	const [curreny, setCurrency] = React.useState('');

	const [paymentMethod, setPaymentMethod] = React.useState([]);
	const handleAmount = (hours, slotType, fifteenOnline, appointmentPrice, thirtyfiveOnline, sixtyOnline, fifteenOffline, clinicPrice, thirtyfiveOffline, sixtyOffline, doctor) => {
		switch (hours) {
			case 15: return slotType ? fifteenOnline : fifteenOffline;
			case 30: return slotType ? appointmentPrice : clinicPrice;
			case 45: return slotType ? thirtyfiveOnline : thirtyfiveOffline;
			case 60: return slotType ? sixtyOnline : sixtyOffline;
			default: return slotType ? doctor.onlinePrice : doctor.offlinePrice;
		}
	}

	useEffect(() => {
		if(state.appointments.slot === null && state.appointments.date === null) {
			// props.history.push('/patient/book-appointment');
		}
		const allCookieData = Cookies.get();
		const doctor = JSON.parse(allCookieData.doctorInfo);
		const { fifteenOnline, appointmentPrice, thirtyfiveOnline, sixtyOnline, fifteenOffline, clinicPrice, thirtyfiveOffline, sixtyOffline } = doctor?.data;
		const slot = JSON.parse(allCookieData.slot);
		const appointment = JSON.parse(allCookieData.appointment);
		const methodType = localStorage.getItem('paymentMethod');
		const slotAppointmentType = localStorage.getItem('slotType');
		const hours = moment(new Date(slot.end)).diff(moment(new Date(slot.start)), 'minutes');
		const slotType = slot.type === 1;
		const computedAmount = handleAmount(hours, slotType, fifteenOnline, appointmentPrice, thirtyfiveOnline, sixtyOnline, fifteenOffline, clinicPrice, thirtyfiveOffline, sixtyOffline, doctor);

		if(doctor) {
			setCurrency(doctor.curreny)
			setDoctorInfo({
				name: doctor.name,
				address: doctor.address,
				department: doctor.department,
				amount: computedAmount,
				profileImage: doctor.profileImage
			})
			setAppointmentType(parseInt(slotAppointmentType) - 1);
			setPaymentMethodType(methodType)
			getAllPaymentMethod(doctor.doctorId).then(res => {
				setPaymentMethod(res.data);
			}).catch(err => {
				if(err && err.response && err?.response?.data?.error) {
					toast.error(err?.response?.data?.error)
					dispatch({
						type: SET_ERROR,
						payload: err?.response?.data?.error
					});
				}
			})
		}

		if(slot) {
			setSlotData({
				start: slot.start,
				end: slot.end
			})
		}
		
	}, []);

	const payment = (e) => {
		e.preventDefault();
		const allCookieData = Cookies.get();
		const appointment = JSON.parse(allCookieData.appointment);
		const methodType = localStorage.getItem('paymentMethod');
		const slot = JSON.parse(allCookieData.slot);
		const slotAppointmentType = localStorage.getItem('slotType');
		const doctor = JSON.parse(allCookieData.doctorInfo);
		const uploadDocuments = JSON.parse(localStorage.getItem('uploadDocuments'));
		const { fifteenOnline, appointmentPrice, thirtyfiveOnline, sixtyOnline, fifteenOffline, clinicPrice, thirtyfiveOffline, sixtyOffline } = doctor?.data;
		const hours = moment(new Date(slot.end)).diff(moment(new Date(slot.start)), 'minutes');
		const slotType = slot.type === 1;
		const computedAmount = handleAmount(hours, slotType, fifteenOnline, appointmentPrice, thirtyfiveOnline, sixtyOnline, fifteenOffline, clinicPrice, thirtyfiveOffline, sixtyOffline, doctor);
		if(methodType == 0 && selectedPayment === null) {
			toast.error("Please select payment method!")
			dispatch({
				type: SET_ERROR,
				payload: "Please select payment method!"
			});
			return
		} else {
			const formData = new FormData();
			Object.keys(appointment).forEach(key => {
				if (key === "uploadsDocuments") {
					appointment[key].map(doc => {
						formData.append("uploadsDocuments", doc.name);
					});
				} else {
					formData.append(key, appointment[key]);
				}
			});
	
			formData.append("OrganizationId", appointment.OrganizationId);
			formData.append("patientAge", appointment.patientAge);
			formData.append("patientGender", appointment.patientGender);
			formData.append("patientName", appointment.patientName);
			formData.append("patientWeight", appointment.patientWeight);
			formData.append("symptoms", appointment.symptoms);
			formData.append("why_do_doctor", appointment.why_do_doctor);
			formData.append("doctorId", slot.doctorId);
			formData.append("slotId", slot._id);
			formData.append("amount", computedAmount);
			formData.append("appointmentType", parseInt(slotAppointmentType) - 1);
			formData.append("hypertension", appointment.hypertension);
			formData.append("diabetes", appointment.diabetes);
			formData.append("appointmentType", parseInt(slotAppointmentType) - 1);
	
			const appointmentData = {
				OrganizationId: appointment.OrganizationId,
				patientAge: appointment.patientAge,
				patientGender: appointment.patientGender,
				patientName: appointment.patientName,
				patientWeight: appointment.patientWeight,
				symptoms: appointment.symptoms,
				why_do_doctor: appointment.why_do_doctor,
				doctorId: slot.doctorId,
				slotId: slot._id,
				amount: doctor.curreny+computedAmount,
				appointmentType: parseInt(slotAppointmentType) - 1,
				hypertension: appointment.hypertension,
				diabetes: appointment.diabetes,
				uploadsDocuments: uploadDocuments
				// appointmentType: parseInt(slotAppointmentType) - 1,
			}
			// if((parseInt(slotAppointmentType) - 1) == 0 && methodType == 0) appointmentData.paymentMode = selectedPayment;
			if(methodType == 0) appointmentData.paymentMode = selectedPayment;
			if(methodType == 0) appointmentData.paymentModeCode = selectedPaymentCode;
			loader(true);
			BookedAppointmentAPI(appointmentData).then(res => {
				loader(false);
				Cookies.remove('appointment', { path: '' });
				Cookies.remove('slot', { path: '' });
				Cookies.remove('doctorInfo', { path: '' });
				
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
	
				props.history.push(`/patient/payment-success-booking/${res.data._id}`);
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			}); 
		}
			
		// }

	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const setPayment = (item) => {
		setSelectedPayment(item.type);
		setSelectedPaymentCode(item.code)
	}

	return (
		<StyledContrainer>
			<InsideMenuBar
				props={props}
				type={'patient'}
			/>

			<div className="content pb-4">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<div className="row">
								{/* <div className="col-md-7 col-lg-8">
									<CardComponent>
										<div className="p-4 form-group ">
											<label>Coupon code</label>
											<InputField  />
											<StyledButton class="btn btn-primary submit-btn" >Apply coupon</StyledButton>
										</div>
										
									</CardComponent>
								</div> */}

								<div className="col-md-12 col-lg-12 theiaStickySidebar">
									<CardComponent>
										<div className="card-header">
											<h4 className="card-title">Booking Summary</h4>
										</div>

										<div className="card-body">
											<div className="booking-doc-info">
												<a className="booking-doc-img">
													<img src={doctorInfo.profileImage ? doctorInfo.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" />
												</a>
												<div className="booking-info">
													<h4><a >{doctorInfo.name}</a></h4>
													<div className="rating">
														<span className="d-inline-block average-rating">{doctorInfo.department}</span>
													</div>
													<div className="clinic-details">
														<p className="doc-location"><i className="fas fa-map-marker-alt"></i> {doctorInfo.address}</p>
													</div>
												</div>
											</div>

											<div className="booking-summary">
												<div className="booking-item-wrap">
													<ul className="booking-date">
														<li>Date <span>{moment(slotData.start).utc().format('Do MMMM, YYYY')}</span></li>
														<li>Time <span>{moment(slotData.start).utc().format('hh:mm a')} - {moment(slotData.end).utc().format('hh:mm a')}</span></li>
													</ul>
													{/* <ul className="booking-fee">
														<li>Consulting Fee <span>₹{doctorInfo.amount}</span></li>
														<li>Coupon code <span>{couponCode.code ? couponCode.code : '-'}</span></li>
														<li>Discount <span>₹{couponCode.amount}</span></li>
													</ul> */}
													<div className="booking-total">
														<ul className="booking-total-list">
															<li>
																<span>Total</span>
																<span className="total-cost">{curreny} {doctorInfo.amount}</span>
															</li>
														</ul>
													</div>
												</div>
											</div>

										</div>
									</CardComponent>
									<div>
										{/* {
											appointmentType === 0 && <div className="col-md-12 col-lg-12 theiaStickySidebar">
												<CardComponent className="mt-3 mb-2 p-4">
													<div className="row">
														{
															paymentMethod.map(item => {
																return <div className="col-md-4" onClick={() => setPayment(item)}>
																				<CardComponent className={selectedPayment === item.type ? 'selected-payment-method mt-3 mb-2 p-4' : 'mt-3 mb-2 p-4'}>
																					<div style={{display: 'flex'}}>
																						<img src={getPayLogo(item.type)} height="40px" style={{paddingRight: '20px'}} />
																						<h3>{item.type}</h3>
																					</div>
																					<br />
																					<p>{item.code}</p>
																				</CardComponent>
																			</div>
															})
														}
													</div>
												</CardComponent>
											</div>
										} */}

										{(paymentMethodType == 0 && paymentMethod?.length) ? (
											<Card>
												{paymentMethod?.map(item => (
													<div className="col-md-4" onClick={() => setPayment(item)}>
														<Card className={selectedPayment === item.type ? 'color hover' : 'hover'}>
															<div style={{ display: 'flex' }}>
																<img src={getPayLogo(item.type)} height="40px" style={{ paddingRight: '20px' }} />
																<h3>{item.type}</h3>
															</div>
															<br />
															<p>{item.code}</p>
														</Card>
													</div>
												))}
											</Card>
										) : null}
									
										<StyledButton class="btn btn-primary submit-btn" onClick={(e) => payment(e)}>Confirm and pay</StyledButton>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</StyledContrainer>
	)
}

export default PatientCheckout
