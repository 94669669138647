import styled from 'styled-components';

export const StyledContainer = styled.div`
    background: #fff;
    padding: 50px;
`;

export const StyledButtonPay = styled.button`
	background-color: #09e5ab;
	color: #fff;
	border: 1px solid #09e5ab;
	margin-top: 20px;
	padding: 12px 25px;
	border-radius: 5px;
	font-weight: 700;
`;