import styled from 'styled-components';

export const StyledContrainer = styled.div`
	.selected-payment-method.mt-3.mb-2.p-4 {
    background: #5fcf6b !important;
	}
`;

export const StyledButton = styled.button`
    background-color: #5156be;
    color: #fff;
    border: 1px solid #5156be;
    margin-top: 20px;
    padding: 12px 25px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
`;

export const Card = styled.div`
    background-color: #ffffff;
    -webkit-box-shadow: 0 3px 5px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 3px 5px 1px rgb(0 0 0 / 5%);
    border: 1px solid #EFEFFD;
    -webkit-transition: .5s;
    transition: .5s;
    border-radius: 10px;
    padding: 1.5rem!important;
    margin: 1rem 0 0.5rem;
    &.color {
        background: #5fcf6b !important;
    }
    &.hover {
        cursor: pointer;
    }
`;
