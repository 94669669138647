import React, {useContext, useEffect} from 'react';

import { Link } from 'react-router-dom';
import moment from 'moment';

import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getAppointmentsOrg
} from '../../../../api/subadmin';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';
import InsideMenuBar from '../../../../components/InsideMenus';

import CardComponent from '../../../../components/Card';
import TableComponent from '../../../../components/Table';
import Pagination from '../../../../components/Pagination';
import Search from '../../../../components/Search';
import { globalValidator } from '../../../../utils';

const options = [
	{ id: 0, label: 'Patient Id', value: '_id' },
	{ id: 1, label: 'Patient Name', value: 'name' },
	{ id: 2, label: 'Status', value: 'status' },
	{ id: 3, label: 'Appointment Date', value: 'appt_date' },
	{ id: 4, label: 'Patient Email', value: 'email' },
	{ id: 5, label: 'Contact number', value: 'phoneNumber' }
]

const statusOptions = [
	{id: 0, label: 'Pending',  value: '0'},
	{id: 1, label: 'Confirm', value: '1'},
	{id: 2, label: 'Rejected by you', value: '2'},
	{id: 3, label: 'Rejected by Patient',value: '3'},
	{id: 4, label: 'Completed', value: '4'},
]

function SubAdminApointments(props) {
	const [state, dispatch] = useContext(Context);
	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('_id');
	const [appointment, setAppointment] = React.useState([]);

	useEffect(() => {
		getAppointmentData();
	}, [])

	const getAppointmentData = () => {
		loader(true);
		getAppointmentsOrg().then(res => {
			setAppointment(res?.data);
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
		}).catch(err => {
			setAppointment([])
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}


	const searchAppointments = () => {
		// let valid = ''
		// const keys = [{ name: "email", title: "Email", validateKey: "email" }];
		// valid = globalValidator(search , keys);
		// if (selectValue === 'email') {
		// 	dispatch({
		// 		type: SET_ERROR,
		// 		payload: valid
		// 	});
		// 	return toast.error(valid)	
		// }
		loader(true);

		const payload = {
			[selectValue]: search
		}

		getAppointmentsOrg(search.length > 0 ? payload : '').then(res => {
			setAppointment(res?.data);
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
		}).catch(err => {
			loader(false);
			setAppointment([]);
			if (err.response?.data) {
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			}
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const getStatusClass = (item) => {
		if(item === 0) {
			return 'badge badge-pill bg-warning-light'
		} else if(item === 1) {
			return 'badge badge-pill bg-success-light'
		} else if(item === 2) {
			return 'badge badge-pill bg-danger-light'
		}  else if(item === 3) {
			return 'badge badge-pill bg-danger-light'
		}	else if(item === 4) {
			return 'badge badge-pill bg-success-light'
		}
	}

	const getStatus = (item) => {
		if(item === 0) {
			return 'Pending'
		} else if(item === 1) {
			return 'Confirm'
		} else if(item === 2) {
			return 'Rejected by you'
		}  else if(item === 3) {
			return 'Rejected by patient'
		}  else if(item === 4) {
			return 'Completed'
		}
	}

	const tableHeading = [
		{id: 1, title: 'Patient'},
		{id: 3, title: 'Appointment date'},
        {id: 5, title: 'Amount'},
        {id: 4, title: 'Pay type'},
		{id: 5, title: 'Booking status'},
		{id: 5, title: 'Actions'}
	];

	const [appointmentPaginate, setAppointmentPaginate] = React.useState([]);
	const pageChangeAppointment  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setAppointmentPaginate(data);
	}

	useEffect(() => {
		getAppointmentData();
	}, [selectValue])

	React.useEffect(() => {
        if(search === '') {
            getAppointmentData();
        }
    }, [search])


	const renderAppointmentAmount = (item) => {
		const amount = item?.amount.split('');
		const index = amount?.findIndex((char) => /^[0-9]$/.test(char))
		if(item?.amount && item?.pendingAmount){
			if(!index){
			    return `${parseInt(item?.amount) + item?.pendingAmount}` 
			}else {
			    return `${item?.amount.slice(0,index)}${parseInt(item?.amount.slice(index)) + item?.pendingAmount}` 
			}
		} else {
			return item?.amount
		}
        
    }
	

	return (
		<div>
			<InsideMenuBar
				props={props}
				type={'subadmin'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<CardComponent className="p-4">
								<div className="doctor-panel-header">
									<h3 className="mb-3">Appointments</h3>
								</div>
								<hr />
								<div className="doctor-panel-filters row col-md-12">
									<Search 
										selectValue={selectValue} 
										setSelectValue={setSelectValue} 
										search={search} 
										setSearch={setSearch} 
										searchPatient={searchAppointments} 
										options={options}
										statusOptions={statusOptions}
									/>		
								</div>

								
								<TableComponent tableHeading={tableHeading}>
								{
										appointment.length === 0 ? (
											<tr>
												<td colSpan="12">
													<h5 style={{ textAlign: "center" }}>
														Sorry! There are no results to display
													</h5>
												</td>
											</tr>
										) : 
										appointmentPaginate.map(item => {
											return <tr>
															<td>
																<h2 className="table-avatar">
																	<a className="avatar avatar-sm mr-2">
																		<img className="avatar-img rounded-circle" src={item.patientId.profileImage ? item.patientId.profileImage : "/assets/img/user.png"} alt="User Image" />
																	</a>
																	<a>{item.patientId.name} </a>
																</h2>
															</td>
															<td>{moment(item.slot.start).utc().format('DD/MM/YYYY')}<span class="d-block color-blue">{item.slot && moment(item.slot.start).utc().format('hh:mm a')}-{moment(item.slot.end).utc().format('hh:mm a')}</span></td>
															
															<td>{renderAppointmentAmount(item)} </td>
                                                            <td>{item.appointmentType === 0 ? 
                                                                'Online pay' : 'In Cash'} </td>
															<td><span className={getStatusClass(item.status)}>{getStatus(item.status)}</span></td>
															{/* 	<td><span className="badge badge-pill bg-danger-light">Cancelled</span></td> */}
															{/* <td><span className="badge badge-pill bg-warning-light">Pending</span></td> */}
															<td>
																<div className="table-action">
																	<Link to={`/subuser/view-appointment/${item?._id}/${item?.patientId?._id}`} className="btn btn-sm bg-info-light-blue">
																		<i className="far fa-eye"></i> View
																	</Link>
					
																	
																</div>
															</td>
														</tr>
										})
									}
								</TableComponent>
								{appointment.length > 0 && <Pagination items={appointment} onChangePage={pageChangeAppointment} />}
							
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubAdminApointments
