import React from "react";
import { Route } from "react-router-dom";

import Footer from "../components/Footer";
import HeaderComponent from "../components/Header";

const PublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => (
                <>
                    {rest.name.header && <HeaderComponent {...props} />}
                    <Component {...props} />
                    {rest.name.footer && <Footer {...props} />}
                </>
            )}
        />
    );
};

export default PublicRoute;