import React, { useContext, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import moment from 'moment';

import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getDashboardAppointmentAPI, getAllPatient
} from '../../../../api/subadmin';
import { getPatientInfo, getWardRoomAPI, getWardRoomPatients, getDoctors, getSubAdminByIdAPI } from '../../../../api/organization';

import {
	SET_ERROR,
	SET_COMMON_LOADING,
	SET_WARD_PATIENT_DETAILS
} from '../../../../store/types';

import CardComponent from '../../../../components/Card';
import { globalValidator } from '../../../../utils';

import InsideMenuBar from '../../../../components/InsideMenus';
import './index.css';
import AppointmentsBox from '../../../../components/Appointments';
import SubAdminAddPatient from '../Patient/AddPatient';
import PatientFlow from '../../../../components/PatientFlow';
import PatientSelectModal from '../../../../components/PatientFlow/PatientSelectModal';
import SelectField from '../../../../components/SelectField';

const options = [
	{ id: 0, label: 'Patient ID', value: '_id' },
	{ id: 1, label: 'Patient Name', value: 'name' },
	{ id: 2, label: 'Status', value: 'status' },
	{ id: 3, label: 'Appointment Date', value: 'appt_date' },
	{ id: 4, label: 'Patient Email', value: 'email' },
	{ id: 5, label: 'Contact number', value: 'phoneNumber' }
]

const statusOptions = [
	{ id: 0, label: 'Pending', value: '0' },
	{ id: 1, label: 'Confirm', value: '1' },
	{ id: 2, label: 'Rejected by you', value: '2' },
	{ id: 3, label: 'Rejected by Patient', value: '3' },
	{ id: 4, label: 'Completed', value: '4' },
]

const filterOptions = [
	{ id: 1, label: 'Name', value: 'name' },
	{ id: 4, label: 'Contact Number', value: 'phoneNumber' },
	{ id: 2, label: 'Email', value: 'email' },
	{ id: 0, label: 'Id', value: '_id' },
]

function SubAdminDashbaord(props) {
	const [state, dispatch] = useContext(Context);
	const [subUserInfo, setSubUserInfo] = React.useState({});
	const [currentTab, SetCurrentTab] = React.useState(1);
	const [search, setSearch] = React.useState('');
	const [searchDoctor,setSearchDoctor] = React.useState('')
	const [selectValue, setSelectValue] = React.useState('name');
	const [wardRoomList, setWardRoomList] = React.useState([]);
	const [todayAppointment, setTodayAppointment] = React.useState([]);
	const [upcomingAppointment, setUpcomingAppointment] = React.useState([]);
	const [wardRoomPatients, setWardRoomPatients] = React.useState([]);
	const [patients, setPatients] = React.useState([]);
	const [doctors, setDoctors] = React.useState([]);
	const [modalVisible,setModalVisible] = React.useState(false);
    const [departments, setDepartments] = React.useState([])
	const [showCheckoutModal, setShowCheckoutModal] = useState(false);
	const [createNewPatientModal, setCreateNewPatientModal] = useState(false);
	const [searchPatientModal, setSearchPatientModal] = useState(false);
	const [addingPatient, setAddingPatient] = useState(false);
	const [doctorSelectValue,setDoctorSelectValue] = useState('name');
	const permissions = JSON.parse(localStorage.getItem('subUserPermission'))

	useEffect(() => {
		const data = JSON.parse(localStorage.getItem('subuser'))
		getSubAdminByIdAPI(data._id).then((res) => {
			setSubUserInfo(res.data);
			localStorage.setItem('subUserPermission', JSON.stringify(res.data.permission))
			localStorage.setItem('subUserDesignation', JSON.stringify(res.data.designation))
		}).catch((err) => {
			setSubUserInfo(data);
		})
		loader(true)
		getWardRoompatients();
		getAppointmentData();
		getWardRooms();
		getPatients();
		getDoctorsListing();
	}, [])

	const getDoctorsListing = () => {
		loader(true);
		getDoctors({}).then(res => {
			setDoctors(res.data)
			setDepartments([...new Set(res.data.map((item)=>item.department))])
			loader(false);
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			setDepartments([]);
			loader(false);
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const getPatients = () => {
		loader(true);
		getAllPatient({}).then(res => {
			setPatients(res.data);
			loader(false);
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			setPatients([]);
			loader(false);
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const getWardRoompatients = () => {
		getWardRoomPatients().then(res => {
			let filterData = (res.data && res.data.length) ? res.data.filter((item) => !item.dischargeByDoctor || !item.dischargeByFinancial) : []
			setWardRoomPatients(filterData);
			loader(false)
		}).catch(err => {
			loader(false)
			toast.error(err?.response?.data?.error);
		})
	}

	const getWardRooms = () => {
		getWardRoomAPI().then(res => {
			setWardRoomList(res.data)
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
		})
	}

	const getAppointmentData = () => {
		loader(true);
		getDashboardAppointmentAPI().then(res => {
			setTodayAppointment(res.data.todaysAppointments);
			setUpcomingAppointment(res.data.upcomingAppointments);
			loader(false);
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}


	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}
	

	const fetchPatients = (search) => {
		const payload = {
			[selectValue]: search
		}
		getAllPatient(search.length > 0 ? payload : '').then(res => {
			setPatients(res.data);
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			setPatients([]);
			loader(false);
		})
	}

	const fetchDoctors = (search) => {
		const payload = {
			[doctorSelectValue] : search
		}

		getDoctors(search.length > 0 ? payload : '').then(res => {
			setDoctors(res.data)
			setDepartments([...new Set(res.data.map((item)=>item.department))])
			loader(false);
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
		}).catch(err => {
			setDepartments([]);
			loader(false);
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const handleSearch = (e) => {
		// setSearch(e.target.value)
		if (e.key === 'Enter'){
			loader(true);
			fetchPatients(search)
		}
	}

	const handleSearchDoctor = (e) => {
		// setSearch(e.target.value)
		if (e.key === 'Enter'){
			loader(true);
			fetchDoctors(searchDoctor)
		}
	}

	const handleSearchChangeDoctor = (e) => {
		setSearchDoctor(e.target.value)
		if(e.target.value === '') {
			loader(true);
			fetchDoctors('')
		}
	}
	const handleDoctorSearch = () => {
		loader(true);
		fetchDoctors(searchDoctor);
	}

	const handleSearchChange = (e) => {
		setSearch(e.target.value)
		if(e.target.value === '') {
			loader(true);
			fetchPatients('')
		}
	}

	const handleAddPatient = () => {
		setModalVisible(true);
		document.body.style.overflowY = 'hidden';
	}

	const handlePatientSearch = () => {
		loader(true);
		fetchPatients(search);
	}

	const handleClearSearch = () => {
		setSearch('');
		loader(true);
		fetchPatients('')
	}

	const handleClearDoctor = () => {
		setSearchDoctor('');
		loader(true);
		fetchDoctors('')
	}

	const handleBookNewAppointment = (value) => {
		setAddingPatient(value)
		setShowCheckoutModal(true)
		document.body.style.overflowY = 'hidden';
	}

	const handleSelectPatient = (data) => {
		props.history.push(`/subuser/doctor-listing/${data._id}`);
		// handleSelectPatient()
	}

	const handleExistingPatientClick = () => {
		// const [createNewPatientModal, setCreateNewPatientModal] = useState(false);
		// const [searchPatientModal, setSearchPatientModal] = useState(false);
		setShowCheckoutModal(false);
		setSearchPatientModal(true);
	}

	const handleCreateNewPatient = () => {
		setShowCheckoutModal(false);
		setCreateNewPatientModal(true);
	}

	const handleSelectIPDPatient = (data) => {
		dispatch({
			type: SET_WARD_PATIENT_DETAILS,
			payload: { ...data, patientIdE: true }
		});
		props.history.push(`/subuser/ipdward/add-patient/form-details/${data._id}`);
	}

	const handleAddPatientIntoIPD = (data) => {
		dispatch({
			type: SET_WARD_PATIENT_DETAILS,
			payload: { ...data, patientIdE: false }
		});
		props.history.push(`/subuser/ipdward/add-patient/form-details/${data._id}`);
	}
	const selectedVal = filterOptions.find((item) => item.value === selectValue)?.label 
	const selectedDocVal = filterOptions.find((item) => item.value === doctorSelectValue)?.label 
	return (
		<div>
			<InsideMenuBar
				props={props}
				type={'subadmin'}
			/>
			<div className="content">
				<div className="container-fluid" style={{padding:"15px"}}>
					<div className="row">

						<div className="col-xl-8 col-lg-12 col-md-8 col-sm-12">
							<div className="row">
								<div class="col-xl-4 col-lg-4 col-md-4 col-xs-12">

									<CardComponent className="mb-4 appointments-chart-card">
										<div className="widget-profile pro-widget-content">
											<div className="profile-info-widget">
												<div className="profile-info-widget-setting" onClick={() => props.history.push('/subuser/settings')}>
													<i className="fas fa-user-cog menubaricon"></i>
												</div>
												<a className="booking-doc-img">
													<img src={subUserInfo && subUserInfo.profileImage ? subUserInfo.profileImage : '/assets/img/loading-img.jpg'} alt="User Profile" />
												</a>
												<div className="profile-det-info">
													<h3>{subUserInfo ? subUserInfo.name : '-'}</h3>
													<div className="patient-details">
														<h5 className="mb-0"><i className="fas fa-hospital-alt"></i> {subUserInfo.org_name ? subUserInfo.org_name : '-'}</h5>
														<h5><i className="fas fa-window-restore"></i> {subUserInfo.designation ? subUserInfo.designation : '-'}</h5>
														<h5>Id : {subUserInfo._id ? subUserInfo._id : '-'}</h5>
													</div>
												</div>
											</div>
											{ permissions?.includes("Admin") || permissions?.includes("OPD") ? <div style={{ margin: "0px 10px", width: "100%", backgroundColor: "#86efd3", borderColor: "#86efd3" }} className="btn-patient-primary submit-btn p-2 pr-4 mt-1 mb-1 ml-auto button-text-white btn-patient-light" onClick={()=>handleBookNewAppointment(false)}>Book new appointment</div> : null }
											{ permissions?.includes("Admin") || permissions?.includes("Admit a patient into IPD") ? <div style={{ width: "100%", backgroundColor: "#86efd3", borderColor: "#86efd3" }} className="btn-patient-primary submit-btn p-2 pr-4 mt-3 mb-3 mb-1 ml-auto button-text-white btn-patient-light" onClick={()=>handleBookNewAppointment(true)}>Admit patient into IPD</div> : null }
											{/* <div style={{ margin: "0px 10px", width: "100%", backgroundColor: "#86efd3", borderColor: "#86efd3" }} className="btn-patient-primary submit-btn p-2 pr-4 mt-3 mb-3 ml-auto button-text-white btn-patient-light" onClick={() => props.history.push('/subuser/settings')}>Change your password</div> */}
										</div>
									</CardComponent>


								</div>
								<div className="col-xl-8 col-lg-8 col-md-8 col-xs-12">
									<div className="row">
										<div className="col-xl-6 pl-0 col-lg-6 col-md-6 col-xs-12">
											<AppointmentsBox
												singleCard={true}
												todayAppointment={todayAppointment}
												upcomingAppointment={upcomingAppointment} 
												height={"305px"}
												showTotal={true}
											/>
										</div>
										<div className="col-xl-6 col-lg-6 p-0 col-md-6 col-xs-12">
											<CardComponent className="mb-4 current-vitals-chart-card">
												<div className="d-flex row ml-1 current-vitals-chart-card-heading">
													<h5 className="pt-3 pl-3">Current inpatients</h5>
												</div>
												<hr />
												<ul className="list-unstyled p-3" style={{ height: "305px", overflow: "auto" }} >
													{
														wardRoomPatients.length ? wardRoomPatients && wardRoomPatients.map((item) => (
															<li className="media received d-flex m-0 mb-1 row current-vitals-chart-card-li" style={{ alignItems: "center" }}>
																<div class="media mt-0">
																	<img
																		className="avatar-lg rounded-circle"
																		src={item?.patient?.profileImage || "/assets/img/loading-img.jpg"}
																		alt="User"
																	/>
																	<div class="media-body">
																		<div class="d-flex align-items-center">
																			<div class="mt-0">
																				<h5 style={{wordBreak: 'break-all'}} class="mb-1 tx-13 font-weight-sembold text-dark">
																					{item.patient.name}
																				</h5>
																				<p class="mb-0 tx-12 text-muted">Ward no: {item.roomWard.roomNo} ({item.roomWard.roomType})</p>
																			</div>
																			<span class="ml-auto">
																				<span class="float-end badge badge-success-transparent" onClick={() => props.history.push(`/subuser/view-patient/${item._id}/${item.patient._id}`)}>
																					View
																				</span>
																				{/* <Link to={`/subuser/view-patient/${item._id}/${item.patient._id}`} className="float-end badge badge-success-transparent">
																					View
																				</Link> */}
																			</span>
																		</div>
																	</div>
																</div>
															</li>
														))
															: <div className="d-flex justify-content-center" style={{ marginTop: "15%", textAlign: "center", padding: "20px" }}>Sorry! There are no Patients to display</div>
													}
												</ul>
												<div className="d-flex ml-1 mr-3 mt-2 mb-2">
													<h5>
														Total patients: {wardRoomPatients ? wardRoomPatients.length : 0}
													</h5>
												</div>
											</CardComponent>
										</div>
										
									</div>
								</div>
								<div className="col-md-12 col-xl-12 pr-0 col-xs-12 col-sm-12">
									<CardComponent className="mb-4 appointments-chart-card">
										<div className="d-flex row ml-1 current-vitals-chart-card-heading">
											<h5 className="pt-3 pl-3">IPD wards</h5>
										</div>
										<hr />
										<div className="row ipd-room-view scroll-bar" style={{margin:"0px",height:"665px",overflow:"scroll"}}>
											<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
												<div class="d-flex row p-3" style={{ "text-align": "center" }}><div class="col-md-4"><i class="fas fa-square" style={{ "color": "rgba(95, 239, 84, 0.59)" }}></i>  Available</div><div class="col-md-4"><i class="fas fa-square" style={{ "color": "rgba(255, 151, 184, 0.57)" }}></i>  Occupied</div><div class="col-md-4"><i class="fas fa-square" style={{ "color": "rgba(92, 76, 76, 0.7)" }}></i>  Not in service</div></div>
											</div>
											<div className="wardRoomList d-flex flex-wrap mt-2">
												{
													wardRoomList && wardRoomList.map((data, id) =>
														<div className="col-md-3 col room-view mt-2">
															<i className={`fas fa-clinic-medical room-view-icon room-view-status-${data.status}`}></i>
															<p>{data.roomNo} {data.roomType ? `(${data.roomType})` : ''}</p>
														</div>
													)
												}
											</div>
										</div>
									</CardComponent>
								</div>

								
							</div>
						</div>

						<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
							<div className="row">
							

								<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
									<CardComponent className="mb-4">
										<div className="d-flex col-md current-vitals-chart-card-heading">
											<h5 className="pt-3">Patients</h5>
											<div
												className="btn btn-primary submit-btn ml-auto patient-emed4u-sml-btn"
												style={{
													marginRight: "0.25rem",
													backgroundColor: "#5156be",
													border: "1px solid #5156be",
													display: "flex",
													alignItems: "center",
													marginTop: "10px"
												}}
												onClick={handleAddPatient}
											>
												Add patient
											</div>
										</div>
										<div className="d-flex col-md mt-2 current-vitals-chart-card-heading input-search-icons">
											<SelectField
												className="select-field"
												options={filterOptions}
												placeholder={'Select status'}
												onChange={(e) => {
													setSearch('')
													setSelectValue(e.target.value)
													if(search){
														loader(true);
														fetchPatients('')
													}
												}
												}
												value={selectValue}
											/>
												<input
													value={search}
													placeholder={`Search patient ${selectedVal ? selectedVal : 'Id'}`}
													onChange={handleSearchChange}
													onKeyPress={handleSearch}
													style={{width:"100%"}}
												/>
											{search.length ? <i
												onClick={handleClearSearch}
												class="fa fa-times cancel-icon-input" aria-hidden="true">
											</i> : null}

											<i
												onClick={handlePatientSearch} className="fa fa-search patient-listing-search-icon search-icon-doctor" />
										</div>

										<hr />
										<ul className="list-unstyled p-3" style={{ height: "420px", overflow: "scroll" }} >
											{
												patients.length ? patients.map(item => {
													return <li className="media received d-flex m-0 mb-2 row current-vitals-chart-card-li" style={{ alignItems: "center" }}>
														<div class="media mt-0">
															<img
																className="avatar-lg rounded-circle"
																src={item?.profileImage || "/assets/img/loading-img.jpg"}
																alt="User"
															/>
															<div class="media-body">
																<div class="d-flex align-items-center">
																	<div class="mt-0">
																		<h5 style={{wordBreak: 'break-all'}} class="mb-1 tx-13 font-weight-sembold text-dark">
																			{item.name}
																		</h5>
																		<p class="mb-0 tx-12 text-muted">Id: {item._id}</p>
																	</div>
																	<span class="ml-auto">
																		<span class="float-end badge badge-success-transparent" onClick={() => props.history.push(`/subuser/patient/${item._id}`)}>
																			View
																		</span>
																	</span>
																</div>
															</div>
														</div>
													</li>
												})
													: <div className="d-flex justify-content-center" style={{ marginTop: "21%", textAlign: "center", padding: "20px" }}>Sorry! There are no Patients to display</div>

											}


										</ul>

										<div className="d-flex ml-3 mr-3 mt-2 mb-2">
											<h5>
												Total patients: {patients ? patients.length : 0}
											</h5>
											<span class="ml-auto">
												<span class="float-end badge badge-success-transparent-outline" onClick={()=>props.history.push('/subuser/patient-listing')}>
													View all
												</span>
											</span>
										</div>
										{
											modalVisible &&
											<SubAdminAddPatient
												setModalVisible={setModalVisible}
												getPatients={getPatients}
											/>
										}
									</CardComponent>
								</div>

								<div className="col-xl-12 col-lg-12 col-md-12 col-xs-12">
									<CardComponent className="mb-4">
										<div className="d-flex col-md current-vitals-chart-card-heading">
											<h5 className="pt-3">Doctors</h5>
										</div>
										<div className="d-flex col-md mt-2 current-vitals-chart-card-heading input-search-icons">
											<SelectField
												className="select-field"
												options={filterOptions}
												placeholder={'Select status'}
												onChange={(e) => {
													setSearchDoctor('')
													setDoctorSelectValue(e.target.value)
													if(searchDoctor){
														loader(true);
														fetchDoctors('')
													}
												}
												}
												value={doctorSelectValue}
											/>
												<input
													value={searchDoctor}
													placeholder={`Search doctor ${selectedDocVal ? selectedDocVal : 'Id'}`}
													onChange={handleSearchChangeDoctor}
													onKeyPress={handleSearchDoctor}
													style={{width:"100%"}}
												/>
											{searchDoctor.length ? <i
												onClick={handleClearDoctor}
												class="fa fa-times cancel-icon-input" aria-hidden="true">
											</i> : null}

											<i
												onClick={handleDoctorSearch} className="fa fa-search patient-listing-search-icon search-icon-doctor" />
										</div>

										<hr />
										<ul className="list-unstyled p-3" style={{ height: "350px", overflow: "scroll" }} >
											{departments.length ? departments.map((dept) => (
												<div>
													<h4>{dept}</h4>
													{
														doctors.length && doctors.map(item => {
															if (item.department === dept) {
																return (<li className="media received d-flex m-0 mb-2 row current-vitals-chart-card-li" style={{ alignItems: "center" }}>
																	<div class="media mt-0">
																		<img
																			className="avatar-lg rounded-circle"
																			src={item?.profileImage || "/assets/img/loading-img.jpg"}
																			alt="User"
																		/>
																		<div class="media-body">
																			<div class="d-flex align-items-center">
																				<div class="mt-0">
																					<h5 style={{wordBreak: 'break-all'}} class="mb-1 tx-13 font-weight-sembold text-dark">
																						{item.name}
																					</h5>
																					<p class="mb-0 tx-12 text-muted">Id: {item._id}</p>
																				</div>
																				<span class="ml-auto">
																					<span
																						class="float-end badge badge-success-transparent"
																						onClick={() => {
																							props.history.push(`/subuser/doctor/${item._id}`,{
																								wardRoomPatients: wardRoomPatients,
																								todayAppointment: todayAppointment,
																								upcomingAppointment: upcomingAppointment
																							})
																						}}
																					>
																						View
																					</span>
																				</span>
																			</div>
																		</div>
																	</div>
																</li>
																)
															}
														})
													}
													<hr />
												</div>
											)) :
												< div className="d-flex justify-content-center" style={{ marginTop: "21%", textAlign: "center", padding: "20px" }}>Sorry! There are no Doctors to display</div>
											}
										</ul>

										<div className="d-flex ml-3 mr-3 mt-2 mb-2">
											<h5>
												Total doctors: {doctors ? doctors.length : 0}
											</h5>
											<span class="ml-auto">
												<span class="float-end badge badge-success-transparent-outline" onClick={()=>props.history.push('/subuser/doctor-listing')}>
													View all
												</span>
											</span>
										</div>
										{/* {
											modalVisible &&
											<SubAdminAddPatient
												setModalVisible={setModalVisible}
												getPatients={getPatients}
											/>
										} */}
									</CardComponent>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				showCheckoutModal && 
				<PatientSelectModal
					setShowModal={setShowCheckoutModal}
					handleExistingPatientClick={handleExistingPatientClick}
					handleCreateNewPatient={handleCreateNewPatient}
				/>
			}
			{
				createNewPatientModal &&
				<SubAdminAddPatient
					setModalVisible={setCreateNewPatientModal}
					isPatientFlow={true}
					handleProcced={addingPatient ? handleAddPatientIntoIPD :  handleSelectPatient}
				/>
			}
			{
                searchPatientModal &&
               <PatientFlow
			   	addingPatient={addingPatient}
				setModalVisible={setSearchPatientModal}
				handleProcced={addingPatient  ? handleSelectIPDPatient : handleSelectPatient}
				/>
            }
		</div>
	)
}

export default SubAdminDashbaord
