import { CircularProgress, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import React, { useState } from "react";
import { useStyles } from "../../assets/styles/BloodSugarStyles";
import { StyledButton, StyledCrossIcon, StyledFormik } from "../../assets/styles/CommonStyles";
import strings from "../../constant/strings";
import { respiratoryRateSchema } from "../../utils/forms/schema.respiratoryRateSchema";
import FieldWrapper from "../InputField/TextFieldWrapper";
import * as mainService from "../../services/mainService.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";


const RespiratoryRate = ({ setOpenModal, getUpdatedValue }) => {
  const classes = useStyles();
  let initialValues= {
    respiratoryRate: "",
    note: ""
  }
  const [buttonLoading, setButtonLoading] = useState(false);
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData
  }));

  const handleSubmit = async (values, {resetForm}) => {
    const res = await mainService.addRespiratoryRateApi({
      respiratory_rates: values.breathPerMin, notes: values.note, token: userData.token
    });
    if (res.response_type === "fail") {
      setButtonLoading(false);
      toast.error(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
    } else {
      resetForm();
      setOpenModal(false);
      getUpdatedValue(true);
      setButtonLoading(false);
      toast.success(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
    }
  }
  return (
    <div>
      <Grid className={classes.secondayCont}>
        <h2 className={classes.mainCont}>{strings.respiratoryRate}</h2>
        <StyledCrossIcon
          src={"/assets/img/x.png"}
          alt="User"
          onClick={() => setOpenModal(false)}
        />

      </Grid>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={respiratoryRateSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleChange,
            errors,
            handleSubmit,
          }) => (
            <StyledFormik>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FieldWrapper
                    name="breathPerMin"
                    label={`${strings.breathPerMin}`}
                    type="number"
                    onChange={(e) => {
                      handleChange("breathPerMin")(e);
                    }}
                  />

                </Grid>
                <Grid item xs={12}>
                  <FieldWrapper
                    name="note"
                    label={`${strings.note}`}
                    type="text"
                    onChange={(e) => {
                      handleChange("note")(e);
                    }}
                  />
                </Grid>
              </Grid>
              <Box m={1} pt={1}>
                <StyledButton
                  name="submit"
                  disabled={Object.keys(errors).length > 0 || buttonLoading}
                  onClick={handleSubmit}
                  variant="contained">
                  {buttonLoading ? (
                    <CircularProgress
                      style={{ color: "white" }}
                      size={20}
                      thickness={5}
                    />
                  ) : `${strings.save}`}

                </StyledButton>
              </Box>
            </StyledFormik>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default RespiratoryRate;