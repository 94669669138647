import React from "react";
import { makeStyles } from '@mui/styles';
import { StyledLogo } from "../../assets/styles/CommonStyles";

const useStyles = makeStyles(() => ({
    mainStyle: {
        width: '100%',
        justifyContent: "center",
        display: "flex",
        alignItems: "center"
    }
}))

const LogoIcon = () => {
    const classes = useStyles();
    return (
        <div className={classes.mainStyle}>
            <StyledLogo
                src={"/assets/img/image1.png"}
                alt="User"
            />
        </div>
    )
}

export default LogoIcon;