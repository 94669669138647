import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import colors from "../../constant/colors";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabComponent({ tabs, tabValue, handleTab, customTab, customTabIndicator }) {
  
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: "1.2px solid #F5F5F7" }}>
        <Tabs
          value={tabValue}
          onChange={handleTab}
          aria-label="basic tabs example"
         classes = {{ indicator: customTabIndicator} }
          TabIndicatorProps={{
            style: {
              backgroundColor: colors.primary
            }
          }}
        >
          {tabs?.map((tabItem) => (
           
            <Tab label={tabItem?.name} classes = {{ root:  customTab }}  {...a11yProps(tabItem?.id)} />
          ))}
        </Tabs>
      </Box>
      {/* {tabs?.map((tabItem) => (
        <TabPanel value={tabValue} index={0}>
         {tabItem?.name} 
        </TabPanel>
      ))} */}
    </Box>
  );
}
