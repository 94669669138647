import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    error: null,
    currencies: [],
  };

  const publicSlice = createSlice({
    name: 'public',
    initialState,
    reducers: {
      getCurrencyRequest(state) {
        state.currencies = [];
        state.loading = true;
        state.error = null;
      },
      getCurrencySuccess(state, { payload }) {
        state.currencies = payload;
        state.loading = false;
        state.error = null;
      },
      getCurrencyFailure(state, { payload }) {
        state.currencies = [];
        state.loading = false;
        state.error = payload;
      },

           

    },
  });
  
  export const { getCurrencyRequest, getCurrencySuccess, getCurrencyFailure } = publicSlice.actions;
  
  export default publicSlice.reducer;