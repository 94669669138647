import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import defaultUserPng from "../../../../src/assets/img/user.png";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import {
  StyledCrossIcon,
  StyledHeadingText01,
  UserImage,
} from "../../../assets/styles/CommonStyles";
import { profileStyles } from "../../../assets/styles/DoctorProfileSettingStyles";
import { useStyles } from "../../../assets/styles/PatientDashboardStyles";
import strings from "../../../constant/strings";
import TabComponent from "../../Tab";
import UploadFile from "../../controls/UploadFile";
import {
  updateProfile,
  updateProfileSubadmin,
} from "../../../services/authService";
import { toast } from "react-toastify";
import { patientProfile } from "../../../redux/user/userActions";
import { subadminProfileAction } from "../../../redux/user/subadmin/subadminAction";
import { PATIENT_UPLOAD, SUBADMIN_UPLOAD } from "../../../services/apiUrl";
import * as mainService from "../../../services/mainService";

const ProfileSetting = ({ tabValue, setTabValue, tabs, profileHead }) => {
  const dispatch = useDispatch();
  const styleClasses = commonMuiStyles();
  const profileSettingClasses = profileStyles();
  const classes = useStyles();
  const history = useHistory();
  const inputRefs = useRef();
  const [image, setImage] = useState();
  const [fileType, setFileType] = useState();
  const { patientProfileData, userData,subadminProfileData } = useSelector(state => ({
    patientProfileData: state.user.patientProfile || {},
    userData: state.auth.userData,
    subadminProfileData: state.subadmin.subadminProfileData,
}))
  const userType = localStorage.getItem("userType");
  const uploadUrl = userType === "patient" ? PATIENT_UPLOAD : SUBADMIN_UPLOAD;

  useEffect(() => {
    if (userType === "subadmin") {
      dispatch(subadminProfileAction(userData.token));
    } else {
      dispatch(patientProfile(userData.token));
    }
  }, [dispatch, userData.token, userType]);

  const handleCross = () => {
    history.goBack();
  };

  const handleTab = (e, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (image) {
      handleSubmit();
    }
  }, [fileType]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("file", image);

    try {
      const response = await mainService.fileUpload(
        uploadUrl,
        formData,
        userData.token
      );

      const updatedProfileImageUrl = response?.response?.data;
      if (image) {
        await handleSubmitUpdate(updatedProfileImageUrl);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmitUpdate = async (profileImageUrl) => {
    const profileImgData =
      userType === "patient"
        ? { profileImage: profileImageUrl }
        : { profile_image: profileImageUrl };

    const updateService =
      userType === "patient" ? updateProfile : updateProfileSubadmin;

    try {
      const res = await updateService({
        token: userData.token,
        data: profileImgData,
      });

      if (res.response_type === "fail") {
        toast.error(res?.response?.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 2000,
          style: { width: "auto", borderRadius: 8 },
        });
      } else {
        toast.success("Profile updated successfully.", {
          position: "bottom-center",
          closeButton: false,
          autoClose: 2000,
          style: { width: "auto", borderRadius: 8 },
        });

        // Refreshing the profile data
        if (userType === "subadmin") {
          dispatch(subadminProfileAction(userData.token));
        } else {
          dispatch(patientProfile(userData.token));
        }

        // Refreshing the page to reflect updates
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        className={`${classes.mainCont} ${profileSettingClasses.mainSecCont}`}
      >
        <StyledHeadingText01>{profileHead}</StyledHeadingText01>
        <StyledCrossIcon
          src={"/assets/img/x.png"}
          alt="x"
          onClick={handleCross}
        />
      </Grid>
      <Grid item xs={12} className={profileSettingClasses.imageCont}>
        <div>
          <IconButton
            sx={{
              position: "absolute",
              right: "calc(50% - 55px)",
            }}
          >
            <BorderColorIcon onClick={() => inputRefs.current.click()} />
          </IconButton>

          <UploadFile
            inputRefs={inputRefs}
            image={image}
            setImage={setImage}
            fileType={fileType}
            setFileType={setFileType}
            limit={5}
            navigate="single"
          />
          <UserImage
            src={patientProfileData?.profileImage || subadminProfileData?.profile_image || defaultUserPng}
            className={profileSettingClasses.imageStyle}
            alt="Profile Picture"
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TabComponent
          tabs={tabs}
          tabValue={tabValue}
          setTabValue={setTabValue}
          handleTab={handleTab}
          customTab={styleClasses.customTab}
          customTabIndicator={styleClasses.customTabIndicator}
        />
      </Grid>
    </Grid>
  );
};

export default ProfileSetting;
