import React from 'react';

import { Link } from 'react-router-dom';

import {
	StyledContainer
} from './styles';

function SuccessMsgAfterRegister() {
	return (
		<StyledContainer className="content success-page-cont">
				<div class="container-fluid">
				
					<div class="row justify-content-center">
						<div class="col-lg-6">
						
							<div class="card success-card">
								<div class="card-body">
									<div class="success-cont">
										<i class="fas fa-check"></i>
										<h3>You have successfully added an account with us.!</h3>
										<p>Our team will verify your profile and contact you at the earliest.</p>
										<Link to="/" class="btn btn-primary view-inv-btn">Go back to home</Link>
									</div>
								</div>
							</div>
							
						</div>
					</div>
					
				</div>
		</StyledContainer>
	)
}

export default SuccessMsgAfterRegister
