import React from 'react';
import CardComponent from '../Card';

function MedicalHistory(props) {
	const { hideMedication, hideHealthCondition, hideSurgery, hideAllergies, hideVaccine, collapseShow} =  props;
	return (
		<CardComponent className="pl-3 pr-3">
			{!hideMedication ?
				<div className="accordion-card-appt pb-2" style={{ border: "none", borderBottom: "1px solid #e1e0e0" }}>
						<div id="headingSix">
							<h5 className="col-md mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseMedicationOne" aria-expanded="true" aria-controls="collapseMedicationOne">
									<h4 className="ml-0">Medications</h4>
									<button className="accordion-viw-apt">
										<i className="fa fa-chevron-down" />
									</button>
								</button>
							</h5>
						</div>

						<div id="collapseMedicationOne" className={collapseShow ? "collapse show" : "collapse"} aria-labelledby="headingSix" data-parent="#accordionExample">
							<div className="accordion view-appt-de-accordion" id="accordionExample">
								<div className="col-md-12">
									{
										props.medicalHistory.healthConditionData && props.medicalHistory.healthConditionData.map((item, index) => {
											return <CardComponent className="p-4 mt-2"><div className="row" style={{ marginBottom: '10px' }}>
											<div className="col-md-6">
		
												<ul className="booking-date bor-right-1">
													<li>Medication name <span>{item.name}</span></li>
													<li>Quantity <span>{item.quantity}</span></li>
													<li>Duration (Start on) <span>{item.start_date}</span></li>
													<li>Prescribed by <span>{item.by && item.by}</span></li>
													<li>Other instructions <span>{item.instructions}</span></li>
												</ul>
		
											</div>
		
											<div className="col-md-6">
		
												<ul className="booking-date">
													<li>Dosage<span>{item.method_intake} ({item.method_intake}) / {item.form_medicine}</span></li>
													<li>When <span>{item.when}</span></li>
													<li>Duration (End on) by <span>{item.end_date}</span></li>
													<li>Notes / Side effects <span>{item.notes}</span></li>
												</ul>
		
											</div>
										</div></CardComponent>
										})
									}

									{
										props.medicalHistory.healthConditionData.length === 0 && <h5 className="pl-5 pt-2" style={{ color: 'red' }}>Sorry! There are no results to display
										</h5>
									}

								</div>
							</div>
						</div>
					</div>
				 :
				null}

			<div className="accordion view-appt-de-accordion" id="accordionExample">
				{!hideHealthCondition ?
					<div className="accordion-card-appt pb-2" style={{ border: "none", borderBottom: "1px solid #e1e0e0", }}>
						<div id="headingSix">
							<h5 className="col-md mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
									<h4 className="ml-0">Health conditions</h4>
									<button className="accordion-viw-apt">
										<i className="fa fa-chevron-down" />
									</button>
								</button>
							</h5>
						</div>

						<div id="collapseOne" className={collapseShow ? "collapse show" : "collapse"} aria-labelledby="headingSix" data-parent="#accordionExample">
							<div>
								<div className="col-md-12">
									{
										props.medicalHistory.healthConditionData && props.medicalHistory.healthConditionData.map((item, index) => {
											return <CardComponent className="p-4 mt-2"><div className="row" style={{ marginBottom: '10px' }} key={index}>
												<div className="col-md-6">

													<ul className="booking-date bor-right-1">
														<li>Name <span>{item.name}</span></li>
														<li>Status <span>{item.status === 0 ? 'Ongoing' : '-'}</span></li>
														<li>Medication <span>{item.medication}</span></li>
													</ul>

												</div>

												<div className="col-md-6">

													<ul className="booking-date">
														<li>Diagnosed on <span>{item.diagnosed_on}</span></li>
														<li>Treated by <span>{item.treated_by}</span></li>
														<li>Notes <span>{item.notes}</span></li>
													</ul>

												</div>
											</div></CardComponent>
										})
									}

									{
										props.medicalHistory.healthConditionData.length === 0 && <h5 className="pl-5 pt-2" style={{ color: 'red' }}>Sorry! There are no results to display
										</h5>
									}

								</div>
							</div>
						</div>
					</div>
					: null}
				{!hideSurgery ?
					<div className="accordion-card-appt pb-2" style={{ border: "none", borderBottom: "1px solid #e1e0e0" }}>
						<div id="headingSix">
							<h5 className="col-md mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
									<h4 className="ml-0">Surgeries</h4>
									<button className="accordion-viw-apt">
										<i className="fa fa-chevron-down" />
									</button>
								</button>
							</h5>
						</div>

						<div id="collapseSix" className={collapseShow ? "collapse show" : "collapse"}  aria-labelledby="headingSix" data-parent="#accordionExample">
							<div>
								<div className="col-md-12">
									{
										props.medicalHistory.surgeriesData && props.medicalHistory.surgeriesData.map(item => {
											return <CardComponent className="p-4 mt-2"><div className="row" style={{ borderBottom: '1px solid #ccc', marginBottom: '10px' }}>
												<div className="col-md-6">

													<ul className="booking-date bor-right-1">
														<li>Name <span>{item.name}</span></li>
														<li>Date <span>{item.date}</span></li>
														<li>Notes <span>{item.notes}</span></li>
													</ul>

												</div>

												<div className="col-md-6">

													<ul className="booking-date">
														<li>Implants / Support devices<span>{item.implants}</span></li>
														<li>Treated by <span>{item.by}</span></li>
													</ul>

												</div>
											</div></CardComponent>
										})
									}

									{
										props.medicalHistory.surgeriesData.length === 0 && <h5 className="pl-5 pt-2" style={{ color: 'red' }}>Sorry! There are no results to display
										</h5>
									}

								</div>
							</div>
						</div>
					</div>
					: null}
				{!hideAllergies ?
					<div className="accordion-card-appt pb-2" style={{ border: "none", borderBottom: "1px solid #e1e0e0" }}>
						<div id="headingSix">
							<h5 className="col-md mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
									<h4 className="ml-0">Allergies</h4>
									<button className="accordion-viw-apt">
										<i className="fa fa-chevron-down" />
									</button>
								</button>
							</h5>
						</div>

						<div id="collapseTwo" className={collapseShow ? "collapse show" : "collapse"}  aria-labelledby="headingSix" data-parent="#accordionExample">
							<div>
								<div className="col-md-12">
									{
										props.medicalHistory.allergieData && props.medicalHistory.allergieData.map(item => {
											return <CardComponent className="p-4 mt-2"><div className="row" style={{ marginBottom: '10px' }}>
												<div className="col-md-6">

													<ul className="booking-date bor-right-1">
														<li>Name <span>{item.name}</span></li>
														<li>Reaction <span>{item.reaction}</span></li>
														<li>First diagnosed on <span>{item.first_diagnosed}</span></li>
														<li>Notes <span>{item.notes}</span></li>
													</ul>

												</div>

												<div className="col-md-6">

													<ul className="booking-date">
														<li>Triggered by <span>{item.triggered_by}</span></li>
														<li>How often does it occur <span>{item.how_often_does}</span></li>
														<li>Medication <span>{item.medication}</span></li>
													</ul>

												</div>
											</div></CardComponent>
										})
									}

									{
										props.medicalHistory.allergieData.length === 0 && <h5 className="pl-5 pt-2" style={{ color: 'red' }}>Sorry! There are no results to display
										</h5>
									}

								</div>
							</div>
						</div>
					</div>
					: null}

				{!hideVaccine ?
					<div className="accordion-card-appt pb-2" style={{ border: "none" }}>
						<div id="headingOne">
							<h5 className="col-md mb-0">
								<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
									<h4 className="ml-0">Vaccinations</h4>
									<button className="accordion-viw-apt">
										<i className="fa fa-chevron-down" />
									</button>
								</button>
							</h5>
						</div>
						<div id="collapseThree" className={collapseShow ? "collapse show" : "collapse"}  aria-labelledby="headingOne" data-parent="#accordionExample">
							<div>
								<div className="col-md-12">

									{
										props.medicalHistory.vaccinationData && props.medicalHistory.vaccinationData.map(item => {
											return <CardComponent className="p-4 mt-2"><div className="row" style={{ marginBottom: '10px' }}>
												<div className="col-md-6">

													<ul className="booking-date bor-right-1">
														<li>Vaccination for <span>{item.for}</span></li>
														<li>Vaccine name <span>{item.vaccine_name}</span></li>
														<li>Lot number <span>#{item.lot_number}</span></li>
													</ul>

												</div>

												<div className="col-md-6">

													<ul className="booking-date">
														<li>Taken on <span>{item.taken_on}	</span></li>
														<li>Vaccine details <span>{item.vaccine_details}</span></li>
														<li>Notes <span>{item.notes}</span></li>
													</ul>

												</div>

											</div></CardComponent>
										})
									}

									{
										props.medicalHistory.vaccinationData.length === 0 && <h5 className="pl-5 pt-2" style={{ color: 'red' }}>Sorry! There are no results to display
										</h5>
									}

								</div>
							</div>
						</div>
					</div>
					: null}

			</div>
		</CardComponent>
	)
}

export default MedicalHistory