import React from 'react';

import {
	StyledLatestBlog
} from './styles'

function LatestBlog() {
	return (
		<StyledLatestBlog className="card post-widget">
			<div className="card-header">
				<h4 className="card-title">Latest Posts</h4>
			</div>
			<div className="card-body">
				<ul className="latest-posts">
					<li>
						<div className="post-thumb">
							<a href="">
								<img className="img-fluid" src="/assets/img/blog/blog-thumb-01.jpg" alt="" />
							</a>
						</div>
						<div className="post-info">
							<h4>
								<a href="">Emed4u – Making your clinic painless visit?</a>
							</h4>
							<p>4 Dec 2020</p>
						</div>
					</li>
					<li>
						<div className="post-thumb">
							<a href="">
								<img className="img-fluid" src="/assets/img/blog/blog-thumb-02.jpg" alt="" />
							</a>
						</div>
						<div className="post-info">
							<h4>
								<a href="">What are the benefits of Online Doctor Booking?</a>
							</h4>
							<p>3 Dec 2020</p>
						</div>
					</li>
					
				</ul>
			</div>
		</StyledLatestBlog>
	)
}

export default LatestBlog
