import styled from 'styled-components';

export const StyledContainer = styled.div`
    img {
        border-radius: 0px !important;
        height: 100% !important;
    }
    .align-center {
        align-items: center;
    }
`;

export const StyledButton = styled.button`
    background-color: #5156be;
    color: #fff;
    height: 48px;
    border: 1px solid #5156be;
    margin-top: 20px;
    padding: 12px 25px;
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
    margin-left: auto;
`;