import React from 'react';

import {
  StyledContainer
} from './styles';

function Card(props) {
  return (
    <StyledContainer className={props.className} id={props.id ? props.id : ''}>
      {props.children}
    </StyledContainer>
  )
}

export default Card
