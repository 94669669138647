import React, { useEffect } from 'react'
import InputSearch from '../../../components/InputSearch';
import { H1Ttitle, H3FAQ, H3SubHeading, QuestionariesHeading, QuestionaryTitle, SectionFAQ, SectionHeading, TextWrapper, Wrapper, WrapperInput } from './styles';
import { SingleQuestion } from './utility';

const Question = 'What is an electronic or telemedicine consultation?'
const Answer = 'An electronic or telemedicine consultation is a medical review which involves remote communication between patients and clinicians which can include medical specialists or allied health professionals. In some common medical conditions telemedicine consultation could prove to be the quickest way to consult a doctor or a health professional and receive a safe and unbiased health advice in the comfort of your home.'

const data = {
  AboutDevices:
  {
    title: 'About Devices',
    questions: [{ question: Question, answer: Answer }, { question: Question, answer: Answer }, { question: Question, answer: Answer }, { question: Question, answer: Answer }, { question: Question, answer: Answer }]
  },
  AboutHMS:
  {
    title: 'About HMS',
    questions: [{ question: Question, answer: Answer }, { question: Question, answer: Answer }, { question: Question, answer: Answer }, { question: Question, answer: Answer }, { question: Question, answer: Answer }, { question: Question, answer: Answer }]
  }
}

const styles = { marginLeft: '5px' }

function MobileAboutPage({ isMobile }) {
  const aboutDevices = data.AboutDevices.title;

  const aboutHMS = data.AboutHMS.title;

  const [initial, changeInitial] = React.useState(aboutHMS);
  const current = aboutDevices === initial ? 'AboutDevices' : 'AboutHMS';
  const [collapsibleData, setCollapsibleData] = React.useState(data[current]?.questions);
  const [search, setSearch] = React.useState('');
  
  const handleCollapsible = (item) => {
    const singleUpdateData = collapsibleData.map((ques) => {
      if (ques.index === item) return { ...ques, open: !ques.open }
      else return ques;
    });
    setCollapsibleData(singleUpdateData);
  }
  useEffect(() => {
    const newData = data[current]?.questions.map((ques, index) => ({ ...ques, open: false, index: index + 1 }));
    setCollapsibleData(newData);
  }, [current])

  const getCollapsibleData = () => {
    return search.length ? collapsibleData.filter((val) => val.question.toLowerCase().includes(search.toLowerCase())) : collapsibleData
  }

  return (
    <Wrapper>
      <SectionHeading>
        <H3FAQ>FAQs</H3FAQ>
        <H1Ttitle className="colored">Frequently Asked <H1Ttitle style={styles}> Questions </H1Ttitle> ?</H1Ttitle>
        <H3SubHeading>Have questions? We’re here to help.</H3SubHeading>
        <WrapperInput>
          <InputSearch isMobile setSearch={(val) => setSearch(val)} search={search}/>
        </WrapperInput>
      </SectionHeading>
      <SectionFAQ>
        <QuestionariesHeading onClick={() => changeInitial(aboutHMS)}>
          {/* <TextWrapper border={aboutDevices === initial}><QuestionaryTitle className={(aboutDevices === initial) && 'colored'} onClick={() => changeInitial(aboutDevices)}>{aboutDevices}</QuestionaryTitle></TextWrapper> */}
          <TextWrapper border={aboutHMS === initial}><QuestionaryTitle className={(aboutHMS === initial) && 'colored'} >{aboutHMS}</QuestionaryTitle></TextWrapper>
        </QuestionariesHeading>
        {getCollapsibleData()?.map(({ question, answer, open, index }) => <SingleQuestion key={index} question={question} answer={answer} condition={open} index={index} setCondition={handleCollapsible} />)}
      </SectionFAQ>

    </Wrapper>
  )
}

export default MobileAboutPage;
