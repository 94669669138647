import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: #ffffff;
  // -webkit-box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.2);
  // box-shadow: 0 10px 55px 5px rgba(137, 173, 255, 0.2);
  // border-radius: 10px;
  -webkit-box-shadow: 0 3px 5px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 3px 5px 1px rgb(0 0 0 / 5%);
    border: 1px solid #EFEFFD;
    -webkit-transition: .5s;
    transition: .5s;
    border-radius: 10px;
`;
