import React, {useContext, useEffect} from 'react';

import moment from 'moment';

import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getAppointments
} from '../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

import {
  INITIAL_EVENTS,
  createEventId
} from './event-utils';

import CardComponent from '../../../../components/Card';

import './calender.css';
import InsideMenuBar from '../../../../components/InsideMenus';


function DoctorCalendar(props) {

	const [state, dispatch] = useContext(Context);

	const [appointment, setAppointment] = React.useState([]);

	useEffect(() => {
		loader(true);
		getAppointments().then(res => {

			const data = [];
			res.data.map(item => {
				data.push({_id: item._id, start: item.slot.start, end: item.slot.end,title: getStatus(item.status), color: getStatusColor(item.status), eventBackgroundColor: 'red'})
				return item
			});

			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
			


			setAppointment(data);
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}, [])

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const getStatus = (item) => {
		if(item === 0) {
			return 'Pending by you'
		} else if(item === 1) {
			return 'Confirm'
		} else if(item === 2) {
			return 'Rejected by you'
		}  else if(item === 3) {
			return 'Rejected by patient'
		}  else if(item === 4) {
			return 'Completed'
		}
	}

	const getStatusColor = (item) => {
		if(item === 0) {
			return '#f39c12'
		} else if(item === 1 || item === 4) {
			return 'green'
		} else if(item === 2 || item === 3) {
			return '#f31212'
		}
	}

	return (
		<div>
			<InsideMenuBar
				props={props}
				type={'doctor'}
			/>
				<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<div className="row mb-3">
									<div className="col-md-4">Pending appointment show -  <b style={{color: '#f39c12', paddingLeft: '10px'}}>Orange color</b></div>
									<div className="col-md-4">Confirm appointment show -  <b style={{color: 'green', paddingLeft: '10px'}}>Green colors</b></div>
									<div className="col-md-4">Reject appointment show -  <b style={{color: '#f31212', paddingLeft: '10px'}}>Red color</b></div>
								</div>
								<hr />
								<FullCalendar
									plugins={[
										dayGridPlugin,
										timeGridPlugin,
										interactionPlugin,
										listPlugin
									]}
									headerToolbar={{
										left: "prev,next today",
										center: "title",
										right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
									}}
									timeZone="UTC"
									initialView="timeGridWeek"
									editable={false}
									selectable={false}
									selectMirror={false}
									dayMaxEvents={false}
									// select={this.handleDateSelect}
									// eventClick={this.handleEventClick}
									initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
									// eventContent={renderEventContent} // custom render function
									// eventResize={this.handleEvents}
									// eventDrop={this.handleEvents}
									eventColor='green'
									events={appointment}
									
								/>
							</CardComponent>
						</div>
					</div>
				</div>
			</div> 
        </div>
    )
}

export default DoctorCalendar
