import React from 'react';

import Breadcrumb from '../../components/Breadcrumb';
import CardComponent from '../../components/Card';

function MobileAppsLand() {
	return (
		<div>
				
			<Breadcrumb
				backRoute={[{id: 1, route: 'Home'}]}
				currentRoute='Provide Better Health Outcomes'
				title='Provide Better Health Outcomes' />

			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-lg-8">
							<CardComponent  className="p-4 mb-4">
								<div className="widget about-widget">
									<h4>Provide Better Health Outcomes – Using Patient Health Record Management (PRM)</h4>
									<p>Using the Patient Health Record Management (PRM), you can know more about the patient’s health reports and comprehensive medical history right at your fingertips. It works on a secure platform, and the right information leads to better decisions about your patient’s health care by accessing their health records.
									</p>
									<br />

									<p>Patient health record Management is a secure way for patients to access and share their health data with you. They can upload medical reports; details of medications prescribed for them, or update health readings using various tools. Use your desktop or mobile phone for video consultations the same way you would interact with patients at your clinic. You will not experience any connectivity issues during the interaction.
									</p>
									<br />

									<p>There is an audio-only mode to resume sessions interrupted by poor connectivity. This way, the session will not disconnect, causing a bad experience. Our Patient health record management (PRM) system provides unlimited online storage for the patients’ health data. All this information is securely accessible to you from anywhere, at any time you need it. It means that you can access this information during clinic consultations and online video consultations too.
									</p>
									<br />

									<p>Make sure to provide your patients with the best health care possible. There are custom care plans to track your patients between visits. Further, it reduces the risk of health complications between clinic visits, thus improves treating them using continuous monitoring. Online health reports and prescriptions for telemedicine sessions are sent to the patients, or they can be printed on your clinic letterhead.
									</p>
									<br />

								</div>
							</CardComponent>
						</div>

						<div className="col-lg-4 features-img mt-5 pt-5">
							<img src="/assets/img/land4.png" className="img-fluid" alt="Feature" />
						</div>

					</div>
				</div>
			</div>

		</div>
    )
}

export default MobileAppsLand
