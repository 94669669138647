import React, { useContext, useState, useEffect } from 'react';
import { Heading, HeadingContent, Wrapper } from './styles';
import { HighLightedContent, InputSearch, ListContent } from './utility';
import {
	getBlogsAPI
} from '../../../api/website';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';

import { Context } from '../../../store';

function BlogList() {
	const [singleBlog, setSingleBlog] = useState([]);
	const [blogs, setBlogs] = useState([]);
	const [state, dispatch] = useContext(Context);

	useEffect(() => {
		loader(true);
		getBlogsAPI().then(res => {
			loader(false);
			setSingleBlog(res.data.length > 0 ? res.data[0] : [])
			setBlogs(res.data);
		}).catch(err => {
			loader(false);
		})
	}, []);

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<Wrapper>
			<HeadingContent>
				<Heading>Blogs</Heading>
				{/* <InputSearch /> */}
			</HeadingContent>
			{singleBlog.length != 0 && <div><HighLightedContent singleBlog={singleBlog} /></div>}
			<div><ListContent blogs={blogs} /> </div>
		</Wrapper>
	)
}

export default BlogList
