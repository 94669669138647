import React, { useContext, useEffect } from 'react';

import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getAllPatient
} from '../../../../api/subadmin';
import InsideMenuBar from '../../../../components/InsideMenus';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';
import './index.css';
import CardComponent from '../../../../components/Card';
import PatientCard from '../../../DoctorPanel/components/PatientCard';
import InputField from '../../../../components/InputField';
import Pagination from '../../../../components/Pagination';
import Search from '../../../../components/Search';
import { globalValidator } from '../../../../utils';
import SubAdminAddPatient from './AddPatient';

const options = [
	{ id: 0, label: 'Patient Id', value: '_id' },
	{ id: 1, label: 'Patient Name', value: 'name' },
	{ id: 3, label: 'Patient Email', value: 'email' },
	{ id: 4, label: 'Contact number', value: 'phoneNumber' }
]

const statusOptions = [
	{id: 0, label: 'Pending',  value: '0'},
	{id: 1, label: 'Confirm', value: '1'},
	{id: 2, label: 'Rejected by you', value: '2'},
	{id: 3, label: 'Rejected by Patient',value: '3'},
	{id: 4, label: 'Completed', value: '4'},
]

function SubAdminPatients(props) {
	const [state, dispatch] = useContext(Context);
	const [patients, setPatients] = React.useState([]);
	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('_id');
	const [pageChangePatients, setPageChangePatients] = React.useState([]);
	const [modalVisible,setModalVisible] = React.useState(false);

	const pageChange  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setPageChangePatients(data);
	}

	useEffect(() => {
		getPatients();
	}, [])

	const getPatients = () => {
		loader(true);
		getAllPatient({}).then(res => {
			setPatients(res.data);
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
		}).catch(err => {
			setPatients([]);
			loader(false);
			// toast.error(err?.response?.data?.error)
			// dispatch({
			// 	type: SET_ERROR,
			// 	payload: err?.response?.data?.error
			// });
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const addPatient = () => {
		// props.history.push('/subuser/add-patient');
		setModalVisible(true);
		document.body.style.overflowY = 'hidden';
	}

	const searchPatient = () => {
		// let valid = ''
		// const keys = [{ name: "email", title: "Email", validateKey: "email" }];
		// valid = globalValidator(search , keys);
		// if (selectValue === 'email' && valid) {
		// 	dispatch({
		// 		type: SET_ERROR,
		// 		payload: valid
		// 	});
		// 	return toast.error(valid)	
		// }

		loader(true);

		const payload = {
			[selectValue]: search
		}
		getAllPatient(search.length > 0 ? payload : '').then(res => {
			setPatients(res.data);
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
		}).catch(err => {
			setPatients([]);
			loader(false);
			// toast.error(err?.response?.data?.error)
			// dispatch({
			// 	type: SET_ERROR,
			// 	payload: err?.response?.data?.error
			// });
		})
	}

	useEffect(() => {
		getPatients();
	}, [selectValue])

	useEffect(() => {
        if(search === '') {
            getPatients();
        }
    }, [search])

	return (
		<div>
			<InsideMenuBar 
				props={props} 
				type={'subadmin'}
				/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
							<CardComponent className="p-4">
							
							<div className="doctor-panel-header">
								<h3 className="mb-3">Patient listing</h3>
								<hr />
								<div className="doctor-panel-filters row col-md-12">
									<Search 
										selectValue={selectValue} 
										setSelectValue={setSelectValue} 
										search={search} 
										setSearch={setSearch} 
										searchPatient={searchPatient}
										options={options}
										statusOptions={statusOptions}
										className = "patient-listing"
									/>
									
									<div className="col-md-2 mt-auto no-padding form-group doctor-active-btn-div">
										<button className="doctor-active-btn" onClick={() => addPatient()}>
											Add patient
										</button>
									</div>
									
								</div>
							</div>

								<div className="row row-grid">
									{
										patients?.length ? pageChangePatients && pageChangePatients.map(item => {
											return <div className="col-md-6 col-lg-4 col-xl-4" key={item._id}>
												<PatientCard
													profileImage={item.profileImage}
													name={item.name}
													_id={item._id}
													address={item.address}
													phoneNumber={item.phoneNumber}
													gender={item.gender}
													bloodGroup={item.bloodGroup}
													status={item.status}
													dob={item.dob}
													totalAppointment={item.count}
													bookAppt={true}
												/>
											</div>
										}) : (
											<div style={{width: '100%'}}>
												<h5 style={{ textAlign: "center", margin: '40px 0px 30px' }}>
													Sorry! There are no results to display
												</h5>
											</div>
										)
									}

								</div>

							{patients.length > 0 && <Pagination items={patients} onChangePage={pageChange} />}
								{
									modalVisible &&
									<SubAdminAddPatient
										setModalVisible={setModalVisible}
										getPatients={getPatients}
									/>
								}
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubAdminPatients
