import React, { useState, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';

import { isMobile } from "react-device-detect";

import AgoraRTC from "agora-rtc-sdk-ng";

import { toast } from 'react-toastify';
import { Context } from '../../store';
import {
	SET_ERROR,
	SET_COMMON_LOADING,
	SET_USER_TYPE
} from '../../store/types';

import Peer from "simple-peer";
import {Howl} from 'howler';
import ringtone from '../../ringtone.mp3';

import {
	getOrgLogo
} from '../../api/organization';

import {startBasicCall, leaveCall, setUser} from './Agora_RTC'

import { logout } from '../../utils'
import './test.css';

import {
	StyledContainer
} from './styles';

const ringtoneSound = new Howl({
	src: [ringtone],
	loop: true,
	preload: true
})

const menus = [
  {id: 1, title: 'Home', route: '/', submenu: false},
  {id: 2, title: 'How it works', route: '/how-it-works', submenu: false},
//   {id: 2, title: 'Telehealth', submenu: true, submenus: [
//     {id: 1, title: 'Telemedicine', route: '/tele-medicine'},
//     {id: 2, title: 'Telemonitoring', route: '/tele-monitoring'},
//     {id: 3, title: 'Second Opinion', route: '/second-opinion'}
//   ]},
  {id: 3, title: 'Holistic medicine', submenu: true, submenus: [
    {id: 1, title: 'Neuro Linguistic Programming (NLP)', route: '/nlp'},
    {id: 2, title: 'Yoga', route: '/yoga-nutrition'},
    {id: 3, title: 'Bio Hacking', route: '/bio-hacking'}, //KetOnTrack
  ]},
  {id: 4, title: 'Health network', submenu: true, submenus: [
    {id: 1, title: 'Blogs', route: '/blogs'},
    {id: 2, title: 'Youtube', route: '/youtube'},
    {id: 3, title: 'Events', route: '/events'}
  ]},
  {id: 5, title: 'Helpful links', submenu: true, submenus: [
    {id: 1, title: 'About us', route: '/about-us'},
    // {id: 2, title: 'Management Team', route: '/management-team'},
    {id: 3, title: 'Contact us', route: '/contact-us'},
    {id: 4, title: 'Investor relations', route: '/investor-relations'}
  ]}
]

function HeaderOrgConponent(props) {
	const [state, dispatch] = useContext(Context);
	// const [audio, setAudio] = useState(true);
	// const [video, setVideo] = useState(true);
  	// const [receivingCall, setReceivingCall] = useState(false);
  	// const [caller, setCaller] = useState("");
  	// const [callerSignal, setCallerSignal] = useState();
	// const [callAccepted, setCallAccepted] = useState(false);
	// const [currentChat, setCurrentChat] = useState(null);
	const [currentUserId, setCurrentUserId] = useState(null);
	// const [doctorName, setDoctorName ] = useState(null);

	// const [callAcceptedByYou, setCallAcceptedByYou] = useState(false);

	// const [doctorId, setDoctorId ] = useState(null);
	// const [appointmentId, setAppointmentId ] = useState(null);

	// const userVideo = useRef();
	// const partnerVideo = useRef();

	// const [currentUserVideo, setCurrentUserVideo] = React.useState(false);
	// const [userVideoA, setUserVideoA] = React.useState(true);


	const [appid, setAppid] = useState('f5a79afbfcce4005bc94277dbd7fe04b')
  	const [channel, setChannel] = useState('')
  	const [token, setToken] = useState('')
  	const [isjoin, setIsJoin] = useState(false)
	const [uuid, setUuid] = useState('');
	const [doctorName, setDoctorName] = useState('');
	
	const [calling, setCalling] = useState(false);


	const [userLogin, setUserLogin] = React.useState(false);
	const [userType, setUserType] = React.useState('');
	const [userName, setUserName] = React.useState('');

	React.useEffect(() => {
		if(typeof window !== "undefined" && window.location.pathname.includes("emergency-contact")) {
			setUserLogin(true);
				setUserType('emergencyContact');
			setUserName(JSON.parse(localStorage.getItem('emergencyContact')).name);
			JSON.parse(localStorage.getItem('emergencyContact')).org  && getLogo(JSON.parse(localStorage.getItem('emergencyContact')).orgData._id);
		} else {
			if(JSON.parse(localStorage.getItem('customer'))) {
				setUserLogin(true);
				setUserType('customer');
				setUserName(JSON.parse(localStorage.getItem('customer')).name);
				JSON.parse(localStorage.getItem('customer')).org  && getLogo(JSON.parse(localStorage.getItem('customer')).orgData._id);
			} else if(JSON.parse(localStorage.getItem('doctor'))) {
				setUserLogin(true);
				setUserType('doctor');
				setUserName(JSON.parse(localStorage.getItem('doctor')).name);
				JSON.parse(localStorage.getItem('doctor')).org  && getLogo(JSON.parse(localStorage.getItem('doctor')).orgData._id);
			} else if(JSON.parse(localStorage.getItem('organization'))) {
				setUserLogin(true);
				setUserType('organization');
				setUserName(JSON.parse(localStorage.getItem('organization')).org_name)
				getLogo(JSON.parse(localStorage.getItem('organization'))._id);
			} 
		}

	}, [])

	const getLogo = (id) => {
		if(id) {
			getOrgLogo(id).then(res => {
				if(res && res.data && res.data.logo) {
					setLogo(res.data.logo)
				}
			}).catch(err => console.log(err, '..err'))
		}
	}
	
	React.useEffect(() => {
		const customer = JSON.parse(localStorage.getItem('customer'));

		const path = window.location.pathname;

  //  && path.includes("patient")
		if(customer && customer._id ) {
			setCurrentUserId(customer._id);
			// const user = {
			// 	userId: customer._id
			// };
			// socket.emit("join_patient", user);

		

			// socket.on('rtctoken-patient', data => {
			// 	if(data) {
			// 		ringtoneSound.play();
			// 		setCalling(true);
			// 		setChannel(data.channel)
			// 		setToken(data.refreshToken)
			// 		setUuid(data.uuid)
			// 		setDoctorName(data.doctorName)
			// 		setUser('Patient');
			// 	}
			// })
		}
	}, [])

	const handleClickJoin = () => {
		setCalling(false);

		setTimeout(() => {
			ringtoneSound.unload();
			ringtoneSound.stop();
		}, 100);

		AgoraRTC.getDevices()
		.then(devices => {
			const audioDevices = devices.filter(function(device){
					return device.kind === "audioinput";
			});
			const videoDevices = devices.filter(function(device){
					return device.kind === "videoinput";
			});

			var selectedMicrophoneId = audioDevices[0].deviceId;
			var selectedCameraId = videoDevices[0].deviceId;
			return Promise.all([
				AgoraRTC.createCameraVideoTrack({ cameraId: selectedCameraId }),
				AgoraRTC.createMicrophoneAudioTrack({ microphoneId: selectedMicrophoneId }),
			]);
		})
		.then(res => {
			const [videoTrack, audioTrack] = res;

			let options = {
				appId: appid,
				channel: channel,
				token: token,
				uid: uuid
			}
			const val = startBasicCall(options, videoTrack, audioTrack).then(res => {
			})
			
			setIsJoin(true)
		

		}).catch(e => {
			toast.error('Permission denied')
			dispatch({
				type: SET_ERROR,
				payload: 'Permission denied'
			});
		});

   
  }

  const handleClickLeave = () => {
    leaveCall('Patient')
    setIsJoin(false)
  }

	const callRejected = () => {
		setCalling(false)
		// const user = {
		// 	appointmentId: channel,
		// 	from: 'Patient'
		// };
		// socket.emit("rejectCall", user);

		setTimeout(() => {
			ringtoneSound.unload();
			ringtoneSound.stop();
		}, 100);
		
	}

	const [currentSubAdmin, setCurrentSubAdmin] = React.useState(null);

	const [logo, setLogo] = React.useState('');

	const onSetTitle = (title) => {
		if(currentSubAdmin === title) setCurrentSubAdmin(null);
		else setCurrentSubAdmin(title)
	}

	const getRoute = () => {
		if(userType == 'customer') {
			return '/patient/dashboard';
		} else if(userType == 'doctor') {
			return '/doctor/schedule';
		} else if(userType == 'organization') {
			return 'organization/dashboard';
		}
	}

	const onSetLogout = () => {
		dispatch({
			type: SET_USER_TYPE,
			payload: ''
		});
		setUserLogin(false);
		localStorage.clear();
		props.history.push('/');
	}

	return (
	  <StyledContainer>
    <header className="header">
      <nav className="navbar navbar-expand-lg header-nav">
        <div className="navbar-header">
            {props.names && props.names.subHeader===false ? null :  <a id="mobile_btn" href="javascript:void(0);">
                <span className="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </a>}
            <a  className="navbar-brand logo">
                <img src={logo ? logo : "/assets/img/logo.png"} className="img-fluid" alt="Logo" />
            </a>
        </div>
        <div className="main-menu-wrapper">
            <div className="menu-header">
                <a  className="menu-logo">
                    <img src={logo ? logo : "/assets/img/logo.png"} className="img-fluid" alt="Logo" />
                </a>
                <a id="menu_close" className="menu-close" href="javascript:void(0);">
                    <i className="fas fa-times"></i>
                </a>
            </div>
            <ul className="main-nav">
              
            </ul>		 
        </div>		 
        <ul className="nav main-nav header-navbar-rht">
					{!userLogin && <li className="nav-item contact-item">
							<div className="header-contact-img">
									<i className="far fa-envelope"></i>							
							</div>
							<div className="header-contact-detail">
									<p className="contact-header">Contact</p>
									<p className="contact-info-header"> info@emed4u.com</p>
							</div>
						</li>}
						
					{
						userLogin && <li className="has-submenu" >
							<a className='submenu log-emed'>Welcome  {userName}<i className="fas fa-chevron-down"></i></a>
							<ul className="submenu">
								<li><Link to={getRoute()} >Dashboard</Link></li>
								<li><a style={{cursor: 'pointer'}} onClick={() => onSetLogout()} >Logout</a></li>
							</ul>
						</li>	
					}
            {/* <li className="nav-item">
                <Link to={'/login'} className="nav-link header-login" >login / Signup </Link>
            </li> */}
        </ul>
      </nav>


{calling ? (

	<div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)', paddingLeft: '0px'}}>
		<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
						<div className="modal-body" style={{    padding: '40px'}}>
								<div className="call-box incoming-box">
										<div className="call-wrapper" style={{    height: 'auto'}}>
												<div className="call-inner">
														<div className="call-user">
																{/* <img className="call-avatar" src={doctorId.profileImage ? doctorId.profileImage : "/assets/img/doctor-empty.png"} alt="User Image" /> */}
																<h4>{doctorName}</h4>
																<span>Calling ...</span>
														</div>							
														<div className="call-items">
																<a href="javascript:void(0);" className="btn call-item call-end" data-dismiss="modal" aria-label="Close"><i className="material-icons" onClick={callRejected}>call_end</i></a>
																{/* <a href="javascript:void(0);" onClick={() => {}} className="btn call-item call-start"><i className="material-icons">call</i></a> */}
																<a href="javascript:void(0);" onClick={handleClickJoin} data-toggle="modal" data-target="#video_call" className="btn call-item call-start"><i className="material-icons">videocam</i></a>
														</div>
												</div>
										</div>
								</div>
								
						</div>
				</div>
			</div>
		</div>
) : null}


<div class="modal fade call-modal " id="video_call">
<div class="modal-dialog video-call-modal modal-dialog-centered" role="document">
	<div class="modal-content">
		<div class="modal-body">

			<div class="call-wrapper">
			<div class="call-main-row">
				<div class="call-main-wrapper">
					<div class="call-view">
						<div class="call-window">

							<div class="fixed-header">
								<div class="navbar">
									<div class="user-details">
										<div class="float-left user-img">
											
										</div>
										<div class="user-info float-left">
											<a><span>{doctorName}</span></a>
										</div>



									</div>

								</div>
							</div>

							<div class="call-contents">
								<div class="call-content-wrap">
									<div class="user-video">
										<div className='video-agora-box'>
											
										</div>




									</div>
									<div class="my-video">
										<ul>
											<li style={{height: isMobile ? '50px' : '120px'}}>
												<div id='video-agora-local'></div>

											</li>
										</ul>
									</div>
								</div>
							</div>

							<div class="call-footer">
								<div class="call-icons">
									<ul class="call-items">
								 {/* <li class="call-item">
											<a href="javascript:void(0);" title="Enable Video" data-placement="top" data-toggle="tooltip" >
												<i class="fas fa-video camera"></i>
											</a>
										</li>
										<li class="call-item">
											<a href="javascript:void(0);" title="Mute Audio" data-placement="top" data-toggle="tooltip">
												<i class="fa fa-microphone microphone"></i>
											</a>
										</li> */}
									</ul>
									<div class="end-call" style={{top: '-10px'}}>
										<a href="javascript:void(0);"   class="btn call-item call-end" data-dismiss="modal" aria-label="Close" onClick={() => handleClickLeave()}>
											<i class="material-icons">call_end</i>
										</a>
									</div>
								</div>
							</div>

						</div>
					</div>

				</div>
			</div>
		</div>


		</div>
	</div>
</div>
</div>


    </header>
	</StyledContainer>
  )
}

export default HeaderOrgConponent
