import { getCurrencyApi } from "../../services/mainService";
import { getCurrencyAction } from "./publicAction";
import { getCurrencyFailure, getCurrencyRequest, getCurrencySuccess } from "./publicSlice";
import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
function* getcurrency(action) {
    try {
       yield put(getCurrencyRequest()); 
      const apiResponse = yield call(getCurrencyApi, action.payload);
      if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
          yield put( getCurrencySuccess(listData));
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put(getCurrencyFailure(apiResponse.response.message))
        }
      }
      
    } 
    catch (error) {}
  }




export function* publicSaga() {
    yield takeLatest(getCurrencyAction.type, getcurrency);
   
    
  }