import React from 'react'
import Slider from "react-slick";

import {
	StyledTestimonialWrapper,
	StyledTestimonialContent
} from './styles';

function Testimonial() {
	const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
	};
	
	return (
		<StyledTestimonialWrapper className="doctor-slider slider m-auto">
			<Slider {...settings}>
				<StyledTestimonialContent className="profile-widget col-12">
					{/* <div className="feature-item m-auto">
						<img className="avatar-img rounded-circle" alt="User Image" src="/assets/img/patients/patient8.jpg" />
					</div> */}
					<div className="pro-content">
						{/* <h3 className="title">
							<a>Ruby Perrin</a> 
						</h3> */}
						<p className="speciality">Thanks to Emed4u I can now make better informed decisions for my patients. its telemonitoring feature allows me to keep tabs on the patient from a distance and also make the right diagnosis.</p>
						<div className="rating">
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
						</div>
					</div>
				</StyledTestimonialContent>

				<StyledTestimonialContent className="profile-widget col-12">
					{/* <div className="feature-item  m-auto">
						<img className="img-fluid" alt="User Image" src="/assets/img/patients/patient8.jpg" />
					</div> */}
					<div className="pro-content">
						{/* <h3 className="title">
							<a>Ruby Perrin</a> 
						</h3> */}
						<p className="speciality">Our clinic uses the Emed4u system and it works really well. I was facing a lot of issues managing the patient data but Emed4u has made my life easier. It has also improved the online consultation experience. I appreciate it. </p>
						<div className="rating">
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
						</div>
					</div>
				</StyledTestimonialContent>

				<StyledTestimonialContent className="profile-widget col-12">
					<div className="pro-content">
						<p className="speciality">Emed4u has been a game changer with its telemonitoring and telemedicine features. I use Emed4u for patients with chronic diseases as they need regular check-ups. It has been pretty easy to keep tabs on them from a distance. Great app </p>
						<div className="rating">
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
						</div>
					</div>
				</StyledTestimonialContent>


				<StyledTestimonialContent className="profile-widget col-12">
					<div className="pro-content">
						<p className="speciality">The best thing is I can manage my appointments or have a consultation from anywhere. I just need to log-in to my account and that’s it. I use the app all the time to connect with my regular patients. the customer service is really nice. I highly recommend it. </p>
						<div className="rating">
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
						</div>
					</div>
				</StyledTestimonialContent>

				<StyledTestimonialContent className="profile-widget col-12">
					<div className="pro-content">
						<p className="speciality">TThe telemedicine and telemonitoring features have been a very big help for me. I can use these features and understand the patient’s health better and give the right medication. </p>
						<div className="rating">
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
						</div>
					</div>
				</StyledTestimonialContent>
				
			</Slider>
		</StyledTestimonialWrapper>
	)
}

export default Testimonial
