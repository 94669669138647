import React, { useEffect, useState } from 'react';
import ReactApexChart from "react-apexcharts";
import { minimumPoint } from '../../../graphUtils';
import './index.css';
import moment from 'moment';

const setGraphAll = (data) => data == 365 ? 'all' : '1'

function LineChartComponent(props) {
	const { keyItem, Linedata, type, currentFilter, color, label, showfilter, doubleLineChart, determineLabel, patientReport, height,applyFilter } = props;
	const [selection, setSelection] = useState('1');

	useEffect(() => {
		setSelection(currentFilter ? currentFilter : setGraphAll(currentFilter));
	},[currentFilter])

	let series = []
	if(patientReport) {
		let updateData = [];
		let pastweek = []
		if (doubleLineChart) {
			let sys_BP_current = [];
			let dia_BP_current = [];
			let sys_BP_past = [];
			let dia_BP_past = [];
			for (var i = 0; i < Object.keys(Linedata.currentWeek).length; i++) {
				sys_BP_current.push(parseInt(Linedata.currentWeek[i].sys_blood_pressure));
				dia_BP_current.push(parseInt(Linedata.currentWeek[i].dia_blood_pressure));

			}
			for (var i = 0; i < Object.keys(Linedata.pasWeek).length; i++) {
				sys_BP_past.push(parseInt(Linedata.pasWeek[i].sys_blood_pressure));
				dia_BP_past.push(parseInt(Linedata.pasWeek[i].dia_blood_pressure));

			}
			series = [
				{
					name: 'Dia Bp',
					label: 'Dia Bp',
					paddingLeft: '30px',
					data: [parseFloat(dia_BP_current.reduce((x, y) => x + y, 0) / dia_BP_current.length).toFixed(2), parseFloat(dia_BP_past.reduce((x, y) => x + y, 0) / dia_BP_past.length).toFixed(2)]
				},
				{
					name: 'Sys Bp',
					label: 'Sys Bp',
					data: [parseFloat(sys_BP_current.reduce((x, y) => x + y, 0) / sys_BP_current.length).toFixed(2), parseFloat(sys_BP_past.reduce((x, y) => x + y, 0) / sys_BP_past.length).toFixed(2)]
				},
			]
		}
		else {
			Linedata.currentWeek.map(item => {
				updateData.push(parseInt(item[determineLabel.graphData]))
				return item;
			})
			Linedata.pasWeek.map(item => {
				pastweek.push(parseInt(item[determineLabel.graphData]))
				return item;
			})
			series = [{
				name: label,
				data: [parseFloat(updateData.reduce((x, y) => x + y, 0) / updateData.length).toFixed(2), parseFloat(pastweek.reduce((x, y) => x + y, 0) / pastweek.length).toFixed(2)]
			},
			]
		}
	}
	else if(doubleLineChart){
		let sys_BP = [];
		let dia_BP =[];
		for (var i =0; i<Object.keys(Linedata).length; i++){
			sys_BP.push([new Date(Linedata[i]?.createdAt).getTime(),parseInt(Linedata[i].sys_blood_pressure)]);
			dia_BP.push([new Date(Linedata[i]?.createdAt).getTime(),parseInt(Linedata[i].dia_blood_pressure)]);
	
		}
		series = [
			{
				name: 'Sys BP',
				data: sys_BP,
			},
			{
				name: 'Dia BP',
				data: dia_BP,
			}
		]
	}
	else if(type === 'bar') {
		let updateData = [];
		Linedata.map(item => {
			updateData.push([Date.parse(item.createdAt), parseInt(item[determineLabel.graphData])])
			return item;
		})
		series = [{
			name: label,
			data: updateData
		}]
	}
	else {
		let updateData = [];
		Linedata.map(item => {
			updateData.push([Date.parse(item.createdAt), parseInt(item[determineLabel.graphData])])
			return item;
		})
		series = [{
			name: label,
			data: updateData
		}]
	}

	let options = {}
	if(patientReport) {
		options=  {
			annotations: {
			  points: [{
				x: label,
				seriesIndex: 0,
				label: {
				  borderColor: '#775DD0',
				  style: {
					color: '#fff',
					background: '#775DD0',
				  },
				}
			  }]
			},
			chart: {
			  height: 350,
			  type: 'bar',
			  stacked: true,
				animations: {
					enabled: false,
				}
			},
			plotOptions: {
			  bar: {
				borderRadius: 10,
				columnWidth: '10%',
			  }
			},
			dataLabels: {
			  enabled: false
			},
			stroke: {
			  curve: 'straight',
			  width: 2,
			  colors: doubleLineChart ? [color.borderDiaColor, color.borderSysColor] : [color.borderColor]
			},
			
			grid: {
			  row: {
				colors: ['#fff', '#f2f2f2']
			  },
			},
			xaxis: {
			  labels: {
				rotate: -45,
			  },
			  categories: [
				  'Current Week',
				  'Previous Week'
				//   `Current Week (${moment().startOf('week').subtract(6, 'days').format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')})`,
				//   `Previous Week (${moment().startOf('week').subtract(13, 'days').format('DD/MM/YYYY')} - ${moment().startOf('week').subtract(7, 'days').format('DD/MM/YYYY')})`,
				],
			  tickPlacement: 'on'
			},
			fill: {
				type: 'solid',
				colors: doubleLineChart ? [color.fillDiaColor, color.fillSysColor] : [color.fillColor],
				gradient: {
					shadeIntensity: 0.9,
					opacityFrom: 0.7,
					opacityTo: 0.9,
					stops: [0, 100]
				}
			},
		}
	}
	else {
		options = {
			chart: {
				id: keyItem,
				type: type,
				height: 350,
				zoom: {
					autoScaleYaxis: true
				},
				animations: {
					enabled: false,
				}
			},
			plotOptions: {
				bar: {
					borderRadius: 1,
					columnWidth: '50%',
				}
			},
			annotations: {
				yaxis: [{
					y: 30,
					// y: 0,
					borderColor: '#999',
					label: {
						show: true,
						// text: label,
						style: {
							color: "#fff",
							background: '#00E396'
						}
					}
				}],
				xaxis: [{
					x: new Date('14 Nov 2012').getTime(),
					borderColor: '#red',
					yAxisIndex: 0,
					label: {
						show: true,
						style: {
							color: "#fff",
							background: '#775DD0'
						}
					},
				}]
			},
			dataLabels: {
				enabled: false
			},
			markers: {
				size: 0,
				style: 'hollow',
			},
			stroke: {
				curve: 'straight',
				show: true,
				lineCap: 'round',
				width: 2,
				colors: doubleLineChart ? [color.borderSysColor, color.borderDiaColor] : [color.borderColor]
			},
			xaxis: {
				type: 'datetime',
				labels : {
					datetimeUTC : false
				}
			},
			yaxis: {
				min : minimumPoint(label),
				// min : 0,
			},
			tooltip: {
				x: {
					format: 'dd MMM yyyy'
				}
			},
			fill: {
				type: 'gradient',
				colors: doubleLineChart ? [color.fillSysColor, color.fillDiaColor] : [color.fillColor],
				gradient: {
					// shadeIntensity: 1,
					opacityFrom: 1,
					opacityTo: 0.8,
					stops: [0,100]
				}
			},
			colors: doubleLineChart ? [color.borderSysColor, color.borderDiaColor] : [color.borderColor]
		}
	}


	const updateData = (timeline) => {
		setSelection(timeline)
		switch (timeline) {
			case '1':
				applyFilter(1)
				break
			case '3':
				applyFilter(3)
				break
			case '7':
				applyFilter(7)
				break
			case '30':
				applyFilter(30)
				break
			case '180':
				applyFilter(180)
				break
			case '365':
				applyFilter(365)
				break
			case 'all':
				applyFilter(365)
				break
			default:
		}
	}
	
	// }
	// const dbstate = {
	// 	options: {
	// 	  chart: {
	// 		id: "basic-bar"
	// 	  },
	// 	  xaxis: {
	// 		categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
	// 	  }
	// 	},
	// 	series: [
	// 	  {
	// 		name: "series-1",
	// 		data: [30, 40, 45, 50, 49, 60, 70, 91]
	// 	  }
	// 	]
	//   };

	return (
		<div className="row">
			<div id="chart" style={{width:'100%'}}>
				{showfilter &&
					<div class="toolbar">
						<button id="1"
							onClick={() => updateData('1')} className={(selection == '1' ? 'active' : '')}>
							1D
						</button>
						&nbsp;
						<button id="3"
							onClick={() => updateData('3')} className={(selection == '3' ? 'active' : '')}>
							3D
						</button>
						&nbsp;
						<button id="7"
							onClick={() => updateData('7')} className={(selection == '7' ? 'active' : '')}>
							1W
						</button>
						&nbsp;
						<button id="30"
							onClick={() => updateData('30')} className={(selection == '30' ? 'active' : '')}>
							1M
						</button>
						&nbsp;
						<button id="180"
							onClick={() => updateData('180')} className={(selection == '180' ? 'active' : '')}>
							6M
						</button>
						&nbsp;
						<button id="365"
							onClick={() => updateData('365')} className={(selection == '365' ? 'active' : '')}>
							1Y
						</button>
						&nbsp;
						<button id="all"
							onClick={() => updateData('all')} className={(selection == 'all' ? 'active' : '')}>
							ALL
						</button>
					</div>
				}

				<div id="chart-timeline">
					<ReactApexChart options={options} series={series} type={type} height={height} />
				</div>
			</div>
		</div>
	);
}

export default LineChartComponent;
