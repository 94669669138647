import React from 'react';

import Cookies from 'js-cookie';

import { toast } from 'react-toastify';

import Select from "react-select";
import Files from 'react-files';

import { Context } from '../../../../../store';

import {
	BookedAppointmentAPI
} from '../../../../../api/subadmin';
import {
	getPatientInfo,
} from '../../../../../api/organization';

import {
	SET_ERROR,
	SET_COMMON_LOADING,
} from '../../../../../store/types';
import { globalValidator, getUser } from "../../../../../utils";

import CardComponent from '../../../../../components/Card';

import InputField from '../../../../../components/InputField';
import SelectField from '../../../../../components/SelectField';

import {
	StyledContainer,
	StyledButtonPay,
	StyledButtonType
} from './styles';
import InsideMenuBar from '../../../../../components/InsideMenus';

function SubAdminPatientFormBooking(props) {
	const [state, dispatch] = React.useContext(Context);
	let fileRef = React.useRef(null);

	const [patientIdE, setPatientIdE] = React.useState(false);
	const [patientId, setPatientId] = React.useState('');
	const [showIdField,setShowIdField] = React.useState('');
	const [filesErrorMsg, setFilesErrorMsg] = React.useState('');
	const [patientInfo, SetPatientInfo] = React.useState({
		OrganizationId: '',
		patientName: '',
		patientAge: '',
		patientWeight: '',
		why_do_doctor: '',
		symptoms: [],
		patientGender: '',
		uploadsDocuments: [],
		hypertension: false,
		diabetes: false,
		appointmentType: 1
	});


	React.useEffect(() => {
		const allCookieData = Cookies.get();
		if(allCookieData && allCookieData.setPatientInfo) {
			const user = JSON.parse(allCookieData.setPatientInfo);
			const ordId = JSON.parse(localStorage.getItem('subuser')).OrganizationId;
			SetPatientInfo({
				OrganizationId: ordId,
				patientName: user.name,
				patientAge: _calculateAge(user.dob),
				patientWeight: user.weight,
				patientGender: user.gender,
				why_do_doctor: '',
				symptoms: [],
				id: user.id,
				uploadsDocuments: [],
				hypertension: false,
				diabetes: false,
				appointmentType: 1
			})
			setShowIdField(props.location.state?.showIdField);
		}
	
	}, [])
	const symptomsArr = [
		"Fever",
		"Weight loss",
		"Headache/ Migraine headaches",
		"Runny nose (Rhinitis)",
		"Thyroid / Hypo-hyperthyroidism",
		"Asthma / COPD",
		"General Body aches",
		"Legs pain or cramps in rest or after walking",
		"Swollen legs / feet",
		"Knee pain or painful knee joint",
		"Painful lower back or stiff back",
		"Painful shoulder joint",
		"Painful ankle joint",
		"painful feet",
		"High blood pressure",
		"Low blood pressure",
		"High Blood Sugar",
		"Low blood sugar",
		"High Cholesterol and blood fats",
		"Chest pain at rest or doing efforts",
		"No hunger/loss of appetite",
		"excessive Hunger",
		"Gastric trouble with Burps and excessive gas formation",
		"Nausea with Vomiting",
		"Diarrhoea",
		"Constipation /stool hardening",
		"Blood in Stool",
		"Urination difficulty/ burning or pain",
		"loss of urine control",
		"Blood in urine"
	];

	const onFilesChange = (files) => {
		fileRef.state.files= '';
		const filesFilter = files.map((item) => item.type)
		const acceptsTypes = ["image/png", "image/jpg", "image/jpeg", "application/pdf"];
		const fileSize = files.filter((item) => item.size > 5242880)
		const filesCheckType = filesFilter;

		// eslint-disable-next-line no-extend-native
		Array.prototype.gb_inArray = function(filesCheckType, matchAll = false) {
			if (matchAll) {
				return filesCheckType.every(i => this.includes(i));
			} else {
				return filesCheckType.some(i => this.includes(i));
			}
		};
		
		const fileTypeCheck = acceptsTypes.gb_inArray(filesCheckType, true);

		if(files?.length > 5){
			setFilesErrorMsg('Maximum 5 files are allowed!')
			fileRef.state.files= '';
			return
		}

		if(!fileTypeCheck){
			setFilesErrorMsg('PDF or Image should be accepted!')
			fileRef.state.files= '';
			return 
		}

		if(fileSize?.length){
            setFilesErrorMsg('Please upload a file smaller than 5 MB')
            fileRef.state.files= '';
            return
        }
			fileRef.state.files= '';
			SetPatientInfo((info) => ({
				...info,
				uploadsDocuments: files
			}))
			setFilesErrorMsg('')
			setFilesErrorMsg('')
  }
 
	const onFilesError = (error, file) => {
		// toast.error(error.message)
		// dispatch({
		// 	type: SET_ERROR,
		// 	payload: error.message
		// });
	}

	const handleChangeSymptoms = (e) => {
		SetPatientInfo(info => ({...info, symptoms: e}))
	}
	
	
	const gotoCheckout = (e) => {
		e.preventDefault();
		const allCookieData = Cookies.get();
		const slot = JSON.parse(allCookieData.slot);
		const ordId = JSON.parse(localStorage.getItem('subuser')).OrganizationId;
			const keys = [
				{ name: "patientName", title: "Name", validateKey: "required" },
				{ name: "patientAge", title: "Age", validateKey: "required" },
				{ name: "patientWeight", title: "Weight", validateKey: "required" },
				{ name: "symptoms", title: "Symptoms", validateKey: "required" },
				{ name: "why_do_doctor", title: "Why do you need doctor", validateKey: "required" }
			];
			let valid = globalValidator(patientInfo, keys);

			if (valid === true) {
				patientInfo.symptoms = patientInfo.symptoms.map(item => {
					return item.value;
				});


			const appointmentData = {
				OrganizationId: ordId,
				patientAge: patientInfo.patientAge,
				patientGender: patientInfo.patientGender,
				patientName: patientInfo.patientName,
				patientWeight: patientInfo.patientWeight,
				symptoms: patientInfo.symptoms,
				why_do_doctor: patientInfo.why_do_doctor,
				doctorId: slot.doctorId,
				slotId: slot._id,
				amount: 300,
				appointmentType: patientInfo.appointmentType,
				hypertension: patientInfo.hypertension,
				diabetes: patientInfo.diabetes,
				appointmentType: patientInfo.appointmentType
			}
			loader(true);
			BookedAppointmentAPI(showIdField ? patientInfo.id : props.match.params.id, appointmentData).then(res => {
				loader(false);
				Cookies.remove('appointment', { path: '' });
				Cookies.remove('slot', { path: '' });
				Cookies.remove('doctorInfo', { path: '' });
				Cookies.remove('setPatientInfo', { path: '' });
				
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
	
				props.history.push(`/subuser/appointments`);
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			}); 
			
			// const formData = new FormData();
			// Object.keys(patientInfo).forEach(key => {
			// 	if (key === "uploadsDocuments") {
			// 		patientInfo[key].map(doc => {
			// 			formData.append("uploadsDocuments", doc, doc.name);
			// 		});
			// 	} else {
			// 		formData.append(key, patientInfo[key]);
			// 	}
			// });
			// localStorage.setItem('appointment', JSON.stringify(patientInfo))

		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const filesRemoveOne = (file) => {
		const files = patientInfo.uploadsDocuments.filter((item) => item.id!==file.id)
		fileRef.state.files=files
		SetPatientInfo((info) => ({
			...info,
			uploadsDocuments: files
		}))
	  }
	  
	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const _calculateAge = (dateString) => { // birthday is a date
		if(dateString) {
			var today = new Date();
			var birthDate = new Date(dateString);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
					age--;
			}
			if (age <= 0) {
				return 0
			}
			return age;
		}
    return '';
	}

	// const gotoCheckout = () => {
		// props.history.push('/patient/checkout-payment');
	// }
	

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'subadmin'}
			/>
				<div className="content">
					<div className="container-fluid">
						<div className="row">

							<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<div className="row pt-2">
									<div className="p-4 col-md-3 form-group ">
										<label>Patient name <small style={{ color: 'red' }}>*</small></label>
										<InputField value={patientInfo.patientName} onChange={e => SetPatientInfo((user) => ({ ...user, patientName: e.target.value }))} />
									</div>
									<div className="p-4 col-md-3 form-group ">
										<label>Patient age <small style={{ color: 'red' }}>*</small></label>
										<InputField value={patientInfo.patientAge} onChange={e => SetPatientInfo((user) => ({ ...user, patientAge: e.target.value }))} />
									</div>
									<div className="p-4 col-md-3 form-group ">
										<label>Weight (in kgs) <small style={{ color: 'red' }}>*</small></label>
										<InputField value={patientInfo.patientWeight} onChange={e => SetPatientInfo((user) => ({ ...user, patientWeight: e.target.value }))} />
									</div>
									<div className="p-4 col-md-3 form-group ">
										<label>Gender <small style={{ color: 'red' }}>*</small></label>
										<SelectField onChange={e => SetPatientInfo((user) => ({ ...user, patientGender: e.target.value }))} value={patientInfo.patientGender} options={[{ label: 'Select gender' }, { label: 'Male', value: 'male' }, { label: 'Female', value: 'female' }, { label: 'Other', value: 'other' }]} />
									</div>
								</div>
								<div className="row pl-2 pr-2">
									<div className="col-md-12 form-group">
										<label>Symptoms <small style={{ color: 'red' }}>*</small></label>
										<Select
											type="select"
											name="symptoms"
											isMulti
											onChange={(e) => handleChangeSymptoms(e)}
											options={symptomsArr.map(symptom => {
												return {
													label: symptom,
													value: symptom
												};
											})}
										/>
									</div>
								</div>

								<div className="row pl-2 pr-2">
									<div className="col-md-12 form-group">
										<label>Why do you need doctor? <small style={{ color: 'red' }}>*</small></label>
										<InputField value={patientInfo.why_do_doctor} onChange={e => SetPatientInfo((user) => ({ ...user, why_do_doctor: e.target.value }))} />
									</div>
								</div>

								<div className="row pl-2 pr-2">
									<div className="col-md-8 form-group">
										<div className="row">
											<div className="col-md-6 form-group">
												<label>Hypertension</label>
												<div className="d-flex">
													<div class="btn-group me-2" role="group" aria-label="First group">
														<button type="button" className={patientInfo.hypertension ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({ ...user, hypertension: true }))}>Yes</button>
														<button type="button" className={!patientInfo.hypertension ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({ ...user, hypertension: false }))}>No</button>
													</div>
												</div>
											</div>
											<div className="col-md-6 form-group">
												<label>Diabetes</label>
												<div className="d-flex">
													<div class="btn-group me-2" role="group" aria-label="First group">
														<button type="button" className={patientInfo.diabetes ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({ ...user, diabetes: true }))}>Yes</button>
														<button type="button" className={!patientInfo.diabetes ? "btn btn-primary" : "btn btn-secondary"} onClick={() => SetPatientInfo((user) => ({ ...user, diabetes: false }))}>No</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-4 form-group">
										<label>Payment method</label>
										<div className="d-flex btn-group-app">
											<StyledButtonType onClick={e => SetPatientInfo((user) => ({ ...user, appointmentType: 0 }))} className={patientInfo.appointmentType === 0 ? 'active-btn-blue' : ''}>
												Online pay
											</StyledButtonType>
											<StyledButtonType onClick={e => SetPatientInfo((user) => ({ ...user, appointmentType: 1 }))} className={patientInfo.appointmentType === 1 ? 'active-btn-blue' : ''}>
												Cash
											</StyledButtonType>
										</div>
									</div>
								</div>

									<div className="row pl-2 pr-2">
										<div className="col-md-12 form-group">
											<label>Upload documents <small>(You can upload 5 files)</small></label>
											<Files
												ref={(fileItem) => fileRef = fileItem}
												className='files-dropzone'
												onChange={onFilesChange}
												onError={onFilesError}
												// accepts={['image/*', '.pdf', 'audio/*']}
												multiple
												// maxFiles={5}
												// maxFileSize={10000000}
												minFileSize={0}
												clickable
											>
													<div>Drop files here or click to upload</div>

												{/* {patientInfo.uploadsDocuments && patientInfo.uploadsDocuments.length > 0 ? (
													<div className="files-gallery">
														{patientInfo.uploadsDocuments.map(file => (
															<img src="/assets/img/file-uploaded.png" />
														))}
													</div>
												) : (
												)} */}
											</Files>
											{filesErrorMsg ? <p style={{color: 'red'}}>{filesErrorMsg}</p> : ''}
										{
											patientInfo.uploadsDocuments && patientInfo.uploadsDocuments.length > 0
												?
												<div className="wardRoomList d-flex flex-wrap mt-2">
													{patientInfo.uploadsDocuments.map((file) =>

														<div className="col-md-2 col room-view mt-2">
															<div>
																<img src="/assets/img/file-uploaded.png" />
																<div
																	id={file.id}
																	className='files-list-item-remove'
																	onClick={() => filesRemoveOne(file)} // eslint-disable-line
																/>
															</div>
														</div>


													)}
												</div>
												: null
										}
										</div>
									</div>
								</CardComponent>
								
								<div class="submit-section proceed-btn text-right">
									<StyledButtonPay class="btn btn-primary submit-btn" onClick={(e) =>gotoCheckout(e)}>Proceed </StyledButtonPay>
								</div>

							</div>
						</div>
					</div>
				</div>

		</StyledContainer>
	)
}

export default SubAdminPatientFormBooking
