import React, { useContext } from 'react';
import Files from 'react-files';

import { toast } from 'react-toastify';
import { Context } from '../../store';

import { updatedDocuments } from '../../api/organization';

import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../store/types';

import { StyledContainer, StyledButtonPay } from './styles';

function UploadDocuments(props) {
	const [state, dispatch] = useContext(Context);
	const [filesErrorMsg, setFilesErrorMsg] = React.useState('');
	let fileRef = React.useRef(null);

	const [documents, setDocuments] = React.useState([]);

	const onFilesChange = (files) => {
		fileRef.state.files= '';
		if(files?.length > 5){
			setFilesErrorMsg('Maximum 5 files are allowed!')
			fileRef.state.files= '';
			return
		}
		if(files[0]?.size > 5242880){
			fileRef.state.files= '';
            setFilesErrorMsg('Please upload a file smaller than 5 MB')
            return
        }
		if(files[0]?.type == "image/png" || files[0]?.type == "image/jpg" || files[0]?.type == "image/jpeg" || files[0]?.type == "application/pdf"){
			fileRef.state.files= '';
			setDocuments(files)
			setFilesErrorMsg('')
		} else {
			setFilesErrorMsg('PDF or Image should be accepted!');
			fileRef.state.files= '';
		}
		setFilesErrorMsg('');
		fileRef.state.files= '';
	}

	const filesRemoveOne = (file) => {
		const files = documents.filter((item) => item.id !== file.id)
		fileRef.state.files = files
		setDocuments(files)
	}

	const onFilesError = (error, file) => {
		// toast.error(error.message)
		// dispatch({
		// 	type: SET_ERROR,
		// 	payload: error.message
		// });
	}

	const procced = (e) => {
		e.preventDefault();
		if(props.match.params && props.match.params.id) {
			loader(true);
			updatedDocuments(props.match.params.id, {uploadsDocuments: documents}).then(res => {
				loader(false);
				// toast.success('Document successfully uploaded!')
				dispatch({
					type: SET_ERROR,
					payload: 'Document successfully uploaded!'
				});
			}, error => {
				loader(false);
				toast.error(error)
				dispatch({
					type: SET_ERROR,
					payload: error
				});
			})
		}
		
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<StyledContainer>
			<div className="row pl-2 pr-2">
				<div className="col-md-12 form-group">
					<h3>Upload documents <small>(You can upload 5 files)</small></h3>
					<Files
						ref={(fileItem) => fileRef = fileItem}
						className='files-dropzone'
						onChange={onFilesChange}
						onError={onFilesError}
						// accepts={['image/*', '.pdf', 'audio/*']}
						multiple
						// maxFiles={5}
						// maxFileSize={10000000}
						minFileSize={0}
						clickable
					>
						<div>Drop files here or click to upload</div>
					</Files>
					{filesErrorMsg ? <p style={{color: 'red'}}>{filesErrorMsg}</p> : ''}

					{
						documents && documents.length > 0
							?
							<div className="wardRoomList d-flex flex-wrap mt-2">
								{documents.map((file) =>
									<div className="col-md-2 col room-view mt-2">
										<div>
											<img src="/assets/img/file-uploaded.png" />
											<div
												id={file.id}
												className='files-list-item-remove'
												onClick={() => filesRemoveOne(file)} // eslint-disable-line
											/>
										</div>
									</div>
								)}
							</div>
							: null
					}
				</div>
			</div>
			<div class="submit-section proceed-btn text-center">
				<StyledButtonPay class="btn btn-primary submit-btn" onClick={(e) => procced(e)}>Submit </StyledButtonPay>
			</div>
		</StyledContainer>
	)
}

export default UploadDocuments
