import React, { useState } from 'react'
import DesktopConductStudy from './DesktopConductStudy';
import { Items } from './DesktopConductStudy/utility';



const arr = [
    { title: 'areas of interest', option: []}, 
    { title: 'Year', option: [] }, 
    { title: 'Country', option: [] }, 
    { title: 'Biomakers', option: [] }, 
    { title: 'Publication type', option: [] }
]

const descriptionText = 'Evaluation of Web-Based and In-Person Methods to Recruit Adults With Type 1 Diabetes for a Mobile Exercise Intervention: Prospective Observational Study'
const studyDataObj = (title, options) => ({
    title: title, 
    option: options, 
    
    date: '2021', 
    description: descriptionText, 
    areaOfInterest: 'HIV', 
    biomark: 'Weight', 
    publicationType: 'Peer Review', 

})

const staticData = []


const ConductStudy = () => {
    // const aboutDevices = data.Offline.title;
	// const [initial, changeInitial] = React.useState(aboutDevices);
	// const current = aboutDevices === initial ? 'Offline' : 'Online';

	// const [collapsibleData, setCollapsibleData] = React.useState(data[current]?.questions);
	// useEffect(() =>{
	// 	setCollapsibleData(data[current]?.questions)
	// },[current])
	const [selected, setSelected] = useState(1);
	const [selectedTags, setSelectedTags ] = useState([])
    const [isQuestion, setQuestion] = useState(false);

    return <DesktopConductStudy 
        selected={selected} 
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        setSelected={setSelected}
        isQuestion={isQuestion}
        setQuestion={setQuestion}
        />
    
}

export default ConductStudy;