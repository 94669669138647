// import React, { useEffect, useState, useContext } from "react";
// import { isMobile } from "react-device-detect";

// import AgoraRTC from "agora-rtc-sdk-ng";

// import { toast } from 'react-toastify';
// import { Context } from '../../../store';
// import {
// 	SET_ERROR,
// 	SET_COMMON_LOADING
// } from '../../../store/types';

// import './test.css';

// import {Howl} from 'howler';
// import ringtone from '../../../ringtone.mp3';

// import {startBasicCall, leaveCall} from './Agora_RTC'

// import './test.css';


// const ringtoneSound = new Howl({
// 	src: [ringtone],
// 	loop: true,
// 	preload: true
// })

// function Test() {
// 	const [state, dispatch] = useContext(Context);

// 	const [appid, setAppid] = useState('f5a79afbfcce4005bc94277dbd7fe04b')
//   const [channel, setChannel] = useState('609b636c7857670d905f37c1')
//   const [token, setToken] = useState('006f5a79afbfcce4005bc94277dbd7fe04bIAAQV2O0QaRtBSU8nQmARpX+VwI1aiPzusLFH1OsOYvjT+hyTkldwYS+IgD+4M2u1TutYAQAAQCV/a1gAgCV/a1gAwCV/a1gBACV/a1g')
//   const [isjoin, setIsJoin] = useState(false)
// 	const [uuid, setUuid] = useState('602d5dce4b16da91d567770e');
// 	const [doctorName, setDoctorName] = useState('');
	
// 	const [calling, setCalling] = useState(false);

// 	React.useEffect(() => {
// 		const user = {
//       userId: '602d5df44b16da91d5677710'
//     };
//     socket.emit("join_patient", user);

//     socket.on("doctors_list", data => {
// 		});
		
// 		socket.on('rtctoken-patient', data => {
// 			if(data) {
// 				ringtoneSound.play();
// 				setCalling(true);
// 				setChannel(data.channel)
// 				setToken(data.refreshToken)
// 				setUuid(data.uuid)
// 				setDoctorName(data.doctorName)
// 			}
// 		})

// 	}, [])

// 	const startVideoCall = () => {
	
// 	}

	
// 	const handleClickJoin = () => {
// 		setCalling(false);

// 		setTimeout(() => {
// 			ringtoneSound.unload();
// 			ringtoneSound.stop();
// 		}, 100);

// 		AgoraRTC.getDevices()
// 		.then(devices => {
// 			const audioDevices = devices.filter(function(device){
// 					return device.kind === "audioinput";
// 			});
// 			const videoDevices = devices.filter(function(device){
// 					return device.kind === "videoinput";
// 			});

// 			var selectedMicrophoneId = audioDevices[0].deviceId;
// 			var selectedCameraId = videoDevices[0].deviceId;
// 			return Promise.all([
// 				AgoraRTC.createCameraVideoTrack({ cameraId: selectedCameraId }),
// 				AgoraRTC.createMicrophoneAudioTrack({ microphoneId: selectedMicrophoneId }),
// 			]);
// 		})
// 		.then(res => {
// 			const [videoTrack, audioTrack] = res;

// 			let options = {
// 				appId: appid,
// 				channel: channel,
// 				token: token,
// 				uid: uuid
// 			}
// 			const val = startBasicCall(options, videoTrack, audioTrack).then(res => {
// 			})
			
// 			setIsJoin(true)
		

// 		}).catch(e => {
// 			toast.error('Permission denied')
// 			dispatch({
// 				type: SET_ERROR,
// 				payload: 'Permission denied'
// 			});
// 		});

   
//   }

//   const handleClickLeave = () => {
//     leaveCall()
//     setIsJoin(false)
//   }

// 	const callRejected = () => {

// 		setCalling(false)
// 		const user = {
// 			appointmentId: '602d5df44b16da91d5677710',
// 			from: 'Patient'
// 		};
// 		socket.emit("rejectCall", user);

// 		setTimeout(() => {
// 			ringtoneSound.unload();
// 			ringtoneSound.stop();
// 		}, 100);
		
// 	}


// 	return (
// 		<div className="">

// 			{calling ? (

// 				<div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)', paddingLeft: '0px'}}>
// 					<div className="modal-dialog modal-dialog-centered" role="document">
// 							<div className="modal-content">
// 									<div className="modal-body" style={{    padding: '40px'}}>
// 											<div className="call-box incoming-box">
// 													<div className="call-wrapper" style={{    height: 'auto'}}>
// 															<div className="call-inner">
// 																	<div className="call-user">
																		
// 																			<h4>{doctorName}</h4>
// 																			<span>Calling ...</span>
// 																	</div>							
// 																	<div className="call-items">
// 																			<a href="javascript:void(0);" className="btn call-item call-end" data-dismiss="modal" aria-label="Close"><i className="material-icons" onClick={callRejected}>call_end</i></a>
																			
// 																			<a href="javascript:void(0);" onClick={handleClickJoin} data-toggle="modal" data-target="#video_call" className="btn call-item call-start"><i className="material-icons">videocam</i></a>
// 																	</div>
// 															</div>
// 													</div>
// 											</div>
											
// 									</div>
// 							</div>
// 						</div>
// 					</div>
// 			) : null}


// 		<div class="modal fade call-modal " id="video_call">
// 			<div class="modal-dialog video-call-modal modal-dialog-centered" role="document">
// 				<div class="modal-content">
// 					<div class="modal-body">

// 						<div class="call-wrapper">
// 						<div class="call-main-row">
// 							<div class="call-main-wrapper">
// 								<div class="call-view">
// 									<div class="call-window">

// 										<div class="fixed-header">
// 											<div class="navbar">
// 												<div class="user-details">
// 													<div class="float-left user-img">
														
// 													</div>
// 													<div class="user-info float-left">
// 														<a><span>{doctorName}</span></a>
// 													</div>



// 												</div>

// 											</div>
// 										</div>

// 										<div class="call-contents">
// 											<div class="call-content-wrap">
// 												<div class="user-video">
// 													<div className='video-agora-box'>
														
// 													</div>




// 												</div>
// 												<div class="my-video">
// 													<ul>
// 														<li style={{height: isMobile ? '50px' : '120px'}}>
// 															<div id='video-agora-local'></div>

// 														</li>
// 													</ul>
// 												</div>
// 											</div>
// 										</div>

// 										<div class="call-footer">
// 											<div class="call-icons">
// 												<ul class="call-items">
											
// 												</ul>
// 												<div class="end-call" style={{top: '-10px'}}>
// 													<a href="javascript:void(0);"   class="btn call-item call-end" data-dismiss="modal" aria-label="Close" onClick={() => handleClickLeave()}>
// 														<i class="material-icons">call_end</i>
// 													</a>
// 												</div>
// 											</div>
// 										</div>

// 									</div>
// 								</div>

// 							</div>
// 						</div>
// 					</div>


// 					</div>
// 				</div>
// 			</div>
// 		</div>

// 		</div>
// 	)
// }

// export default Test


import React from 'react'

function Test() {
	return (
		<div>
			
		</div>
	)
}

export default Test
