import React, {useContext, useEffect,useState} from 'react';
import {
	StyledContainer,
	StyledContainerReport,
	StyledButtonGroup,
	StyledButton 
} from './styles';
import {
	Link
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { Context } from '../../../../store';

import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';
import { 
	getPatientInfo,
	getIpdHistoryOfPatient,
	getAppointmentHistory,
	getOrgPatientReport
} from '../../../../api/organization';

import { getMedicalHistoryDataAPI } from '../../../../api/doctor';
import '../IpdWard/Patient/appointment.css';
import HealthRecordsChart from '../../Components/HealthRecordsChart';
import PatientInfo from '../../Components/PatientInfo';
import AppointmentCard from '../../../../components/Appointments';
import MedicalHistoryComponent from '../../../../components/medicalHistory';
import IpdList from '../../../../components/IpdList';
import CardComponent from '../../../../components/Card';
import ECGchart from '../../../../components/EcgGraph';
import MedicalReports from './MedicalReports';

function ViewPatient(props) {
	const [state, dispatch] = useContext(Context);
	const [historyAppointments,setHistoryAppointments] = useState([])
	const [ipdHistory, setIpdHistory] = useState([]);
	const [isPatietnInIpd, setIsPatietnInIpd] = useState(true);
	const [ecgWidth, setEcgWidth] = useState();
	const permissions = JSON.parse(localStorage.getItem('subUserPermission'))
	
	const [patientInfo, SetPatientInfo] = useState({
		dob: '',
		gender: '',
		phoneNumber: '',
		name: '',
		address: '',
		profileImage: null,
		org_name: '',
		weight: '',
		_id: null
	});
	
	const [medicalHistory, setMedicalHistory] = useState({
		allergieData: [],
		healthConditionData: [],
		medicationData: [],
		surgeriesData: [],
		vaccinationData: []
	});
    const [serviceDetails, setServicesDetails] = useState({
		serviceDate: '',
        serviceName: '',
        serviceAmount: '',
        serviceAddedBy: JSON.parse(localStorage.getItem('subuser'))._id,
		addedByApi : false,
    })

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://cdn.jsdelivr.net/npm/chart.js@2.9.4/dist/Chart.min.js";
		script.async = true;

		document.body.appendChild(script);
		
		if(props.match.params && props.match.params.id) { 
			loader(true);
			
			getPatientInfo(props.match.params.id).then((res) => {
				// loader(false);
				SetPatientInfo({
					dob: res.data.dob,
					email: res.data.email,
					gender: res.data.gender,
					phoneNumber: res.data.phoneNumber,
					name: res.data.name,
					address: res.data.address,
					profileImage: res.data.profileImage,
					org_name: res.data.org_name,
					_id: res.data._id,
					weight: res.data.weight
				})
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
			})
			getIpdHistoryOfPatient(props.match.params.id).then((res) => {
				let currentIpd = []
				res.data.map((item)=>{
					if(!item.dischargeDate){
						currentIpd.push(item)
					}
				})
				if (res.data.length) {
					setIsPatietnInIpd(currentIpd.length ? true : false)
				}
				else {
					setIsPatietnInIpd(false)
				}
				setIpdHistory(res.data)
			}).catch((err) => {
				loader(false)
			})
			getAppointments();
			getMedicalHistoryDataAPI(props.match.params.id).then(res => {
				setMedicalHistory(res.data);
				// loader(false);
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
	}, [])

	useEffect(()=>{
		const k = document.getElementById('ecg-graph-width-appointmentId').offsetWidth;
		setEcgWidth(k-20)
	},[])


	const getAppointments = () => {
		getAppointmentHistory(props.match.params.id).then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setHistoryAppointments(res.data);
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const openIpdPatientView = (id) => {
		props.history.push(`/subuser/view-patient/${id}/${props.match.params.id}`)
	}
	const handleClickAppointment = (data) => {
		props.history.push(`/subuser/view-appointment/${data?._id}/${data?.patientId?._id}`);
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
        <div>
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="col-md-12 row">
                                <Link
                                    to={'/subuser/dashboard'}
                                    style={{
                                        border: '1px solid #5156be',
                                        borderRadius: '5px',
                                        padding: '10px',
                                        marginBottom: '20px',
                                        color: '#5156be',
                                        fontSize: '17px',
                                        fontWeight: '500'
                                    }}
                                >
                                    <i className="fa fa-arrow-left pr-2" />
                                    Back To Dashboard
                                </Link>
								{!isPatietnInIpd ?
									<div className='ml-auto d-flex'>
										{permissions?.includes("Admin") || permissions?.includes("OPD") ? <Link
											to={`/subuser/doctor-listing/${props.match.params.id}`}
											style={{
												border: '1px solid #5156be',
												borderRadius: '5px',
												padding: '10px',
												marginBottom: '20px',
												color: '#FFF',
												backgroundColor: "#5156be",
												fontSize: '17px',
												fontWeight: '400',
												marginRight: "20px"
											}}
										>
											Book an appointment
										</Link>: null}
										{permissions?.includes("Admin") || permissions?.includes("Admit a patient into IPD") ? <Link
											to={`/subuser/ipdward/add-patient/${props.match.params.id}`}
											style={{
												border: '1px solid #5156be',
												borderRadius: '5px',
												padding: '10px',
												marginBottom: '20px',
												backgroundColor: "#5156be",
												color: '#FFF',
												fontSize: '17px',
												fontWeight: '400'
											}}
										>
											Admit patient into IPD
										</Link>: null}
									</div> : null
								}
                            </div>

                            <h3 className="mb-4 mt-4">Patient details </h3>
                            <StyledContainer>
                                <div className="content">
                                    <div className="container-fluid pr-0">
                                        <div className="container-fluid-card row p-0 d-flex col-md-12">
                                            <div className='col-md-9'>
                                                <HealthRecordsChart  showAdd={true} patientId={props.match.params.id} />
												<div className="p-3">
													<h2 className = "cards-main-heading mb-3">Medical History</h2>
													<MedicalHistoryComponent medicalHistory={medicalHistory} />
												</div>
                                            </div>
                                            <div className='col-md' style={{ padding: "0px" }}>
                                                <PatientInfo patientInfo={patientInfo} />
                                                {/* <ServiceList data={patientDetails.services} /> */}
												<AppointmentCard
													heading='Previous appointments'
													data={historyAppointments}
													keyItem="Previous"
													handleClickAppointment={handleClickAppointment}
												/>
												<IpdList data={ipdHistory} openIpdPatientView={openIpdPatientView}/>
												<CardComponent className='p-2 ecg-graph-width-appointment ecg-min-height mb-2' id='ecg-graph-width-appointmentId'>
													{ecgWidth && patientInfo._id && <ECGchart
														width={ecgWidth}
														id={patientInfo._id}
													/>}
												</CardComponent>
												
												<MedicalReports patiendId={props.match.params.id} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </StyledContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ViewPatient;