import styled from 'styled-components';

export const StyledVirtualSlider = styled.div`
	.virtual-emed4u {
		max-height: 550px;
    height: 550px;
		@media (max-width: 767px){  
			max-height: 750px;
			height: 750px;
		}
		background-image: linear-gradient(to bottom right, #077fb7, #27aa6b);
		.row {
			padding: 5% 15%;
			.virtual-img {
				height: 425px;
				@media (max-width: 767px){  
					height: 230px;
				}
				img {
					height: 100%;
				}
			}
			h2 {
				color: #fff;
				padding: 3% 0%;
			}
			p {
				color: #fff;
			}
			button {
				margin-top: 20px;
				background: #ffffff;
				color: #25a96c;
				border: none;
				line-height: initial;
				text-transform: uppercase;
				border-radius: 30px;
				padding: 17px 50px 15.5px 25px;
				position: relative;
				-webkit-transition: 0.5s;
				-webkit-transition: 0.5s;
				transition: 0.5s;
				font-size: 14px;
				font-family: "Rubik",sans-serif;
				font-weight: 500;
				display: -webkit-inline-box;
				display: -webkit-inline-flex;
				display: -ms-inline-flexbox;
				display: inline-flex;
				font-weight: 600;
				position: relative;
				i {
					position: absolute;
					right: 10px;
					top: 50%;
					-webkit-transform: translateY(-50%);
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					transform: translateY(-50%);
					text-align: center;
					display: inline-block;
					height: 33px;
					width: 33px;
					line-height: 33px;
					color: #ffffff;
					border-radius: 50%;
					background-color: #25a96c;
					-webkit-transition: 0.5s;
					-webkit-transition: 0.5s;
					transition: 0.5s;
				}
			}
		}
	}
`;