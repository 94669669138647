import styled from 'styled-components';
import { ReactComponent as Search } from '../../../assets/img/search.svg';
import { ReactComponent as Wisker } from '../../../assets/img/left-wisker.svg';
import { ReactComponent as Eye } from '../../../assets/img/eye.svg';

export const StyledBlogList = styled.div`
  .empty-blog {
    justify-content: center;
    display: flex;
    height: 100px;
    padding-top: 50px;
  }
`;


export const Wrapper = styled.div`
  margin: 32px 80px 42px;
  @media (max-width: 767px){ 
    margin: 32px 0px 42px 30px;
  }
`;

export const CenteredContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


export const HeadingContent = styled(CenteredContent)`
`;

export const Heading = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 104px;
  line-height: 136px;
  
  background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 800px) {
    margin: 0 0 8px;
  }

  @media (min-width: 320px) and (max-width: 700px) {
    font-size: 12vw;
    line-height: 16vw;
  }

  @media (max-width: 320px) {
    font-size: 32px;
    line-height: 42px;
  }

`;


export const InputWrapper = styled.div`
    position: relative;
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 11px;
    left: 16px;
`;

export const SearchIcon = styled(props => <Search {...props}/>)`
`;

export const Input = styled.input`
    border: 2px solid #CCCCCC;
    border-radius: 8px;
    padding-left: 48px;
    background-size: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5E5E5E;
    width: 300px;
    height: 48px;
`;

export const BigImageItemMain = styled.div`
  width: 100%;
  height: 320px; 
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  &.small {
    width: 388px;
    height: 212px;
    cursor: pointer;
  }
`;

export const BigImageItem = styled.div`
  width: 100%;
  height: 320px; 
  img {
    width: 100%;
    height: 100%;
  }
  &.small {
    width: 388px;
    height: 212px;
    cursor: pointer;
  }
`;

export const ColoredText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0 6px;
`;

export const Date = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5E5E5E;
  margin: 8px 0;
  &.small {
    margin: 0;
  }
`;

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: #2B2B2B;
  width: 90%;
  margin: 16px 0;
  cursor: pointer;

  &.small {
    margin: 16px 0 0;
    width: auto;
    font-size: 32px;
    line-height: 42px;
  }
  @media (max-width: 767px){ 
    font-size: 22px !important;
    line-height: 25px !important;
  }
`;

export const Credit = styled.p`

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5E5E5E;
  display: flex;
  margin: 24px 0;

  &.colored {
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0 5px;
  }
  &.small {
    margin: 11px 0 0;
  }
  @media (max-width: 767px){ 
    display: block;
  }
`;
export const Section = styled.div`
  width: -webkit-fill-available;
  z-index: 999;
  cursor: pointer;
  &.pad {
    padding: 24px;
  }
`;


export const HighLightedContentWrapper =  styled(CenteredContent)`
  margin: 40px 0 88px; 
  @media (max-width: 767px){

    display: none;
    margin-right: 30px;
  }
`;
export const WiskerWrapper = styled.div`
  display: flex;
`;

export const LeftWisker = styled(props => <Wisker {...props}/>)`
`;

export const RightWisker = styled(props => <Wisker {...props}/>)`
  transform: rotate(180deg);
`;


export const SingleCardWrapper = styled.div`
  width: 388px;
  // height: 431px;
  margin-bottom: 54px;
  z-index: 9999;
  margin-right: 40px;
`;

export const ListingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const EyeIcon = styled(props => <Eye {...props}/>)`
`;

export const EyeViewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 18px 0 0;
  &.no-margin {
    margin: 0;
  }
`;

export const ViewText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2B2B2B;
  margin-left: 7px;
`;

export const IFrame = styled.iframe`
  width: 693px;
  height: 379px;
  frameborder: 0;
  border: none;
  filter: drop-shadow(-4px -4px 15px rgba(192, 192, 192, 0.25)) drop-shadow(4px 4px 15px rgba(192, 192, 192, 0.25));
  border-radius: 8px;
  
  &.small {
    width: 388px;
    height: 212px;
  }
`;
