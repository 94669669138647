import React from 'react';
import CardComponent from '../Card';
import moment from 'moment';

import LineChartComponent from './LineChart'
import { determineLabel, determineSubAdminLabel, deleteData, getBmiClass, getBmiTitle  } from '../../graphUtils';
import FilterByDate from '../DateFilter/FilterByDate';
import Pagination from '../Pagination';

const GraphDetailsModal = (props) => {
    const { showDetailsModal,subAdmin, setPaginateData,currentFilter, allSearchData, paginateData,currentItem, setShowDetailsModal, handleClearValue, renderFullInfo, allData, onChangeDate, searchDate, setSearchDate, onReset, deleteItem,applyFilter  } = props;
    const pageChangeBP = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setPaginateData(data)
	}
    const renderStatus = (title) => {
		switch (title) {
			case 'Pulse':
				return ' per min '
			case 'Blood sugar':
				return ' mg/dl'
			case 'Temperature':
				return ' °C'
			case 'Respiratory rate':
				return ' per min '
			case 'Oxygen Saturation':
				return '%'
			default:
				break;
		}
	}

    const headingStatus = (title) => {
		switch (title) {
			case 'Pulse':
				return '(per min)'
			case 'Blood Sugar':
				return '(mg/dl)'
			case 'Temperature':
				return '(°C)'
			case 'Respiratory Rate':
				return '(per min)'
			case 'SPO2':
				return '(%)'
            case 'Blood pressure':
                return '(mmHg)'
			default:
				break;
		}
	}
    return (
        <div className="modal fade custom-modal  none-border show" style={{
            display: showDetailsModal ? 'block' : 'none', backdropFilter: 'blur(1px)',
            background: 'rgb(104 71 99 / 24%)'
        }}
        data-backdrop="static"
        >
            <div className="modal-dialog modal-dialog-centered show-graph-details-modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{currentItem.title == 'Pulse' ? 'Heart rate' : currentItem.title} {headingStatus(currentItem.title)}</h4>
                        <i style={{fontSize:"22px"}} class="btn fas fa-times ml-auto" onClick={() =>{
							document.body.style.overflowY = 'auto';
                            setShowDetailsModal(false);
                            setSearchDate('');
                            handleClearValue();
                        }}></i>
                    </div>
                    <div className="modal-body" style={{height: '84vh',overflow:'scroll'}}>
                        <CardComponent className="p-4 mb-4">
                            <div className="row mb-4">
                                <div className='col-md-12'>
                                    {renderFullInfo.showChart ?
                                        renderFullInfo.isDoubleLineChart ?
                                                <LineChartComponent keyItem={`full-${currentItem.id}`} Linedata={allData} type={currentItem.type} color={currentItem.color} label={currentItem.title} showfilter={true} doubleLineChart height={350} applyFilter={applyFilter} currentFilter={currentFilter}/>
                                            : <LineChartComponent keyItem={`full-${currentItem.id}`} Linedata={allData} type={currentItem.type} color={currentItem.color} label={currentItem.title} showfilter={true} determineLabel={subAdmin ? determineSubAdminLabel(currentItem.name) : determineLabel(currentItem.name)} height={350} applyFilter={applyFilter} currentFilter={currentFilter} />
                                        : renderFullInfo.showImage ?
                                            <div className="col-md-12">
                                                <img src={renderFullInfo.url} />
                                            </div> : null
                                    }
                                {renderFullInfo.showChart ?
                                    <div className='col-md-7 col-lg-8 col-xl-8 text-center d-flex mt-4'>
                                        <FilterByDate
                                            onChangeDate={onChangeDate}
                                            searchDate={searchDate}
                                            setSearchDate={setSearchDate}
                                            onReset={onReset}
                                        />
                                    </div>
                                    : null}
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-md-12 mt-4 mb-4">{allData.length > 0 && <h2>{currentItem.title == 'Pulse' ? 'Heart rate' : currentItem.title} readings</h2>}</div>
                                <>
                                    {searchDate ?
                                        allSearchData?.length ?
                                            allSearchData.map(item => {
                                                return <div className={currentItem.title !== 'BMI'? "col-md-6 col-xs-12 postion-relative":`p-4 mb-4 color-white bmi-cal ${getBmiClass(item)}` }>
                                                    <CardComponent className="p-4 mb-4">
                                                    {renderFullInfo.showData.map((data)=> 
                                                        <h5>{currentItem.title !== 'BMI'? data.label : `${data.label} ${getBmiTitle(item)}`} {data.label === 'Date:'? moment(item[data.valueTobeDisplayed]).format('DD/MM/YYYY, hh:mm a') :item[data.valueTobeDisplayed]}</h5>
                                                    )}
                                                    </CardComponent>
                                                    <button className="remove-heath" onClick={() => deleteData(item._id, renderFullInfo.title, deleteItem)}><i className="fas fa-times"></i></button>
                                                </div>
                                            })
                                            : <div className='col-md-12'><h2 className='text-center'>No Records Found </h2></div>
                                        :
                                        paginateData.map(item => {
                                            return <div className="col-md-6 col-xs-12 postion-relative">
                                                <CardComponent className="p-4 mb-4">
                                                {renderFullInfo.showData.map((data)=> 
                                                        <h5>{data.label} {data.label === 'Date:'? moment(item[data.valueTobeDisplayed]).format('DD/MM/YYYY, hh:mm a') :item[data.valueTobeDisplayed]}{ data.label == 'Heart rate:' ||  data.label == 'Level:' || data.label == 'Blood sugar:' || data.label == 'Temperature:' || data.label == 'Respiratory Rate:' ? renderStatus(renderFullInfo.title) : ''}</h5>
                                                    )}
                                                </CardComponent>
                                                {!subAdmin ? <button className="remove-heath" onClick={() => deleteData(item._id, renderFullInfo.title,deleteItem)}><i className="fas fa-times"></i></button> : null }
                                            </div>
                                        })

                                    }
                                </>

                            </div>

                            {allData?.length > 10 ? <Pagination items={allData} onChangePage={pageChangeBP} /> : null}
                        </CardComponent>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GraphDetailsModal;