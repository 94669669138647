import moment from 'moment'; 

export const tabName = (name) =>  {
    switch (name) {
        case 'Blood pressure':
            return 'all_Bp';
        case 'Blood sugar':
            return 'all_Bsugar';
        case 'Oxygen Saturation':
            return 'all_OxygenLevel';
        case 'Pulse':
            return 'all_Pluse';
        case 'Respiratory rate':
            return 'all_RespiratoryRates';
        case 'Temperature':
            return 'all_Temperature';
        case 'Lipid Profile':
            return 'all_Cholesterol';
        case 'Joint Movements':
            return 'all_Joint';
        case 'Pain level':
            return 'all_Pain';
        default:
            return null;
    }
}


export const menus1 = [
	{id: 1, name: 'Blood pressure'},
	{id: 9, name: 'Pulse'},
	{id: 7, name: 'Oxygen Saturation'},
	{id: 10, name: 'Respiratory rate'},
	{id: 2, name: 'Blood sugar'},
	{id: 3, name: 'Temperature'}
];

export const menus2 = [
	{id: 1, name: 'Lipid Profile'}
];

export const menus3 = [
	{id: 1, name: 'Joint Movements'},
	{id: 2, name: 'Pain level'}
];

export const menus4 = [
	{id: 1, name: 'Weight'},
	{id: 6, name: 'BMI'},
	{id: 2, name: 'Waist circumference'},
	{id: 3, name: 'Exercise'},
	{id: 4, name: 'Nutrition'},
	{id: 5, name: 'Sleep'},
];

export const menusLineChart = [
	{id: 1, value: '1D', key: 'todaysData'},
	{id: 2, value: '3D', key: 'threeDaysData'},
	{id: 3, value: '1W', key: 'oneWeeksData'},
	{id: 4, value: '1M', key: 'oneMonthData'},
	{id: 5, value: '3M', key: 'threeMonthData'},
	{id: 6, value: '6M', key: 'sixMonthData'},
];


export const determineLabel = (name) => {
    switch(name){
        case 'all_Bsugar':
            return {label: 'Blood sugar' , color: '#FEF862', graphData: 'how_much'}
        case 'all_OxygenLevel':
            return {label: 'Oxygen Saturation %' , color: '#FEF862', graphData: 'level'}
        case 'all_Pluse':
            return {label: 'Pulse per min' , color: '#EBC2FC', graphData: 'pluse'}
        case 'all_RespiratoryRates':
            return {label: 'Respiratory Rate (breaths/min)' , color: '#D99138', graphData: 'respiratory_rates'}
        case 'all_Temperature':
            return {label: 'Temperature' , color: '#6DFAFB', graphData: 'temperature'}
        case 'all_Food':
            return {label: 'Nutrition (duration)' , color: 'rgba(1, 59, 97, 1)', graphData: 'duration'}
        case 'bmiall':
            return {label: 'BMI' , color: 'rgba(1, 59, 97, 1)'}
        case 'all_Waist':
            return {label: 'Waist circumference (cm)' , color: 'rgba(1, 59, 97, 1)', graphData: 'waist'}
        case 'all_Weight':
            return {label: 'Weight' , color: 'rgba(1, 59, 97, 1)', graphData: 'weight'}
        case 'ipd_graph':
            return {label: 'IPD', color: 'rgba(1, 59, 97, 1)', graphData: 'ipd' }
        case 'opd_graph':
            return { label: 'OPD', color: 'rgba(1, 59, 97, 1)', graphData: 'opd' }
        case 'all_Cholesterol':
            return { label : 'Lipid Profile', color: 'rgba(1, 59, 97, 1)', graphData: ['HDL_C','LDL_C','VLDL'] }
        default:
            return null;
    }
}

export const determineSubAdminLabel = (name) => {
    switch(name){
        case 'all_Bsugar_detail':
            return {label: 'Blood sugar' , color: '#FEF862', graphData: 'how_much'}
        case 'all_OxygenLevel_detail':
            return {label: 'Oxygen Saturation %' , color: '#FEF862', graphData: 'level'}
        case 'all_Pluse_detail':
            return {label: 'Pulse per min' , color: '#EBC2FC', graphData: 'pluse'}
        case 'all_RespiratoryRates_detail':
            return {label: 'Respiratory Rate (breaths/min)' , color: '#D99138', graphData: 'respiratory_rates'}
        case 'all_Temperature_detail':
            return {label: 'Temperature' , color: '#6DFAFB', graphData: 'temperature'}
        case 'all_Food':
            return {label: 'Nutrition (duration)' , color: 'rgba(1, 59, 97, 1)', graphData: 'duration'}
        case 'bmiall':
            return {label: 'BMI' , color: 'rgba(1, 59, 97, 1)'}
        case 'all_Waist':
            return {label: 'Waist circumference (cm)' , color: 'rgba(1, 59, 97, 1)', graphData: 'waist'}
        case 'all_Weight':
            return {label: 'Weight' , color: 'rgba(1, 59, 97, 1)', graphData: 'weight'}
        case 'all_Cholesterol_detail':
            return { label: 'Lipid Profile', color: 'rgba(1, 59, 97, 1)', graphData: ['HDL_C', 'LDL_C', 'VLDL'] }
        default:
            return null;
    }
}

export const getDoubleLineChart = (value, setLineData,setSysAvg,setDiaAvg) => {
    var lineData = {};
    var dates = [];
    var times = [];
    var sys_BP = [];
    var dia_BP =[];
    let h_Details = value;
        
            let h_len = Object.keys(h_Details).length

            for (var i =0; i<h_len; i++){
                         
                var time_Stamp = new Date(h_Details[i].createdAt);
             
                     
                dates[i] = moment(h_Details[i].createdAt).format('hh:mm A, DD/MM/YY');//(time_Stamp.toISOString()).substr(0,10);
                times[i] = (time_Stamp.toISOString()).substr(11,5);
                sys_BP.push(parseInt(h_Details[i].sys_blood_pressure));
                dia_BP.push(parseInt(h_Details[i].dia_blood_pressure));
        
            }
            const reducer = (accumulator, curr) => accumulator + curr;
            const sysAvg = sys_BP.reduce(reducer) / sys_BP.length;
            const diaAvg = dia_BP.reduce(reducer) / dia_BP.length;
            setDiaAvg(parseFloat(diaAvg).toFixed(2));
            setSysAvg(parseFloat(sysAvg).toFixed(2));
            lineData = {
                labels: dates,
                datasets: [
                    {
                        label: "Systolic",
                        data: sys_BP,
                        fill:true,
                        pointRadius: 2,
                        // backgroundColor: "#ec1378",
                        backgroundColor:"rgba(75,192,192,0.2)",
                        borderWidth: 1.5,
                        // borderColor: "#DC143C"
                        borderColor:"rgba(75,192,192,1)"
                    },
                    {
                        label: "Diastolic",
                        data: dia_BP,
                        pointRadius: 2,
                        fill:true,
                        backgroundColor: "#6181ca",
                        borderWidth: 1.5,
                        borderColor: "#013b61",
                    }
                ],
                fill: true
            };
            setLineData(lineData);
}

export const getSingleLineChart = (value, obj, setResRateLineData,setAvg,borderColor,backgroundColor) => {
    var lineData = {};
    var dates = [];
    var times = [];
    var sys_BP = [];
    let h_Details = value;
            let h_len = Object.keys(h_Details).length

            for (var i =0; i<h_len; i++){
                         
                var time_Stamp = new Date(h_Details[i].createdAt);
             
                     
                dates[i] = moment(h_Details[i].createdAt).format('hh:mm A, DD/MM/YY');//(time_Stamp.toISOString()).substr(0,10);
                times[i] = (time_Stamp.toISOString()).substr(11,5);
                sys_BP.push((h_Details[i][obj.graphData]));
            }
            const reducer = (accumulator, curr) => parseInt(accumulator) + parseInt(curr);
            const avg = sys_BP.reduce(reducer) / sys_BP.length;

            setAvg(parseFloat(avg).toFixed(2))
            lineData = {
                labels: dates,
                datasets: [
                    {
                        label: "Systolic",
                        data: sys_BP,
                        fill: true,
                        borderColor: borderColor,
                        pointRadius: 2,
                        borderWidth: 1.5,
                        backgroundColor: backgroundColor,
                    },
                ],
                fill: true,
                backgroundColor: backgroundColor,
            };
            setResRateLineData(lineData);
}

export const addData = (name, data, setData, addDetails) => {
    switch(name){
        case 'Blood pressure':
            return addDetails(data, 'bp_detail', setData, "all_Bp");
        case 'Blood sugar':
            return addDetails(data,'add_Bsugar', setData, "all_Bsugar");
        case 'Oxygen Saturation':
            return addDetails(data,'add_OxygenLevel', setData, "all_OxygenLevel");
        case 'Pulse':
            return addDetails(data,'add_Pluse', setData, "all_Pluse");
        case 'Respiratory rate':
            return addDetails(data,'add_RespiratoryRates', setData, "all_RespiratoryRates");
        case 'Temperature':
            return addDetails(data,'add_Temperature', setData, "all_Temperature");
        case 'Lipid Profile':
            return addDetails(data,'add_Cholesterol', setData, "all_Cholesterol");
        case 'Joint Movements':
            return addDetails(data,'add_Joint', setData, "all_Joint");
        case 'Pain level':
            return addDetails(data,'add_Pain', setData, "all_Pain");
        default:
            return null;
    }
}
export const bpData = {
    showChart: true,
    isDoubleLineChart: true,
    title: 'Blood pressure',
    inputFields: [  
        {
            id: 1, label:'Systolic (mmHg)', name:'sys_blood_pressure', validation:'systolic',   
        },
        {
            id: 2, label:'Diastolic (mmHg)', name:'dia_blood_pressure', validation:'diastolic',   
        },
        {
            id: 3, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Systolic (mmHg):',valueTobeDisplayed:'sys_blood_pressure' 
        },
        {
            id: 2, label:'Diastolic (mmHg):', valueTobeDisplayed:'dia_blood_pressure'
        },
        {
            id: 3, label:'Notes:', valueTobeDisplayed:'notes'
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
}  

const options = [
    { id: 1, label: 'Fasting', value: 'Fasting' },
    { id: 2, label: '2 hours Post Breakfast', value: '2 hours Post Breakfast' },
    { id: 1, label: 'Pre Lunch', value: 'Pre Lunch' },
    { id: 1, label: '2 hours Post Lunch', value: '2 hours Post Lunch' },
    { id: 1, label: 'Pre Dinner', value: 'Pre Dinner' },
    { id: 1, label: '2 hours Post Dinner', value: '2 hours Post Dinner' },
    { id: 1, label: 'Bed Time', value: 'Bed Time' },
    { id: 1, label: '3 AM', value: '3 AM' },
    { id: 1, label: 'Random', value: 'Random' }
];

export const bloodSugarData = {
    showChart: true,
    isDoubleLineChart: false,
    title: 'Blood sugar',
    inputFields: [ 
        {
            id: 1, label:'Reading Type', name:'blood_sugar', selectType: true, options: options
        }, 
        {
            id: 2, label:'Blood sugar (mg/dl)', name:'how_much', validation: 'blood_sugar',
        },
        {
            id: 3, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Blood sugar:', valueTobeDisplayed:'how_much',
        },
        {
            id: 2, label:'Reading Type:', valueTobeDisplayed:'blood_sugar',
        }, 
        {
            id: 3, label:'Notes:', valueTobeDisplayed:'notes',   
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
} 


const oxygenOptions = [
    { id: 1, label: 'Pulse Oximeter Method', value: 'Pulse Oximeter Method' },
    { id: 2, label: 'Arterial Blood Gas', value: 'Arterial Blood Gas' }
]

const locationOptions = [
    { id: 1, label: 'Wrist', value: 'Wrist' },
    { id: 2, label: 'Neck', value: 'Neck' }
]

export const oxygenData = {
    showChart: true,
    isDoubleLineChart: false,
    title: 'Oxygen Saturation',
    inputFields: [ 
        {
            id: 1, label:'Reading method', name:'reading_method', selectType: true, options: oxygenOptions
        }, 
        {
            id: 2, label:'Level (%)', name:'level', validation: 'oxygen',
        },
        {
            id: 3, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Level:', valueTobeDisplayed:'level',
        }, 
        {
            id: 2, label:'Reading method:', valueTobeDisplayed:'reading_method',
        },
        {
            id: 3, label:'Notes:', valueTobeDisplayed:'notes',   
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
}  

export const PulseData = {
    showChart: true,
    isDoubleLineChart: false,
    title: 'Pulse',
    inputFields: [ 
        {
            id: 1, label:'Pulse per min', name:'pluse', validation: 'pulse',
        },
        {
            id: 2, label:'Location', name:'location', selectType: true, options: locationOptions
        }, 
        // {
        //     id: 2, label:'Location', name:'location',
        // },
        {
            id: 3, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Heart rate:', valueTobeDisplayed:'pluse',
        }, 
        {
            id: 2, label:'Location:', valueTobeDisplayed:'location',
        },
        {
            id: 3, label:'Notes:', valueTobeDisplayed:'notes',   
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
} 

export const respiratoryData = {
    showChart: true,
    title: 'Respiratory rate',
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Respiratory rate (min)', name:'respiratory_rates', validation: 'respiratory'
        }, 
        {
            id: 2, label:'Notes', name:'notes',   
        }
       ],
    showData: [
        {
            id: 1, label:'Respiratory Rate:', valueTobeDisplayed:'respiratory_rates',
        }, 
        {
            id: 2, label:'Notes:', valueTobeDisplayed:'notes',
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
} 

const tempOptions = [
    { id: 1, label: 'Celsius', value: 'Celsius' },
// {id: 2, label : 'Fahrenheit', value: 'Fahrenheit'}
]

export const tempData = {
    title: 'Temperature',
    showChart: true,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Temperature (°C)', name:'temperature', validation: 'temperature'
        }, 
        {
            id: 2, label:'Temperature Unit', name:'temperature_unit', selectType: true, options: tempOptions
        },
        {
            id: 3, label:'Notes', name:'notes',   
        }
       ],
    showData: [
        {
            id: 1, label:'Temperature:', valueTobeDisplayed:'temperature',
        }, 
        {
            id: 2, label:'Temperature Unit:', valueTobeDisplayed:'temperature_unit',
        },
        {
            id: 3, label:'Notes:', valueTobeDisplayed:'notes',
        },
        {
            id: 4, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
} 

export const lipidData = {
    showImage: true,
    title: 'Lipid Profile',
    url: '/assets/img/chole.jpeg',
    showChart: false,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Total Cholesterol (mg/dl)', name:'total_cholesterol',validation: 'total_cholesterol'
        }, 
        {
            id: 2, label:'HDL-C (mg/dl)', name:'HDL_C',validation: 'HDL_C'
        },
        {
            id: 3, label:'LDL_C', name:'LDL_C',validation: 'LDL_C'
        }, 
        {
            id: 4, label:'Triglycerides (mg/dl)', name:'triglycerides',validation: 'triglycerides'
        },
        {
            id: 5, label:'VLDL (mg/dl)', name:'VLDL',validation: 'VLDL'
        },
        {
            id: 5, label:'Notes', name:'notes',   
        }
       ],
    showData: [
        {
            id: 1, label:'Total Cholesterol (mg/dl):', valueTobeDisplayed:'total_cholesterol',
        }, 
        {
            id: 2, label:'HDL-C (mg/dl):', valueTobeDisplayed:'HDL_C',
        },
        {
            id: 3, label:'Triglycerides (mg/dl):', valueTobeDisplayed:'triglycerides',
        }, 
        {
            id: 5, label:'VLDL (mg/dl)', valueTobeDisplayed:'VLDL',
        },
        {
            id: 5, label:'Notes:', valueTobeDisplayed:'notes',
        },
        {
            id: 6, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
}

const jointOptions = [
    { id: 1, label: 'No joint stiffness', value: 'No joint stiffness' },
    { id: 2, label: 'Slight joint stiffness', value: 'Slight joint stiffness' },
    { id: 3, label: 'Moderate joint stiffness - pain affecting regular movement', value: 'Moderate joint stiffness - pain affecting regular movement' },
    { id: 4, label: 'Significant joint stiffness - able to move but with lot of pain', value: 'Significant joint stiffness - able to move but with lot of pain' },
    { id: 5, label: 'Severe joint stiffness - almost unable to move. Severe pain', value: 'Severe joint stiffness - almost unable to move. Severe pain' },
]

export const jointData = {
    showChart: false,
    title: 'Joint Movements',

    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Joint stiffness level', name:'level', selectType: true, options: jointOptions,
        }, 
        {
            id: 2, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Joint stiffness level:', valueTobeDisplayed:'level',
        }, 
        {
            id: 2, label:'Notes:', valueTobeDisplayed:'notes',   
        },
        {
            id: 3, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
}

const painOptions = [
    { id: 1, label: 'No Pain', value: 'No Pain' },
    { id: 2, label: 'Slight joint stiffness', value: 'Slight joint stiffness' },
    { id: 3, label: 'Minor - Very Mild', value: 'Minor - Very Mild' },
    { id: 4, label: 'Minor - Discomforting', value: 'Minor - Discomforting' },
    { id: 5, label: 'Minor - Tolerable', value: 'Minor - Tolerable' },
    { id: 6, label: 'Moderate - Distressing', value: 'Moderate - Distressing' },
    { id: 7, label: 'Moderate - Very Distressing', value: 'Moderate - Very Distressing' },
    { id: 8, label: 'Moderate - Intense', value: 'Moderate - Intense' },
    { id: 9, label: 'Severe - Very Intense', value: 'Severe - Very Intense' },
    { id: 10, label: 'Severe - Utterly Horrible', value: 'Severe - Utterly Horrible' },
    { id: 11, label: 'Severe - Unbearable', value: 'Severe - Unbearable' },
    { id: 12, label: 'Severe - Unimaginable', value: 'Severe - Unimaginable' }
]


export const painData = {
    title: 'Pain level',
    showChart: false,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Pain level', name:'intensity', selectType: true , options: painOptions,
        }, 
        {
            id: 2, label:'Duration (min)', name:'duration',
        },
        {
            id: 3, label:'Location', name:'location',
        },
        {
            id: 4, label:'Trigger', name:'trigger',
        },
        {
            id: 5, label:'Relief', name:'relief',
        },
        {
            id: 6, label:'Notes', name:'notes',   
        }],
    showData: [
        {
            id: 1, label:'Pain level:', valueTobeDisplayed:'pluse',
        }, 
        {
            id: 2, label:'Duration (min):', valueTobeDisplayed:'duration',
        },
        {
            id: 3, label:'Location', valueTobeDisplayed:'location',
        },
        {
            id: 4, label:'Trigger', valueTobeDisplayed:'trigger',
        },
        {
            id: 5, label:'Relief', valueTobeDisplayed:'relief',
        },
        {
            id: 6, label:'Notes:', valueTobeDisplayed:'notes',   
        },
        {
            id: 7, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
} 

export const weightData = {
    showChart: true,
    isDoubleLineChart: false,
    inputFields: [ 
        {
            id: 1, label:'Weight (kg)', name:'weight',
        },
        {
            id: 2, label:'Notes', name:'notes',
        },
       ],
    showData: [
        {
            id: 1, label:'Weight (kg):', valueTobeDisplayed:'weight',
        },
        {
            id: 2, label:'Notes:', valueTobeDisplayed:'notes',
        },
        {
            id: 2, label:'Date:', valueTobeDisplayed:'createdAt'
        }]
}
export const getBmiTitle = (value) => {
		
    var height = parseInt(value?.height);
    var weight = parseInt(value?.weight);

    let bmi =  (weight / height) / height;
    let bmiValue = parseInt(parseFloat(bmi*10000).toFixed(2));
    
    if(bmiValue < 18.5) {
        return `Underweight (${bmiValue})`;
    } else if(bmiValue >= 18.5 && bmiValue < 24.9) {
        return `Normal weight (${bmiValue})`;
    } else if(bmiValue >= 25 && bmiValue < 29.9) {
        return `Overweight (${bmiValue})`;
    }else if(bmiValue >= 30) {
        return `Obesity (${bmiValue})`;
    }
}

export const deleteData = (id, name, deleteItem) => {
    switch (name) {
        case 'Blood pressure':
            return deleteItem(id, 'bp');
        case 'Blood sugar':
            return deleteItem(id, 'bsugar');
        case 'Oxygen Saturation':
            return deleteItem(id, 'oxygen_level');
        case 'Pulse':
            return deleteItem(id, 'pluse');
        case 'Respiratory rate':
            return deleteItem(id, 'respiratory_rate');
        case 'Temperature':
            return deleteItem(id, 'temperature');
        case 'Exercise':
            return deleteItem(id, 'exercise');
        case 'Nutrition':
            return deleteItem(id, 'food');
        case 'BMI':
            return deleteItem(id, 'height');
        case 'Waist circumference':
            return deleteItem(id, 'waist');
        case 'Weight':
            return deleteItem(id, 'weight');
        case 'Lipid Profile':
            return deleteItem(id, 'cholesterol');
        case 'Joint Movements':
            return deleteItem(id, 'joint');
        case 'Pain level':
            return deleteItem(id, 'pain');
        default:
            return null;
    }
}
export const getBmiClass = (value) => {
    var height = parseInt(value.height);
    var weight = parseInt(value.weight);
    
    let bmi =  (weight / height) / height;
    let bmiValue = parseInt(parseFloat(bmi*10000).toFixed(2));
    
    if(bmiValue < 18.5) {
        return "under-bmi";
    } else if(bmiValue >= 18.5 && bmiValue < 24.9) {
        return "success-bmi";
    } else if(bmiValue >= 25 && bmiValue < 29.9) {
        return "over-bmi";
    }else if(bmiValue >= 30) {
        return "obesity-bmi";
    }
}

export const renderData = (name) => {
    switch(name) {
        case 'Blood pressure':
            return bpData;
        case 'Blood Sugar':
            return bloodSugarData;
        case 'SPO2':
            return oxygenData;
        case 'Pulse':
            return PulseData;
        case 'Respiratory Rate':
            return respiratoryData;
        case 'Temperature':
            return tempData
        case 'Lipid Profile':
            return lipidData;
        case 'Pain level':
            return painData;
        case 'Joint Movements':
            return jointData;
        default:
            return [];
    }
}

export const getHealthStatus = (title, value) => {
    switch(title) {
        case 'Blood Sugar':
            if(value === 0)
                return '-'
            else if (value <= 140)
                return 'Normal'
            else if (value > 140 && value <= 199)
                return 'Diabetes'
            else
                return 'prediabetes'
        case 'SPO2':
            if(value === 0)
                return '-'
            else if (value >= 90)
                return 'Normal'
            else
                return 'At Risk'
        case 'Pulse':
            if(value == 0)
                return '-'
            else if (value <= 100 && value >= 60)
                return 'Normal'
            else
                return 'At Risk'
        case 'Respiratory Rate':
            if(value === 0)
                return '-'
            else if (value <= 16 && value >= 12)
                return 'Normal'
            else
                return 'At Risk'
        case 'Temperature':
            if(value === 0)
                return '-'
            else if (value <= 37.2 && value >= 36.5)
                return 'Normal'
            else if (value <= 38 && value >= 35)
                return 'High'
            else
                return 'At Risk'
        case 'Lipid Profile':
            if(value === 0)
                return '-'
            else if (value <= 160 && value >= 40)
                return 'Normal'
            else
                return 'At Risk'
        case 'Pain level':
            if (value === 0 || value === 1)
                return 'Normal'
            else if(value > 1 && value  <=4)
                return 'Mild'
            else if (value > 4 && value <= 7)
                return 'Moderate'
            else
                return 'Severe'
        case 'Joint Movements':
            return 'Normal'
        default:
            return []
    }
}

export const getBpHealthStatus = (sysValue, diaValue) => {
    if (sysValue === 0 && diaValue === 0) {
        return '-'
    }
    if (sysValue <= 120 && diaValue <= 80) {
        return 'Normal'
    }
    else if (sysValue > 120 && sysValue < 139 && diaValue > 80 && diaValue < 89) {
        return 'At Risk'
    }
    else {
        return 'High BP'
    }
}

export const getHealthInfoDetails = (title) => {
    switch(title) {
        case 'Blood pressure':
            return {
                'Normal': 'Normal : 120 / 80',
                'AtRisk': 'AtRisk : 120 - 139 / 80 - 89',
                'High': 'High : 139 - 150 / 90 - 100',
            }
        case 'Blood Sugar':
            return {
                'Normal': 'Normal : <140',
                'High': 'High : 140 - 199',
                'AtRisk': 'AtRisk : >200'
            }
        case 'SPO2':
            return {
                'Normal': 'Normal:  >90',
                'High': 'Low : 80 - 90',
                'AtRisk': 'AtRisk : <80',
            }
        case 'Pulse':
            return {
                'Normal': 'Normal : 60 - 100',
                'High': 'High : 100-100',
                'AtRisk': 'AtRisk : < 60 / > 110 ',
            }
        case 'Respiratory Rate':
            return {
                'Normal': 'Normal : 12 / 16',
                'High': 'High : 16-17',
                'AtRisk': 'AtRisk : < 12 / > 17',
            }
        case 'Temperature':
            return {
                'Normal': 'Normal : 36.5 - 37.2',
                'High': '38 - 40',
                'AtRisk': '< 35 / > 40',
            }
        case 'Lipid Profile':
            return {
                'Normal': 'Normal : 40 / 160',
                'High': 'High : 160-200',
                'AtRisk': 'AtRisk : >200',
            }
        case 'Pain level':
            return {
                'Normal': 'Normal : 0 - 1',
                'High': 'Mild : 1 - 4',
                'Severe': ' > 4 ',
            }
        case 'Joint Movements':
            return {
                'Normal': '120 / 80',
                'High': '120 - 139 / 80 - 89',
                'AtRisk': '139 - 150 / 90 - 100',
            }
        default:
            return [];
    }
}


export const BmiStatus = (Bmi) => {
    if(Bmi ===0) {
        return '-'
    }
    else if(Bmi <16 ){
        return 'Severe Thinness'
    }
    else if (Bmi < 17 && Bmi >=16) {
        return 'Moderate Thinness'
    }
    else if (Bmi >=17 && Bmi <= 18.5) {
        return 'Mild Thinness'
    }
    else if (Bmi > 18.5 && Bmi <= 25) {
        return 'Normal'
    }
    else if (Bmi > 25 && Bmi <= 30) {
        return 'Overweight'
    }
    else if (Bmi > 30 && Bmi <= 35) {
        return 'Obese Class I'
    }
    else if (Bmi > 35 && Bmi <= 40) {
        return 'Obese Class II'
    }
    else if (Bmi > 40) {
        return 'Obese Class III'
    }
}

export const minimumPoint = (type) => {
    switch(type) {
        case 'Blood pressure':
            return 30;
        case 'Blood Sugar':
            return 30
        case 'SPO2':
            return 30
        case 'Pulse':
            return 20
        case 'Respiratory Rate':
            return 2
        case 'Temperature':
            return 10
        case 'Lipid Profile':
            return 30
        case 'Pain level':
            return 0
        case 'Joint Movements':
            return 30
        default:
            return 10
    }
}

export const getAllType = (type) =>  {
    switch (type) {
        case 'all_Bp':
            return 'all_bp_detail';
        case 'all_Bsugar':
            return 'all_Bsugar_detail';
        case 'all_OxygenLevel':
            return 'all_OxygenLevel_detail';
        case 'all_Pluse':
            return 'all_Pluse_detail';
        case 'all_RespiratoryRates':
            return 'all_RespiratoryRates_detail';
        case 'all_Temperature':
            return 'all_Temperature_detail';
        case 'all_Cholesterol':
            return 'all_Cholesterol_detail';
        case 'all_Joint':
            return 'all_Joint_detail';
        case 'all_Pain':
            return 'all_Pain_detail';
        default:
            return null;
    }
}