import React from 'react';

import { Link } from 'react-router-dom';
import moment from 'moment';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	getInvoices
} from '../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';


import {
	StyledContainer,
	StyledHeading
} from './styles';

import TableComponent from '../../../components/Table';
import CardComponent from '../../../components/Card';
import Pagination from '../../../components/Pagination';
import Search from '../../../components/Search';
import InsideMenuBar from '../../../components/InsideMenus';

const tableHeading = [
	{id: 1, title: 'Invoice number'},
	// {id: 2, title: 'Patient Id'},
	{id: 4, title: 'Date'},
	{id: 5, title: 'Amount'},
	{id: 5, title: 'Status'},
	{id: 5, title: 'Actions'}
];

const options = [
	{ id: 0, label: 'Id', value: 'id' },
	{ id: 1, label: 'Name', value: 'name' },
	{ id: 2, label: 'Email', value: 'email' },
	// { id: 3, label: 'Status', value: 'status' },
	{ id: 4, label: 'Contact Number', value: 'contact' }
]

function PatientInvoices(props) {
	const [state, dispatch] = React.useContext(Context);
	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('id');
	const [invoices, setInvoices] = React.useState([]);

	React.useEffect(() => {
		document.body.className = "";

		loader(true);
		getInvoices().then(res => {
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
			setInvoices(res.data.reverse());
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}, [])

	const searchInvoice = () => {
		loader(true);
		getInvoices({search: search, key: selectValue}).then(res => {
			loader(false);
			// toast.success(res.message)
			dispatch({
				type: SET_ERROR,
				payload: res.message
			});
			setInvoices(res.data);
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const [invoicesPaginate, setInvoicesPaginate] = React.useState([]);
	const pageChangeInvoice  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setInvoicesPaginate(data);
	}
	
	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'patient'}
			/>
			
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12">
							<CardComponent className="p-4">
								<StyledHeading>
									Invoice Listing
								</StyledHeading>
								{/* <hr /> */}
								{/* <div className="doctor-panel-filters row col-md-12">
									<Search
										selectValue={selectValue} 
										setSelectValue={setSelectValue} 
										search={search} 
										setSearch={setSearch} 
										searchPatient={searchInvoice} 
										name={"Invoice"}
										options={options}
									/>
								</div> */}
								<TableComponent tableHeading={tableHeading}>
									{
										invoicesPaginate && invoicesPaginate.map(item => {
											return <tr key={item._id}>
															<td>#{item.invoiceNumber}</td>
															{/* <td>#Emed4u0001</td> */}
															<td>{moment(item.createdAt).format('Do MMMM, YYYY')}</td>
															<td>{item.amount} </td>
															<td><span className="badge badge-pill bg-success-blue text-white inv-badge">Paid</span></td>
															{/* 	<td><span class="badge badge-pill bg-danger-light">Cancelled</span></td> */}
															{/* <td><span class="badge badge-pill bg-warning-light">Pending</span></td> */}
															<td>
																<div className="table-action">
																	<Link to={`/patient/view-invoice/${item._id}`} className="btn btn-sm bg-info-light-blue">
																		<i className="far fa-eye"></i> View
																	</Link>
																</div>
															</td>
														</tr>
										})
									}

									{
										invoices.length === 0 && (
										<tr>
											<td colSpan="12">
												<h5 style={{ textAlign: "center" }}>
													Sorry! There are no results to display
												</h5>
											</td>
										</tr>
										)
									}
									
								</TableComponent>

								{invoices && invoices.length > 0 && <Pagination items={invoices} onChangePage={pageChangeInvoice} />} 

							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default PatientInvoices