import React, { useState, useContext, useEffect }  from 'react';
import { Link } from 'react-router-dom';
import CardComponent from '../../../../../components/Card';
import InputField from '../../../../../components/InputField';
import SelectField from '../../../../../components/SelectField';
import DatePicker from 'react-date-picker';
import { toast } from 'react-toastify';
import { globalValidator } from "../../../../../utils";
import { Context } from '../../../../../store';
import {
	addPatient,
	getPatientInfo,
	updatePatientInfo
} from '../../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../../store/types';
import {
	StyledContainer,
	StyledHeader,
	StyledBackTo,
	StyledTitle,
	StyledAddEditPatient
} from './styles';
import Cookies from 'js-cookie';
import PhoneInput from 'react-phone-number-input';

function SubAdminAddPatient(props) {
	const [state, dispatch] = useContext(Context);
	const { patientId, setModalVisible, getPatients, isPatientFlow, handleProcced } = props;
	const [patient, setPatient] = useState({name: '', email: '', phoneNumber: '', bloodGroup: '', address: ''});

	useEffect(() => {
		if(patientId) {
			loader(true);
			getPatientInfo(patientId).then(res => {
				loader(false);
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				
				setPatient({
					name: res.data.name,
					email: res.data.email,
					phoneNumber: res.data.phoneNumber,
					bloodGroup: res.data.bloodGroup,
					address: res.data.address
				});
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		}
	}, [])

	const submitPatient = (e) => {
		e.preventDefault();
    const keys = [
      { name: "email", title: "Email", validateKey: "email" },
			{ name: "name", title: "Name", validateKey: "required" },
    ];
		let valid = globalValidator(patient, keys);

		if(valid === true) {
			loader(true);
			if(patientId) {
				updatePatientInfo(patientId, patient).then(res => {
					loader(false);
					toast.success(res?.message)
					dispatch({
						type: SET_ERROR,
						payload: res?.message
					});
					setModalVisible(false);
					document.body.style.overflowY = 'auto';
					getPatients();
				}).catch(err => {
					loader(false);
					toast.error(err?.response?.data?.error)
					dispatch({
						type: SET_ERROR,
						payload: err?.response?.data?.error
					});
				})
			} else {
				let data = {
					name: patient.name, 
					email: patient.email, 
					phoneNumber: patient.phoneNumber, 
					bloodGroup: patient.bloodGroup, 
					address: patient.address,
					dob: patient.dob,
					password: 'Emed4u@12345#!',
					verifyed: true,
					gender: patient.gender,
					occupation: patient.occupation,
					city: patient.city,
					address: patient.address

				}
				addPatient(data).then(res => {
					loader(false);
					toast.success(res?.message || 'Credentials are sent to patients mail!')
					dispatch({
						type: SET_ERROR,
						payload: res?.message
					});
					setModalVisible(false);
					if(getPatients){
						getPatients();
					}
					if (isPatientFlow) {
						Cookies.set('setPatientInfo', JSON.stringify({
							name: res.data.name,
							address: res.data.address,
							gender: res.data.gender,
							dob: res.data.dob,
							weight: res.data.weight,
							id: res.data._id
						}), { path: '' });
						handleProcced(res.data,);
					}
					document.body.style.overflowY = 'auto';
					setPatient({name: '', email: '', phoneNumber: '', status: '', bloodGroup: '', address: ''});
				}).catch(err => {
					loader(false);
					toast.error(err?.response?.data?.error)
					dispatch({
						type: SET_ERROR,
						payload: err?.response?.data?.error
					});
				})
			}
			
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
			<div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '70%' }} role="document">
				<div className="modal-content" style={{ maxHeight: "700px", overflowY: "scroll" }}>
					<i class="fas fa-times patient-info-modal-closed-icon" onClick={() => {
						setModalVisible(false);
						document.body.style.overflowY = 'auto';
					}}></i>

					<div className="col-md-12 margin-top-bottom">

						<div className='col-md-12 d-flex'>
							<StyledTitle>
								{patientId ? 'Update' : 'Add new'} patient
							</StyledTitle>
						</div>



							<div className="row pt-3 margin-zero">
								<div className="col-md-6 no-padding form-group ">
									<label>Name</label>
									<InputField value={patient.name} onChange={e => setPatient((patient) => ({ ...patient, name: e.target.value }))} />
								</div>

								<div className="col-md-6 no-padding form-group ">
									<label>Email</label>
									<InputField value={patient.email} onChange={e => setPatient((patient) => ({ ...patient, email: e.target.value }))} />
								</div>
							</div>
							<div className="row pt-3 margin-zero">
								<div className="col-md-4 no-padding form-group" style={{ display: 'flex', flexDirection: 'column' }}>
									<label>Date of birth</label>
									<DatePicker value={patient.dob} className="ipdwardDatePicker" selected={patient.dob} onChange={date => setPatient((patient) => ({ ...patient, dob: date }))} />
								</div>
								<div className="col-md-4 no-padding form-group ">
									<label>Gender</label>
									<SelectField value={patient.gender} onChange={e => setPatient((patient) => ({ ...patient, gender: e.target.value }))} options={[{ id: 1, label: 'Male', value: 'male' }, { id: 2, label: 'Female', value: 'female' }, { id: 3, label: 'Others', value: 'other' }]} />
								</div>
								<div className="col-md-4 no-padding form-group ">
									<label>Blood group</label>
									<SelectField value={patient.bloodGroup} onChange={e => setPatient((patient) => ({ ...patient, bloodGroup: e.target.value }))} options={[{ id: 1, label: 'A-', value: 'A-' }, { id: 1, label: 'A+', value: 'A+' },
									{ id: 1, label: 'B-', value: 'B-' }, { id: 1, label: 'B+', value: 'B+' },
									{ id: 1, label: 'AB-', value: 'AB-' }, { id: 1, label: 'AB+', value: 'AB+' },
									{ id: 1, label: 'O-', value: 'O-' }, { id: 1, label: 'O+', value: 'O+' }]} />
								</div>





							</div>
							<div className="row pt-3 margin-zero">

								{/* <div className="col-md-6 no-padding form-group ">
										<label>Assign doctor</label>
										<SelectField options={[]} />
									</div> */}
							</div>
							<div className="row pt-3 margin-zero">
								<div className="col-md-4 no-padding form-group ">
									<label>Contact number</label>
									<PhoneInput
										placeholder="Enter phone number"
										international
										defaultCountry="IN"
										value={patient.phoneNumber}
										onChange={e => setPatient((patient) => ({ ...patient, phoneNumber: e }))}
									/>
								</div>
								<div className="col-md-4 no-padding form-group ">
									<label>City</label>
									<InputField value={patient.city} onChange={e => setPatient((patient) => ({ ...patient, city: e.target.value }))} />
								</div>
								<div className="col-md-4 no-padding form-group ">
									<label>Occupation</label>
									<InputField value={patient.occupation} onChange={e => setPatient((patient) => ({ ...patient, occupation: e.target.value }))} />
								</div>
							</div>
							<div className="row pt-3 margin-zero">
								<div className="col-md-6 no-padding form-group ">
									<label>Address</label>
									<InputField value={patient.address} onChange={e => setPatient((patient) => ({ ...patient, address: e.target.value }))} />
								</div>
							</div>
							<div className="col-md-12 row mb-2" style={{justifyContent:"flex-end"}}>
								<div className="btn btn-primary submit-btn p-2" onClick={(e) => submitPatient(e)}>{patientId ? 'Update' : 'Save'} patient</div>
							</div>

					</div>
				</div>
			</div>
		</div>
	)
}

export default SubAdminAddPatient
