import styled from 'styled-components';

export const StyledContainer = styled.div`
	.u-valign-middle {
		h1 {
			width: 75px;
			background: #64dda5;
			border-radius: 50%;
			padding: 15px;
		}
	}
	.card-hows {
    text-align: center;
    padding: 50px 35px;
    margin-bottom: 2rem;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 4px;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    overflow: hidden;
    -webkit-box-shadow: 0 5px 30px 0 rgb(0 0 0 / 7%);
    box-shadow: 0 5px 30px 0 rgb(0 0 0 / 7%);
    min-height: 200px;
	}

	.section-title-org {
		p {
			font-size: 18px;
			font-weight: 600;
		}
	}

	.font-18 {
		font-size: 18px;
	}
	.info-box {
		.text-image-haft-haft .info-box ul.list {
			margin-left: 24px;
		}
		ul.list {
			padding-left: 0;
			font-size: 17px;
		}
		ul.list.green-bullets li {
			position: relative;
			padding-left: 6px;
			margin-bottom: 12px;
		}
		ul.list li {
			list-style: none;
			vertical-align: middle;
		}
		ul.list.green-bullets li:after {
			background: url(https://www.brightful.me/assets/play/img/green-check.svg);
			content: " ";
			width: 20px;
			height: 20px;
			position: absolute;
			left: -24px;
			top: 2px;
			background-size: cover;
		}
	}


	

`;
