import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import ContactUsModal from '../Contact';
import FeedBackModal from '../Feedback';


import {
	StyledFooterWrapper
} from './styles';

function Footer() {

	const [tableToggle1, setTableToggle1] = useState(false);
	const [feedbackToggle,setFeedbackToggle]=useState(false);

	const executeScroll = () => {
		document.querySelector('body').scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}
	const handleOpen = () => {
		document.body.style.overflowY = 'auto'
		setTableToggle1(tableToggle1 => !tableToggle1);
	}
	const handleFeedbackOpen = () => {
		// document.body.style.overflowY = 'auto'
		setFeedbackToggle(feedbackToggle => !feedbackToggle);
	}
	return (
		<StyledFooterWrapper>
			<footer className="footer">

				<div className="footer-top">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-3 col-md-6 footer-container-alignment">

								<div className="footer-widget footer-about">
									<div className="footer-logo">
										<img src="/assets/img/logo.png" alt="logo" style={{ width: '100%', height: '100%' }} />
									</div>
									<div className="footer-about-content">
										<p>Emed4u aims to assist you with a cost effective, refined and informative medical consultation so that you can focus on a smooth and accelerated recovery. </p>
										<div className="row apps-shows hide-item">
											<a href="https://play.google.com/store/apps/details?id=com.emed4ulastestapp" rel="noreferrer" target="_blank">
												<img className="btn-store" src="/assets/img/android.png" alt="" />
											</a>
											<a href="https://apps.apple.com/in/app/emed4u/id1573147985" rel="noreferrer" target="_blank">
												<img className="btn-store" src="/assets/img/apple.png" alt="" />
											</a>
										</div>
									</div>
								</div>

							</div>
							<div className='footer-mobile-screen row'>
								<div className="col-lg-4 col-md-6 col-6">
									<div className="footer-widget footer-menu mb-4">
										<h2 className="footer-title">Company</h2>
										<ul>
											<li onClick={() => executeScroll()}><Link to={'/about'}>About Us</Link></li>
											<li onClick={() => executeScroll()}><Link to={'/blogs'}>Blogs</Link></li>
											{/* <li onClick={() => executeScroll()}><Link to={'/login'}>Pricing</Link></li> */}
											<li onClick={() => executeScroll()}><Link to={'/events'}>Events</Link></li>
											<li onClick={() => executeScroll()} style={{ cursor : 'pointer' }} ><Link to = {'/contact-us'}>Contact Us</Link></li>
											<li onClick={() => executeScroll()}><Link to={'/faqs'}>FAQ’s</Link></li>
										</ul>
									</div>
								</div>

								<div className="col-lg-4 col-6 col-md-6">
									<div className="footer-widget footer-menu">
										<h2 className="footer-title">Others</h2>
										<ul>
											<li onClick={() => executeScroll()}><Link to={'/conduct-study'}>Conduct a study</Link></li>
											<li style={{ cursor : 'pointer' }}  onClick={() =>setFeedbackToggle(!feedbackToggle) }><Link to>Feedback or suggestion</Link></li>
											<li onClick={() => executeScroll()}><Link to={'/support'}>Support</Link></li>
											<li><a href={'/sitemap.xml'}>Sitemap</a></li>
										</ul>
									</div>

								{/* <div className="footer-widget footer-menu mb-4 mt-4">
									<h2 className="footer-title">Services</h2>
									<ul>
										<li><Link style={{whiteSpace: 'pre'}} to={'/login'}>Health Management System</Link></li>
										<li><Link to={'/login'}>Wearables</Link></li>
									</ul>
								</div> */}
							</div>

							<div className="col-lg-4 col-6 col-md-6">
								<div className="footer-widget footer-menu">
									<h2 className="footer-title">Services</h2>
									<ul>
										<li onClick={() => executeScroll()}><Link style={{whiteSpace: 'pre'}} to={'/hms'}>Health Management System</Link></li>
										{/* <li><Link to={'/events'}>Wearables</Link></li> */}
									</ul>
								</div>
							</div>
							
							{/* <div className="col-lg-4 col-md-6 col-6">
								<div className="footer-widget footer-menu mb-4">
									<h2 className="footer-title">For Patients</h2>
									<ul>
										<li><Link to={'/login'}>Login</Link></li>
									</ul>
								</div>
								<div className="footer-widget footer-menu mb-4">
									<h2 className="footer-title">For Doctors</h2>
									<ul>
										<li><Link to={'/doctor/login'}>Login</Link></li>
									</ul>
								</div>
								<div className="footer-widget footer-menu mb-4">
									<h2 className="footer-title">For clinicians / Hospitals</h2>
									<ul>
										<li><Link to={'/organization/login'}>Login</Link></li>
										<li><Link to={'/organization/register'}>Register</Link></li>
									</ul>
								</div>
								<div className="footer-widget footer-menu mb-4">
									<h2 className="footer-title">For organization sub-admin</h2>
									<ul>
										<li><Link to={'/subuser/login'}>Login</Link></li>
									</ul>
								</div>
							</div> */}
							</div>
							<div className="col-lg-3 col-md-6 mobile-screen-padding footer-screen">
								<div className="footer-widget footer-contact">
									<h2 className="footer-title">Certified</h2>
									<div className="footer-contact-info">
										<div className="cert-img" style={{width: '109px'}}>
											<img src="/assets/img/iso-9001-2015-certification.jpg" />
										</div>

										<div className="cert-img certification2" style={{ width: '109px' }}>
											<img src="/assets/img/iso-27000-certification.jpg" />
										</div>

									</div>
								</div>
								<div className="footer-widget footer-contact hide-item2">
									<h2 className="footer-title"> </h2>
									<div className="footer-contact-info margin-top-screen">
										<div className="cert-img" style={{ width: '109px' }}>
											<a href="https://play.google.com/store/apps/details?id=com.emed4ulastestapp" rel="noreferrer" target="_blank">
												<img className="btn-store" src="/assets/img/android.png" alt="" />
											</a>
										</div>

										<div className="cert-img" style={{ width: '109px' }}>
											<a href="https://apps.apple.com/in/app/emed4u/id1573147985" rel="noreferrer" target="_blank">
												<img className="btn-store" src="/assets/img/apple.png" alt="" />
											</a>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="footer-bottom">
					<div className="container-fluid">
						<div className="row footer-content-padding">
							<div className="col-md-6 col-lg-6">
								<div className="copyright-text">
									<p className="mb-0">
										<div className="social-icon">
											<ul className="mobile-screen-icon">
												<li>
													<a href="https://www.facebook.com/emed4u" target="_blank">
														<img src="/assets/img/Frame5.svg" />

													</a>
												</li>
												<li>
													<a href="https://twitter.com/emed4u" target="_blank">
														<img src="/assets/img/Frame3.png" />
													</a>
												</li>
												{/* <li>
													<a href="" target="_blank"><i className="fab fa-linkedin-in"></i></a>
												</li> */}
												<li>
													<a href="https://www.instagram.com/emed4u/" target="_blank">
														<img src="/assets/img/Frame2.svg" />
													</a>
												</li>
												<li>
													<a href="https://www.youtube.com/c/EMED4U/videos" target="_blank">
														<img src="/assets/img/Frame4.png" />
													</a>
												</li>
												{/* <li>
													<a href="" target="_blank"><i className="fab fa-dribbble"></i> </a>
												</li> */}
											</ul>
										</div>
									</p>
								</div>
							</div>
							<div className="col-md-6 col-lg-6">

								<div className="copyright-menu">
									<ul className="policy-menu hide-item">
										<li>
											<Link to onClick={() => {
												document.querySelector('body').scrollTo({
													top: 0,
													behavior: 'smooth'
												})
											}
											}
											>
												<img src="/assets/img/Frame1.png" alt='top' />
											</Link>
										</li>
									</ul>
								</div>

							</div>
						</div>
						<div className="copyright">
							<div className="row footer-content-padding">
								<div className="col-md-6 col-lg-6">
									<div className="copyright-text">
										<p className="mb-0 text-content-center">&copy; 2022 Emed4u. All rights reserved.</p>
									</div>
								</div>
								<div className="col-md-6 col-lg-6">
									<div className="copyright-menu">
										<ul className="policy-menu text-content-center">
											<li onClick={() => executeScroll()}><Link to={'/terms-and-conditions'}>Terms and conditions</Link></li>
											<li onClick={() => executeScroll()}><Link to={'/privacy-policy'}>Privacy policy</Link></li>
											<li onClick={() => executeScroll()}><Link to={'/refund-&-cancellation-policy'}>Refund & cancellation</Link></li>
										</ul>
									</div>

								</div>
							</div>
						</div>

					</div>
				</div>
				<ContactUsModal
					tableToggle1={tableToggle1}
					setTableToggle1={setTableToggle1}
					handleOpen={handleOpen} />
				<FeedBackModal
					feedbackToggle={feedbackToggle}
					setFeedbackToggle={setFeedbackToggle}
					handleFeedbackOpen={handleFeedbackOpen}
				/>
			</footer>

		</StyledFooterWrapper>
	)
}

export default Footer
