import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Context } from '../../../../store';
import {
  getDoctorInfoAPI
} from '../../../../api/doctor';
import {
	SET_ERROR,
	SET_COMMON_LOADING,
	SET_USER_TYPE
} from '../../../../store/types';

import {
	StyledContainer,
	StyledImgSection
} from './styles';

import { logout } from '../../../../utils';

const apiUrl =  'https://api.emed4u.com'; //'http://localhost:5000'
// const socket = io(apiUrl);

const menus = [
	{id: 1, name: 'My Appointments Overview', icon: 'columns', badge: false, route: '/doctor/schedule'},
	{id: 2, name: 'List & calendar view', icon: 'calendar-check', badge: false, route: '/doctor/appointments'},
	{id: 5, name: 'Schedule Time', icon: 'business-time', badge: false, route: '/doctor/schedule-slots'},
	{id: 3, name: 'Communication', icon: 'comments', badge: false, route: '/doctor/doctor-chat'},
    {id: 4, name: 'Patients (Add / List)', icon: 'user-injured', badge: false, route: '/doctor/patient-listing'},
	
	// {id: 6, name: 'Calendar', icon: 'calendar-check', badge: false, route: '/doctor/calendars'},
    // {id: 7, name: 'Completed Appointments', icon: 'hourglass-start', badge: false, route: '/doctor/completed-appointments'},
	// {id: 8, name: 'Reviews', icon: 'star', badge: false, route: '/doctor/reviews'},
	{id: 12, name: 'Payment method', icon: 'money-check-alt', badge: false, route: '/doctor/payment-method'},
	{id: 9, name: 'Invoices', icon: 'file-invoice', badge: false, route: '/doctor/invoices'},
	{id: 10, name: 'Edit your profile', icon: 'user-cog', badge: false, route: '/doctor/settings'},
	// {id: 12, name: 'Terms & condition', icon: 'balance-scale-right', badge: false, route: '/doctor/settings'},
	{id: 11, name: 'Logout', icon: 'sign-out-alt', badge: false}
];

function DoctorSidebar(props) {
	const [state, dispatch] = React.useContext(Context);

	const [doctorInfo, SetDoctorInfo] = React.useState({
    dob: '',
    gender: '',
    phoneNumber: '',
		name: '',
		address: '',
		department: '',
		profileImage: '',
		_id: null,
		org_name: ''
	});

	const [newMessage, setNewMessage] = React.useState([]);
	
	React.useEffect(() => {
		document.body.className = "";

		getDoctorInfoAPI().then(res => {
      SetDoctorInfo({
        dob: res.data.dob,
        gender: res.data.gender,
        phoneNumber: res.data.phoneNumber,
        name: res.data.name,
				address: res.data.address,
				department: res.data.department,
				profileImage: res.data.profileImage,
				_id: res.data._id,
				org_name: res.data.orgData ? res.data.orgData.org_name : ''
      })
      
		}).catch(err => {
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})

		const doctorId = JSON.parse(localStorage.getItem('doctor'))._id; 
		const user = {
			id: doctorId
		};

		// socket.emit("newMessageDoctor", user);

		// socket.on("newMessageDoctor", data => {
		// 	if(data) setNewMessage(data.result);
		// })
	}, []);

	const logoutUser = () => {
		dispatch({
			type: SET_USER_TYPE,
			payload: ''
		});
		logout();
		props.doctor.history.push('/');
	}

	const onLoadImage = () => {
		return 'Loading...'
	}

	return (
		<StyledContainer className="profile-sidebar">
			<div className="widget-profile pro-widget-content">
				<div className="profile-info-widget">
					<StyledImgSection className="booking-doc-img">
						<img src={doctorInfo.profileImage ? doctorInfo.profileImage : '/assets/img/loading-img.jpg'} alt="User Image" onError="this.onerror=null;this.src='/assets/img/loading-img.jpg';" />
					</StyledImgSection>
					<div className="profile-det-info">
						<h3>{doctorInfo.name}</h3>
						<div className="patient-details">
							<h5>{doctorInfo.department}</h5>
						
							<h5 className="mb-0"><i className="fas fa-hospital-alt"></i> {doctorInfo.org_name}</h5>
							<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> {doctorInfo.address}</h5>
						</div>
					</div>
				</div>
			</div>
			<div className="dashboard-widget">
				<nav className="dashboard-menu">
					<ul>
						{
							menus.map(item => (
								<li key={item.id} className={item.route === props.doctor.match.path ? 'active' : ''}>
									{/* <Link to={item.route}>
										<i className={`fas fa-${item.icon}`}></i>
										<span>{item.name}</span>
										
									</Link> */}
									{
										item.name === 'Logout' ? (
										<a onClick={() => logoutUser()} style={{cursor: 'pointer'}}>
											<i className={`fas fa-${item.icon}`}></i>
											<span>{item.name}</span>
											{/* {
												item.badge &&	<small className="unread-msg">{item.count}</small>
											} */}
										</a>
										) : (
											<Link to={item.route}>
												<i className={`fas fa-${item.icon}`}></i>
												<span>{item.name}</span>
												{
													item.name === 'Communication' && newMessage.length > 0 &&	<small className="unread-msg">{newMessage.length}</small>
												}
											</Link>
										)
									}
								</li>
							))
						}
					</ul>
				</nav>
			</div>
		</StyledContainer>
	)
}

export default DoctorSidebar
