import React, { useContext }  from 'react';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	postContactUsAPI
} from '../../../api/website';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';

import { globalValidator } from "../../../utils";

import Card from '../../../components/Card';
import Testimonial from '../../LandingPage/Testimonial';
import InputField from '../../../components/InputField';

import {
	StyledContainer,
	StyledHeaderContainer,
	StyledFooterContainer,
	StyledCardWrapper,
  StyledCardIconWrapper,
  StyledCardIcon,
  StyledCardTitle,
  StyledCardButton,
	StyledHomeSlider
} from './styles';
import PhoneInput from 'react-phone-number-input';

const tools = [
	{id: 1, description: 'APPOINTMENTS', title: 'Manage Your Appointments on the Go', icon: '/assets/img/icons/icon1.png', image: '/assets/img/land1.png', text: 'With the appointments, you can streamline consultation on the go, whether online or in-clinic.', route: '/manage-your-appointments'},
	{id: 2, description: 'VIDEO CONSULTATION', title: 'Video / Text Consultations in your Virtual Practice', icon: '/assets/img/icons/icon2.png', image: '/assets/img/land2.png', text: 'Using the Virtual Practice tool, you can meet more patients every day. An excellent, quick, and efficient tool helps follow-up with your patients, wherever they are located. Elderly patients and others with disabilities who are located in a distant place can easily connect with you. With greater collaboration, you can follow up with them for proper health care and monitor their health in a better way. ', route: '/video-consultations'},
	{id: 3, description: 'PATIENT QUESTIONS', title: 'Communication Made Simple', icon: '/assets/img/icons/icon3.png', image: '/assets/img/land3.png', text: 'You can communicate with your patients with just a simple click. Tell them about what’s new and keep them updated with the events relevant to them.', route: '/communication-made-simple'},
	{id: 4, description: 'MOBILE APPS', title: 'Provide Better Health Outcomes – Using Patient Health Record Management (PRM)', icon: '/assets/img/icons/icon4.png', image: '/assets/img/land4.png', text: 'Using the Patient Health Record Management (PRM), you can know more about the patient’s health reports and comprehensive medical history right at your fingertips.', route: '/provide-better-health'},
	{id: 6, description: 'REMOTE MONITORING', title: 'Tele Monitoring In Your Virtual Clinical Practice/ Clinical Institute', icon: '/assets/img/icons/icon5.png', image: '/assets/img/land5.png', text: 'Ensure that your patients get the best health care. Custom care plans that monitor the patients can reduce the risk of health complications between visits.', route: '/tele-monitoring-virtual-clinical'},
	{id: 5, description: 'BILLING & REPORTS', title: 'Billing & Reports', icon: '/assets/img/icons/icon6.png', image: '/assets/img/land6.png', text: 'Keep track of your growing practice by collecting payments online. Manage online billing and revenue reports that help in tracking your practice revenue. ', route: '/billing-and-reports'}
];

function LandingPageLead(props) {
	const [state, dispatch] = useContext(Context);

	const [contactus, setContactUs] = React.useState({
		name: "",
		email: "",
		phoneNumber: "",
		city: "",
		clinic: "",
		message: ""
	});

	const sumbitContactRequest = () => {
    const keys = [
      { name: "name", title: "Name", validateKey: "required" },
			{ name: "email", title: "Email", validateKey: "email" },
			{ name: "phoneNumber", title: "Contact Number", validateKey: "required" },
			{ name: "city", title: "City", validateKey: "required" },
			{ name: "clinic", title: "Clinic / Hospital", validateKey: "required" },
    ];

    let valid = globalValidator(contactus, keys);

		if (valid === true) {
			loader(true);
			postContactUsAPI(contactus).then(res => {
				loader(false);
				// toast.success('Demo request successfully submitted!')
				dispatch({
					type: SET_ERROR,
					payload: 'Demo request successfully submitted!'
				});
				setContactUs({
					name: "",
					email: "",
					subject: "",
					message: ""
				})
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}

	}

			
	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const [toolId, setToolId] = React.useState(1);

	const [currentTool, setCurrentTool] = React.useState({
    id: 1,
    text: 'With the appointments, you can streamline consultation on the go, whether online or in-clinic.',
    image: '/assets/img/land1.png',
    route: '/manage-your-appointments'
  })

	const onSetCurrentTool = (item) => {
    setToolId(item.id)

    setCurrentTool({
      id: item.id,
      text: item.text,
      image: item.image,
      route: item.route
    })
  }

	const changePageTele = (route) => {
    props.history.push(route);
  }

  return(
		<StyledContainer>

			<StyledHeaderContainer>
				<header className="header">
					<nav className="navbar navbar-expand-lg header-nav">
						<div className="navbar-header">
							<a id="mobile_btn" href="javascript:void(0);">
								<span className="bar-icon">
									<span></span>
									<span></span>
									<span></span>
								</span>
							</a>
								<a  className="navbar-brand logo">
									<img src="/assets/img/logo.png" className="img-fluid" alt="Logo" />
								</a>
						</div>
						<div className="main-menu-wrapper">
								<div className="menu-header">
										<a  className="menu-logo">
											<img src="/assets/img/logo.png" className="img-fluid" alt="Logo" />
										</a>
										<a id="menu_close" className="menu-close" href="javascript:void(0);">
												<i className="fas fa-times"></i>
										</a>
								</div>
							</div>
						</nav>
					</header>
			</StyledHeaderContainer>

			<StyledHomeSlider>
				<div className='col-12 d-flex bd-home-slider bg-img-002'>
					<div className="slide-image col-12 col-lg-5">
						<span className="text-secondary text-uppercase d-block mb-3">
							&#8226; Our cloud-based management system ensures managing workflow for doctors and clinicians is a smooth experience. <br /><br />
							&#8226; We provide a management tool so efficient; it relieves all that managing stress and enables you to provide the best care to your patients. <br /><br />
							&#8226; Emed4u is telemedicine and telemonitoring website. <br /><br />
							&#8226; We assist you in managing hospital and clinical practice in an organized and innovative way.
						</span>
						
				</div>
			</div>
         
    </StyledHomeSlider>


		<div className="row p-5 main-lan-con">
			<div className="col-md-8">
				<div className="row lan-main-start">

					<h5>Why Emed4u?</h5>

					<div className="lan-contrainer">
						<div className="lan-left">
							<h6>Hospital management web app</h6>
							<p>
							Customized subscription plans Our subscription prices are customizable according to your work plan. Subscription plans are designed with an option for upgrades as you grow. The system is suitable for all medical set-ups, from single practitioners to hospitals and organizations.
							</p>
						</div>

						<div className="lan-right">
							<img src="/assets/img/Hospital-management.png" />
						</div>
					</div>

					<div className="lan-contrainer">
						<div className="lan-left">
							<h6>Health parameters records</h6>
							<p>
							Doctors can deliver the best healthcare services to their patients equipped with health parameter records of the last six months. We provide an option to keep a six-month continuous log of a patient's health parameters. This option aids the doctor in better diagnosis and understanding of the patient's health.
							</p>
						</div>

						<div className="lan-right">
							<img src="/assets/img/Hospital-management-2.png" />
						</div>
					</div>


					<div className="lan-contrainer">
						<div className="lan-left">
							<h6>Encrypted and reliable technology</h6>
							<p>
							We provide a safe virtual tele clinic tool for the doctor and patient to connect. High-quality video and audio call services are available for online consultations. These services will make your appointments more personal and assure your patient’s trust in you.
							</p>
						</div>

						<div className="lan-right">
							<img src="/assets/img/Hospital-management-3.png" />
						</div>
					</div>


					<div className="lan-contrainer">
						<div className="lan-left">
							<h6>Appointment logs and management</h6>
							<p>
							Our user-friendly system makes it easy to navigate through your appointment logs and records. Our system enables you to manage all your appointments from anywhere around the world. Our clinic management tool isn't limited to online consultations only. A doctor can record and manage their in-clinic appointments with our system as well.
							</p>
						</div>

						<div className="lan-right">
							<img src="/assets/img/Hospital-management-4.png" />
						</div>
					</div>

				</div>
			</div>

			<div className="col-md-4">
				<div className="row bg-form-lan">

					<h5>Schedule a Demo Now and Get 3 Months of Subscription Free</h5>

					<div className="col-md-12 no-padding form-group card-label">
						<label>Name</label>
						<InputField value={contactus.name} onChange={e => setContactUs((user) => ({...user, name: e.target.value}))} />
					</div>
					<div className="col-md-12 no-padding form-group card-label">
						<label>Email</label>
						<InputField value={contactus.email} onChange={e => setContactUs((user) => ({...user, email: e.target.value}))} />
					</div>

					<div className="col-md-12 no-padding form-group card-label">
						<label>Contact number</label>
						<PhoneInput
							placeholder="Enter phone number"
							international
							defaultCountry="IN"
							value={contactus.phoneNumber}
							onChange={e => setContactUs((user) => ({...user, phoneNumber: e}))}
						/>
					</div>
					<div className="col-md-12 no-padding form-group card-label">
						<label>City</label>
						<InputField value={contactus.city} onChange={e => setContactUs((user) => ({...user, city: e.target.value}))} />
					</div>

					<div className="col-md-12 no-padding form-group card-label">
						<label>Organization / Clinic</label>
						<InputField value={contactus.clinic} onChange={e => setContactUs((user) => ({...user, clinic: e.target.value}))} />
					</div>

					<div className="col-md-12 no-padding form-group "  style={{display:'grid'}}>
						<label>Message</label>
						<textarea style={{height: '80px'}} value={contactus.message} onChange={e => setContactUs((user) => ({...user, message: e.target.value}))}></textarea>
					</div>

					<div className="text-center" style={{width: '100%'}}>
						<StyledCardButton onClick={() => sumbitContactRequest()}> 
						Get a Free Demo     
							<i className="fa fa-chevron-right" />
						</StyledCardButton>
					</div>

				</div>
			</div>
		</div>
		

			<section className="section section-doctor">
				<div className="container-fluid">
					<div className="row">
							<div className="section-header col-lg-12 text-center">
								<h2>Virtual Practice/Teleclinic/ Virtual Hospital tools</h2>
								{/* <p>Lorem Ipsum is simply dummy text </p> */}
							</div>
						<div className="col-lg-8">
						
							<div className="row">
								{
									tools.map(tool => {
										return <div className="col-lg-4 mb-3" key={tool.id}>
														<Card className="text-center">
															<StyledCardWrapper className={toolId === tool.id ? 'active-card-section' : ''} onClick={() => onSetCurrentTool(tool)}>
																<StyledCardIconWrapper>
																	<StyledCardIcon src={tool.icon} />
																</StyledCardIconWrapper>
																<StyledCardTitle>
																	{tool.title}
																</StyledCardTitle>
															</StyledCardWrapper>
														</Card>
													</div>
									})
								}
							</div>
						</div>
						<div className="col-lg-4 features-img">
							<div className="about-content">
								<p>{currentTool.text}</p>
								<StyledCardButton onClick={() => changePageTele(currentTool.route)}> 
									Read more     
									<i className="fa fa-chevron-right" />
								</StyledCardButton>
							</div>
							<img src={currentTool.image} className="img-fluid" alt="Feature" />
						</div>
					</div>
				</div>
			</section>

			<section className="section section-specialities">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-header text-center">
              <h2>Don't take our word, hear what our customers have to say</h2>
            </div>
            
						<Testimonial />

          </div>
         
        </div>
      </div>
    </section>

		<section className="section section-doctor">
			<div className="container-fluid">
				<div className="row">
						<div className="section-header col-lg-12 text-center">
							<h2>Schedule a Demo Now and Get 3 Months of Subscription Free</h2>
							{/* <p>Lorem Ipsum is simply dummy text </p> */}
						</div>
					<div className="col-lg-12">
						<div className="row mt-5">
							<div className="col-md-6 no-padding form-group card-label">
								<label>Name</label>
								<InputField value={contactus.name} onChange={e => setContactUs((user) => ({...user, name: e.target.value}))} />
							</div>
							<div className="col-md-6 no-padding form-group card-label">
								<label>Email</label>
								<InputField value={contactus.email} onChange={e => setContactUs((user) => ({...user, email: e.target.value}))} />
							</div>
						</div>

						<div className="row">
							<div className="col-md-6 no-padding form-group card-label">
								<label>Contact number</label>
								<PhoneInput
									placeholder="Enter phone number"
									international
									defaultCountry="IN"
									value={contactus.phoneNumber}
									onChange={e => setContactUs((user) => ({...user, phoneNumber: e}))}
								/>
							</div>
							<div className="col-md-6 no-padding form-group card-label">
								<label>City</label>
								<InputField value={contactus.city} onChange={e => setContactUs((user) => ({...user, city: e.target.value}))} />
							</div>
						</div>

						<div className="col-md-12 no-padding form-group card-label">
							<label>Organization / Clinic</label>
							<InputField value={contactus.clinic} onChange={e => setContactUs((user) => ({...user, clinic: e.target.value}))} />
						</div>

						<div className="col-md-12 no-padding form-group "  style={{display:'grid'}}>
							<label>Message</label>
							<textarea style={{height: '80px'}} value={contactus.message} onChange={e => setContactUs((user) => ({...user, message: e.target.value}))}></textarea>
						</div>

						<div className="text-center">
							<StyledCardButton onClick={() => sumbitContactRequest()}> 
							Get a Free Demo     
								<i className="fa fa-chevron-right" />
							</StyledCardButton>
						</div>


						
							

					</div>
				</div>
			</div>
		</section>

			


			<StyledFooterContainer>
				<div className="logo-fo"><img src="/assets/img/logo.png" className="img-fluid" alt="Logo" /></div>

				<div className="fo-content">
					<span >Copyright © 2022, Emed4u. All rights reserved. 
						<ul >
							<li ><a href="https://emed4u.com/privacy-policy" target="_blank">Privacy Policy</a></li>
							<li><a href="https://emed4u.com/terms-and-conditions" target="_blank">Terms &amp; Conditions</a></li>
						</ul>
					</span>
				</div>
			</StyledFooterContainer>
		</StyledContainer>
	)  
}

export default LandingPageLead