import React, { useState } from 'react'
import { SubmitButton, QuestionTitle, QuestionWrapper, ContentArea, Modal, ProgressBar } from './styles'
import { BigInputField, Crossed, SingleQuestions, UpAndDown } from './utility'

const quesArr = [
	'Please enter your first name. *',
	'Please enter your second name. *'
]

const backshadow = {
	backdropFilter: 'blur(1px)',
	background: 'rgb(104 71 99 / 24%)'
}
const Question = ({isQuestion, setQuestion}) => {
	const [current, setCurrent] = useState(0);
	const updatedArr = quesArr?.map((item, index) => ({ ques: item, idx: index + 1, answered: false}))
	const [questionArr, setQuestionArr] = useState(updatedArr);
	const handleSubmit = () => {

	}

	return (
		<Modal isOpen={isQuestion} style={backshadow}>
			{/* {quesArr?.map(item => )} */}
			<SingleQuestions question={questionArr[current]} setQuestion={setQuestion}/>
		</Modal>
	)
}

export default Question