import { CometChat } from "@cometchat-pro/chat";
import { apiUrl } from "./constants";
import axios from "axios";
import AWS from 'aws-sdk';

export const globalDateFormat = "DD/MM/YYYY";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS,
  secretAccessKey: process.env.REACT_APP_SECRET,
  region: process.env.REACT_APP_REGION,
});
const s3 = new AWS.S3();

export const handleFileUploadToS3 = async (file, fileName) => {
try {
    //fileName = fileName + new Date().toUTCString(); 
    const bucketName = process.env.REACT_APP_BUCKET_NAME;
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      //contentDisposition : 'inline;fileName="' + fileName + '"'
    };
    await s3.upload(params).promise();
    return true;
    //return await getImageUrl(bucketName, fileName);
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};

const getImageUrl = async (bucketName, fileName) => {
  try {
    const params = {
      Bucket: bucketName,
      Key: fileName,
      // Expires: 604800,
    };
    return await s3.getSignedUrlPromise('getObject', params);
  } catch (error) {
    console.error('Error generating image URL:', error);
    return null;
  }
};

export function saveObject(key, value) {
  if (window && window.localStorage) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
}

export function removeObject(key) {
  window.localStorage.removeItem(key);
}

export function getObject(key) {
  if (window && window.localStorage) {
    return JSON.parse(window.localStorage.getItem(key));
  }
  return null;
}

export function getUserType() {
  if (typeof window !== "undefined") {
    const path = window.location.pathname;
    const fetchValue = localStorage.getItem("linkValue")
    // if (path.includes("admin")) {
    //   return "admin";
    // }
    if ((path?.includes("doctor") || fetchValue?.includes('doctor')) && (!path?.includes("organization") || fetchValue?.includes('clinicians')) && (!path?.includes('subuser') || fetchValue?.includes('subadmin'))) {
      return !path?.includes("subuser/doctor") ? "doctor" : "subuser";
    }
    if (path?.includes("organization") || fetchValue?.includes('clinicians')) {
      return "organization";
    }
    if (path?.includes("subuser") || fetchValue?.includes('subadmin')) {
      return "subuser";
    } 
    if (path?.includes("emergency-contact")) {
      return "emergencyContact";
    }
    return "customer";
  }

  return "user";
}

export function getUser() {
  if (typeof window !== "undefined" && window.localStorage) {
    return JSON.parse(window.localStorage.getItem(getUserType()));
  }

  return null;
}

export function saveUser(value) {
  if (window && window.localStorage) {
    return window.localStorage.setItem(getUserType(), JSON.stringify(value));
  }

  return null;
}

export function saveDoctorUser(value) {
  if (window && window.localStorage) {
    return window.localStorage.setItem("doctor", JSON.stringify(value));
  }

  return null;
}

export function saveOrgUser(value) {
  if (window && window.localStorage) {
    return window.localStorage.setItem("organization", JSON.stringify(value));
  }

  return null;
}

export function isLoggedIn() {
  let session = getObject(getUserType());
  let token = session && session?.token;
  return session && session?.token;
}

export function getHeaders() {
  let user = getUser();
  return {
    Authorization: `Bearer ${(user && user.token) || null}`
  };
}

export function generateUrl(path) {
  if (path.includes("http")) {
    return path;
  }
  return apiUrl + path;
}

export function apiReq(endPoint, data, method, headers, config) {
  return new Promise((res, rej) => {
    headers = {
      ...getHeaders(),
      ...headers
    };

    if (method === "get") {
      data = {
        params: data,
        headers,
        ...config
      };
    }

    axios[method](endPoint, data, { ...config, headers })
      .then(result => {
        let { data } = result;
        return res(data);
      })
      .catch(err => {
        return rej(err);
      });
  });

}

export function apiPost(endPoint, data, headers = {}, config = {}) {
  return apiReq(generateUrl(endPoint), data, "post", headers, config);
}

export function apiDelete(endPoint, data, headers = {}, config = {}) {
  return apiReq(generateUrl(endPoint), data, "delete", headers, config);
}

export function apiGet(endPoint, data, headers = {}, config = {}) {
  return apiReq(generateUrl(endPoint), data, "get", headers, config);
}

export function apiPut(endPoint, data, headers = {}, config = {}) {
  return apiReq(generateUrl(endPoint), data, "put", headers, config);
}

export const isValidString = str => {
  return (str && str.trim()) || false;
};

export const globalValidator = (data, keys) => {
  for (let i in keys) {
    const key = keys[i];
    switch (key.validateKey) {
      case "email":
        if (!checkEmail(data[key.name])) {
          return "Please provide valid email";
        }
        break;
      case "required":
        if (!isValidString(data[key.name] && data[key.name].toString())) {
          return `${key.title} is Required`;
        }
        break;
      case "phone":
        if (!checkPhone(data[key.name])) {
          return `Please provide valid phone number`;
        }
        break;
      case "password":
        if (!checkPassword(data[key.name])) {
          return `Password should be minimum 6 digit`;
        }
        break;
      case "confirm_password":
        if (data["password"] !== data["confirm_password"]) {
          return `Password and Confirm Password do not match`;
        }
        break;
      default:
        break;
    }
  }
  return true;
};

export const checkEmail = email => {
  if (!isValidString(email)) {
    return false;
  }
  var emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegEx.test(email);
};

export const checkPhone = phoneNo => {
  if (phoneNo) {
    var phoneRegEx = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return phoneRegEx.test(phoneNo);
  } else {
    return false;
  }
};

export const checkPassword = password => {
  if (password.length >= 6) {
    return true;
  } else {
    return false;
  }
};

export function logout() {
  localStorage.removeItem(getUserType());
}

export function getErrorMessageFromResponse(err) {
  return (
    (err.response && err.response.data && err.response.data.error) ||
    "Server Encountered an error"
  );
}

export function convertArrayToSelectOptions(
  arr,
  labelKey = "name",
  idKey = "_id"
) {
  return arr.map(item => {
    return { data: item, label: item.name, value: item._id };
  });
}

export async function initCometChat() {
  const region = process.env.REACT_APP_COMETCHAT_REGION;
  const appID = process.env.REACT_APP_COMETCHAT_APPID;
  const authKey = process.env.REACT_APP_COMETCHAT_AUTHKEY;
  const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
  await CometChat.init(appID, appSetting);
  return authKey;
}

export const createCometUser = async (userType, userData, authKey, isUserExists) => {
  let user = null;
  let duplicateError = false;
  try {
    user = new CometChat.User(`${userData._id}`);
    user.setName(userData.name);
    if (userData.profileImage) user.setAvatar(userData.profileImage)
    if(isUserExists) {
      await CometChat.updateUser(user, authKey);
    } else {
      await CometChat.createUser(user, authKey);
    }
  } catch (error) {
    if (error.code === "ERR_UID_ALREADY_EXISTS") {
      duplicateError = true;
    } else {
    }
  }
  if (duplicateError) return createCometUser(userType, userData, authKey, true)
  return user;
}

export async function loginUserOnComet(userType, userData, setState) {
  let user = null;
  let authKey = null;
  let loggedIn = {}
  try {
    authKey = await initCometChat();
    user = await createCometUser(userType, userData, authKey);
    const _user = await CometChat.login(user.uid, authKey);
    setState({ loggedInUser: _user, initialised: true })
    navigator.permissions.query({ name: 'notifications' }).then(x => {
      if (x.state === 'prompt') {
        Notification.requestPermission()
          .then(console.log.bind(null, 'Notification permission'))
          .catch(error => console.log('Emed4u-Web > utils | 275 | error ', error))
      }
    }).catch(error => {
      console.log('Emed4u-Web > utils | 278 | error ', error)
    })
  } catch (error) {
    setState(prev => ({ ...prev, user: null, initialised: false }))
  } finally {
    return loggedIn;
  }
}