import React, { useState } from 'preact/hooks'
import { useMediaQuery } from 'react-responsive'
import Question from '../Question'
import { BackgroundImage, Border, ClinicStudyTitle, ConductStudyModeTitle, ConductStudyWrapper, ImageCardContainer, SectionStudy, StudyHeading, TagMain, TagText, TagsOut, TextWrapper, RenderItemsContainer, Background } from './styles'
import { ConductStudyCard, HoveredCard, Items, TitleWithCount } from './utility'
const titleArray = ['areas of interest', 'Year', 'Country', 'Biomakers', 'Publication type']

const DesktopConductStudy = ({ selected, setSelected, selectedTags = [], setSelectedTags, isQuestion, setQuestion }) => {

	const checkSelected = (val) => {
		return selected === val
	}

	const array = ['All areas of interest', 'Hypertension', 'Children', 'HIV']
	const arrayYear = ['ALL', '2022', '2021', '2020', '2019', '2018']

	const handleSelect = (val) => {
		if (!selectedTags.includes(val)) {
			setSelectedTags((prevState) => [...prevState, val])
		} else {
			let newArray = [...selectedTags]
			const index = [...newArray].indexOf(val);
			if (index !== -1) {
				newArray.splice(index, 1);
				setSelectedTags([...newArray])
			}
		}
	}

	const selectedArray = selected === 2 ? arrayYear : array
	const handleTags = (value) => (selected !== value) && setSelectedTags([]);
	const isMobile = useMediaQuery({
		query: '(min-width: 1080px)'
	  });
	return (
		<ConductStudyWrapper>
			<ImageCardContainer>
				<BackgroundImage src={'assets/img/conduct-study-image.png'} alt={'background'} />
				<HoveredCard isMobile={isMobile} setQuestion={setQuestion}/>
			</ImageCardContainer>
			<ClinicStudyTitle>Clinical Studies</ClinicStudyTitle>
			<SectionStudy>
				{/* <StudyHeading>
					<TextWrapper
						border={checkSelected(1)}
						onClick={() => { setSelected(1); setSelectedTags([]) }}
					>
						<ConductStudyModeTitle className={(checkSelected(1)) && 'colored'}>areas of interest</ConductStudyModeTitle>
					</TextWrapper>
					<TextWrapper
						border={checkSelected(2)}
						onClick={() => { setSelected(2); setSelectedTags([]) }}
					>
						<ConductStudyModeTitle className={(checkSelected(2)) && 'colored'}>Year</ConductStudyModeTitle>
					</TextWrapper>
					<TextWrapper
						border={checkSelected(3)}
						onClick={() => { setSelected(3); setSelectedTags([]) }}
					>
						<ConductStudyModeTitle className={(checkSelected(3)) && 'colored'}>Country</ConductStudyModeTitle>
					</TextWrapper>
					<TextWrapper
						border={checkSelected(4)}
						onClick={() => { setSelected(4); setSelectedTags([]) }}
					>
						<ConductStudyModeTitle className={(checkSelected(4)) && 'colored'}>Biomakers</ConductStudyModeTitle>
					</TextWrapper>
					<TextWrapper
						border={checkSelected(5)}
						onClick={() => { setSelected(5); setSelectedTags([]) }}
					>
						<ConductStudyModeTitle className={(checkSelected(5)) && 'colored'}>Publication type</ConductStudyModeTitle>
					</TextWrapper>
				</StudyHeading> */}
				<StudyHeading>
					{titleArray?.map((item, index) => (
						<TextWrapper
							key={index}
							border={checkSelected(index + 1)}
							onClick={() => { setSelected(index + 1); handleTags(index + 1) }}
						>
							<TitleWithCount id={index + 1} title={item} selectedTags={selectedTags} checkSelected={checkSelected(index + 1)} />
						</TextWrapper>
					))}
				</StudyHeading>
				<TagsOut>
					{selectedArray.map((val, index) => {
						return (
							<TagMain
								key={index}
								onClick={() => handleSelect(val)}
								active={selectedTags.includes(val)}
							>
								<TagText active={selectedTags.includes(val)}>{val}</TagText>
							</TagMain>
						)
					})}
				</TagsOut>
				<RenderItemsContainer>
					{[...Array(9).keys()]?.map((_) => <Items />)}
					<Items />
				</RenderItemsContainer>
			</SectionStudy>
			<Question isQuestion={isQuestion} setQuestion={setQuestion}/>
		</ConductStudyWrapper>
	)
}

export default DesktopConductStudy;