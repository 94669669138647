import React from 'react';

import { Link } from 'react-router-dom';
import moment from 'moment';

import { toast } from 'react-toastify';

import { Context } from '../../../../store';
import {
	getAppointmentDetailsAPI
} from '../../../../api/patient';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import Breadcrumb from '../../../../components/Breadcrumb';
import CardComponent from '../../../../components/Card';
import InsideMenuBar from '../../../../components/InsideMenus';

function BookSuccess(props) {
	const [state, dispatch] = React.useContext(Context);

	const [data, setData] = React.useState(null);

	React.useEffect(() => {
		if(props.match.params && props.match.params.id) {
			loader(true);
			getAppointmentDetailsAPI(props.match.params.id).then(res => {
				loader(false);
				// toast.success(res.message)
				// dispatch({
				// 	type: SET_ERROR,
				// 	payload: res.message
				// });
				setData(res.data);
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		}
	}, [])

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	return (
		<div>
			<InsideMenuBar
				props={props}
				type={'patient'}
			/>

			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent>
								<div className="success-cont p-5">
									<i className="fas fa-check"></i>
									<h3>Appointment booked Successfully!</h3>
									<p>Appointment booked with <strong>{data && data.doctorId.name}</strong><br />{data && moment(data.slot.start).utc().format('Do MMMM, YYYY')} on <strong>{data && moment(data.slot.start).utc().format('hh:mm a')} - {data && moment(data.slot.end).utc().format('hh:mm a')}
									({data && moment(data.slot.start).utc().format('dddd')}) </strong></p>
									<Link to={"/patient/invoices"} className="btn btn-primary view-inv-btn">Go to invoices </Link>
								</div>
							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BookSuccess
