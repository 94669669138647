/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { VictoryPie } from 'victory';

const StyledSvg = styled('svg')(() => ({
    width: 240,
    margin: '-34px 0px -25px'
}))

export default React.memo(function ReactCirculargauge({max = 120, value = 0, color }) {
    // calculate needle angle
    const needleAngle = React.useMemo(() => {
        // calculate percentage
        const percentage = (270 / max) * value;
        return -135 + percentage;
    }, [max, value])

    return (
        <StyledSvg viewBox="0 0 400 400">
            <VictoryPie
                standalone={false}
                animate={{ duration: 500 }}
                width={400}
                height={400}
                data={[100]}
                labels={() => null}
                cornerRadius={25}
                padAngle={5}
                colorScale={[color]}
                startAngle={-135}
                endAngle={135}
                innerRadius={130}
            />
            <line
                x1="200"
                y1="200"
                x2="200"
                y2="115"
                stroke={color}
                strokeWidth="6"
                transform={`rotate(${needleAngle}, 200, 200)`}
            />
        </StyledSvg>
    )
})