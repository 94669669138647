import styled from "styled-components";
import { ReactComponent as Cross } from '../../../assets/img/cross.svg';
import { ReactComponent as Arrow } from '../../../assets/img/chevron-down.svg';


export const CrossButton = styled(props => <Cross {...props}/>)`
	position: absolute;
	top: 17px;
	right: 16px;
	border: 0px;
	background: #fff;
    cursor: pointer;
`;

export const QuestionTitle = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: #2B2B2B;
    // margin: 248px 0 56px;
    margin: 22vh 0 7vh;

    @media (max-width: 320px) {
        font-size: 16px;
        line-height: 24px;
        color: #002952;
    }    
`;

export const SubmitButton = styled.div`
    background: ${(props => props.isVisible ? 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)' : '#F5F6FA')};
    border-radius: 8px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    width: 200px;
    height: 58px;

    @media (max-width: 320px) {
        font-size: 16px;
        line-height: 24px;
        width: 148px;
        height: 40px;

        &.centered {

        }
    }

    display: flex;
    align-items: center;
    color: ${((props) => props.isVisible? '#FFFFFF' : '#CCCCCC')};

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 48px 0 0;
    cursor: pointer;
`;

export const NavigateArrow = styled.div`
    display: flex;
    justify-content: center;
	margin: 176px 0 0;
`;

export const ArrowIcon = styled.div`
	// width: 48px;
	// height: 48px;
	// background: ${(props)=>props.check ? 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);' : 'linear-gradient(90deg, rgba(9, 126, 183, 0.3) 0%, rgba(42, 171, 108, 0.3) 100%)'};
	// border-radius: 8px;
	// display: flex;
    // justify-content: center;
    // align-items: center;
	// cursor: ${(props)=> props.check ? 'pointer' : 'not-allowed'};
	// transform: ${(props) => props.left ? 'rotate(180deg)'  :'rotate(0deg)'}

    width: 80px;
    height: 48px;
    background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
    border-radius: 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 4px;
    cursor: pointer;
    @media (max-width: 320px) {
        width: 72px;
        height: 32px;
    }
`;

export const Down = styled(ArrowIcon)``;

export const Up = styled(ArrowIcon)``;

export const RightArrow = styled(Arrow)`
    transform: ${(props) => props.down ? 'rotate(90deg)' : 'rotate(-0.25turn);'};
`;

export const QuestionWrapper = styled.div`
    padding: 32px 32px 41px;
    @media (max-width: 320px) {
        padding: 32px 24px 48px;
    }
    position: relative;
    background: #fff;
    margin: 10px;
    height: 97%;
`;

export const ContentArea = styled.div`
    margin: 0px 78px 0 142px;
    @media (max-width: 320px) {
        margin: 0;
    }
`;

export const Input = styled.input`
    border-bottom: 2px solid #002952;
    width: 100%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    @media (max-width: 320px) {
        font-size: 16px;
        line-height: 24px;
    }
    color: #2B2B2B;

    padding: 0;
    border-radius: 0;
    border: 0;

    outline: none;
`;

export const BottomBar = styled.div`
    border: 2px solid #002952;
    margin: 16px 0 0;
`;

export const Modal = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;   
    z-index: 1050;
    
    display: ${(props) => props.isOpen ? 'block' : 'none'}
`;

export const ProgressBar =  styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    
    background: #CCCCCC;
    height: 17px;

    &.colored {
        background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
        @media (max-width: 320px) {
            height: 8px;
        }
    }
    @media (max-width: 320px) {
        height: 8px;
    }
`;