import styled from 'styled-components';

export const StyledYoga = styled.div`
.PhoneInput .PhoneInputInput {
    border: 1px solid #dcdcdc;
    color: #333;
    font-size: 15px;
    min-height: 46px;
    padding: 6px 15px;
    border-radius: 6px;
  }

  .nlp-benifits {
    border-right: 2px solid #60cf68;
    padding: 40px 0px;
  }

  .req-app-ce {
    padding: 40px 20px;
    background: #fff;
  }

  .nlp-benifits li {
    font-size: 19px;
  }

  .nlp-benifits ul {
    list-style: none;
  }

`;

export const StyledButton = styled.div`
  text-align: center;
`;

export const StyledCardButton = styled.button`
  margin-top: 20px;
  background: #19ce67;
  color: #ffffff;
  border: none;
  line-height: initial;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 17px 50px 15.5px 25px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  display: inline-flex;
  font-weight: 600;
  position: relative;

  i {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    display: inline-block;
    height: 33px;
    width: 33px;
    line-height: 33px;
    color: #ffffff;
    border-radius: 50%;
    background-color: #8ee9b2;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
`;


export const StyledSubTitle = styled.p`
  color: #19ce67 !important;
  font-weight: 500;
  font-size: 17px !important;

  .text-center {
    text-align: center;
  }

  

`;

export const StyledCardWrapper = styled.div`
  text-align: center;
  // min-height: 240px;
  padding: 5px 20px;

  
`;

export const StyledCardIconWrapper = styled.div`
  margin-bottom: 20px;
`;

export const StyledCardIcon = styled.img`
  background: white;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 2px 2px 13px rgba(0, 0, 0, 0.1);
`;

export const StyledCardTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
`;

export const StyledCardSubTitle = styled.span`
  color: #212529;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
`;