import styled from 'styled-components';

export const StyledLeftPanel = styled.div`
  background-color: #2d80b8 !important;
  border-top-left-radius: .25rem!important;
  border-bottom-left-radius: .25rem!important;

  .h-100 {
    height: 100%!important;
	}

	.no-gutters {
    margin-right: 0;
    margin-left: 0;
	}

	
`;

export const StyledLeftHeading = styled.h3`
  color: #fff!important;
`;

export const StyledLeftSubHeading = styled.p`
	color: #dee3e4;
	line-height: 1.6;
	font-size: 1.125rem;
	margin-bottom: 1.5rem;
`;

export const StyledLeftImage = styled.img`
	max-width: 100%;
	height: auto;
	vertical-align: inherit;
`;

export const StyledRightPanel = styled.div`
	padding-right: 0;
	padding-left: 0;
	align-items: center!important;
	display: flex!important;
	border-top-right-radius: .25rem!important;
	border-bottom-right-radius: .25rem!important;
	background-color: #fff!important;

	.PhoneInputInput {
		border-color: #dcdcdc;
		color: #333;
		font-size: 15px;
		min-height: 46px;
		padding: 6px 15px;
		border: 1px solid #ced4da;
		border-radius: .25rem;
		transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	}


	.container-checkbox {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		font-weight: 500;
		font-size: 14px;
		font-size: 0.875rem;
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}

		a {
			color: #1dbe72;
		}

		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 20px;
			width: 20px;
			background-color: #eee;
			border-radius: 5px;
		}
	}
	.container-checkbox:hover input ~ .checkmark {
		background-color: #ccc;
	}
	.container-checkbox input:checked ~ .checkmark {
		background-color: #1dbe72;
	}
	.container-checkbox .checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}
	.container-checkbox input:checked ~ .checkmark:after {
		display: block;
	}
	.container-checkbox .checkmark:after {
		left: 7px;
		top: 4px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;

export const StyledRightHeading = styled.h3`
	font-size: 1.125rem !important;
	text-align: center!important;
	margin-bottom: 1.5rem!important;
`;

export const StyledRightSocialLogin = styled.ul`
	margin: 0;
	padding: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	list-style: none;
`;

export const StyledSocialFB = styled.li`
	margin: 0px 6px 4px;
	padding: 0;
	overflow: visible;
	background-color: #1874eb;
	border-radius: 50%;
	display: block;
	height: 38px;
	line-height: 38px;
	width: 38px;
	font-size: 18px;
	text-align: center;
	color: #fff;
	text-decoration: none;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
`;

export const StyledSocialGoogle = styled.li`
	margin: 0px 6px 4px;
	padding: 0;
	overflow: visible;
	background-color: #DB4437;
	border-radius: 50%;
	display: block;
	height: 38px;
	line-height: 38px;
	width: 38px;
	font-size: 18px;
	text-align: center;
	color: #fff;
	text-decoration: none;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
`;

export const StyledRightDivider = styled.div``;

export const StyledDividerLine = styled.hr`
	border-top: 1px solid rgba(16, 85, 96, 0.1);
	-ms-flex-positive: 1!important;
	flex-grow: 1!important;
`;

export const StyledDividerHeading = styled.span`
	font-size: 0.875rem !important;
	color: #6c757d!important;
`;

export const StyledLoginButton = styled.button`
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	width: 100%;
	height: 40px;
	border: 0;
	color: #fff;
	background: #19ce67;
	border-radius: 5px;
	text-align: center;
	text-transform: none;
	overflow: visible;
`;

export const StyledRightBottom = styled.div`
	margin-top: 30px;
	border-radius: 0 0 5px 5px;
	text-align: center;
	padding: 15px 10px;
	background: #19ce67;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	background-image: -webkit-linear-gradient(left,rgb(119 230 91 / 90%),rgb(8 160 132 / 90%));
	background-image: linear-gradient(to right,rgba(119,230,91,0.9),rgb(8 160 132 / 90%));
`;

export const StyledBottomContent = styled.p`
	margin-bottom: 0;
	margin-top: 0;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
`;

export const StyledBottomLink = styled.a`
	color: #fff;
	font-size: 12px;
	background: #2b2f4c;
	padding: 7px 10px;
	border-radius: 3px;
	display: inline-block;
	margin-left: 5px;
	cursor: pointer;
`;

export const StyledForgotPassword = styled.div`
	text-align: right!important;
`;

export const StyledForgotPasswordLink = styled.a`
	color: #3d3d3d !important;
	display: inline-block;
	font-size: 13px;
	margin-bottom: 10px;
	font-weight: 400;
	cursor: pointer;

	&:hover {
		color: #19ce67 !important;
	}
`;
