
import React, { useState } from 'react';
import { TextArea } from './styles'
import { SingleRadio } from './utility';
import { ReactComponent as Angrysmile } from '../../assets/img/angrysmile.svg'
import { ReactComponent as Sadsmile } from '../../assets/img/sadsmile.svg'
import { ReactComponent as Silentsmile } from '../../assets/img/silentsmile.svg'
import { ReactComponent as Happysmile } from '../../assets/img/happysmile.svg'
import { ReactComponent as Smile } from '../../assets/img/smile.svg'
import { useMediaQuery } from 'react-responsive';
import './modal.css';

const FeedBackModal = (props) => {
	const [selectedOption, setSelectedOption] = useState(null);
	const isMobile = useMediaQuery({
		query: '(max-width: 700px)'
	})
	
	return <div className="modal ContactUsModal custom-modal fade none-border show" style={{
		display: props.feedbackToggle ? 'block' : 'none', backdropFilter: 'blur(1px)',
		background: 'rgb(104 71 99 / 24%)',
	}}>
		<div className="modal-dialog modal-dialog-centered">
			<div className="modal-content">
				<div className="modal-header pb-0" style={{ flexDirection: 'column', border: 'none', padding: '2.25rem 65px' }}>
					<img
						className="call-avatar "
						src={"/assets/img/x.png"}
						alt="User"
						style={{ position: 'absolute', right: '18px', top: '18px', cursor: 'pointer' }}
						data-dismiss="modal"
						onClick={(e) => {
							document.body.style.overflowY = 'auto'
							props.setFeedbackToggle(feedbackToggle => !feedbackToggle);
						}}
					/>



				</div>
				<div className="modal-body d-flex row " style={{ padding: '0 40px' }}>
					<div className="col-md-12 no-padding form-group feedback-inner-container" >
						<h4 className="feedback-heading">Feedback form</h4>
						<div className="">

							<p className="m-0  mt-2 feedbackinnertitleone">How do you feel about our website?</p>
							<div className="feedback-reviews">
								<Angrysmile style={{ width: isMobile ? 24 : null, height: isMobile ? 24 : null }} />
								<Sadsmile style={{ marginLeft: isMobile ? '29px' : '44px', width: isMobile ? 24 : null, height: isMobile ? 24 : null }} />
								<Silentsmile style={{ marginLeft: isMobile ? '29px' : '44px', width: isMobile ? 24 : null, height: isMobile ? 24 : null }} />
								<Happysmile style={{ marginLeft: isMobile ? '29px' : '44px', width: isMobile ? 24 : null, height: isMobile ? 24 : null }} />
								<Smile style={{ marginLeft: isMobile ? '29px' : '44px', width: isMobile ? 24 : null, height: isMobile ? 24 : null }} />


							</div>

						</div>
						<div className="">
							<p className="m-0 mt-2 feedbackinnertitle" >How likely are you to recommend our product and services to others?</p>
							<div>
								<div className="feedbackradiogroup">
									{[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((item, index) => <SingleRadio key={item} index={index} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />)}
								</div>
								<div className="feedback-radio-group-inner">
									<p className="feedback-radio-group-inner-text">not at all</p>
									<p className="feedback-radio-group-inner-text">Very likely</p>
								</div>
							</div>
							{/* <RadioItem>
							<RadioItemText>0</RadioItemText>
							<CircleContainer><RadioItemCirlce /></CircleContainer>
						</RadioItem> */}
						</div>
						<p className="feedbackinnertitlethree">What feature do you want us to improve?</p>
						<div className="input-field-component-textarea">
							<TextArea
								className="feedback-textarea"
								placeholder="Type your feedback...."
							// value={data1.name} 
							// onChange={e => setData1((value) => ({ ...value, name: e.target.value }))}
							/>
						</div>

						<div className="new-input-field">
							<p className=" feedbackinnertitleother">Help us reach out to you(optional)</p>
							<div className="input-field-component">
								<input
									className="mt-3  form-control input-field-feedback"
									placeholder="Full Name"
								// value={data1.name} 
								// onChange={e => setData1((value) => ({ ...value, name: e.target.value }))}
								/>
								<input
									className="mt-3  form-control input-field-feedback"
									placeholder="Email"
								// value={data1.name} 
								// onChange={e => setData1((value) => ({ ...value, name: e.target.value }))}
								/>
							</div>

						</div>

					</div>
				</div>
				<div className="modal-feedback-button-main">
					<div
						className="modal-feedback-button"
						style={{ cursor: 'pointer' }}
					>
						<p className="modal-feedback-button-text">Submit</p>
					</div>
				</div>

			</div>
		</div>
	</div>
}

export default FeedBackModal