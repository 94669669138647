import React from 'react';

import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getCouponsAPI
} from '../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import {
	StyledContainer,
	StyledHeading,
	StyledAdd
} from './styles';

import CardComponent from '../../../../components/Card';
import TableComponent from '../../../../components/Table';
import Pagination from '../../../../components/Pagination';
import Search from '../../../../components/Search';
import InsideMenuBar from '../../../../components/InsideMenus';

const tableHeading = [
	{id: 1, title: 'Coupon code'},
	{id: 3, title: 'Amount'},
	{id: 4, title: 'Start - End Date'},
	// {id: 5, title: 'Status'},
	{id: 6, title: 'Actions'}
];

const options = [
	{ id: 1, label: 'Name', value: 'name' },
	{ id: 4, label: 'Contact Number', value: 'contact' },
	{ id: 2, label: 'Email', value: 'email' },
	{ id: 3, label: 'Status', value: 'status' },
	{ id: 0, label: 'Id', value: 'id' },
]

const statusOptions = [
	{ id: 0, label: 'Active', value: '0' },
	{ id: 1, label: 'Closed', value: '1' }
]

function Coupons(props) {

	const [state, dispatch] = React.useContext(Context);

	const [coupons, setCoupons] = React.useState([]);

	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('name');
	
	const fetchCouponsApi = () => {
		loader(true);
		getCouponsAPI().then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setCoupons(res?.data);
		
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	React.useEffect(() => {
		fetchCouponsApi();
	}, []);

	const searchPatient = () => {
		loader(true);
		const payload = {
            [selectValue]: search
        }
		getCouponsAPI(payload).then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setCoupons(res?.data);
		
		}).catch(err => {
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const [couponPaginate, setCouponPaginate] = React.useState([]);
	const pageChangeCoupon  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setCouponPaginate(data);
	}

	React.useEffect(() => {
		fetchCouponsApi();
	}, [selectValue]);

	React.useEffect(() => {
        if(search === '') {
            fetchCouponsApi();
        }
    }, [search])

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">

									<StyledHeading>
										Coupons
									</StyledHeading>
									<hr/>
									<div className="doctor-panel-filters row col-md-12 p-4">
										<Search
											selectValue={selectValue} 
											setSelectValue={setSelectValue} 
											search={search} 
											setSearch={setSearch} 
											searchPatient={searchPatient} 
											name={"Coupon"}
											options={options}
											statusOptions={statusOptions}
										/>
										<div className="col-md-2 mt-5 mb-0 no-padding form-group doctor-active-btn-div">
											<button className="doctor-active-btn m-0" onClick={() => props.history.push('/organization/add-coupon')}>Create coupon</button>
										</div>
									</div>
								
								<TableComponent tableHeading={tableHeading}>
								{coupons.length === 0 ? (<h5 style={{width: '100%', textAlign: 'center', marginLeft: '150%', marginTop: '10%'}}>No data found!</h5>) : 
									
									couponPaginate.map(item => {
											return <tr>
															<td>{item.coupon}</td>
															<td>
																{
																	item.type == '%' ? item.amount+'%' : '₹'+item.amount
																}
															</td>
															<td>{moment(item.start_time).utc().format('DD/MM/YYYY')} - {moment(item.finish_time).utc().format('DD/MM/YYYY')}</td>
															{/* <td><span className="badge badge-pill bg-success inv-badge">Active</span></td> */}
															{/* 	<td><span class="badge badge-pill bg-danger-light">Cancelled</span></td> */}
															{/* <td><span class="badge badge-pill bg-warning-light">Pending</span></td> */}
															<td>
																<div className="table-action">
																	<Link to={`/organization/view-coupon/${item._id}`} className="btn btn-sm bg-info-light-blue">
																		<i className="far fa-eye"></i> View
																	</Link>
																</div>
															</td>
														</tr>
										})
									
									}
								</TableComponent>

								{coupons.length > 0 && <Pagination items={coupons} onChangePage={pageChangeCoupon} />}

							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default Coupons
