import { object, number } from "yup";

export const lipidProfileSchema = object({
    total_cholesterol: number()
        .typeError("Cholestrol must be a number")
        .min(0, "Cholestrol must be greater than or equal to 0")
        .max(300, "Cholestrol must be less than or equal to 300")
        .required("Cholestrol is required"),
    LDL_C: number()
        .typeError("LDL_C must be a number")
        .min(0, "LDL_C must be greater than or equal to 0")
        .max(200, "LDL_C must be less than or equal to 200")
        .required("LDL_C is required"),
    VLDL: number()
        .typeError("VLDL must be a number")
        .min(0, "VLDL must be greater than or equal to 0")
        .max(35, "VLDL must be less than or equal to 35")
        .required("VLDL is required"),
    HDL_C: number()
        .typeError("HDL_C must be a number")
        .min(0, "HDL_C must be greater than or equal to 0")
        .max(70, "HDL_C must be less than or equal to 70")
        .required("HDL_C is required"),
    triglycerides: number()
        .typeError("Triglycerides must be a number")
        .min(0, "Triglycerides must be greater than or equal to 0")
        .max(500, "Triglycerides must be less than or equal to 500")
        .required("Triglycerides is required"),
});