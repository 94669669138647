import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import Slider from "react-slick";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import { StyledHomeSlider } from './styles';
import CommonSignUpModal from '../../../components/Header/CommonSignUpModal/index';
import { Context } from '../../../store';
import LoginModal1 from '../../../components/Header/LoginModal1';

function HomeSlider() {
  const [isSignUpModal, setSignUpModal] = useState(false);
  const [loginToggle, setLoginToggle] = useState(false);
  const [state, dispatch] = useContext(Context);
  const history = useHistory();

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    className : 'home-main-slider-full slider-main-mobile-screen',
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    arrows: false
  };


  const HomeSlider = [
    {
      id: 1,
      title: 'Notice every difference in your body.',
      bgClass: 'col-12 d-flex bd-home-slider bg-img-001',
      mobileClass: 'bg-home-mobile-001',
      route: '/organization/register'
    },
    {
      id: 2,
      title: 'Always stay in touch with your patient.',
      bgClass: 'col-12 d-flex bd-home-slider bg-img-002',
      mobileClass: 'bg-home-mobile-002',
      route: '/organization/register'
    },
    {
      id: 3,
      title: 'Health support is one touch away.',
      bgClass: 'col-12 d-flex bd-home-slider bg-img-003',
      mobileClass: 'bg-home-mobile-003',
      route: '/organization/register'
    },
  ]

  const handleLoginOpen = () => {
		document.body.style.overflowY = 'auto'
		setLoginToggle(loginToggle => !loginToggle);
	}

  const getRoute = (type) => {
		if (type === 'customer') {
			return '/patient/dashboard';
		} else if (type === 'doctor') {
			return '/doctor/schedule';
		} else if (type === 'organization') {
			return 'organization/dashboard';
		} else if (type === 'subuser') {
			return 'subuser/dashboard';
		}
	}

  const handleClickButton  = () => {
    if (state && state?.userType) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      history.push(getRoute(state?.userType));
    } else {
      setSignUpModal(!isSignUpModal)
    }
  }

  return (
    <StyledHomeSlider>

      <Slider {...settings}>
        {
          HomeSlider.map(item => {
            return  <div className={item.bgClass}>
                    <div className="slide-image col-12 col-lg-8">
                      <h1 className="mb-3" style={{ textAlign: 'left' }}>
                        {item.title}
                      </h1>
                      <div className="d-inline-block img-dsk-sh-book" style={{ textAlign: 'left' }}>
                        <button onClick={() => handleClickButton()} className="btn book-btn1 new-book-btn1" tabindex="0">{`Explore more >`}</button>
                      </div>
                    </div>
                    <div className={`col-12 col-lg-6 d-flex img-dsk-sh ${item.mobileClass}`}>
                      <div className="d-inline-block img-dsk-sh-book-01">
                        <button onClick={() => handleClickButton()} className="btn book-btn1 new-book-btn1" tabindex="0">{`Explore more >`}</button>
                        
                      </div>
                    </div>
                  </div>
          })
        }
      </Slider>

      {/* <Carousel
        autoPlay
        infiniteLoop
        showStatus={false}
        showArrows={false}
      >
        {
          HomeSlider.map(item => {
            return <div className={item.bgClass}>
              <div className="slide-image col-12 col-lg-8">
                <h1 className="mb-3" style={{ textAlign: 'left' }}>
                  {item.title}
                </h1>
                <div className="d-inline-block img-dsk-sh-book" style={{ textAlign: 'left' }}>
                  <Link to={item.route} className="btn book-btn1 new-book-btn1" tabindex="0">{`Explore more >`}</Link>
                </div>
              </div>
              <div className={`col-12 col-lg-6 d-flex img-dsk-sh ${item.mobileClass}`}>
                <div className="d-inline-block img-dsk-sh-book-01">
                  <Link to={item.route} className="btn book-btn1 new-book-btn1" tabindex="0">{`Explore more >`}</Link>
                </div>
              </div>
            </div>
          })
        }
      </Carousel> */}
      	<div className="modal custom-modal fade none-border show handleLoginOpen" style={{
					display: loginToggle ? 'block' : 'none', backdropFilter: 'blur(1px)',
					background: 'rgb(104 71 99 / 24%)'
				}}>
					<div className="overlay-background-disable" />
					<LoginModal1
						isSignUpModal={isSignUpModal}
						// handleOpen={handleLoginOpen}
						setLoginToggle={setLoginToggle}
						loginToggle={loginToggle}
						toggleLogin={() => {
							handleLoginOpen()
							setSignUpModal(true)
						}} 
					/>
				</div>
      <CommonSignUpModal 
				isSignUpModal={isSignUpModal} 
				setSignUpModal={setSignUpModal} 
				toggleSignUp={()=>{
					setSignUpModal(false);
					handleLoginOpen();
				}}
			/>
    </StyledHomeSlider>
  )
}

export default HomeSlider;
