import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { Grid, Box, CircularProgress, Modal } from "@mui/material";
import strings from "../../../constant/strings";
import HeadingComponent from "../HeadingComponent";
import { useStyles } from "../../../assets/styles/PatientDashboardStyles";
import TabComponent from "../../Tab";
import { daySchedule, scheduleTabs } from '../../../constant/staticLists';
import { scheduleStyles } from "../../../assets/styles/DocScheduleStyles";
import { capitalizeFirstLetter } from "../../../constant/staticHelper";
import SelectionDropdown from "../../control/SelectionDropdown";
import SlotScheduler from "../../control/SlotScheduler";
import HDFCPng from "../../../assets/img/HDFC.png";
import * as mainService from "../../../services/mainService.js";
import { StyledSecondaryButton, modalStyle } from "../../../assets/styles/CommonStyles";
import { AddOutlined } from "@mui/icons-material";
import CalenderBlock from "../../control/CalenderBlock";
import DoctorSlotCreation from "./DoctorSlotCreation";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorAvailableTimeAction, getSlotsByDoctorAction } from "../../../redux/schedule/doctor/doctorScheduleAction";
import moment from "moment";
import { toast } from "react-toastify";
import deleteSlot from "../../../assets/img/warning.png";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import { getCurrencyAction } from "../../../redux/public/publicAction";

const Schedule = () => {
  let selectedDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
  const classes = useStyles();
  const dispatch = useDispatch();
  const styleClasses = commonMuiStyles();
  const scheduleClasses = scheduleStyles();
  const [tabValue, setTabValue] = useState(0);
  const [date, setDate] = useState(selectedDate);
  const [isSlotDelete, setSlotDelete] = useState(false);
  const [scheduleSelection, setScheduleSelection] = useState("d");
  const [isSlotClicked, setSlotClicked] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [localList, setLocalList] = useState([]);
  const [currencyList, setCurrency] = useState([]);
  const { userData, getSlotsByDoctorList ,currencieslist} = useSelector((state) => ({
    userData: state.auth.userData,
    getSlotsByDoctorList: state.schedule.getSlotsByDoctorList,
    currencieslist: state.public.currencies
  }));

  const daySchedules = Object.keys(daySchedule).map(key => ({
    label: capitalizeFirstLetter(key.replace(/_/g, ' ').toLowerCase()),
    level: daySchedule[key]
  }));

  useEffect(() => {
    selectedDate = moment(new Date(selectedDate)).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    getList(scheduleSelection, selectedDate)
  }, [])

  useEffect(() => {
    setLocalList(getSlotsByDoctorList);
  }, [getSlotsByDoctorList]);

  // useEffect(async () => { 
  //  dispatch(getCurrencyAction())
  //   currencieslist?.map((item) => {
  //     const temp = { label: item.name, level: item.enum }
  //     setCurrency((prev) => [...prev, temp])
  //     console.log("currencyList-----",currencyList)
  //   });
  // },[]);

  const getList = (duration, date) => {
    dispatch(getSlotsByDoctorAction({ token: userData.token, duration: duration, date: date }))
  }

  

  const handleTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const dateValue = (value) => {
    //const formattedDate = moment(new Date(value)).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') 
    const formattedDate = moment(new Date(value)).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');  
    setDate(formattedDate);
    getList(scheduleSelection, formattedDate)
  };

  const handleDropdownValue = (data) => {
    setScheduleSelection(data.level);
    getList(data.level, date)
    dispatch(getSlotsByDoctorAction({ token: userData.token, duration: data.level, date: date }))

  }

  const handleSlotCreation = () => {
    setSlotClicked(!isSlotClicked);
    dispatch(getDoctorAvailableTimeAction({ date: selectedDate, token: userData.token }))
   // dispatch(getCurrencyAction())
  }

  const setSlotClick = (item) => {
    setSlotClicked(item);
    getList(scheduleSelection, selectedDate)
  }

  const handleClose = () => {
    setSlotDelete(false);
  }

  const handleDeleteClick = async () => {
    let value = { slot_id: selectedItem._id, token: userData.token }
    const res = await mainService.deleteSlotsByDoctorApi(value);
    if (res.response_type === "fail") {
      toast.error(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: { width: 'auto', borderRadius: 8 } });
    } else {
      setLocalList(prevList => prevList.filter(item => item._id !== selectedItem._id));
      setSelectedItem({});
      setSlotDelete(false);
      toast.success(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: { width: 'auto', borderRadius: 8 } });
    }
  }

  const handleSlotDelete = (value, item) => {
    setSlotDelete(value);
    setSelectedItem(item);
  }

  return (
   
    <>
     {!isSlotClicked ? <Grid container spacing={0}>
      <Grid item xs={12} sm={8} lg={10} className={classes.mainCont}>
        <HeadingComponent text={{ "primary": strings.schedule, "secondary": strings.scheduleHeading }} />
      </Grid>
      <Grid item xs={12} sm={4} lg={2} className={classes.buttonContainer}>
        <StyledSecondaryButton
          onClick={handleSlotCreation}
          variant="contained">
          <AddOutlined />
          {strings.createSlot}
        </StyledSecondaryButton>
      </Grid>
      <Grid item xs={12} lg={12}>
        <TabComponent tabs={scheduleTabs} tabValue={tabValue} setTabValue={setTabValue} handleTab={handleTab}
          customTab={styleClasses.customTab}
          customTabIndicator={styleClasses.customTabIndicator} />
      </Grid>

      <Grid item xs={12} lg={4} className={scheduleClasses.secondaryCont}>
        <Grid className={scheduleClasses.dropdownCont}>
          <SelectionDropdown
            name="selectGender"
            list={daySchedules}
            value={scheduleSelection}
            handleDropdownValue={handleDropdownValue}
          />
        </Grid>
        <CalenderBlock dateValue={dateValue} date={date} scheduleSelection={scheduleSelection} />
      </Grid>

      <Grid item xs={12} lg={8} className={scheduleClasses.secondaryCont}>
        <SlotScheduler scheduleSelection={scheduleSelection} list={localList} tabValue={tabValue}
          setSlotDelete={handleSlotDelete} isSlotDelete={isSlotDelete} date={date}
        />
      </Grid>

    </Grid> :
      <DoctorSlotCreation
        scheduleSelection={tabValue}
        isSlotClicked={isSlotClicked}
        setSlotClicked={setSlotClick}
        //currencyList={currencyList}
      />}
      <Modal
        keepMounted
        open={isSlotDelete}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={modalStyle} p={2} className={scheduleClasses.modalCont}>
          <img src ={deleteSlot} alt="delete slots" className={scheduleClasses.warningImageStyle} />
          <div className={scheduleClasses.marginStyle}>Do you want to delete Slot? </div>
          <Grid container display={"flex"} justifyContent={"space-around"} mt={3} style={{ width: '100%'}}>
            <StyledSecondaryButton onClick={handleClose} className={scheduleClasses.buttonStyle}>
              Cancel
            </StyledSecondaryButton>
            <StyledSecondaryButton onClick={handleDeleteClick} className={scheduleClasses.primaryButtonStyle}>
              Delete Slot
            </StyledSecondaryButton>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}

export default React.memo(Schedule);