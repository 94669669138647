import styled from "styled-components";

export const Wrapper = styled.div`
    background: #2B2B2B;
    padding: 32px 83px;
    @media screen and (max-width:700px){
        padding: 32px 5px;
    }
`;

export const Heading1 = styled.h1`
    display: flex;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
`;


export const H1Ttitle = styled(Heading1)`
    font-weight: 500;
    font-size: 64px;
    line-height: 94px;
    text-align: center;
    color: #FFFFFF;
    &.colored {
        background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 15px;
    }
    // @media screen and (min-width:700px) and (max-width:1122px) {
    //     white-space: nowrap;
    //     font-size: 3rem;
    // }
`;

export const Image = styled.img`
    margin: 0 18px 48px;
    cursor: ${(props) => !props.active ? '' : 'pointer'};;
    opacity: ${(props) => !props.active ? '0.3' : '1'};
`;

export const ImageWrapper = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 20px;
    @media screen and (max-width:700px){
        justify-content: space-between;
        width: 100%;
    }

`;

export const MainDiv = styled.div`
    position: relative; 
    margin-top: 40px;
    @media screen and (max-width:700px){
        height: 550px;
    }
`;

export const EmptyCircle = styled.div`
    width: 40px;
    height: 40px;
    background: #2B2B2B;
    border: 2px solid #CCCCCC;
    border-radius: 112px;
    margin: 8px 0;
    z-index: 2;
`;

export const FilledCircle = styled.div`
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    border: 4px solid #FFFFFF;
    border-radius: 112px;
    margin: 17px 0 30px;
    z-index: 2;
`;

export const LightText = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #CCCCCC;
    display: flex;
    justify-content: center;
    cursor:pointer;
    &.increase-size {
        font-size: 28px;
        line-height: 42px;
        color: #FFFFFF;
        justify-content: center;
        @media screen and (max-width:700px){
            font-size: 16px;
        }
    }

    @media screen and (max-width:700px){
        font-size: 12px;
    }
`;

export const ListWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ItemWrapper = styled.div`
    margin: ${(props) => props.check ? '-25px 0px 0px' : ''};
    @media screen and (max-width:700px){
        width: 120px;
    }
`;

export const Line = styled.hr`
    border-top: 1px solid #CCCCCC;
    z-index: 1;
    position: absolute;
    top: 36px;
    width: 94%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;

    @media screen and (max-width:700px){
        width: 88%;
    }
`;

export const HoverCard = styled.div`
    width: 455px;
    height: 403px;
    background: #FFFFFF;
    box-shadow: 4px -4px 47px rgba(217, 217, 217, 0.15);
    border-radius: 4px;
    position: relative;
    margin: 30px 0 0;
    @media screen and (max-width:700px){
        width: 330px;
        position: absolute;
        left: calc(50% - 165px);
    }
`;

export const ImageContainer = styled.img`
    width: 100%
`;
export const ContentWrapper = styled.div`
    margin: 10px 12px 24px
`;

export const ContentContainer = styled.div`
    position: absolute;
    top: 0;
`;

export const Title = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #2B2B2B;
    // margin: 10px 12px 0;
    display: flex;
`;

export const Content = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2B2B2B;
    // margin: 4px 12px 24px
`;

export const PointerWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const Pointer = styled.div`
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 4px -4px 47px rgb(217 217 217 / 15%);
    border-radius: 4px;
    transform: rotate(45deg);
    position: absolute;
    top: -20px;
    @media screen and (max-width:700px){
        display: none;
    }
`;
