import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { youtube, youtubeEvent } from '../../HealthNetwork/BlogList/youtubeList';
import Events from './Events';
import EventsMobile from './EventsMobile';

const dataObj = (index, link) => ({
	idx: index,
	link: link,
	time: '2023',
	event: 'Informative video on Health',
	location: 'Colambia  University',
	host: 'Host: Dr.Sandeep & Dr. Amit Sharma',
})

const eventsArray = youtubeEvent
const videoArray = youtube?.map((item, index) => dataObj(index, item))

const data = {
	Offline:
	{
	  title: 'ONLINE EVENTS',
	//   title: 'INFORMATIVE VIDEOS',
	  questions: eventsArray
	},
	Online:
	{
	  title: 'INFORMATIVE VIDEOS',
		// title: 'ONLINE EVENTS',
	  questions: videoArray?.map((item) => ({...item, online: true, time: '2023'}))
	}
  }

const EventPage = () => {
	const aboutDevices = data.Offline.title;
	const [initial, changeInitial] = React.useState(aboutDevices);
	const current = aboutDevices === initial ? 'Offline' : 'Online';

	const [collapsibleData, setCollapsibleData] = React.useState(data[current]?.questions);
	useEffect(() =>{
		setCollapsibleData(data[current]?.questions)
	},[current])

	const isMobile = useMediaQuery({
		query: '(min-width: 700px)'
	  });
	return !isMobile ? <EventsMobile isMobile={!isMobile} initial={initial} collapsibleData={collapsibleData} changeInitial={changeInitial}/> : <Events initial={initial} collapsibleData={collapsibleData} changeInitial={changeInitial}/>
}

export default EventPage