import React from 'react';

import Breadcrumb from '../../components/Breadcrumb';
import CardComponent from '../../components/Card';

function PatientQuestionsLand() {
	return (
		<div>

			<Breadcrumb
				backRoute={[{id: 1, route: 'Home'}]}
				currentRoute='Communication Made Simple'
				title='Communication Made Simple' />

			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-lg-8">
							<CardComponent  className="p-4 mb-4">
								<div className="widget about-widget">
									<h4>Communication Made Simple</h4>
									<p>You can communicate with your patients with just a simple click. Tell them about what’s new and keep them updated with the events relevant to them. Save your staff time using the mobile app; maybe you are at a new location or gone off for a holiday. Your patients are well informed always about the status using notifications. 
									</p>
									<br />
									<p>Group the patients according to their similarities in treatment plans, health conditions, or their location. It becomes easier to communicate with them using customized SMS or email notifications. Further, it eliminates the need for bulk notifications, which can become a hassle sometimes. The Virtual Practice app is accessible for the patients and your practice sessions by keeping in touch with each other. . 
									</p>
									<br />
								</div>
							</CardComponent>
						</div>

						<div className="col-lg-4 features-img mt-1">
							<img src="/assets/img/land3.png" className="img-fluid" alt="Feature" />
						</div>

					</div>
				</div>
			</div>

		</div>
	)
}

export default PatientQuestionsLand
