import React from 'react';
import { Link } from 'react-router-dom';

import {
	StyledContainer,
	StyledHeading,
	StyledPatient,
	StyledPatientSearch,
	StyledAddPatient
} from './styles';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getSubAdminAPI,
	getPatientInfo,
	getWardRoomAPI,
	getWardRoomPatients,
} from '../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import CardComponent from '../../../../components/Card'
import TableComponent from '../../../../components/Table';
import InputField from '../../../../components/InputField';
import Pagination from '../../../../components/Pagination';
import Search from '../../../../components/Search';
import InsideMenuBar from '../../../../components/InsideMenus';

const tableHeading = [
	{ id: 1, title: 'Room no.(Type)' },
	{ id: 2, title: 'Patient name' },
	{ id: 3, title: 'Allotment date' },
	{ id: 4, title: 'Discharge date' },
	{ id: 5, title: 'Doctor Name' },
	{ id: 6, title: 'Amount Paid' }
];

const options = [
	{ id: 0, label: 'Id', value: 'id' },
	{ id: 1, label: 'Name', value: 'name' },
	{ id: 2, label: 'Email', value: 'email' },
	{ id: 3, label: 'Status', value: 'status' },
	{ id: 4, label: 'Contact Number', value: 'contact' }
]

function HospitalRooms(props) {
	const [state, dispatch] = React.useContext(Context);
	const [currentTab, SetCurrentTab] = React.useState(1);
	const [wardRoomList,setWardRoomList] = React.useState([]);
	const [patients,setpatients] = React.useState([]);


	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('id');

	React.useEffect(() => {
		
		loader(true);
		getWardRoomAPI().then(res=>{
			setWardRoomList(res.data)
			loader(false);
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
		})
		getWardRoomPatients().then(res => {
			setpatients(res.data);
			loader(false)
		}).catch(err => {
			loader(false)
			toast.error(err.response.data.error);
		})
	}, [])

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}


	const [patientPaginate, setPatientPaginate] = React.useState([]);
	const pageChangePatient = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setPatientPaginate(data);
	}

	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">

						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-2">
								<div className='col-md-2 mt-2 ml-auto'>
									<StyledPatient>
										<Link to={'/organization/rooms/add'} style={{ width: '100%' }}>
											<StyledAddPatient>
												Add a room
											</StyledAddPatient>
										</Link>
									</StyledPatient>
								</div>
								<div>
								<div className="d-flex mb-4">
								<button onClick={() => SetCurrentTab(1)} className={currentTab === 1 ? 'doctor-active-btn' : "doctor-btn-outline"}>
									Room View
								</button>

								<button onClick={() => SetCurrentTab(2)} className={currentTab === 2 ? 'doctor-active-btn' : "doctor-btn-outline"}>
									List View
								</button>
							</div>
									{
										currentTab === 1 &&
										<div>
											<div className='d-flex row p-3' style={{ textAlign: 'center' }}>
												<div className="col-md-2" style={{}}>
													<i style={{ color: "#5fef5496" }} class="fas fa-square"></i>  Available
												</div>
												<div className="col-md-2">
													<i style={{ color: "#ff97b891" }} class="fas fa-square"></i>  Occupied
												</div>
												<div className="col-md-2">
													<i style={{ color: "#5c4c4cb3" }} class="fas fa-square"></i>  Not in service
												</div>
											</div>
											<div className="d-flex row mt-2">
												{
													wardRoomList.map((data, id) =>
														<div className="col-md-3 col room-view mt-2">
															<i className={`fas fa-clinic-medical room-view-icon room-view-status-${data.status}`}></i>
															<p>Room {data.roomNo}</p>
														</div>
													)
												}
											</div>
										</div>
									}
									{
										currentTab === 2 &&
										<div>
											<TableComponent tableHeading={tableHeading}>
												{
													patientPaginate && patientPaginate.map(item => {
														return <tr key={item._id}>
															<td>
																<h2 className="table-avatar">
																	<a>{item.roomWard ? item.roomWard.roomNo : ''} {item.roomWard.roomType ? `(${item.roomWard.roomType})` : ''}</a>
																</h2>
															</td>
															<td>{item.patient ? item.patient?.name : '-'} </td>
															<td>{item.admissionDateTime ? moment(item.admissionDateTime).format("Do MMM, YYYY") : '-'} </td>
															<td>{item.dischargeDate ? moment(item.dischargeDate).format("Do MMM, YYYY") : '-'}  </td>
															<td> {item.doctor ? item.doctor.name : '-'} </td>
															<td> {item.totalPayableAmount ? item.totalPayableAmount : '-'} </td>

														</tr>
													})
												}

											</TableComponent>

										</div>
									}
								</div>


								{ currentTab === 2 && patients.length > 0 && <Pagination items={patients} onChangePage={pageChangePatient} />}

							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default HospitalRooms
