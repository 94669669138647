import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { StyledTableCell, StyledTableContainer, StyledTableRow, StryledGridSection, StyledNoRecordSpan, StyledDiv, StyleButtonForMoreRecord } from './MedicalHistory';

export default function HealthParameters({data}) {

    const [bloodPressure, setbloodPressure] = useState(1);
    const [bloodSugar, setbloodSugar] = useState(1);
    const [spo2, setspo2] = useState(1);
    const [bodyTemperature, setbodyTemperature] = useState(1);
    const [heartRate, setheartRate] = useState(1);
    const [respiratoryRate, setrespiratoryRate] = useState(1);
    const blood_sugar_reading = (num) => {

        switch(num){
            case 1 : return "Fasting"
            case 2 : return "2 hours post breakfast"
            case 3 : return "Pre lunch"
            case 4 : return "2 hours post lunch"
            case 5 : return "Pre runner"
            case 6 : return "2 hours post dinner"
            case 7 : return "Bed time"
            case 8 : return "3 am"
            case 9 : return "Random"       
        }
    }

    return (
        <>
        {Object.keys(data).length === 0 ? <StyledDiv>No Health data requested</StyledDiv> :
        <Grid container >
                { data?.blood_pressure === false ? '' :
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Blood Pressure</StryledGridSection>
                        <Grid item xs={10}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row" align="center" >Date</StyledTableCell>
                                            <StyledTableCell component="td" scope="row"  align="center" >Systolic Blood Pressure</StyledTableCell>
                                            <StyledTableCell component="td" scope="row"  align="center" >Diastolic Blood Pressure</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                       { data?.blood_pressure.slice(0, bloodPressure ? 3 : data?.blood_pressure.length).map((row, index) => <StyledTableRow  >
                                                <StyledTableCell component="td" scope="row"  align="center"  >{moment(row.createdAt).format('YYYY-MMM-DD')}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row"  align="center"  >{row.dia_blood_pressure} mmHg</StyledTableCell>
                                                <StyledTableCell component="td" scope="row"  align="center" >{row.sys_blood_pressure} mmHg</StyledTableCell>
                                            </StyledTableRow>)}   
                                    </TableBody>
                                </Table>
                                
                                { data?.blood_pressure.length === 0 ? <StyledNoRecordSpan>No data</StyledNoRecordSpan> : 
                                 data?.blood_pressure.length > 3 && <StyleButtonForMoreRecord onClick={()=> setbloodPressure(!bloodPressure)}>{!bloodPressure ? 'Less' : 'More'}</StyleButtonForMoreRecord> }
                            </StyledTableContainer>
                        </Grid>
                        
                    </Grid>
                </Grid> }

                { data?.blood_sugar === false ? '' :
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Blood Sugar</StryledGridSection>
                        <Grid item xs={10}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row"  align="center" >Date</StyledTableCell>
                                            <StyledTableCell component="td" scope="row"  align="center" >Blood Sugar</StyledTableCell>
                                            <StyledTableCell component='td' scope='row'  align="center" >Reading Type</StyledTableCell>
            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.blood_sugar.slice(0, !bloodSugar ? data?.blood_sugar.length : 3).map((row, index)=> <StyledTableRow key={index} >
                                                <StyledTableCell component="td" scope="row"  align="center" >{moment(row.createdAt).format('YYYY-MMM-DD')}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row"  align="center" >{row.blood_sugar} mg/dL</StyledTableCell>
                                                <StyledTableCell component='td' scope='row'  align="center" >{blood_sugar_reading(row.reading_method)}</StyledTableCell>
                                            </StyledTableRow> )}
                                            
                                         
                                    </TableBody>
                                </Table>
                                { data?.blood_sugar.length === 0 ? <StyledNoRecordSpan>No data</StyledNoRecordSpan> : 
                                 data?.blood_sugar.length > 3 && <StyleButtonForMoreRecord onClick={()=> setbloodSugar(!bloodSugar)}>{!bloodSugar ? 'Less' : 'More'}</StyleButtonForMoreRecord> }
                            </StyledTableContainer>
                        </Grid>
                        
                    </Grid>
                </Grid> }

                { data?.spo2 === false ? '' :
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Blood Oxygen</StryledGridSection>
                        <Grid item xs={10}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                         <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row"  align="center" >Date</StyledTableCell>
                                            <StyledTableCell component="td" scope="row"  align="center"  > Blood Oxygen percentage</StyledTableCell>
                                            <StyledTableCell component="td" scope="row"  align="center" > Reading Type</StyledTableCell>
                                        </TableRow> 
                                        
                                    </TableHead>
                                    <TableBody>
                                    {data?.spo2.slice(0, !spo2 ? data?.spo2.length : 3).map((row, index) => <StyledTableRow key={index}>
                                                <StyledTableCell component="td" scope="row"  align="center" >{moment(row.createdAt).format('YYYY-MMM-DD')}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row"  align="center" >{row.percentage} %</StyledTableCell>
                                                <StyledTableCell component="td" scope="row"  align="center" >{row.reading_method === 1 ? "Pulse oximeter" : "Artial blood gas"}</StyledTableCell>
                                            </StyledTableRow> )}
                                    </TableBody> 
                                </Table> 
                                { data?.spo2.length === 0 ? <StyledNoRecordSpan>No data</StyledNoRecordSpan> : 
                                 data?.spo2.length > 3 && <StyleButtonForMoreRecord onClick={()=> setspo2(!spo2)}>{!spo2 ? 'Less' : 'More'}</StyleButtonForMoreRecord> }{ data?.spo2.length === 0 ? <StyledNoRecordSpan>No data</StyledNoRecordSpan> : ''}
                            </StyledTableContainer>
                        </Grid>
                        
                    </Grid>
                </Grid>  }

                { data?.body_temperature === false ? '' :
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Body Temperature</StryledGridSection>
                        <Grid item xs={10}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}> 
                                            <StyledTableCell component="td" scope="row"  align="center" >Date</StyledTableCell>
                                            <StyledTableCell component="td" scope="row"  align="center" >Body Temperature</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                       { data?.body_temperature.slice(0, !bodyTemperature ? data?.body_temperature.length : 3).map((row,index)=> <StyledTableRow key={index}>
                                                <StyledTableCell component="td" scope="row"  align="center" >{moment(row.createdAt).format('YYYY-MMM-DD')}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row"  align="center" >{row.temperature} °{row.temperature_unit === 1 ? "C": "F"}</StyledTableCell>
                                            </StyledTableRow> )}   
                                    </TableBody>
                                </Table>
                                { data?.body_temperature.length === 0 ? <StyledNoRecordSpan>No data</StyledNoRecordSpan> : 
                                 data?.body_temperature.length > 3 && <StyleButtonForMoreRecord onClick={()=> setbodyTemperature(!bodyTemperature)}>{!bodyTemperature ? 'Less' : 'More'}</StyleButtonForMoreRecord> }
                            </StyledTableContainer>
                        </Grid>
                        
                    </Grid>
                </Grid> }

                { data?.heart_rate === false ? '' :
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Heart Rate</StryledGridSection>
                        <Grid item xs={10}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row"  align="center" >Date</StyledTableCell>
                                            <StyledTableCell component="td" scope="row"  align="center" >Pulse per min</StyledTableCell>
                                            <StyledTableCell component="td" scope="row"  align="center" >Body Part</StyledTableCell>
            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { data?.heart_rate.slice(0, !heartRate ? data?.heart_rate.length : 3).map((row,index)=> <StyledTableRow key={index}>
                                                <StyledTableCell component="td" scope="row"  align="center" >{moment(row.createdAt).format('YYYY-MMM-DD')}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row"  align="center"  >{row.pulse} BPM</StyledTableCell>
                                                <StyledTableCell component="td" scope="row"  align="center"  >{row.location ===1 ? "Wrist" : "Neck"}</StyledTableCell>
                                            </StyledTableRow>)}
                                            
                                         
                                    </TableBody>
                                </Table>
                                { data?.heart_rate.length === 0 ? <StyledNoRecordSpan>No data</StyledNoRecordSpan> : 
                                 data?.heart_rate.length > 3 && <StyleButtonForMoreRecord onClick={()=> setheartRate(!heartRate)}>{!heartRate ? 'Less' : 'More'}</StyleButtonForMoreRecord> }
                            </StyledTableContainer>
                        </Grid>
                        
                    </Grid>
                </Grid> }

                { data?.respiratory_rate === false ? '' :
                <Grid item xs={12} paddingTop={'24px'}>
                    <Grid container paddingLeft={'8px'} spacing={2} justifyContent={'center'}>
                        <StryledGridSection item xs={12}>Respiratory rate</StryledGridSection>
                        <Grid item xs={10}>
                            <StyledTableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow component={"th"}>
                                            <StyledTableCell component="td" scope="row"  align="center" >Date</StyledTableCell>
                                            <StyledTableCell component="td" scope="row"  align="center" >Respiratory rate</StyledTableCell>
            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                       {data?.respiratory_rate.slice(0, !respiratoryRate ? data?.respiratory_rate.length : 3).map((row, index)=> <StyledTableRow key={index} >
                                                <StyledTableCell component="td" scope="row"  align="center" >{moment(row.createdAt).format('YYYY-MMM-DD')}</StyledTableCell>
                                                <StyledTableCell component="td" scope="row"  align="center" >{row.respiratory_rates} breaths/min</StyledTableCell>
                                            </StyledTableRow>)}             
                                    </TableBody>
                                </Table>
                                { data?.respiratory_rate.length === 0 ? <StyledNoRecordSpan>No data</StyledNoRecordSpan> : 
                                 data?.respiratory_rate.length > 3 && <StyleButtonForMoreRecord onClick={()=> setrespiratoryRate(!respiratoryRate)}>{!respiratoryRate ? 'Less' : 'More'}</StyleButtonForMoreRecord> }
                            </StyledTableContainer>
                        </Grid>
                        
                    </Grid>
                </Grid>   }
            </Grid> }
            
        
        </>
    )
}

