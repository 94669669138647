import { apiPost, apiGet, apiPut, apiDelete } from "../utils";

export function postLoginAPI(data) {
	return apiPost(`/api/doctor/login`, data);
}

export function doctorLoginAPI(data) {
	return apiPost(`/api/doctor/login`, data);
}

export function postRegisterAPI(data) {
	return apiPost('/api/doctor/signup', data);
}

export function forgotPasswordAPI(data) {
	return apiPost('/api/doctor/forgotpassword', data);
}

export function addSlotAPI(data) {
	return apiPost(`/api/doctor/add/slots`, data);
}

export function getSlotAPI() {
	return apiGet(`/api/doctor/get/slots`);
}


export function getDashboardAppointmentAPI(query) {
	return apiGet(`/api/organization/doctor-get-dashboard-appointments`, query);
}

export function getAppointments(query) {
	return apiGet(`/api/organization/doctor-get-appointments`, query);
}

export function getAppointmentDetailsAPI(id) {
	return apiGet(`/api/organization/doctor-booked-appointment-detail/${id}`);
}

export function getMedicalHistoryDataAPI(id) {
	return apiGet(`/api/organization/doctor-patient-medical-history/${id}`);
}

export function getAllPatient(query) {
	return apiGet(`/api/organization/search-doctor-patient-by-doctor`, query);
}

export function getAllAppointmentAPI(id) {
	return apiGet(`/api/organization/doctor-all-patient-app/${id}`);
}

export function getAllCompletedApp() {
	return apiGet(`/api/organization/doctor-all-completed-appointments`);
}

export function updateAppointmentStatusAPI(id, body) {
	return apiPut(`/api/organization/doctor-all-completed-appointments/${id}`, body);
}

export function getAllInvoices(query) {
	return apiGet(`/api/organization/doctor-invoices`, query);
}

export function getDoctorInfoAPI() {
	return apiGet(`/api/doctor/doctor-info`);
}

export function updateDoctorInfoAPI(id, data) {
	return apiPut(`/api/doctor/update-doctor-info/${id}`, data);
}

export function resetPasswordDoctorAPI(payload) {
	return apiPut("/api/doctor/resetpassword", payload);
}

export function updateDoctorLogo(id, data) {
	return apiPut(`/api/doctor/update-doctor/${id}`, data);
}

export function sendReportAPI(id, report) {
	return apiPost(`/api/organization/doctor_send_final_report/${id}`, report);
}

export function deleteSlotAPI(id,) {
	return apiDelete(`/api/organization/doctor/remove/slot/${id}`);
}
export function getWardRoomPatients () {
	return apiGet(`/api/doctor/get/admit-patient-ward-rooms`)
}
export function getWardRoomCurrentPatients (query) {
	return apiGet(`/api/doctor/get/admin-patient-current-ward`, query)
}
export function getPatientWardRoomById(id) {
	return apiGet(`/api/doctor/get/admit-patient-ward-rooms-by-Id/${id}`)
}



// Heath parameters
export function getSubBPAPI(type,id) {
	return apiGet(`/api/heath_parameter/${type}/${id}`);
}

export function getSubBPAPIByFilter(type,id,query) {
	return apiGet(`/api/heath_parameter/${type}/${id}?start=${query.start}&end=${query.end}`);
}

export function addSubBPAPI(data, id, type) {
	return apiPost(`/api/heath_parameter/${type}/${id}`, data);
}
export function getBPAPI(id) {
	return apiGet(`/api/heath_parameter/all_bp_detail/${id}`, );
}
export function getBSAPI(id) {
	return apiGet(`/api/heath_parameter/all_Bsugar_detail/${id}`, );
}
export function getOxyAPI(id) {
	return apiGet(`/api/heath_parameter/all_OxygenLevel_detail/${id}`, );
}
export function getPluseAPI(id) {
	return apiGet(`/api/heath_parameter/all_Pluse_detail/${id}`, );
}
export function getRespiratoryAPI(id) {
	return apiGet(`/api/heath_parameter/all_RespiratoryRates_detail/${id}`, );
}
export function getTemperatureAPI(id) {
	return apiGet(`/api/heath_parameter/all_Temperature_detail/${id}`, );
}
export function getCholesterolAPI(id) {
	return apiGet(`/api/heath_parameter/all_Cholesterol_detail/${id}`, );
}
export function getJointAPI(id) {
	return apiGet(`/api/heath_parameter/all_Joint_detail/${id}`, );
}
export function getPainAPI(id) {
	return apiGet(`/api/heath_parameter/all_Pain_detail/${id}`, );
}
export function getExerciseAPI(id) {
	return apiGet(`/api/heath_parameter/all_Exercise_detail/${id}`, );
}
export function getFoodAPI(id) {
	return apiGet(`/api/heath_parameter/all_Food_detail/${id}`, );
}
export function getHeightAPI(id) {
	return apiGet(`/api/heath_parameter/allgetBMI/${id}`, );
}
export function getWaistAPI(id) {
	return apiGet(`/api/heath_parameter/all_Waist_detail/${id}`, );
}
export function getWeightAPI(id) {
	return apiGet(`/api/heath_parameter/all_Weight_detail/${id}`, );
}


export function addPatientAPI(doctorId, payload) {
	return apiPost(`/api/organization/add-patient-by-doctor/${doctorId}`, payload);
}

export function getDoctorSlotsListingAPI(id) {
	return apiGet(`/api/organization/patient-doctor-slots/${id}`);
}

export function bookAppointmentForPatientAPI(doctorId, payload) {
	return apiPost(`/api/organization/book-appointment-for-patient-by-doctor/${doctorId}`, payload);
}

export function genrateFIleLinkAPI(file) {
	return apiPost(`/api/referral-file-link`, file);
}

export function updateCashPaymentAPI(id, data) {
	return apiPut(`/api/organization/update-appointment-cashpayment-status/${id}`, data);
}

export function addPaymentMethod(data) {
	return apiPost(`/api/doctor/add/payment-method`, data);
}
export function getAllPaymentMethod() {
	return apiGet(`/api/doctor/get/payment-methods`);
}
export function removePaymentMethod(id) {
	return apiDelete(`/api/doctor/remove/payment-method/${id}`);
}


// Send sms
export function sendSmsAPI(payload) {
	return apiPost(`/api/organization/send-sms-by-phone`, payload)
}