import React from 'react';

import Breadcrumb from '../../components/Breadcrumb';
import CardComponent from '../../components/Card';

function AppointmentLand() {
	return (
		<div>
			<Breadcrumb
				backRoute={[{id: 1, route: 'Home'}]}
				currentRoute='Manage Your Appointments'
				title='Manage Your Appointments on the Go' />

			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-8">
							<CardComponent  className="p-4 mb-4">
								<div className="widget about-widget">
									<h4>Manage Your Appointments on the Go</h4>
									<p>With the appointments, you can streamline consultation on the go, whether online or in-clinic. We help in taking care of your busy schedule and can manage the time accordingly. Avail the benefits of scheduling appointments for staff and booking for patients that notifies you with status updates. Further, it helps in preventing no-shows and saves your practice losses. </p>
									<br />
									<p>Take advantage of managing all your appointments on your Virtual practice – a useful tool that helps schedule appointments based on your convenience. Using the virtual practice tool, you can receive timely reminders of the scheduled meetings in advance. It helps in planning your day efficiently. To allow the patients to schedule appointments with you, you can easily publish your consultation details using the Virtual Practice tool with convenience.</p>
									<br />
									<p>
									Virtual Practice gives the best solution for your staff members. They can use the web or mobile app to manage appointments, confirm, or reschedule them as per requests received. Either the requests are made using the phone or during an in-clinic consultation session, which is further noted using the calendar. 
									</p>
									<br />
									<p>
									When a request is received, you will get alerts using the Virtual Practice tool. It also shows a daily summary of the appointments added in a day. The same is sent to your email or as a text message to notify you. On the other end, the patients will also receive reminders about the scheduled appointments. Secondly, using the Virtual Practice, the patients can easily book appointments using their Smartphone app. 
									</p>
								</div>
							</CardComponent>
						</div>

						<div className="col-lg-4 features-img mt-5 pt-5">
							<img src="/assets/img/land1.png" className="img-fluid" alt="Feature" />
						</div>

					</div>
				</div>
			</div>

		</div>
	)
}

export default AppointmentLand
