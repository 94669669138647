import React, { useContext } from 'react';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	postNLPAPI
} from '../../../api/website';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';

import SliderComponent from '../components/Silder';
import InputField from '../../../components/InputField';
import SelectField from '../../../components/SelectField';
import Card from '../../../components/Card';
import PricingComponent from '../components/Pricing';

import Testimonial from '../components/Testimonial';

import PhoneInput from "react-phone-number-input";

import { globalValidator } from "../../../utils";

import {
	StyledYoga,
	StyledButton,
	StyledCardButton,
	StyledCardWrapper,
	StyledCardIconWrapper,
	StyledCardIcon,
	StyledCardTitle,
	StyledCardSubTitle,
	StyledSubTitle
} from './styles';

import {Helmet} from "react-helmet";

function NLP() {
	const [state, dispatch] = useContext(Context);

	const [nlp, setNlp] = React.useState({
		name: '',
		email: '',
		dob: new Date(),
		gender: 'Male',
		phone: '',
		message: ''
	})

	const submit = () => {
		const keys = [
      { name: "name", title: "Name", validateKey: "required" },
			{ name: "email", title: "Email", validateKey: "email" },
			{
        name: "message",
        title: "Message",
        validateKey: "required"
      },
    ];

    let valid = globalValidator(nlp, keys);

		if (valid === true) {
			loader(true);
			postNLPAPI(nlp).then(res => {
				loader(false);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				setNlp({
					name: '',
					email: '',
					dob: new Date(),
					gender: 'Male',
					phone: '',
					message: ''
				})
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const whyUs = [
		{id: 1, title: 'Each individual creates an internal map of the world around them. This information is perceived by the brain through the senses. Each individual map is unique because people tend to place different level of importance on different senses and information. For instance, some individuals may rely more on their visual perceptions while others are more attentive to the sounds around them.'},
		{id: 2, title: 'The system used by a person to process the world around them is ‘Primary Representational System’. NLP techniques work on the premise that PRS can be used to alter a person’s internal map.', },
		{id: 3, title: 'By using NLP techniques, it is possible to improve a person’s understanding of their behavioural patterns and build a more effective line of communication between the conscious and unconscious processes that occur in our minds. We may consciously know how to respond to certain stimuli, but if our unconscious processes take over, we have little control over the result. NLP is a way to circumvent that and make us feel more in control.', }
	];

	const data = [
		{
			id: 1, 
			name: 'Debra S',
			msg: 'NLP is a powerful tool. I felt empowered to realise that I am in charge of my thoughts, beliefs, inner dialogues and actions and that has made a big difference to me'
		},
		{
			id: 1, 
			name: 'Shyam G',
			msg: 'Well! NLP changed my life. Initially, it was just curiosity. A friend told me that NLP was one of the things that had stuck with them, so I thought I did give it a go. My family relationships improved; I changed my eating habits and lost weight, I started public speaking and changed my career'
		},
		{
			id: 1, 
			name: 'Shalini M',
			msg: 'Having made big changes in my own life simply through raising my awareness of how my language patterns and thoughts impacted on my life experiences, I wanted others to be able to do this too....and that is why I now run the Association for NLP'
		}
	]

	return (
		<StyledYoga>
			<Helmet title = {'Book appointment for neuro linguistic programming. NLP helps to increase self confidence neuro linguistic programming.'}
				htmlAttributes={{ lang: "en" }}
				meta={[
					{
						name: `description`,
						content:"Book online appointment for neuro linguistic programming. NPL is best way to improve person's self confidence.",
					},
					{
						name: `keywords`,
						content:'online neuro linguistic programming appointment',
					},
					{
						property: "url",
						content: 'https://emed4u.com/neuro-linguistic-programming'
					},
				
     		 	]}
   			/>
			<SliderComponent 
				className="bd-home-slider bg-img-001 " 
				yoga={false} 
				title="Neuro Linguistic Programming"
				subTitle="Because your mind deserves good health!"
				description="Neuro Linguistic Programming (NLP) empowers, enables and teaches us to better understand the way our brain (neuro) processes the words we use (linguistic) and how that can impact on our past, present and future (programming). It gives us strategies for observing human behaviour and learning from the best and worst experiences.  NLP is a set of skills that reveal the kind of communication that matters most – on the inside and outside."
				heading="All you need is a desire to change and a willingness to learn new ways of being...with yourself, your thoughts and with others”."
				/>	

			


			<section className="section mt-5 section-specialities">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-9 m-auto">
              <div className="section-header text-center">
                <h2>How does NLP work?</h2>
              </div>
						</div>
					</div>
					<div className="row">
						{
							whyUs.map(tool => {
								return <div className="col-lg-12 mb-3" key={tool.id}>
												{/* <Card className="text-center"> */}
													<StyledCardWrapper>
														{/* <StyledCardIconWrapper>
															<StyledCardIcon src={tool.icon} />
														</StyledCardIconWrapper> */}
														<StyledCardTitle>
														<img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" />		{tool.title}
														</StyledCardTitle>
														{/* <StyledCardSubTitle>
															{tool.description}
														</StyledCardSubTitle> <br />
														 */}
													</StyledCardWrapper>
												{/* </Card> */}
											</div>
							})
						}
					</div>
				</div>
			</section>

			<section className="">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-6 col-xs-12 nlp-benifits">
							<div className="section-header text-center">
                <h2>Benefits</h2>
              </div>
							<h3>NLP is a very powerful tool to improve interpersonal skills or Business skills and designing the life the way you want. </h3>
							<h4>It enhances:</h4>	
							<ul>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Overcoming challenges like: Anger, Phobia, Fear, Frustration, Negative Behaviour, Depression, Addictions etc.</li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Self-confidence and motivation.</li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Gaining control of life.</li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Clarity to move forward in life.</li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Setting powerful beliefs.</li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Relationship with partner & family.</li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Resolving conflicts in personal & professional life.</li>
								<li><img src="https://www.brightful.me/assets/play/img/green-check.svg" className="mr-3" /> Public speaking and leadership skills.</li>
							</ul>
						</div>

						<div className="col-md-6 col-xs-12 req-app-ce">
							<div className="section-header text-center">
								<h2>Request Appointment</h2>
							</div>
							<div className="row">
								<div className="col-md-6 col-sm-12">
									<div className="form-group card-label">
										<label>Name</label>
										<InputField value={nlp.name} onChange={e => setNlp((user) => ({...user, name: e.target.value}))} />
									</div>
								</div>
								<div className="col-md-6 col-sm-12">
									<div className="form-group card-label">
										<label>Date of birth</label>
										<InputField 
											type="date" 
											value={nlp.dob} 
											onChange={e => setNlp((user) => ({...user, dob: e.target.value}))}
										/>

									</div>
								</div>
								<div className="col-md-6 col-sm-12">
									<div className="form-group card-label">
										<label>Gender</label>
										<SelectField value={nlp.gender} onChange={e => setNlp((user) => ({...user, gender: e.target.value}))} options={[{id: 1, label: 'Male', value: 'male'}, {id: 2, label: 'Female', value: 'female'}, {id: 3, label: 'Others', value: 'other'}]} />
									</div>
								</div>

								<div className="col-md-6 col-sm-12">
									<div className="form-group card-label">
										<label>Email</label>
										<InputField value={nlp.email} onChange={e => setNlp((user) => ({...user, email: e.target.value}))} type="email" />
									</div>
								</div>

								<div className="col-md-12 col-sm-12">
									<div className="form-group card-label">
										<label>Contact number</label>
											<PhoneInput
												placeholder="Enter phone number"
												international
												defaultCountry="IN"
												value={nlp.phone}
												onChange={e => setNlp((user) => ({...user, phone: e}))}
											/>
									</div>
								</div>

							</div>
							<div className="row">
								<div className="col-md-12 col-sm-12">
									<div className="form-group card-label" style={{display:'grid'}}>
										<label>Message</label>
										<textarea style={{height: '145px'}} value={nlp.message} onChange={e => setNlp((user) => ({...user, message: e.target.value}))}></textarea>
									</div>
								</div>
							</div>
							<StyledButton onClick={() => submit()}>
								<StyledCardButton>
									Make Appointment     
									<i className="fa fa-chevron-right" />
								</StyledCardButton>
							</StyledButton>
						</div>

					</div>
				</div>
			</section>

			<section className="section pt-4">
      <div className="container-fluid">
        <div className="section-header text-center mt-2">
          <h2>Testimonials</h2>
          {/* <h2>Our Happy Clients Says About Us</h2> */}
        </div> 
        <div className="row">
          <Testimonial data={data} />
        </div>
      </div>
    </section>

			

			{/* <section className="section section-specialities">
				<div className="container">
					<div className="section-header text-center">
						<StyledSubTitle>Pricing</StyledSubTitle>
						<h2>Lorem Ipsum is simply dummy text</h2>
					</div> 
					<div className="row m-auto">
						<div className="col-md-8 m-auto">
							<PricingComponent />
							<PricingComponent />
						</div>
						
					</div>
				</div>
			</section> */}
		</StyledYoga>
	)
}

export default NLP
