import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { organizationProfileAction, updateOrganizationProfileAction } from "../../../../redux/user/organization/organizationAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { profileStyles } from "../../../../assets/styles/DoctorProfileSettingStyles";
import { useStyles } from "../../../../assets/styles/PatientDashboardStyles";
import { commonMuiStyles } from "../../../../assets/styles/CommonMUIStyles";
import { StyledCrossIcon, StyledHeadingText01, UserImage } from "../../../../assets/styles/CommonStyles";
import defaultUserPng from "../../../../assets/img/user.png";
import UploadFile from "../../../controls/UploadFile";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import TabComponent from "../../../Tab";
import OrgProfile from "../../../OrgProfile";
import ResetPassword from "../../../ResetPassword";
import * as mainService from "../../../../services/mainService";
import { toast } from "react-toastify";
import { ORGANIZATION_UPLOAD } from "../../../../services/apiUrl";
import strings from "../../../../constant/strings";

const OrganizationProfileSetting = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styleClasses = commonMuiStyles();
  const profileSettingClasses = profileStyles();
  const history = useHistory();
  const inputRefs = useRef();
  const [image, setImage] = useState();
  const [fileType, setFileType] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [onLoading, setLoading] = useState(false);
  const { orgProfileData, userData } = useSelector((state) => ({
    orgProfileData: state.organization.orgProfileData,
    userData: state.auth.userData,
  }));

  const tabs = [
    { id: 0, name: "Organization details" },
    { id: 1, name: "Reset password" },
  ];

  const url = ORGANIZATION_UPLOAD;

  useEffect(() => {
    dispatch(organizationProfileAction(userData.token));
  }, [dispatch, userData.token]);

  const handleCross = () => {
    history.goBack();
  };

  const handleTab = (e, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (image) {
      handleSubmit();
    }
  }, [fileType]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("file", image);

    try {
      const response = await mainService.fileUpload(url, formData);
      const uploadedImage = response?.response?.data;

      if (uploadedImage) {
        handleSubmitUpdate(uploadedImage);
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const handleSubmitUpdate = async (uploadedImage) => {
    setLoading(true);
    const profileImg = { logo: uploadedImage };

    try {
      const res = await mainService.updateProfileByOrganizationAPI({
        token: userData.token,
        data: profileImg,
      });

      if (res.response_type === "fail") {
        toast.error(res?.response?.message, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 2000,
          style: { width: "auto", borderRadius: 8 },
        });
      } else {

        toast.success("Profile updated successfully.", {
          position: "bottom-center",
          closeButton: false,
          autoClose: 2000,
          style: { width: "auto", borderRadius: 8 },
        });
        window.location.reload();
        // Reload the profile data
        dispatch(organizationProfileAction(userData.token));
        
      
      }
    } catch (error) {
      console.error("Error during profile update:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        className={`${classes.mainCont} ${profileSettingClasses.mainSecCont}`}
      >
        <StyledHeadingText01>{strings.orgProfile}</StyledHeadingText01>
        <StyledCrossIcon
          src="/assets/img/x.png"
          alt="close"
          onClick={handleCross}
        />
      </Grid>
      <Grid item xs={12} className={profileSettingClasses.imageCont}>
        <div>
          <IconButton
            sx={{
              position: "absolute",
              right: "calc(50% - 55px)",
            }}
            onClick={() => inputRefs.current.click()}
          >
            <BorderColorIcon />
          </IconButton>

          <UploadFile
            inputRefs={inputRefs}
            image={image}
            setImage={setImage}
            fileType={fileType}
            setFileType={setFileType}
            limit={5}
            navigate="single"
          />

          <UserImage
            src={image || orgProfileData.logo || defaultUserPng}
            className={profileSettingClasses.imageStyle}
            alt="organization logo"
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TabComponent
          tabs={tabs}
          tabValue={tabValue}
          setTabValue={setTabValue}
          handleTab={handleTab}
          customTab={styleClasses.customTab}
          customTabIndicator={styleClasses.customTabIndicator}
        />
      </Grid>
      <Grid item xs={12}>
        {tabValue === 0 ? (
          <OrgProfile onLoading={onLoading} setLoading={setLoading} />
        ) : (
          <ResetPassword onLoading={onLoading} setLoading={setLoading} />
        )}
      </Grid>
    </Grid>
  );
};

export default OrganizationProfileSetting;
