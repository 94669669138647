import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #fff;
    padding: 56px 100px 56px;
`;

export const SectionHeading = styled.div``;

export const Heading3 = styled.h3`
    display: flex;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
`;

export const Heading1 = styled.h1`
    display: flex;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    margin: 8px 0 0;
`;

export const H3FAQ = styled(Heading3)`
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2B2B2B;
`;

export const H1Ttitle = styled(Heading1)`
    font-weight: 500;
    font-size: 64px;
    line-height: 94px;
    text-align: center;
    color: #2B2B2B;
    .colored & {
        background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
    }
    @media screen and (min-width:700px) and (max-width:1122px) {
        white-space: nowrap;
        font-size: 3rem;
    }
`;

export const H3SubHeading = styled(Heading1)`
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #5E5E5E;
`;

export const WrapperInput = styled(Heading1)`
    margin: 40px 0 0;
`;

export const SectionFAQ = styled.div`
    margin: 112px 0 0;
`;

export const QuestionariesHeading = styled.div`
    display: flex;
`;

export const QuestionaryTitle = styled.div`
    width: -webkit-fill-available;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2B2B2B;
    cursor: pointer;
    &.colored {
        background: linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
    }
`;

export const TextWrapper = styled.div`
    width: -webkit-fill-available;
    width: -moz-available;
    border-bottom: ${(props) => props.border ? '4px solid transparent' : '1px solid #CCCCCC'};
	background-image: linear-gradient(white, white), linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%);
	background-origin: border-box;
	background-clip: content-box, border-box;
`;

export const Question = styled.div`
    margin: 40px 0 32px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #2B2B2B;
    width: -webkit-fill-available;
    width: -moz-available;
`;

export const Answer = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #5E5E5E;
    margin: 0 0 32px;
    width: calc(100% - 61px);
`;

export const SingleQuesWrapper = styled.div`
    border-bottom: 1px solid #CCCCCC;
`;

export const NoResultText = styled.div`
    margin: 50px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #5E5E5E;
`;


