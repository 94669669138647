import React from 'react';

import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Context } from '../../../../store';
import {
	getAppointmentsOrg
} from '../../../../api/organization';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import {
	StyledContainer,
	StyledHeading,
	StyledPatient,
	StyledPatientSearch,
	StyledAddPatient
} from './styles';

import CardComponent from '../../../../components/Card'
import TableComponent from '../../../../components/Table';
import InputField from '../../../../components/InputField';
import Pagination from '../../../../components/Pagination';
import Search from '../../../../components/Search';
import InsideMenuBar from '../../../../components/InsideMenus';

const tableHeading = [
	{id: 1, title: 'Doctor'},
	{id: 2, title: 'Patient'},
	{id: 3, title: 'Date'},
	{id: 7, title: 'Time'},
	{id: 4, title: 'Booking Date'},
	{id: 5, title: 'Amount'},
	{id: 6, title: 'Status'},
	{id: 8, title: 'Actions'}
];

const options = [
	{ id: 1, label: 'Name', value: 'name' },
	{ id: 4, label: 'Contact Number', value: 'contact' },
	{ id: 2, label: 'Email', value: 'email' },
	{ id: 3, label: 'Status', value: 'status' },
	{ id: 0, label: 'Id', value: 'id' },
]

const statusOptions = [
	{ id: 0, label: 'Pending', value: '0' },
	{ id: 1, label: 'Confirm', value: '1' },
	{ id: 2, label: 'Rejected by doctor', value: '2' },
	{ id: 3, label: 'Rejected by patient', value:'3'}
]
function Appointments(props) {
	const [state, dispatch] = React.useContext(Context);
	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('name');
	const [appointments, setAppointments] = React.useState([]);
	
	const fetchAppointmentsOrg = () => {
		loader(true);
		getAppointmentsOrg().then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setAppointments(res?.data);
		
		}).catch(err => {
			setAppointments([]);
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	React.useEffect(() => {
		fetchAppointmentsOrg();
	}, []);

	const searchPatient = () => {
		loader(true);
		const payload = {
            [selectValue]: search
        }
		getAppointmentsOrg(payload).then(res => {
			loader(false);
			// toast.success(res?.message)
			dispatch({
				type: SET_ERROR,
				payload: res?.message
			});
			setAppointments(res.data);
		
		}).catch(err => {
			setAppointments([]);
			loader(false);
			toast.error(err?.response?.data?.error)
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const getStatusClass = (item) => {
		if(item === 0) {
			return 'badge badge-pill bg-warning-light'
		} else if(item === 1 || item === 4) {
			return 'badge badge-pill bg-success-blue text-white'
		} else if(item === 2 || item === 3) {
			return 'badge badge-pill bg-danger-light'
		}
	}

	const getStatus = (item) => {
		if(item === 0) {
			return 'Pending'
		} else if(item === 1) {
			return 'Confirm'
		} else if(item === 2) {
			return 'Rejected by doctor'
		}  else if(item === 3) {
			return 'Rejected by patient'
		}  else if(item === 4) {
			return 'Completed'
		}
	}

	const [appointmentPaginate, setAppointmentPaginate] = React.useState([]);
	const pageChangeAppointment  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setAppointmentPaginate(data);
	}

	React.useEffect(() => {
		fetchAppointmentsOrg();
	}, [selectValue]);

	React.useEffect(() => {
        if(search === '') {
            fetchAppointmentsOrg();
        }
    }, [search])


	return (
		<StyledContainer>
			<InsideMenuBar
				props={props}
				type={'organization'}
			/>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<CardComponent className="p-4">
								<StyledHeading>
									Appointment history
								</StyledHeading>
								<hr />
								<div className="doctor-panel-filters row col-md-12">
									<Search 
										selectValue={selectValue} 
										setSelectValue={setSelectValue} 
										search={search} 
										setSearch={setSearch} 
										searchPatient={searchPatient} 
										name={"Patient's"}
										options={options}
										statusOptions = {statusOptions}
									/>
								</div>
								
								<TableComponent tableHeading={tableHeading}>
								<> {
									appointments?.length != 0 ? appointmentPaginate?.map(item => {
										return <tr>
														<td>
															<h2 className="table-avatar">
																<a className="avatar avatar-sm mr-2">
																	<img className="avatar-img rounded-circle" src={item.doctorId.profileImage ? item.doctorId.profileImage : "/assets/img/doctors/doctor-thumb-01.jpg"} alt="" />
																</a>
																<a>{item.doctorId.name}</a>
															</h2>
														</td>
														<td>
															<h2 className="table-avatar">
																<a className="avatar avatar-sm mr-2">
																	<img className="avatar-img rounded-circle" src={item.patientId.profileImage ? item.patientId.profileImage : "/assets/img/loading-img.jpg"} alt="" />
																</a>
																<a>{item.patientId.name} </a>
															</h2>
														</td>
														<td>{moment(item?.slot?.start).utc().format('DD/MM/YYYY')} </td>
														<td>{moment(item?.slot?.start).utc().format('hh:mm a')} - {moment(item?.slot?.end).utc().format('hh:mm a')}</td>
														<td>{item.createdAt ? moment(item.createdAt).utc().format('DD/MM/YYYY') : '-'} </td>
														<td>{item.amount} </td>
														<td><span className={getStatusClass(item.status)}>{
															getStatus(item.status)
														}</span></td>
														<td>
															<div className="table-action">
																<Link to={`/organization/view-patient/${item._id}/${item.patientId._id}`} className="btn btn-sm bg-info-light-blue">
																	<i className="far fa-eye"></i> View
																</Link>
															</div>
														</td>
													</tr> 
									}): null
								}
								</>
							
								</TableComponent>
								{appointments?.length == 0 ? (<h5 style={{width: '100%', textAlign: 'center', margin: '2% 0 1%'}}>Sorry! There are no Patients to display!</h5>): null}
								{appointments?.length > 0 && <Pagination items={appointments} onChangePage={pageChangeAppointment} />}

							</CardComponent>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	)
}

export default Appointments