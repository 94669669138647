import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import "./pending.css"
import pendingclock from "../../assets/img/pendingclock.png"
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
const SubscriptionPending = ({status}) => {
  const [progress, setProgress] = useState(0);

  // Simulate the circular indicator movement
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 1 : 0));
    }, 2000); // Speed of progress

    return () => clearInterval(interval);
  }, []);
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: "white", // Linear gradient background
        color: '#fff',
        textAlign: 'center',
        padding: 2,
        marginTop:-10
      }}
    >
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Subscription Details
      </Typography>
      <div className="payment-container">
        <div className="payment-header">
          <h1>{status=="Failed"?"Payment Failed":"Payment Pending"}</h1>
        </div>
        <div className="circular-progress">
          {/* Circular progress with dynamic stroke-dashoffset based on the progress state */}
          <svg className="progress-ring" width="160" height="160">
            <circle
              className="progress-ring__circle"
              stroke="url(#gradient)"
              strokeWidth="12"
              fill="transparent"
              r="70"
              cx="80"
              cy="80"
              style={{
                strokeDasharray: 440, // Circumference of the circle
                strokeDashoffset: 440 - (440 * progress) / 100, // Adjust stroke offset
              }}
            />
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: "#F44336", stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: "#FFEB3B", stopOpacity: 1 }} />
              </linearGradient>
            </defs>
          </svg>
          <div className="progress-text">{progress}%</div>
        </div>

        <div className="payment-details">
         
        </div>
        {
          status =="Failed"?
          (
            <button className="retry-button">Retry</button>
          ):""
          // (
          //   <button className="retry-button">continue</button>
          // )
        }
        
      </div>
    
      {/* <Typography variant='h5'sx ={{marginBottom:2}}>
      Pending
      </Typography> */}
      
      
     
      
      <Typography variant="h6" sx={{ marginTop: 4 }}>
        {status=='Failed'?
      "please retry payment":
      " wait for the payment to succeed"  
      }
        
      </Typography>
    </Box>
  );
};

export default SubscriptionPending;
