import { CircularProgress, Grid } from "@mui/material";
import React from "react";

import FieldWrapper from "../../InputField/TextFieldWrapper";
import strings from "../../../constant/strings";
import { profileStyles } from "../../../assets/styles/DoctorProfileSettingStyles";
import { Formik } from "formik";

import { useDispatch, useSelector } from "react-redux";

import { StyledSecondaryButton } from "../../../assets/styles/CommonStyles";
import { resetOrgPasswordAction, resetSubadminPasswordAction, subadminlogoutAction } from "../../../redux/auth/authActions";
import { resetDoctoPassword } from "../../../utils/forms/schema.doctorProfileSettingSchema";

const ResetPasswordsubadmin = ({ onLoading, setLoading }) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.auth);

  const profileSettingClasses = profileStyles();

  const initialValues = {
    password: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values) => {
    const { confirmPassword, ...rest } = values;  // Remove confirmPassword before sending
   
    setLoading(true);
    await dispatch(resetSubadminPasswordAction(rest, userData.token));
    await dispatch(subadminlogoutAction(userData.token));
    setLoading(false);
  };

  return (
    <div className={profileSettingClasses.mainCont}>
      <Grid container spacing={2} className={profileSettingClasses.formikCont} width="70%">
        <Formik
          initialValues={initialValues}
          validationSchema={resetDoctoPassword}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, isSubmitting, errors }) => (
            <>
              <Grid item xs={12}>
                <FieldWrapper name="password" label={strings.enterCurrentPassword} />
              </Grid>
              <Grid item xs={12}>
                <FieldWrapper name="newPassword" label={strings.enterNewPassword} />
              </Grid>
              <Grid item xs={12}>
                <FieldWrapper name="confirmPassword" label={strings.enterConfirmPassword} />
              </Grid>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                <StyledSecondaryButton
                  disabled={Object.keys(errors).length > 0 || isSubmitting || onLoading}
                  onClick={handleSubmit}
                  style={{ marginBottom: 24 }}
                >
                  {onLoading ? (
                    <CircularProgress style={{ color: "white" }} size={20} thickness={5} />
                  ) : (
                    'Update Password'
                  )}
                </StyledSecondaryButton>
              </Grid>
            </>
          )}
        </Formik>
      </Grid>
    </div>
  );
};

export default ResetPasswordsubadmin;
