import { makeStyles } from "@mui/styles";
import { Modal, Button, Divider, Grid } from '@mui/material';
import styled from "styled-components";
import colors from "../../constant/colors";
import fontWeights from "../../constant/fontWeights";

const useStyles = makeStyles(()=>({


}))

export const StyledTextContent = styled(Grid)`

color: ${colors.black};
font-family: 'Poppins';
font-style: normal;
font-weight: ${fontWeights.fontWeight400};
font-size: 16px;
line-height: 24px;
text-align: center;
color: #2B2B2B;
`;

export const StyledTextBold = styled(Grid)`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

color: #2B2B2B;`;

export {useStyles};
