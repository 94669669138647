import React from "react";
import { Grid } from "@mui/material";

const ListHeading = ({ text, className }) => {
  return (
    <>
      <div
        className={
          !!text.secondaryHeading
            ? className.healthListHeading
            : `${className.healthListHeading} ${className.lipidTextStyle}`
        }
      >
        {text?.primaryHeading}
      </div>
      {!!text.secondaryHeading &&
      <Grid container spacing={0} className={className.secondaryCont}>
        <Grid item xs={10} className={className.secondaryHeadingStyle}>
          {text.secondaryHeading}
        </Grid>
      </Grid>}
      {!!text.secondaryHeading &&
      <div >kfvkfhvrfvhrf3oiv
      </div>}
    </>
  );
};

export default ListHeading;
