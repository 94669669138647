import React, {useEffect, useContext, useState} from 'react';

import InputField from '../../../../components/InputField';

import { toast } from 'react-toastify';

import { Context } from '../../../../store';
import {
	postLoginAPI
} from '../../../../api/subadmin';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../../store/types';

import { globalValidator, saveUser } from "../../../../utils";

import {
	StyledLeftPanel,
	StyledLeftHeading,
	StyledLeftSubHeading,
	StyledLeftImage,
	StyledRightPanel,
	StyledRightHeading,
	StyledRightSocialLogin,
	StyledSocialFB,
	StyledSocialGoogle,
	StyledRightDivider,
	StyledDividerLine,
	StyledDividerHeading,
	StyledLoginButton,
	StyledRightBottom,
	StyledBottomContent,
	StyledBottomLink,
	StyledForgotPassword,
	StyledForgotPasswordLink
} from './styles';

function SubUserLogin(props) {


	const [state, dispatch] = useContext(Context);

	const [orgUser, SetOrgUser] = useState({username: '', password: ''});

	const login = (e) => {
		e.preventDefault();
		const { username, password } = orgUser;
    const keys = [
      { name: "username", title: "Username", validateKey: "required" },
      { name: "password", title: "Password", validateKey: "password" }
    ];
		let valid = globalValidator({ username, password }, keys);

		if(valid === true) {
			loader(true);
			postLoginAPI(orgUser).then(res => {
				saveUser(res.data);
				loader(false);
				// toast.success(res?.message)
				dispatch({
					type: SET_ERROR,
					payload: res?.message
				});
				props.history.push('/subuser/dashboard');
			}).catch(err => {
				loader(false);
				toast.error(err?.response?.data?.error)
				dispatch({
					type: SET_ERROR,
					payload: err?.response?.data?.error
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const forgotPassword = () => {
		props.history.push('/subuser/forgot-password');
	}

	return (
		<section className="section home-tile-section">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-9 m-auto">
						<div className="row">
              <StyledLeftPanel className="col-md-5">
								<div className="row no-gutters h-100">
									<div className="col-10 col-lg-9 d-flex flex-column mx-auto">
										<StyledLeftHeading className="mt-5 mb-4">Login</StyledLeftHeading>
										<StyledLeftSubHeading>
											To keep connected with us please login with your personal info.
										</StyledLeftSubHeading>
										<div className="mt-auto mb-4">
											<StyledLeftImage src="https://demo.harnishdesign.net/html/oxyy/images/login-vector.png" />
										</div>
									</div>
								</div>
							</StyledLeftPanel>

							<StyledRightPanel className="col-md-7">
								<div className="container my-auto py-5">
									<div className="row">
										<div className="col-11 col-lg-10 mx-auto">

											<form className="form-border info-widget">
												<div className="form-group card-label">
													<label>Username</label>
													<InputField value={orgUser.username} onChange={e => SetOrgUser((user) => ({...user, username: e.target.value}))} />
												</div>

												<div className="form-group card-label">
													<label>Password</label>
													<InputField value={orgUser.password} password={true}  type="password" onChange={e => SetOrgUser((user) => ({...user, password: e.target.value}))} />
												</div>

												<StyledForgotPassword>
													<StyledForgotPasswordLink onClick={() => forgotPassword()}>
														Forgot Password ?
													</StyledForgotPasswordLink>
												</StyledForgotPassword>

												<StyledLoginButton onClick={(e) => login(e)}>
													Sign In Now
												</StyledLoginButton>
											</form>
											
										</div>
									
									</div>

								</div>
							
								
							</StyledRightPanel>

            </div>
          </div>
        </div>
		  </div>
	  </section>  
	)
}

export default SubUserLogin
