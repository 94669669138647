import { makeStyles } from "@mui/styles";
import colors from "../../constant/colors";
import fontWeights from "../../constant/fontWeights";

const useStyles = makeStyles(() => ({
  mainCont: {
    width: "90%",
    height: 100,
  },
  radiobutton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  image: {
    minHeight: "44px",
    maxHeight: "52px",
    minWidth: "63px",
    maxWidth: "98px",
    "@media only screen and (max-width: 414px)": {
      height: "40px",
    },
  },
  label: {
    width: "100%",
    marginBottom: "10px",
  },
  radiobuttoncontainer: {
    background: colors.radioButtonContainerColor,
    border: `2px solid ${colors.grey}`,
    borderRadius: "8px",
    width: "100%",
    minHeight: "52px",
    "@media only screen and (max-width: 414px)": {
      minHeight: "46px",
    },
  },
  selectedRadio: {
    border: `2px solid ${colors.primary}`,
    borderRadius: "8px",
    width: "100%",
    minHeight: "52px",
    color: colors.white,
    background: colors.primary,
    borderRadius: 8,
    "@media only screen and (max-width: 414px)": {
      minHeight: "46px",
    },
  },
  radiolabelfont: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight400,
    fontSize: "16px",
    lineHeight: "24px",
    "@media only screen and (max-width: 414px)": {
      fontSize: 14,
    },
  },
  root: {
    "& .css-j204z7-MuiFormControlLabel-root": {
      marginLeft: "0px",
      marginBottom: "8px",
    },
    "& .css-j204z7-MuiFormControlLabel-label": {
      marginLeft: "0px",
    },
    '&$checked': {
      color: colors.white
    },
  },
  buttonStyle: {
    color: "#808080",
    border: "2px red solid",
    
  },
  buttonStyleSecond: {
    color: "#808080",
    border: "2px red solid",
    
  },
  textfieldStyle: {
    color: colors.white,
  },
  checked: {
    backgroundColor: colors.white,
  },
  buttonContainer:{
    display:"flex",
    justifyContent:"center"
  },
  buttonContainerRight:{
    display:"flex",
    justifyContent: "flex-end",
    
  },
  buttonContainerLeft:{
    display:"flex",
    justifyContent: "flex-start"
  },
  
  dropdown: {
    width: "100%",
    height: "52px",
    background: "#F8F7F7",
    border: "1px solid #CCCCCC",
    borderRadius: "8px",
    color: `${colors.black2}`,
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    //lineHeight:" 24px",
   //padding: "16px",
    '&:focus': {
      borderColor: colors.primary,
    },
  },

  errordropdown: {
    width: "100%",
    height: "52px",
    background: "#F8F7F7",
    border: `1px solid ${colors.errorTextColor}`,
    borderRadius: "8px",
    color: `${colors.black2}`,
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    padding: "16px",
    '&:focus': {
      borderColor: colors.errorTextColor,
    },
  },

  borderStyle: {
    border: `2px solid ${colors.black2}`,
    paddingBottom: "16px",
    borderRadius: "8px",
    
  }, 
  borderStyleChecked: {
    border: `2px solid green`,
    paddingBottom: "16px",
    borderRadius: "8px",
  },  
}));

export { useStyles };
