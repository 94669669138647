import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";

import { useStyles } from "../../../assets/styles/PatientDashboardStyles";
import {
  StyledInnerHeadingText,
  StyledSecondaryButton,
} from "../../../assets/styles/CommonStyles";
import strings from "../../../constant/strings";
import HeadingComponent from "../HeadingComponent/index.js";
import Lists from "../../controls/Lists";
import {
  HealthParameterList,
  LipidProfileList,
} from "../../../constant/staticLists";
import { useDispatch, useSelector } from "react-redux";
import {
  bloodSugar,
  healthParams,
  lipidProfile,
  bloodPressure,
  bloodOxygen,
  bodyTemp,
  heartRate,
  respiratoryRate,
} from "../../../redux/user/userActions";
import ECGIcon from "../../../assets/img/ecg.png";
import moment from "moment";
import { usePatientHealthStyles } from "./style";
import { useHistory } from 'react-router-dom';
import TabComponent from "../../Tab";
import { getPatientAppointmentAction } from "../../../redux/appointment/patient/patientAppointmentActions";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import { useHealthParameterStyles } from "../HealthParameters/style.js";
import HealthParameters from "../HealthParameters/index.js";
const actionSagaMapping = {
  bloodPressureData: bloodPressure,
  bloodSugarData: bloodSugar,
  spo2sData: bloodOxygen,
  bodyTemperatureData: bodyTemp,
  respiratorieData: respiratoryRate,
  heartRateData: heartRate
};

const PatientDashboard = ({ item, patientID }) => {
  // const classes = useStyles();
  const commonClasses = useStyles();
  const dispatch = useDispatch();
  const useHealthParameterClasses = useHealthParameterStyles();
  const patientHealthClassses = usePatientHealthStyles();
  const styleClasses = commonMuiStyles();
  const [showData, setShowData] = useState([]);
  const [duration, setDuration] = React.useState(
    HealthParameterList.reduce((prev, cur) => {
      prev[cur.primaryKey] = "w";
      return prev;
    }, {})
  );
  const classes = {
    ...commonClasses,
    ...patientHealthClassses, ...useHealthParameterClasses
  };
  const [date, setDate] = React.useState(
    HealthParameterList.reduce((prev, cur) => {
      prev[cur.primaryKey] = moment(new Date()).format("YYYY-MM-DD");
      return prev;
    }, {})
  );
  const [tabValue, setTabValue] = useState(0);
  const tabs = [
    { id: 0, name: "Today" },
    { id: 1, name: "Upcoming" },
  ];
  const prevDate = useRef(date);
  const prevDur = useRef(duration);
  const { userData, user } = useSelector((state) => ({
    userData: state.auth.userData,
    user: state.user,
  }));

  const updatedLipidProfileList = LipidProfileList.map((item) => {
    return {
      ...item,
      value: user[item.value],
    };
  });
  const { patientAppointmentList } = useSelector((state) => ({
    patientAppointmentList: state.appointment.patientAppointmentList || [],
  }));
  useEffect(()=>{
    dispatch(getPatientAppointmentAction({ token: userData.token, status: 1 }));
  },[])
  const userType = localStorage.getItem("userType");

  useEffect(() => {
    if (
      patientAppointmentList === null ||
      patientAppointmentList.length === 0
    ) {
      setShowData([]);
      return;
    }
    let data = JSON.parse(JSON.stringify(patientAppointmentList));
    data.sort(
      (a, b) =>
        moment(a.start_time).format("YYYY-MM-DDTHH:mm") -
        moment(b.start_time).format("YYYY-MM-DDTHH:mm")
    );

    if (!tabValue) {
      const today = moment().startOf("day");
      const todayFilteredData = data.filter((item) => {
        const itemDate = moment(item.start_time);
        return itemDate.isSame(today, "day");
      });
      if (todayFilteredData.length) {
        const now = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        const filteredData = todayFilteredData.filter((item) => {
          const itemDate = moment(item.start_time);
          return itemDate.isSameOrAfter(now);
        });
        console.log("data",filteredData)
        setShowData(filteredData);
      } else {
        setShowData([]);
      }
    } else {
      const todayDate = moment().format('YYYY-MM-DD');
      const futureAppointments = data.filter(item =>
        moment(item.start_time).isAfter(todayDate, 'day'))
      if (futureAppointments?.length) {
        setShowData(futureAppointments);
      }
      else {
        setShowData([]);
      }
    }
  }, [patientAppointmentList, tabValue]);
  
  useEffect(() => {
    let changed;
    const preValues = Object.entries(prevDur.current);
    const values = Object.entries(duration);
    preValues.forEach((element, index) => {
      if (element[1] !== values[index][1]) changed = values[index];
    });
    prevDur.current = duration;
    if (changed) {
      userType == "patient" ?
        dispatch(
          actionSagaMapping[changed[0]].call(null, {
            token: userData.token,
            duration: changed[1],
            date: date[changed[0]],
          })
        ) :  dispatch(
          actionSagaMapping[changed[0]].call(null, {
            token: userData.token,
            duration: changed[1],
            date: date[changed[0]],
            appointmentId: userType === 'doctor' ? item._id : patientID
          })
        )
    }
  }, [duration]);

  const history = useHistory();

  useEffect(() => {
    let changed;
    const preValues = Object.entries(prevDate.current);
    const values = Object.entries(date);
    preValues.forEach((element, index) => {
      if (element[1] !== values[index][1]) changed = values[index];
    });
    prevDate.current = date;
    if (changed) {
      userType == "patient" ?
        dispatch(
          actionSagaMapping[changed[0]].call(null, {
            token: userData.token,
            duration: duration[changed[0]],
            date: changed[1],
          })
        ) :
        dispatch(
          actionSagaMapping[changed[0]].call(null, {
            token: userData.token,
            duration: duration[changed[0]],
            date: changed[1],
            appointmentId: userType === 'doctor' ? item._id : patientID
          }))
    }
  }, [date]);

  useEffect(() => {
    let date = !!item ? moment(item.start_time).utc().format("YYYY-MM-DD") : '';
    let id = userType=== "doctor" ?  item._id : (userType === "organization" || userType === "subadmin") ? patientID : ""

    if(userType==='doctor')
    { dispatch(healthParams({ token: userData.token, duration: "w", appointmentId: id, date: date }))}
    else if(userType === 'organization' || userType === "subadmin")
    {dispatch(healthParams({ token: userData.token, duration: "w", patientId: id, date: date }))}
    else {
      dispatch(lipidProfile({ token: userData.token, duration: "w", date: date }))
    }
    //dispatch(lipidProfile({ token: userData.token, duration: "w", appointmentId: id, date: date }))
    //dispatch(healthParams({ token: userData.token, duration: "w", appointmentId: id, date: date }));
    //setTimeout(() => dispatch(heartRate({ token: userData.token, duration: "w", appointmentId: id, date: date })), 0);
  }, [userData, dispatch]);

  // useEffect(() => {
  //   userType === "patient" && dispatch(
  //     getPatientAppointmentAction({
  //       token: userData.token,
  //       search: "",
  //       status: 1,
  //     })
  //   );
  // }, []);

  const handleTab = (e, value) => {
    setTabValue(value);
  };

  return (
    <Grid container spacing={0} >
      {userType === 'patient' &&
        <Grid item xs={12} className={classes.mainCont}>
          <HeadingComponent
            text={{ primary: strings.home, secondary: strings.homeHeadingText }}
          />
        </Grid>}
      <Grid item xs={12}>
        <Grid container spacing={0}>
          {userType === 'patient' && <Grid item xs={12} sm={12} md={4} lg={3} className={classes.appointMainCont}>
            <div className={classes.appointmentTextStyle}>
              {strings.appointments}
            </div>
         
            <div className={classes.detailCont}>
              <TabComponent
                tabs={tabs}
                tabValue={tabValue}
                setTabValue={setTabValue}
                handleTab={handleTab}
                customTab={`${styleClasses.customTab} ${classes.fontSize}}`}
                customTabIndicator={styleClasses.customTabIndicator}
              />
              {showData.length === 0 ? (
                <div className={classes.marginTop}>
                  <div className={classes.appointmentTextStyle}>
                    No Appointments
                  </div>
                </div>
              ) : (
                <div>
                  <Grid
                    container
                    spacing={0}
                    className={classes.appointmentCont}
                  >
                    <Grid item xs={1}>
                      <img
                        src={"/assets/img/doctor-empty.png"}
                        alt="doctor-img"
                        style={{ height: "18px", width: "18px" }}
                      />
                    </Grid>
                    <Grid item xs={8} className={classes.appointmentTextStyle}>
                      {showData[0].doctorInfo.name}
                    </Grid>
                    <Grid item xs={12} className={classes.content}>
                      {showData[0].doctorInfo.department}{" "}
                      {showData[0].appointment_type === 0
                        ? " : Online"
                        : ": In-Person"}
                    </Grid>
                    {!tabValue ? (
                      <Grid item xs={12} className={classes.content}>
                        Today ·{" "}
                        {moment(showData[0].start_time).format("hh:mm")} -
                        {moment(showData[0].end_time).add(1,'seconds').format("hh:mm A")}                          
                      </Grid>
                    ) : (
                      <Grid item xs={12} className={classes.content}>
                        {moment(showData[0].start_time).format("YYYY-MM-DD")}{" "} ·{" "}
                        {moment(showData[0].start_time).format("hh:mm")}{" "}-{" "} {moment(showData[0].end_time).add(1,'seconds').format("hh:mm A")}                        
                      </Grid>
                    )}
                  </Grid>
                </div>
              )}
            </div>
            <div
              className={classes.buttonCont}
              onClick={() => history.push("/patient/appointment-listing")}
            >
              {strings.viewMore}
            </div>
          </Grid>
          }
          <Grid item xs={12} sm={12} md={userType === 'patient' ? 7.8 : 12} lg={ userType === 'patient' ? 8.8 : 12} className={classes.ecgCont}>
            <img src={ECGIcon} style={{ width: "100%", height: "100%" }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.innerCont} >
        <StyledInnerHeadingText>
          {strings.healthParameter}
        </StyledInnerHeadingText>
        {userType === 'patient' && <StyledSecondaryButton
          type="button"
          onClick={() => history.push("/patient/health")}
          sx={{ textTransform: "capitalize" }}
        >
          {strings.viewHealthParameter}
        </StyledSecondaryButton>
        }
      </Grid>
 {/* // {/* <Grid item xs={12} className={classes.healthParameterCont}> */}
       {/* //  {/* <Lists */}
          {/* list={HealthParameterList}
          className={classes}
          navigation="healthParameters"
          duration={duration}
          setDuration={setDuration}
          date={date}
          setDate={setDate}
        // /> */} 
      {/* //</Grid> */} 
         <div  mt={-30}className={classes.mainHealthCont}>
            <Grid container justify="center" className={classes.secondaryMainHealth}>
              <HealthParameters className={classes} list={HealthParameterList} duration={duration} setDuration={setDuration}  date={date} setDate={setDate} />
            </Grid>
          </div>
      <Grid item xs={12} className={classes.innerCont}>
        <StyledInnerHeadingText>{strings.lipidProfile}</StyledInnerHeadingText>
      </Grid>
      <Grid item xs={12} className={classes.healthParameterCont}>
        <Lists
          list={updatedLipidProfileList}
          navigation="lipidParameters"
          data={bloodSugar}
          className={classes}
          duration={duration}
          setDuration={setDuration}
        />
      </Grid>
    </Grid>
  );
};

export default PatientDashboard;
