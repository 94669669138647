import styled from 'styled-components';

export const MainContainer = styled.div`
margin: 20px 60px 35px;
overflow-y: auto;
`;

export const TopContainer = styled.div`
display: flex;
justify-content: space-between;
padding: 15px;
border-bottom: 2px solid #F5F5F7;
`;

export const Heading = styled.div`
    font-family: Poppins;
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color: #2B2B2B;
`;

export const TextStyle = styled.div`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #5E5E5E;
`;