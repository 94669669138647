import styled from 'styled-components';

export const StyledBlogCard = styled.div`
.blog-content img {
  width: 500px !important;
}
.blog-image {
  width: 500px !important;
}


`;

export const StyledBlogYoutube= styled.div`
  
`;

export const StyledYoutibeDoctors= styled.div`
  .post-author {
    padding: 5px;
  }
`;
