import React, { useContext } from 'react';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	postContactUsAPI
} from '../../../api/website';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';

import Breadcrumb from '../../../components/Breadcrumb';
import InputField from '../../../components/InputField';

import { globalValidator } from "../../../utils";

import {
	StyledContactus,
	StyledTitle,
	StyledSubTitle,
	StyledDescription,
	StyledColounContent,
	StyledImgContrainer,
	StyledImg,
	StyledContactTitleContent,
	StyledContactSubTitleContent,
	StyledContactColounDescription,
	StyledButton,
	StyledCardButton
} from './styles';
import {Helmet} from "react-helmet";
import { useMediaQuery } from 'react-responsive'
import MobileAboutPage from '../../LandingPage/MobileAboutPage';
import AboutPage from '../../LandingPage/AboutPage';

function Contactus() {
	const [state, dispatch] = useContext(Context);

	const [contactus, setContactUs] = React.useState({
		name: "",
		email: "",
		subject: "",
		message: ""
	});

	const sumbitContactRequest = () => {
    const keys = [
      { name: "name", title: "Name", validateKey: "required" },
			{ name: "email", title: "Email", validateKey: "email" },
			{
        name: "message",
        title: "Message",
        validateKey: "required"
      },
    ];

    let valid = globalValidator(contactus, keys);

		if (valid === true) {
			loader(true);
			postContactUsAPI(contactus).then(res => {
				loader(false);
				// toast.success(res.message)
				dispatch({
					type: SET_ERROR,
					payload: res.message
				});
				setContactUs({
					name: "",
					email: "",
					subject: "",
					message: ""
				})
			}).catch(err => {
				loader(false);
				toast.error(err.response.data.error)
				dispatch({
					type: SET_ERROR,
					payload: err.response.data.error
				});
			})
		} else {
			toast.error(valid)
			dispatch({
				type: SET_ERROR,
				payload: valid
			});
		}

	}

			
	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	// const isMobile = useMediaQuery({
	// 	query: '(min-width: 700px)'
	//   });
	//   {/* Change name of the components */}
	//   return !isMobile ? <MobileAboutPage isMobile={!isMobile} /> : <AboutPage />

	return (
		<StyledContactus>
			<Helmet title = {'Contact best healtcare solution.'}
				htmlAttributes={{ lang: "en" }}
				meta={[
					{
						name: `description`,
						content:'Send us your query. We are best healtcare solution.',
					},
					{
						name: `keywords`,
						content:'best healthcare solution, digital healthcare solution, hospital management solution, online patient managemenet solution',
					},
				]}
     		 />
			<Breadcrumb 
				backRoute={[{id: 1, route: 'Home'}]}
				currentRoute='Contact us'
				title='Contact form' />
			
			<section className="section section-specialities">
      <div className="container">
        <div className="section-header text-center">
          <StyledSubTitle>Always Get In Touch</StyledSubTitle>
          <StyledTitle>We'r Ready To </StyledTitle>
					{/* <StyledDescription>
						Lorem ipsum dolor amet consectetur adipisicing eliteiuim sete eiusmod tempor incididunt ut labore etnalom dolore magna aliqua udiminimate veniam quis norud. Labore etnalom dolore magna aliqua udiminimate veniam quis norud.
					</StyledDescription> */}
        </div> 
        <div className="row">
          <div className="col-md-4 col-xs-12">
						<StyledColounContent>
							<StyledImgContrainer>
								<StyledImg src="http://amentotech.com/projects/doctreat/wp-content/uploads/2019/08/img-01-1.jpg" />
							</StyledImgContrainer>
							<StyledContactTitleContent>
								<StyledContactSubTitleContent>Have Query? </StyledContactSubTitleContent>
								{/* +91 76580 64535 */}
								Contact us
							</StyledContactTitleContent>
							{/* <StyledContactColounDescription>
								Amet consectetur adipisicing eliteiuim sete eiuode tempor incididunt. 
							</StyledContactColounDescription> */}
						</StyledColounContent>
					</div>
					<div className="col-md-4 col-xs-12">
						<StyledColounContent>
							<StyledImgContrainer>
								<StyledImg src="http://amentotech.com/projects/doctreat/wp-content/uploads/2019/08/img-02-1.jpg" />
							</StyledImgContrainer>
							<StyledContactTitleContent>
								<StyledContactSubTitleContent>Have Query? Email Us</StyledContactSubTitleContent>
									info@emed4u.com
							</StyledContactTitleContent>
							{/* <StyledContactColounDescription>
								Amet consectetur adipisicing eliteiuim sete eiuode tempor incididunt. 
							</StyledContactColounDescription> */}
						</StyledColounContent>
					</div>
					<div className="col-md-4 col-xs-12">
						<StyledColounContent>
							<StyledImgContrainer>
								<StyledImg src="http://amentotech.com/projects/doctreat/wp-content/uploads/2019/08/img-03.jpg" />
							</StyledImgContrainer>
							<StyledContactTitleContent>
								<StyledContactSubTitleContent>Have Query? Visit Us</StyledContactSubTitleContent>
								261 Shiv nagar colony, opposite Jwala Estate, Amritsar, Punjab, India
							</StyledContactTitleContent>
							{/* <StyledContactColounDescription>
								Amet consectetur adipisicing eliteiuim sete eiuode tempor incididunt. 
							</StyledContactColounDescription> */}
						</StyledColounContent>
					</div>
        </div>

				<div className="row mt-5">
					<div className="col-md-6 no-padding form-group card-label">
						<label>Name</label>
						<InputField value={contactus.name} onChange={e => setContactUs((user) => ({...user, name: e.target.value}))} />
					</div>
					<div className="col-md-6 no-padding form-group card-label">
						<label>Email</label>
						<InputField value={contactus.email} onChange={e => setContactUs((user) => ({...user, email: e.target.value}))} type="email" />
					</div>
					<div className="col-md-12 no-padding form-group "  style={{display:'grid'}}>
						<label>Message</label>
						<textarea style={{height: '145px'}} value={contactus.message} onChange={e => setContactUs((user) => ({...user, message: e.target.value}))}></textarea>
					</div>
					<StyledButton>
						<StyledCardButton onClick={() => sumbitContactRequest()}>
							Send Now
						</StyledCardButton>
					</StyledButton>
				</div>
      </div>
    </section>
		</StyledContactus>
	)
}

export default Contactus
