import React from 'react';
import InputField from '../InputField';
import SelectField from '../SelectField';
import './index.css';

function Search(props) {
	const selectedVal = props.options.find((item) => item.value === props.selectValue)?.label 

	return (
		<div className={`row col-md-10 ${props.className ? props.className : ''}`}>
			<div className='col-md-10 select-input-field'>
				<h4 className="mb-3">Filters</h4>
			</div>
			<div className="col-md-8 no-padding form-group select-input-field search-div">
				<label>Search by {props.name} {selectedVal ? selectedVal : 'Id'}</label>
				{props.selectValue === 'appt_date' ?
					<InputField type='date' value={props.search} onChange={(e) => props.setSearch(e.target.value)} />
				: null}

				{props.selectValue === 'status' ?
					<SelectField options={props.statusOptions} placeholder={'Select status'} onChange={(e) => props.setSearch(e.target.value)} value={props.search} />
				: null}

				{props.selectValue === 'email' ?
					<InputField value={props.search} onChange={(e) => props.setSearch(e.target.value)} />
				: null}

				{props.selectValue !== 'appt_date' && props.selectValue !== 'status' && props.selectValue !== 'email' ?
					<InputField value={props.search} onChange={(e) => props.setSearch(e.target.value)} />
				: null}
			</div>

			<div className="col-md-3 no-padding form-group mobile-screen-padding">
				<label>Filter</label>
				<SelectField options={props.options} placeholder={'Select Filter'} 
					onChange={(e) => {
						props.setSearch('')
						props.setSelectValue(e.target.value)}
					} 
					value={props.selectValue} 
				/>
			</div>

			<div className="col-md-1 no-padding form-group">
				<button
					className="doctor-active-btn hb-mobile-screen-button"
					onClick={() => props.searchPatient()}
				>
					<i className="fa fa-search" />
				</button>
			</div>
		</div>
	);
}

export default Search;
