import React, { useEffect } from 'react';
import {
	StyledPatient,
} from './styles';

import { toast } from 'react-toastify';
import { Context } from '../../store';
import {
	getAllPatient
} from '../../api/subadmin';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../store/types';
import TableComponent from '../Table';
import Pagination from '../Pagination';
import moment from 'moment';
import Search from '../Search';
import Cookies from 'js-cookie';
import { getWardRoomPatients } from '../../api/organization';

const options = [
	{ id: 0, label: 'Id', value: '_id' },
	{ id: 1, label: 'Name', value: 'name' },
	{ id: 2, label: 'Email', value: 'email' },
	// { id: 3, label: 'Status', value: 'status' },
	{ id: 4, label: 'Contact Number', value: 'phoneNumber' }
]

const tableHeading = [
	{id: 1, title: 'Patient'},
	// {id: 2, title: 'Latest appointments'},
	{id: 4, title: 'Joined Date'},
	{id: 5, title: 'Actions'}
];

function PatientFlow(props) {
	const [state, dispatch] = React.useContext(Context);
    const { addingPatient, setModalVisible, handleProcced } = props;
	const [patients, setPatients] = React.useState([]);
	const [search, setSearch] = React.useState('');
	const [selectValue, setSelectValue] = React.useState('_id');
	const [wardRoomPatients, setWardRoomPatients] = React.useState([]);
	const [msgAlert, setMsgAlert] = React.useState([1]);

	const fetchGetAllPatient = () => {
		loader(true);
		getAllPatient({['name']: search}).then(res => {
			loader(false);
			setPatients(res.data);
			setMsgAlert(res.data);
		}).catch(err => {
			loader(false);
			setPatients([]);
			setMsgAlert([]);
		})
	}

	const searchPatient = () => {
		loader(true);
		getAllPatient({[selectValue]: search}).then(res => {
			loader(false);
			setPatients(res.data);
			setMsgAlert(res?.data);
			// if(res.data.length == 0){
			// 	toast.error("Patients not found");
			// 	dispatch({
			// 		type: SET_ERROR,
			// 		payload: "Patients not found"
			// 	});
			// }
		}).catch(err => {
			loader(false);
			setPatients([]);
			setMsgAlert([]);
			// toast.error(err?.response?.data?.error)
			// dispatch({
			// 	type: SET_ERROR,
			// 	payload: err?.response?.data?.error
			// });
		})
	}

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}

	const fetchtWardRoomPatients = () => {
		getWardRoomPatients().then(res => {
			let filterData = (res.data && res.data.length) ? res.data.filter((item) => !item.dischargeByDoctor || !item.dischargeByFinancial) : []
			setWardRoomPatients(filterData);
			loader(false)
		}).catch(err => {
			loader(false)
			setWardRoomPatients([]);
			toast.error(err?.response?.data?.error);
			dispatch({
				type: SET_ERROR,
				payload: err?.response?.data?.error
			});
		})
	}

    const handleSelect = (data) => {
		fetchtWardRoomPatients();
		const currentPatient = wardRoomPatients.filter((item) => item.patient._id == data?._id )
		if(addingPatient && currentPatient?.length) {
			toast.error("Patient already exists")
			dispatch({
				type: SET_ERROR,
				payload: 'Patient already exists'
			});
			return false
		}
        setModalVisible(false);
        document.body.style.overflowY = 'auto';
        Cookies.set('setPatientInfo', JSON.stringify({
            name: data.name,
            address: data.address,
            gender: data.gender,
            dob: data.dob,
            weight: data.weight,
            id: data._id
        }), { path: '' });
        handleProcced(data);
    }
	const [patientPaginate, setPatientPaginate] = React.useState([]);
	const pageChangePatient  = (data) => {
		document.querySelector('body').scrollTo(0, 0);
		setPatientPaginate(data);
	}

	useEffect(() => {
		fetchtWardRoomPatients();
	}, []);
	
	useEffect(() => {
        if(search === '') {
            fetchGetAllPatient();
        }
    }, [search])

	useEffect(() => {
        fetchGetAllPatient();
    }, [selectValue])

	return (
		<div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)' }}>
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: '70%' }} role="document">
                    <div className="modal-content p-2" style={{ maxHeight: "700px", minHeight:"400px" }}>
                    <i class="fas fa-times patient-info-modal-close-icon" onClick={() => {
                                setModalVisible(false);
                                document.body.style.overflowY = 'auto';
                            }}></i>
						<div className="col-md-12 col-lg-12 col-xl-12 scroll-bar"  style={{ maxHeight: "600px", overflow:"scroll"}}>
								<StyledPatient>
									<div className="doctor-panel-filters row col-md-12 p-4">
										<Search
											selectValue={selectValue} 
											setSelectValue={setSelectValue} 
											search={search} 
											setSearch={setSearch} 
											searchPatient={searchPatient} 
											name={"Patient"}
											options={options}
										/>
									</div>
								</StyledPatient>
								<TableComponent tableHeading={tableHeading}>
									{
										patients.length > 0 && patientPaginate.map(item => {
											return (
												<tr key={item._id}>
													<td>
														<h2 className="table-avatar">
															<a href className="avatar avatar-sm mr-2">
																<img className="avatar-img rounded-circle" alt="" src={item.profileImage ? item.profileImage : "/assets/img/loading-img.jpg"} alt="User Image" />
															</a>
															<a href>{item.name} </a>
														</h2>
													</td>
													{/* <td>14 Dec 2020</td> */}
													<td>{moment(item.createdAt).format('DD/MM/YYYY, hh:mm a')} </td>
													<td>
														<div className="table-action">
															<div className="btn btn-sm bg-info-light-blue" onClick={()=>handleSelect(item)}>
																select
															</div>
														</div>
													</td>
												</tr>
											)
										})
									}
									
								</TableComponent>
								{msgAlert?.length == 0 ? <div className="d-flex justify-content-center" style={{textAlign: "center", padding: "50px 20px"}}>Sorry! There are no Patients to display</div>: null}

								{patients.length > 0 && <Pagination items={patients} onChangePage={pageChangePatient} />}
						</div>
					</div>
				</div>
			</div>
	)
}

export default PatientFlow
