import { makeStyles } from "@mui/styles";

import colors from "../../constant/colors";
import fontWeights from "../../constant/fontWeights";

const scheduleStyles = makeStyles(() => ({
  secondaryCont: {
    padding: "16px 8px",
    width: '100%'
  },
  dropdownCont: {
    width: 320,
    marginBottom: 12
  },
  selectionCont: {
    '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
      
      // background: colors.primary,
      // "-webkit-background-clip": "text",
      // "-webkit-text-fill-color": "transparent",
      // backgroundClip: "text",
      // textFillColor: "transparent",
    }
  },
  toolbarStyle:{
    display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' 
  },
  customEventStyle:{
    display: 'flex', justifyContent: 'space-between', alignItems: 'center' 
  },
  textStyle:{
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: fontWeights.fontWeight500,
    textTransform: "capitalize",
    lineHeight: '18px',
  },
  textCont: {
    marginLeft: 8
  },
  modalCont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: "center"
  },
  warningImageStyle: {
    width: 72,
    height: 72
  },
  buttonStyle: {
    border: `1px solid ${colors.grey1}`,
    color: colors.grey1,
    background: colors.white,
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: fontWeights.fontWeight500,
    textTransform: "capitalize",
    lineHeight: '24px',
    padding: "0px 36px"
  },
  primaryButtonStyle: {
    background: colors.warning,
    color: colors.white,
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: fontWeights.fontWeight500,
    textTransform: "capitalize",
    lineHeight: '24px',
    padding: "0px 24px",
    "&:hover": {
      background: colors.warning,
      color: colors.white,
    },
  },
  marginStyle: {
    marginTop: 10
  }
}));

export { scheduleStyles };
