import React, { useContext, useState } from 'react';
import './index.css';
import {
	StyledContainer
} from './styles';
import { Link } from 'react-router-dom';
import { logout } from '../../utils';

import { Howl } from 'howler';
import ringtone from '../../ringtone.mp3';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { leaveCall, muteAudioToggle, pauseVideo, resumeVideo, setUser, startBasicCall, unMuteAudioToggle } from '../Header/Agora_RTC';
import { toast } from 'react-toastify';
import { Context } from '../../store';
import { SET_ERROR, SET_USER_TYPE } from '../../store/types';
import { isMobile } from 'react-device-detect';
const apiUrl = 'https://api.emed4u.com'; //'http://localhost:5000'
// const socket = io(apiUrl);
const ringtoneSound = new Howl({
	src: [ringtone],
	loop: true,
	preload: true
})

const flexSpaceBetween = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}

const width = {width: '30%'}
const patientMenus = [
	{id: 13, name: 'Dashboard', icon: 'home', badge: false, route: '/patient/dashboard'},
	{id: 1, name: 'Appointments', icon: 'columns', badge: false, route: '/patient/appointment'},
	{id: 9, name: 'Medical record', icon: 'file-medical-alt', badge: false, route: '/patient/medical-record'},
	{id: 3, name: 'Message your doctor', icon: 'comments', badge: true, count: '1', route: '/patient/chat'},
	// {id: 12, name: 'Create QR', icon: 'notes-medical', badge: false, route: '/patient/create-qr'},
	// {id: 4, name: 'Profile Settings', icon: 'user-cog', badge: false, route: '/patient/settings'},
	// {id: 8, name: 'Billings & Invoices', icon: 'bookmark', badge: false, route: '/patient/invoices'},
	{id: 11, name: 'Logout', icon: 'sign-out-alt', badge: false}
];

const subAdminMenus = [
	{id: 1, name: 'Dashboard', icon: 'home', badge: false, route: '/subuser/dashboard'},
	{id: 3, name: 'IPD', icon: 'procedures', badge: false, route: '/subuser/ipdward'},
	// {id: 4, name: 'Patient Listing', icon: 'procedures', badge: false, route: '/subuser/patient-listing'},
	{id: 2, name: 'Appointments', icon: 'columns', badge: false, route: '/subuser/appointments'},
	{id: 11, name: 'Logout', icon: 'sign-out-alt', badge: false}

];

const doctorMenus = [
	{id: 1, name: 'Dashboard', icon: 'home', badge: false, route: '/doctor/schedule'},
	// {id: 2, name: 'My appointment overview', icon: 'columns', badge: false, route: '/doctor/appointments-overview'},
	// {id: 3, name: 'List & calendar view', icon: 'calendar-check', badge: false, route: '/doctor/appointments'},
	{id: 4, name: 'Schedule time', icon: 'business-time', badge: false, route: '/doctor/schedule-slots'},
	{id: 5, name: 'Communication', icon: 'comments', badge: false, route: '/doctor/doctor-chat'},
    // {id: 6, name: 'Patients (Add / List)', icon: 'user-injured', badge: false, route: '/doctor/patient-listing'},
	// {id: 7, name: 'Payment method', icon: 'money-check-alt', badge: false, route: '/doctor/payment-method'},
	// {id: 8, name: 'Invoices', icon: 'file-invoice', badge: false, route: '/doctor/invoices'},
	// {id: 9, name: 'Treatments', icon: 'file-invoice', badge: false, route:'/doctor/treatment'},
	{id: 11, name: 'Logout', icon: 'sign-out-alt', badge: false}

]

const orgMenus = [
	{id: 1, name: 'Dashboard', icon: 'home', badge: false, route: '/organization/dashboard'},
	{id: 2, name: 'Appointments', icon: 'calendar-check', badge: false, route: '/organization/appointments'},
	// {id: 3, name: 'Doctors (Add / List)', icon: 'user-md', badge: false, route: '/organization/doctor-listing'},
	// {id: 7, name: 'Patients (Add / List)', icon: 'user-injured', badge: false, route: '/organization/patient-listing'},
	// {id: 9, name: 'Sub admin (Add / List)', icon: 'users', badge: false, route: '/organization/sub-admin'},
	{id: 10, name: 'IPD wards', icon: 'hospital-alt', badge: false, route: '/organization/rooms'},
	{id: 8, name: 'Coupons', icon: 'gifts', badge: false, route: '/organization/coupons'},
	{id: 4, name: 'Invoices', icon: 'user-cog', badge: false, route: '/organization/invoices'},
	{id: 11, name: 'Logout', icon: 'sign-out-alt', badge: false}

	// {id: 10, name: 'Terms and condition', icon: 'file', badge: false, route: '/organization/terms-conditions'},
	// {id: 5, name: 'Edit your profile', icon: 'lock', badge: false, route: '/organization/settings'},
	// {id: 6, name: 'Logout', icon: 'sign-out-alt', badge: false}
];


function InsideMenuBar(props) {
	const [permission, setPermission] = React.useState('');
	const [calling, setCalling] = React.useState(false);
	const [doctorName, setDoctorName] = React.useState('');
	const [channel, setChannel] = useState('');
	const [appid, setAppid] = useState('f5a79afbfcce4005bc94277dbd7fe04b');
	const [token, setToken] = useState('');
	const [uuid, setUuid] = useState('');
	const [isjoin, setIsJoin] = useState(false);
	const [state, dispatch] = useContext(Context);
	const [currentUserId, setCurrentUserId] = useState(null);
	const [doctorProifleImage, setDoctorProifleImage ] = useState('');
	const [callType, setCallType] = useState('audio');

	const [muteAudio, setMuteAudio] = useState(false);
	const [stopVideo, setStopVideo] = useState(false);

	React.useEffect(() => {
		const script = document.createElement("script");

		script.src = "https://cdn.jsdelivr.net/npm/chart.js@2.9.4/dist/Chart.min.js";
		script.async = true;

		document.body.appendChild(script);
		(async function () {
			const data = await JSON.parse(localStorage.getItem('doctor'));
			if (data) {
				if (data.permission.includes("IPD") && data.permission.includes("OPD")) {
					setPermission('ADMIN');
				} else if (data.permission.includes("IPD")) {
					setPermission('IPD');
				} else {
					setPermission('OPD');
				}
			}

		}());
	}, [])

	// React.useEffect(() => {
	// 	socket.on('rtctoken-patient', data => {
	// 	  if(data) {
	// 		setChannel(data.channel)
	// 		setToken(data.refreshToken)
	// 		setUuid(data.uuid)
	// 		setDoctorProifleImage(data.doctorImage);
	// 		setDoctorName(data.doctorName);
	// 		setCallType(data.type)
	// 		setUser('Patient');
	// 	  }
	// 	})
	//   },[])

	  React.useEffect(() => {
		const data = state.callingData;
		if(data) {
			setChannel(data.channel)
			setToken(data.refreshToken)
			setUuid(data.uuid)
			setDoctorProifleImage(data.doctorImage);
			setDoctorName(data.doctorName);
			setCallType(data.type)
			setUser('Patient');
		  }
	  },[state])

	// React.useEffect(() => {
	// 	const customer = JSON.parse(localStorage.getItem('customer'));
	// 	const path = window.location.pathname;
	// 	if(customer && customer._id ) {
	// 		setCurrentUserId(customer._id);
	// 		const user = {
	// 			userId: customer._id
	// 		};
	// 		socket.emit("join_patient", user);
	// 		socket.on('rtctoken-patient', data => {
	// 			if(data) {
	// 				ringtoneSound.play();
	// 				setCalling(true);
	// 				alert('Working here')
	// 				setChannel(data.channel)
	// 				setToken(data.refreshToken)
	// 				setUuid(data.uuid)
	// 				setDoctorProifleImage(data.doctorImage);
	// 				setDoctorName(data.doctorName);
	// 				setCallType(data.type)
	// 				setUser('Patient');
	// 			}
	// 		})

	// 		socket.on("endCall", data => {
	// 			leaveCall(callType);
	// 			setIsJoin(false);
	// 			if (data?.role == 'Doctor') {
	// 				toast.success('Doctor end the call!')
	// 				dispatch({
	// 					type: SET_ERROR,
	// 					payload: 'Doctor end the call!'
	// 				});
	// 				setTimeout(() => {
	// 					window.location.reload();
	// 				}, 1000)
	// 			}
	// 		})
	// 	}
	// }, [])

	const getMenus = (type) => {
		if (type === 'subadmin') return subAdminMenus
		if (type === 'patient') return patientMenus
		if (type === 'doctor') {
			if(permission === 'IPD') {
				return doctorMenus.filter((item) => item.id !== 4)
			}
			return doctorMenus
		}
		if (type === 'organization') return orgMenus
	}

	const menus = getMenus(props.type)
	const logoutUser = () => {
		dispatch({
			type: SET_USER_TYPE,
			payload: ''
		});
		logout();
		props.props.history.push('/');
	}

	const callRejected = () => {
		const user = {
			appointmentId: channel,
			from: 'Patient'
		};
	//	socket.emit("rejectCall", user);
		setIsJoin(false);
		setCalling(false);
		ringtoneSound.stop();
		ringtoneSound.unload();
		// setTimeout(() => {
		// }, 100);
	}

	const handleClickJoin = () => {
		setCalling(false);
		ringtoneSound.stop();
		ringtoneSound.unload();
		// setTimeout(() => {
		// }, 100);
		AgoraRTC.getDevices()
			.then(devices => {
				const audioDevices = devices.filter(function (device) {
					return device.kind === "audioinput";
				});
				var selectedMicrophoneId = audioDevices[0].deviceId;
				
				const videoDevices = devices.filter(function (device) {
					return device.kind === "videoinput";
				});
				var selectedCameraId = videoDevices[0].deviceId;
				
				return Promise.all([
					AgoraRTC.createCameraVideoTrack({ cameraId: selectedCameraId }),
					AgoraRTC.createMicrophoneAudioTrack({ microphoneId: selectedMicrophoneId }),
				]);
			})
			.then(res => {
				const [videoTrack, audioTrack] = res;
				let options = {
					appId: appid,
					channel: channel,
					token: token,
					uid: uuid
				}
				const val = startBasicCall(options, videoTrack, audioTrack, callType).then(res => {
				})
				setIsJoin(true)
			}).catch(e => {
				toast.error('Permission denied')
				dispatch({
					type: SET_ERROR,
					payload: 'Permission denied'
				});
			});
	}
	const handleClickLeave = () => {
		const user = {
			appointmentId: channel,
			from: 'Patient'
		};
		// socket.emit("rejectCall", user);
		leaveCall(callType)
		setIsJoin(false)
		ringtoneSound.stop();
		ringtoneSound.unload();
		setTimeout(() => {
			window.location.reload();
		}, 1000)
	}

	const handleMuteAudio = () => {
		if (muteAudio) {
		  setMuteAudio(false);
		  unMuteAudioToggle()
		} else {
		  setMuteAudio(true);
		  muteAudioToggle()
		}
	  };

	const handleStopVideo = () => {
		if (stopVideo) {
		  setStopVideo(false);
		  resumeVideo()
		} else {
		  setStopVideo(true);
		  pauseVideo()
		}
	};
	return (
		<>
			<StyledContainer className="profile-menubar">
				<ul>
					{menus.map(item => (
						// item.route === props.props.match.path
						<li key={item.id} className={props.props.match.path.includes(item.route) ? 'active-patients' : ''}>
							{
								item.name === 'Logout' ? (
									<a className="patient-menubar-emed4u-link" data-toggle="modal" data-target="#exampleModalCenterlogout" style={{ cursor: 'pointer' }}>
										<i className={`fas fa-${item.icon}`}></i>
										<span>{item.name}</span>
									</a>
								) : (
									<Link to={item.route} className="patient-menubar-emed4u-link">
										<i className={`fas fa-${item.icon} menubaricon`}></i>
										{props.type === 'subadmin' ? <span style={{ width: '107px' }}>{item.name}</span> : <span>{item.name}</span>}
									</Link>
								)
							}
						</li>
					))}
				</ul>
				<div class="modal fade" id="exampleModalCenterlogout" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
					<div class="modal-dialog modal-dialog-centered" role="document">
						<div class="modal-content">
							<div class="modal-body p-5" style={{ fontSize: "20px" }}>
								Are you sure you want to log out?
							</div>
							<div class="modal-footer">
								<button type="button" class="btn btn-patient-light" data-dismiss="modal">Cancel</button>
								<button type="button" class="btn btn-primary" data-dismiss="modal" onClick={logoutUser}>Logout</button>
							</div>
						</div>
					</div>
				</div>
			</StyledContainer>
			{/* {calling ? (
				<div className="modal fade call-modal show" id="video_call" aria-modal="true" style={{ display: 'block', background: 'rgb(80 88 80 / 10%)', backdropFilter: 'blur(2px)', paddingLeft: '0px' }}>
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-body" style={{ padding: '40px' }}>
								<div className="call-box incoming-box">
									<div className="call-wrapper" style={{ height: 'auto' }}>
										<div className="call-inner">
											<div className="call-user">
												<img className="call-avatar" src={doctorProifleImage ? `${doctorProifleImage}` : "/assets/img/doctor-empty.png"} alt="User Image" />
												<h4>{doctorName}</h4>
												<span>Calling ...</span>
											</div>
											<div className="call-items">
												<a href="javascript:void(0);" className="btn call-item call-end" data-dismiss="modal" aria-label="Close"><i className="material-icons" onClick={callRejected}>call_end</i></a>
												{callType === 'audio' ? (
													<a href="javascript:void(0);" onClick={() => handleClickJoin()} data-toggle="modal" data-target="#video_call" className="btn call-item call-start"><i className="material-icons">phone_in_talk</i></a>
												) : (
													<a href="javascript:void(0);" onClick={() => handleClickJoin()} data-toggle="modal" data-target="#video_call" className="btn call-item call-start"><i className="material-icons">videocam</i></a>
												)}
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			) : null} */}
			{/* <div class="modal fade call-modal " id="video_call">
				<div class="modal-dialog video-call-modal modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-body">
							<div class="call-wrapper">
								<div class="call-main-row">
									<div class="call-main-wrapper">
										<div class="call-view">
											<div class="call-window">
												<div class="fixed-header">
													<div class="navbar">
														<div class="user-details">
															<div class="float-left user-img">
															</div>
															<div class="user-info float-left">
																<a><span>{doctorName}</span></a>
															</div>
														</div>
													</div>
												</div>
												<div class="call-contents">
													<div class="call-content-wrap">
														<div class="user-video">
															<div className='video-agora-box'>
															</div>
														</div>
														<div class="my-video">
															<ul>
																<li style={{ height: isMobile ? '50px' : '120px' }}>
																	<div id='video-agora-local'></div>
																</li>
															</ul>
														</div>
													</div>
												</div>
												<div class="call-footer">
													<div class="call-icons">
														<ul class="call-items">
														</ul>
														<div class="end-call" style={{ top: '-10px' }}>
															<a href="javascript:void(0);" class="btn call-item call-end" data-dismiss="modal" aria-label="Close" onClick={() => handleClickLeave()}>
																<i class="material-icons">call_end</i>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			<div class="modal fade call-modal" id="video_call">
				<div class="modal-dialog video-call-modal modal-dialog-centered" role="document">
					<div class="modal-content">
						<div class="modal-body">
							<div class="call-wrapper">
								<div class="call-main-row">
									<div class="call-main-wrapper">
										<div class="call-view">
											<div class="call-window">
												<div class="fixed-header">
													<div class="navbar">
														<div class="user-details">
															<div class="float-left user-img">
																<a class="avatar avatar-sm mr-2" title="Charlene Reed">
																	<img src={doctorProifleImage ? `${doctorProifleImage}` : "/assets/img/doctor-empty.png"} alt="User Image" class="rounded-circle" />
																	<span class="status online"></span>
																</a>
															</div>
															<div class="user-info float-left">
																<a><span>{doctorName}</span></a>
															</div>
														</div>
													</div>
												</div>
												<div class="call-contents">
													<div class="call-content-wrap">
														<div class="user-video">
															{
																callType === 'audio' ?
																	<img src="/assets/img/audio.png" class="img-fluid" alt="User Image" />
																	:
																	<div className='video-agora-box'></div>
															}
														</div>
														<div class="my-video">
															<ul>
																<li style={{ height: isMobile ? '50px' : '120px' }}>
																	{
																		callType === 'audio' ?
																			<img src="/assets/img/audio.png" class="img-fluid" alt="User Image" />
																			:
																			<div id='video-agora-local'></div>
																	}
																</li>
															</ul>
														</div>
													</div>
												</div>
												<div class="call-footer" style={flexSpaceBetween}>
													<div style={width}/>
													<div class="call-icons">
														<ul class="call-items">
															{callType === 'video' && <li class="call-item">
																<a href="javascript:void(0);" title="Enable Video" onClick={() => handleStopVideo()} data-placement="top" data-toggle="tooltip" className={stopVideo?'unMuteMicrophone':'muteMicrophone'} >
																	{/* <i class="fas fa-video camera"></i> */}
																	{stopVideo ? <i class="fa fa-video-slash "></i> : <i class="fa fa-video"></i>}
																</a>
															</li>}
															<li class="call-item">
																<a href="javascript:void(0);" title="Mute Audio" onClick={() => handleMuteAudio()} data-placement="top" data-toggle="tooltip" className={muteAudio?'unMuteMicrophone':'muteMicrophone'} >
																	{/* <i class="fa fa-microphone microphone"></i> */}
																	{muteAudio ? <i class="fa fa-microphone-slash "></i> : <i class="fa fa-microphone"></i>}
																</a>
															</li>
														</ul>
													</div>
													<div class="end-call" style={width}>
														<a href="javascript:void(0);" class="btn call-item end-call" data-dismiss="modal" aria-label="Close" onClick={() => handleClickLeave()}>
															<i class="material-icons">call_end</i>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default InsideMenuBar;

