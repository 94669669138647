import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { StyledButton } from '../../assets/styles/CommonStyles';
import { StyledDialogActions } from '../../assets/styles/DialogsStyle';

export default function Dialogs({ open, setOpen, content }) {
  const handleClose = () => {
    setOpen(false)
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title1"
        aria-describedby="alert-dialog-description1"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description1" >
            {content}
          </DialogContentText>
        </DialogContent>

        <StyledDialogActions >
          <StyledButton className='custom-dialog-actions' onClick={handleClose} autoFocus >
            Close
          </StyledButton>
        </StyledDialogActions>
      </Dialog>
    </>
  );
}