export const EditIcon = () => (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1763_1332)">
<path d="M12 3.75L14.25 6" stroke="#5E5E5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.75 5.25H4.5C4.10218 5.25 3.72064 5.40804 3.43934 5.68934C3.15804 5.97064 3 6.35218 3 6.75V13.5C3 13.8978 3.15804 14.2794 3.43934 14.5607C3.72064 14.842 4.10218 15 4.5 15H11.25C11.6478 15 12.0294 14.842 12.3107 14.5607C12.592 14.2794 12.75 13.8978 12.75 13.5V11.25" stroke="#5E5E5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.75 11.2502H9L15.375 4.87517C15.6734 4.5768 15.841 4.17213 15.841 3.75017C15.841 3.32821 15.6734 2.92354 15.375 2.62517C15.0766 2.3268 14.672 2.15918 14.25 2.15918C13.828 2.15918 13.4234 2.3268 13.125 2.62517L6.75 9.00017V11.2502Z" stroke="#5E5E5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1763_1332">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
</svg>
)