import React, { useEffect } from 'react'
import InputField from '../InputField';

function FilterByDate(props) {
  	return (

        <div className="col-md-12">
            <div className='d-flex justify-content-end'>
                <InputField type='date' value={props.searchDate} onChange={(e) => {
                    props.onChangeDate(e.target.value)}} />
                <div className="btn btn-primary submit-btn ml-3 d-flex align-items-center mobile-screen-rest-btn" onClick={() => props.onReset()}>Reset Filter</div>	
            </div>
        </div>
	);
};

export default FilterByDate;
