import styled from 'styled-components';

export const Input = styled.input`
    border: 2px solid #CCCCCC;
    border-radius: 8px;
    padding-left: 48px;
    background-size: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5E5E5E;
    width: 426px;
    height: 48px;

    &.input {
        border: 2px solid #CCCCCC;
        border-radius: 8px;
        background-size: 20px;
        font-weight: 400;
        color: #5E5E5E;
        width: 213px;
        height: 32px;
        border: 1px solid #CCCCCC;
        border-radius: 6px;
        font-size: 12px;
        line-height: 16px;
        padding-left: 28px;
    }
`;

export const InputWrapper = styled.div`
    position: relative;
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 5px;
    left: 16px;
`;
export const IconMobileWrapper = styled.div`
    position: absolute;
    top: 4px;
    left: 8px;
`;
